import EmailInfo from "./parts/EmailInfo";
import LoginInfo from "./parts/LoginInfo";
import PasswordInfo from "./parts/PasswordInfo";

import DeleteAccount from "pages/Account/elements/DeleteAccount";

import * as Styled from "./NormalAccount.styles";

const NormalAccount = ({ userData, authentication  }) => {

  return (
    <Styled.NormalAccountContainer>
      <LoginInfo userLogin={userData.name} />
      <EmailInfo userEmail={userData.email} />
          <PasswordInfo authentication={authentication} />
      <DeleteAccount isLocalUser/>
    </Styled.NormalAccountContainer>
  );
};
export default NormalAccount;
