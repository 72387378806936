import { memo, useEffect } from "react";
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useFormContext, Controller } from "react-hook-form";
import InputMask from "react-input-mask";

import TextFieldForm from "./TextFieldForm";

const HourInput = ({ name, isRequired, defaultValue, disabled, ...rest }) => {
    const { t } = useTranslation();
    const { control, trigger } = useFormContext();

    // rest.minValue = '09:00';
    // rest.maxValue = '12:00';

    const validateHourField = async (value) => {
        if (isRequired && (value === '' || value === undefined || value === null)) {
            return t('application.errors.fieldRequired');
        }
        if (value.length < 5 || (/_/).test(value)) {
            return t('application.errors.hourFieldTooShort');
        }
        if (!(/^(0\d|1\d|2[0-3]):[0-5]\d$/).test(value)) {
            return t('application.errors.hourFieldWrongFormat');
        }
        const valueToDate = new Date(`2000-01-01T${value}`);
        if (rest.minValue) {
            const minDate = new Date(`2000-01-01T${rest.minValue}`);
            if (valueToDate < minDate) {
                return `${t('application.errors.hourMin')} ${rest.minValue} )`;
            }
        }
        if (rest.maxValue) {
            const maxDate = new Date(`2000-01-01T${rest.maxValue}`);
            if (valueToDate > maxDate) {
                return `${t('application.errors.hourMax')} ${rest.maxValue} )`;
            }
        }
        return true;
    }

    return (
        <StyledHourWrapper
            className='hour-input'
        >
            <Controller
                name={name}
                control={control}
                defaultValue=''
                rules={{ validate: validateHourField }}
                render={({ field, fieldState }) => (
                    <HourContentInput
                        field={field}
                        fieldState={fieldState}
                        isRequired={isRequired}
                        trigger={trigger}
                        rest={rest}
                        disabled={disabled}
                    />
                )}
            />
        </StyledHourWrapper>
    )
};

const HourContentInput = ({ field, fieldState, isRequired, trigger, disabled, rest }) => {
    useEffect(() => {
        if (field.value) {
            trigger(field.name);
        }
    }, []);
    return (
        <InputMask
            mask="99:99"
            value={field.value}
            onChange={field.onChange}
            onBlur={ev => {
                trigger(field.name);
            }}
            disabled={disabled}
        >
            {(inputProps) => {
                return (
                    <TextFieldForm
                        placeholder={'GG:MM'}
                        {...inputProps}
                        {...rest}
                        name={null}
                        isRequired={isRequired}
                        error={fieldState.error}
                        disabled={disabled}
                    />
                )
            }}
        </InputMask>
    )
}

export default memo(HourInput);


const StyledHourWrapper = styled.div`
    & .input-wrapper {
        max-width: 8rem;
    }
`;