import styled from "styled-components";

import ImgBgError from 'assets/images/error500.jpg';

export const OutsideError = styled.div`
    background-color: ${({theme}) => theme.colors.main};
    background-image: url(${ImgBgError});
    background-size: cover;
    min-height: 100vh;
    display: flex;
    align-items: center;
    
    * {
        color: white !important;
    }

    img.warning {
        -webkit-filter: grayscale(1) invert(100%); 
        filter: grayscale(1) invert(100%);
    }
`

export const OutsideErrorWrapper = styled.div`
    border-top: 1px solid #cccccc35;
    border-bottom: 1px solid #cccccc35;
    width: 100%;
    justify-content: center;
    display: flex;
    gap: 100px;
    padding: 120px 0 150px;

    @media ${({theme}) => theme.device.mobile} {
        flex-direction: column;
        gap: 50px;
        padding: 25px;
    }

    & > div:first-child img {
        @media ${({theme}) => theme.device.tablet} {
            position: relative;
            top: -55px;
        }
    }


`