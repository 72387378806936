import React, { useState, useEffect, useContext, useRef } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { AppContext } from "context/appContext";

import { useLocation, useParams } from "react-router-dom";

import useAuthentication from "hooks/useAuthentication";

import InstanceMenu from "components/reusable/InstanceMenu/InstanceMenu";
import ModalLoginProblem from "./parts/ModalLoginProblem";

import NaborLogo from "components/commons/Logo/NaborLogo";
import LocalLogo from "components/commons/Logo/LocalLogo";

import * as Styled from "./Header.styles";
import * as Layout from "components/commons/Layout/Layout";


const Header = () => {
  const { t } = useTranslation();
  const { city, generalType } = useParams();
  const location = useLocation();
  const [mobileMenuActive, setMobileMenuActive] = useState(false);
  const [useNarrowLogo, setUseNarrowLogo] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const { headerAuthMenuWhite } = useContext(AppContext);
  const authentication = useAuthentication();

  const logoWrapperRef = useRef(null);

  const login = () => {
    const loginSuccess = authentication.login();
    if (loginSuccess) {
        console.log("Logged in Success Header", loginSuccess);
    } else
    if (!loginSuccess) {
      console.log("Error logging in Header", loginSuccess);
      setShowModal(true);
    }
  };

  const logout = () => {
    authentication.logout();
  };
  const register = () => {
    authentication.register();
  };

  const menuNotLoggedIn = [
    { title: t("menu.signup"), href: "#1", handler: register },
    { title: t("menu.login"), href: "#2", handler: login },
  ];
  const menuLoggedIn = [
    { title: t("menu.application"), href: `/${city}/${generalType}/wnioski` },
    { title: t("menu.account"), href: `/${city}/${generalType}/konto` },
    { title: t("menu.logout"), href: null, handler: logout },
  ];

  useEffect(() => {
    setMobileMenuActive(false);
  }, [location.pathname]);

  const menuItemsRight = authentication.isAuthenticated
    ? menuLoggedIn
    : menuNotLoggedIn;

  useEffect(() => {
    const handleResize = () => {
      const divElement = logoWrapperRef.current;
      if (city && !generalType && divElement) {
        setUseNarrowLogo(
          window.innerWidth - divElement.getBoundingClientRect()?.width < 650
        );
      }
    };
    if (!generalType || !city) {
      window.addEventListener("resize", handleResize);
      handleResize();
    }
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [city && generalType]);

  return (
    <Styled.Header primary={city && generalType ? false : true} >
        {showModal && <ModalLoginProblem closeModal={() => setShowModal(false)} info={"Nie możemy wyświetlić strony"}/>}
      <Styled.HeaderShadow className="header-shadow" primary={city && generalType ? false : true}>
        <HeaderLayoutContainer className="header-layout-container">
          <Styled.HeaderTopMenu className="header-top-menu">
            <Styled.HeaderLogo className="header-logo" ref={logoWrapperRef}>
              <Styled.NaborLogoContainer className="nabor-logo-container">
                <NaborLogo useNarrowLogo={useNarrowLogo} />
              </Styled.NaborLogoContainer>
              <LocalLogo />
            </Styled.HeaderLogo>
               {process.env.REACT_APP_NOLOGIN_MODE !== 'true' ?
                <Styled.HeaderSignMenu className={mobileMenuActive ? "active" : ""}>
                  {city &&
                    generalType &&
                    menuItemsRight.map((item, index) => (
                      <Styled.HeaderMenuItem key={index}>
                        <Styled.HeaderMenuLink
                          active={item.href === location.pathname}
                          to={item.href ? item.href : "#"}
                          onClick={item.handler}
                          className={headerAuthMenuWhite}
                        >
                          {item.title}
                        </Styled.HeaderMenuLink>
                      </Styled.HeaderMenuItem>
                    ))}
                </Styled.HeaderSignMenu>
                : null
            }
            {city && generalType && (
              <Styled.HeaderMobile>
                <Hamburger
                  className={mobileMenuActive ? "active" : ""}
                  onClick={() => setMobileMenuActive(!mobileMenuActive)}
                  aria-label="Menu"
                >
                  <span />
                  <span />
                  <span />
                </Hamburger>
              </Styled.HeaderMobile>
            )}
          </Styled.HeaderTopMenu>
        </HeaderLayoutContainer>
      </Styled.HeaderShadow>
      <Styled.HeaderMenu
        className={mobileMenuActive ? "active" : "header-menu"}
      >
        {city && generalType && (
          <InstanceMenu
            isAuthenticated={authentication.isAuthenticated}
            mobileMenuActive={mobileMenuActive}
          />
        )}
      </Styled.HeaderMenu>
    </Styled.Header>
  );
};

export default Header;

const HeaderLayoutContainer = styled(Layout.Container)``;

const Hamburger = ({ className, onClick }) => {
  return (
    <Styled.MobileHamburger className={className} onClick={onClick}>
      <span />
      <span />
      <span />
    </Styled.MobileHamburger>
  );
};
