import { useTranslation } from "react-i18next";

import { useInstance } from "hooks/useInstance";
import useUnknownCityOrType from "hooks/useUnknownCityOrType";

import { Page } from "components/commons/Page/Page";
import Breadcrumbs from "components/commons/Breadcrumbs/Breadcrumbs";
import StaticPage from "components/reusable/StaticPage/StaticPage";
import Banner from "components/commons/Banner/Banner";
import HowToSection from "components/reusable/HowToSection/HowToSection";

import DocumentsListing from "../../components/documents-page/DocumentsListing/DocumentsListing";

const InstanceHelp = () => {
  const { t } = useTranslation();

  useUnknownCityOrType();

  useInstance([
    {
      name: t("breadcrumbs.help"),
    },
  ]);

  return (
    <Page>
      <Breadcrumbs />
      <Banner />
      <StaticPage title={t("menu.help")}>
          <DocumentsListing type={2} />
      </StaticPage>
      <HowToSection indexesToRemove={[0]} />
    </Page>
  );
};

export default InstanceHelp;
