import React from 'react';

import * as Styled from './StaticPage.styles';
import * as Layout from 'components/commons/Layout/Layout';
import * as Text from 'components/commons/Text/Text';

const StaticPage = ({title, children}) => {
    return (
        <Styled.StaticPage >
            <Layout.Container narrowmore>
                {/* <Text.TitleMain>{title}</Text.TitleMain> */}
                <Styled.H2>{title}</Styled.H2>

                <Styled.StaticPageContent>
                    {children}
                </Styled.StaticPageContent>
            </Layout.Container>
        </Styled.StaticPage>
    )
};

export default StaticPage;