import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

const ButtonBaseStyling = ({ theme: { colors }, disabled, secondary, reverse, extraordinary }) => css`
    font-size: 1.6rem;
    font-weight: 600;
    letter-spacing: 0.8px;
    text-transform: uppercase;
    padding: 1.2rem 5rem;
    border-radius: 3.2rem;
    cursor: pointer;
    font-family: "Barlow",sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center; 
    text-align: center;
    opacity: 1;
    border: 2px solid white;    

    ${!secondary ? css`
        /* PRIMARY */
        color: ${reverse ? colors.main : colors.white};
        background-color: ${reverse ? colors.white : colors.main};
        border-color: ${reverse ? colors.white : colors.main};

        &:hover, &:focus {
            color: ${reverse ? colors.white : colors.main};
            background-color: ${reverse ? colors.accent : colors.white};
            border-color: ${reverse ? colors.white : colors.accent};
        }

        &:focus, &:focus-visible {
            box-shadow: ${reverse ? '0px 3px 30px #00000074' : '0px 3px 30px #00318974'};
            outline: none;
            background-color: ${reverse ? colors.white : colors.main};
            color: ${reverse ? colors.main : colors.white};
        }

        &:active {
            color: ${reverse ? colors.white : colors.accent};
            background-color: ${reverse ? colors.main : colors.white};
            border-color: ${reverse ? colors.white : colors.main};
        }
        
        &:hover {
            box-shadow: ${reverse ? 'none' : '0px 0px 20px #6F6F6F74'};
        }

        ${extraordinary && css`
            color: ${colors.white};
            background-color: ${colors.warning};
            border-color: ${colors.warning};
            &:hover, &:focus, &:active {
                color: ${colors.warning};
                background-color: ${colors.white};
                border-color: ${colors.warning};
            }
        `}

        ${disabled && css`
            pointer-events: none;
            color: ${reverse ? colors.grey30 : colors.white};
            background-color: ${reverse ? colors.white : colors.primaryDisabledGrey};
            border-color: ${reverse ? colors.white : colors.primaryDisabledGrey};
        `}

    ` : css`
        /* SECONDARY */
        color: ${reverse ? colors.white : colors.main};
        background-color: ${reverse ? colors.main : colors.white};
        border-color: ${reverse ? colors.white : colors.main};
        
        &:focus, &:focus-visible {
            color: ${reverse ? colors.white : colors.main};
            background-color: ${reverse ? colors.main : colors.white};
            border-color: ${reverse ? colors.white : colors.accent};
            box-shadow: 0px 3px 30px #00000074;
            outline: none;
        }

        &:active {
            color: ${reverse ? colors.white : colors.main};
            background-color: ${reverse ? colors.main : colors.white};
            border-color: ${colors.accent};
        }
        
        &:hover {
            color: ${reverse ? colors.white : colors.white};
            background-color: ${reverse ? colors.accent : colors.main};
            border-color: ${reverse ? colors.white : colors.main};
        }
            

        ${disabled && css`
            pointer-events: none;    
            border-color: ${colors.grey30};
            color: ${colors.grey60};
        `}
    `}
`;


export const Button = styled(({ disabled, reverse, secondary, ...props }) =>
    <button {...props} disabled={disabled}/>
)` 
    ${props => ButtonBaseStyling(props)}
`;

export const LinkButton = styled(({ disabled, reverse, secondary, ...props }) =>
    <Link {...props} />
)`
    ${props => ButtonBaseStyling(props)}
`;

export const AnchorLinkButton = styled(({ disabled, reverse, secondary, ...props }) =>
    <a {...props} />
)`
    ${props => ButtonBaseStyling(props)}
`;


// const BaseTextButtonStyling = ;

export const ButtonText = styled(({bold, ...props}) => {
    if (props.to && props.to.length > 0) {
        return <Link {...props} />
    }
    return (
        <button {...props} />
    )
})(({ theme: { colors, device }, bold }) => css`
    margin: 0;
    padding: 0;
    font-size: 1.6rem;
    cursor: pointer;
    letter-spacing: 0.5px;
    font-family: "Barlow";
    background: none;
    color: ${colors.main};
    position: relative;
    width: fit-content;
    outline: none;
    border: none;
    font-weight: ${bold ? 600 : 400};

    >.icon {
        width: 1.9rem;
        height: 1.9rem;
        fill: ${colors.main};
        position: relative;
        top: 0.3rem;
        margin-right: 0.3rem;
    }

    &:hover, &:focus {
        text-decoration: underline;
        >.icon {
            fill: ${colors.accent};
        }
    }

    &[disabled] {
        pointer-events: none;
        color: ${colors.accent};
    }

    @media ${device.tablet} {
        grid-template-columns: ${({ grid }) => grid || 'repeat(3, 1fr)'};
    }
`);