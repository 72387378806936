import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import L from 'leaflet';
import 'leaflet/dist/leaflet.css';

import { ButtonText } from 'components/commons/Button/ButtonText';
import { Button } from 'components/commons/Button/Button';
import mapMarker from 'assets/icons/map-marker.svg';
import closeIcon from 'assets/icons/ico-close-2.svg';

import { H4 } from "styles/common.styles";
import Loader from 'components/commons/Loader/Loader';
import * as Styled from "./ModalMap.styles";

import {
    TileLayer,
    Marker,
} from "react-leaflet";

const ModalMap = ({ unit, onCloseModal }) => {
    const { t } = useTranslation();
    const [position, setPosition] = useState();
    const [isLoading, setLoading] = useState(false);
    const [error, setError] = useState();

    const convertGeoLocationToPosition = (geoLocation) => {
        let position = geoLocation.substring(7, geoLocation.length - 1);
        const parts = position.split(" ");
        const result = [];
        result.push(parts[1]);
        result.push(parts[0]);
        return result;
    };

    useEffect(() => {
        if (unit) {
            if (unit.geoLocation) {
                return setPosition(convertGeoLocationToPosition(unit.geoLocation));
            }
            geocodeUnitAdress(unit);
        }
    }, [unit]);

    const geocodeUnitAdress = async (unit) => {
        if (!unit) return;
        const addressString = `${unit.houseNo} ${unit.street.replace("ul.", "")}, ${unit.postalCode} ${unit.city}, Poland`;
        const encodedAddress = encodeURIComponent(addressString);
        try {
            setLoading(true);
            setError(null);
            fetch(`https://nabor.pcss.pl/nom/search?format=json&q=${encodedAddress}`)
                .then(res => res.json())
                .then(data => {
                    if (data.length > 0) {
                        setPosition({
                            lat: data[0].lat,
                            lon: data[0].lon,
                        });
                    } else {
                        onCloseModal();
                        window.open(`https://maps.google.com?q=${encodedAddress}`);
                    }
                })
                .catch(() => {
                    onCloseModal();
                    window.open(`https://maps.google.com?q=${encodedAddress}`);
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (error) {
            setLoading(false);
        }

    };

    const markerObject = new L.Icon({
        iconUrl: mapMarker,
        iconRetinaUrl: mapMarker,
        popupAnchor: [0, -20],
        iconSize: new L.Point(45, 45),
    });

    const showOnGoogleMap = () => {
        if (position) {
            window.open(`https://maps.google.com?q=${position.lat},${position.lng}`);
        }
    };

    if (!position || position.length === 0) {
        return null;
    }

    return (
        <>
            <Styled.ModalMapWrapper aria-hidden="true">
                <Styled.ModalMap
                    id="ModalMap"
                    role="dialog"
                    aria-labelledby="ModalMapTitle"
                >
                    <Styled.ModalContainer>
                        <Styled.ModalMapHeader>
                            <H4 id="ModalMapTitle">{`${t("units:locationModal.header")}: ${unit?.name}`}</H4>
                            <Styled.CloseButton onClick={onCloseModal}>
                                <img className='image-wrapper' src={closeIcon} alt={t("units:locationModal.closeIconAlt")} />
                            </Styled.CloseButton>
                        </Styled.ModalMapHeader>
                        { (isLoading || !position) ? (
                            <Loader />
                        ) : (
                            <Styled.ModalMapContainer
                                center={position}
                                zoom={18}
                                scrollWheelZoom
                                closeOnBackground={false}
                            >
                                <TileLayer
                                    url='https://nabor.pcss.pl/tile/{z}/{x}/{y}.png'
                                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                />
                                <Marker
                                    position={position}
                                    icon={markerObject}
                                >
                                    <Styled.ModalMapTooltip
                                        direction="top"
                                        opacity={1}
                                        permanent
                                    >
                                        <span style={{ fontSize: "1.5rem" }}>{unit.name}</span>
                                    </Styled.ModalMapTooltip>
                                </Marker>
                            </Styled.ModalMapContainer>
                        )}

                        <Styled.ModalMapButtons>
                            <ButtonText onClick={showOnGoogleMap}>
                                {t("units:locationModal.showOnGoogleMap")}
                            </ButtonText>
                            <Button secondary onClick={onCloseModal}>
                                {t("units:locationModal.close")}
                            </Button>
                        </Styled.ModalMapButtons>
                    </Styled.ModalContainer>
                </Styled.ModalMap>
            </Styled.ModalMapWrapper>
            <Styled.BodyBlockScroll />
        </>
    );
}

export default ModalMap;


