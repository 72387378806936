import styled from "styled-components";

export const NormalAccountContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  gap: 5rem;
  width: 80%; 
`;

export const LoginInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding-bottom: 4rem;
  border-bottom: 1px solid ${({ theme }) => theme.colors.main};
    p {
        font-weight: 600; 
    };
`;

export const EmailInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding-bottom: 4rem;
  border-bottom: 1px solid ${({ theme }) => theme.colors.main};
`;

    export const EmailInputContainer = styled.div`
        display: flex;
        flex-direction: column;
        padding-bottom: 4rem;
            label {
                color: ${({ theme }) => theme.colors.grey90};
                font-size: 1.4rem;
                margin-bottom: 0.5rem;
                padding-left: 0.6rem;
            }
            input {
                width: 398px;
                height: 43px;
                // background: ${({ theme }) => theme.colors.grey90} 0% 0% no-repeat padding-box;
                border: 1px solid ${({ theme }) => theme.colors.grey30};
                border-radius: 4px;
                opacity: 1;
                padding-left: 1rem;
                letter-spacing: 0.48px;
                font-size: 1.4rem;
                font-weight: 600;     
        }
    `;

export const PasswordInfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding-bottom: 4rem;
    border-bottom: 1px solid ${({ theme }) => theme.colors.main};
        button {
            width: 250px;
            align-self: center;
            margin-top: 2rem;
        };
`;




