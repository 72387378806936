import React, { memo, useState, useMemo, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Button } from "components/commons/Button/Button";
import ModalConfirm from "components/commons/Modal/ModalConfirm";

import useApi from "hooks/useApi";
import * as Styled from "../ApplicationItem.styles";

import EditIcon from "assets/icons/edit-icon-blue.svg";
import DeleteIcon from "assets/icons/delete-icon-blue.svg";
import DownloadIcon from "assets/icons/download-white.svg";
import ReadOnlyIcon from "assets/icons/ico-readonly.svg";
import IcoAlert from "assets/icons/ico-triangle-alert-dark.svg";
import SignIcon from "assets/icons/sign.svg";
import ResultsIcon from "assets/icons/results.svg";
import ShowIcon from "assets/icons/show.svg";

import { parseAppType, podajMianownik } from "utils/utils";

const ApplicationGeneralInformation = ({
    applicationInfo,
    deleteApplication,
    submitApplication,
    sentVerificationLink,
    requestForWithdrawal,
    removeRequestForWithdrawal,
    signApplication,
    prolongateApplication,
    processApplication,
    acceptApplication,
    recreateApplication,
    copyApplication,
    getDeclination,
    isMobile,
    isIncomplete,
}) => {
    const { documentApi } = useApi();
    const { t } = useTranslation();
    const { city, generalType } = useParams();
    const [applicationRemoveConfirmModal, setApplicationRemoveConfirmModal] =
        useState(null);
    const [canApply, setCanApply] = useState(false);

    useEffect(() => {
        if (applicationInfo) {
            const isEditable = applicationInfo?.[0]?.isEditable;
            const incomplete = applicationInfo?.[0]?.incomplete;
            const isSaved = applicationInfo?.[0]?.isSaved;
            const instanceStatus = applicationInfo?.[0]?.instanceStatus;

            if (
                isEditable &&
                !incomplete &&
                isSaved &&
                [2, 3, 4, 70].includes(instanceStatus)
            ) {
                setCanApply(true);
            }
        }
    }, [applicationInfo]);

    const editApplicationLink = useMemo(() => {
        const id_DictApplicationType = applicationInfo?.[0]?.id_DictApplicationType;
        const appId = applicationInfo?.[0]?.id;
        const appType = parseAppType(id_DictApplicationType);

        return `/${city}/${generalType}${appType}/${appId}`;
    }, [applicationInfo, city, generalType]);

    const downloadApplication = async () => {
        const appId = applicationInfo?.[0]?.id;
        const id_DictApplicationType = applicationInfo?.[0]?.id_DictApplicationType;
        const appType = parseAppType(id_DictApplicationType, true);
        let filename = `${applicationInfo?.[0]?.fullName} ${appType}`;
        filename = filename.replaceAll(" ", "_");
        await documentApi.downloadFile("Application/"+appId, filename);
    };

    /// Buttons and application info with dependencies ///

    const StatusText = ({ children }) =>
        canApply ? (
            <Styled.ApplicationContentInfoParagraph>
                {t("myApplications.applicationGeneralInformation.status")}{" "}
                {getDeclination(generalType, "singular", "dopelniacz")}:{" "}
                <Styled.StatusText className="status-apply">
                    {children}
                </Styled.StatusText>
            </Styled.ApplicationContentInfoParagraph>
        ) : isIncomplete ? (
            <Styled.ApplicationContentInfoParagraph className="status-alert">
                <img src={IcoAlert} alt="Alert icon" />
                {t("myApplications.applicationGeneralInformation.status")}{" "}
                {getDeclination(generalType, "singular", "dopelniacz")}:{" "}
                <Styled.StatusText className="status-alert_info">{children}</Styled.StatusText>
                <Styled.StatusLink to={editApplicationLink} className="status-alert_link"> - Przejdź do wniosku aby go uzupełnić</Styled.StatusLink>
            </Styled.ApplicationContentInfoParagraph>
        ) : (
            <Styled.ApplicationContentInfoParagraph>
                {t("myApplications.applicationGeneralInformation.status")}{" "}
                {getDeclination(generalType, "singular", "dopelniacz")}:{" "}
                <Styled.StatusText>{children}</Styled.StatusText>
            </Styled.ApplicationContentInfoParagraph>
        );

    const ApplyButtonWarning = ({ className }) => {
        return canApply ? (
            <Styled.ButtonWarning className={className}>
                {t("myApplications.applicationGeneralInformation.actionButtons.submit")}{" "}
                {getDeclination(generalType, "singular", "mianownik")}
            </Styled.ButtonWarning>
        ) : null;
    };

    const EditOrReadOnlyButton = ({
        isEditable,
        className,
        editApplicationLink,
        t,
        generalType,
    }) =>
        isEditable ? (
            <Button secondary className={className} to={editApplicationLink}>
                <span className="text">
                    {t("myApplications.applicationGeneralInformation.actionButtons.edit")}{" "}
                    <span className="space">
                        {getDeclination(generalType, "singular", "mianownik")}
                    </span>
                </span>
                <img src={EditIcon} alt="Edit icon" />
            </Button>
        ) : (
            <Button
                secondary
                className={className}
                to={`${editApplicationLink}?mode=ro`}
            >
                <span className="text">
                    {t(
                        "myApplications.applicationGeneralInformation.actionButtons.readOnly"
                    )}{" "}
                    <span className="space">
                        {getDeclination(generalType, "singular", "mianownik")}
                    </span>
                </span>
                <img
                    className="read-only-icon"
                    src={ReadOnlyIcon}
                    alt="Show application icon"
                />
            </Button>
        );

    return (
        <>
            {applicationRemoveConfirmModal && (
                <ModalConfirm
                    closeModal={() => setApplicationRemoveConfirmModal(null)}
                    title={applicationRemoveConfirmModal.title}
                    text={applicationRemoveConfirmModal.text}
                    onConfirm={() => deleteApplication(applicationInfo?.[0]?.id)}
                    confirmText={applicationRemoveConfirmModal.confirmText}
                />
            )}
            {!isMobile && (
                <>
                    { applicationInfo?.map((info, index) => (
                        <Styled.ApplicationInfoWrapper key={index}>
                            <Styled.H5 
                                className="application-information-title"
                            >
                                {t("myApplications.applicationGeneralInformation.title")}{" "}
                                {getDeclination(generalType, "singular", "miejscownik")}
                            </Styled.H5>
                            <Styled.ApplicationContent 
                                className="application-content"
                            >
                                <Styled.ApplicationContentInfo className="application-content-info">
                                    <Styled.ApplicationContentInfoParagraph className="application-id">
                                        {podajMianownik(info.id_DictApplicationType, generalType)} nr:{" "}
                                        <Styled.GeneralInfoText>{info.docId}</Styled.GeneralInfoText>
                                    </Styled.ApplicationContentInfoParagraph>
                                    <Styled.ApplicationContentInfoParagraph className="application-fullname">
                                        {podajMianownik(info.id_DictApplicationType, generalType)} dla:{" "}
                                        <Styled.GeneralInfoText>{info.fullName}</Styled.GeneralInfoText>
                                    </Styled.ApplicationContentInfoParagraph>
                                    <StatusText>{info.status}</StatusText>
                                    {info.qualified && <Styled.ApplicationContentInfoParagraph className="application-qualified">
                                        {t(
                                            "myApplications.applicationGeneralInformation.qualified"
                                        )}{" "}
                                        <Styled.GeneralInfoText>{info.qualified}</Styled.GeneralInfoText>
                                    </Styled.ApplicationContentInfoParagraph>
                                    }
                                </Styled.ApplicationContentInfo>
                                <Styled.ApplicationActionButtonsContainer>
                                    <ApplyButtonWarning
                                        isEditable={info.isEditable}
                                        incomplete={info.incomplete}
                                        isSaved={info.isSaved}
                                        instanceStatus={info.instanceStatus}
                                    />
                                </Styled.ApplicationActionButtonsContainer>
                            </Styled.ApplicationContent>
                            <Styled.ApplicationDates>
                                <Styled.H6 className="application-information-subtitle">
                                    {t("myApplications.applicationGeneralInformation.titleDates")}{" "}
                                    {getDeclination(generalType, "singular", "dopelniacz")}
                                </Styled.H6>
                                <Styled.ApplicationContentInfoParagraph>
                                    {t("myApplications.applicationGeneralInformation.dateSaved")}{" "}
                                    <Styled.GeneralInfoText>
                                        {info.createdDate}
                                    </Styled.GeneralInfoText>
                                </Styled.ApplicationContentInfoParagraph>
                                {info.validDate && <Styled.ApplicationContentInfoParagraph>
                                    {t("myApplications.applicationGeneralInformation.dateVaild")}{" "}
                                    <Styled.GeneralInfoText>
                                        {info.validDate}
                                    </Styled.GeneralInfoText>
                                </Styled.ApplicationContentInfoParagraph>
                                }
                                <Styled.ApplicationContentInfoParagraph>
                                    {t(
                                        "myApplications.applicationGeneralInformation.dateModified"
                                    )}{" "}
                                    <Styled.GeneralInfoText>
                                        {info.lastModified}
                                    </Styled.GeneralInfoText>
                                </Styled.ApplicationContentInfoParagraph>
                            </Styled.ApplicationDates>
                            <Styled.ApplicationActions>
                                <Styled.H6 className="application-information-subtitle">
                                    {t(
                                        "myApplications.applicationGeneralInformation.actionButtons.title"
                                    )}{" "}
                                    {getDeclination(generalType, "singular", "dopelniacz")}
                                </Styled.H6>
                                <Styled.ApplicationActionButtonsContainer>
                                    <Styled.ApplicationSecondaryActions>
                                        <EditOrReadOnlyButton
                                            isEditable={info.isEditable}
                                            className="application-button"
                                            editApplicationLink={editApplicationLink}
                                            t={t}
                                            generalType={generalType}
                                        />
                                        <Button
                                            secondary
                                            className="application-button"
                                            disabled={!info.isRemovable}
                                            onClick={() =>
                                                deleteApplication({ id: info.id, name: info.fullName })
                                            }
                                        >
                                            <span className="text">
                                                {t(
                                                    "myApplications.applicationGeneralInformation.actionButtons.delete"
                                                )}{" "}
                                                <span className="space">
                                                    {getDeclination(generalType, "singular", "mianownik")}
                                                </span>
                                            </span>
                                            <img src={DeleteIcon} alt="Delete icon" />
                                        </Button>
                                        {info.signDocument && !info.incomplete && info.id_DictApplicationStatus === 1 &&
                                            <Button
                                                secondary
                                                className="application-button"
                                                onClick={async () => {
                                                    signApplication({id: info.id, name: info.fullName})
                                                }}
                                            >
                                            <span className="text">
                                                {t("default:myApplications.applicationGeneralInformation.actionButtons.sign")}
                                            </span>
                                            <img src={SignIcon} alt="Sign icon"/>
                                            </Button>}
                                        {info.isProlongationRequired && info.isContinuous && !info.incomplete && info.id_DictApplicationStatus === 2 &&
                                            <Button
                                                secondary
                                                className="application-button"
                                                onClick={async () => {
                                                    prolongateApplication({id: info.id, name: info.fullName})
                                                }}
                                            >
                                            <span className="text">
                                                {t("default:myAccount.submittedApplications.application.prolongateApplication.title")}
                                            </span>
                                            </Button>}
                                        {info.sa == false && info.isSA == true && !info.incomplete &&
                                            <Button
                                                secondary
                                                className="application-button"
                                                onClick={async () => {
                                                    copyApplication({id: info.id, name: info.fullName})
                                                }}
                                            >
                                            <span className="text">
                                                {t("default:myAccount.submittedApplications.application.copyApplication.title")}
                                            </span>
                                            </Button>}
                                        {!info.isContinuous && !info.incomplete && info.id_DictApplicationStatus === 3 && <Button
                                            secondary
                                            className="application-button"
                                            onClick={async () => {
                                                acceptApplication({id: info.id, name: info.fullName})
                                            }}
                                        >
                                        <span className="text">
                                            {t("default:myAccount.submittedApplications.application.confirmationOfAcceptance.title")}
                                        </span>
                                        </Button>}
                                        {info.isContinuous && !info.incomplete && info.id_DictApplicationStatus === 3 && <Button
                                                secondary
                                                className="application-button"
                                                onClick={async () => {
                                                    processApplication({id: info.id, name: info.fullName})
                                                }}
                                            >
                                            <span className="text">
                                                {t("default:myAccount.submittedApplications.application.confirmationOfAcceptance.title")}
                                            </span>
                                            </Button>}
                                        {info.hasMailVerification && !info.incomplete && info.id_DictApplicationStatus === 1 && <Button
                                            secondary
                                            onClick={ async ()=> {
                                                sentVerificationLink({ id: info.id, name: info.fullName })
                                            }}
                                        >
                                        <span className="text">
                                            {t("default:myApplications.applicationGeneralInformation.actionButtons.sent")}
                                        </span>
                                        </Button>}
                                        {info.remoteVerification && !info.incomplete && info.id_DictApplicationStatus === 1 &&
                                            ((info.id_DictApplicationType === 3 && (info.instanceStatus === 2 || info.instanceStatus === 4)) ||
                                             (info.id_DictApplicationType !== 3 && (info.instanceStatus === 3 || info.instanceStatus === 4)) ||
                                             (info.sa && info.instanceStatus === 70)
                                            )
                                            &&
                                             <Button
                                            secondary
                                            onClick={() =>
                                                submitApplication({ id: info.id, name: info.fullName })
                                            }
                                        >
                                        <span className="text">
                                            {t("myApplications.applicationGeneralInformation.actionButtons.submit")+" "+getDeclination(generalType, "singular", "mianownik")}
                                        </span>
                                        </Button>}
                                        {info.remoteVerification && !info.unverifyAlertSent && !info.incomplete && info.id_DictApplicationStatus === 2 &&
                                            ((info.id_DictApplicationType === 3 && (info.instanceStatus === 2 || info.instanceStatus === 4)) ||
                                                (info.id_DictApplicationType !== 3 && (info.instanceStatus === 3 || info.instanceStatus === 4)) ||
                                                (info.sa && info.instanceStatus === 70)
                                            )
                                            &&
                                            <Button
                                                secondary
                                                onClick={() =>
                                                    requestForWithdrawal({ id: info.id, name: info.fullName })
                                                }
                                            >
                                        <span className="text">
                                            {t("myAccount.submittedApplications.application.sendRequestForWithdrawal.title")}
                                        </span>
                                        </Button>}
                                        {info.remoteVerification && info.unverifyAlertSent && !info.incomplete && info.id_DictApplicationStatus === 2 &&
                                            ((info.id_DictApplicationType === 3 && (info.instanceStatus === 2 || info.instanceStatus === 4)) ||
                                                (info.id_DictApplicationType !== 3 && (info.instanceStatus === 3 || info.instanceStatus === 4)) ||
                                                (info.sa && info.instanceStatus === 70)
                                            )
                                            &&
                                            <Button
                                                secondary
                                                onClick={()=>
                                                    removeRequestForWithdrawal({ id: info.id, name: info.fullName })
                                                }
                                            >
                                        <span className="text">
                                            {t("myAccount.submittedApplications.application.removeRequestForWithdrawal.title")}
                                        </span>
                                        </Button>}
                                    </Styled.ApplicationSecondaryActions>
                                    <Button
                                        className="application-button"
                                        disabled={!info.isDownloadable || info.incomplete}
                                        onClick={downloadApplication}
                                    >
                                        <span className="text">
                                            {t(
                                                "myApplications.applicationGeneralInformation.actionButtons.download"
                                            )}{" "}
                                            <span className="space">
                                                {getDeclination(generalType, "singular", "mianownik")}
                                            </span>
                                        </span>
                                        <img src={DownloadIcon} alt="Download icon" />
                                    </Button>
                                </Styled.ApplicationActionButtonsContainer>
                            </Styled.ApplicationActions>
                        </Styled.ApplicationInfoWrapper>
                    ))}
                </>
            )}
            { isMobile && (
                <ApplicationGeneralInformationMobile
                    applicationInfo={applicationInfo}
                    deleteApplication={deleteApplication}
                    submitApplication={submitApplication}
                    sentVerificationLink={sentVerificationLink}
                    requestForWithdrawal={requestForWithdrawal}
                    removeRequestForWithdrawal={removeRequestForWithdrawal}
                    signApplication={signApplication}
                    prolongateApplication={prolongateApplication}
                    processApplication={processApplication}
                    acceptApplication={acceptApplication}
                    recreateApplication={recreateApplication}
                    copyApplication={copyApplication}
                    getDeclination={getDeclination}
                    downloadApplication={downloadApplication}
                    editApplicationLink={editApplicationLink}
                    generalType={generalType}
                    applyButtonWarning={ApplyButtonWarning}
                    editOrReadOnly={EditOrReadOnlyButton}
                />
            ) }
        </>
    );
};

export default memo(ApplicationGeneralInformation);

const ApplicationGeneralInformationMobile = ({
    applicationInfo,
    deleteApplication,
    submitApplication,
    sentVerificationLink,
    requestForWithdrawal,
    removeRequestForWithdrawal,
    signApplication,
    prolongateApplication,
    processApplication,
    acceptApplication,
    recreateApplication,
    copyApplication,
    getDeclination,
    generalType,
    editApplicationLink,
    downloadApplication,
    applyButtonWarning: ApplyButtonWarning,
    editOrReadOnly: EditOrReadOnlyButton,
}) => {
    const { t } = useTranslation();
    return (
        <>
            {applicationInfo?.map((info) => (
                <Styled.ApplicationInfoWrapperMobile
                    key={info.id}
                    className="general-info"
                >
                    <Styled.ApplicationInfoTitleContainer>
                        <Styled.H4 className="application-id-mobile">
                            {podajMianownik(info.id_DictApplicationType, generalType)} nr:{" "}
                            {info.docId}
                        </Styled.H4>
                    </Styled.ApplicationInfoTitleContainer>
                    <Styled.ApplicationInfoStatus>
                        <Styled.H5 className="application-title-mobile">
                            {t("myApplications.applicationGeneralInformation.status")}{" "}
                            {getDeclination(generalType, "singular", "dopelniacz")}:{" "}
                            <Styled.StatusText className="status-mobile">
                                {info.status}
                            </Styled.StatusText>
                        </Styled.H5>
                        <ApplyButtonWarning
                            className="application-button-warning-mobile"
                            isEditable={info.isEditable}
                            incomplete={info.incomplete}
                            isSaved={info.isSaved}
                            instanceStatus={info.instanceStatus}
                        />
                    </Styled.ApplicationInfoStatus>
                    <Styled.ApplicationInfoMobile>
                        <Styled.H5 className="application-title-mobile">
                            {t("myApplications.applicationGeneralInformation.titleDates")}{" "}
                            {getDeclination(generalType, "singular", "dopelniacz")}:
                        </Styled.H5>
                        <Styled.ApplicationInfoParagraphMobile>
                            {t("myApplications.applicationGeneralInformation.dateSaved")}{" "}
                            {info.createdDate}
                        </Styled.ApplicationInfoParagraphMobile>
                        {info.validDate &&<Styled.ApplicationInfoParagraphMobile>
                            {t("myApplications.applicationGeneralInformation.dateVaild")}{" "}
                            {info.validDate}
                        </Styled.ApplicationInfoParagraphMobile>
                        }
                        <Styled.ApplicationInfoParagraphMobile>
                            {t("myApplications.applicationGeneralInformation.dateModified")}{" "}
                            {info.lastModified}
                        </Styled.ApplicationInfoParagraphMobile>
                    </Styled.ApplicationInfoMobile>
                    <Styled.ApplicationActionButtonsContainerMobile>
                        <EditOrReadOnlyButton
                            isEditable={info.isEditable}
                            className="application-button-mobile"
                            editApplicationLink={editApplicationLink}
                            t={t}
                            generalType={generalType}
                        />
                        <Button
                            secondary
                            className="application-button-mobile"
                            disabled={!info.isRemovable}
                            onClick={() =>
                                deleteApplication({ id: info.id, name: info.fullName })
                            }
                        >
                            <span className="text">
                                {t(
                                    "myApplications.applicationGeneralInformation.actionButtons.delete"
                                )}{" "}
                                <span className="space">
                                    {getDeclination(generalType, "singular", "mianownik")}
                                </span>
                            </span>
                            <img className="delete-icon" src={DeleteIcon} alt="Delete icon" />
                        </Button>
                        {info.signDocument && !info.incomplete && info.id_DictApplicationStatus === 1 && <Button
                            secondary
                            className="application-button-mobile"
                            onClick={
                                async () => {
                                    signApplication({id: info.id, name: info.fullName})
                                }}
                        >
                            <span className="text">
                                {t("default:myApplications.applicationGeneralInformation.actionButtons.sign")}
                            </span>
                            <img src={SignIcon} alt="Sign icon"/>
                        </Button>}
                        {info.isProlongationRequired && info.isContinuous && !info.incomplete && info.id_DictApplicationStatus === 2 && <Button
                            secondary
                            className="application-button-mobile"
                            onClick={
                                async () => {
                                    prolongateApplication({id: info.id, name: info.fullName})
                                }}
                        >
                            <span className="text">
                                {t("default:myAccount.submittedApplications.application.prolongateApplication.title")}
                            </span>
                        </Button>}
                        {info.sa == false && info.isSA == true && !info.incomplete && <Button
                            secondary
                            className="application-button-mobile"
                            onClick={
                                async () => {
                                    copyApplication({id: info.id, name: info.fullName})
                                }}
                        >
                            <span className="text">
                                {t("default:myAccount.submittedApplications.application.copyApplication.title")}
                            </span>
                        </Button>}
                        {!info.isContinuous && !info.incomplete && info.id_DictApplicationStatus === 3 && <Button
                            secondary
                            className="application-button-mobile"
                            onClick={
                                async () => {
                                    acceptApplication({id: info.id, name: info.fullName})
                                }}
                        >
                            <span className="text">
                                {t("default:myAccount.submittedApplications.application.confirmationOfAcceptance.title")}
                            </span>
                        </Button>}
                        {info.isContinuous && !info.incomplete && info.id_DictApplicationStatus === 3 && <Button
                                secondary
                                className="application-button-mobile"
                                onClick={
                                    async () => {
                                        processApplication({id: info.id, name: info.fullName})
                                    }}
                            >
                                <span className="text">
                                    {t("default:myAccount.submittedApplications.application.confirmationOfAcceptance.title")}
                                </span>
                            </Button>}
                        {info.hasMailVerification && !info.incomplete && info.id_DictApplicationStatus === 1 && <Button
                                secondary
                                className="application-button-mobile"
                                onClick={
                                    async ()=> {
                                        sentVerificationLink({ id: info.id, name: info.fullName })
                                }}
                            >
                            <span className="text">
                                {t("default:myApplications.applicationGeneralInformation.actionButtons.sent")}
                            </span>
                            </Button>}
                            {info.remoteVerification && !info.incomplete && info.id_DictApplicationStatus === 1 &&
                                ((info.id_DictApplicationType === 3 && (info.instanceStatus === 2 || info.instanceStatus === 4)) ||
                                 (info.id_DictApplicationType !== 3 && (info.instanceStatus === 3 || info.instanceStatus === 4)) ||
                                 (info.sa && info.instanceStatus === 70)
                                ) && <Button
                                secondary
                                className="application-button-mobile"
                                onClick={() =>
                                    submitApplication({ id: info.id, name: info.fullName })
                                }
                            >
                            <span className="text">
                                {t("myApplications.applicationGeneralInformation.actionButtons.submit")+" "+getDeclination(generalType, "singular", "mianownik")}
                            </span>
                            </Button>}
                        {info.remoteVerification && !info.unverifyAlertSent && !info.incomplete && info.id_DictApplicationStatus === 2 &&
                            ((info.id_DictApplicationType === 3 && (info.instanceStatus === 2 || info.instanceStatus === 4)) ||
                                (info.id_DictApplicationType !== 3 && (info.instanceStatus === 3 || info.instanceStatus === 4)) ||
                                (info.sa && info.instanceStatus === 70)
                            ) && <Button
                                secondary
                                className="application-button-mobile"
                                onClick={() =>
                                    requestForWithdrawal({ id: info.id, name: info.fullName })
                                }
                            >
                            <span className="text">
                                {t("myAccount.submittedApplications.application.sendRequestForWithdrawal.title")}
                            </span>
                            </Button>}
                        {info.remoteVerification && info.unverifyAlertSent && !info.incomplete && info.id_DictApplicationStatus === 2 &&
                            ((info.id_DictApplicationType === 3 && (info.instanceStatus === 2 || info.instanceStatus === 4)) ||
                                (info.id_DictApplicationType !== 3 && (info.instanceStatus === 3 || info.instanceStatus === 4)) ||
                                (info.sa && info.instanceStatus === 70)
                            ) && <Button
                                secondary
                                className="application-button-mobile"
                                onClick={()=>
                                    removeRequestForWithdrawal({ id: info.id, name: info.fullName })
                            }
                            >
                            <span className="text">
                                {t("myAccount.submittedApplications.application.removeRequestForWithdrawal.title")}
                            </span>
                            </Button>}
                        <Button
                            className="application-button-mobile"
                            disabled={!info.isDownloadable}
                            onClick={downloadApplication}
                        >
                            <span className="text">
                                {t(
                                    "myApplications.applicationGeneralInformation.actionButtons.download"
                                )}{" "}
                                <span className="space">
                                    {getDeclination(generalType, "singular", "mianownik")}
                                </span>
                            </span>
                            <img src={DownloadIcon} alt="Download icon" />
                        </Button>
                    </Styled.ApplicationActionButtonsContainerMobile>
                </Styled.ApplicationInfoWrapperMobile>
            ))}
        </>
    );
};
