import React from 'react';

const InfoIcon = ({ ...props }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        {...props}
    >
        <g className='icon' id="Warstwa_1-2" data-name="Warstwa 1">
            <path id="Path_5898" data-name="Path 5898" className="cls-1" d="m9,5h2v2h-2v-2Zm0,4h2v6h-2v-6ZM10,0C4.48,0,0,4.48,0,10s4.48,10,10,10,10-4.48,10-10S15.52,0,10,0Zm0,18c-4.42,0-8-3.58-8-8S5.58,2,10,2s8,3.58,8,8c0,4.42-3.58,7.99-8,8Z"/>
        </g>
    </svg>
)

export default InfoIcon;