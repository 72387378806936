import { useState, memo, Fragment, useCallback } from 'react';
import { useTranslation } from "react-i18next";

import * as Styled from "./UnitsList.styled";

import { Link as TextLink } from "components/commons/Link/Link";

const UnitInstance = ({
    unit,
    features = [],
    branches = null,
    linkToUnitPrefix,
    setGeoModalUnit,
    unitInfo = null,
}) => {
    const { t } = useTranslation();
    const [isExpanded, setIsExpanded] = useState(false);

    const MobileShowGeoModalCell = memo(() => (
        <Styled.UnitFeatureCell onlyMobile>
            <Styled.UnitText isBold onlyMobile>
                {t("units:header.location")}
            </Styled.UnitText>
            <Styled.UnitText isBold toRight>
                <TextLink
                    onClick={() => setGeoModalUnit(unit)}
                >
                    {t("units:entry.map")}
                </TextLink>
            </Styled.UnitText>
        </Styled.UnitFeatureCell>
    ), []);

    const onShowMapModal = useCallback(() => {
        setGeoModalUnit(unit);
    }, []);

    const anyVisibleFeatures = (features) => {
        if (features?.length) {
            const any = features.some(function (feature) {
                return feature.value != '' || feature.value === 0;
            });
            return any;
        }
        return false;
    }

    return (
        <Styled.UnitItem
            featuresNumber={features?.length || 0}
            className='unit-item'
            isExpanded={isExpanded}
            hasBranches={branches?.length > 0}
        >
            <UnitNameAddress
                id={unit.id}
                name={unit.name}
                street={unit.street}
                houseNo={unit.houseNo}
                city={unit.city}
                district={unit.district}
                postalCode={unit.postalCode || ""}
                noBorder={!features || features?.length === 0}
                linkToUnitPrefix={linkToUnitPrefix}
                onShowMapModal={onShowMapModal}
            />
            {/* 
            THESE two divs (MobileExpandWrapper and MobileFeaturesWrapper) are added only for mobile purposes on desktop they are "omitted" in css using display: contents
            */}
            <Styled.MobileExpandWrapper
                className='mobile-expand-wrapper'
                isExpanded={isExpanded}
            >
                <Styled.MobileFeaturesWrapper
                    className='mobile-features-wrapper'
                >
                    <MobileShowGeoModalCell />
                    <Styled.UnitFeatureCell onlyMobile>
                        <Styled.UnitText isBold>
                            {anyVisibleFeatures(features) &&
                            t("units:header.additionalFeatures")+":"}
                        </Styled.UnitText>
                    </Styled.UnitFeatureCell>
                    {features?.map((feature, index) => {
                        return (
                            <FeatureCell
                                key={`unit-${unit.id}-feature-${feature.id}`}
                                id={feature.id}
                                value={feature.value}
                                label={feature.label}
                                confirmText={t("units:entry.confirm")}
                                isLastInRow={index === features.length - 1}
                                isBold={feature.isBold}
                            />
                        )
                    })}

                    {branches && (
                        <>
                            {branches.map((branch) => {
                                if (branch.isHidden) return null;
                                return (
                                    <Fragment key={branch.id}>
                                        <Styled.UnitFeatureTitle
                                            isBranch
                                            branchName
                                        >
                                            {branch.notLinked ? (
                                                <Styled.UnitText isBold>
                                                    {branch.name}
                                                </Styled.UnitText>
                                            ) : (
                                                <Styled.UnitTitle
                                                    to={`${linkToUnitPrefix}Oddzial/${branch.id}`}
                                                    state={ {unitId: unit.id, unitName: unit.name, branchName: branch.name} }
                                                >
                                                    {branch.name}
                                                </Styled.UnitTitle>
                                            )}

                                        </Styled.UnitFeatureTitle>
                                        {branch.features?.map((feature, index) => {
                                            return (
                                                <FeatureCell
                                                    key={`branch-${branch.id}-feature-${feature.id}`}
                                                    // feature={feature}
                                                    id={feature.id}
                                                    value={feature.value}
                                                    label={feature.label}
                                                    confirmText={t("units:entry.confirm")}
                                                    isLastInRow={index === branch.features.length - 1}
                                                />
                                            )
                                        })}
                                    </Fragment>
                                )
                            })}
                        </>
                    )}
                    {unitInfo && (
                        <Styled.UnitFeatureCell gridFullWidth>
                            <Styled.UnitText isBold>
                                {unitInfo}
                            </Styled.UnitText>
                        </Styled.UnitFeatureCell>
                    )}

                </Styled.MobileFeaturesWrapper>
            </Styled.MobileExpandWrapper>



            <Styled.Button
                onClick={() => setIsExpanded((val) => !val)}
                className='unit-show-button'
            >
                {isExpanded ? t("units:entry.collapse") : t("units:entry.expand")}
            </Styled.Button>
        </Styled.UnitItem>
    );
}
export default UnitInstance;

const FeatureCell = memo(({
    id,
    value,
    label,
    confirmText,
    isLastInRow,
    isBold
}) => (
    <Styled.UnitFeatureCell
        className='unit-feature-cell'
        isDetail
        key={id}
        noBorder={isLastInRow}
        isHiddenOnMobile={!value || value === ''}
        
    >
        <Styled.UnitText onlyMobile>
            {label}
        </Styled.UnitText>

        {typeof value === 'string' && (
            <Styled.UnitText toRight isBold={isBold}>
                {value}
            </Styled.UnitText>
        )}

        {typeof value === 'boolean' && value === true && (
            <Styled.UnitText isBold isWithCheck toRight>
                {confirmText}
            </Styled.UnitText>
        )}
    </Styled.UnitFeatureCell>
));

const UnitNameAddress = memo(({
    id,
    name,
    street,
    houseNo,
    city,
    district,
    postalCode,
    noBorder,
    linkToUnitPrefix,
    onShowMapModal,
}) => {
    const { t } = useTranslation();
    return (
        <>
            <Styled.UnitFeatureTitle
                noBorder={noBorder}
            >
                <Styled.UnitTitle
                    to={`${linkToUnitPrefix}${id}`}
                    state={{name: name}}
                >
                    {name}
                </Styled.UnitTitle>
                <Styled.UnitAddress>
                    <b>{t("units:entry.address")}:</b>{" "}
                    {street && (
                        <>
                            {`${street} ${houseNo},`}{" "}
                            <span>{`${postalCode} ${city}`}</span>
                        </>
                    )}
                </Styled.UnitAddress>

                {district && (
                    <Styled.UnitInfo onlyDesktop>
                        <b>{t("units:entry.district")}:</b> {district}
                    </Styled.UnitInfo>
                )}
                <Styled.UnitInfo isMargin onlyDesktop>
                    <b>{t("units:entry.location")}:</b>{" "}
                    <TextLink
                        onClick={onShowMapModal}
                    >
                        {t("units:entry.map")}
                    </TextLink>
                </Styled.UnitInfo>
            </Styled.UnitFeatureTitle>
        </>
    )
});