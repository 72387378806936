import { useState, useRef } from "react";

import { useTranslation } from "react-i18next";
import {
  SectionTextContainer,
  SectionExpander,
  ExpanderText,
  ExpandBt,
} from "./Unit.styles";
import galleryExpandIcon from "assets/images/gallery-expand.svg";

export const UnitHtmlSection = ({ children }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const textRef = useRef();

  const { t } = useTranslation();

  return (
    <>
      <SectionTextContainer
        isLarge={textRef?.current?.offsetHeight > 250}
        isExpanded={isExpanded}
      >
        <span ref={textRef}>{children}</span>
      </SectionTextContainer>

      {textRef?.current?.offsetHeight > 250 && (
        <SectionExpander>
          <ExpanderText>
            {!isExpanded ? (
              <>{t("units:showMore")}</>
            ) : (
              <>{t("units:showLess")}</>
            )}
          </ExpanderText>
          <ExpandBt
            onClick={() => setIsExpanded((state) => !state)}
            expanded={isExpanded}
            src={galleryExpandIcon}
            alt="section-expand button"
          />
        </SectionExpander>
      )}
    </>
  );
};
