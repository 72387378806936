import { useMemo } from "react";
import BaseSection from './BaseSection';
import SummarySection from './SummarySection';
import QuestionsSection from './QuestionsSection';
import PreferencesSection from './PreferencesSection';
import PredefinedSchool from './PredefinedSchool';

const sections = {
    BaseSection,
    SummarySection,
    QuestionsSection,
    PreferencesSection,
    PredefinedSchool,
}

const SectionFactory = ({ section }) => {
    const Section = useMemo(() => 
        sections[section.type] || sections['BaseSection']
    , [section.type]);

    return (
        <Section 
            data={section}
        />
    )
}

export default SectionFactory;