import Enum from "./Enum";

export const UnitTypes = {
	Nursery: "zlobek",
	Kindergarten: "przedszkole",
	PrimarySchool: "szkolapodstawowa",
	SecondarySchool: "szkolaponadpodstawowa",
	PostSecondarySchool: "szkolapolicealna",
	TradeSchool: "szkolabranzowa2",
	Dormitory: "bursa",
	HolidayDuty: "dyzurywakacyjne",
	Class4: "klasa4",
	Class6: "klasa6",
	Class7: "klasa7",
	Semicolony: "polkolonie",
	Center: "bcu",
    Culture: "domkultury"
};

export const UnitGeneralTypes = {
	Nursery: 1,
	Kindergarten: 2,
	PrimarySchool: 3,
	TradeSchool: 5,
	PostSecondarySchool: 6,
	Dormitory: 7,
	Class4: 21,
	Class6: 22,
	Class7: 8,
	SecondarySchool: 9,
	HolidayDuty: 10,
	Semicolony: 11,
	Center: 13,
    Culture: 14
};

export const DetailedUnitTypes = {
	Kindergarten: 1,
	PrimarySchool: 3,
	TradeSchool: 13,
	SecondarySchool: 14,
	SpecialisedSecondarySchool: 15,
	TechnicalSchool: 16,
	SchoolComplex: 100,
};

export const UnitInstanceStatus = {
	NotIncluded: 0,
	InPreparation: 1,
	ToBeApproved: 5,
	Approved: 10,
	Hidden: 15,
};

export const DictUnitType = {
	Przedszkole: 1,
	Szkola_podstawowa: 3,
	Gimnazjum: 4,
	Branzowa_szkoła_I_stopnia: 13,
	Liceum_ogolnoksztalcace: 14,
	Liceum_profilowane: 15,
	Technikum: 16,
	Liceum_ogolnoksztalcace_uzupelniajace_dla_absolwentow_zasadniczych_szkol_zawodowych: 17,
	Technikum_uzupelniajace_dla_absolwentow_zasadniczych_szkol_zawodowych: 18,
	Szkola_policealna: 19,
	Szkola_specjalna_przysposabiajaca_do_pracy_dla_uczniow_z_uposledzeniem_umyslowym_w_stopniu_umiarkowanym_lub_znacznym: 20,
	Szescioletnia_ogolnoksztalcaca_szkola_muzyczna_I_stopnia: 21,
	Szescioletnia_szkola_muzyczna_I_stopnia: 22,
	Czteroletnia_szkola_muzyczna_I_stopnia: 23,
	Szescioletnia_ogolnoksztalcaca_szkola_muzyczna_II_stopnia: 24,
	Szescioletnia_szkola_muzyczna_II_stopnia: 25,
	Szescioletnia_ogolnoksztalcaca_szkola_sztuk_pieknych: 26,
	Czteroletnie_liceum_plastyczne: 27,
	Dziewiecioletnia_ogolnoksztalcaca_szkola_baletowa: 29,
	Szescioletnia_szkola_sztuki_tanca: 30,
	Czteroletnia_szkola_sztuki_cyrkowej: 31,
	Policealna_szkola_artystyczna: 32,
	Szkola_pomaturalna_bibliotekarska: 33,
	Kolegium_nauczycielskie: 34,
	Nauczycielskie_Kolegium_Jezykow_Obcych: 35,
	Osrodek_politechniczny: 36,
	Palac_mlodziezy: 37,
	Mlodziezowy_dom_kultury: 38,
	Ognisko_pracy_pozaszkolnej: 39,
	Miedzyszkolny_osrodek_sportowy: 40,
	Ogrod_jordanowski: 41,
	Pozaszkolna_placowka_specjalistyczna: 42,
	Szkolne_schronisko_mlodziezowe: 43,
	Placowki_artystyczne: 44,
	Centrum_Ksztalcenia_Praktycznego: 45,
	Centrum_Ksztalcenia_Ustawicznego_ze_szkolami: 46,
	Osrodek_doksztalcania_i_doskonalenia_zawodowego: 47,
	Poradnia_psychologiczno_pedagogiczna: 48,
	Poradnia_specjalistyczna: 49,
	Specjalny_Osrodek_Wychowawczy: 50,
	Specjalny_Osrodek_Szkolno_Wychowawczy: 51,
	Osrodek_Rewalidacyjno_Wychowawczy: 52,
	Mlodziezowy_Osrodek_Wychowawczy: 53,
	Mlodziezowy_Osrodek_Socjoterapii_ze_szkolami: 54,
	Bursa: 55,
	Dom_wczasow_dzieciecych: 56,
	Placowka_doskonalenia_nauczycieli: 57,
	Biblioteki_pedagogiczne: 58,
	Publiczna_placowka_opiekunczo_wychowawcza_w_systemie_pomocy_spolecznej: 59,
	Zaklad_poprawczy: 60,
	Schronisko_dla_nieletnich: 61,
	Rodzinny_osrodek_diagnostyczno_konsultacyjny: 62,
	Publiczny_osrodek_adopcyjno_opiekunczy: 63,
	Niepubliczna_placowka_oswiatowo_wychowawcza_w_systemie_oswiaty: 64,
	Kolegium_Pracownikow_Sluzb_Spolecznych: 65,
	Szkola_pomaturalna_animatorow_kultury: 66,
	Delegatura: 67,
	Internat: 68,
	Czteroletnia_szkola_muzyczna_II_stopnia: 69,
	Dziewiecioletnia_szkola_sztuki_tanca: 70,
	Szkola_specjalna_przysposabiajaca_do_pracy: 73,
	Centrum_Ksztalcenia_Ustawicznego_bez_szkol: 74,
	Niepubliczna_placowka_ksztalcenia_ustawicznego_i_praktycznego: 75,
	Mlodziezowy_Osrodek_Socjoterapii_bez_szkol: 76,
	Zespol_wychowania_przedszkolnego: 80,
	Punkt_przedszkolny: 81,
	Poznanska_szkola_choralna_: 82,
	Niepubliczna_placowka_ksztalcenia_ustawicznego_i_praktycznego_ze_szkolami: 83,
	Zlobek: 84,
	Klub_dzieciecy: 85,
	Dzienny_opiekun: 86,
	Zlobek_integracyjny: 87,
	Zlobek_projektowy: 88,
	Zlobek_projektowy2: 89,
	Branzowa_szkoła_II_stopnia: 90,
	Zespol_szkol_i_placowek_oswiatowych: 100,
	Minister: 101,
	Kuratorium_oswiaty_wraz_z_delegaturami: 102,
	Jednostka_Samorzadu_Terytorialnego: 103,
	ZEAS: 104,
	Specjalistyczna_jednostka_nadzoru: 105,
	Organ_sprawujacy_nadzor_pedagogiczny_nad_zakladami_poprawczymi_schroniskami_dla_nieletnich_rodzinnymi_osrodkami_diagnostyczno_konsultacyjnymi_oraz_szkolami_przy_zakladach_karnych: 106,
	Centralna_Komisja_Egzaminacyjna: 107,
	Okregowa_Komisja_Egzaminacyjna: 108,
	Zlobek_projektowy3: 109,
	Zlobek_projektowy4: 110,
	Oddzial_Przedszkolny: 301,
	Eksperymentalna_Szkola_Podstawowa: 503,
};

export const DictUnitTypeExt = {
	Przedszkole: new Enum(1,'Przedszkole'),
	Szkola_podstawowa: new Enum(3,'Szkoła podstawowa'),
	Gimnazjum: new Enum(4,'Gimnazjum'),
	Branzowa_szkoła_I_stopnia: new Enum(13,'Branżowa szkoła I stopnia'),
	Liceum_ogolnoksztalcace: new Enum(14,'Liceum ogólnokształcące'),
	Liceum_profilowane: new Enum(15,'Liceum profilowane'),
	Technikum: new Enum(16,'Technikum'),
	Liceum_ogolnoksztalcace_uzupelniajace_dla_absolwentow_zasadniczych_szkol_zawodowych: new Enum(17,'Liceum ogólnokształcące uzupelniajace dla absolwentow zasadniczych szkol zawodowych'),
	Technikum_uzupelniajace_dla_absolwentow_zasadniczych_szkol_zawodowych: new Enum(18,'Technikum uzupełniajace dla absolwentów zasadniczych szkol zawodowych'),
	Szkola_policealna: new Enum(19,'Szkoła policealna'),
	Szkola_specjalna_przysposabiajaca_do_pracy_dla_uczniow_z_uposledzeniem_umyslowym_w_stopniu_umiarkowanym_lub_znacznym: new Enum(20,'Szkoła specjalna przysposabiajaca do pracy dla uczniow z uposledzeniem umyslowym w stopniu umiarkowanym lub znacznym'),
	Szescioletnia_ogolnoksztalcaca_szkola_muzyczna_I_stopnia: new Enum(21,'Sześcioletnia ogolnoksztalcaca szkoła muzyczna I stopnia'),
	Szescioletnia_szkola_muzyczna_I_stopnia: new Enum(22,'Sześcioletnia szkoła muzyczna I stopnia'),
	Czteroletnia_szkola_muzyczna_I_stopnia: new Enum(23,'Czteroletnia szkoła muzyczna I stopnia'),
	Szescioletnia_ogolnoksztalcaca_szkola_muzyczna_II_stopnia: new Enum(24,'Sześcioletnia ogolnoksztalcaca szkoła muzyczna II stopnia'),
	Szescioletnia_szkola_muzyczna_II_stopnia: new Enum(25,'Sześcioletnia szkoła muzyczna II stopnia'),
	Szescioletnia_ogolnoksztalcaca_szkola_sztuk_pieknych: new Enum(26,'Sześcioletnia ogolnoksztalcaca szkoła sztuk pieknych'),
	Czteroletnie_liceum_plastyczne: new Enum(27,'Czteroletnie liceum plastyczne'),
	Dziewiecioletnia_ogolnoksztalcaca_szkola_baletowa: new Enum(29,'Dziewiecioletnia ogolnoksztalcaca szkoła baletowa'),
	Szescioletnia_szkola_sztuki_tanca: new Enum(30,'Sześcioletnia szkoła sztuki tanca'),
	Czteroletnia_szkola_sztuki_cyrkowej: new Enum(31,'Czteroletnia szkoła sztuki cyrkowej'),
	Policealna_szkola_artystyczna: new Enum(32,'Policealna szkoła artystyczna'),
	Szkola_pomaturalna_bibliotekarska: new Enum(33,'Szkoła pomaturalna bibliotekarska'),
	Kolegium_nauczycielskie: new Enum(34,'Kolegium nauczycielskie'),
	Nauczycielskie_Kolegium_Jezykow_Obcych: new Enum(35,'Nauczycielskie Kolegium Jezykow Obcych'),
	Osrodek_politechniczny: new Enum(36,'Ośrodek politechniczny'),
	Palac_mlodziezy: new Enum(37,'Pałac młodziezy'),
	Mlodziezowy_dom_kultury: new Enum(38,'Młodzieżowy dom kultury'),
	Ognisko_pracy_pozaszkolnej: new Enum(39,'Ognisko pracy pozaszkolnej'),
	Miedzyszkolny_osrodek_sportowy: new Enum(40,'Międzyszkolny ośrodek sportowy'),
	Ogrod_jordanowski: new Enum(41,'Ogród jordanowski'),
	Pozaszkolna_placowka_specjalistyczna: new Enum(42,'Pozaszkolna placowka specjalistyczna'),
	Szkolne_schronisko_mlodziezowe: new Enum(43,'Szkolne schronisko młodziezowe'),
	Placowki_artystyczne: new Enum(44,'Placówka artystyczna'),
	Centrum_Ksztalcenia_Praktycznego: new Enum(45,'Centrum Kształcenia Praktycznego'),
	Centrum_Ksztalcenia_Ustawicznego_ze_szkolami: new Enum(46,'Centrum Kształcenia Ustawicznego ze szkolami'),
	Osrodek_doksztalcania_i_doskonalenia_zawodowego: new Enum(47,'Ośrodek dokształcania i doskonalenia zawodowego'),
	Poradnia_psychologiczno_pedagogiczna: new Enum(48,'Poradnia psychologiczno pedagogiczna'),
	Poradnia_specjalistyczna: new Enum(49,'Poradnia specjalistyczna'),
	Specjalny_Osrodek_Wychowawczy: new Enum(50,'Specjalny Ośrodek Wychowawczy'),
	Specjalny_Osrodek_Szkolno_Wychowawczy: new Enum(51,'Specjalny Ośrodek Szkolno-Wychowawczy'),
	Osrodek_Rewalidacyjno_Wychowawczy: new Enum(52,'Ośrodek Rewalidacyjno-Wychowawczy'),
	Mlodziezowy_Osrodek_Wychowawczy: new Enum(53,'Młodzieżowy Osrodek Wychowawczy'),
	Mlodziezowy_Osrodek_Socjoterapii_ze_szkolami: new Enum(54,'Młodzieżowy Ośrodek Socjoterapii ze szkołami'),
	Bursa: new Enum(55,'Bursa'),
	Dom_wczasow_dzieciecych: new Enum(56,'Dom wczasów dziecięcych'),
	Placowka_doskonalenia_nauczycieli: new Enum(57,'Placówka doskonalenia nauczycieli'),
	Biblioteki_pedagogiczne: new Enum(58,'Biblioteki pedagogiczne'),
	Publiczna_placowka_opiekunczo_wychowawcza_w_systemie_pomocy_spolecznej: new Enum(59,'Publiczna placówka opiekuńczo-wychowawcza w systemie pomocy społecznej'),
	Zaklad_poprawczy: new Enum(60,'Zakład poprawczy'),
	Schronisko_dla_nieletnich: new Enum(61,'Schronisko dla nieletnich'),
	Rodzinny_osrodek_diagnostyczno_konsultacyjny: new Enum(62,'Rodzinny ośrodek diagnostyczno konsultacyjny'),
	Publiczny_osrodek_adopcyjno_opiekunczy: new Enum(63,'Publiczny ośrodek adopcyjno-opiekunczy'),
	Niepubliczna_placowka_oswiatowo_wychowawcza_w_systemie_oswiaty: new Enum(64,'Niepubliczna placówka oswiatowo-wychowawcza w systemie oswiaty'),
	Kolegium_Pracownikow_Sluzb_Spolecznych: new Enum(65,'Kolegium Pracownikow Słuzb Spolecznych'),
	Szkola_pomaturalna_animatorow_kultury: new Enum(66,'Szkoła pomaturalna animatorów kultury'),
	Delegatura: new Enum(67,'Delegatura'),
	Internat: new Enum(68,'Internat'),
	Czteroletnia_szkola_muzyczna_II_stopnia: new Enum(69,'Czteroletnia szkoła muzyczna II stopnia'),
	Dziewiecioletnia_szkola_sztuki_tanca: new Enum(70,'Dziewięcioletnia szkoła sztuki tanca'),
	Szkola_specjalna_przysposabiajaca_do_pracy: new Enum(73,'Szkoła specjalna przysposabiajaca do pracy'),
	Centrum_Ksztalcenia_Ustawicznego_bez_szkol: new Enum(74,'Centrum Kształcenia Ustawicznego bez szkol'),
	Niepubliczna_placowka_ksztalcenia_ustawicznego_i_praktycznego: new Enum(75,'Niepubliczna placówka kształcenia ustawicznego i praktycznego'),
	Mlodziezowy_Osrodek_Socjoterapii_bez_szkol: new Enum(76,'Młodzieżowy Ośrodek Socjoterapii bez szkol'),
	Zespol_wychowania_przedszkolnego: new Enum(80,'Zespół wychowania przedszkolnego'),
	Punkt_przedszkolny: new Enum(81,'Punkt przedszkolny'),
	Poznanska_szkola_choralna_: new Enum(82,'Poznańska szkoła choralna'),
	Niepubliczna_placowka_ksztalcenia_ustawicznego_i_praktycznego_ze_szkolami: new Enum(83,'Niepubliczna placówka kształcenia ustawicznego i praktycznego ze szkolami'),
	Zlobek: new Enum(84,'Żłobek'),
	Klub_dzieciecy: new Enum(85,'Klub dziecięcy'),
	Dzienny_opiekun: new Enum(86,'Dzienny opiekun'),
	Zlobek_integracyjny: new Enum(87,'Żłobek integracyjny'),
	Zlobek_projektowy: new Enum(88,'Żłobek projektowy'),
	Zlobek_projektowy2: new Enum(89,'Żłobek projektowy'),
	Branzowa_szkoła_II_stopnia: new Enum(90,'Branżowa szkoła II stopnia'),
	Zespol_szkol_i_placowek_oswiatowych: new Enum(100,'Zespół szkół i placówek oświatowych'),
	Minister: new Enum(101,'Jednostka ministerialna'),
	Kuratorium_oswiaty_wraz_z_delegaturami: new Enum(102,'Kuratorium oświaty wraz z delegaturami'),
	Jednostka_Samorzadu_Terytorialnego: new Enum(103,'Jednostka Samorządu Terytorialnego'),
	ZEAS: new Enum(104,'ZEAS'),
	Specjalistyczna_jednostka_nadzoru: new Enum(105,'Specjalistyczna jednostka nadzoru'),
	Organ_sprawujacy_nadzor_pedagogiczny_nad_zakladami_poprawczymi_schroniskami_dla_nieletnich_rodzinnymi_osrodkami_diagnostyczno_konsultacyjnymi_oraz_szkolami_przy_zakladach_karnych: new Enum(106,'Organ sprawujacy nadzor pedagogiczny nad zakladami poprawczymi schroniskami dla nieletnich rodzinnymi osrodkami diagnostyczno konsultacyjnymi oraz szkolami przy zakladach karnych'),
	Centralna_Komisja_Egzaminacyjna: new Enum(107,'Centralna Komisja Egzaminacyjna'),
	Okregowa_Komisja_Egzaminacyjna: new Enum(108,'Okręgowa Komisja Egzaminacyjna'),
	Zlobek_projektowy3: new Enum(109,'Żłobek projektowy'),
	Zlobek_projektowy4: new Enum(110,'Żłobek projektowy'),
	Oddzial_Przedszkolny: new Enum(301,'Oddział Przedszkolny'),
	Eksperymentalna_Szkola_Podstawowa: new Enum(503,'Eksperymentalna Szkoła Podstawowa'),
};


export const InstanceStatus = {
	Poczatkowy: 1,
	Zbieranie_wnioskow: 2,
	Zbieranie_deklaracji: 3,
	Zbieranie_wnioskow_i_deklaracji: 4,
	Koniec_przyjec_publicznych: 15,
	Zakonczono_zbieranie: 20,
	Konklawe: 50,
	Reczna_kwalifikacja: 51,
	Zatwierdzone_listy_zakwalifikowanych: 55,
	Ogloszenie_kwalifikacji: 58,
	Przyjmowanie_dzieci: 60,
	Ogloszenie_przyjetych: 65,
	Rekrutacja_uzupelniajaca: 70,
	Koniec_przyjec_publicznych_RU: 71,
	Zakonczono_zbieranie_RU: 72,
	Reczna_kwalifikacja_RU: 73,
	Konklawe_uzupeniajace: 75,
	Zatwierdzone_listy_zakwalifikowanych_RU: 78,
	Ogloszenie_kwalifikacji_RU: 80,
	Przyjmowanie_dzieci_RU: 82,
	Ogloszenie_przyjetych_RU: 85,
	Koniec: 100,
};

export const instanceBannerDict = {
    [UnitTypes.Nursery]: "banner4-m.png",
    [UnitTypes.Kindergarten]: "banner5-m.png",
    [UnitTypes.PrimarySchool]: "banner16-m.png",
    [UnitTypes.TradeSchool]: "banner7-m.png",
    [UnitTypes.PostSecondarySchool]: "banner15-m.png",
    [UnitTypes.Dormitory]: "banner8-m.png",
    [UnitTypes.Class4]: "banner10-m.png",
	[UnitTypes.Class6]: "banner10-m.png",
    [UnitTypes.Class7]: "banner11-m.png",
    [UnitTypes.SecondarySchool]: "banner6-m.png",
    [UnitTypes.HolidayDuty]: "banner17-m.png",
    [UnitTypes.Semicolony]: "banner9-m.png",
	[UnitTypes.Center]: "banner13-m.jpg",
    [UnitTypes.Culture]: "banner14-m.jpg",
  };


export const GroupKind = {
	Start: 0, //regulamin lub oświadczenia
	Personal: 1, //dane osobowe
	Chooser: 2, //wybór bieżącego przedszkola lub szkoly obwodowej
	PreQuestions: 3, //grupa pytań do umieszczenia przed wyborem preferencji
	Preferences: 4, //zakładka z wyborem preferencji
	Questions: 5, // grupa pytań umieszczana po wyborze preferencji
	Summary: 6 //ostatnia strony, z zapisem podania, zgodami etc.
}

export const SectionType =
	{
		None: 'None',
		Regulations: 'Regulations',
		Statements: 'Statements',
		PredefinedSchool: 'PredefinedSchool'
	}

export const HighSchoolDisplayMode =
{
	/// <summary>
	/// Nieużywana zaślepka
	/// </summary>
	Unknown: 0,
	/// <summary>
	/// 1 - liczba wolnych miejsc w oddziale
	/// </summary>
	FreePlacesInSchoolBranch: 1,
	/// <summary>
	/// 2 - liczba  wolnych miejsc w placówce
	/// </summary>
	FreePlacesInUnit: 2,
	/// <summary>
	/// 4 - liczba  wniosków 1 pref w oddziale
	/// </summary>
	CountOfApplicationsOnFirstPreferenceInSchoolBranch: 4,
	/// <summary>
	/// 8 - liczba  wniosków 1 pref w placówce
	/// </summary>
	CountOfApplicationsOnFirstPreferenceInUnit: 8,
	/// <summary>
	/// 16 - liczba  wniosków wszystkich  w oddziale
	/// </summary>
	CountOfAllApplicationsInSchoolBranch: 16,
	/// <summary>
	/// 32 - liczba  wniosków wszystkich  w placówce
	/// </summary>
	CountOfAllApplicationsInUnit: 32
}

export const FieldType =
{
	radio:'radio',
	input:'input',
	select:'select',
	textarea:'textarea'
}
export const ComponentEnumType =
{
	text: 'text',
		pesel: 'pesel',
		postal: 'postal',
		date: 'date',
		checkbox: 'checkbox',
		email: 'email',
		tel: 'tel',
		teryt: 'teryt',
		hour: 'hour',
		time: 'time',
		integer: 'integer',
		number: 'number',
		bigint: 'bigint',
		boolean: 'boolean',
		userType: 'userType',
		unit: 'unit',
		unitFromInstance: 'unitFromInstance',
		unitFromRegion: 'unitFromRegion',
		language: 'language',
		profession: 'profession'
}

export const KindergartenAgeCodes = {
    6015: [6,7,8,9,10,11,12],
    6101: [3],
    6102: [4],
    6103: [5],
    6104: [6],
    6107: [3, 4],
    6108: [4, 5],
    6109: [5, 6],
    6110: [6, 7]
}