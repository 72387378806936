import { useMemo, memo } from 'react';
import styled, { css } from 'styled-components';

const Error = ({ error, style, position = 'bottom' }) => {
    const errorMessage = useMemo(() => {
        if (error) {
            if (typeof error === "string") {
                return error;
            } else {
                return error?.message || null;
            }
        }
        return null;
    }, [error]);
    if (!errorMessage) return null;
    return (
        <ErrorWrapper 
            className='error-wrapper'
            position={position}
        >
            <ErrorContent
                style={style || {}}
                className='error-text'
                position={position}
            >
                {errorMessage}
            </ErrorContent>
        </ErrorWrapper>
    )
};
export default memo(Error);


const ErrorContent = styled.div(({ theme, position }) => css`
    font-size: 1.4rem;
    font-weight: 500;
    color: ${theme.colors.warning};
    position: absolute;
    background: white;
    border: 1px solid #AAAAAA;
    border-radius: 0.5rem;
    padding: 0.5rem;
    left: 0.5rem;
    z-index: 90;
    width: max-content;
    max-width: min(80dvw, 40rem);
    box-shadow: 0px 3px 6px #00000029;
    top: -4px;

    /* &::before {
        content: "";
        position: absolute;
        width: 0;
        height: 0;
        border-width: 1rem;
        border-style: solid;
        border-color: transparent transparent #AAAAAA transparent;
        top: -2rem;
        left: 2rem;
    }
    &::after {
        content: "";
        position: absolute;
        width: 0px;
        height: 0px;
        border-width: 1rem;
        border-style: solid;
        border-color: transparent transparent white transparent;
        top: -1.8rem;
        left: 2rem;
    }
    
    ${position === 'top' && css`
        &::before {
            border-color: #AAAAAA transparent transparent transparent;
            top: auto;
            left: auto;
            bottom: -2rem;
            right: 2rem;
        }
        &::after {
            border-color: white transparent transparent transparent;
            top: auto;
            left: auto;
            bottom: -1.8rem;
            right: 2rem;
        }
    `} */
`);

const ErrorWrapper = styled.div( ({position}) => css`
    position: relative;
    /* ${position === 'top' && css`
        position: absolute;
        top: -2rem;
        right: 0;
    `} */
`);
