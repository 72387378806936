import React, { useEffect, useContext, useState } from "react";
import { useTranslation, Trans } from "react-i18next";

import Breadcrumbs from "components/commons/Breadcrumbs/Breadcrumbs";
import Collapse from "components/commons/Collapse/Collapse";

import { AppContext } from "context/appContext";

import StaticPage from "components/reusable/StaticPage/StaticPage";

import Banner from "components/commons/Banner/Banner";

import * as Styled from "./AccessDeclaration.styles";
import parse from "html-react-parser";

const AccessDeclaration = () => {
  const { t } = useTranslation();
  const { setBreadcrumbsPath } = useContext(AppContext);
  const data = t("accessDeclaration:points", { returnObjects: true });

  useEffect(() => {
    setBreadcrumbsPath([
      {
        name: t("breadcrumbs.accessDeclaration"),
      },
    ]);
  }, []);

  return (
    <>
      <Breadcrumbs />
      <Banner />
      <StaticPage title={t("menu.accessDeclaration")}>
        <Styled.AccessDeclaration>
          {data.length > 0 &&
            data.map((item) => (
              <Collapse title={item.title} key={item.title} id={item.title}>
                <Styled.AnswerText>
                    {parse(item.content)}
                </Styled.AnswerText>
              </Collapse>
            ))}
        </Styled.AccessDeclaration>
      </StaticPage>
    </>
  );
};

export default AccessDeclaration;
