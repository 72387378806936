export const stringToIso = (stringDate = '') => {
    if (stringDate.length === 0) {
        return '';
    }
    if ((/_/).test(stringDate) || stringDate.length < 10) {
        return '00';
    }
    if (stringDate.match(/^\d{2}\.\d{2}\.\d{4}$/)) {
        const [day, month, year] = stringDate.split('.');
        if (year && month && day) {
            return `${year}-${month}-${day}`;
        }
        return '0000-00-00';
    }
    return '0000-00-00';
}

export const isoToString = (isoDate) => {
    if (typeof isoDate === 'string') {
        const [year, month, day] = isoDate.split('-');
        if (year && month && day && year !== '0000') {
            return `${day}.${month}.${year}`;
        }
        return null;
    }    
    return null;
}

export const parseDateToString = (date) => {
    const isoDate = isoToString(parseDateToIso(date));
    return isoDate;
}

export const parseDateToIso = (date) => {
    if (isNaN(new Date(date).getFullYear())) {
        // wrong incoming date;
        return null;
    }
    // if (!date || date === Invalid Date) {
    //     console.log("date: ", date);
    //     return null;
    // }
    const isoDate = new Date(date.getTime() - (date.getTimezoneOffset() * 60000)).toISOString();
    return isoDate.split('T')[0];
};