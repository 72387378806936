import styled, { css } from "styled-components";

import { H2 as CommonH2 } from "styles/common.styles";
import { H3 as CommonH3 } from "styles/common.styles";
import { H4 as CommonH4 } from "styles/common.styles";
import { H5 as CommonH5 } from "styles/common.styles";
import { H6 as CommonH6 } from "styles/common.styles";

import { Button as CommonButton } from "components/commons";
import { Link } from "react-router-dom";

import arrowIcon from "assets/icons/ico-preferences-mobile.svg";

export const H2 = styled(CommonH2)`
  font-size: 2.4rem;
  color: ${({ theme }) => theme.colors.black};
  margin-top: 2rem;
`;

export const H3 = styled(CommonH3)`
  font-size: 2rem;
  margin: 4rem 0;
  color: ${({ theme }) => theme.colors.black};
`;

export const H4 = styled(CommonH4)`
  font-size: 1.8rem;
  color: ${({ theme }) => theme.colors.main};

  &.application-id-mobile {
    color: ${({ theme }) => theme.colors.white};
    font-weight: 400;
  }
`;

export const H5 = styled(CommonH5)`
  font-size: 1.8rem;
  color: ${({ theme }) => theme.colors.black};
  &.children-overview-title {
    margin-bottom: 5rem;
  }

  &.application-information-title {
    font-size: 2.7rem;
  }

  &.application-title-mobile {
    font-size: 1.6rem;
    color: ${({ theme }) => theme.colors.black};
  }
`;

export const H6 = styled.h6`
  font-size: 2rem;
  color: ${({ theme }) => theme.colors.main};

  &.application-information-subtitle {
    font-size: 2.2rem;
    color: ${({ theme }) => theme.colors.black};
    margin-bottom: 2rem;
  }
`;

export const ApplicationPanelTab = styled.div`
  cursor: pointer;
  background-color: ${({ theme }) => theme.colors.blueTabBackground};
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  margin-bottom: -2px;

  /* border-top: 2px solid transparent;
  border-right: 2px solid ${({ theme }) => theme.colors.main};
  border-left: 2px solid ${({ theme }) => theme.colors.main}; */
  border: 2px solid transparent;
  border-bottom: none;

  ${({ isActive }) =>
    isActive &&
    css`
      background-color: ${({ theme }) => theme.colors.white};
      color: ${({ theme }) => theme.colors.blackTabText};
      /* border-top: 2px solid ${({ theme }) => theme.colors.main};
      border-right: 2px solid ${({ theme }) => theme.colors.main};
      border-left: 2px solid ${({ theme }) => theme.colors.main}; */
      border-color: ${({ theme }) => theme.colors.main};
      border-bottom-left-radius: 2px;
      border-bottom-right-radius: 2px solid ${({ theme }) => theme.colors.white};
      z-index: 1;
      margin-bottom: -2px;
    `}

  /* .tabe {
    padding: 2rem 3.5rem;
    font-size: 1.8rem;
    font-weight: 600;

    &.application-panel-tab_incomplete {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 2rem;

      ${({ isActive }) =>
        isActive &&
        css`
          color: ${({ theme }) => theme.colors.white};
          background-color: ${({ theme }) => theme.colors.accent};
          border-top: 2px solid ${({ theme }) => theme.colors.accent};
          border-right: 2px solid ${({ theme }) => theme.colors.accent};
          border-left: 2px solid ${({ theme }) => theme.colors.accent};
          border-radius: 5px;
          padding-bottom: 1.8rem;
        `}
    }
  } */

  @media ${({ theme: { device } }) => device.tablet} {
    padding: 1rem;
  }
`;

export const ApplicationPanelTabInner = styled.div(({ 
    theme: { colors }, incomplete, isActive
    }) => css`
    padding: 2rem 3.5rem;
    font-size: 1.8rem;
    font-weight: 600;
    ${ incomplete && css`
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 2rem;
    `}

    ${ isActive && css`
        color: ${colors.white};
        background-color: ${colors.accent};
        border-top: 2px solid ${colors.accent};
        border-right: 2px solid ${colors.accent};
        border-left: 2px solid ${colors.accent};
        border-radius: 5px;
        padding-bottom: 1.8rem;
    `}

`);

export const ApplicationInformationPanel = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: flex-start;
  margin: 5rem 0;
  width: 100%;
`;

export const ApplicationInformationPanelWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 5rem 0;
  width: 100%;
`;

export const ApplicationInformationPanelTabContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 2rem;
  margin-bottom: 0;
  width: calc(100% - 2rem);
`;

export const ApplicationInfoWrapper = styled.div`
  margin: 5rem;
  display: flex;
  flex-direction: column;
  gap: 5rem;
  table {
    width: 100%;
    padding: 1rem;
    border-collapse: collapse;

    thead {
      tr {
        color: ${({ theme }) => theme.colors.black};
        font-size: 1.8rem;
        padding: 1rem;

        th {
          padding: 1rem;
          border-bottom: 2px solid
            ${({ theme }) => theme.colors.borderChildrenOverview};
          border-top: 2px solid
            ${({ theme }) => theme.colors.borderChildrenOverview};
        }

        .school-title,
        .status-title {
          text-align: left;
        }
        .pref-title {
          padding-left: 3rem;
        }
      }
    }

    tbody {
      tr {
        color: ${({ theme }) => theme.colors.black};
        font-size: 1.8rem;
        padding: 1rem;
        justify-items: center;

        td {
          padding: 3rem 1rem;
          font-weight: 600;
          border-bottom: 1px solid
            ${({ theme }) => theme.colors.borderChildrenOverview};
        }
        &:last-child td {
          border-bottom: none;
        }

        .list-index {
          justify-content: center;
          text-align: center;
        }

        .school-name,
        .school-status {
          text-align: left;
        }

        .school-date,
        .school-points {
          text-align: center;
        }
      }
    }
  }
`;

export const ApplicationInformationContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: space-between;
  margin: 2rem 0;
  border: 2px solid ${({ theme }) => theme.colors.main};
  border-radius: 0 10px 10px 10px;
  width: 100%;
  height: auto;
  margin-top: 0;
  padding-top: 0;

  @media ${({ theme: { device } }) => device.tablet} {
    height: auto;
  }
`;

export const ApplicationContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 5rem;
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderChildrenOverview};
`;

export const ApplicationContentInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const ApplicationContentInfoParagraph = styled.p`
  font-size: 1.8rem;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.black};
  margin: 0.5rem 0;

  &::first-letter {
    text-transform: uppercase;
  }

  &.application-attachment-name {
    border-top: 1px solid ${({ theme }) => theme.colors.borderChildrenOverview};
    border-bottom: 1px solid
      ${({ theme }) => theme.colors.borderChildrenOverview};
    padding: 2rem 0;
    font-size: 2.2rem;
  }

  &.application-attachment-info {
    font-weight: 500;
  }

  &.status-alert {
    display: flex;
    gap: 0.5rem;
  }
`;

export const StatusLink = styled(Link)`
  font-size: 1.8rem;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.main};
  text-decoration: none;
  `;

export const GeneralInfoText = styled.span`
  font-size: 1.8rem;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.black};
  `;

export const StatusText = styled.span`
  color: ${({ theme }) => theme.colors.black};

  &.status-apply {
    color: ${({ theme }) => theme.colors.warning};
  }

  &.status-mobile {
    font-weight: 600;
  }

  &.status-preferences-mobile {
    font-weight: 400;
    color: ${({ theme }) => theme.colors.black};
    padding-bottom: 1rem;
  }

  &.status-alert_info {
    font-weight: 800;
  }

  &.status-alert_help {
    color: ${({ theme }) => theme.colors.main};
  }
`;

export const ApplicationDates = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-bottom: 5rem;
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderChildrenOverview};
`;

export const ApplicationActions = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5rem;
`;

export const ApplicationActionButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 2rem;
  align-items: center;
  justify-content: space-between;

  .application-button {
    display: flex;
    align-items: center;
    gap: 2.5rem;
    padding: 1.5rem 3rem;

    .text {
      display: flex;
      gap: 0.5rem;
    }
  }

  .application-button:hover {
    img {
      filter: invert(22%) sepia(65%) saturate(4391%) hue-rotate(197deg)
        brightness(90%) contrast(89%);
    }
  }

  &.application-attachment-button-primary {
    padding-top: 3rem;
    justify-content: flex-end;
  }

  &.application-attachment-buttons {
    border-bottom: 1px solid
      ${({ theme }) => theme.colors.borderChildrenOverview};
    padding: 3rem 0;
  }

  img {
    &.read-only-icon {
      width: 20px;
      height: 20px;
    }
  }
`;

export const ApplicationSecondaryActions = styled.div`
  display: flex;
  gap: 2rem;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;

  .button-default-secondary:hover {
    img {
      filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(0deg)
        brightness(100%) contrast(100%);
    }
  }

  .button-default-secondary-disabled {
    img {
      filter: grayscale(100%);
    }
  }
`;

export const ButtonWarning = styled(CommonButton)`
  display: flex;
  flex-direction: row;
  gap: 15rem;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.colors.warning};
  border: 2px solid ${({ theme }) => theme.colors.warning};
  transition: 0.3s ease;

  &:hover {
    color: ${({ theme }) => theme.colors.white};
    background-color: ${({ theme }) => theme.colors.warning};
    border: 2px solid ${({ theme }) => theme.colors.warning};
    box-shadow: 0 0 0 0.4rem ${({ theme }) => theme.colors.warning};
  }

  &.button-default-disabled {
    background-color: ${({ theme }) => theme.colors.primaryDisabledGrey};
    border: 2px solid ${({ theme }) => theme.colors.primaryDisabledGrey};
    cursor: not-allowed;
  }

  &.application-button-warning-mobile {
    width: 100%;
    justify-content: center;
  }
`;

export const ApplicationAttachmentsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

// Styles for mobile
/// ApplicationGeneralInformationMobile ///

export const ApplicationPanelMobile = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.main};
  border-radius: 4px;
  margin-bottom: 5rem;
`;

export const ApplicationInfoWrapperMobile = styled.div`
  display: flex;
  flex-direction: column;

  .preferences-list-content {
    max-height: 0;
    overflow: hidden;
    transition: 1s linear;
  }

  .preferences-list-content.expanded {
    max-height: 1000px;
  }
`;

export const ApplicationContainerMobile = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 2rem;
  background-color: ${({ theme }) => theme.colors.blueTabBackground};
`;

export const ApplicationInfoTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.main};
  height: 8rem;
  padding: 2rem;
  border-top: 1px solid ${({ theme }) => theme.colors.main};
  border-radius: 4px 4px 0 0;
`;

export const ApplicationSubtitleContainerMobile = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  height: auto;
  background-color: ${({ theme }) => theme.colors.blueTabBackground};
  border-bottom: 1px solid ${({ theme }) => theme.colors.preferencesBorder};
  padding: 2rem;
  gap: 2rem;

  &.application-unit-name-mobile {
    background-color: ${({ theme }) => theme.colors.preferencesBackground};
  }
`;

export const ApplicationInfoStatus = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 2rem;
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderChildrenOverview};
  background-color: ${({ theme }) => theme.colors.blueTabBackground};
`;

export const ApplicationInfoParagraphMobile = styled.p`
  font-size: 1.4rem;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.blackTabText};
`;

export const ApplicationInfoMobile = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding: 2rem;
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderChildrenOverview};
  background-color: ${({ theme }) => theme.colors.blueTabBackground};
`;

export const ApplicationActionButtonsContainerMobile = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3rem;
  padding: 2rem;
  background-color: ${({ theme }) => theme.colors.blueTabBackground};

  .application-button-mobile {
    width: 100%;
    gap: 1rem;
    height: 5rem;

    img {
      margin-left: 2rem;

      &.delete-icon {
        margin-left: 3.5rem;
      }

      &.read-only-icon {
        width: 2rem;
        height: 2rem;
      }

      &:hover {
        img {
          filter: invert(22%) sepia(65%) saturate(4391%) hue-rotate(197deg)
            brightness(90%) contrast(89%);
        }
      }
    }
  }

  .button-default-secondary:hover {
    img {
      filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(0deg)
        brightness(100%) contrast(100%);
    }
  }

  .button-default-secondary-disabled {
    img {
      filter: grayscale(100%);
    }
  }
`;

export const ApplicationPrefTitleContainerMobile = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.backgroundPrime};
  height: 8rem;
  padding: 2rem;
  border-bottom: 1px solid ${({ theme }) => theme.colors.preferencesBorder};

  .arrow-icon {
    transition: transform 0.3s, background-color 0.3s;
    cursor: pointer;
    background: url(${arrowIcon}) no-repeat center;
    height: 28px;
    width: 28px;
    background-size: contain;
    transform: rotate(180deg);
  }

  .arrow-icon.expanded {
    transform: rotate(0deg);
  }
`;
