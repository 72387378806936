import React from "react";
import { useTranslation } from "react-i18next";
import Authorize from "components/authorize/Authorize";
import useAuthentication from "hooks/useAuthentication";
import useUnknownCityOrType from "hooks/useUnknownCityOrType";

import { Page } from "components/commons/Page/Page";
import StaticPage from "components/reusable/StaticPage/StaticPage";

import NormalAccount from "./elements/NormalAccount/NormalAccount";
import TrustedProfile from "./elements/TrustedProfile/TrustedProfile";
import Breadcrumbs from "components/commons/Breadcrumbs/Breadcrumbs";

import { Button } from "components/commons";

import * as Styled from "pages/Account/Account.styles";

const Account = () => {
  const { t } = useTranslation();
  const authentication = useAuthentication();

  useUnknownCityOrType();

  return (
    <Authorize>
      <Page>
        <Breadcrumbs />
        <StaticPage title={t("menu.account")}>
          {authentication && authentication.isAuthenticated && (
            <Styled.AccountContainer>
                {authentication.auth.user &&  authentication.auth.user?.profile.idp == 'local' &&
                    <NormalAccount userData={authentication.auth.user?.profile} authentication={authentication} />
                }
                {authentication.auth.user && authentication.auth.user?.profile.idp != 'local' &&
                    <TrustedProfile profile={authentication.auth.user?.profile} />
                }
              <div>
              {t("myData.title")} <br />
              </div>
            </Styled.AccountContainer>
          )}

          {/* dla testów */}
          <Button onClick={authentication.forgotPassword}>Nie pamiętam hasła</Button>

          {authentication.isLoading && <div>Logowanie...</div>}

        </StaticPage>
      </Page>
    </Authorize>
  );
};

export default Account;
