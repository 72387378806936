import React from 'react';

import * as Styled from './List.styles';

const List = ({children}) => {
    return (
        <Styled.List>
            {children}
        </Styled.List>
    )
};

const ListItem = ({children}) => {
    return (
        <Styled.ListItem>
            {children}
        </Styled.ListItem>
    )
};

export { ListItem };

export default List;