import { useState, memo, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useForm, Controller } from 'react-hook-form';
import { Button } from "components/commons/Button/Button";
import { filterOptionsDefault } from "./UnitsFilters.config";
import IconClose from 'assets/images/icon-close.svg';
import * as Styled from "./UnitsFilters.styles";
import IconFilters from "assets/icons/ico-filters.svg";

export const UnitsFiltersForm = memo(({
    currentInstanceFilters,
    defaultValues,
    onFilter,
    filtersExpandedOnStartup = false,
}) => {
    const { t } = useTranslation();
    const [filtersExpanded, setFiltersExpanded] = useState(filtersExpandedOnStartup && Object.keys(defaultValues)?.length > 2);

    const {
        handleSubmit,
        control,
        formState: { isDirty },
        setValue,
        reset,
    } = useForm({ defaultValues });

    const onSubmit = async (submitData) => {
        const flattenedFormData = Object.keys(submitData).map(key => {
            const element = submitData[key];
            if (element === undefined 
                || element === null 
                || element === '' 
                || (Array.isArray(element) && element.length < 1)
                ) return null;

            if (Array.isArray(element)) {
                return { [key]: element.flatMap((obj) => obj.value).toString() };
            }
            if (typeof element === 'object') {
                return { [key]: element.value };
            }
            if (typeof element === 'string') {
                return { [key]: element };
            }
        }).filter(el => el !== null);
        onFilter({ ...Object.assign({}, ...flattenedFormData) });
        reset(submitData);
    };

    const onClearFilters = () => {
        reset(filterOptionsDefault);
        onFilter({});
    };

    const MappedFilters = (filters) => filters.map(
        (filter) => (
            <FilterDropdown
                key={filter.id}
                filter={filter}
                control={control}
            />
        )
    );

    return (
        <Styled.FiltersSection className='filters-section'>
            <Styled.SearchContainer className='search-container'>
                <Styled.SearchWrapper className="search-wrapper">
                    <Styled.SearchHeader>
                        {t("units:filters.searchHeader")}
                    </Styled.SearchHeader>
                    <Styled.SearchBar
                        hasTwoFilterElements={currentInstanceFilters.length === 1}
                    >

                        <Styled.SearchInputWrapper>
                            <Controller
                                name='unitName'
                                control={control}
                                render={({ field }) => (
                                    <>
                                        <Styled.FiltersInput
                                            className='filters-input'
                                            aria-label={t("units:filters.unit.placeholder")}
                                            placeholder={t("units:filters.unit.placeholder")}
                                            {...field}
                                        />
                                        { field.value.length > 0 && (
                                            <Styled.SearchInputClearButton
                                                onClick={() => {
                                                    setValue('unitName', '');
                                                    handleSubmit(onSubmit)();
                                                }}
                                            >
                                                <img src={IconClose} alt="clear-search-button" />
                                            </Styled.SearchInputClearButton>
                                        )}
                                    </>
                                )}
                            />
                        </Styled.SearchInputWrapper>

                        { currentInstanceFilters.length === 1 && (
                            <>
                                {MappedFilters(currentInstanceFilters)}
                            </>
                        )}

                        <Button
                            disabled={!isDirty}
                            onClick={handleSubmit(onSubmit)}
                        >
                            {t("units:filters.searchBt")}
                        </Button>
                    </Styled.SearchBar>

                    {currentInstanceFilters.length > 1 && (
                        <Styled.SearchFooter>
                            <Styled.ExpandFiltersButton
                                secondary
                                type='button'
                                onClick={() => setFiltersExpanded(currentState => !currentState)}
                            >
                                {filtersExpanded ? t("units:filters.hideFilters") : t("units:filters.showFilters")}
                                <img src={IconFilters} alt="expand-filters" />
                            </Styled.ExpandFiltersButton>
                        </Styled.SearchFooter>
                    )}

                </Styled.SearchWrapper>
            </Styled.SearchContainer>

            {filtersExpanded && (
                <Styled.FiltersContainer
                    className='unit-filters'
                >
                    <Styled.FiltersWrapper>
                        {currentInstanceFilters.length > 0 && MappedFilters(currentInstanceFilters)}
                        <Styled.ButtonsContainer>
                            <Styled.ButtonsWrapper>
                                <Styled.TextButton
                                    type='button'
                                    onClick={onClearFilters}
                                >
                                    {t("units:filters.clear")}
                                </Styled.TextButton>
                                <Button
                                    disabled={!isDirty}
                                    onClick={handleSubmit(onSubmit)}
                                >
                                    {t("units:filters.submit")}
                                </Button>
                            </Styled.ButtonsWrapper>
                        </Styled.ButtonsContainer>
                    </Styled.FiltersWrapper>
                </Styled.FiltersContainer>
            )}
        </Styled.FiltersSection>
    );
});

const FilterDropdown = memo(({ filter, control }) => (
    <Controller
        key={filter.id}
        name={filter.id}
        control={control}
        render={({ field }) => {
            return (
                <Styled.FiltersDropdown
                    {...field}
                    labelText={filter?.label}
                    placeholder={filter?.placeholder}
                    options={filter?.options}
                    isMulti={filter?.multiselect}
                    value={field.value}
                    aria-label={filter?.placeholder}
                />
            );
        }}
    />
));
