import styled from "styled-components";

import circleIcon from 'assets/images/circle.svg'
import circleFullIcon from 'assets/images/circle-full.svg'
import circleTickIcon from 'assets/images/circle-tick.svg'

export const Container = styled.div`
    height: 25rem;
    vertical-align: top;
    white-space: normal;
    text-align: left;
    h4 {
        margin: 1rem 4.3rem;
        font-size: 1.6rem;
    }

    @media ${({theme}) => theme.device.desktop} {
        text-align: center;
        display: inline-block;
        height: auto;
        h4 { margin: 3.4rem auto 0.9rem; }
    }
`;

export const TitleWrapper = styled.h4`
    width: 100%;
    @media ${({theme}) => theme.device.desktop} {
        width: 80%;
        padding-bottom: 4rem;
    }
`;

export const DateIconWrapper = styled.div`
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    @media ${({theme}) => theme.device.desktop} {
        display: flex;
        height: 13rem;
        flex-direction: column;
    }
`;

export const DateDisplay = styled.p`
    margin: 1rem;
    font-weight: 500;
    @media ${({theme}) => theme.device.desktop} { 
        margin: 1.6rem;
    }
`;

export const Icon = styled.div`
    width: 3.3rem;
    height: 3.3rem;
    flex: none;
    ${props => {
        if(props.icon === 'now'){
            return `background: no-repeat center url(${circleFullIcon}); background-size: contain;`
        } else if(props.icon === 'past') {
            return `background: no-repeat center url(${circleTickIcon}); background-size: contain;`
        } else {
            return `background: no-repeat center url(${circleIcon}); background-size: contain;`
        }
    }};
`;

export const Line = styled.div`
    width: 3.3rem;
    height: 3.3rem;    
    margin-left: -33px;
    flex: none;
    pointer-events: none;
    &::after {
        content: '';
        position: absolute;
        border-left: 1px solid ${({theme}) => theme.colors.accent};
        height: 3.3rem;
        transform: translate(50%, -50px);
        height: 200%;
        z-index: -1;
        width: 3.3rem;
    }
    @media ${({theme}) => theme.device.desktop} {
        height: 3.3rem;
        width: 100%;
        position: relative;
        width: 100%;
        margin-left: 0;
        margin-bottom: -3.3rem;        
        &::after {
            border-left: 0;
            border-bottom: 1px solid ${({theme}) => theme.colors.accent};
            ${props => props.icon==='past' ? `border-width:2px;` : ``}
            height: 100%;
            width: 300%;
            transform: translate(-50%, -50%);
            z-index: -1;
        }
    }
`;

export const Description = styled.p`
        margin: 1rem 4.3rem;
        font-size: 1.4rem;

        @media ${({theme}) => theme.device.desktop} {
            display: none;
        }
`;

export const OnMobile = styled.div`
    @media ${({theme}) => theme.device.desktop} {
        display: none;
    }
`;

export const OnDesktop = styled.div`
    display: none;
    @media ${({theme}) => theme.device.desktop} {
        display: block;
    }
`;