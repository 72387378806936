import { useState, useEffect } from "react";
import useApi from "hooks/useApi";

const useStorageManager = (url, minutes = 5) => {
  	const [data, setData] = useState(null);
  	const [error, setError] = useState(null);
  	const [loading, setLoading] = useState(false);
	const { offerApi } = useApi();

	const getData = async () => {
		setLoading(true);
		const { data: fetchedData, error } = await offerApi.get(url);

		if (error) {
			if (window.localStorage.getItem(url)) getCachedData();
			setError(true);
			setLoading(false);
			return null;
		} 

		if (fetchedData) {
			setData(fetchedData);
			storeData(fetchedData);
			setLoading(false);
		}
	}

	const getCachedData = () => {
		const item = window.localStorage.getItem(url);
		setData(JSON.parse(item).data);
	}

	const storeData = (dataToStore) => {
		const dataToLocalStorage = {};
		dataToLocalStorage['time'] = Date.now();
		dataToLocalStorage['key'] = url;
		dataToLocalStorage['data'] = dataToStore;

		try {
			localStorage.setItem(url, JSON.stringify(dataToLocalStorage));
		} catch(exception) {
			localStorage.clear();//czyścimy storage w razie błędu przekroczenia limitu
			try {
				localStorage.setItem(url, JSON.stringify(dataToLocalStorage));
			} finally { }
		}
	}

	const isValidData = () => {
		const item = window.localStorage.getItem(url);
		const dateNow = new Date();

		if (item) {
			const dataFromStore = JSON.parse(item);
			return dataFromStore.time + (minutes * 60 * 1000) > Date.parse(dateNow);
		} 

		return false;
	}

	const updateData = (data) => {
		setData(data);
		storeData(data);
	}

	const reloadData = async () => {
		const { data } = await offerApi.get(url);
		updateData(data);
		return data;
	}

  	useEffect(() => {
		isValidData() ? getCachedData() : getData();
  	}, [url]);

  return { data, loading, error, setData: updateData, reloadData };
};

export default useStorageManager;