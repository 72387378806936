import styled from "styled-components";

export const Footer = styled.footer`
  background: ${({ theme }) => theme.colors.selectItemBackground};
  padding: 5.2rem 0;

  & h5 {
    color: ${({ theme }) => theme.colors.main};
  }

  & > div {
    display: grid;
    grid-template-columns: 2fr 1fr 1fr 1fr 1fr;
    align-items: start;

    @media ${({ theme }) => theme.device.touch} {
      display: flex;
      flex-direction: column;
    }
  }

  & a:hover, & a:focus{
    text-decoration: underline;
  }

  & a:focus-visible {
    outline: 1.5px solid ${props => (props.reverse === true ? props.theme.colors.white : props.theme.colors.grey90)};
    outline-offset: 2px;
    padding: 0.1rem;
    border-radius: 5px;
  }

  & a.img-link:focus, & a.img-link:focus-visible {
    outline: none;
  }

  & a:focus-visible img {
    outline: 1.5px solid ${props => (props.reverse === true ? props.theme.colors.white : props.theme.colors.grey90)};
    border-radius: 5px;
  }


  
`;

export const FooterCpyright = styled.div`
  display: flex;
  align-items: center;
  gap: 2.6rem;
  font-size: 1.4rem;
  padding-left: 1rem;

  & img {
    height: 5.4rem;
    margin-top: -4.1rem;

    @media ${({ theme }) => theme.device.mobile} {
      margin-top: 0;
    }
  }

  & span {
    color: ${({ theme }) => theme.colors.black};
  }

  & > div > div {
    font-weight: 500;
    display: flex;
    gap: 1.5rem;
    margin-bottom: 1.9rem;

    @media ${({ theme }) => theme.device.mobile} {
      flex-direction: column;
    }
  }

  @media ${({ theme }) => theme.device.mobile} {
    flex-direction: column;
    gap: 1.5rem;
    align-items: flex-start;
  }
`;

export const FooterLinks = styled.div`
  display: contents;

  & > div {
    display: flex;
    flex-direction: column;
    gap: 1.6rem;
    padding: 0 1rem;
  }

  @media ${({ theme }) => theme.device.tabletOnly} {
    margin-top: 6.8rem;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;

    & > div:first-of-type {
      padding: 0 1rem 0 0;
    }

    & > div:last-of-type {
      padding: 0 0 0 1rem;
    }
  }

  @media ${({ theme }) => theme.device.mobile} {
    display: flex;
    flex-direction: column;
    gap: 3.6rem;
    margin-top: 2.7rem;

    & > div {
      padding: 0;
    }
  }
`;
