import React, {
	useState,
	useRef,
	useCallback,
	useMemo,
	useEffect,
	forwardRef
} from "react";

import useOnClickOutside from "hooks/useOnClickOutside";
import PropTypes from "prop-types";

import ImgIconClose from "assets/images/close.svg";
import ImgIconCheckmark from "assets/images/checkmark.svg";

import * as Styled from "./Select.styles";

import Input from 'components/commons/Input/Input';

const Select = forwardRef(({
	children,
	round,
	disabled,
	label,
	multiple,
	onChange,
	onSearch = null,
    onChangeType = "string", // string | object { label, value} | value
	...props
}, ref) => {
	const localRef = useRef(null);
	const [open, setOpen] = useState(false); // czy rozwinięta lista
	const [value, setValue] = useState(multiple ? [] : null); // wartość wybranego elementu
	const [active, setActive] = useState(null); // ideks aktywnego elementu w rozwijanej liście

  let itemsCount = 0; // liczba elementów na liscie bez uwzględnienia pustego elementu
  const [selText, setSelText] = useState([]);

  const parseChildren = () => {
    const res = [];

    if (children) {
      if (!Array.isArray(children))
        res.push({
          idx: 0,
          val: children.props.value,
          text: children.props.children,
        });
      else if (Array.isArray(children)) {
        children.map((el, idx) => {
          if (!Array.isArray(el)) {
            if (el)
              res.push({
                idx: idx,
                val: el.props.value,
                text: el.props.children,
              });
          } else if (Array.isArray(el)) {
            return el.map((e, idx) => {
              if (!Array.isArray(e)) {
                res.push({
                  idx: idx,
                  val: e.props.value,
                  text: e.props.children,
                });
              }
            });
          }
        });
      }
      itemsCount = res.length;
    }

    return res;
  };

  const items = useMemo(() => parseChildren(), [children]);

  const findNextAlphabetically = (e) => {
    const letter = e.key.toUpperCase();
    if (active > 0 && getActiveItem().text[0] <= letter) {
      for (var i = active + 1; i < items.length; i++) {
        const el = items[i];
        if (el?.text?.startsWith(letter)) {
          setActive(el.idx + 1);
          scrollToActive(e.target, el.idx + 1);
          return el;
        }
      }
    } else {
      const el = items.find((x) => x.text?.startsWith(letter));
      if (el) {
        setActive(el.idx + 1);
        scrollToActive(e.target, el.idx + 1);
        return el;
      }
    }
  };

  const getDafaultText = () => {
    const el = items.find((x) => x.val == null || x.val === "");
    if (el) return el.text;
    return <>&nbsp;</>;
  };

  const getActiveItem = () => {
    if (active) {
      return items[active];
    }
    return null;
  };

  const toggleOpen = () => {
    setOpen(!open);

    if (!open && active != null) setActive(null);
  };
  const selectClick = (e) => {
    if (!disabled) toggleOpen();
  };

  const scrollToActive = (e, newActive) => {
    const el = e.querySelector("li[data-idx='" + (newActive + 1) + "']");
    if (el)
      el.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "nearest",
      });
  };

  const selectActive = (e) => {
    const el = getActiveItem();

    if (el != null) {
      onSelectItem(el.val, el.text);
    }
  };

  const handleKeyDown = (e) => {
    if (disabled) return;
    if (e.key === "Enter") {
      if (open) {
        selectActive(e.target);
      }
      toggleOpen();
    }
    if (e.key === "ArrowDown") {
      e.preventDefault();
      let newActive = +(active == null ? -1 : active) + 1;
      if (newActive >= itemsCount) newActive = itemsCount;

      setActive(newActive);
      scrollToActive(e.target, newActive);
    }
    if (e.key === "ArrowUp") {
      e.preventDefault();
      if (active != null) {
        let newActive = +active - 1;
        if (newActive < 0) newActive = 0;

        setActive(newActive);
        scrollToActive(e.target, newActive);
      }
    }
    if (e.key === "Escape") {
      toggleOpen();
    }

    if (e.key === " " && multiple) {
      e.preventDefault();
      if (active) {
        const a = getActiveItem();
        itemClick(e, a.val, a.text);
      }
    }

    if (e.key >= "a" && e.key <= "ż") {
      e.preventDefault();
      findNextAlphabetically(e);
    }
  };

  const getItems = (children) => {
    let res = [];

    const isItemSelected = (v) => {
      if (multiple && Array.isArray(value)) {
        return value.find((el) => el === v);
      }
      return false;
    };

    const getItem = (idx, v, c) => {
      return (
        v !== undefined && (
          <Styled.SelectListItem
            active={active == idx}
            data-idx={idx + 1}
            key={idx}
            value={v}
            selected={isItemSelected(v)}
          >
            <a href="#!" onClick={(e) => itemClick(e, v, c)}>
              <span>{c}</span>
              <span>
                <img src={ImgIconCheckmark} alt="Tak" />
              </span>
            </a>
          </Styled.SelectListItem>
        )
      );
    };

    if (items) {
      items.map((el, idx) => {
        if (el.val !== "") {
          res.push(getItem(idx, el.val, el.text));
        }
      });
      itemsCount = res.length;
    }

    return res;
  };

  const unselectItem = (e, idx) => {
    e.preventDefault();
    e.stopPropagation();

    if (multiple && Array.isArray(value)) {
      const v = value[idx];
      itemClick(e, v);
    }
  };

  const getButtonContent = () => {
    if (value == null) {
      return getDafaultText();
    }

    if (Array.isArray(value)) {
      if (value.length === 0) return getDafaultText();

      return (
        <Styled.SelectLabelButtonMulti>
          {selText.map((item, idx) => (
            <Styled.SelectLabelButtonItem key={idx}>
              {item}
              <span onClick={(e) => unselectItem(e, idx)}>
                <img src={ImgIconClose} alt="Close" />
              </span>
            </Styled.SelectLabelButtonItem>
          ))}
        </Styled.SelectLabelButtonMulti>
      );
    } else return <span>{selText.join("")}</span>;
  };

  const clickOutSideHandler = useCallback(() => {
    if (!open) return;

    setOpen(false);
  }, [open, setOpen]);

	useOnClickOutside(localRef, clickOutSideHandler);

	const onSelectItem = (val, txt) => {
		setOpen(false);
		setValue(val);
		setSelText(Array.of(txt));
        console.log(val, txt);
		if (onChange) {
            if (onChangeType === "string") {
                return onChange(val, txt);
            } else if (onChangeType === "value") {
                return onChange(val);
            } else if (onChangeType === "object") {
                return onChange({
                    value: val,
                    label: txt,
                });
            }
        }
	};

  const isEmpty = () => {
    return value == null || (Array.isArray(value) && value.length === 0);
  };

  const empty = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const val = multiple ? [] : null;
    const txt = [];
    setOpen(false);

    onSelectItem(val, txt);
  };

  const itemClick = (e, v, txt) => {
    e.preventDefault();
    e.stopPropagation();

    if (multiple) {
      const idx = value.findIndex((el) => el === v);
      var newValues = null;
      var newTexts = null;
      if (idx === -1) {
        newValues = [...value, v];
        newTexts = [...selText, txt];
      } else {
        newValues = value.filter((_, i) => i !== idx);
        newTexts = selText.filter((_, i) => i !== idx);
      }
      setValue(newValues);
      setSelText(newTexts);

			if (onChange) {
				if (onChangeType === "string") {
                    return onChange(newValues, newTexts);
                } else if (onChangeType === "value") {
                    return onChange(newValues);
                } else if (onChangeType === "object") {
                    return onChange(newValues.map((el, i) => ({
                        value: el,
                        label: newTexts[i],
                })))
                }
			}
		} else onSelectItem(v, txt);
	};

  useEffect(() => {
    if (
      props.value === "" ||
      (Array.isArray(props.value) && props.value?.length < 1)
    ) {
      setSelText([]);
      setValue(multiple ? [] : null);
    }
  }, [props.value]);

	return (
		<>
			{label && (
				<Styled.SelectLabel 
                    onClick={() => setOpen(true)}
                    className='select-label'
                >
					{label}
				</Styled.SelectLabel>
			)}
			{ onSearch && <Input onChange={(e) => onSearch(e.target.value)}  />}
			<Styled.Select
				round={round}
				disabled={disabled}
				open={open}
				ref={localRef}
				onKeyDown={handleKeyDown}
				{...props}
			>
				<Styled.SelectLabelButton
					open={open}
					onClick={selectClick}
				>
					<Styled.SelectLabelButtonContent>
						{getButtonContent()}
					</Styled.SelectLabelButtonContent>
					{!isEmpty && (
						<span onClick={empty}>
							<img src={ImgIconClose} alt="Close" />
						</span>
					)}
				</Styled.SelectLabelButton>
				{open && <ul>{getItems(children)}</ul>}
			</Styled.Select>
		</>
	);
});

Select.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]).isRequired,
	round: PropTypes.bool,
	disabled: PropTypes.bool,
	label: PropTypes.oneOfType([
		PropTypes.node,
        PropTypes.string
	]),
	multiple: PropTypes.bool,
	onChange: PropTypes.func,
    onChangeType: PropTypes.oneOf(["string", "object", "value"]),
	onSearch: PropTypes.func,
};

export default Select;
