import React, { useEffect } from 'react';
import SectionFactory from '../sections/SectionFactory';

const QuestionsModule = ({ data: { sections }, setModuleData }) => {
    // question module has one section by default

    useEffect(() => {
        if (sections.length === 1) {
            setModuleData({
                moduleName: sections[0].section,
            })
        }
    }, [sections]);

    return (
        <>
            {sections.map((section, index) => {
                return (
                    <SectionFactory
                        section={{...section, type: 'QuestionsSection'}}
                        key={`${section.type}__${index}`}
                    />
                )
            })}
        </>
    );
}

export default QuestionsModule;