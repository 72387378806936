import { UnitTypes, UnitGeneralTypes } from "utils/enums";

const districtsFilter = {
    id: "districts",
    label: "units:filters.district.description",
    placeholder: "units:filters.district.placeholder",
    multiselect: true,
    options: 'districts',
    conditionalSetting: "ShowDistrict",
}

const freePlacesFilter = {
    id: "freePlaces",
    label: "units:filters.freePlaces.description",
    placeholder: "units:filters.freePlaces.placeholder",
    multiselect: false,
    options: 'boolean',
    conditionalSetting: "ShowFreePlaces",
    filterConditionalSetting: "ShowFreePlacesFilter",
};

const featuresFilter = {
    id: "features",
    label: "units:filters.features.description",
    placeholder: "units:filters.features.placeholder",
    multiselect: true,
    options: 'features',
};

const typeFilter = {
    id: "type",
    label: "units:filters.type.description",
    placeholder: "units:filters.type.placeholder",
    multiselect: false,
    options: 'types',
    negativeConditionalSetting: "NoPublicNurserySegregation",
};

const ageGroupNurseryFilter = {
    id: "ageGroup",
    label: "units:filters.ageGroups.description",
    placeholder: "units:filters.ageGroups.placeholder",
    multiselect: false,
    options: 'branchTypes',
    conditionalSetting: "ShowAgesInInformator",
};

const additionalActivitiesFilter = {
    id: "additionalActivities",
    label: "units:filters.activities.description",
    placeholder: "units:filters.activities.placeholder",
    multiselect: true,
    options: 'features',
};

const languagesFilter = {
    id: "languages",
    label: "units:filters.languages.description",
    placeholder: "units:filters.languages.placeholder",
    multiselect: true,
    options: 'languages',
};

const secondarySchoolTypesFilter = {
    id: "secondarySchoolType",
    label: "units:filters.schoolType.description",
    placeholder: "units:filters.schoolType.description",
    multiselect: false,
    options: 'secondarySchoolTypes',
};

const extendedSubjectsFilter = {
    id: "extendedSubjects",
    label: "units:filters.extendedSubjects.description",
    placeholder: "units:filters.extendedSubjects.placeholder",
    multiselect: true,
    options: 'subjects',
};

const awardedSubjectsFilter = {
    id: "awardedSubjects",
    label: "units:filters.awardedSubjects.description",
    placeholder: "units:filters.awardedSubjects.placeholder",
    multiselect: true,
    options: 'subjects',
};

const professionsFilter = {
    id: "professions",
    label: "units:filters.professions.description",
    placeholder: "units:filters.professions.placeholder",
    multiselect: true,
    options: 'professions',
};

const semicolonyAgeGroupFilter = {
    id: "ageGroup",
    label: "units:filters.semicolony.description",
    placeholder: "units:filters.semicolony.placeholder",
    multiselect: false,
    options: 'branchTypes',
    conditionalSetting: null,
};

const semicolonyPackageFilter = {
    id: "package",
    label: "Pakiet",
    placeholder: "dowolny",
    multiselect: false,
    options: 'package',
    conditionalSetting: null,
};

export const instanceFiltersConfig = [
    {
        unitGeneralType: UnitGeneralTypes.Nursery,
        unitGeneralLabel: UnitTypes.Nursery,
        filters: [
            districtsFilter,
            featuresFilter,
            typeFilter,
            ageGroupNurseryFilter,
            freePlacesFilter,
        ],
    },
    {
        unitGeneralType: UnitGeneralTypes.Kindergarten,
        unitGeneralLabel: UnitTypes.Kindergarten,
        filters: [
            districtsFilter,
            additionalActivitiesFilter,
            freePlacesFilter
        ],
    },
    {
        unitGeneralType: UnitGeneralTypes.PrimarySchool,
        unitGeneralLabel: UnitTypes.PrimarySchool,
        filters: [
            districtsFilter,
            languagesFilter,
            freePlacesFilter,
        ],
    },
    {
        unitGeneralType: UnitGeneralTypes.Class4,
        unitGeneralLabel: UnitTypes.Class4,
        filters: [
            districtsFilter,
            languagesFilter,
            freePlacesFilter,
        ],
    },
    {
        unitGeneralType: UnitGeneralTypes.Class6,
        unitGeneralLabel: UnitTypes.Class6,
        filters: [
            districtsFilter,
            languagesFilter,
            freePlacesFilter,
        ],
    },
    {
        unitGeneralType: UnitGeneralTypes.Class7,
        unitGeneralLabel: UnitTypes.Class7,
        filters: [
            districtsFilter,
            languagesFilter,
            freePlacesFilter,
        ],
    },
    {
        unitGeneralType: UnitGeneralTypes.SecondarySchool,
        unitGeneralLabel: UnitTypes.SecondarySchool,
        filters: [
            districtsFilter,
            secondarySchoolTypesFilter,
            languagesFilter,
            extendedSubjectsFilter,
            awardedSubjectsFilter,
            professionsFilter,
        ],
    },
    {
        unitGeneralType: UnitGeneralTypes.TradeSchool,
        unitGeneralLabel: UnitTypes.TradeSchool,
        filters: [
            districtsFilter,
            secondarySchoolTypesFilter,
            languagesFilter,
            extendedSubjectsFilter,
            awardedSubjectsFilter,
            professionsFilter,
        ],
    },
    {
        unitGeneralType: UnitGeneralTypes.PostSecondarySchool,
        unitGeneralLabel: UnitTypes.PostSecondarySchool,
        filters: [
            languagesFilter,
        ],
    },
    {
        unitGeneralType: UnitGeneralTypes.Dormitory,
        unitGeneralLabel: UnitTypes.Dormitory,
        filters: [districtsFilter],
    },
    {
        unitGeneralType: UnitGeneralTypes.HolidayDuty,
        unitGeneralLabel: UnitTypes.HolidayDuty,
        filters: [districtsFilter],
    },
    {
        unitGeneralType: UnitGeneralTypes.Semicolony,
        unitGeneralLabel: UnitTypes.Semicolony,
        filters: [
            districtsFilter,
            semicolonyAgeGroupFilter,
            semicolonyPackageFilter
        ],
    },
    {
        unitGeneralType: UnitGeneralTypes.Culture,
        unitGeneralLabel: UnitTypes.Culture,
        filters: [
        ],
    },
    {
        unitGeneralType: UnitGeneralTypes.Center,
        unitGeneralLabel: UnitTypes.Center,
        filters: [
        ],
    },
];

export const filterOptionsDefault = {
    unitName: "",
    districts: [],
    features: [],
    type: null,
    ageGroup: null,
    additionalActivities: [],
    languages: [],
    freePlaces: null,
    secondarySchoolType: null,
    extendedSubjects: [],
    awardedSubjects: [],
    professions: [],
    package: null
};
