import styled from "styled-components";

export const ModalContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    gap: 2rem;
`;

export const ModalInfoTitle = styled.h3`
  font-size: 2rem;
  color: ${({ theme }) => theme.colors.grey90};
  font-weight: 600;
  margin-bottom: 2rem;

  ::before {
    content: "i";
    color: #1a76cb;
    background-color: white;
    border: 2px solid #1a76cb;
    border-radius: 50%;
    margin-right: 1rem;
    margin-left: -0.5rem;
    margin-bottom: 5px;
    padding: 0 10px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: 25px;
    width: 25px;
    font-size: 20px;
    vertical-align: middle;
  }
`;

export const H4 = styled.h4`
    font-size: 1.6rem;
    color: ${({theme}) => theme.colors.grey90};
    font-weight: 600;
`;

export const MoadlInfoText = styled.p`
    color: ${({theme}) => theme.colors.black};
    font-size: 1.6rem;
    margin-bottom: 2rem;
`;
