import React from 'react';

const DeleteIcon = ({ ...props }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 21.25 21.23"
        {...props}
    >
        <g className='icon' id="Group_6089" data-name="Group 6089">
            <path id="Path_7144" data-name="Path 7144" className="cls-1" d="m18.23,5.51c-.43,0-.77.35-.77.77v11.53c-.04,1.07-.94,1.91-2.01,1.88H5.82c-1.07.04-1.97-.8-2.01-1.88V6.28c0-.43-.35-.77-.77-.77-.43,0-.77.35-.77.77h0v11.53c.04,1.93,1.63,3.46,3.56,3.42h9.63c1.93.03,3.52-1.5,3.56-3.42V6.28c0-.43-.35-.77-.77-.77,0,0,0,0,0,0Z" />
            <path id="Path_7145" data-name="Path 7145" className="cls-1" d="m7.64,16.8V6.28c0-.43-.35-.77-.77-.77s-.77.35-.77.77h0v10.52c0,.43.35.77.77.77s.77-.35.77-.77Z" />
            <path id="Path_7146" data-name="Path 7146" className="cls-1" d="m11.35,16.8V6.28c0-.43-.35-.77-.77-.77s-.77.35-.77.77h0v10.52c0,.43.35.77.77.77s.77-.35.77-.77Z" />
            <path id="Path_7147" data-name="Path 7147" className="cls-1" d="m15.13,16.8V6.28c0-.43-.35-.77-.77-.77-.43,0-.77.35-.77.77h0v10.52c0,.43.35.77.77.77s.77-.35.77-.77Z" />
            <path id="Path_7148" data-name="Path 7148" className="cls-1" d="m20.48,3.16h-5.41v-.85C15.05,1.01,13.97-.02,12.67,0h-4.09c-1.3-.02-2.38,1.01-2.4,2.32v.85H.77c-.43,0-.77.35-.77.77s.35.77.77.77h19.7c.43,0,.77-.35.77-.77s-.35-.77-.77-.77h0Zm-12.74-.85c.02-.45.4-.79.85-.77h4.09c.45-.02.82.32.85.77v.85h-5.78v-.85Z" />
        </g>
    </svg>
)

export default DeleteIcon;