import { useState, useEffect, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams, useParams, useLocation, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import useApi from "hooks/useApi";
import usePageBlocker from 'hooks/usePageBlocker';
import useAuthentication from "hooks/useAuthentication";
import useDocumentTitle from 'hooks/useDocumentTitle';

import useInstanceAndUnits from './useInstanceAndUnits';
import useAutoSequenceValidation from './useAutoSequenceValidation';
import useFormValidation from './useFormValidation';

import { podajCelownik } from "utils/utils";

const useApplication = ({
    applicationType,
}) => {
    const { t } = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();
    const { city, generalType, appId: savedAppId } = useParams();
    const [searchParams, setSearchParams] = useSearchParams();

    const formReadOnly = searchParams.get('mode') === 'ro';
    const currentStep = parseInt(searchParams.get('krok'));

    const { candidateApi, offerApi } = useApi();

    const [formDefaultValues, setFormDefaultValues] = useState();
    const [saveId, setSaveId] = useState(savedAppId);
    const [formIsReady, setFormIsReady] = useState(false);

    const [formDefaultValuesIsSet, setFormDefaultValuesIsSet] = useState(() => savedAppId ? false : true);

    const [formSaveable, setFormSaveable] = useState(false);

    const [instanceSchema, setInstanceSchema] = useState();
    const [schema, setSchema] = useState();


    const [availableStep, setAvailableStep] = useState(0);

    const [bottomBarHeight, setBottomBarHeight] = useState(null);

    const [moduleError, setModuleError] = useState(null); // text displayed above "next button" in bottom navigation bar

    const { isAuthenticated } = useAuthentication(formReadOnly);

    const [disableBlocking, setDisableBlocking] = useState(false);    
    const pageBlocker = usePageBlocker(isAuthenticated && !disableBlocking);

    const { instanceId, instance, units } = useInstanceAndUnits(formReadOnly);

    const [ initialRegulations, setInitialRegulations ] = useState(null);

    // start action - when all nedded data is available - start loading additionalData in sequence
    useEffect(() => {
        if (isAuthenticated && instanceId && applicationType && instance) {
            loadDataSequence(instanceId, applicationType, saveId);
        }
    }, [isAuthenticated, instanceId, applicationType, instance]);


    // start methods used in loadDataSequence
    const fetchSavedApplication = useCallback(async (appIdentifier) => {
        const { data: appList, error } = await candidateApi.get(`Application/${appIdentifier}`);
        // if (error.response.status === 401) {
        //     setTimeout(() => fetchSavedApplication(appIdentifier), 500);
        // }
        return appList;
    }, [candidateApi]);

    const fetchSchema = useCallback(async (id, applicationType, addSAParam) => {
        const { data: fetchedSchema, error } = await offerApi.get(`AppDescription/${id}/${applicationType}${addSAParam ? `?sa=true` : ""}`);
        return fetchedSchema;
    }, [offerApi]);

    const updateSchemaOnBranches = useCallback((schema, selectedBranches) => {
        const selectedUnits = findUnitsByBranches(units, selectedBranches);
        const idDictUnitTypesFoundInSelectedUnits = selectedUnits.map(unit => unit.Id_DictUnitType);
        return processSchema(schema, idDictUnitTypesFoundInSelectedUnits);
    }, [units]);

    const getEnabledFlag = useCallback((what, flag) => {
        if (!what) return null;
        if (!flag) return null;
        if (((what & flag) === flag))
            return flag;
        return null;
    }, []);

    const loadDataSequence = useCallback(async (instanceId, applicationType, saveIdLoc) => {
        const schema = await fetchSchema(instanceId, applicationType, (instance.supplementaryAdmission && formReadOnly));

        setInstanceSchema(schema); // store original schema

        if (!saveIdLoc) {
            // move {kind: 3 (GroupKind.PreQuestions)} before Preferences and hide all conditional modules
            setSchema(processSchema(schema));
            setCurrentStep(0);
            setAvailableStep(0);

            // regulations appear only on new Applicatioon
            setInitialRegulations(
                getEnabledFlag(instance?.settings?.ApplicationStartPage?.value, 1) ||
                getEnabledFlag(instance?.settings?.ApplicationStartPage?.value, 2) ||
                null
            );

            return setFormDefaultValues('null');
        }

        const appData = await fetchSavedApplication(saveIdLoc);
        const processedInputData = processInputData(appData, schema);

        let newSchema = null;

        if (processedInputData?.branches?.length > 0) {
            // only if some branches are selected we can check if some conditional modules should be shown;
            newSchema = updateSchemaOnBranches(schema, processedInputData.branches);
        } else {
            newSchema = processSchema(schema);
        };
        setSchema(newSchema);

        /// if application is saved - check how far user have reached last time:
        const reachedStep = checkPreviouslyReachedStep(newSchema, appData, instance.minPreferences);

        setAvailableStep(reachedStep);

        if (appData.incomplete && (isNaN(currentStep) || (currentStep > reachedStep))) {
            setCurrentStep(reachedStep);
        } else if (isNaN(currentStep)) {
            setCurrentStep(0);
        }

        // form is ready to save when incoming data includes firstName, lastName and (pesel or identityNr and birthDate)
        setFormSaveable(!!(processedInputData.FirstName && processedInputData.LastName && (processedInputData.Pesel || (processedInputData.IdentityNr && processedInputData.BirthDate))));
        setFormDefaultValues(processedInputData);

    }, [fetchSchema, fetchSavedApplication, setSchema, setFormDefaultValues, instance, formReadOnly]);
    /// END of initial sequence


    const updateModuleSchemaOnSelectedBranches = useCallback((selectedBranches) => {
        setSchema(updateSchemaOnBranches(instanceSchema, selectedBranches));
    }, [instanceSchema, updateSchemaOnBranches]);

    // MAIN ACTION for saving entire application
    const saveForm = useCallback(async (vals, submit = false) => {
        const saveVals = processOutputData({ vals, applicationType, instance, saveId });
        if (submit) {
            saveVals.Incomplete = false;
        }
        console.log("REDUCED DATA TO SAVE: ", Object.keys(saveVals).reduce((acc, key) => {
            return saveVals[key] ? { ...acc, [key]: saveVals[key] } : acc
        }, {}));
        const method = saveId ? candidateApi.put : candidateApi.post;

        setFormSaveable(false);

        let saveApplicationResponse;
        try {
            const { data: savedApplicationId } = await method('Application', JSON.stringify(saveVals));

            if ( savedApplicationId && savedApplicationId !== true && !submit) {
                setSaveId(savedApplicationId);
                const path = `${location.pathname}/${savedApplicationId}${location.search}`;
                setDisableBlocking(true);
                await new Promise(resolve => setTimeout(() => {
                    navigate(path, { replace: false });
                    resolve();
                }, 2));
                await new Promise(resolve => setTimeout(resolve, 2));
                setDisableBlocking(false);
                setModuleError({text: t('application.savedComment'), isError: false})
                return saveApplicationResponse = savedApplicationId;
            }
            setModuleError({text: `Wniosek zapisany`, isError: false})
            return saveApplicationResponse = 'success';
        } catch ({response, message}) {
            if (response?.data?.detail)
                alert(response.data.detail);
            if (response.status === 401) {
                setModuleError({text: 'Użytkownik niezalogowany', isError: false})
                return saveApplicationResponse = 'Użytkownik niezalogowany';
            }
            if (message) {
                return saveApplicationResponse = response;
            }            
        } finally {
            setFormSaveable(true);
        }
        return saveApplicationResponse;
    }, [instanceId, applicationType, candidateApi, saveId]);


    const steps = useMemo(() => {
        if (!schema) return [];
        return schema.map((step) => step.group)
    }, [schema]);


    const setCurrentStep = useCallback((step, withAvailableStep = false) => {
        if (withAvailableStep) {
            setAvailableStep(step);
        }

        const objectFromEntries = Object.entries(Object.fromEntries(searchParams)).reduce((acc, [key, value]) => {
            // define values that need to remain in search params - cookie data is not needed;
            if (key === "krok" || key === "mode" || key === "preselected_branch" || key === "preselected_unit") {
                acc[key] = value;
            }
            return acc;
        }, {});

        const queryParams = { ...objectFromEntries, krok: step.toString() };
        setSearchParams(new URLSearchParams(queryParams), { replace: true });

        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, [searchParams, setSearchParams]);

    useEffect(() => {
        // hide module text error after 4 seconds
        if (moduleError && moduleError?.text.length > 1) {
            setTimeout(() => setModuleError(null), 4000);
        }
    }, [moduleError]);

    /*** FORM DECLARATION */
    // Since the whole application has been simplified, we can move form handling here
    const formMethods = useForm({
        defaultValues: {
            ...formDefaultValues
            // Id_Street: "3021160595424",
            // Mother_Id_Street: "302116",
            // Father_Id_Street: "3021",
            // formDefaultValues
            // branches: [105338, 105332, 105334]
            // PostalCode: '61-82'
            // Pesel: '84789'
            // BirthDate: '2020-02-20',
            // answers: {
            //     INCOME_DATE: '2020-02-20'
            // }
            // answers: {
            //     SIBLINGS_COUNT: '14:27'
            // }
        }
    });
    const { getValues, reset } = formMethods;

    /*** FORM VALIDATION */
    const formValidationMethods = useFormValidation(schema, currentStep, formMethods);
    const { onValidation } = formValidationMethods;

    /*** FORM HANDLING */
    const onSaveForm = useCallback(async () => {
        const currentValues = getValues();
        const saveResponse = await saveForm(currentValues);
        reset(currentValues);
        return saveResponse;
    }, [saveForm, getValues, reset]);


    const instantBackToAppList = useCallback( async() => {
        setDisableBlocking(true);
        await new Promise(resolve => setTimeout(() => {
            setTimeout(() => {
                resolve();
                }, 10);
            }))
            navigate(`/${city}/${generalType}/wnioski`, { replace: false }
        );
    }, [city, generalType]);

    

    useEffect(() => {
        if (formDefaultValues && !formDefaultValuesIsSet) {
            reset(formDefaultValues);
            setFormDefaultValuesIsSet(true);
        }
    }, [formDefaultValues]);

    useDocumentTitle(t("loginOrRegister.title", { what: podajCelownik(applicationType) }));

    const showErrorInModule = useCallback((errors) => {
        if (!errors || errors?.length < 1) return;        
        if (errors[0].field === 'branches') {
            return setModuleError({ text: t('application.preferences.errorInPreferences'), isError: true });
        }
        setModuleError({ text: t('application.preferences.errorsInModule'), isError: true });
    }, [ t ]);

    const autoSequenceValidation = useAutoSequenceValidation({ 
        setCurrentStep, 
        currentStep, 
        onValidation, 
        numSteps: steps.length,
        showErrorInModule
    }); 

    const [ pendingEntireFormValidation, setPendingEntireFormValidation ] = useState(false);

    const onSubmitEntireForm =  useCallback(async () => {
        autoSequenceValidation.startAutoValidation(0, steps.length, steps.length)
        setPendingEntireFormValidation(true);
        
    }, [autoSequenceValidation.startAutoValidation, steps.length]);

    const sendFormAfterAutoValidation = useCallback(async () => {
        const currentValues = getValues();
        const saveResponse = await saveForm(currentValues, true);
        if (saveResponse === 'success') {
            instantBackToAppList();
        }
    }, [instantBackToAppList, getValues, saveForm]);

    useEffect(() => {
        if (pendingEntireFormValidation && !autoSequenceValidation.isAutoSequence) {
            setPendingEntireFormValidation(false);
            sendFormAfterAutoValidation();
        }
    }, [pendingEntireFormValidation, autoSequenceValidation.isAutoSequence, currentStep])


    useEffect(() => {
        if (!!schema && (savedAppId ? !!formDefaultValues : true)) {
            setFormIsReady(true);
        }
    }, [schema, savedAppId, formDefaultValues]);    

    const onBallClickGotoStep = useCallback(async (step) => {        
        if (step < currentStep) {
            // prev steps are available immidiately
            return setCurrentStep(step);
        }
        const validation = await onValidation();
        if (validation === true) {
            /// validation return array of object if failed or "true" if passed
            if (step === currentStep + 1) {
                return setCurrentStep(step);
            }
            return autoSequenceValidation.startAutoValidation( currentStep + 1, step, true );
            // startAutoValidation of steps
        }
        showErrorInModule(validation);
    }, [currentStep, availableStep, onValidation]);
    
    const onNextModule = useCallback(async () => {
        if (formReadOnly) return setCurrentStep(crs => crs + 1 < availableStep ? crs + 1 : crs, true);
        const validation = await onValidation();
        if (validation === true) {
            autoSequenceValidation.setValidStepsArray( state => {
                const copy = [...state];
                if (state[currentStep] && state[currentStep] === true) {
                    copy[currentStep] = false;
                    return copy;
                }
                return state;
            })
            return setCurrentStep(currentStep + 1, true);
        }
        showErrorInModule(validation);
    }, [onValidation, autoSequenceValidation, currentStep, availableStep]);

    return {
        schema,
        instanceId, instance, units,

        // main settings sethings
        formIsReady,
        isAuthenticated,
        formReadOnly,

        // form and validation methods
        autoSequenceValidation,

        formMethods,
        formValidationMethods,

        // saving form
        onSaveForm,
        onSubmitEntireForm,
        formSaveable,
        setFormSaveable,

        instantBackToAppList,


        // form steps handling
        steps,
        currentStep,
        availableStep,
        setCurrentStep,
        setAvailableStep,

        onBallClickGotoStep,
        onNextModule,

        // minor settings and methods
        pageBlocker,

        bottomBarHeight,
        setBottomBarHeight,
        
        moduleError, 
        setModuleError,       

        updateModuleSchemaOnSelectedBranches,

        initialRegulations, setInitialRegulations,

        // updateAutoSequenceErrors: autoSequenceValidation.setValidStepsArray,
    }
}

export default useApplication;




/// FUNCTIONS
const checkPreviouslyReachedStep = (schema, appData, instanceMinPreferences = 1) => {
    // traverse trough schema and check if any of answers is not null - of course there are exceptions:
    // preferentions refers to schoolBranches (kind: 4)
    // questions depends on answers (kind: 5)
    let reachedStep = 0;

    for (let i = 0; i < schema.length; i++) {
        const module = schema[i];

        if (module?.kind === 1 && module?.sections?.length > 0) {
            // standard module;
            if (!module.sections.some(({ fields }) => {
                return fields.some(({ name }) => {
                    if (appData[name] !== null || appData[name] !== undefined) {
                        return true;
                    }
                    return false;
                });
            })) {
                break;
            }
            reachedStep = i;
        }

        if (module.kind === 2) {
            // predefined school 
            if (appData.Id_PredefinedUnit) {
                reachedStep = i;
            }
        }

        if (module.kind === 4) { // SCHOOL BRANCHES
            if (!appData?.SchoolBranches || appData?.SchoolBranches?.length < 1) {
                break;
            }
            if (appData?.SchoolBranches?.length < instanceMinPreferences) {
                reachedStep = i;
                break;
            }
            reachedStep = i;
        }

        if (module.kind === 5 || module.kind === 6 || module.kind === 3) { // QUESTIONS / FINISH
            const flattenedQuestions = module?.sections?.flatMap(section => section?.questions || []);
            if (!flattenedQuestions || flattenedQuestions?.length < 1) {
                reachedStep = i;
                break;
            }

            const requiredQuestions = flattenedQuestions?.filter(({ required }) => required);

            const answeredUIDS = appData?.Answers?.map(({ UID }) => UID);
            const allRequiredHaveAnswers = requiredQuestions?.every(({ uid }) => answeredUIDS?.includes(uid) || false);

            if (allRequiredHaveAnswers) {
                console.log("ALL REQUIRED HAVE ANSWERS: ", i);
                reachedStep = i;
            }

            if (!allRequiredHaveAnswers) {
                reachedStep = i;
                break;
            }
        }
    }
    return reachedStep;
}


// PROCESSING INPUT AND OUTPUT DATA
const processInputData = (data, schema) => {
    // there are possibly option components in answers - to set its initial value we need options from schema.
    const questionsReducedFromSchema = schema.reduce((acc, step) => {
        if (step && (step?.kind === 5 || step?.kind === 3 || step?.kind === 6)) {
            const stepFlatQuestions = step.sections.reduce((accumulatedQuestions, section) => {
                return [...accumulatedQuestions, ...section.questions];
            }, []);
            return [...acc, ...stepFlatQuestions];
        }
        return [...acc];
    }, []).map(({ uid, component, options }) => ({ uid, component, options }));

    const incoming = {
        // processnull
        ...(Object.keys(data).reduce((acc, key) => {
            if (data[key] === null) {
                return acc;
            }
            return { ...acc, [key]: data[key] }
        }, {})),

        // process incoming "SchoolBranches"
        ...((data.SchoolBranches && data.SchoolBranches.length > 0) && {
            branches: data.SchoolBranches.map(({ Id_SchoolBranch }) => Id_SchoolBranch)
        }),

        // process incoming "Answers"
        ...((data.Answers && data.Answers.length > 0) && {
            "Answers": data.Answers?.reduce((acc, answer) => {
                const foundInSchema = questionsReducedFromSchema.find(({ uid }) => uid === answer.UID);

                if (!foundInSchema) return acc;
                
                const name = `${answer.UID}${answer.Id_SchoolBranch ? `##${answer.Id_SchoolBranch}` : ''}`

                if (foundInSchema.component === 'select') {
                    const schemaOptions = Object.entries(foundInSchema.options).map(([label, value]) => ({ label, value: value }));
                    return { ...acc, [name]: { value: schemaOptions.find(({ value }) => value.toString() === answer.Value.toString()) } }
                }
                return ({
                    ...acc, [name]: answer.Value
                });
            }, {})
        }),
    }
    return incoming;
}

const processOutputData = ({ vals, applicationType, instance, saveId }) => {
    // console.log("process: ", vals);
    let saveVals = Object.keys(vals).reduce((acc, key) => {
        if (typeof vals[key] === 'object') {
            if (key === 'Answers') {
                const answers = vals[key];
                if (!answers) {
                    return acc;
                }
                const mapped = Object.keys(answers).map(key => {
                    const [Uid, Id_SchoolBranch] = key.split("##");
                     
                    const keyVal = answers[key];
                    if (keyVal || keyVal === 0) {
                        let val = keyVal?.value?.value?.toString() || keyVal?.value?.toString() || keyVal?.toString() || null;

                        // check if it is not wrong date:
                        if (val.length === 19 && val.split('T').length === 2) {
                            val = val.split('T')[0];
                        }
                        return {
                            Uid,
                            Id_SchoolBranch: parseInt(Id_SchoolBranch) || null,
                            value: val,
                        };
                    }
                }).filter(item => item);

                return { ...acc, Answers: mapped };
            }


            if (vals[key]?.value) {
                return { ...acc, [key]: vals[key].value }
            }
            return { ...acc, [key]: vals[key] }
        }
        if (vals[key] === '') {
            return { ...acc, [key]: null }
        }
        return { ...acc, [key]: vals[key] }
    }, {});

    saveVals = {
        ...saveVals,
        Incomplete: true,
        Id_DictApplicationType: applicationType,
        Id_Instance: instance.id.toString(),
        ...(saveId ? { Id: parseInt(saveId) } : {}),
        ...(saveId ? {} : { SupplementaryAdmission: instance.instanceStatus == 70}),
    }
    return saveVals
}


const processSchema = (schema, selectedBranchesDictUnitTypes = []) => {
    // if new application - move {kind: 3 (GroupKind.PreQuestions)} before Preferences and hide all conditional modules
    return schema.reduce((acc, module) => {
        if (module.kind === 3) {
            const preferencesIndex = acc.findIndex(({ kind }) => kind === 4);
            const copy = [...acc];
            copy.splice(preferencesIndex, 0, module);
            return copy;
        }
        if (module.conditional) {
            if (Array.isArray(selectedBranchesDictUnitTypes) && selectedBranchesDictUnitTypes?.length > 0) {
                const getAllModuleQuestionsDictUnitTypes = module.sections.reduce((acc, section) => {
                    if (!section?.questions) return acc;
                    return [...acc, ...section.questions.flatMap((question) => {
                        return question.dictUnitTypes;
                    })];
                }, []);
                const withoutRepetitions = Array.from(new Set(getAllModuleQuestionsDictUnitTypes));
                if (withoutRepetitions.some(element => selectedBranchesDictUnitTypes.includes(element))) {
                    return [...acc, module];
                }
                return acc;
            }
            return acc;
        }
        return [...acc, module];
    }, []);
};


const findUnitsByBranches = (units, branches) => {
    if (!units) return [];
    return units?.reduce((acc, unit) => {
        if (!unit) return acc;
        const branchFound = unit?.schoolBranches?.find((branch) => branches?.includes(branch.id));
        if (branchFound) {
            return [...acc, {
                Id_Unit: unit.id,
                Id_SchoolBranch: branchFound.id,
                Id_DictUnitType: unit.id_DictUnitType,
                SchoolBranchName: branchFound.name,
                UnitName: unit.name,
                SchoolBranches: unit.schoolBranches,
            }]
        }
        return acc;
    }, []);
}