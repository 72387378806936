export const size = {
    colXxs: '360px',
    mobileSmall: '575px',
    colXs: '720px',
    mobile: '719px',
    tablet: '900px',
    colMedium: '1039px',
    colDesktop: '1040px',
    colWide: '1320px',
    colLarge: '1600px',
    colXlarge: '1920px',
};

// theme
export const theme = {

    colors: {
        main: "#003189", // primaryColor & color1
        accent: "#1A76CB", // color2
        backgroundPrime: "#CCD5E7", // color3
        backgroundLight: "#F5F9FD", // color4
        warning: "#E0211B", // color5
        gradient: "linear-gradient(180deg, #1A76CB 0%, #003189 100%)", // color6
        grey90: "#323232", // color7
        grey60: "#5C5C5C", // color8
        grey30: "#B7B7B7", // color9
        grey5: "#EAEAEA", // color10
        white: "#FFFFFF", // color11 || white
        black: "#393939",
        ligthblueFiltersBackground: "#D8E0F0",

        modalTransparentBackground: '#393939bb',
        labelBorder: '#C3C3C3',
        disabledControlBackground: '#E3E3E3',
        selectItemBackground: '#EDF2F7', // old 12

        unitAddressColor: '#676767',
        main3: '#00318930', // main with opacity 0.3

        primaryDisabledGrey: '#797979',
        blueTabBackground: '#ecf2f7',
        blackTabText: '#000000',
        borderChildrenOverview: "#777777",

        //mobile
        preferencesBackground: '#dce4ef',
        preferencesBorder: '#B7C5DE',

        success: '#00C853',
    },
    
    device: {
        onlyXSmall: `screen and (max-width: ${size.colXxs})`,
        mobileSmall: `screen and (max-width: ${size.mobileSmall})`,
        smallDevices: `screen and (min-width: ${size.colXxs})`,
        smallDevicesOnly: `screen and (min-width: ${size.colXxs}) and (max-width: ${size.mobile}))`,
        mobile: `screen and (max-width: ${size.mobile})`,
        tablet: `screen and (min-width: ${size.colXs})`,
        tabletOnly: `screen and (min-width: ${size.colXs}) and (max-width: ${size.colMedium})`,
        tabletMax: `screen and (max-width: ${size.tablet})`,
        touch: `screen and (max-width: ${size.colMedium})`,
        touchOnly: `screen and (min-width: ${size.mobile}) and (max-width: ${size.colMedium})`,
        desktop: `screen and (min-width: ${size.colDesktop})`,
        desktopOnly: `screen and (min-width: ${size.colDesktop}) and (max-width: ${size.colLarge})`,
        desktopMax: `screen and (max-width: ${size.colDesktop})`,
        desktopWide: `screen and (min-width: ${size.colWide})`,
        desktopWideMax: `screen and (max-width: ${size.colWide})`,
        widescreen: `screen and (min-width: ${size.colLarge})`,
        widescreenMax: `screen and (max-width: ${size.colLarge})`,
        widescreenOnly: `screen and (min-width: ${size.colLarge}) and (max-width: ${size.colXlarge})`,
        hd: `screen and (min-width: ${size.colLarge})`,
        superHd: `screen and (min-width: ${size.colXlarge})`,
        superHdMax: `screen and (max-width: ${size.colXlarge})`,
    },
    size,
}
