import styled, { css } from "styled-components";
import * as Layout from "components/commons/Layout/Layout";

const bgImage = css`
  background-image: url(${(props) => props.image});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`;

export const BannerContainer = styled(Layout.Container)`
  display: grid;
  grid-template-columns: auto 840px;
  grid-template-rows: auto auto;
  grid-template-areas:
    "info image"
    "buttons image";

  @media ${({ theme }) => theme.device.widescreenMax} {
    grid-template-columns: auto 780px;
  }

  @media ${({ theme }) => theme.device.desktopWideMax} {
    grid-template-columns: auto;
    grid-template-rows: auto auto auto;
    grid-template-areas:
      "info"
      "image"
      "buttons";
  }
`;

export const BannerBasic = styled.div`
  width: 100%;
  height: 31rem;
  ${bgImage};
  border-radius: 4.4rem;

  @media ${({ theme }) => theme.device.mobile} {
    border-radius: 0;
  }
`;

export const BannerBasicWrapper = styled(Layout.Container)`
    @media ${({ theme }) => theme.device.mobile} {
        width: 100%;
        max-width: unset;
    }
`;

export const Banner = styled.div`
  width: 100%;
  color: ${({ theme }) => theme.colors.white};
  margin-bottom: 6rem;
  background: linear-gradient(
    to right,
    rgb(39, 105, 163) 24%,
    rgb(10, 44, 106) 42%
  );

  @media ${({ theme }) => theme.device.desktopWideMax} {
    background: transparent linear-gradient(180deg, #0a2c6a 0%, #0161b9 100%);
  }
`;

export const BannerTitle = styled.h3`
  text-align: left;
  font-size: 3.5rem;
  font-weight: bold;
  letter-spacing: 1.75px;
  margin-bottom: 3.3rem;

  @media ${({ theme }) => theme.device.mobile} {
    letter-spacing: 1px;
    font-size: 2rem;
  }
`;

export const BannerDescription = styled.p`
  letter-spacing: 0;
  font-size: 1.8rem;
  line-height: 1.6;
  font-weight: 300;

  @media ${({ theme }) => theme.device.mobile} {
    font-size: 1.4rem;
    margin-bottom: 2.3rem;
  }
`;

export const BannerInfo = styled.section`
  grid-area: info;
  margin-top: 9rem;
  margin-bottom: 4rem;
  max-width: 71rem;

  @media ${({ theme }) => theme.device.desktopWideMax} {
    margin-top: 7rem;
    margin-bottom: 0;
  }

  @media ${({ theme }) => theme.device.mobile} {
    margin-top: 4rem;
  }
`;

export const BannerFrameWrapper = styled.div`
  grid-area: image;
  margin: auto;
  position: relative;
  width: 565px;
  height: 375px;
  box-shadow: rgb(39, 105, 163) 0px 0px 42px 50px inset,
    0 0 40px 36px rgb(39, 105, 163);
  background-color: rgb(39, 105, 163);

  @media ${({ theme }) => theme.device.superHdMax} {
    max-width: 700px;
  }

  @media ${({ theme }) => theme.device.desktopWideMax} {
    max-width: 575px;
    max-height: 385px;
    margin: 64px auto;
  }

  @media ${({ theme }) => theme.device.tabletMax} {
    max-height: 317px;
    max-width: 483px;
    box-shadow: rgb(39, 105, 163) 0px 0px 42px 50px inset,
      0 0 40px 36px rgb(39, 105, 163);
    background-color: rgb(39, 105, 163);
  }

  @media ${({ theme }) => theme.device.mobile} {
    max-width: 243px;
    max-height: 140px;
    margin-top: 54px;
  }
`;

export const BannerImgFrame = styled.section`
  ${bgImage};
  width: 730px;
  height: 430px;
  margin: auto;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);

  @media ${({ theme }) => theme.device.tabletMax} {
    height: 360px;
    width: 640px;
  }

  @media ${({ theme }) => theme.device.mobile} {
    width: 322px;
    height: 191px;
  }
`;

export const BannerCityImg = styled.div`
  ${bgImage};
  width: 533px;
  height: 334px;
  position: absolute;
  top: 49%;
  left: 50%;
  transform: translate(-50%, -51%);

  @media ${({ theme }) => theme.device.tabletMax} {
    max-width: 477px;
    max-height: 303px;
  }

  @media ${({ theme }) => theme.device.mobile} {
    width: 232px;
    height: 146px;
  }
`;

export const BannerButtons = styled.section`
  grid-area: buttons;
  margin-bottom: 8.7rem;

  @media ${({ theme }) => theme.device.desktopWideMax} {
    margin-bottom: 7rem;
  }

  @media ${({ theme }) => theme.device.mobile} {
    margin-top: 14px;
    margin-bottom: 4rem;
  }
`;

export const BannerButtonsTitle = styled.span`
  font-size: 2rem;
  font-weight: 600;

  @media ${({ theme }) => theme.device.mobile} {
    display: block;
    width: 100%;
    font-size: 1.6rem;
    text-align: center;
  }
`;

export const BannerButtonsList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 2.9rem;
  margin-top: 4.1rem;

  @media ${({ theme }) => theme.device.mobile} {
    margin-top: 2.3rem;
    gap: 3rem;
    align-items: center;
    flex-direction: column;
  }
`;
