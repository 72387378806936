import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { AppContext } from "context/appContext";

import { Link } from "components/commons/Link/Link";

import List, { ListItem } from "../List/List";

import { getInstancesByType } from "utils/utils";
import { UnitTypes, UnitGeneralTypes } from "utils/enums";

import url from "routes/routes";

import * as Styled from "./InstancesSelector.styles";
import * as Layout from "components/commons/Layout/Layout";
import * as Text from "components/commons/Text/Text";
import Unit from "../../../pages/Unit/Unit";

const InstancesListing = ({ title, list, type, withType }) => {
  const { t } = useTranslation();

  return (
    <Styled.InstancesListing>
      <Text.TitleSub>{title}</Text.TitleSub>
      <Styled.InstancesListContainer quantity={list.length}>
        {list.map((i) => (
          <ListItem key={i.id || i}>
            {type ? (
              <Link to={`/${i.regionUrl}/${i.instanceUrl || i.typeUrl}/oferta`}>
                {withType ? i.displayName : i.instanceName}
              </Link>
            ) : (
              <Link to={`${url.allInstances.all}/${i}`}>
                {t(`unitTypes.${i}`)}
              </Link>
            )}
          </ListItem>
        ))}
      </Styled.InstancesListContainer>
    </Styled.InstancesListing>
  );
};

const InstancesSelector = () => {
  const { t } = useTranslation();
  const { type } = useParams();
  const { instances, setBreadcrumbsPath } = useContext(AppContext);

  const [withType, setWithType] = useState(false);
  const [additionalPaths, setAdditionalPaths] = useState(null);

  const dutiesExists = instances?.some((instance) => instance.unitGeneralType == UnitGeneralTypes.HolidayDuty );
  const semicolonyExists = instances?.some((instance) => instance.unitGeneralType == UnitGeneralTypes.Semicolony );

  const typeExists = (type) => {
    switch (type) {
      case UnitTypes.Nursery:
      case UnitTypes.Kindergarten:
      case UnitTypes.PrimarySchool:
      case UnitTypes.SecondarySchool:
      case UnitTypes.TradeSchool:
      case UnitTypes.Dormitory:
      case UnitTypes.HolidayDuty:
      case UnitTypes.Class4:
      case UnitTypes.Class6:
      case UnitTypes.Class7:
      case UnitTypes.Semicolony:
      case UnitTypes.PostSecondarySchool:
      case UnitTypes.Culture:
      case UnitTypes.Center:
        return true;
      default:
        return false;
    }
  };

  const filteredInstances = (path) => {
    return typeExists(path) ? getInstancesByType(instances, path) : instances;
  };

  useEffect(() => {
    switch (type) {
      case UnitTypes.Kindergarten:
        if (dutiesExists)
            setAdditionalPaths([UnitTypes.HolidayDuty]);
        else
          setAdditionalPaths(null);
        break;
      case UnitTypes.PrimarySchool:
        if (semicolonyExists)
            setAdditionalPaths([UnitTypes.Semicolony, UnitTypes.Class4, UnitTypes.Class6, UnitTypes.Class7]);
        else
            setAdditionalPaths([UnitTypes.Class4, UnitTypes.Class6, UnitTypes.Class7]);
        break;
      default:
        setAdditionalPaths(null);
        break;
    }

    if (type) {
      setBreadcrumbsPath([
        {
          name: t("allinstances:title"),
          url: url.allInstances.all,
        },
        {
          name: t(`unitTypes.${type}`),
        },
      ]);
      setWithType(false);
    } else {
      setBreadcrumbsPath([
        {
          name: t("allinstances:title"),
        },
      ]);

      setWithType(true);
    }
  }, [type]);

  return (
    <Styled.InstancesSelector>
      <Layout.Container>

        {type ? (
          <>
            <InstancesListing
              title={t(`unitTypes.${type}`)}
              list={filteredInstances(type)}
              type={type}
              withType={withType}
            />
            {additionalPaths &&
              additionalPaths.length &&
              additionalPaths.map((additionalPath) => (
                <InstancesListing
                  title={t(`unitTypes.${additionalPath}`)}
                  list={filteredInstances(additionalPath)}
                  type={additionalPath}
                  withType={withType}
                  key={additionalPath}
                />
              ))}
          </>
        ) : (
          <InstancesListing
            title={t("allinstances:unitType")}
            list={Object.keys(t("unitTypesForView", { returnObjects: true }))}
          />
        )}
      </Layout.Container>
    </Styled.InstancesSelector>
  );
};

export default InstancesSelector;
