import * as React from 'react';

import * as Styled from './OutsideError.styles';
import NaborLogo from 'components/commons/Logo/NaborLogo';

const OutsideError = ({children}) => {
    return (
        <Styled.OutsideError>
            <Styled.OutsideErrorWrapper>
                <NaborLogo className="logo" white />
                {children}
            </Styled.OutsideErrorWrapper>
        </Styled.OutsideError>
    )
};

export default OutsideError;