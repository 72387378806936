import React from 'react';

import * as Styled from './ContentBlock.styles';
import * as Text from 'components/commons/Text/Text';

import ImgIconWarning from 'assets/images/icon-warning.svg';

const ContentBlock = ({title, subtitle, children}) => {
    return (
        <Styled.ContentBlock>
            <Styled.ContentBlockTitle>
                <Text.HeadingSub>{title}</Text.HeadingSub>
            </Styled.ContentBlockTitle>
            <Styled.ContentBlockSubTitle>
                <img className="warning" src={ImgIconWarning} alt="Warning" /> <Text.Title bold>{subtitle}.</Text.Title>
            </Styled.ContentBlockSubTitle>
            <Styled.ContentBlockBox>
                <Text.Title>{children}.</Text.Title>
            </Styled.ContentBlockBox>
        </Styled.ContentBlock>
    )
};

export default ContentBlock;