import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";

import { AppContext } from "context/appContext";

import { getInstance } from "utils/utils";

import * as Styled from "./InstanceMenu.styles";
import * as Layout from "components/commons/Layout/Layout";

import InstanceMenuItems from "./InstanceMenuItems";

const InstanceMenu = ({ isAuthenticated, mobileMenuActive }) => {
  const { city, generalType } = useParams();

  const { instances } = useContext(AppContext);

  const [instance, setInstance] = useState(null);

  useEffect(() => {
    if (instances?.length > 0) {
      if (instances != null && instances.length > 0) {
        setInstance(getInstance(instances, city, generalType)[0]);
      }
    }
  }, [instances, city]);

  return (
    <Styled.InstanceMenu>
      <Layout.Container>
        <InstanceMenuItems 
            instance={instance} 
            isAuthenticated={isAuthenticated} 
            mobileMenuActive={mobileMenuActive} 
        />
      </Layout.Container>
    </Styled.InstanceMenu>
  );
};

export default InstanceMenu;
