import { createBrowserRouter } from "react-router-dom";

import Wrapper from 'components/main/Wrapper/Wrapper';
import Error404 from "pages/Error/404";
// import Error500 from "pages/Error/500";

import Home from "pages/Home/Home";
import About from "pages/About/About";
import Help from "pages/Help/Help";
import AllInstances from "pages/AllInstances/AllInstances";
import City from "pages/City/City";
import Offer from "pages/Offer/Offer";
import Documents from "pages/Documents/Documents";
import News from "pages/News/News";
import Faq from "pages/Faq/Faq";
import Contact from "pages/Contact/Contact";
import StartApplication from "pages/StartApplication/StartApplication";
import Account from "pages/Account/Account";
import Unit from "pages/Unit/Unit";
import ApplicationList from "pages/ApplicationList/ApplicationList";
import Application from 'pages/Application/Application';
import Branch from "pages/Branch/Branch";
import UnitGroup from "pages/UnitGroup/UnitGroup";
import ApplicationContact from "pages/ApplicationContact/ApplicationContact";
import InstanceHelp from "pages/InstanceHelp/InstanceHelp";
import InstanceCalculator from "pages/Calculator/Calculator";
import url from './routes';
import ChooseApplicationType from "pages/ChooseApplicationType/ChooseApplicationType";
import PrivacyPolicy from "pages/PrivacyPolicy/PrivacyPolicy";
import AccessDeclaration from "pages/AccessDeclaration/AccessDeclaration";
import MailVerification from "pages/Actions/MailVerification";

const AppRoutes =
{
    noLogin: process.env.REACT_APP_NOLOGIN_MODE === 'true',

    create: (rootLoader) => {
        
        return  createBrowserRouter([
            {
                path: '',
                loader: rootLoader,
                element: <Wrapper />,
                errorElement: <Error404 />,

                children: [
                    {
                        index: true,
                        element: <Home />
                    },
                    {
                        path: url.about,
                        element: <About />
                    },
                    {
                        path: url.help,
                        element: <Help />
                    },
                    {
                        path: url.contact,
                        element: <ApplicationContact />
                    },
                    {
                        path: url.allInstances.all,
                        children: [
                            {
                                index: true,
                                element: <AllInstances />
                            },
                            {
                                path: url.allInstances.type,
                                element: <AllInstances />
                            },
                        ]
                    },
                    {
                        path: url.myAccount.applicationsList,
                        element: AppRoutes.noLogin ? null : <ApplicationList />
                    },
                    {
                        path: url.myAccount.account,
                        element: AppRoutes.noLogin ? null : <Account />
                    },
                    {
                        path: url.myAccount.declaration,
                        element: AppRoutes.noLogin ? null : <Application applicationType={1} />
                    },
                    {
                        path: url.myAccount.submission,
                        element: AppRoutes.noLogin ? null : <Application applicationType={2} />
                    },
                    {
                        path: url.myAccount.application,
                        element: AppRoutes.noLogin ? null : <Application applicationType={3} />
                    },
                    {
                        path: url.myAccount.appeal,
                        element: AppRoutes.noLogin ? null : <Application applicationType={4} />
                    },
                    {
                        path: url.city.main,
                        element: <City />
                    },
                    {
                        path: url.city.instance,
                        element: <Offer />
                    },
                    {
                        path: url.city.unit,
                        element: <Unit />
                    },
                    {
                        path: url.city.branch,
                        element: <Branch />
                    },
                    {
                        path: url.city.unitGroup,
                        element: <UnitGroup />
                    },
                    {
                        path: url.city.documents,
                        element: <Documents />
                    },
                    {
                        path: url.city.news,
                        element: <News />
                    },
                    {
                        path: url.city.contact,
                        element: <Contact />
                    },
                    {
                        path: url.city.faq,
                        element: <Faq />
                    },
                    {
                        path: url.city.wybor,
                        element: <ChooseApplicationType />
                    },
                    {
                        path: url.city.start,
                        element: <StartApplication />
                    },
                    {
                        path: url.city.account,
                        element: <Account />
                    },
                    {
                        path: url.city.offer,
                        element: <Offer />
                    },
                    {
                        path: url.city.calculator,
                        element: <InstanceCalculator />
                    },
                    {
                        path: url.city.help,
                        element: <InstanceHelp />
                    },
                    {
                        path: url.privacyPolicy,
                        element: <PrivacyPolicy />
                    },
                    {
                        path: url.accessDeclaration,
                        element: <AccessDeclaration />
                    },
                    {
                        path: url.city.unknown,
                        element: <Offer />
                    },
                    {
                        path: url.mail.verification,
                        element: <MailVerification />
                    },
                ],
            },
        ]);
    }
};

//    AppRoutes.layoutMain.map((route, index) => {* / }
//        {            const { element, ...rest } = route; }
//        {            return <Route key={index} {...rest} element={element} />; }
//        {        })} }


export default AppRoutes;
