import styled from 'styled-components';
import { H2 as CommonH2 } from "styles/common.styles";

export const StaticPage = styled.div`
    padding-bottom: 100px;
`;

export const StaticPageContent = styled.div`
    margin-top: 50px;

    a {
        text-decoration: none;
        color: rgb(0, 49, 137)
    }

    a:hover {
        text-decoration: underline;
    }
`;

export const H2 = styled(CommonH2)`
    margin-top: 3rem;
`;