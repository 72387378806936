import { parseDateToIso } from 'utils/dateUtils';

export const validatePesel = (pesel) => {
    var reg = /^[0-9]{11}$/;
    if (reg.test(pesel) === false) return false;

    const weights = [9, 7, 3, 1, 9, 7, 3, 1, 9, 7];

    const testChecksum = weights.reduce((acc, weight, index) => {
        const digit = parseInt(pesel.charAt(index), 10);
        return acc + (digit * weight);
    }, 0) % 10 === parseInt(pesel.charAt(10), 10);
    if (!testChecksum) return null;

    return { 
        isValid: true,
        birthDate: calcBirthDateFromPesel(pesel),
        gender: calcGenderFromPesel(pesel)
    }
    // return testChecksum;
}

const calcBirthDateFromPesel = (pesel) => {
    var year = parseInt(pesel.substring(0, 2), 10);
    var month = parseInt(pesel.substring(2, 4), 10) - 1;
    var day = parseInt(pesel.substring(4, 6), 10);
    if (month >= 60) {
        year = year + 2200;
        month = month - 60;
    } else if (month >= 40) {
        year = year + 2100;
        month = month - 40;
    } else if (month >= 20) {
        year = year + 2000;
        month = month - 20;
    } else {
        year += 1900;
    }
    // isNaN of date does the opposite - false means that date is valid
    const dateFromPesel = new Date(year, month, day);
    if (isNaN(dateFromPesel)) return null;
    return parseDateToIso(dateFromPesel);
}

const calcGenderFromPesel = (pesel) => {
    const genderDigit = parseInt(pesel.substring(9, 10), 10);
    // male - 1, female - 2
    return genderDigit % 2 === 1 ? '1' : '2';
}

/**
 * Returns an object with three properties: `isValid`, `birthDate`, `gender` and `validatePesel` function.
 * 
 * `isValid` is true if the given PESEL number is valid, false otherwise.
 * `birthDate` is the birth date of the person in ISO format, or null if PESEL is invalid.
 * `gender` is the gender of the person, '1' for male and '2' for female, or null if PESEL is invalid.
 * `validatePesel` is the function that validates the PESEL number.
 * 
 * @param {string} pesel - the PESEL number to validate
 * @returns {Object} - an object with `isValid`, `birthDate`, `gender` and `validatePesel` properties
 */
const usePesel = () => {    
    return {
        validatePesel,
    };
}

export default usePesel;