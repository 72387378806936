import NewsListing from 'components/news-page/NewsListing/NewsListing';
import Breadcrumbs from 'components/commons/Breadcrumbs/Breadcrumbs';
import Banner from 'components/commons/Banner/Banner';

import useUnknownCityOrType from "hooks/useUnknownCityOrType";

import { Page } from 'components/commons/Page/Page';

const News = () => {
    useUnknownCityOrType();
    return (
        <Page>
            <Breadcrumbs />
            <Banner />
            <NewsListing />
        </Page>
    )
};

export default News;