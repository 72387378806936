import styled from "styled-components";

export const AccessDeclaration = styled.div`
  ul, ol{
		padding-left: 2rem;
		margin-left: 1rem;
	}

	h3{
		margin: 1.5rem 0px 1.25rem;
        font-weight: 500;
        font-size: 2rem;
	}
    
    a {
        text-decoration: none;
        color: rgb(0, 49, 137)
    }

	a:hover {
		text-decoration: underline;
	}

	p{
		margin: 1rem 0;
	}
`;

export const AnswerText = styled.div`
	font-size: 1.4rem;
	line-height: 2.2rem;
	color: ${({theme}) => theme.colors.grey90};

	& ul {
		padding-left: 2rem;
	}
`;