import useStorageManager from "hooks/useStorageManager";
import { createContext, useState } from "react";

export const AppContext = createContext();

const AppProvider = ({ children }) => {
    const { data: instances, loading, error, setData: setInstances, reloadData: reloadInstances } = useStorageManager("instance");
    const [breadcrumbsPath, setBreadcrumbsPath] = useState([]);
    const [headerAuthMenuWhite, setHeaderAuthMenuWhite] = useState(false);
    const { data: regions } = useStorageManager("region");
    const [offerChanged, setOfferChanged] = useState(false);
    const setBreadcrumbsPathWrapper = (breadcrumbs) => {
        setBreadcrumbsPath(breadcrumbs);
        if (Array.isArray(breadcrumbs) && breadcrumbs.length > 0) {
            let title = "";
            if (breadcrumbs.length > 1) {
                breadcrumbs.slice(1).forEach((x, i) => {
                        if (title)
                            title += "-";
                        title += x.name || "";
                    },
                );
            } else {
                title = breadcrumbs[0].name;
            }
            if (title && document.title !== title)
                document.title = title;
        }
    };
    const toggleOfferChanged = () => {
        setOfferChanged(!offerChanged);
    }
    return (
        <AppContext.Provider
            value={{
                instances,
                setInstances,
                regions,
                breadcrumbsPath,
                setBreadcrumbsPath: setBreadcrumbsPathWrapper,
                headerAuthMenuWhite,
                setHeaderAuthMenuWhite,
                error,
                loading,
                reloadInstances,
                offerChanged,
                toggleOfferChanged
            }}
        >
            {children}
        </AppContext.Provider>
    );
};

export default AppProvider;
