import { useContext } from 'react';
import TextFieldForm from "./TextFieldForm";
import RadioForm from './RadioForm';
import CheckboxForm from "./CheckboxForm";
import SelectForm from "./SelectForm";
import PostalCodeInput from "./PostalCodeInput";
import PeselInput from "./PeselInput";
import TerytSelect from "./TerytSelect";
import HourInput from "./HourInput";
import DateInput from "./DateInput";
import IncomeInput from "./IncomeInput";
import IntegerInput from "./IntegerInput";
import EmailInput from './EmailInput';
import PhoneInput from './PhoneInput';
import { FieldType, ComponentEnumType } from "utils/enums";

import { ApplicationContext } from "../Application";

const FieldFactory = ({
    field,
}) => {
    const { name, component, componentType, ...rest } = field;

    const { formReadOnly } = useContext(ApplicationContext);

    if (field.isVisible === false) {
        return null;
    }
    const currentProps = {name, disabled: formReadOnly, ...rest}
    switch (component) {
        case FieldType.input:
            switch (componentType) {
                case ComponentEnumType.checkbox:
                    return <CheckboxForm {...currentProps} />;
                case ComponentEnumType.date:
                    return <DateInput {...currentProps} />;
                case ComponentEnumType.hour:
                    return <HourInput {...currentProps} />;
                case ComponentEnumType.text:
                    return <TextFieldForm {...currentProps} type="text" />;
                case ComponentEnumType.pesel:
                    return <PeselInput {...currentProps} />;
                case ComponentEnumType.postal:
                    return <PostalCodeInput {...currentProps} />;
                case ComponentEnumType.number:
                    return <IncomeInput {...currentProps} />;
                case ComponentEnumType.integer:
                    return <IntegerInput {...currentProps} />;
                case ComponentEnumType.tel:
                    return <PhoneInput {...currentProps} />;
                case ComponentEnumType.email:
                    return <EmailInput {...currentProps} />;
                case ComponentEnumType.language:
                    return <SelectForm {...currentProps} options={[]} />;
                case ComponentEnumType.profession:
                    return <SelectForm {...currentProps} options={[]} />;
                default:
                    return <TextFieldForm {...currentProps} />;
            }
        case FieldType.textarea: {
            return <TextFieldForm multiline {...currentProps} />;
        }
        case FieldType.select:
            switch (componentType) {
                case ComponentEnumType.teryt:
                    return <TerytSelect {...currentProps} />;
                default:
                    return <SelectForm {...currentProps} />;
            }
        case FieldType.radio:
            return <RadioForm {...currentProps} />;
        default:
            return null;
    }
};

export default FieldFactory;
