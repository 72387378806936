import React, {useContext, useEffect, useLayoutEffect, useState} from 'react';
import {Outlet, useLocation} from "react-router-dom";
import styled from 'styled-components';

import Header from 'components/layouts/Header/Header';
import Footer from 'components/layouts/Footer/Footer';

import { AppContext } from 'context/appContext';
import useApi from "hooks/useApi";

import Error500 from 'pages/Error/500';
import Loader from 'components/commons/Loader/Loader';

const Wrapper = () => {
    const location = useLocation();
    const [ loadingTimeout, setLoadingTimeout ] = useState(false);
    let { instances, setInstances, error, loading, reloadInstances, offerChanged, toggleOfferChanged } = useContext(AppContext);
    const { offerApi } = useApi();

    useLayoutEffect(() => {
        document.documentElement.scrollTo(0, 0);
    }, [location.pathname]);

    const getActiveInstances = async () => {
        const { data } = await offerApi.get("instance/active");
        if (Array.isArray(data) && data.length > 0 && Array.isArray(instances) && instances.length > 0) {
            let isChanged = false;
            let forceReload = false;
            if (instances.length != data.length)
            {
                console.log("Zaszła zmiana w ilości instancji");
                forceReload = true;
            }
            data.map((instance) => {
                const counterpart = instances.filter(({id}) => ( id === instance.id));
                if (counterpart?.length > 0 ) {
                    const instanceToUpdate = counterpart[0];
                    const changes = Object.keys(instance).filter( key => instanceToUpdate[key] !== instance[key] );
                    if (changes?.length > 0 ) {
                        changes.forEach((key) => {
                            console.log (`Zmiana w instancji ${instance.id} pole ${key}: poprzednio: ${instanceToUpdate[key]} teraz: ${instance[key]}`);
                        });
                        Object.assign(instanceToUpdate, instance);
                        isChanged = true;
                    }
                } else {
                    //Doszła jakaś nowa instancja
                    console.log(`Doszła instancja ${instance.id}`);
                    forceReload = true;
                }
            });
            if (isChanged) {
                setInstances(instances);
                toggleOfferChanged();
            }
            if (forceReload) {
               instances = await reloadInstances();
            }
        }
    };

    useEffect(() => {
        const interval = setInterval(() => {
            getActiveInstances();
        }, 30000);
        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        if (loading) {
            setLoadingTimeout(false);
            const interval = setInterval(() => {
                setLoadingTimeout(true);
            }, 1000);
            return () => clearInterval(interval);
        }
    }, [loading]);

    if (error) return <Error500 />

    if (loading) return <Loader isLonger isLongerOver={loadingTimeout} />

    return (
        <>
            <Header />
            <OutletWrapper className='outlet-wrapper'>
                <Outlet />
            </OutletWrapper>
            <Footer />
        </>
    );
}

export default Wrapper;

const OutletWrapper = styled.div`
    flex: 1;
`;