const validateField = async (text, validationString, yup, reference) => {
    const validationSchema = yup.lazy(value => {
        try {
            return eval(`yup.${validationString}`);
        } catch (error) {
            console.error('Nieprawidłowy string walidacji YUP w obiekcie:', reference || 'nieznany');
            return yup.string();
        }
    });

    try {
        const result = await validationSchema.validateSync(text);
        if (result) return null;
    } catch (error) {
        return error.message;
    }
};

export default validateField;