import styled, { css } from "styled-components";
import { H5 } from 'styles/common.styles';

import loupeIcon from 'assets/icons/ico-loupe.svg';
import zoomIcon from 'assets/icons/ico-zoom-1.svg';
import forwardIcon from 'assets/icons/ico-fw-1.svg';
import closeIcon from 'assets/icons/ico-close-1.svg';

import { 
    bigButtonBaseSize, 
    buttonBaseSize, 
    mobileBigButtonBaseSize, 
    mobileButtonBaseSize,
    baseRadius,
    mobileRadius,
} from './constants';

export const GalleryContainer = styled.section`
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    gap: 2dvw;
    margin-bottom: 30px;
    @media ${({theme: {device}}) => device.desktop} {
        gap: 1dvw;
    }
`;

export const ZoomOnHover = styled.div`
    .image-wrapper {
        transform: scale(1.01);
        transition: transform 0.2s ease-in-out;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center center;
    }
    &:hover {
        .image-wrapper {
            transform: scale(1.1);
        }
        .show-on-hover {
            opacity: 1;
        }
    }
    width: 100%;
    overflow: hidden;
    position: relative;
    border-radius: ${baseRadius}px;
    object-fit: cover;

    &.disabled {
        pointer-events: none;
    }
    @media ${props => props.theme.device.mobileSmall} {
        border-radius: ${mobileRadius}px;
    }
`;

export const Hero = styled(ZoomOnHover)`
    aspect-ratio: 1.5/1;
`;

export const HeroImage = styled.img``;

export const ThumbsContainer = styled.div`
    display: grid;
    grid-template-columns: ${({columnNum}) => `repeat(${columnNum}, 1fr)`};
    grid-auto-rows: min-content;
    gap: 1.5dvw;
    overflow: hidden;
    transition: max-height ease-out 0.4s;
    @media ${({theme: {device}}) => device.desktop} {
        gap: 0.75dvw;
    }
`;

export const ThumbWrapper = styled(ZoomOnHover)`
    width: fit-content;
    position: relative;
    max-width: 100%;
    width: 100%;
    aspect-ratio: ${({aspectRatio}) => aspectRatio};
    border-width: ${(props) => {
        return '2px';
    }};
    @media ${props => props.theme.device.mobileSmall} {
        cursor: pointer;
        & .show-on-hover {
            display: none;
        }
    }
`;

export const Thumb = styled.div``;

// Expand gallery
export const GalleryExpander = styled.div`
    width: 100;
    border-bottom: 2px solid ${({theme}) => theme.colors.labelBorder};
    position: relative;
    margin: 10px 0 45px;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const ExpanderThumbsNumText = styled(H5)`
    color: ${({theme}) => theme.colors.main};
`;

export const ExpandBt = styled.img`
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    margin-bottom: -20px;
    margin-top: 15px;
    ${(props) =>
		props.expanded &&
		css`
			transform: rotate(-180deg);
		`}
`;

// Buttons:
const GalleryButton = styled.div`
    position: absolute;    
    opacity: 0;
    cursor: pointer;
    z-index: 100;
    transition: all 0.2s ease-in-out;
    background-repeat: no-repeat;
    background-size: contain;
    scale: 1;
    width: ${buttonBaseSize}px;
    height: ${buttonBaseSize}px;

    @media ${props => props.theme.device.mobileSmall} {
        width: ${mobileButtonBaseSize}px;
        height: ${mobileBigButtonBaseSize}px;
        opacity: 1;
    }
    &:hover {
        scale: 1.2;
    }
`

export const HeroNextBt = styled(GalleryButton)`    
    top: calc(50% - ${Math.floor(buttonBaseSize / 2)}px);
    right: 20px;
    background-image: url(${forwardIcon});
    @media ${props => props.theme.device.mobileSmall} {       
        top: calc(50% - ${Math.floor(mobileButtonBaseSize/2)}px);
        right: 10px;
    }
`;

export const HeroPrevBt = styled(HeroNextBt)`
    left: 20px;
    transform: rotate(180deg);
    @media ${props => props.theme.device.mobileSmall} {
        left: 10px;
    }
`;

export const HeroShowModalBt = styled(GalleryButton)`
    right: 32px;
    top: 32px;
    width: ${bigButtonBaseSize}px;
    height: ${bigButtonBaseSize}px;
    background-image: url(${zoomIcon});

    @media ${props => props.theme.device.mobileSmall} {
        width: ${mobileBigButtonBaseSize}px;
        height: ${mobileBigButtonBaseSize}px;
        right: 12px;
        top: 12px;
    }
`;

export const ThumbEnlargeBt = styled(GalleryButton)`
    width: ${bigButtonBaseSize}px;
    height: ${bigButtonBaseSize}px;

    top: calc(50% - ${Math.floor(bigButtonBaseSize/2)}px);
    left: calc(50% - ${Math.floor(bigButtonBaseSize/2)}px);

    background-image: url(${loupeIcon});

    @media ${props => props.theme.device.mobileSmall} {
        width: ${mobileBigButtonBaseSize}px;
        height: ${mobileBigButtonBaseSize}px;  
        top: calc(50% - ${Math.floor(mobileBigButtonBaseSize/2)}px);
        left: calc(50% - ${Math.floor(mobileBigButtonBaseSize/2)}px);
    }
`;



//Modal
export const ModalContent = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const ModalImageWrapper = styled.div`
    overflow: hidden;
    position: relative;
    width: 90vw;
    height: 90vh;
    box-shadow: 0px 0px 20px #0000004D;
    border-radius: ${baseRadius};
    max-width: ${props => props.maxWidth};
    max-height: ${props => props.maxHeight};
    > img {
        height: 100%;
        width: 100%;
        object-fit: contain;
    }
    @media ${props => props.theme.device.mobileSmall} {
        border-radius: ${mobileRadius}px;
    }
`

export const ModalNextBt = styled(HeroNextBt)`
    opacity: 1;
`
export const ModalPrevBt = styled(HeroPrevBt)`
    opacity: 1;
`

export const ModalCloseBt = styled(GalleryButton)`
    width: ${bigButtonBaseSize}px;
    height: ${bigButtonBaseSize}px; 
    right: 32px;
    top: 32px;
    opacity: 1;
    background-image: url(${closeIcon});
    @media ${props => props.theme.device.mobileSmall} {
        width: ${mobileBigButtonBaseSize}px;
        height: ${mobileBigButtonBaseSize}px;  
        top: 16px;
        right: 16px;
    }
`;