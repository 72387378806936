import React, { useCallback, useEffect, useState, forwardRef } from "react";
import { useTranslation } from "react-i18next";
import ScheduleCarousel from "components/Schedule/ScheduleCarousel";
import * as Styled from "./Offer.styles";
import useApi from "hooks/useApi";

const OfferSchedule = forwardRef(({ instanceId, generalType, currentYear, setShowScheduleButton }, ref) => {
    const { t } = useTranslation();
    const [schedule, setSchedule] = useState();
    const { offerApi } = useApi();

    const fetchSchedule = useCallback(async (id) => {
        const { data } = await offerApi.get(`instance/${id}/schedule`);
        if (data) {
            setSchedule(data);
            setShowScheduleButton(data.length > 0);
        }
    }, []);

    useEffect(() => {
        if (instanceId) {
            fetchSchedule(instanceId);
        }
    }, [instanceId]);

    return (
        <>
            {schedule?.length > 0 && (
                <Styled.ScheduleFlexContainer className='schedule-flex-container'> 
                    <Styled.OfferScheduleContainer 
                        className='offer-schedule-container'
                        ref={ref}
                    >
                        <Styled.OfferScheduleHeader className='offer-schedule-header'>
                            {`${t('offer.recruitmentSchedule')} ${currentYear}/${currentYear + 1}`}
                        </Styled.OfferScheduleHeader>
                        <ScheduleCarousel
                            schedule={schedule}
                        />
                    </Styled.OfferScheduleContainer>
                </Styled.ScheduleFlexContainer>
            )}
        </>
    );
});

export default OfferSchedule;