import { useEffect } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import InputMask from "react-input-mask";

import TextFieldForm from './TextFieldForm';

const PostalCodeInput = ({ name, isRequired, disabled, ...rest }) => {
    const { t } = useTranslation();
    const { control, trigger } = useFormContext();

    const validatePostalCode = (value) => {
        if (isRequired && (value === '' || value === undefined || value === null)) {
            return t('application.errors.fieldRequired');
        }
        if ((/_/).test(value) || value.length < 6) {
            return t('application.errors.invalidPostalCode');
        }
        return true;
    }

    return (
        <StyledPostalWrapper
            className='hour-input'
        >
            <Controller
                name={name}
                control={control}
                defaultValue=''
                rules={{ validate: validatePostalCode }}
                render={({ field, fieldState }) => {
                    return (
                        <PostalFieldContent
                            field={field}
                            fieldState={fieldState}
                            isRequired={isRequired}
                            trigger={trigger}
                            disabled={disabled}
                            {...rest}
                        />
                   )
                }}
            />
        </StyledPostalWrapper>
    );
};

export default PostalCodeInput;

const PostalFieldContent = ({ field, fieldState, isRequired, trigger, disabled, ...rest }) => {
    useEffect(() => {
        if (field.value) {
            trigger(field.name);
        }
    }, []);
    return (
        <InputMask
            mask="99-999"
            value={field.value}
            onChange={field.onChange}
            onBlur={ev => {
                trigger(field.name);
            }}
            disabled={disabled}
        >
            {(inputProps) => {
                return (
                    <TextFieldForm
                        placeholder={'__-___'}
                        {...inputProps}
                        {...rest}
                        name={null}
                        isRequired={isRequired}
                        error={fieldState.error}
                        disabled={disabled}
                    />
                )
            }}
        </InputMask>
    )
}

const StyledPostalWrapper = styled.div`
    & .input-wrapper {
        max-width: 8rem;
    }
`;