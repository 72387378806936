import React, { Suspense, useState } from 'react';
import { RouterProvider } from "react-router-dom";
import { ThemeProvider } from 'styled-components';
import { GlobalStyles } from 'styles/GlobalStyles';

import AppProvider from 'context/appContext';
import AppRoutes from 'routes/AppRoutes';

import Loader from 'components/commons/Loader/Loader';

import { theme } from 'styles/theme';

import { AuthProvider } from "react-oidc-context";
import { WebStorageStateStore } from 'oidc-client-ts';

import { globalConfig } from "configuration/globalConfig";

import { useEffect } from "react";

const App = () => { 
    const data = globalConfig.get();

    const oidcConfig = {
        authority: data.REACT_APP_STS_AUTHORITY,
        client_id: data.REACT_APP_CLIENT_ID,
        redirect_uri: window.location.origin,
        post_logout_redirect_uri: " https://localhost:44366/",
        response_type: 'code',
        scope: "openid profile",
        loadUserInfo: true,
        acr_values: "tenant:" + data.REACT_APP_TENANT,
        tokenRenewal: 1,
        

        onSigninCallback: (user) => {
            console.log("the `user` prop is actually the data the app received from `/userinfo`")
            console.log(JSON.stringify(user.profile));
            window.history.replaceState(
                {},
                document.title,
                window.location.pathname
            );
        },
        userStore: new WebStorageStateStore({ store: window.localStorage }),
    };

    const clearCachedData = (minutes) => {
        const now = new Date().getTime();
        const itemsToDelete = [];
        for (const key in localStorage) {
            const strItem = localStorage.getItem(key);
            if (strItem) {
                const item = JSON.parse(strItem);
                if (item) {
                    if (item.time && item.key && item.data) {
                        if( (item.time + (minutes * 60 * 1000)) < now) {
                            itemsToDelete.push(key)
                        }
                    } else if (item.time && item.expireTime === 'null') {
                        itemsToDelete.push(key)
                    }
                    else if (item.time && item.expireTime && !isNaN(parseInt(item.expireTime))) {
                        if( item.expireTime < now) {
                            itemsToDelete.push(key)
                        }
                    }
                }
            }
        }
        if (itemsToDelete.length) {
            for (const key of itemsToDelete) {
                localStorage.removeItem(key);
            }
        }
    }


    useEffect(() => {
        try {
            clearCachedData(60);
        } catch(exception) {
        }
    }, []);

    return (
        <AuthProvider {...oidcConfig}>
            <AppProvider>
                <Suspense fallback={<Loader />} >
                        <ThemeProvider theme={theme}>
                            <GlobalStyles />
                            <RouterProvider router={AppRoutes.create()} />
                        </ThemeProvider>
                </Suspense>
            </AppProvider>
        </AuthProvider>
    );
}

export default App;

