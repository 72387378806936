function GlobalConfig() {
    let defaultConfig = {
        REACT_APP_STS_AUTHORITY:  'https://login.dev.nabor.pcss.pl',
        REACT_APP_OFFER_API_ENDPOINT: "https://offer-api.dev.nabor.pcss.pl/api",
        REACT_APP_CANDIDATE_API_ENDPOINT: "https://candidate-api.dev.nabor.pcss.pl/api",
        REACT_APP_DOCUMENT_API: "https://documents.dev.nabor.pcss.pl/api",
        REACT_APP_CLIENT_ID: "nabor.candidate",
        REACT_APP_RESPONSE_TYPE: "code",
        REACT_APP_CLIENT_SCOPE: "openid profile"
    }


    if (typeof window.ENV_GLOBAL_CONFIG !== 'undefined')
        defaultConfig = { ...defaultConfig, ...window.ENV_GLOBAL_CONFIG };

    this.get = () => defaultConfig;
}


export const globalConfig = new GlobalConfig();

