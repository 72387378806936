import { useMemo, useContext } from 'react';
import { useFormContext } from 'react-hook-form';

import { ApplicationContext } from '../Application';

const useQuestionSchoolFitering = () => {
    const { getValues } = useFormContext();
    const { units } = useContext(ApplicationContext);

    const filteredSchools = useMemo(() => {
        if (!units) return null;
        let selectedBranches = getValues('branches');
        if (units && selectedBranches?.length > 0) {
            const filteredSchools = selectedBranches.map((branchId) => {
                // filter schools besed on selected branches
                return { ...units.find( ({ schoolBranches }) => {
                    const flattenBranches = schoolBranches?.map( branch => branch.id );
                    return flattenBranches?.includes(branchId);
                }), adequateBranchId: branchId };
            }).reduce( (acc, item) => {
                // remove school duplicates
                if (acc.some( ({ id }) => id === item.id)) {
                    return acc;
                }
                return [...acc, item];
            }, []);
            return filteredSchools;
        }
    }, [units, getValues]);

    return filteredSchools;
}
export default useQuestionSchoolFitering;