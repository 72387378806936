import { Page } from "components/commons/Page/Page";
import Breadcrumbs from "components/commons/Breadcrumbs/Breadcrumbs";
import Banner from "components/commons/Banner/Banner";
import StaticPage from "components/reusable/StaticPage/StaticPage";

import { useTranslation } from "react-i18next";

import { useInstance } from "hooks/useInstance";
import useAuthentication from "hooks/useAuthentication";

import LoginOrRegisterDescription from "./parts/LoginOrRegisterDescription";
import LoginOrRegisterPanel from "./parts/LoginOrRegisterPanel";

const LoginOrRegister = () => {
  const { t } = useTranslation();
  const appType = window.location.pathname.split("/").pop();
  let biernik = "podanie";
  if (appType) {
      if (appType === "wniosek") {
          biernik = "wniosek";
      } else if (appType === "deklaracja") {
          biernik = "deklarację";
      } else if (appType === "zgloszenie") {
          biernik = "zgłoszenie";
      }
  }

  const authentication = useAuthentication();

  const login = () => {
    authentication.login();
  };

  const register = () => {
    authentication.register();
  };

  useInstance([
    {
      name: t("loginOrRegister.title", {what: biernik}),
    },
  ]);

  return (
    <Page>
      <Breadcrumbs />
      <Banner />
      <StaticPage title={t("loginOrRegister.title", {what: biernik})}>
      <LoginOrRegisterDescription login={login} register={register}/>
      <LoginOrRegisterPanel login={login} register={register}/>
      </StaticPage>
    </Page>
  );
};

export default LoginOrRegister;
