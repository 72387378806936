import React, { useEffect, useState, useMemo, useRef, memo, useLayoutEffect } from 'react';

import { useTranslation } from 'react-i18next';
import * as Styled from './Gallery.styles';

import * as consts from './constants';

import galleryExpandIcon from 'assets/images/gallery-expand.svg';

const GalleryThumbs = ({ thumbs, setCurrentBigImageIndex }) => {
    
    const { t } = useTranslation();
    const [expanded, setExpanded] = useState(false);
    const containerRef = useRef();

    const [singleThumbHeight, setSingleThumbHeight] = useState(0);

    const [maxThumbsHeight, setMaxThumbsHeight] = useState(400);

    const remainingImagesText = useMemo(() => {
        const restNum = thumbs.length - 3;
        if (restNum === 0) return '';
        if (restNum === 1) return t('unit.gallery.left1img');
        if (restNum === 2) return t('unit.gallery.left2img');
        if (restNum === 3) return t('unit.gallery.left3img');
        return `(${restNum} ${t('unit.gallery.leftMore')})`;
    }, [thumbs.length, t]);

    useEffect(() => {
        if (!thumbs.length || !singleThumbHeight) return;
        const rowNum = (Math.ceil(thumbs.length / 3));
        const maxHei = (rowNum * singleThumbHeight) + (consts.thumbsGap * (rowNum -1));
        setMaxThumbsHeight(maxHei);
    }, [singleThumbHeight, thumbs.length]);

    return (
        <>
            {
                thumbs.length > 1 && (
                    <Styled.ThumbsContainer
                        ref={containerRef}
                        expanded={expanded}
                        style={{ maxHeight: expanded ? `${maxThumbsHeight}px` : `${singleThumbHeight}px` }}
                        className='thumbs'
                        columnNum={ thumbs.length > 2 ? 3 : 2}
                    >
                        {thumbs.map((thumb, index) => (
                            <ThumbItem
                                key={`thumb--${index}`}
                                thumb={thumb}
                                index={index}
                                setCurrentBigImageIndex={setCurrentBigImageIndex}
                                setThumbHeight={index === 0 ? setSingleThumbHeight : null}
                                aspectRatio={ thumbs.length === 2 ? '3 / 1.5' : '4 / 3' }
                            />
                        ))}
                    </Styled.ThumbsContainer>
                )
            }

            {thumbs.length > 3 && (
                <Styled.GalleryExpander>
                    <Styled.ExpanderThumbsNumText>
                        {!expanded
                            ? <>{t('unit.gallery.showMore')} {remainingImagesText}</>
                            : <>{t('unit.gallery.showFewer')}</>
                        }
                    </Styled.ExpanderThumbsNumText>
                    <Styled.ExpandBt
                        onClick={() => setExpanded(state => !state)}
                        expanded={expanded}
                        src={galleryExpandIcon}
                        alt='gallery-expand button'
                    />
                </Styled.GalleryExpander>
            )}
        </>
    )
}

export default memo(GalleryThumbs);

const ThumbItem = memo(({
    thumb,
    setCurrentBigImageIndex,
    index,
    setThumbHeight,
    aspectRatio,
}) => {
    const itemRef = useRef();
    useLayoutEffect(() => {
        if (!setThumbHeight || !itemRef.current) return;
        if (itemRef.current) {
            const resizeObserver = new ResizeObserver(entries => {
                for (const entry of entries) {
                    setThumbHeight(entry.target.offsetHeight);
                }
            });
            resizeObserver.observe(itemRef.current);
            return () => {
                resizeObserver.disconnect();
            };
        }
    }, [setThumbHeight]);

    return (
        <Styled.ThumbWrapper
            className='thumb-wrapper'
            onClick={() => setCurrentBigImageIndex(index)}
            ref={itemRef}
            aspectRatio={aspectRatio}
        >
            <Styled.ThumbEnlargeBt className='show-on-hover' />
            <img className='image-wrapper' src={thumb} alt="Obrazek" />
        </Styled.ThumbWrapper>
    )
});