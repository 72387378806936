import { memo } from "react";
import { hyphenateWord } from "services/hyphenate";
import * as Styled from "./UnitsList.styled";

const UnitsListHeader = ({
    headerFeatures,
    unitsNumber,
    title,
    featuresTitle
}) => (
    <Styled.UnitHeaderWrapper>
        <Styled.UnitHeader
            quantity={headerFeatures.length}
            className='units-list-header'
        >
            <Styled.UnitHeaderText blue isProperty>
                {hyphenateWord(title)} ({unitsNumber})
            </Styled.UnitHeaderText>

            { headerFeatures?.length === 1 && (
                <Styled.UnitHeaderFeatures blue noBorder>
                    { headerFeatures[0]  }
                </Styled.UnitHeaderFeatures>
            )}

            { headerFeatures.length > 1 && (
                <>
                    <Styled.UnitHeaderFeatures blue noBorder>
                        { headerFeatures.length === 1 ? headerFeatures[0] : featuresTitle }
                    </Styled.UnitHeaderFeatures>
                    
                    <Styled.UnitHeaderText blue empty />
                    {headerFeatures.map((el, idx) => {
                        return (
                            <Styled.UnitHeaderText
                                key={`header-features-${idx}`}
                            >
                                {hyphenateWord(el)}
                            </Styled.UnitHeaderText>
                        )
                    })}
                </>
            )}
        </Styled.UnitHeader>
    </Styled.UnitHeaderWrapper>
);
export default memo(UnitsListHeader);