import React from 'react';
import SectionFactory from '../sections/SectionFactory';

const StartModule = ({ data, disabled }) => {
    return (
        <>
            {data.sections.map((section, index) => {
                return (
                    <SectionFactory
                        section={section}
                        key={`${section.type}__${index}`}
                        
                        disabled={disabled}
                    />
                )
            })}
        </>
    );
}

export default StartModule;