import styled from "styled-components";

import { H4 as CommonH4 } from "styles/common.styles";
import { H5 as CommonH5 } from "styles/common.styles";


export const H4 = styled(CommonH4)`
  color: ${({ theme }) => theme.colors.grey90};
`;

export const H5 = styled(CommonH5)`
  color: ${({ theme }) => theme.colors.grey90};
`;

export const AccountContainer = styled.div`
  p {
    color: ${({ theme }) => theme.colors.grey90};
    font-size: 1.6rem;
    letter-spacing: 0.48px;
  }
  a {
    font-size: 1.8rem;
    font-weight: 600;
    @media ${({ theme }) => theme.device.mobile} {
      font-size: 1.4rem;
    }
  }
`;

export const DeleteAccountContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  gap: 3rem;
  padding-bottom: 4rem;
`;

export const DeleteAccountHeader = styled.div`
  padding-bottom: 2rem;
`;

export const DeleteAccountDescription = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const DeleteAccountInformation = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  p {
    font-weight: bold;
  };

  p::before {
    content: "!";
    color: red;
    background-color: white;
    border: 1px solid red;
    border-radius: 50%; 
    margin-right: 0.5rem;
    margin-left: -0.5rem;
    margin-bottom: 0.2rem;
    padding: 0 10px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: 20px;
    width: 20px;
    font-size: 12px;
    vertical-align: middle
  };
`;

export const DeleteAccountLink  = styled.button`
  display: inline;
  background: none;
  color: ${({ theme }) => theme.colors.main};
  font: inherit;
  font-weight: 600;
  font-size: 1.8rem;
  text-decoration: none;
  border: none;
  cursor: pointer;
  padding: 0;
  margin: 0;
  width: fit-content;
  height: fit-content;

  &:hover,
  &:focus {
    text-decoration: underline;
  }

  &:focus-visible {
        outline: 1.5px solid ${props => (props.reverse === true ? props.theme.colors.white : props.theme.colors.grey90)};
        outline-offset: 2px;
        padding: 0.1rem;
        border-radius: 5px;
    }
`