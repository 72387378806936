import React, { memo, useEffect } from "react";
import { useTranslation } from "react-i18next";

import UnitsListHeader from "./UnitsListHeader";
import UnitInstance from './UnitInstance';
import useUnitList from './useUnitList';

import * as Styled from "./UnitsList.styled";

const UnitsList = ({
    units,
    instance,
    setGeoModalUnit
}) => {
    const { t } = useTranslation();

    /// ALL SETTINGS moved to hook;
    const {
        headerFeatures,
        getUnitFeaturesBranches,
        nurseriesPublicSegregation,
        featuresTitle,
    } = useUnitList(instance);

    const GroupUnits = ({ renderList }) => {
        if (nurseriesPublicSegregation) {
            const unitTypes = [
                { name: "public", condition: 'isPublic' },
                { name: "private", condition: '!isPublic' },
            ];
            return unitTypes.map(
                ({ name, condition }) => {
                    const filteredUnits = units.filter(unit => condition.startsWith("!") ? !unit[condition.substring(1)] : unit[condition]);
                    if (filteredUnits.length > 0) {
                        return (
                            <React.Fragment key={name}>
                                <Styled.UnitTypeBar
                                    className='unit-type-bar'
                                >
                                    {`${t(`units:${name}`)} (${filteredUnits?.length})`}
                                </Styled.UnitTypeBar>
                                {renderList(filteredUnits)}
                            </React.Fragment>
                        )
                    }
                    return null;
                }
            );
        }
        return renderList(units);
    }

    return (
        <Styled.UnitList 
            className='unit-list'
        >
            <UnitsListHeader
                headerFeatures={headerFeatures}
                unitsNumber={units.length}
                title={t("units:header.name")}
                featuresTitle={featuresTitle}
            />
            {nurseriesPublicSegregation && (
                <GroupUnits
                    renderList={(list) => {
                        return (
                            <UnitMapList
                                units={list}
                                linkToUnitPrefix={`/${instance.regionUrl}/${instance.typeUrl}/info/`}
                                getUnitFeaturesBranches={getUnitFeaturesBranches}
                                setGeoModalUnit={setGeoModalUnit}
                            />
                        );
                    }}
                />
            )}

            {!nurseriesPublicSegregation && (
                <UnitMapList
                    units={units}
                    linkToUnitPrefix={`/${instance.regionUrl}/${instance.typeUrl}/info/`}
                    getUnitFeaturesBranches={getUnitFeaturesBranches}
                    setGeoModalUnit={setGeoModalUnit}
                />
            )}
        </Styled.UnitList>
    );
};

export default memo(UnitsList);

const UnitMapList = ({
    units,
    linkToUnitPrefix,
    setGeoModalUnit,
    getUnitFeaturesBranches,
}) => {
    return (
        <>
            {units.map((unit) => {
                const { features, branches, unitAdditionalInfo } = getUnitFeaturesBranches(unit);
                return (
                    <UnitInstance
                        key={unit.id}
                        unit={unit}
                        linkToUnitPrefix={linkToUnitPrefix}
                        features={features}
                        branches={branches}
                        setGeoModalUnit={setGeoModalUnit}
                        unitInfo={unitAdditionalInfo}
                    />
                )
            })}
        </>
    )
};