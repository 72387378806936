import ScheduleBlock from './ScheduleBlock';
import { useState } from 'react';
import * as Styled from './ScheduleCarousel.styled'

const ScheduleCarousel = ({ schedule }) => {
    const [index, setIndex] = useState(0);
    const prev = () => { setIndex(index - 1) }
    const next = () => { setIndex(index + 1) }

    if (schedule) {
        schedule.sort((a, b) => a.order - b.order);
    }

    return (
        <Styled.CarouselWrapper>
            <Styled.Carousel items={schedule.length}>
                <Styled.NavArrow
                    onClick={prev}
                    disabled={index <= 0}
                    left={true}
                />
                <Styled.CarouselContent
                    offset={index}
                    items={schedule.length}
                    row={1}
                    draggable
                    className='schedule-timeline'
                >
                    {schedule.map((item) => (
                        <ScheduleBlock
                            key={item.id}
                            element={item}
                        />
                    ))}
                </Styled.CarouselContent>
                <Styled.NavArrow
                    className='nav-arrow nav-arrow-right'
                    onClick={next}
                    disabled={index >= schedule.length - 4}
                />
            </Styled.Carousel>
        </Styled.CarouselWrapper>
    );
}


export default ScheduleCarousel;