import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import SectionFactory from '../sections/SectionFactory';

const PreferencesModule = ({ setModuleData }) => {
    const { t } = useTranslation();
    useEffect(() =>{
        setModuleData({
            moduleName: t('application.preferences.title'),
            moduleDescription: t('application.preferences.description'),
        });
        return () => {
            setModuleData(null);
        }
    }, []);
    return (
        <SectionFactory 
            section={{section: 'Wybierz placówkę',  type: 'PreferencesSection'}} 
        />
    );
}

export default PreferencesModule;