import { useState, useMemo, useContext, useCallback, useRef, useLayoutEffect, useEffect } from 'react';
import { useLocation } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import * as Styled from '../Application.styles';
import { Button } from 'components/commons/Button/Button';
import { ButtonText } from 'components/commons/Button/ButtonText';

// import useModuleValidation, { ModuleValidationContext } from '../utils/useModuleValidation';
import { ApplicationContext } from '../Application';

import StartModule from './StartModule'; // 0- Start, strona startowa - regulamin lub owiadczenia
import PersonalModule from './PersonalModule'; // 1- Personal, Dane osobowe kandydata i rodziców/opiekunów
import ChooserModule from './ChooserModule'; // 2- Chooser, Wybór przedszkola na deklaracji lub szkoły obwodowej na zgłoszeniu
import PreferencesModule from './PreferencesModule'; // 3- Preferences, Strona z wyborem preferencji
import QuestionsModule from './QuestionsModule'; // 4- Questions, Zakładka z pytaniami
import SummaryModule from './SummaryModule'; // 5- Summary Ostatnia strona  - zapis podania, zgody do zaznaczenia

import * as ModuleStyled from './ModuleFactory.styles';

import { GroupKind } from 'utils/enums';

const ModuleFactory = ({
    module,
    formIsDirty,
    maxStep,
}) => {
    const { t } = useTranslation();
    const [moduleData, setModuleData] = useState(null);
    const { pathname } = useLocation();

    const moduleButtonsRef = useRef();

    const {
        currentStep,

        autoSequenceValidation,
        formSaveable,
        onSaveForm,

        formReadOnly: disabled,

        onSubmitEntireForm: onSubmitForm,
        instantBackToAppList,

        onNextModule,

        setBottomBarHeight,

        moduleError,
    } = useContext(ApplicationContext);

    const lastStep = currentStep === maxStep;

    useLayoutEffect(() => {
        // set bottom bar height in useApplication
        if (moduleButtonsRef?.current) setBottomBarHeight(moduleButtonsRef.current.offsetHeight);
        const handleResize = () => {
            if (moduleButtonsRef?.current) setBottomBarHeight(moduleButtonsRef.current.offsetHeight);
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const CurrentModule = useMemo(() => {
        switch (module?.kind) {
            case GroupKind.Start:
                return StartModule;
            case GroupKind.Personal:
                return PersonalModule;
            case GroupKind.Chooser:
                return ChooserModule;
            case GroupKind.PreQuestions:
                return QuestionsModule; // will eventually be changed to prequestions
            case GroupKind.Preferences:
                return PreferencesModule;
            case GroupKind.Questions:
                return QuestionsModule;
            case GroupKind.Summary:
                return SummaryModule;
            default:
                return PersonalModule;
        }
    }, [module]);

    return (
        <Styled.ModuleWrapper>
            <Styled.ModuleHeader>
                <Styled.ModuleTitle>{
                    module?.group ||
                    moduleData?.moduleName ||
                    'Brak tytułu'
                }</Styled.ModuleTitle>
                {moduleData?.moduleDescription && (
                    <Styled.ModuleDescription>
                        {moduleData.moduleDescription}
                    </Styled.ModuleDescription>
                )}
            </Styled.ModuleHeader>
            <CurrentModule
                data={module}
                setModuleData={setModuleData}
                disabled={disabled}
            />
            <ModuleStyled.ModuleButtonsWrapper
                className='module-buttons-wrapper'
                ref={moduleButtonsRef}
            >
                {moduleError && (
                    <ModuleStyled.StyledModuleErrorText
                        isError={moduleError.isError}
                    >
                        {moduleError.text}
                    </ModuleStyled.StyledModuleErrorText>
                )}

                {!disabled && (
                    <>
                        <ButtonText
                            type="button"
                            to={`/${pathname.split('/').slice(1, 3).join('/')}`}
                            className='cancel-button'
                            bold
                        >
                            {t('application.cancelApplication')}
                        </ButtonText>
                        {lastStep && (
                            <Button
                                type="button"
                                secondary
                                onClick={ () => autoSequenceValidation.startAutoValidation(0, maxStep, maxStep)}
                            >
                                {t('application.checkApp')}
                            </Button>
                        )}

                        {!lastStep && (
                            <Button
                                type="button"
                                secondary
                                onClick={onSaveForm}
                                disabled={formSaveable === false || !formIsDirty}
                            >
                                {t('application.saveForm')}
                            </Button>
                        )}
                    </>
                )}

                {disabled && !lastStep && (
                    <Button
                        type="button"
                        onClick={instantBackToAppList}
                        secondary
                    >
                        {t('application.backToAppList')}
                    </Button>
                )}

                <Button
                    type="button"
                    onClick={lastStep
                        ? (disabled ? instantBackToAppList : onSubmitForm)
                        : onNextModule
                    }
                >
                    {lastStep
                        ? (disabled 
                            ? t('application.backToAppList') 
                            : t('application.submit'))
                        : t('application.goFurther')
                    }
                </Button>
            </ModuleStyled.ModuleButtonsWrapper>
        </Styled.ModuleWrapper>
    )
}
export default ModuleFactory;