import React, { useState, useEffect } from "react";
import parse from "html-react-parser";
import { useTranslation } from "react-i18next";

import useApi from "hooks/useApi";
import { createDictFromArray, getDate } from "utils/utils";

import * as Styled from "./Branch.styles";

import {
    languageModes as langModesConst,
    languages as langsConst,
    subjects as subjectsConst,
    professions as professionsConst,
} from 'utils/constants';

const BranchContent = ({ branchId, showFreePlaces, setBranchData = null }) => {
    const { t } = useTranslation();
    const { offerApi } = useApi();
    const [branch, setBranch] = useState();
    const [languages] = useState(
        createDictFromArray(
            langsConst,
            "id",
            "label"
        )
    );
    const [languageModes] = useState(
        createDictFromArray(
            langModesConst,
            "id",
            "label"
        )
    );

    const [subjects] = useState(
        createDictFromArray(
            subjectsConst,
            "id",
            "name"
        )
    );
    const [professions] = useState(
        createDictFromArray(
            professionsConst,
            "id",
            "name"
        )
    );

    const getLanguageName = (id) => {
        return languages ? languages[id] || "Nieznany" : null;
    };

    const getLanguageMode = (id) => {
        return languageModes ? languageModes[id] || "Nieznany" : null;
    };

    const getSubjectName = (id) => {
        return subjects ? subjects[id] || "Nieznany" : null;
    };

    const getProfessionName = (id) => {
        return professions ? professions[id] || "Nieznany" : null;
    };

    useEffect(() => {        
        const fetchBranch = async (id) => {
            const { data, error } = await offerApi.get(`unit/branch/${id}`);
            setBranch(data);
            if (setBranchData){
                setBranchData(data);
            }
        };
        if (branchId) {
            fetchBranch(branchId);
        }        
    }, [branchId]);

    //[Język polski, Matematyka]
    const menCompatibleSubjects = [1, 108];

    if (!branch) {
        return null;
    }

    return (
        <div className='branch-content'>
            <Styled.BranchSectionContainer>
                {branch.description && (
                    <Styled.BranchSection>
                        <Styled.BranchSectionHeading>
                            {t("units:branches.sections.description")}
                        </Styled.BranchSectionHeading>
                        {parse(branch.description)}
                    </Styled.BranchSection>
                )}

                {(branch.availableTo || showFreePlaces) && (
                    <Styled.BranchSection>
                        <Styled.BranchSectionHeading>
                            {t("units:branches.sections.recruitment")}
                        </Styled.BranchSectionHeading>
                        {branch.availableTo && (
                            <Styled.BranchSectionDict>
                                <strong>
                                    {t("units:branches.features.availableTo")}:
                                </strong>
                                <p>{getDate(branch.availableTo)}</p>
                            </Styled.BranchSectionDict>
                        )}
                        {showFreePlaces && (
                            <Styled.BranchSectionDict>
                                <strong>
                                    {t("units:branches.features.freePlaces")}:
                                </strong>
                                <p>{branch.freePlaces}</p>
                            </Styled.BranchSectionDict>
                        )}
                    </Styled.BranchSection>
                )}

                {(branch.awardedSubjectsExt) && (
                    <Styled.BranchSection>
                        <Styled.BranchSectionHeading>
                            {t("units:branches.sections.awardedSubjects")}
                        </Styled.BranchSectionHeading>
                        <Styled.BranchSectionList>
                            {branch.menCompatible &&
                                menCompatibleSubjects.map((id) => (
                                    <li key={`subject-${id}`}>{getSubjectName(id)}</li>
                                ))}
                            {branch.awardedSubjectsExt &&
                                Object.values(branch.awardedSubjectsExt).map((subjects, index) => (
                                    <li key={index}>{
                                        subjects.map((id)=>getSubjectName(id)).join(", ")
                                    }</li>
                                ))}
                        </Styled.BranchSectionList>
                    </Styled.BranchSection>
                )}

                {branch.extendedSubjectsExt && (
                    <Styled.BranchSection>
                        <Styled.BranchSectionHeading>
                            {t("units:branches.sections.extendedSubjects")}
                        </Styled.BranchSectionHeading>
                        <Styled.BranchSectionList>
                            {branch.extendedSubjectsExt &&
                                Object.values(branch.extendedSubjectsExt).map((subjects, index) => (
                                    <li key={index}>{
                                        subjects.map((id)=>getSubjectName(id)).join(", ")
                                    }</li>
                                ))}
                        </Styled.BranchSectionList>
                    </Styled.BranchSection>
                )}

                {branch.professions && (
                    <Styled.BranchSection>
                        <Styled.BranchSectionHeading>
                            {t("units:branches.sections.professions")}
                        </Styled.BranchSectionHeading>
                        <Styled.BranchSectionList>
                            {branch.professions.map((profession) => (
                                <li key={profession}>
                                    {getProfessionName(profession)}
                                </li>
                            ))}
                        </Styled.BranchSectionList>
                    </Styled.BranchSection>
                )}

                {branch.languages && (
                    <Styled.BranchSection>
                        <Styled.BranchSectionHeading>
                            {t("units:branches.sections.languages")}{" "}
                            {` (${branch.languages.length ?? 0})`}
                        </Styled.BranchSectionHeading>
                        <Styled.BranchSectionList>
                            {branch.languages.map((lang) => (
                                    <li className="lowercase"
                                        key={`lang-${lang.id_DictLanguage}`}
                                        title={getLanguageMode(lang.id_DictLanguageMode)}
                                    >
                                        {getLanguageName(lang.id_DictLanguage)}
                                    </li>
                                )
                            )}
                        </Styled.BranchSectionList>
                    </Styled.BranchSection>
                )}
            </Styled.BranchSectionContainer>
        </div>
    )
}

export default BranchContent;