
import React, { useContext } from 'react';
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';

import { AppContext } from 'context/appContext';

import  Select  from 'components/commons/Select/Select';

import { cities } from 'utils/utils';

import * as Styled from './NavigationSection.styles';
import * as Text from 'components/commons/Text/Text';

const NavigationSection = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();

    const { instances } = useContext(AppContext);

    const onChange = (val, txt) => {
        navigate(`/${val}`);
    }

    if (!instances) return null

    return (
        <Styled.NavigationSection
            className='navigation-section'
        >
            <Styled.NavigationSectionWrapper
                className='navigation-section-wrapper'
            >
                <Styled.Title>
                    <Text.TitleMain>{t('navigationSection.title')}</Text.TitleMain>
                </Styled.Title>
                <Styled.SelectContainer>
                    <Select round onChange={onChange} className="select">
                        <option>{t('navigationSection.placeholder')}</option>
                        {
                            cities(instances) && cities(instances).map(({id, name}, idx) =>
                                <option key={idx} value={ id }>{ name }</option>
                            )
                        }
                    </Select>
                </Styled.SelectContainer>
            </Styled.NavigationSectionWrapper>
        </Styled.NavigationSection>
    )
};

export default NavigationSection;