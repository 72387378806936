import styled, { css } from "styled-components";
import { Link } from "react-router-dom";

export const Logo = styled.div`
    ${({useNarrowLogo}) => useNarrowLogo && css`
        @media ${({ theme }) => theme.device.desktopOnly} {
            max-width: 100px;
            overflow: hidden;
        }
    `}
    & img {
        height: ${({isLocal}) => isLocal ? "5.7rem" : "12rem"};

        @media ${({ theme }) => theme.device.touch} {
            height: ${({isLocal}) => isLocal ? "5.7rem" : "12rem"};
        }

        @media ${({theme}) => theme.device.mobile} {
            height: 4rem;
        }
    }
`;

export const LocalLogoLabel = styled.label`
  font-size: 3.6rem;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.black};

  @media ${({ theme }) => theme.device.mobile} {
    font-size: 1.5rem;
  }
  @media ${({ theme }) => theme.device.desktopOnly} {
    max-width: calc(100vw - 86rem);
  }
`;

export const LogoContainer = styled.div`
  display: flex;
  position: relative;
  padding-left: 5rem;

  &::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 1px;
    background-color: #707070;
  }

  @media ${({theme}) => theme.device.desktopOnly} {
    padding-left: 2rem;
  }

  @media ${({theme}) => theme.device.touch} {
    padding-left: 2rem;
  }

  @media ${({theme}) => theme.device.mobile} {
    padding: 0;
    gap: 0.9rem;

    &::before {
        display: none;
    }
  }
`;

export const LogoLink = styled(Link)`
  display: flex;
  align-items: center;
  gap: 2.3rem;

  @media ${({theme}) => theme.device.desktopOnly} {
    gap: 1.5rem;
  }

  @media ${({theme}) => theme.device.mobile} {
    gap: 0.9rem;
  }
`;
