import React, { useMemo } from 'react';
import { NavLink, useParams } from "react-router-dom";
import url from "routes/routes";

import { useTranslation } from "react-i18next";

import useAuthentication from "hooks/useAuthentication";

import { parseAppType } from "utils/utils";

import * as Styled from "./InstanceMenu.styles";

const InstanceMenuItems = ({
    instance,
    itemClick,
    isAuthenticated,
    mobileMenuActive,
}) => {
    const params = useParams();
    const pathPrefix = "/" + params.city + "/" + params.generalType;
    const { t } = useTranslation();
    const { city, generalType } = useParams();
    const authentication = useAuthentication();

    const logout = () => {
        authentication.logout();
    };

    const items = [
        {
            title: t("menu.ourInstitutions"),
            // title: instance
            //     ? instance.instanceUrl
            //         ? `${instance.instanceName}`
            //         : `${t(`unitTypes.${instance.typeUrl}`)} ${instance.instanceName}`
            //     : "x",
            href: pathPrefix + "/oferta",
        },
    ];

    if (instance?.unitGeneralType === 9) {
        items.push({
            title: t("menu.pointsCalculator"),
            href: pathPrefix + "/kalkulator",
        });
    }

    if (instance?.allowPublicSubbmissions && process.env.REACT_APP_NOLOGIN_MODE !== 'true') {
        let title = "Wypełnij wniosek";
        if (instance?.unitGeneralType === 11) {
            title = "Wypełnij zgłoszenie";
        }
        items.push({
            title: title,
            href: pathPrefix + parseAppType(3),
        });
    }

    if (instance?.allowPublicClaimsAndApplications && process.env.REACT_APP_NOLOGIN_MODE !== 'true' ) {
        let appType = 3;
        let fillForm = "";
        if (instance?.unitGeneralType === 3) {
            fillForm = "Wypełnij zgłoszenie";
            appType = 2;
        } else {
            fillForm = "Wypełnij deklarację";
            appType = 1;
        }
        items.push({ title: fillForm, href: pathPrefix + parseAppType(appType) });
    }

    if (instance?.documentsAvailable) {
        items.push({ title: t("menu.documents"), href: pathPrefix + "/dokumenty" });
    }
    if (instance?.newsAvailable) {
        items.push({ title: t("menu.news"), href: pathPrefix + "/aktualnosci" });
    }
    if (instance?.faqAvailable) {
        items.push({ title: t("menu.faq"), href: pathPrefix + "/faq" });
    }
    if (instance?.contactAvailable) {
        items.push({ title: t("menu.contactShort"), href: pathPrefix + "/kontakt" });
    }
    items.push({ title: t("menu.help"), href: pathPrefix + "/pomoc" });

    const menuItems = useMemo(() => {
        let authItems = [];
        if (mobileMenuActive) {
            if (isAuthenticated) {
                authItems.push({ title: t("menu.application"), href: `/${city}/${generalType}/wnioski` });
                authItems.push({ title: t("menu.myData"), href: `/${city}/${generalType}/konto` });
                authItems.push({ title: t("menu.logout"), href: null, handler: logout, className: "logout" });
            } else {
                authItems.push({ title: t("menu.signup"), href: null, handler: authentication.register });
                authItems.push({ title: t("menu.login"), href: null, handler: authentication.login });
            }
        }
        return process.env.REACT_APP_NOLOGIN_MODE !== 'true' ? authItems : [];
    }, [mobileMenuActive]);

    return (
        <>
            <ul>
                {items.map((item, index) => (
                    <li key={index}>
                        <Styled.StyledNavLink 
                            to={item.href || "#"} 
                            onClick={item.handler || itemClick}
                        >
                            {item.title}
                        </Styled.StyledNavLink>
                    </li>
                ))}
            </ul>
            {menuItems.length > 0 && (
                <Styled.MyAccountMenu>            
                    {menuItems.map((item, index) => (
                        <Styled.AuthItems key={`auth-item-${index}`} className={item.className}>                            
                            {item.href ? (
                                <Styled.StyledNavLink
                                    to={item.href} 
                                    onClick={item.handler || itemClick}
                                >
                                    {item.title}
                                </Styled.StyledNavLink>
                            ) : (
                                <Styled.NavHandler 
                                    onClick={item.handler}
                                >
                                    {item.title}
                                </Styled.NavHandler>
                            )}
                        </Styled.AuthItems>
                    ))}
                </Styled.MyAccountMenu>
            )}
        </>
    );
};


export default InstanceMenuItems;
