
import React, { useState, useEffect, useMemo, useCallback, memo, useContext } from 'react';
import { useForm, Controller, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { SelectComponent } from './SelectForm';
import TextFieldForm from './TextFieldForm';

import { ApplicationContext } from '../Application';

import useApi from "hooks/useApi";

const provinces = [
    { value: '2', label: 'dolnośląskie', field: 'province' },
    { value: '4', label: 'kujawsko-pomorskie', field: 'province' },
    { value: '6', label: 'lubelskie', field: 'province' },
    { value: '8', label: 'lubuskie', field: 'province' },
    { value: '10', label: 'łódzkie', field: 'province' },
    { value: '12', label: 'małopolskie', field: 'province' },
    { value: '14', label: 'mazowieckie', field: 'province' },
    { value: '16', label: 'opolskie', field: 'province' },
    { value: '18', label: 'podkarpackie', field: 'province' },
    { value: '20', label: 'podlaskie', field: 'province' },
    { value: '22', label: 'pomorskie', field: 'province' },
    { value: '24', label: 'śląskie', field: 'province' },
    { value: '26', label: 'świętokrzyskie', field: 'province' },
    { value: '28', label: 'warmińsko-mazurskie', field: 'province' },
    { value: '30', label: 'wielkopolskie', field: 'province' },
    { value: '32', label: 'zachodniopomorskie', field: 'province' },
    { value: '1', label: 'Zagranica', field: 'province' }
];

// const terytFields = ['province', 'county', 'community', 'locality', 'street'];
// const refNames = ['provinces', 'counties', 'communities', 'Localities', 'streets'];

// const textFields = ['locality', 'street'];

const terytSettings = [
    { name: 'province', apiRefName: null, optionsTerytRef: null, mainId: null, mainName: null, label: null, description: null, tLabel: 'selectProvince', placeholder: 'selectProvince' },

    { name: 'county', apiRefName: 'counties', optionsTerytRef: 'province', mainId: null, mainName: null, label: null, description: null, tLabel: 'selectCounty', emptyPlaceholder: 'beforeSelectCounty', placeholder: 'selectCounty' },

    { name: 'community', apiRefName: 'communities', optionsTerytRef: 'county', mainId: 'communityId', mainName: 'communityName', label: 'communityLabel', description: 'communityDescription', tLabel: 'selectCommunity', emptyPlaceholder: 'beforeSelectCommunity', placeholder: 'selectCommunity' },

    { name: 'locality', apiRefName: 'Localities', optionsTerytRef: 'community', mainId: 'cityId', mainName: 'cityName', label: 'cityLabel', description: 'cityDescription', tLabel: 'selectLocality', emptyPlaceholder: 'beforeSelectLocality', placeholder: 'selectLocality' },

    { name: 'street', apiRefName: 'streets', optionsTerytRef: 'locality', mainId: 'streetId', mainName: 'streetName', label: 'streetLabel', description: 'streetDescription', tLabel: 'selectStreet', emptyPlaceholder: 'beforeSelectStreet', placeholder: 'selectStreet' },

];

const TerytSelect = ({
    terytData,
    isRequired,
    disabled = false,
    ...terytRest
}) => {
    const { instance, formValidationMethods: { addFieldToValidation, removeFieldFromValidation } } = useContext(ApplicationContext);
    const [uniqueStreetTeryt, setUniqueStreetTeryt] = useState();
    
    const [noStreetsInLocality, setNoStreetsInLocality] = useState(false);
    
    const [validationCount, setValidationCount] = useState(0);
    
    const { register: rootRegister, unregister: rootUnregister, setValue: rootSetFormValue, getValues: rootGetValues } = useFormContext();
    
    // const [terytValue, setTerytValue] = useState();

    const calcTerytInitialValue = useMemo(() => {
        // initial teryt taken from main form (edit or back to step)
        const communityId = rootGetValues(terytData.communityId)?.toString();
        const cityId = rootGetValues(terytData.cityId)?.toString();
        const streetId = rootGetValues(terytData.streetId)?.toString();
        const cityName = rootGetValues(terytData.cityName)?.toString();
        const streetName = rootGetValues(terytData.streetName)?.toString();
        if (!streetId && !cityId && !communityId ) {
            if (streetName || cityName) return '1'; // zagranica

            // GET INITIAL TERYT FROM INSTANCE
            if (instance?.terytCode) {
                if (instance.terytCode < 1) return '-1';
                if (instance.terytCode % 10000 === 0) return (instance.terytCode / 10000).toString();
                if (instance.terytCode % 100 === 0) return (instance.terytCode / 100).toString();
                return instance.terytCode.toString();
            }
            return '-1';
        }
        if (streetId && cityId) {
            if (streetId.startsWith(cityId)) {
                // street has he same locality identifier as city
                setUniqueStreetTeryt(null);
                return streetId;
            } else {
                // street has 
                setUniqueStreetTeryt(streetId);
                return cityId;
            }
        }
        return cityId || communityId || '-1';
    },[]);

    const [terytValue, setTerytValue] = useState( calcTerytInitialValue );

    const availableFields = useMemo(() => {
        // init all fields;
        return terytSettings.reduce((acc, { mainId, mainName }) => {
            const terytDataId = terytData[mainId];
            const terytDataName = terytData[mainName];
            return [...acc, ...(terytDataId ? [terytDataId] : []), ...(terytDataName ? [terytDataName] : [])];
        }, []);
    }, [terytData]);    

    const rootFieldValidate = useCallback((value, fieldName) => {
        if (value || !isRequired) {
            return true;
        }
        setValidationCount(state => state + 1);
        return false;
    }, [isRequired]);

    const registerFields = useCallback((avFields) => {
        avFields.forEach((fieldName) => {
            rootRegister(fieldName, { validate: (value) => rootFieldValidate(value, fieldName) });
            addFieldToValidation(fieldName);
        });
    }, [rootRegister, addFieldToValidation, rootFieldValidate]);

    const unRegisterFields = useCallback((avFields) => {
        avFields.forEach((fieldName) => {
            rootUnregister(fieldName);
            removeFieldFromValidation(fieldName);
        });
    }, [rootUnregister, addFieldToValidation]);

    useEffect(() => {
        // handle no streets in locality
        if (noStreetsInLocality) {
            rootUnregister(terytData.streetId);
            rootUnregister(terytData.streetName);
            removeFieldFromValidation(terytData.streetId);
            removeFieldFromValidation(terytData.streetName);
        } else {
            rootRegister(terytData.streetId, { validate: (value) => rootFieldValidate(value, terytData.streetId) });
            rootRegister(terytData.streetName, { validate: (value) => rootFieldValidate(value, terytData.streetName) });
            addFieldToValidation(terytData.streetId);
            addFieldToValidation(terytData.streetName);
        }
    }, [noStreetsInLocality]);

    useEffect(() => {
        // terytValue
        if (availableFields.length > 0 && terytValue !== '1') {
            rootUnregister(terytData.cityName, { validate: (value) => rootFieldValidate(value, terytData.streetId) });
            rootUnregister(terytData.streetName);
            removeFieldFromValidation(terytData.cityName);
            removeFieldFromValidation(terytData.streetName);
            registerFields(availableFields);
        } else {
            unRegisterFields(availableFields);
            rootRegister(terytData.cityName, { validate: (value) => rootFieldValidate(value, terytData.streetId) });
            rootRegister(terytData.streetName);
            addFieldToValidation(terytData.cityName);
            addFieldToValidation(terytData.streetName);
        }
    }, [availableFields, registerFields, terytValue]);

    const [fieldsFromInitialAlreadySet, setFieldsFromInitialAlreadySet] = useState(false);

    const setMainFormValues = useCallback(({ data, type }) => {
        if (!fieldsFromInitialAlreadySet && data.map(({value}) => value).includes(calcTerytInitialValue)) {
            setFieldsFromInitialAlreadySet(true);
        }
        if (type === 'teryt') {
            rootSetFormValue(terytData.communityId, data?.[2]?.value || null, { shouldDirty: fieldsFromInitialAlreadySet });
            rootSetFormValue(terytData.cityName, data?.[3]?.label || null, { shouldDirty: fieldsFromInitialAlreadySet });
            rootSetFormValue(terytData.cityId, data?.[3]?.value || null, { shouldDirty: fieldsFromInitialAlreadySet });
            rootSetFormValue(terytData.streetName, data?.[4]?.label || null, { shouldDirty: fieldsFromInitialAlreadySet });
            rootSetFormValue(terytData.streetId, data?.[4]?.value || null, { shouldDirty: fieldsFromInitialAlreadySet });
            if (!data?.[4]?.value.startsWith(data?.[3]?.value)) {
                setUniqueStreetTeryt(data?.[4]?.value);
            }else {
                setUniqueStreetTeryt(null);
            }
        }
        if (type === 'text') {
            rootSetFormValue(terytData.cityName, data?.locality || null, { shouldDirty: fieldsFromInitialAlreadySet });
            rootSetFormValue(terytData.streetName, data?.street || null, { shouldDirty: fieldsFromInitialAlreadySet });
        }
    }, [terytData, rootSetFormValue, fieldsFromInitialAlreadySet, calcTerytInitialValue]);

    if (!terytValue) return null;
    if (terytValue === '1') return (
        <>
            <FieldsForm
                terytData={terytData}
                setTerytValue={setTerytValue}

                setMainFormValues={setMainFormValues}
                initialValues={{ 
                    locality: rootGetValues(terytData?.cityName) || null,
                    street: rootGetValues(terytData.streetName) || null 
                }}
                disabled={disabled}
            />
        </>
    )
    return (
        <>
            <TerytForm
                terytValue={terytValue}
                setTerytValue={setTerytValue}
                isRequired={isRequired}

                terytData={terytData}

                setMainFormValues={setMainFormValues}
                initialStreetUniqueTeryt={uniqueStreetTeryt}

                noStreetsInLocality={noStreetsInLocality}
                setNoStreetsInLocality={setNoStreetsInLocality}

                validationCount={validationCount}
                disabled={disabled}
            />
        </>
    )
};

TerytSelect.displayName = 'TerytSelect';
export default TerytSelect;


const TerytForm = ({
    isRequired,
    disabled,
    terytValue,
    terytData,
    setTerytValue,
    setMainFormValues,
    initialStreetUniqueTeryt = null,

    noStreetsInLocality,
    setNoStreetsInLocality,
    validationCount,
}) => {
    const [localTerytValue, setLocalTerytValue] = useState(terytValue);
    const [streetUniqueTeryt, setStreetUniqueTeryt] = useState(initialStreetUniqueTeryt);

    const [autoFill, setAutoFill] = useState(false);

    const [lastSentChangeStringified, setLastSentChangeStringified] = useState(null);

    const { t } = useTranslation();
    const { control, getValues, setValue, trigger } = useForm({
        mode: 'onBlur',
        reValidateMode: 'onBlur',
        defaultValues: {
            province: provinces.find(province => province.value === terytValue),
            county: {},
            community: {},
            locality: {},
            street: {},
        }
    });

    const formWatch = useWatch({ control: control, name: ['province', 'county', 'community', 'locality', 'street'] });

    const handleAutoFillShortcut = useCallback((e) => {
        if (e.ctrlKey && e.shiftKey) {
            setAutoFill(true);
        }
    }, [setAutoFill]);

    useEffect(() => {
        if (process.env.REACT_APP_ENV === "production") {
            return;
        };

        document.addEventListener("keydown", handleAutoFillShortcut);

        return () => document.removeEventListener("keydown", handleAutoFillShortcut);
    }, [handleAutoFillShortcut]);

    useEffect(() => {
        /// check if autofill has reached street or locality with no streets - turn off autofill
        if (!autoFill) return;
        setAutoFill(!((noStreetsInLocality && formWatch[3]) || formWatch[4]));
    }, [autoFill, formWatch, noStreetsInLocality]);

    useEffect(() => {
        if (validationCount !== 0) {
            trigger(['province', 'county', 'community', 'locality', 'street']);
        }
    }, [validationCount, trigger]);

    useEffect(() => {
        const formWatchFiltered = formWatch.filter(field => field && field.value);
        const valuesStringified = formWatchFiltered.map(({value}) => value).join(',');
        if (valuesStringified !== lastSentChangeStringified) {
            setLastSentChangeStringified(valuesStringified);
            setMainFormValues({ type: 'teryt', data: formWatchFiltered });
        }
    }, [noStreetsInLocality, formWatch, setMainFormValues]);

    const onSelectChange = useCallback((val) => {
        if (val.field === 'province' && val.value === '1') {
            setTerytValue('1');
        }

        if (val && val.value && val.value.length > 0 && !localTerytValue.startsWith(val.value)) {
            if (val.field === 'street') {
                if (val.value.slice(0, -5) === localTerytValue) {
                    // if street has different "locality teryt" than previously selected in locality don't use it for localTerytValue
                    setLocalTerytValue(val.value);
                    setStreetUniqueTeryt(null);
                } else {
                    setStreetUniqueTeryt(val);
                }
            }
            if (val.field !== 'street' && localTerytValue !== val.value) {
                setLocalTerytValue(val.value);
            }
        }
        if (val.field) {
            if (!val.value) {
                setValue(val.field, null);
            } else {
                setValue(val.field, val);
            }
        }
        if (validationCount !== 0) trigger(val.field);
    }, [localTerytValue]);

    const terytObject = useMemo(() => {
        const base = {
            province: null,
            county: null,
            community: null,
            locality: null,
            street: null,
        }

        if (localTerytValue === -1) return base;

        if (localTerytValue.length < 3) {
            return {
                ...base,
                province: localTerytValue,
            }
        }
        if (localTerytValue.length < 5) {
            return {
                ...base,
                province: localTerytValue.slice(0, -2),
                county: localTerytValue,
            }
        }
        if (localTerytValue.length < 7) {
            return {
                ...base,
                province: localTerytValue.slice(0, -4),
                county: localTerytValue.slice(0, -2),
                community: localTerytValue,
            }
        }
        if (localTerytValue.length < 14) {
            return {
                province: localTerytValue.slice(0, -11),
                county: localTerytValue.slice(0, -9),
                community: localTerytValue.slice(0, -7),
                locality: localTerytValue,
                street: streetUniqueTeryt || null,
            }
        }
        return {
            province: localTerytValue.slice(0, -16),
            county: localTerytValue.slice(0, -14),
            community: localTerytValue.slice(0, -12),
            locality: localTerytValue.slice(0, -5),
            street: localTerytValue,
        }
    }, [localTerytValue, streetUniqueTeryt]);

    const validateSelect = useCallback((value, fieldName, isRequired) => {
        if (!isRequired || value?.value) return true;
        return t('application.errors.fieldRequired');
    }, []);

    return (
        <>
            {terytObject && terytSettings.map((fieldSettings, index) => {
                return (
                    <ControlledSelect
                        name={fieldSettings.name}
                        apiRefName={fieldSettings.apiRefName}
                        terytNumber={terytObject[fieldSettings?.name]}
                        optionsTerytNumber={terytObject[fieldSettings?.optionsTerytRef]}
                        key={`teryt${index}`}

                        validateSelect={validateSelect}
                        onChange={onSelectChange}
                        setNoStreetsInLocality={setNoStreetsInLocality}

                        label={terytData[fieldSettings?.label] || t(`application.${fieldSettings?.tLabel}`)}
                        description={terytData[fieldSettings?.description] || null}
                        emptyPlaceholder={t(`application.${fieldSettings?.emptyPlaceholder}`)}
                        placeholder={t(`application.${fieldSettings?.placeholder}`)}
                        noOptionsPlaceholder={fieldSettings.name === 'street' ? t('application.noStreets') : t('application.noOptionsMessage')}

                        control={control}
                        isRequired={isRequired}
                        getValues={getValues}
                        disabled={disabled}

                        autoFill={autoFill}
                    />
                )
            })}
        </>
    )
}

const ControlledSelect = memo(({
    name,
    terytNumber,
    optionsTerytNumber,
    apiRefName,
    setNoStreetsInLocality,

    validateSelect,
    onChange,

    label,
    description,
    placeholder,
    emptyPlaceholder,
    noOptionsPlaceholder,

    control,
    isRequired,
    getValues,
    disabled,

    autoFill = false,
}) => {
    const [options, setOptions] = useState(() => name === 'province' ? provinces : []);
    const [noOptions, setNoOptions] = useState(false);
    const { offerApi } = useApi();

    const loadFieldOptions = useCallback(async (fieldName, terytNumber) => {
        if (terytNumber === '-1') return;
        const { data } = await offerApi.get(`Teryt/${terytNumber}/${fieldName}`);
        if (data) {
            setNoOptions(data.length === 0);
            const parsedOptions = data.map(({ id, name: label }) => ({ value: id.toString(), label: label, field: name }));
            setOptions(parsedOptions);
            if (fieldName === 'streets') {
                setNoStreetsInLocality(data.length === 0);
            }
        }
    }, []);

    useEffect(() => {
        // when autofill is on - once options are loaded - if no option is selected - select random one 
        if (autoFill && options.length > 0 && !getValues(name)?.value) {
            const foundOption = options[Math.floor(Math.random() * options.length)];
            onChange(foundOption);
        }
    }, [options, autoFill, getValues]);

    useEffect(() => {
        if (name !== 'province') {
            if (optionsTerytNumber && apiRefName) {
                loadFieldOptions(apiRefName, optionsTerytNumber);
            } else {
                setOptions([]);
            }
        }
    }, [optionsTerytNumber, apiRefName, loadFieldOptions]);

    useEffect(() => {
        if (options.length > 0 && terytNumber) {
            const foundOption = options.find(element => element.value.toString() === terytNumber.toString());
            if (!foundOption) {
                // console.log(terytNumber, ' :::: ', options);
            }
            if (foundOption && foundOption.value !== getValues(name)?.value) {
                onChange(foundOption);
            }
        }
        if (!terytNumber && options.length === 1 && getValues(name)?.value !== options[0]?.value) {
            onChange(options[0]);
        }
        if (!terytNumber && options.length !== 1) {
            onChange({ field: name, value: null, label: null });
        }
    }, [options, terytNumber]);

    const momoizedPlaceholder = useMemo(() => {
        if (options.length === 0) {
            if (noOptions && noOptionsPlaceholder) {
                return noOptionsPlaceholder;
            }
            return emptyPlaceholder
        }
        return placeholder;
    }, [options, noOptions, placeholder, emptyPlaceholder, noOptionsPlaceholder]);

    return (
        <Controller
            name={name}
            control={control}
            rules={{
                validate: (val) => {
                    // console.log(name, ': ', noOptions);
                    return validateSelect(val, name, noOptions ? false : isRequired)
                }
            }}
            render={({ field, fieldState }) => {
                return (
                    <SelectComponent
                        options={options}
                        label={label}
                        placeholder={momoizedPlaceholder}
                        description={description}
                        isRequired={noOptions ? false : isRequired}
                        disabled={!options.length || disabled}
                        error={fieldState.error}
                        isSearchable

                        value={field.value}
                        onChange={onChange}
                        onBlur={field.onBlur}
                        ref={null}

                    // filterOption={createFilter({ ignoreAccents: false })}
                    />
                )
            }}
        />
    )
}, (oldState, newState) => {
    if (oldState.terytNumber !== newState.terytNumber ||
        oldState.optionsTerytNumber !== newState.optionsTerytNumber ||
        oldState.autoFill !== newState.autoFill
    ) {
        return false;
    }
    return true;
}
);


const FieldsForm = ({ terytData, setTerytValue, setMainFormValues, initialValues, disabled }) => {
    const { t } = useTranslation();
    const onSelectChange = (val) => {
        if (val.value !== '1') {
            setTerytValue(val?.value.toString());
        }
    }
    const { control, getValues } = useForm({
        mode: 'onBlur',
        reValidateMode: 'onBlur',
        defaultValues: {
            province: provinces.find(province => province.value === '1'),
            locality: initialValues?.locality || '',
            street: initialValues?.street || '',
        }
    });

    const validateTextInput = (value, fieldName, isRequired) => {
        if (value.length < 1 && isRequired) {
            return t('application.errors.fieldRequired');
        }
        setMainFormValues({ type: 'text', data: getValues() });
        return true;
    }

    return (
        <>
            {terytSettings.map((fieldSettings, index) => {
                if (index === 0) {
                    return (
                        <ControlledSelect
                            name={fieldSettings.name}
                            apiRefName={fieldSettings.apiRefName}
                            terytNumber={'1'}
                            optionsTerytNumber={'0'}
                            key={`teryt${index}`}

                            validateSelect={null}
                            onChange={onSelectChange}

                            label={terytData[fieldSettings?.label] || t(`application.${fieldSettings?.tLabel}`)}
                            description={terytData[fieldSettings?.description] || null}
                            emptyPlaceholder={t(`application.${fieldSettings?.emptyPlaceholder}`)}
                            placeholder={t(`application.${fieldSettings?.placeholder}`)}

                            control={control}
                            isRequired={true}
                            getValues={getValues}
                            disabled={disabled}
                        />
                    )
                }
            })}
            <ControlledTextField
                name={'locality'}
                control={control}
                isRequired={true}
                label={'Miasto'}
                validateTextInput={validateTextInput}
                disabled={disabled}
            />
            <ControlledTextField
                name={'street'}
                control={control}
                isRequired={false}
                label={'Ulica'}
                validateTextInput={validateTextInput}
                disabled={disabled}
            />
        </>
    )
}

const ControlledTextField = memo(({ name, control, isRequired, label, validateTextInput, disabled }) => {
    return (
        <Controller
            name={name}
            control={control}
            defaultValue={''}
            rules={{
                validate: (val) => validateTextInput(val, name, isRequired)
            }}
            render={({ field: { name, onBlur, ...fieldRest }, fieldState: { error } }) => {
                return (
                    <TextFieldForm
                        label={label}
                        isRequired={isRequired}
                        {...fieldRest}
                        error={error}
                        onBlur={onBlur}
                        disabled={disabled}
                    />)
            }
            }
        />
    )
}, () => true);