import { Link } from 'components/commons/Link/Link';
import styled from 'styled-components';

export const Header = styled.header`
    position: relative;
    /* padding: 2rem 0; */
    /* padding-bottom: 1rem; */
    z-index: 900;

    @media ${({ theme }) => theme.device.desktop} {
        background: transparent;
    }

    @media ${({theme}) => theme.device.touch} {
        /* padding: 1.9rem 0; */
        border-bottom:  ${props => props.primary ? 'none' : '1px solid black'};
        background: ${props => props.primary ? 'transparent' : '#fff'};
        position: ${props => props.primary ? 'static' : 'fixed'};
        width: 100%;
        & + section:not(.info-section):first-of-type {
            padding-top: 100px;
        }
    }
`;

export const HeaderShadow = styled.div`
    background: ${props => props.primary ? 'transparent' : '#fff'};
    position: relative;
    @media ${({theme}) => theme.device.desktop} {
        box-shadow: none;
        background: transparent;
    }
    padding: 1.9rem 0;
`;

export const HeaderLogo = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
  gap: 2rem;
  width: calc(50% + 55px);

  @media ${({ theme }) => theme.device.desktopOnly} {
    width: calc(50% + 50px);
    gap: 0.2rem;
  }

  @media ${({ theme }) => theme.device.touch} {
    width: calc(100% - 50px);
    gap: 0;
  }


  @media ${({ theme }) => theme.device.mobile} {
    gap: 1rem;
    width: calc(100% - 30px);
  }
`;

export const NaborLogoContainer = styled.div`
    padding-right: 5rem;

    @media ${({ theme }) => theme.device.desktopOnly} {
        padding-right: 2rem;
    }

    @media ${({ theme }) => theme.device.touch} {
        padding-right: 2rem;
    }

    @media ${({ theme }) => theme.device.mobile} {
        padding-right: 0;
    }
`;

export const HeaderMobile = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    @media ${({ theme }) => theme.device.desktop} {
        display: none;
    }
`;

export const MobileHamburger = styled.button`
    border: 0;
    background: transparent;
    padding: 0;
    margin: 0;

    height: 19px;
    width: 24px;

    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    cursor: pointer;
    
    span {
        display: block;
        height: 3px;
        width: 100%;
        border-radius: 10px;
        background: ${({ theme }) => theme.colors.main};

        &:nth-child(1) {
            transform-origin: 0% 0%;
            transition: transform 0.4s ease-in-out;
        }

        &:nth-child(2) {
            transition: transform 0.2s ease-in-out;
        }

        &:nth-child(3) {
            transform-origin: 0% 100%;
            transition: transform 0.4s ease-in-out;
        }
    }

    &.active {
        span {
            &:nth-child(1) {
                transform: rotate(45deg);
            }
    
            &:nth-child(2) {
                transform: scaleY(0);
            }
    
            &:nth-child(3) {
                transform: rotate(-45deg);
            }
        }
    }
`;

export const HeaderTopMenu = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media ${({theme}) => theme.device.touch} {
        flex-grow: 1;
    }
`;


export const HeaderSignMenu = styled.div`
    display: flex;
    list-style-type: none;
    gap: 4rem;

    @media ${({ theme }) => theme.device.touch} {
        width: 100%;
        justify-content: flex-end;
        margin-right: 6rem;
    }
`;

export const HeaderMenu = styled.div`
    display: none;

    @media ${({ theme }) => theme.device.desktop} {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 4rem;
        background-color: transparent;
    }

    &.active {
        @media ${({ theme }) => theme.device.touch} {
            display: block;
            /* position: absolute;
            top: 125px; */
            left: 0;
            background: white;
            padding: 30px 24px 48px;
            width: 100%;
        }

        @media ${({ theme }) => theme.device.mobile} {
            top: 81px;
        }
    }
`;

export const HeaderMenuList = styled.ul`
    list-style-type: none;
    display: grid;
    gap: 1.5rem;
    margin-bottom: 1.5rem;

    &:not(:first-child) {
        @media ${({ theme }) => theme.device.touch} {
            border-top: 2px solid ${({ theme }) => theme.colors.grey5};
            margin-top: 25px;
            padding-top: 25px;
        }
    }

    @media ${({ theme }) => theme.device.desktop} {
        display: flex;
        gap: 4rem;
        margin-bottom: 0;
        
    }
`;

export const HeaderMenuItem = styled.li`
`;

export const HeaderMenuLink = styled(Link)`
    @media ${({ theme }) => theme.device.desktop} {
        font-size: 2rem;

        &.white {
            color: ${({ theme }) => theme.colors.white};
        }
    }
    @media ${({ theme }) => theme.device.touch} {
        display: none;
      }
`;
