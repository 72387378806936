import React from 'react';
import styled from 'styled-components';

const StyledDescription = styled.p`
    display: block;
    font-size: 1.4rem;
    color: ${props => props.theme.colors.grey60};
    font-style: italic;
    white-space: pre-wrap;
    margin-top: 0.4rem;
`;
const Description = ({ children}) => (
    <>
        { children && (
            <StyledDescription >
                {children}
            </StyledDescription>
        )}
    </>
);
export default Description;