import React, { useEffect, useCallback, useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import useStorageStateExpiring from "hooks/useStorageStateExpiring";
import useUnitFiltering from 'hooks/useUnitFiltering';

import UnitsFilters from "components/commons/UnitsFilters/UnitsFilters";
import UnitsList from './unitsList/UnitsList';
import ModalMap from './modalMap/ModalMap';

import { Notification } from "components/commons/Notification/Notification";
import { viewTypeDict } from "./Offer";

import { H4 } from "styles/common.styles";

import * as Styled from "./UnitsListWrapper.styles";
import useApi from "hooks/useApi";

/**
 * Fetching list units and filter them
 * Displaying geoMap modal
 */

const UnitsListWrapper = ({ instance }) => {
    const { t } = useTranslation();
    const { city, generalType } = useParams();
    const [units, setUnits] = useStorageStateExpiring(`/${city}/${generalType}/units`, 2);
    const [geoModalUnit, setGeoModalUnit] = useState(null);

    const filteredUnitsList = useUnitFiltering({ units, instance });
    const { offerApi } = useApi();

    const fetchUnits = useCallback(async (id) => {
        const { data, error } = await offerApi.get(`instance/${id}/units`);
        setUnits(data);
    }, []);

    useEffect(() => {
        if (units) return;
        if (instance && instance.id) {
            fetchUnits(instance.id);
        }
    }, [instance, units, fetchUnits]);

    return (
        <>
            {units?.length > 0 ? (
                <>
                    <UnitsFilters
                        instance={instance}
                        units={units}
                    />
                    {filteredUnitsList?.length > 0 ? (
                        <UnitsList
                            units={filteredUnitsList}
                            instance={instance}
                            viewType={viewTypeDict[instance.unitGeneralType]}
                            setGeoModalUnit={setGeoModalUnit}
                        />
                    ) : (
                        <Notification type="warning">
                            {t("units:notFound")}
                        </Notification>
                    )}
                </>
                ) : (
                    units != null && <Styled.NoUnits><H4>{t("units:noUnits")}</H4></Styled.NoUnits>
                )
            }
            {geoModalUnit && (
                <ModalMap
                    unit={geoModalUnit}
                    onCloseModal={() => {
                        setGeoModalUnit(null);
                    }}
                />
            )}
        </>
    );
};

export default UnitsListWrapper;
