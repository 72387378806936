import React, { useState, useEffect, useContext } from "react";
import { useParams, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { AppContext } from "context/appContext";
import useUnknownCityOrType from "hooks/useUnknownCityOrType";

import useApi from "hooks/useApi";
import { getInstance } from "utils/utils";
import { RoundedArrow } from "components/commons/icons/RoundedArrow";
import { Banner } from "components/commons";

import * as Styled from "./Branch.styles";
import * as Text from "components/commons/Text/Text";
import * as Layout from "components/commons/Layout/Layout";

import BranchContent from './BranchContent';

import Breadcrumbs from "components/commons/Breadcrumbs/Breadcrumbs";
import url from "routes/routes";
import { Page } from "components/commons/Page/Page";

const Branch = () => {
    const params = useParams();
    const { state } = useLocation();
    const { t } = useTranslation();
    const { instances: instancesList, setBreadcrumbsPath } =
        useContext(AppContext);
    
    useUnknownCityOrType();

    const [branch, setBranch] = useState(null);
    const [instanceShort, setInstanceShort] = useState(null);
    const [instanceSettings, setInstanceSettings] = useState(null);
    const { offerApi } = useApi();

    const unitId = state?.unitId;
    const unitName = state?.unitName;
    const branchName = state?.branchName;

    useEffect(() => {
        let isSubscribed = true;

        const fetchInstance = async (id) => {
            if (instanceShort?.settings) {
                const settings = instanceShort?.settings;
                setInstanceSettings(settings);
            } else {
                const { data } = await offerApi.get(`instance/${id}`);

                if (isSubscribed) {
                    const settings = data?.settings;
                    setInstanceSettings(settings);
                }
            }
        };

        if (instancesList?.length > 0) {
            const instances = getInstance(
                instancesList,
                params.city,
                params.generalType
            );
            if (instances != null && instances.length > 0) {
                const inst = instances[0];
                setInstanceShort(inst);
                fetchInstance(inst.id);
            }
        }
    }, [params.city, params.generalType, instancesList]);

    useEffect(() => {
        setBreadcrumbsPath([
            {
                name: t("breadcrumbs.allInstances"),
                url: url.allInstances.all,
            },
            {
                name: instanceShort?.instanceName || params.city,
                url: `/${params.city}/`,
            },
            {
                name: t(`unitTypes.${params.generalType}`),
                url: `/${params.city}/${params.generalType}/oferta`,
            },
            {
                name: branch?.unitName || unitName,
                url: `/${params.city}/${params.generalType}/info/${branch?.id_Unit || unitId}`,
            },
            {
                name: branch?.name || branchName,
            },
        ]);
    }, [instanceShort?.instanceName, branch?.unitName, branch?.name]);    

    return (
        <Page>
            <Banner />
            <Breadcrumbs />
            <Layout.Container>
                <Styled.BranchPage>
                    <Text.TitleMain>
                        {`${branch?.unitName} - `}
                        <Styled.BranchName>{branch?.normalizedName}</Styled.BranchName>
                    </Text.TitleMain>

                    { instanceSettings && (
                        <BranchContent
                            branchId={params.uid}
                            showFreePlaces={instanceSettings?.showFreePlaces}
                            setBranchData={setBranch}
                        />
                    )}

                    <Styled.BranchBackLink
                        to={`/${params.city}/${params.generalType}/oferta`}
                    >
                        <RoundedArrow />
                        {`${t("units:goBack")} ${instanceShort?.displayName}`}
                    </Styled.BranchBackLink>
                </Styled.BranchPage>
            </Layout.Container>
        </Page>
    );
};

export default Branch;
