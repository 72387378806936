import styled from 'styled-components';

// .react-datepicker__year-dropdown .react-datepicker__year-option:first-child::before {
//     content: "\25B2";
//     position: absolute;
//     top: 5%;
//     left: 45%;
//     margin: auto;
//   }
export const DatePickerStyleWrapper = styled.div`
    width: fit-content;
    .react-datepicker {
        font-size: 1.4rem;
        z-index: 1000000;
    }

    .react-datepicker-wrapper {
        width: 100%;
    }
    
    .react-datepicker__header {
        background-color: ${({ theme }) => theme.colors.accent};
    }
    
    .react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {
        width: 40px;
        line-height: 40px;
    }

    .react-datepicker__day-name{
        color: #fff;
    }

    .react-datepicker__current-month {
        font-size: 1.4rem;
    }

    .react-datepicker__current-month::first-letter {
        text-transform: uppercase;
    }

    .react-datepicker__tab-loop {
        z-index: 1000000;
        position: absolute;
    }

    .react-datepicker__header__dropdown {
        margin-top: 1rem;
        font-size: 1.6rem;
        display: flex;
        justify-content: space-around;
        >*::first-letter {
            text-transform: uppercase;
        }
    }
    
    .react-datepicker__current-month {
        color: white;
    }
    .react-datepicker__year-dropdown, .react-datepicker__month-dropdown, .react-datepicker__month-year-dropdown {
        background-color: ${({ theme }) => theme.colors.accent};
        color: white;
    }
    .react-datepicker__year-read-view, .react-datepicker__month-read-view, .react-datepicker__month-year-read-view {
        color: white;
    }
    

    .react-datepicker__year-option, .react-datepicker__month-option, .react-datepicker__month-year-option {
        height: 3rem;
        padding-top: 0.5rem;
        font-size: 1.4rem;
        font-weight: 600;
        
        &::first-letter {
            text-transform: uppercase;
        }
        &:hover{
            background: ${({ theme }) => theme.colors.backgroundLight};
            color: ${({ theme }) => theme.colors.main};
            >a {
                &::after {
                    color: ${({ theme }) => theme.colors.main};
                }
            }
        }

        >a {            
            top: -6px;
            position: relative;
            text-indent: 0;
            overflow: hidden;
            width: 100%;
            &::after {
                content: ">";
                color: white;
                width: 100%;
                position: absolute;
                left: 0;
                top: 5px;
                transform: rotate(-90deg);
            }
            &.react-datepicker__navigation--years-previous::after {
                transform: rotate(90deg);
            }
        }
    }
`;

export const StyledDateWrapper = styled.div`
    & .input-wrapper {
        max-width: unset;
        @media ${({ theme }) => theme.device.tablet} {
            max-width: 20rem;
        }
    }
`;


export const CalendarIcon = styled.img`
    width: 2.7rem;
    position: absolute;
    bottom: 0.8rem;
    right: 1.5rem;
    cursor: pointer;
`;