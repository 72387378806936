import React, { useContext, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { AppContext } from "../../context/appContext";
import { getInstance } from "../../utils/utils";
import parse from "html-react-parser";

import { Banner } from "components/commons";

const StartApplication = () => {
  let params = useParams();
  const { instances: instancesList } = useContext(AppContext);
  const [instance, setInstance] = useState(null);
  const [rules, setRules] = useState(null);

  useEffect(() => {
    if (instancesList?.length > 0) {
      const instances = getInstance(
        instancesList,
        params.city,
        params.generalType
      );

      if (instances?.length > 0) {
        const inst = instances[0];
        setInstance(inst);
      }
    }
  }, [instancesList, params.city, params.generalType]);

  let fillApplication = "";
  if (params.appType) {
    switch(parseInt(params.appType)) {
      case 1: fillApplication = "WYPEŁNIJ DEKLARACJĘ"; break;
      case 2: fillApplication = "WYPEŁNIJ ZGŁOSZENIE"; break;
      case 3: fillApplication = "WYPEŁNIJ WNIOSEK"; break;
      default: fillApplication = "WYPEŁNIJ PODANIE"; break;
    }
  } else
  if (
    instance?.allowPublicSubbmissions &&
    instance?.allowPublicClaimsAndApplications
  ) {
    fillApplication = "WYPEŁNIJ PODANIE";
  } else if (instance?.allowPublicClaimsAndApplications) {
    if (instance?.unitGeneralType == 3) {
      fillApplication = "WYPEŁNIJ ZGŁOSZENIE";
    } else {
      fillApplication = "WYPEŁNIJ DEKLARACJĘ";
    }
  } else if (instance?.allowPublicSubbmissions) {
    fillApplication = "WYPEŁNIJ WNIOSEK";
  }
  useEffect(() => {
    if (instance) {
      console.log("fetch");
      fetch(`${process.env.PUBLIC_URL}/regulations/${instance.id}/index.html`)
        .then((r) => r.text())
        .then((html) => {
          setRules(html);
        });
    }
  }, [instance]);

  return (
    <div>
      <Banner />
      <h1>{fillApplication}</h1>
      <p>{instance?.displayName}</p>
      <div>{rules && parse(rules)}</div>
    </div>
  );
};

export default StartApplication;
