import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { Button } from 'components/commons/Button/Button';
import closeIcon from 'assets/icons/ico-close-2.svg';

import BranchContent from 'pages/Branch/BranchContent';
import { H4 } from "styles/common.styles";

import * as Styled from "./ModalUnit.styles";

const ModalBranch = ({ branchId, onHideModal }) => {
    const { t } = useTranslation();
    const { city, generalType } = useParams();

    const [branch, setBranch] = useState();

    const showDetailsInNewWindow = () => {
        window.open(`/${city}/${generalType}/info/Oddzial/${branchId}`);
    };

    const onBackgroundClick = (ev) => {
        if (ev.target.getAttribute('aria-labelledby') === 'ModalUnitTitle') {
            onHideModal();
        }
        ev.stopPropagation();
    }

    return (
        <Styled.ModalUnitWrapper aria-hidden="true">
            <Styled.ModalUnit
                id="ModalUnit"
                role="dialog"
                aria-labelledby="ModalUnitTitle"
                onClick={onBackgroundClick}
            >
                <Styled.ModalContainer
                    style={{maxWidth: '90rem'}}
                >
                    <Styled.ModalUnitHeader>
                        <H4 id="ModalUnitTitle">{`${branch?.unitName || ''} - ${branch?.name || ''}`}</H4>
                        <Styled.CloseButton type="button" onClick={onHideModal}>
                            <img className='image-wrapper' src={closeIcon} alt={t("units:locationModal.closeIconAlt")} />
                        </Styled.CloseButton>
                    </Styled.ModalUnitHeader>
                    <Styled.BranchModalContentWrapper>
                        <BranchContent
                            branchId={branchId}
                            showFreePlaces={false}
                            setBranchData={setBranch}
                        />
                    </Styled.BranchModalContentWrapper>

                    <Styled.ModalUnitsFooter>
                        <Button
                            type='button'
                            onClick={showDetailsInNewWindow}
                        >
                            {t("units:descriptionModal.openBranchNewTabBtn")}
                        </Button>
                    </Styled.ModalUnitsFooter>
                </Styled.ModalContainer>
            </Styled.ModalUnit>
            <Styled.BodyBlockScroll />
        </Styled.ModalUnitWrapper>
    );
}

export default ModalBranch;
