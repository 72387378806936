import styled, {css} from 'styled-components';

export const Input = styled.input(({ error, disabled, theme: {colors} }) => css`
    width: 100%;
    background-color: ${colors.white};
    border-radius: 4px;
    border: 1px solid #AAAAAA;
    padding: 1.3rem 1.1rem;
    font-size: 1.4rem;
    outline: none;
    font-family: inherit;
    color: ${colors.black};

    &:hover {
        box-shadow: 0px 0px 10px #00000029;
        border: 1px solid ${colors.unitAddressColor};
    }

    &:not(:placeholder-shown) {
        /* field is filled */
        border: 1px solid ${colors.unitAddressColor};
    }

    ${error && css`
        border: 1px solid ${colors.warning};
    `};

    &:focus {
        border: 2px solid ${colors.accent};
        margin: -1px;
    }

    &::placeholder {
        color: ${colors.grey30};
    } 

    &:disabled {
        border: 1px solid #AAAAAA;
        background-color: ${colors.disabledControlBackground};
        color: ${colors.black};
    }
`);

export const InputTextArea = styled(Input).attrs({ as: styled.textarea`` })`
    max-width: 100%;
    min-height: 9rem;
    line-height: 1.9rem;
    width: 100%;
`;

export const InputContainer = styled.div`
    pointer-events: ${props => (props.disabled === true ? "none" : "auto")};
    ${({fullWidth}) => fullWidth && css`
        width: 100%;
    `}
`;

export const InputLabel = styled.span`
    display: block;
    font-size: 1.4rem;
    margin-bottom: 3px;
    color: ${props => (props.error === true ? props.theme.colors.warning : props.theme.colors.grey60)};
`;

export const InputRequired = styled.span`
    color: ${props => props.theme.colors.warning};
    margin-right: 5px;
`;

export const InputError = styled.div`
    color: ${props =>  props.theme.colors.warning};
    font-size: 1.4rem;
    font-weight: 500;
`;
