import styled, { css } from "styled-components";
import { Link } from "components/commons/Link/Link.styles";
import checkmark from "assets/images/checkmark-rounded.svg";
import {Button as ButtonComponent} from "components/commons/Button/Button";

import { Container } from 'styles/common.styles'

const UnitCell = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	min-height: 64px;
	padding: 5px;

	@media ${(props) => props.theme.device.mobile} {
		min-height: auto;
	}
`;

const UnitPropertyTitles = styled(UnitCell)`
	justify-content: flex-start;
	padding: 0 15px;
`;

const UnitGrid = styled.div`
	display: grid;
    ${({quantity}) => css`
        grid-template-columns: 3.5fr repeat(${quantity}, minmax(75px, 0.7fr));
    `}
`;

export const UnitList = styled(Container)`
	font-size: 1.6rem;
	color: ${({ theme }) => theme.colors.black};
    display: flex;
    flex-direction: column;
    gap: 3rem;
    margin-top: 5rem;

    @media ${(props) => props.theme.device.desktop} {
		display: block;
        margin-top: 0;
	}
`;

export const UnitHeaderWrapper = styled.div`
	background-color: ${({ theme }) => theme.colors.white};
	position: sticky;
	top: -1px;
	z-index: 1;
    display: none;

	@media ${(props) => props.theme.device.desktop} {
		display: block;
	}
`;

export const UnitHeader = styled(UnitGrid)`
	font-weight: 600;
	border-bottom: 1px solid rgba(0, 49, 137, 0.3);
	border-radius: 22px 22px 0px 0px;
	overflow: hidden;
`;

export const UnitTitle = styled(Link)`
	color: ${({ theme }) => theme.colors.main};
	font-size: 1.6rem;
	font-weight: bolder;

	@media ${(props) => props.theme.device.mobile} {
		font-size: 1.6rem;
	}
	
	&:focus-visible{
		outline: 1px solid ${props => (props.reverse === true ? props.theme.colors.white : props.theme.colors.grey90)};
        outline-offset: 1px;
        padding: 0.1rem;
        border-radius: 2px;
	}
`;

export const UnitHeaderText = styled(UnitPropertyTitles)`
	border-right: 1px solid rgba(0, 49, 137, 0.3);
	font-size: ${({ isProperty }) => (isProperty ? "1.9rem" : "1.4rem")};
	overflow-wrap: word-wrap;
	word-wrap: break-word;
	hyphens: manual;

    ${({blue, theme}) => blue ? css`
        background-color: ${theme.colors.selectItemBackground};
    ` : css`
        background-color: ${theme.colors.white};
    `}

	&:last-of-type {
		border-right: 0;
	}
`;

export const UnitHeaderFeatures = styled(UnitPropertyTitles)`
	grid-column: 2 / -1;
	border-bottom: 1px solid rgba(0, 49, 137, 0.3);
	justify-content: flex-start;
	background-color: ${({ blue, theme }) =>
        blue ? theme.colors.selectItemBackground : theme.colors.white};
	font-size: 1.9rem;
    ${({noBorder}) => noBorder && css`
        border: none;
    `}
`;

export const UnitTypeBar = styled.div`
	background-color: ${({ theme }) => theme.colors.main};
	color: ${({ theme }) => theme.colors.white};
	font-weight: 600;
	padding: 25px 15px;
	font-size: 1.75rem;
	grid-column: 1 / -1;
`;

export const UnitFeatureTitle = styled.div`
	border-bottom: 1px solid rgba(0, 49, 137, 0.3);
	border-right: 1px solid rgba(0, 49, 137, 0.3);
	min-height: 64px;
	${(props) =>
        props.branchName &&
        css`
			grid-column: 1 /
				${(props) =>
                props.holidayDuty ? (props.freePlaces ? "-2" : "-1") : "2"};
			display: flex;
			align-items: center;
			padding-left: 40px;
		`}

	${(props) =>
        props.noBorder &&
        css`
			border-right: 0;
		`}

	& h5 {
		margin-bottom: 10px;
	}

	${(props) =>
        props.noBorder &&
        css`
			border-right: 0;
		`}

	@media ${(props) => props.theme.device.touch} {
		padding: ${({ branchName }) => (branchName ? "20px" : "10px")} 15px 10px;
		border: 0;
		min-height: auto;
	}

	@media ${(props) => props.theme.device.mobile} {
		${({ isBranch }) =>
        isBranch &&
        css`
				border-top: 1px solid ${({ theme }) => theme.colors.main};

				& > * {
					margin-bottom: 0 !important;
				}
			`}
	}
`;

export const UnitFeatureCell = styled(UnitCell)`
	display: flex;
	text-align: center;
	padding: 5px;
	border-bottom: 1px solid rgba(0, 49, 137, 0.3);
	border-right: 1px solid rgba(0, 49, 137, 0.3);
	font-size: 1.6rem;

	&:last-child {
		border-right: 0;
	}

    ${({isHiddenOnMobile}) => isHiddenOnMobile && css`
        @media ${(props) => props.theme.device.touch} {
            display: none;
        }
    `}

	${({noBorder}) => noBorder && css`
		border-right: 0;
	`}

	${({gridFullWidth}) => gridFullWidth && css`
        grid-column: 1/-1;
        border-right: 0;
	`}

	${({onlyMobile}) => onlyMobile && css`
        display: none;
        @media ${(props) => props.theme.device.touch} {
            display: flex;
        }
	`}

    @media ${(props) => props.theme.device.touch} {
		padding: 10px 15px;
		justify-content: space-between;
		border: 0;
		border-top: 1px solid
			${(props) =>
        props.isDetail ? "rgba(0,49,137, 0.3)" : "rgba(0,49,137, 1)"};

		${(props) =>
        props.upDown &&
        css`
				flex-direction: column;
				align-items: flex-start;
				gap: 15px;
			`}

		${(props) =>
        props.isBranch &&
        css`
				border-top: 0 !important;
			`}

		${(props) =>
        props.onlyDesktop &&
        css`
				display: none;
			`}
	}
`;

export const UnitText = styled.p`
	color: ${(props) => (props.isBlue ? props.theme.colors.main : props.theme.colors.main)};
	font-weight: ${(props) => (props.isBold ? 600 : "normal")};
	width: 100%;

	@media ${(props) => props.theme.device.touch} {
		max-width: 60%;
		text-align: ${(props) => (props.toRight ? "right" : "left")};
	}

	${(props) =>
        props.onlyMobile &&
        css`
			display: none;
			@media ${(props) => props.theme.device.touch} {
				display: block;
			}
		`}

	${(props) =>
        props.isWithCheck &&
        css`
			&::before {
				content: url(${checkmark});
				padding-right: 5px;
				color: ${({ theme }) => theme.colors.accent};
			}

			@media ${(props) => props.theme.device.touch} {
				&::before {
					padding-right: 10px;
				}
			}
		`}
`;

export const UnitAddress = styled(UnitText)`
	color: ${({ theme }) => theme.colors.grey90};
	text-align: left;
	padding: 0;

	& > span {
		white-space: nowrap;
	}
`;

export const UnitInfo = styled(UnitText)`
	color: ${({ theme }) => theme.colors.grey90};

	${({ isMargin }) =>
        isMargin &&
        css`
			margin-top: 1rem;
		`}

	${({ onlyDesktop }) => onlyDesktop && css`
		@media ${({ theme }) => theme.device.touch} {
			display: none;	
		}
	`}

	& a:focus-visible{
		outline: 1px solid ${props => (props.reverse === true ? props.theme.colors.white : props.theme.colors.grey90)};
        outline-offset: 1px;
        padding: 0.1rem;
        border-radius: 2px;
	}
`;

export const Button = styled(ButtonComponent)`
    margin: 1.5rem 0;
    @media ${({theme}) => theme.device.desktop} {
        display: none;
    }
`;

export const UnitItem = styled.div`
    display: grid;
    width: auto;
	padding: 10px 0;
	border-radius: 4px;
    justify-items: center;
    > div {
        width: 100%;
    }

    ${({theme}) => css`
        border: 1px solid ${theme.colors.main};
    `}
    
    @media ${({theme}) => theme.device.desktop} {
        justify-items: unset;
        grid-template-columns: 3.5fr repeat(
            ${({ featuresNumber }) => featuresNumber}, minmax(7.5rem, 0.7fr)
        );
        grid-template-rows: 1fr;
        padding: 0;
        margin: 0;
        
        & > div {
			padding: 15px;
		}

        ${({hasBranches, theme}) => hasBranches ? css`
            margin-bottom: 3rem;
            border-radius: 0;
            border-right: none;
            border-left: none;
        ` : css`
            border: none;
        `}
	}
`;


export const MobileExpandWrapper = styled.div`
    display: grid;
    grid-template-rows: 0fr;
    transition: grid-template-rows 0.2s ease-out;

    ${({isExpanded}) => isExpanded && css`
        grid-template-rows: 1fr;
    `}

    @media ${({theme}) => theme.device.desktop} {
        display: contents;
    }
`;

export const MobileFeaturesWrapper = styled.div`
    overflow: hidden;
    @media ${({theme}) => theme.device.desktop} {
        overflow: auto;
        display: contents;
    }
`;