import * as React from 'react';
import { useTranslation } from 'react-i18next';

import OutsideError from 'components/error-page/OutsideError/OutsideError';
import ContentBlock from 'components/error-page/ContentBlock/ContentBlock';

const Error500 = () => {
    const { t } = useTranslation();
    
    return (
        <OutsideError>
            <ContentBlock 
                title={t('error.generalFailure')} 
                subtitle={t('error.breakdown')}
            >
                {t('error.breakdownText')}
            </ContentBlock>
        </OutsideError>
    )
};

export default Error500;