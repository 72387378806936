
import styled, { createGlobalStyle } from 'styled-components';
import { Button } from 'components/commons/Button/Button';
import {Link} from "react-router-dom";

export const BodyBlockScroll = createGlobalStyle`
  body {
    overflow: hidden;
  }
`;

export const ModalUnitWrapper = styled.div`
	position: fixed;
	inset: 0px;
  z-index: 1100;
`;

export const ModalContainer = styled.div`
    display: flex;
    flex-direction: column;
	width: 80dvw;
    max-height: 80dvh;
    overflow: auto;
	background-color: ${({ theme }) => theme.colors.white};
	box-shadow: 0px 0.3rem 1.2rem #00000029;
	border-radius: 4px;
	position: relative;
	overflow: auto;
	
	button{
		border: none;
	}

	@media ${({ theme }) => theme.device.mobileSmall} {
		width: 100vw;
	}
`;

export const ModalUnit = styled.div`
	position: fixed;
	width: 100vw;
	height: 100vh;
	left: 0;
	top: 0;
	z-index: 1000;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: ${({ theme }) => theme.colors.modalTransparentBackground};
`;

export const ModalUnitContainer = styled.div`
    overflow-x: auto;
    padding: 3rem;
`;

export const ModalUnitHeader = styled.div`
	padding: 2rem;
    border-bottom: 1px solid #e5e5e5;
	display: flex;
	justify-content: space-between;
	align-items: baseline;
`;

export const SectionListItem = styled.div`
    display: grid;
    grid-template-columns: 20rem auto;
    gap: 8px;

    @media ${({ theme }) => theme.device.mobile} {
        grid-template-columns: 1fr;
    }
`;

export const SectionListItemDescription = styled.p`
    & a {
        color: #003189;
    }
`;

export const TermsListItem = styled.p`
    font-weight: bold;

    & span {
        font-weight: normal;
        margin-left: 1.5rem;
    }
`;

export const Section = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 8.5rem;

    &:last-child {
        margin-bottom: 2rem;
    }


    color: ${({ theme }) => theme.colors.grey90};

    & ul,
    & ol {
        margin-left: 2rem;
    }

    @media ${({ theme }) => theme.device.mobile} {
        margin-bottom: 35px;
    }
`;

export const SectionHeading = styled.p`
    position: relative;
    color: ${({ theme }) => theme.colors.grey90};
    font-weight: 600;
    font-size: 2rem;
    padding-bottom: 11px;

    @media ${({ theme }) => theme.device.mobile} {
        font-size: 1.8rem;
        padding-bottom: 1.1rem;
    }

    &::after {
        content: "";
        display: block;
        position: absolute;
        height: 1px;
        bottom: 0;
        background-color: #1a76cb;
        opacity: 0.35;
        right: 0;
        left: 0;

        @media ${({ theme }) => theme.device.mobile} {
        right: -24px;
        left: -24px;
        }
    }
`;

export const BranchListItemLink = styled(Link)`
    color: ${({ theme }) => theme.colors.main};
    font-weight: bold;

    &:hover {
        text-decoration: underline;
    }
`;

export const CloseButton = styled.button`
	padding: none;
	background-color: transparent;
	transition: all 0.2s ease-in-out;
	cursor: pointer;

	> img {
			width: 1.6rem;
			background-color: white;
	}
	&:hover {
			transform: scale(0.9);
	}
`;

export const ModalUnitsFooter = styled.div`
	display: flex;
    justify-content: center;
    text-align: right;
    border-top: 1px solid #e5e5e5;
	gap: 4rem;
    padding: 2rem;

	@media ${({ theme }) => theme.device.mobileSmall} {
		flex-direction: column;
		justify-content: center;
		align-items: center;
		padding-top: 32px;
		gap: 3rem;
	}
`;

export const BranchModalContentWrapper = styled.div`
    padding: 3rem;
`;

export const BranchContent = styled.div`

`;