import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { AppContext } from 'context/appContext';

import { Link } from "components/commons/Link/Link";

import url from 'routes/routes';

import * as Styled from './Breadcrumbs.styles';
import * as Layout from 'components/commons/Layout/Layout';

const Breadcrumbs = () => {
    const {t} = useTranslation();
    const { breadcrumbsPath: path } = useContext(AppContext);

    const cutName = (name) => {
		const nameLength = 30;
		return `${name?.substring(0, nameLength)}${name?.toString().length > nameLength ? '...' : ''}`;
	}

    return(
        <Styled.Breadcrumbs>
            <Layout.Container>
                <Styled.BreadcrumbsList>
                    <Styled.BreadcrumbsItem>
                        <Link to={ url.home }>{ t('breadcrumbs.home') }</Link>
                    </Styled.BreadcrumbsItem>
                    { path?.map((item, index) => {
                        if (item.url) return (
                            <Styled.BreadcrumbsItem key={ `${item.name}-${index}` } >
                                <Link to={ item.url }>{ cutName(item.name) }</Link>
                            </Styled.BreadcrumbsItem>
                        );
                        
                        return (
                            <Styled.BreadcrumbsItem key={ `${item.name}-${index}` } >
                                <span>{ cutName(item.name) }</span>
                            </Styled.BreadcrumbsItem>
                        );
                    })}
                </Styled.BreadcrumbsList>
            </Layout.Container>
        </Styled.Breadcrumbs>
    )
}

export default Breadcrumbs;