import styled from "styled-components";

export const Breadcrumbs = styled.div`
    margin-bottom: 30px;
    margin-left: 0;
    margin-right: 0;

    @media ${({ theme }) => theme.device.mobile} {
        margin-top: 3rem;
      }
`

export const BreadcrumbsList = styled.ul`
    display: flex;
	flex-wrap: wrap;
    list-style-type: none;
    margin: 0;
    padding: 0;
`

export const BreadcrumbsItem = styled.li`
    span {
        color: ${({theme}) => theme.colors.grey90};
        font-size: 1.6rem;
    }
    
    &:not(:last-child) {
        &:after {
            content: "/";
			margin: 0 7px;
			color: ${({theme}) => theme.colors.grey90};
            position: relative;
            top: -1px;
        }
			
    }
		
`