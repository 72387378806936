import React, {useEffect, useState} from 'react';

import Logo from "./Logo";

import {useInstance} from "hooks/useInstance";
import {globalConfig} from "configuration/globalConfig";

import {useParams} from "react-router-dom";
import {LocalLogoLabel, LogoContainer, LogoLink} from "./Logo.styles"

const LocalLogo = () => {
    const { city, generalType } = useParams();
    const {instance, region} = useInstance();
    const [currentLink, setCurrentLink] = useState(null);
    const [currentLogo, setCurrentLogo] = useState(null);
    const [currentName, setCurrentName] = useState(null);

    useEffect(() => {
        if (city && instance?.urlName === city && instance?.typeUrl === generalType && instance?.emblemExists) {
            setCurrentLogo(`${globalConfig.get().REACT_APP_OFFER_API_ENDPOINT}/instance/${instance.id}/emblem`);
            setCurrentName(instance.instanceName);
            if (instance?.settings?.NoStandartLogo || instance?.settings?.NoPCSSLogo)
                setCurrentLink("/"+city+"/"+generalType);
            else
                setCurrentLink("/"+city);
        } else if (city && region?.url === city && region?.logoExists) {
            setCurrentLogo(`${globalConfig.get().REACT_APP_OFFER_API_ENDPOINT}/region/${region.id}/logo`);
            setCurrentName(region.name);
            setCurrentLink("/"+region.url);
        }
        else {
            setCurrentLogo(null);
            setCurrentName(null);
            setCurrentLink(null);
        }
    }, [city, generalType, instance, region]);

    useEffect(() => {
        let mourn = false;
        if (city && instance?.urlName === city && instance?.typeUrl === generalType)
            mourn = instance?.settings?.MournVersion;
        document.querySelector("html").classList[mourn ? "add" : "remove"]("mourn");
    }, [city, generalType, instance, region]);

    if (currentLogo == null)
        return <></>

    return (
        <LogoContainer
            className="logo-container"
        >
            <LogoLink to={currentLink}>
                <Logo src={currentLogo} alt="local-logo" isLocal/>
                <LocalLogoLabel className='local-logo-label'>{currentName}</LocalLogoLabel>
            </LogoLink>
        </LogoContainer>
    )
};

export default LocalLogo;