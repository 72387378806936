import React, { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { AppContext } from "context/appContext";

import url from "routes/routes";

import * as Styled from "./InfoSection.styles";
import * as Layout from "components/commons/Layout/Layout";
import * as Text from "components/commons/Text/Text";

import imgElem1 from "assets/images/home/elem1.svg";
import imgElem2 from "assets/images/home/elem2.svg";
import imgElem3 from "assets/images/home/elem3.svg";
import imgElem4 from "assets/images/home/elem4.svg";

import { getCity } from "utils/utils";

const InfoSection = () => {
    const { t } = useTranslation();

    const { city, generalType } = useParams();
    const [cityName, setCityName] = useState(null);

    const { setHeaderAuthMenuWhite, instances, setBreadcrumbsPath } =
        useContext(AppContext);

    useEffect(() => {
        setHeaderAuthMenuWhite(true);

        return () => setHeaderAuthMenuWhite(false);
    }, []);

    useEffect(() => {
        if (city && instances.length) {
            setCityName(getCity(instances, city));
        }
    }, [instances]);

    useEffect(() => {
        setBreadcrumbsPath([
            {
                name: t("breadcrumbs.allInstances"),
                url: url.allInstances.all,
            },
            {
                name: cityName,
            },
        ]);
    }, [cityName]);

    return (
        <>
            <Styled.InfoSection primary={city && generalType ? false : true}
                className='info-section'
            >
                <Styled.InfoSectionContent
                    className="info-section-content"
                >
                    <Text.HeadingMain
                        color
                        className='heading-main'
                    >
                        {city ? cityName : t("infoSection.title")}
                    </Text.HeadingMain>
                    <Text.Title
                        className='heading-content'
                    >
                        {t("infoSection.content")}
                    </Text.Title>
                    
                </Styled.InfoSectionContent>
                {!city && (
                        <Styled.HeadButtonWrapper>
                            <Styled.HeadButton
                                href="#rekrutacja"
                            >
                                {t("infoSection.button")}
                            </Styled.HeadButton>
                        </Styled.HeadButtonWrapper>
                    )}
            </Styled.InfoSection>
            <Styled.BlurIcons>
                <Layout.Container>
                    <Styled.BlurIconsWrapper>
                        <Styled.BlurIcon src={imgElem1} aria-label='zdjecie 1'></Styled.BlurIcon>
                        <Styled.BlurIcon src={imgElem2} aria-label='zdjecie 2'></Styled.BlurIcon>
                        <Styled.BlurIcon src={imgElem3} aria-label='zdjecie 3'></Styled.BlurIcon>
                        <Styled.BlurIcon src={imgElem4} aria-label='zdjecie 4'></Styled.BlurIcon>
                    </Styled.BlurIconsWrapper>
                </Layout.Container>
            </Styled.BlurIcons>
        </>
    );
};

export default InfoSection;
