import React from 'react';

import Modal from 'components/commons/Modal/Modal';
import { Button } from 'components/commons';

import { useTranslation } from "react-i18next";

import * as Styled from './ModalLoginProblem.styles';

const ModalLoginProblem = ({ closeModal }) => {
  const { t } = useTranslation();

  return (
    <Modal
      closeModal={closeModal}
      wrapModal={true}
      closeButton={true}
      className="modal-login-problem"
    >
      {() => (
        <Styled.ModalContent>
          <Styled.ModalInfoTitle>{t("modalLoginProblem.infoTitle")}</Styled.ModalInfoTitle>
          <Styled.H4>{t("modalLoginProblem.heading")}</Styled.H4>
          <Styled.MoadlInfoText>{t("modalLoginProblem.infoText")}</Styled.MoadlInfoText>
          <Button onClick={closeModal}>{t("modalLoginProblem.button")}</Button>
        </Styled.ModalContent>
      )}
    </Modal>
  );
};

export default ModalLoginProblem;
