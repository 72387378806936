import React from "react";

import * as Styled from "./AdditionalFeatures.styles";
import { additionalFeatureIcons } from "./Unit.config";
import parse from "html-react-parser";

export const AdditionalFeatures = ({ visibleFeatures, text }) => {
  return (
    <Styled.IconsList>
      {visibleFeatures?.map((feature) => {
        const choosenVariant = Object.keys(additionalFeatureIcons).find(
          (variant) => additionalFeatureIcons[variant].id === feature
        );

        if (choosenVariant) {
          return (
            <Styled.FeatureIconContainer key={feature}>
              <img
                src={require(`assets/images/additionalFeatures/${additionalFeatureIcons[choosenVariant].icon}`)}
                alt={additionalFeatureIcons[choosenVariant].label}
              />
              <p>
                {(text && parse(text)) ||
                  parse(additionalFeatureIcons[choosenVariant].label)}
              </p>
            </Styled.FeatureIconContainer>
          );
        }
        return <React.Fragment key={feature}></React.Fragment>;
      })}
    </Styled.IconsList>
  );
};
