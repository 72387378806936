import { globalConfig } from "configuration/globalConfig";
import axios from 'axios';
import { useEffect, useCallback } from "react";
import useAuthentication from "hooks/useAuthentication";

const useApi = () => {
    const { access_token } = useAuthentication();

    const baseConfig = {
        headers: {
            'Content-Type': 'application/json; charset="utf-8"',
        }
    }

    const offerApiConfig = {
        ...baseConfig,
        baseURL: globalConfig.get().REACT_APP_OFFER_API_ENDPOINT,
    };

    const candidateApiConfig = (access_token, currentHeaders) => {
        const heds = {
            ...(currentHeaders ? currentHeaders.headers : baseConfig.headers),
            ...(access_token ? { Authorization: `Bearer ${access_token}` } : {}),          
        }
        const cfg =
        {
            ...baseConfig,
            baseURL: globalConfig.get().REACT_APP_CANDIDATE_API_ENDPOINT,
            headers: heds,
        }

        return cfg;
    };

    const apiGet = async (url, config) => {
        // console.log("APIGET: ", config);
        try {
            const response = await axios.get(url, config);
            return response;
        } catch (error) {
            return { error };
        }
    };

    const offerApi = {
        get: async (url) => {
            return await apiGet(url, offerApiConfig);
        },
    }

    const candidateApi = {
        get: async (url) => {
            return await apiGet(url, candidateApiConfig(access_token));
        },

        post: async (url, data = null, headers = null) => {
            try {
                const response = await axios.post(url, data, candidateApiConfig(access_token, headers));
                return response;
            } catch (error) {
                throw error;
            }
        },
        put: async (url, data, headers = null) => {
            try {
                const response = await axios.put(url, data, candidateApiConfig(access_token, headers));
                return response;
            } catch (error) {
                throw error;
            }

        },

        delete: async (endpoint, options = {}) => {
            try {
                const config = { ...candidateApiConfig(access_token), ...options };
                const response = await axios.delete(endpoint, config);
                return response;
            } catch (error) {
                console.log("error w api", error);
                throw error;
            }
        },

        downloadFile: async (url, filename) => {
            const config = {...candidateApiConfig(access_token), responseType: 'blob'};
            const response = await axios.get(`${globalConfig.get().REACT_APP_CANDIDATE_API_ENDPOINT}/${url}`, config);

            const link = document.createElement('a');
            link.href = URL.createObjectURL(response.data);
            link.target = '_blank';
            link.download = filename || '';

            const handleClick = () => {
                link.removeEventListener('click', handleClick);
                link.remove();
            };

            link.addEventListener('click', handleClick);

            link.click();
        },
    };

    const documentApi = {
        downloadFile: async (url, filename) => {
            const config = {...candidateApiConfig(access_token), responseType: 'blob'};
            const response = await axios.get(`${globalConfig.get().REACT_APP_DOCUMENT_API}/${url}`, config);

            const link = document.createElement('a');
            link.href = URL.createObjectURL(response.data);
            link.target = '_blank';
            link.download = filename || '';

            const handleClick = () => {
                link.removeEventListener('click', handleClick);
                link.remove();
            };

            link.addEventListener('click', handleClick);

            link.click();
        },
    }

    return {
        offerApi,
        candidateApi,
        documentApi
    };
};


export default useApi;
