import React from 'react';

import InfoSection from "components/reusable/InfoSection/InfoSection";
import HowToSection from "components/reusable/HowToSection/HowToSection";
import UnitTypesSectionMain from "components/reusable/UnitTypesSection/UnitTypesSectionMain";
// import Title from 'components/commons/Title/Title'
import NavigationSection from "components/index-page/NavigationSection/NavigationSection";
import useDocumentTitle from 'hooks/useDocumentTitle';

const Home = () => {

    useDocumentTitle('Informator');

    return (
        <>
            <InfoSection />
            <NavigationSection/>
            <UnitTypesSectionMain />
            <HowToSection />
        </>
    )
};

export default Home;