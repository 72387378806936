import React, { useEffect, useState } from 'react';
import  useAuthentication  from "hooks/useAuthentication";
import { useLocation, useParams } from "react-router-dom";

const Authorize = ({ children }) => {
    const authentication = useAuthentication();
    const [hasTriedSignin, setHasTriedSignin] = useState(false);
    const { city, generalType } = useParams();

    useEffect(() => {
        if (//!hasAuthParams() &&
            !authentication.auth.isAuthenticated && !authentication.auth.activeNavigator && !authentication.auth.isLoading &&
            !hasTriedSignin
        ) {
            authentication.login();
            setHasTriedSignin(true);
        }
    }, [authentication, hasTriedSignin]);

    return (
        <>
            {children}
        </>
    )
}

export default Authorize;