import styled, { css } from "styled-components";
import { H5 } from "styles/common.styles";
import { Button } from "components/commons/Button/Button.styles";
import { Link } from 'react-router-dom';

export const UnitPage = styled.div`
  margin-bottom: 12rem;
  margin-top: 5rem;
`;

export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media ${({ theme }) => theme.device.touch} {
    margin-bottom: 20px;
  }

  @media ${({ theme }) => theme.device.mobile} {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const UnitButtons = styled.div`
  display: flex;
  gap: 15px;

  @media ${({ theme }) => theme.device.touch} {
    flex-direction: column;
    align-items: center;
  }
`;

export const FeatureContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0 17rem;
  margin-top: 3rem;

  @media ${({ theme }) => theme.device.touch} {
    display: flex;
    flex-direction: column-reverse;
    margin-top: 0;
  }
`;

export const FeatureSide = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

export const SectionHeading = styled.p`
  position: relative;
  color: ${({ theme }) => theme.colors.grey90};
  font-weight: 600;
  font-size: 2rem;
  padding-bottom: 1.5rem;

  @media ${({ theme }) => theme.device.mobile} {
    font-size: 1.8rem;
    padding-bottom: 1.1rem;
  }

  &::after {
    content: "";
    display: block;
    position: absolute;
    height: 1px;
    bottom: 0;
    background-color: #1a76cb;
    opacity: 0.35;
    right: 0;
    left: 0;

    @media ${({ theme }) => theme.device.mobile} {
      right: -24px;
      left: -24px;
    }
  }
`;

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 8.5rem;
  color: ${({ theme }) => theme.colors.grey90};

  & ul,
  & ol {
    margin-left: 2rem;
  }

  @media ${({ theme }) => theme.device.mobile} {
    margin-bottom: 35px;
  }
`;

export const SectionListItem = styled.div`
  display: grid;
  grid-template-columns: 20rem auto;
  gap: 8px;

  @media ${({ theme }) => theme.device.mobile} {
    grid-template-columns: 1fr;
  }
`;

export const SectionListItemDescription = styled.p`
  & a {
    color: #003189;
  }
`;

export const BranchListItemLink = styled(Link)`
  color: ${({ theme }) => theme.colors.main};
  font-weight: bold;

  &:hover {
    text-decoration: underline;
  }
`;

export const TermsListItem = styled.p`
  font-weight: bold;

  & span {
    font-weight: normal;
    margin-left: 1.5rem;
  }
`;

export const ApplicationButton = styled(Button)`
  @media ${({ theme }) => theme.device.mobile} {
    width: 100%;
    margin: 40px 0 40px;
  }
`;

export const SectionTextContainer = styled.div`
  position: relative;
  ${({ isLarge, isExpanded }) =>
    isLarge &&
    css`
      max-height: ${isExpanded ? "auto" : "200px"};
      overflow: hidden;

      &::after {
        display: ${isExpanded ? "none" : "block"};
        content: "";
        width: 100%;
        height: 30px;
        background: rgb(255, 255, 255);
        background: linear-gradient(
          180deg,
          rgba(255, 255, 255, 0) 0%,
          rgba(255, 255, 255, 0.9) 75%,
          rgba(255, 255, 255, 1) 100%
        );
        position: absolute;
        bottom: 0;
      }
    `}
`;

export const SectionAdditionalData = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const GalleryContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TextLink = styled.button`
  all: unset;
  cursor: pointer;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
  gap: 0.9rem;

  &:hover {
    text-decoration: underline;
  }
`;

export const DisplayDesktop = styled.div`
  display: block;

  @media ${({ theme }) => theme.device.touch} {
    display: none;
  }
`;

export const DisplayTouch = styled.div`
  display: none;

  @media ${({ theme }) => theme.device.touch} {
    display: block;
  }
`;

export const SectionExpander = styled.div`
  width: 100;
  border-bottom: 2px solid ${({ theme }) => theme.colors.labelBorder};
  position: relative;
  margin: 10px 0 45px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ExpanderText = styled(H5)`
  color: ${({ theme }) => theme.colors.main};
`;

export const ExpandBt = styled.img`
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  margin-bottom: -20px;
  margin-top: 15px;
  ${(props) =>
    props.expanded &&
    css`
      transform: rotate(-180deg);
    `}
`;

export const LinkButton = styled(Button).attrs({ as: Link })`
`;
