import React from 'react';

import * as Styled from './Logo.styles';

const Logo = ({src, alt, isLocal = false, useNarrowLogo = false}) => {

    return (
        <Styled.Logo 
            isLocal = {isLocal}
            useNarrowLogo={useNarrowLogo}
        >
            <img src={src} alt={alt} />
        </Styled.Logo>
    )
};

export default Logo;