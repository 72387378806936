import styled from "styled-components";

import { H2 as CommonH2 } from "styles/common.styles";
import { Link as CommonLink } from "components/commons/Link/Link.styles";
import { Button as CommonButton } from "components/commons/Button/Button";

export const H2 = styled(CommonH2)`
  margin-bottom: 3rem;
`;

export const Link = styled(CommonLink)`
  font-size: 2rem;
  line-height: 2.2rem;
  color: ${({ theme }) => theme.colors.main};
  font-weight: 600;
  margin-top: 5rem;

  @media ${(props) => props.theme.device.mobile} {
    font-size: 1.4rem;
  }
`;

export const Button = styled(CommonButton)`
        border: 2px solid ${({ theme }) => theme.colors.main};
        background-color: ${({ theme }) => theme.colors.main};
        color: white;
        letter-spacing: 1px;
        width: 398px;
        height: 56px;
        border-radius: 28px;
        opacity: 1;
        cursor: pointer;
        font-weight: 500;

    @media ${(props) => props.theme.device.mobile} {
        font-size: 1.4rem;
    }
`

export const LoginOrRegisterParagraph = styled.p`
  margin-bottom: 1rem;
  font-size: 2rem;
  line-height: 2.4rem;
  color: ${({ theme }) => theme.colors.grey90};

  @media ${(props) => props.theme.device.mobile} {
    font-size: 1.4rem;
  }
`;

export const LoginOrRegisterDescription = styled.div`
  display: flex;
  flex-direction: column;
  align-items: normal;
  margin-bottom: 5rem;
`;

export const LoginOrRegisterPanel = styled.div`
    background-color: ${({ theme }) => theme.colors.backgroundLight};
    border: 1px solid ${({ theme }) => theme.colors.grey5};
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 3rem;
    margin-bottom: 2rem;
    border-radius: 5px;
    width: 56rem;
    height: auto;
    padding: 5.5rem 7rem;
    margin-left: auto;
    margin-right: auto;  

        p {
            font-weight: 600;
        }

    @media ${(props) => props.theme.device.mobile} {
        width: auto;
        height: auto;
    }
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;

    p{
        font-weight: 400;
    }
`;
