export const localStorageHandler = (minutes = 3) => {
	const storeData = (url, dataToStore) => {
		const dataToLocalStorage = {};
		dataToLocalStorage['time'] = Date.now();
		dataToLocalStorage['key'] = url;
		dataToLocalStorage['data'] = dataToStore;

		try {
			localStorage.setItem(url, JSON.stringify(dataToLocalStorage));
		} catch(exception) {
			localStorage.clear();//czyścimy storage w razie błędu przekroczenia limitu
			try {
				localStorage.setItem(url, JSON.stringify(dataToLocalStorage));
			} finally { }
		}
	}

	const isValidData = (url) => {
			const item = window.localStorage.getItem(url);
			const dateNow = new Date();

			if (item) {
					const dataFromStore = JSON.parse(item);
					return dataFromStore.time + (minutes * 60 * 1000) > Date.parse(dateNow);
			} 

			return false;
	}

	const getCachedData = (url) => {
			const item = window.localStorage.getItem(url);
			return JSON.parse(item).data;
	}

	const getStoreData = (url) => isValidData(url) ? getCachedData(url) : null;

	return { getStoreData, storeData };
}
