import { useState, useEffect } from 'react';

import ExamRow from './ExamRow';
import ExtraRow from './ExtraRow';
import ExtraRowExpandable from './ExtraRowExpandable';
import CertificateRow from "./CertificateRow";

import './calculator.css';
import ModalBranch from "../Unit/ModalUnit/ModalBranch";

const Calculator = () => {
	const [pointsArray, setPointsArray] = useState(new Array(10));
	const [pointsSum, setPointsSum] = useState(0);
	const [extraRowExpanded, setExtraRowExpanded] = useState(null);

	const onPointsChange = (id, points) => {
		let newPointsArray = [...pointsArray];
		newPointsArray[id] = points;
		setPointsArray(newPointsArray);
	}

	useEffect(() => {
		let sum = 0;
		pointsArray.forEach((pts, i) => {
			if (pts) {
				if (i > 6) sum += parseFloat(((i === 9 ? 0.3 : 0.35) * pts).toFixed(2))
				else sum += pts;
			}
		});
		setPointsSum(Math.floor(sum * 100) / 100);
	}, [pointsArray]);

	function uncheckExemptions() {
		const checkboxes = document.querySelectorAll('input[type="checkbox"].exemption');
		checkboxes.forEach(checkbox => {
			checkbox.checked = false;
		});
	}

    return (
        <div className="resultsForm">
            <h2>Świadectwo ukończenia szkoły podstawowej</h2>
			<p>Sprawdź orientacyjną liczbę punktów, które możesz uzyskać podczas rekrutacji do szkoły ponadpodstawowej.</p>
			<ul>
				Do obliczenia orientacyjnej liczby punktów będziesz potrzebować:
				<li>oceny z wybranych przedmiotów ze świadectwa ukończenia 8 klasy</li>
				<li>informacji o dodatkowych aktywnościach (np. wolontariat).</li>
				<li>wyników z egzaminu ósmoklasisty</li>
			</ul>
			<table className="tableCalculator">
				<thead>
                    <tr>
                        <th>Przedmiot</th>
						<th>zwolniony</th>
                        <th>ocena</th>
					</tr>
				</thead>
				<tbody>
					<CertificateRow id={0} pts={pointsArray[0]} onChange={onPointsChange}>Ocena z języka polskiego</CertificateRow>
					<CertificateRow id={1} pts={pointsArray[1]} onChange={onPointsChange}>Ocena z matematyki</CertificateRow>
					<CertificateRow id={2} pts={pointsArray[2]} onChange={onPointsChange}>Ocena z I przedmiotu branego pod uwagę w rekrutacji</CertificateRow>
					<CertificateRow id={3} pts={pointsArray[3]} onChange={onPointsChange}>Ocena z II przedmiotu branego pod uwagę w rekrutacji</CertificateRow>
					<ExtraRow id={4} pts={pointsArray[4]} onChange={onPointsChange} max={7}>Świadectwo ukończenia szkoły z wyróżnieniem</ExtraRow>
					<ExtraRow id={5} pts={pointsArray[5]} onChange={onPointsChange} max={3}>Aktywność społeczna (wolontariat)</ExtraRow>
					<tr >
						<td>Szczególne osiągnięcia w zawodach wiedzy, artystycznych i sportowych</td>
						<td></td>
						<td className="calculator-radio-wrapper">
							<div>
								<input
									type="radio"
									id="radio-toggle-yes"
									name="section-toggle"
									value={true}
									checked={extraRowExpanded === true}
									onChange={(e) => setExtraRowExpanded(true)}
									 />
								<label htmlFor="radio-toggle-yes">TAK</label>
							</div>
							<div>
								<input
									type="radio"
									id="radio-toggle-no"
									name="section-toggle"
									value={false}
									checked={extraRowExpanded === false}
									onChange={(e) => setExtraRowExpanded(false)}
									 />
								<label htmlFor="radio-toggle-no">NIE</label>
							</div>
						</td>
					</tr>
                </tbody>
			</table>
			{extraRowExpanded &&
				<ExtraRowExpandable
					id={6}
					onChange={onPointsChange}
					max={18}
				/>
			}
            <h2>Egzamin ósmoklasisty</h2>
			<table className="tableCalculator">
				<thead>
                    <tr>
						<th>Przedmiot</th>
						<th></th>
                        <th>wynik</th>
					</tr>
				</thead>
				<tbody>
					<ExamRow id={7} pts={pointsArray[7]} onChange={onPointsChange} weight={0.35}>Język polski</ExamRow>
					<ExamRow id={8} pts={pointsArray[8]} onChange={onPointsChange} weight={0.35}>Matematyka</ExamRow>
					<ExamRow id={9} pts={pointsArray[9]} onChange={onPointsChange} weight={0.3}>Język obcy nowożytny</ExamRow>
                </tbody>
			</table>
			<button
				className="btn calculator-clear-btn"
				onClick={() => {
					setPointsArray(new Array(10));
					setPointsSum(0);
					setExtraRowExpanded(null);
					uncheckExemptions();
				}}>Wyczyść wyniki</button>
			
            <div className="pointsDisplay">Obliczona liczba punktów: {pointsSum} / 200</div>
        </div>
    )
}

export default Calculator;