import { useTranslation } from "react-i18next";

import { H4 } from "pages/Account/Account.styles";
import * as Styled from "pages/Account/elements/NormalAccount/NormalAccount.styles";

const LoginInfo = ({ userLogin }) => {
  const { t } = useTranslation();
  
  return (
    <Styled.LoginInfoContainer>
      <H4>{t("myData.normalAccount.login")}</H4>
      <p>{userLogin}</p>
    </Styled.LoginInfoContainer>
  );
};

export default LoginInfo;
