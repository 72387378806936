import styled from 'styled-components';

export const Collapse = styled.div`
    display: block;

    & > input[type="checkbox"] {
        display: none;

        & ~ div {
            margin: 0;
            max-height: 0;
            overflow: hidden;
            transition: all 0.3s ease-in;
        }

        &:checked ~ div {
            margin: 4rem 0;
            max-height: 5000rem;
            transition: all 0.3s ease-out;
        }
        &:checked ~ label img {
            transform: rotate(270deg);
            transition: all 0.3s ease;
        }
    }

    & label {
        font-size: 2.2rem;
        font-weight: 600;
        padding: 1.4rem 0;
        color: ${({theme}) => theme.colors.main};
        border-bottom: 2px solid ${({theme}) => theme.colors.labelBorder};

        display: flex;
        justify-content: space-between;
        cursor: pointer;

        & span img {
            height: 3.4rem;
            margin-right: 2rem;
            transform: rotate(90deg);
            transition: all 0.3s ease;
        }
    }
`;
