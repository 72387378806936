import * as React from 'react';
import { useRouteError } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Link } from 'components/commons/Link/Link';

import ContentBlock from 'components/error-page/ContentBlock/ContentBlock';

import url from 'routes/routes';
import InsideError from 'components/error-page/InsideError/InsideError';

const Error404 = () => {
    const { t } = useTranslation();

    const error = useRouteError();
    if (error) console.error(error);

    return (
        <InsideError>
            <ContentBlock 
                title={`${t('error.error')}: 404`} 
                subtitle={t('error.notFound')}
            >
                {t('error.notFoundText1')} <Link to={url.home}>{t('error.notFoundText2')}</Link>
            </ContentBlock>
        </InsideError>
    )
};

export default Error404;