import React, { useContext, useState, useEffect } from "react";

import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { AppContext } from "context/appContext";

import DropletThumbnail from "components/reusable/DropletThumbnail/DropletThumbnail";

import * as Styled from "./UnitTypesSection.styles";
import * as Layout from "components/commons/Layout/Layout";
import * as Text from "components/commons/Text/Text";

import dugt_1 from "assets/images/dugt-1.jpg";
import dugt_2 from "assets/images/dugt-2.jpg";
import dugt_3 from "assets/images/dugt-3.jpg";
import dugt_5 from "assets/images/dugt-5.jpg";
import dugt_6 from "assets/images/dugt-6.png";
import dugt_7 from "assets/images/dugt-7.jpg";
import dugt_9 from "assets/images/dugt-9.jpg";
import dugt_22 from "assets/images/dugt-22.jpg";
import dugt_34 from "assets/images/dugt-34.jpg";
import dugt_37 from "assets/images/dugt-37.jpg";
import dugt_10 from "assets/images/dugt-10.jpg";
import dugt_11 from "assets/images/dugt-11.jpg";

import { UnitTypes } from "utils/enums";

import { getInstances4Region } from "utils/utils";

// to remove
import * as CommonStyled from "styles/common.styles";
import dugt_14 from "../../../assets/images/dugt-14.jpg";
import dugt_13 from "../../../assets/images/dugt-13.jpg";

const UnitTypesSection = () => {
  const { t } = useTranslation("default");

  const { city } = useParams();
  const { instances } = useContext(AppContext);
  const [regionInstances, setRegionInstances] = useState([]);
  const [visibleTypes] = useState(
    t("unitTypesForView", { returnObjects: true })
  );

  let types = Object.keys(visibleTypes);
  if (city) types = regionInstances.map((x) => x.instanceUrl || x.typeUrl);

  useEffect(() => {
    if (city && instances.length)
      setRegionInstances(getInstances4Region(instances, city));
  }, [instances]);

  const allTypes = [
    {
      url: `/${UnitTypes.Nursery}`,
      name: t(`unitTypes.${UnitTypes.Nursery}`),
      kropka: "#FFA0A0",
      obwodka: "#F9D8DD",
      img: dugt_1,
      dropletFrom: "bottom-right",
      dropletTo: "top-right",
    },
    {
      url: `/${UnitTypes.Kindergarten}`,
      name: t(`unitTypes.${UnitTypes.Kindergarten}`),
      kropka: "#FCAE17",
      obwodka: "#CDDEEE",
      img: dugt_2,
      dropletFrom: "top-left",
      dropletTo: "bottom-left",
    },
    {
      url: `/${UnitTypes.HolidayDuty}`,
      name: t(`unitTypes.${UnitTypes.HolidayDuty}`),
      kropka: "#CDDEEE",
      obwodka: "#FCAE17",
      img: dugt_10,
      dropletFrom: "top-right",
      dropletTo: "bottom-right",
    },
    {
      url: `/${UnitTypes.PrimarySchool}`,
      name: t(`unitTypes.${UnitTypes.PrimarySchool}`),
      kropka: "#FB693C",
      obwodka: "#FB693C",
      img: dugt_3,
      dropletFrom: "bottom-right",
      dropletTo: "bottom-left",
    },
    {
      url: `/${UnitTypes.Semicolony}`,
      name: t(`unitTypes.${UnitTypes.Semicolony}`),
      kropka: "#73BA00",
      obwodka: "#73BA00",
      img: dugt_11,
      dropletFrom: "bottom-left",
      dropletTo: "top-left",
    },
    {
      url: `/${UnitTypes.Class4}`,
      name: "Klasy 4 sportowe",
      kropka: "#FFCE00",
      obwodka: "#FFCE00",
      img: dugt_34,
      dropletFrom: "bottom-right",
      dropletTo: "bottom-left",
    },
    {
      url: `/${UnitTypes.Class6}`,
      name: "Klasy 6 sportowe",
      kropka: "#FFCE00",
      obwodka: "#FFCE00",
      img: dugt_22,
      dropletFrom: "bottom-right",
      dropletTo: "bottom-left",
    },
    {
      url: `/${UnitTypes.Class7}`,
      name: "Klasy 7 dwujęzyczne",
      kropka: "#44C8D9",
      obwodka: "#44C8D9",
      img: dugt_37,
      dropletFrom: "top-left",
      dropletTo: "bottom-right",
    },
    {
      url: `/${UnitTypes.SecondarySchool}`,
      name: t(`unitTypes.${UnitTypes.SecondarySchool}`),
      kropka: "#1A76CB",
      obwodka: "#88B8E4",
      img: dugt_9,
      dropletFrom: "top-left",
      dropletTo: "top-right",
    },
    {
      url: `/${UnitTypes.TradeSchool}`,
      name: t(`unitTypes.${UnitTypes.TradeSchool}`),
      kropka: "#02E38C",
      obwodka: "#02E38C",
      img: dugt_5,
      dropletFrom: "top-right",
      dropletTo: "bottom-left",
    },
    {
      url: `/${UnitTypes.Dormitory}`,
      name: t(`unitTypes.${UnitTypes.Dormitory}`),
      kropka: "#003189",
      obwodka: "#FCAE17",
      img: dugt_7,
      dropletFrom: "bottom-left",
      dropletTo: "top-left",
    },
    {
      url: `/${UnitTypes.PostSecondarySchool}`,
      name: t(`unitTypes.${UnitTypes.PostSecondarySchool}`),
      kropka: "green",
      obwodka: "red",
      img: dugt_6,
      dropletFrom: "bottom-left",
      dropletTo: "top-left",
    },
    {
      url: `/${UnitTypes.Culture}`,
      name: t(`unitTypes.${UnitTypes.Culture}`),
      kropka: "red",
      obwodka: "green",
      img: dugt_14,
      dropletFrom: "bottom-right",
      dropletTo: "top-left",
    },
    {
      url: `/${UnitTypes.Center}`,
      name: t(`unitTypes.${UnitTypes.Center}`),
      kropka: "blue",
      obwodka: "yellow",
      img: dugt_13,
      dropletFrom: "top-right",
      dropletTo: "bottom-right",
    }
  ];

  return (
    <Styled.UnitTypesSection>
      <Layout.Container narrow>
        <Styled.UnitSectionContainer 
            className="UnitSectionContainer"
        >
        {types?.length > 1 && (
          <Styled.Title>
            <Text.TitleMain>{t("unitTypesSection.title")}</Text.TitleMain>
          </Styled.Title>
        )}
        <Styled.UnitTypesSectionGrid>
          {allTypes.map((item) => {
            if (types.includes(item.url.substr(1)) || !types.length)
              return (
                <Link
                  key={item.url}
                  to={
                    city
                      ? `/${city}${item.url}/oferta`
                      : `/wdrozenia${item.url}`
                  }
                >
                  <Styled.UnitTypesItem>
                    <Styled.UnitTypesHeader>
                      <CommonStyled.Dot color={item.kropka} />
                      <span>{item.name}</span>
                    </Styled.UnitTypesHeader>
                    <DropletThumbnail
                      src={item.img}
                      color={item.obwodka}
                      start={item.dropletFrom}
                      end={item.dropletTo}
                      alt={item.name}
                    />
                  </Styled.UnitTypesItem>
                </Link>
              );

            return null;
          })}
        </Styled.UnitTypesSectionGrid>
        </Styled.UnitSectionContainer>
      </Layout.Container>
    </Styled.UnitTypesSection>
  );
};

export default UnitTypesSection;
