import { memo, useState, useEffect } from "react";
import { useFormContext } from 'react-hook-form';
import FieldFactory from '../fields/FieldFactory';
import FormSectionWrapper from './FormSectionWrapper';

const terytNamesToCheck = ['cityId', 'cityName', 'communityId', 'streetId', 'streetName'];

const BaseSection = ({
    data, 
}) => {
    // only base section has condition handling so far
    const { getValues, unregister } = useFormContext();
    const [collapsed, setCollapsed] = useState(() => {
        if (data?.condition?.name) {
            return getValues(data?.condition?.name);
        } else {
            return false;
        }
    });

    useEffect(() => {
        if (data?.condition) {
            if (collapsed) {
                const fieldNames = new Set();
                data?.fields.forEach(field => {
                    fieldNames.add(field?.name);

                    // if field is a teryt then check its unique fields
                    if (field?.terytData) {
                        terytNamesToCheck.forEach( terytName => {
                            if (field?.terytData[terytName]) {
                                fieldNames.add(field?.terytData[terytName]);
                            }
                        })
                    }
                })
                const fieldsToUnregister = Array.from(fieldNames);
                setTimeout(() => unregister(fieldsToUnregister), 100);
            }
        }
    }, [ collapsed, data?.condition ]);

    return (
        <FormSectionWrapper 
            section={data} 
            collapsed={collapsed} 
            setCollapsed={setCollapsed}
        >
            {
                data?.fields.map((field) => { 
                    return (
                        <FieldFactory 
                            field={field} 
                            key={field.name}
                        />
                    )
                })
            }
        </FormSectionWrapper>
    )
}
export default memo(BaseSection);