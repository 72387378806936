import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import useApi from "hooks/useApi";

import ModalDeleteAccount from "pages/Account/elements/ModalDeleteAccount";

import * as Styled from "pages/Account/Account.styles";

const DeleteAccount = ({ isLocalUser }) => {
  const { t } = useTranslation();
  const { candidateApi } = useApi();
  const [showModal, setShowModal] = useState(false);
  const [canRemove, setCanRemove] = useState(false);

  const checkForApplications = async () => {  
    const endpoint = `Account/canRemove`;
  
    try {
      const response = await candidateApi.post(endpoint, null);
      if (response.data === true) {
        // <--- Set true/false for testing --->
        setCanRemove(true);
      }
    } catch (error) {
      console.error("Błąd podczas sprawdzania wniosków", error);
    }

    setShowModal(true);
  };

  return (
    <Styled.DeleteAccountContainer className="delete-account">
      <Styled.DeleteAccountHeader className="delete-account-header">
        <Styled.H4>{t("myData.deleteAccount")}</Styled.H4>
      </Styled.DeleteAccountHeader>
      <Styled.DeleteAccountDescription className="delete-account-description">
        <Styled.H5>{t("myData.deleteAccountDescription")}</Styled.H5>
        <p>{t("myData.deleteAccountDescription2")}</p>
      </Styled.DeleteAccountDescription>
      <Styled.DeleteAccountInformation className="delete-account-information">
        <p>{t("myData.deleteAccountInformation")}</p>
        <p>{t("myData.deleteAccountInformation2")}</p>
      </Styled.DeleteAccountInformation>
      <Styled.DeleteAccountLink onClick={checkForApplications}>
        {t("myData.deleteAccountLink")}
      </Styled.DeleteAccountLink>
      {showModal && (
        <ModalDeleteAccount
          closeModal={() => setShowModal(false)}
          closeButton
          isLocalUser={isLocalUser}
          canRemove={canRemove}
        />
      )}
    </Styled.DeleteAccountContainer>
  );
};

export default DeleteAccount;