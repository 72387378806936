import React, { useState, useEffect, useContext } from "react";
import { useParams, useNavigate, useNavigationType, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import parse from "html-react-parser";

import { AppContext } from "context/appContext";

import url from "routes/routes";

// hooks
import useApi from "hooks/useApi";
import useIsMobile from "hooks/useIsMobile";
import useUnknownCityOrType from "hooks/useUnknownCityOrType";

// utils
import { UnitGeneralTypes } from "utils/enums";
import { showProperty, showSection, usedIds } from "utils/unitHelpers";
import { languages as languagesConst } from "utils/constants";
import { localStorageHandler } from "utils/localStorageHandler";
import {
  getInstance,
  getCity,
  decodeEscapedHtml,
  createDictFromArray,
  setIntervalInstantly,
} from "utils/utils";

// components
import Gallery from "components/commons/Gallery/Gallery";
import Breadcrumbs from "components/commons/Breadcrumbs/Breadcrumbs";
import Error403 from "pages/Error/403";
import { RoundedArrow } from "components/commons/icons/RoundedArrow";
import { Banner } from "components/commons";
import { Page } from "components/commons/Page/Page";
import * as Layout from "components/commons/Layout/Layout";
import * as Text from "components/commons/Text/Text";

// local components
import { AdditionalFeatures } from "./AdditionalFeatures";
import { sectionConfig, additionalFeatureIcons } from "./Unit.config";
import * as Styled from "./Unit.styles";
import { UnitHtmlSection } from "./UnitHtmlSection";

const Unit = () => {
  const { t } = useTranslation();
  const { city, generalType, uid } = useParams();
  const navigationType = useNavigationType();
  const navigate = useNavigate();

  const cityIsValid = useUnknownCityOrType();

  const { instances: instancesList } = useContext(AppContext);
  const { offerApi } = useApi();
  const [forbiddenUnit, setForbiddenUnit] = useState(false);
  const [cityName, setCityName] = useState(null);
  const [unit, setUnit] = useState(null);
  const [instance, setInstance] = useState(null);
  const [languages] = useState(
    createDictFromArray(languagesConst, "id", "label")
  );

  const { state } = useLocation();

  const unitName = state?.name;

  const buttonsInfo = {
    declaration: {
      id: "deklaracja",
      text: t("units:buttons.fillDeclaration"),
    },
    report: {
      id: "zgloszenie",
      text: t("units:buttons.fillReport"),
    },
    application: {
      id: "wniosek",
      text: t("units:buttons.fillApplication"),
    },
  };

  const isTouch = useIsMobile("colMedium");

  const boolFeatures = unit?.boolFeatures
    ?.filter((e) => e.value)
    .map((el) => el.id);
  const { getStoreData, storeData } = localStorageHandler(2);

  useEffect(() => {
    let isSubscribed = true;

    const fetchUnit = async (id, inst) => {
      const unitUrl = `unit/${id}?id_instance=${inst.id}`;

      const cacheData = getStoreData(unitUrl);

      if (cacheData) {
        setUnit(cacheData);
        return;
      }

        const { data } = await offerApi.get(unitUrl);

      if (isSubscribed && data) {
        if (data.informatorVisible == false || data.deleted) {
          setForbiddenUnit(true);
        } else {
          setForbiddenUnit(false);
          setUnit(data);
          storeData(unitUrl, data);
        }
      }
    };

    let fetchUnitInterval = null;

    if (city && cityIsValid && instancesList?.length > 0) {
      const instances = getInstance(instancesList, city, generalType);
      setCityName(getCity(instancesList, city));

      if (instances != null && instances.length > 0) {
        const inst = instances[0];
        setInstance(inst);
        if (!unit) {
          fetchUnitInterval = setIntervalInstantly(() => {
            fetchUnit(uid, inst);
          }, 120000);
        }
      }
    }

    return () => {
      clearInterval(fetchUnitInterval);
      return (isSubscribed = false);
    };
  }, [instancesList, city, generalType, uid, cityIsValid]);

  const { setBreadcrumbsPath } = useContext(AppContext);

  useEffect(() => {
    setBreadcrumbsPath([
      {
        name: t("breadcrumbs.allInstances"),
        url: url.allInstances.all,
      },
      {
        name: cityName,
        url: `/${city}/`,
      },
      {
        name: t(`unitTypes.${generalType}`),
        url: `/${city}/${generalType}/oferta`,
      },
      {
        name: unit?.name || unitName,
      },
    ]);
  }, [cityName, unit]);

  const displayAdditionalInformation = () => {
    const featuresIds = Object.values(additionalFeatureIcons)
      .filter((el) => el.id !== null)
      .map((el) => el.id);

    const displayedIds = unit.features?.filter(
      (el) =>
        !usedIds.includes(el.id) &&
        !featuresIds.includes(el.id) &&
        el.valueType !== 6
    );

    const sixYOFeature = unit.features?.find((el) => el.id === 33);

    if (displayedIds?.length > 0) {
      return (
        <>
          {displayedIds.map((feature) => (
            <Styled.SectionListItem key={feature.label}>
              <strong>{feature.label}:</strong>
              <Styled.SectionListItemDescription>
                {typeof feature.value === "boolean"
                  ? t(`units:sections.values.${!!feature.value ? "yes" : "no"}`)
                  : feature.value}
              </Styled.SectionListItemDescription>
            </Styled.SectionListItem>
          ))}
          {sixYOFeature && (
            <Styled.SectionListItem>
              <strong>{sixYOFeature.label}:</strong>
              <Styled.SectionListItemDescription>
                {parse(decodeEscapedHtml(sixYOFeature.value))}
              </Styled.SectionListItemDescription>
            </Styled.SectionListItem>
          )}
        </>
      );
    }

    return null;
  };

  const displaySchoolBranches = () => {
    if (unit?.schoolbranches?.length > 0) {
      return (
        <>
          {unit.schoolbranches.map((branch) => (
            <Styled.BranchListItemLink
              to={`/${city}/${generalType}/info/Oddzial/${branch.id}`}
              state={ {unitId: unit.id, unitName: unit.name, branchName: branch.name} }
              key={branch.name}
            >
              {branch.name}
            </Styled.BranchListItemLink>
          ))}
        </>
      );
    }
    return null;
  };

  const displaySpecificSections = (name) => {
    switch (name) {
      case "additionalFeatures":
        return (
          <>
            {boolFeatures && (
              <AdditionalFeatures visibleFeatures={boolFeatures} />
            )}
          </>
        );
      case "additionalInformationSection":
        return <>{displayAdditionalInformation()}</>;
      case "branches":
        return (
          <>
            {(instance.unitGeneralType === UnitGeneralTypes.SecondarySchool ||
              instance.unitGeneralType === UnitGeneralTypes.TradeSchool) &&
              displaySchoolBranches()}
          </>
        );
      case "languages":
        return <>{unit.languages.map((lang) => languages[lang]).join(", ")}</>;
      case "terms":
        return (
          <>
            {unit.schoolbranches.map((branch) => (
              <Styled.TermsListItem key={branch.name}>
                {branch.normalizedName}{" "}
                {instance?.settings.hasOwnProperty("ShowFreePlaces") && (
                  <span>
                    {t("units:branches.features.freePlaces")}:{" "}
                    {branch.freePlaces}
                  </span>
                )}
              </Styled.TermsListItem>
            ))}
          </>
        );
      default:
        return <></>;
    }
  };

  const showButtons = () => {
    const buttons = [];

    if (
      instance?.allowPublicSubbmissions &&
      instance?.allowPublicClaimsAndApplications
    ) {
      buttons.push(
        instance?.unitGeneralType === 3
          ? buttonsInfo.report
          : buttonsInfo.declaration
      );
      buttons.push(buttonsInfo.application);
    } else if (instance?.allowPublicClaimsAndApplications) {
      if (instance?.unitGeneralType === 3) {
        buttons.push(buttonsInfo.report);
      } else {
        buttons.push(buttonsInfo.declaration);
      }
    } else if (instance?.allowPublicSubbmissions) {
      buttons.push(buttonsInfo.application);
    }

    return (
      <>
        {buttons.map(({ id, text }) => (
          <Styled.LinkButton to={`/${city}/${generalType}/${id}`} key={id}>
            {text}
          </Styled.LinkButton>
        ))}
      </>
    );
  };

  if (forbiddenUnit) {
    return (<Error403/>)
  }

  return (
    <Page>
      <Breadcrumbs />
      <Banner />

      <Layout.Container>
        <Styled.UnitPage>
          {unit && (
            <>
              <Styled.TitleContainer>
                <Text.TitleMain>{unit.name || unit.shortName}</Text.TitleMain>
                {showButtons()}
              </Styled.TitleContainer>

              <Styled.DisplayTouch>
                {isTouch && (
                  <Styled.GalleryContainer>
                    <Gallery images={unit.images ?? []} />
                  </Styled.GalleryContainer>
                )}
              </Styled.DisplayTouch>

              <Styled.FeatureContainer>
                {Object.keys(sectionConfig).map((side) => (
                  <Styled.FeatureSide key={side}>
                    {side === "left" && (
                      <Styled.DisplayDesktop>
                        {!isTouch && (
                          <Styled.GalleryContainer>
                            <Gallery images={unit.images ?? []} />
                          </Styled.GalleryContainer>
                        )}
                      </Styled.DisplayDesktop>
                    )}
                    {sectionConfig[side].map(
                      (section, index) =>
                        showSection({ section, unit, instance, usedIds }) && (
                          <Styled.Section key={index}>
                            <Styled.SectionHeading>
                              {section.hasOwnProperty("i18n_key")
                                ? t(`units:sections.${section.i18n_key}`)
                                : section.getLabel(unit)}
                            </Styled.SectionHeading>
                            {section?.features?.length > 0 ? (
                              <>
                                {section.features.map(
                                  (feature, f_index) =>
                                    showProperty({
                                      feature,
                                      unit,
                                      instance,
                                    }) && (
                                      <Styled.SectionListItem key={f_index}>
                                        <strong>
                                          {feature.hasOwnProperty("i18n_key")
                                            ? t(
                                                `units:sections.features.${feature.i18n_key}`
                                              )
                                            : feature.getLabel(unit)}
                                          :
                                        </strong>
                                        <Styled.SectionListItemDescription>
                                          {feature.getData(unit, {
                                            url: `${city}/${generalType}`,
                                          })}
                                        </Styled.SectionListItemDescription>
                                      </Styled.SectionListItem>
                                    )
                                )}
                              </>
                            ) : (
                              showSection({
                                section,
                                unit,
                                instance,
                                usedIds,
                              }) && (
                                <>
                                  {section.hasOwnProperty("display") ? (
                                    displaySpecificSections(section.display)
                                  ) : (
                                    <UnitHtmlSection>
                                      {section.getData(unit)}
                                    </UnitHtmlSection>
                                  )}
                                </>
                              )
                            )}
                          </Styled.Section>
                        )
                    )}
                  </Styled.FeatureSide>
                ))}
              </Styled.FeatureContainer>

              <Styled.TextLink
                onClick={() => {
                  navigationType === "PUSH"
                    ? navigate(-1)
                    : navigate(`/${city}/${generalType}/oferta`);
                }}
              >
                <RoundedArrow />
                {`${t("units:goBack")} ${instance.displayName}`}
              </Styled.TextLink>
            </>
          )}
        </Styled.UnitPage>
      </Layout.Container>
    </Page>
  );
};

export default Unit;
