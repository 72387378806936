import React, { useState, useEffect, useContext } from "react";

import { useTranslation } from "react-i18next";
import { useParams, Link } from "react-router-dom";
import { AppContext } from "context/appContext";
import useApi from "hooks/useApi";
import useUnknownCityOrType from "hooks/useUnknownCityOrType";

import { getInstance } from "utils/utils";
import { decodeEscapedHtml } from "utils/utils";
import { RoundedArrow } from "components/commons/icons/RoundedArrow";
import { Banner } from "components/commons";
import { DictUnitTypeExt, UnitGeneralTypes, UnitTypes } from "utils/enums";
import { getKeyByValue, linkify } from "utils/utils";
import { Page } from "components/commons/Page/Page";

import Breadcrumbs from "components/commons/Breadcrumbs/Breadcrumbs";
import url from "routes/routes";
import parse from "html-react-parser";

import * as Styled from "./UnitGroup.styles";
import * as Layout from "components/commons/Layout/Layout";
import * as Text from "components/commons/Text/Text";

const UnitGroup = () => {
  const params = useParams();
  const { t } = useTranslation();
  const { instances: instancesList, setBreadcrumbsPath } = useContext(AppContext);
  const { offerApi } = useApi();

  useUnknownCityOrType();

  const [unitGroup, setUnitGroup] = useState(null);
  const [instanceShort, setInstanceShort] = useState(null);

  useEffect(() => {
    if (instancesList?.length > 0) {
      const instances = getInstance(
        instancesList,
        params.city,
        params.generalType
      );
      if (instances != null && instances.length > 0) {
        const inst = instances[0];
        setInstanceShort(inst);
      }
    }
  }, [params.city, params.generalType, instancesList]);

  useEffect(() => {
    setBreadcrumbsPath([
      {
        name: t("breadcrumbs.allInstances"),
        url: url.allInstances.all,
      },
      {
        name: instanceShort?.instanceName || params.city,
        url: `/${params.city}/`,
      },
      {
        name: t(`unitTypes.${params.generalType}`),
        url: `/${params.city}/${params.generalType}/oferta`,
      },
      {
        name: unitGroup?.name || "Szkoła",
      },
    ]);
  }, [instanceShort?.instanceName, unitGroup?.name]);

  useEffect(() => {
    let isSubscribed = true;

    const fetchUnitGroup = async (id) => {
        const { data, error } = await offerApi.get(`unit/zespol/${id}`);

      if (isSubscribed) {
        setUnitGroup(data);
      }
    };
    fetchUnitGroup(params.uid);
    return () => (isSubscribed = false);
  }, [params.city, params.generalType, params.uid]);

  return (
    <Page>
      <Breadcrumbs />
      <Banner />
      <Layout.Container>
        <Styled.UnitGroupPage>
          {unitGroup && (
            <>
              <Text.TitleMain>{unitGroup.name}</Text.TitleMain>

              <Styled.UnitGroupSectionContainer>
                <Styled.UnitGroupSection>
                  <Styled.UnitGroupSectionHeading>
                    {t("units:unitGroup.sections.contact")}
                  </Styled.UnitGroupSectionHeading>
                  {(unitGroup.street ||
                    unitGroup.city ||
                    unitGroup.houseNo ||
                    unitGroup.postalCode) && (
                    <Styled.UnitGroupDict>
                      <strong>{t("units:unitGroup.features.address")}:</strong>
                      <p>{`${unitGroup.street} ${unitGroup.houseNo}, ${unitGroup.postalCode} ${unitGroup.city}`}</p>
                    </Styled.UnitGroupDict>
                  )}
                  {unitGroup.phones?.length > 0 && (
                    <Styled.UnitGroupDict>
                      <strong>{t("units:unitGroup.features.phone")}:</strong>
                      <p>{unitGroup.phones.join(", ")}</p>
                    </Styled.UnitGroupDict>
                  )}
                  {unitGroup.fax?.length > 0 && (
                    <Styled.UnitGroupDict>
                      <strong>{t("units:unitGroup.features.fax")}:</strong>
                      <p>{unitGroup.faxes.join(", ")}</p>
                    </Styled.UnitGroupDict>
                  )}
                  {unitGroup.email && (
                    <Styled.UnitGroupDict>
                      <strong>{t("units:unitGroup.features.email")}:</strong>
                      <a href={`mailto:${unitGroup.email}`}>
                        {unitGroup.email}
                      </a>
                    </Styled.UnitGroupDict>
                  )}
                  {unitGroup.www && (
                    <Styled.UnitGroupDict>
                      <strong>{t("units:unitGroup.features.www")}:</strong>
                      <a
                        href={linkify(unitGroup.www)}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {unitGroup.www}
                      </a>
                    </Styled.UnitGroupDict>
                  )}
                  {unitGroup.id_DictUnitType && (
                    <Styled.UnitGroupDict>
                      <strong>{t("units:unitGroup.features.unitType")}:</strong>
                      <p>
                        {Object.values(DictUnitTypeExt)
                          .find((e) => e.value === unitGroup.id_DictUnitType)
                          ?.toString()}
                      </p>
                    </Styled.UnitGroupDict>
                  )}
                </Styled.UnitGroupSection>

                {unitGroup.description && (
                  <Styled.UnitGroupSection>
                    <Styled.UnitGroupSectionHeading>
                      {t("units:unitGroup.sections.description")}
                    </Styled.UnitGroupSectionHeading>
                    {parse(decodeEscapedHtml(unitGroup.description))}
                  </Styled.UnitGroupSection>
                )}

                {unitGroup.units?.length > 0 && (
                  <Styled.UnitGroupSection>
                    <Styled.UnitGroupSectionHeading>
                      {t("units:unitGroup.sections.unitList")}
                    </Styled.UnitGroupSectionHeading>
                    <Styled.UnitGroupSectionList>
                      {unitGroup.units.map((el) => (
                        <li key={el.id}>
                          <Link
                            to={`/${params.city}/${
                              UnitTypes[
                                getKeyByValue(
                                  UnitGeneralTypes,
                                  el.id_DictUnitGeneralType
                                )
                              ]
                            }/info/${el.id}`}
                            state={{name: el.name}}
                          >
                            {el.name}
                          </Link>
                        </li>
                      ))}
                    </Styled.UnitGroupSectionList>
                  </Styled.UnitGroupSection>
                )}
              </Styled.UnitGroupSectionContainer>

              <Styled.UnitGroupBackLink
                href={`/${params.city}/${params.generalType}/oferta`}
              >
                <RoundedArrow />
                {`${t("units:goBack")} ${instanceShort?.displayName}`}
              </Styled.UnitGroupBackLink>
            </>
          )}
        </Styled.UnitGroupPage>
      </Layout.Container>
    </Page>
  );
};

export default UnitGroup;
