import styled from 'styled-components';

import { Button } from "components/commons/Button/Button";

export const InstanceSection = styled.div`
	padding-top: 0;
	margin-bottom: 5rem;
`;

export const OfferSubHeader = styled.h2`
	font-size: 2.2rem;
	color: ${({ theme }) => theme.colors.grey90};

	& span {
		font-weight: normal;
	}

	@media ${({ theme }) => theme.device.mobile} {
		font-size: 2rem;
	}
`;

export const ScheduleFlexContainer = styled.div`
    display: flex;
    justify-content: center;

    @media ${({ theme }) => theme.device.desktop} {
        padding: 0px 3rem;
    }
    @media ${({ theme }) => theme.device.widescreen} {
        padding: 0px 15rem;
    }
`;

export const OfferScheduleContainer = styled.div`
    width: 100%;
	margin-bottom: 5rem;
	color: ${({ theme }) => theme.colors.grey90};
    max-width: 160rem;

    @media ${({ theme }) => theme.device.desktop} {
        margin-bottom: 0;
    }
`;

export const OfferScheduleHeader = styled.h3`
	font-size: 2rem;
	margin-bottom: 3rem;
    padding-left: 2rem;
    @media ${({ theme }) => theme.device.desktop} {
        padding-left: 0;
    }
`;

export const OfferDescriptionContainer = styled.div`
	position: relative;
	margin-bottom: 8.3rem;

	&::after {
		display: block;
		content: "";
		position: absolute;
		bottom: -4rem;
		left: 0;
		height: 2px;
		width: 70%;
		background-color: ${({ theme }) => theme.colors.accent};
		opacity: 0.35;
	}

	@media ${({ theme }) => theme.device.mobile} {
		&::after {
			width: 100%;
		}
	}
`;

export const OfferApplicationActions = styled.div`
    margin-top: 30px;
	display: flex;
    flex-direction: column;
    gap: 1.5rem;

	@media ${({ theme }) => theme.device.mobile} {
		gap: 0;
	}
`;

export const OfferActionEndorsment = styled.p`
	margin-bottom: 10px;

	@media ${({ theme }) => theme.device.mobile} {
		margin-bottom: 20px;
	}
`;

export const OfferActionButtons = styled.div`
	display: flex;
	gap: 20px;

	@media ${({ theme }) => theme.device.mobile} {
		flex-direction: column;
		gap: 0;
	}
`;

export const OfferDescription = styled.div`
	display: flex;
	align-items: stretch;
	justify-content: space-between;

	@media ${({ theme }) => theme.device.touch} {
		padding: 18px 0;
		gap: 15px;
		align-items: baseline;
	}

	@media ${({ theme }) => theme.device.mobile} {
		flex-direction: column;
		align-items: center;
		gap: 0;
	}

	& p {
		width: 70%;
		display: flex;
		align-items: center;
		margin-top: 3.1rem;

		@media ${({ theme }) => theme.device.touch} {
			border-bottom: 0;
		}

		@media ${({ theme }) => theme.device.mobile} {
			width: 100%;
			margin-bottom: 38px;
		}
	}
`;

export const OfferLink = styled(Button)`
  color: ${({ theme }) => theme.colors.white};
  background: none;
  border-radius: 8px;
  border-color: transparent;

  &:hover {
    text-decoration: underline;
    color: ${({ theme }) => theme.colors.white};
    text-decoration-color: ${({ theme }) => theme.colors.white};
    box-shadow: none;
    background: none;
    border-color: transparent;
  }

  &:focus, &:focus-visible, &:active {
	text-decoration: underline;
	border-color: ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors.white};
	outline: none;
    background: none;
  }

  &:disabled, &[disabled] {
    color: ${({ theme }) => theme.colors.grey60};
    cursor: not-allowed;
  }

  @media ${({ theme }) => theme.device.mobile} {
    width: 312px;
    text-align: center;
  }
`;

export const OfferButton = styled(Button)`
	background-color: transparent;
	color: ${({ theme }) => theme.colors.white};
`;
