import styled from "styled-components";
import { Button } from "components/commons";

import hero360 from "assets/images/home/hero_360.jpg";
import hero720 from "assets/images/home/hero_720.jpg";
import hero1040 from "assets/images/home/hero_1040.jpg";
import hero1600 from "assets/images/home/hero_1600.jpg";
import hero1920 from "assets/images/home/hero_1920.jpg";

export const InfoSection = styled.section`
    mix-blend-mode: multiply;
    width: 100%;

    background-image: url(${hero360});
    background-repeat: no-repeat;
    background-position: top right;
    position: relative;

    top: ${props => props.primary ? '-10rem' : '8rem'};

    padding: ${props => props.primary ? '17rem 2.4rem 8rem' : '7rem 2.4rem 8rem'};
    margin-bottom: ${props => props.primary ? '-10rem' : 'auto'};

    @media ${({ theme }) => theme.device.mobileSmall} {
        padding: 17rem 2.4rem 8rem;
    }

    @media ${({ theme }) => theme.device.tablet} {
        background-image: url(${hero720});
        top: ${props => props.primary ? '-17rem' : '16rem'};
        padding: ${props => props.primary ? '30rem 4rem 1rem' : '10rem 4rem 18rem'};        
    }

    @media ${({ theme }) => theme.device.desktop} {
        background-image: url(${hero1040});
        top: -17rem;
        padding-top: 27rem;
        margin-bottom: -20rem;
        padding-bottom: 8rem;
        min-height: 56rem;
    }

    @media ${({ theme }) => theme.device.widescreen} {
        background-image: url(${hero1600});
        min-height: 73rem;
        padding-top: 42rem;
        padding-left: 16rem;
    }

    @media ${({ theme }) => theme.device.superHd} {
        background-image: url(${hero1920});
        background-position: 105% 0;
        padding-top: 42rem;
        padding-left: calc(50vw - 80rem);
    }
`;

export const InfoSectionContent = styled.div`
    display: grid;
    grid-gap: 3rem;
    max-width: calc(100vw - 23rem);
  
    @media ${({ theme }) => theme.device.mobileSmall} {
        /* < 576px */
        max-width: unset;
    }

    @media ${({ theme }) => theme.device.tablet} {
        max-width: unset;
        grid-gap: 11rem;
        >.heading-main {
            max-width: calc(-40rem + 100vw);
        }
    }
    @media ${({ theme }) => theme.device.desktop} {
        grid-gap: 4rem;
        max-width: calc(-68rem + 100vw);
        >.heading-main {
            max-width: unset;
        }
    }
    @media ${({ theme }) => theme.device.widescreen} {
        grid-gap: 7rem;
        max-width: calc(-87rem + 100vw);
    }
    @media ${({ theme }) => theme.device.superHd} {
        grid-gap: 9rem;
        max-width: calc(-15rem + 50vw);
        >.heading-main {
            max-width: calc(-25rem + 50vw);
        }
    }
`;

export const BlurIcons = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  z-index: -1;
`;

export const BlurIconsWrapper = styled.div`
  position: relative;
  height: 100vh;
`;

export const BlurIcon = styled.img`
  position: absolute;
  z-index: -1;

  &:nth-child(1) {
    top: 20%;
    left: -250px;
    width: 230px;
    filter: blur(1rem);
    opacity: 0.8;
  }

  &:nth-child(2) {
    top: calc(20% + 230px);
    left: -250px;
    width: 170px;
    filter: blur(1rem);
    opacity: 0.8;
  }

  &:nth-child(3) {
    bottom: 15%;
    left: -150px;
    filter: blur(5.5rem);
    opacity: 0.4;
    width: 210px;
  }

  &:nth-child(4) {
    bottom: -5%;
    right: -250px;
    width: 450px;
    filter: blur(5.5rem);
    opacity: 0.3;
  }
`;

export const HeadButtonWrapper = styled.div`
    display: flex;
    justify-content: center;
    margin: 4rem 0 3rem;
    @media ${({ theme }) => theme.device.desktop} {
        margin: 5rem 0 2rem;
        justify-content: flex-start;
    }
`;

export const HeadButton = styled(Button)`
    max-width: max-content;
`;
