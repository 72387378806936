import styled from "styled-components";

export const TrustedProfileContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  gap: 5rem;
  width: 80%; 
`;

export const PersonalInfoContainer = styled.div`
display: flex;
flex-direction: row;
gap: 6rem;
padding-bottom: 4rem;
border-bottom: 1px solid ${({ theme }) => theme.colors.main};

div p:first-of-type {
    font-weight: bold;
  }
`;

export const Info = styled.div`
display: flex;
flex-direction: column;
gap: 2rem;
`