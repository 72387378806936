import styled from 'styled-components';


export const NavigationSection = styled.section`
    display: flex;
    justify-content: center;
`;

export const NavigationSectionWrapper = styled.div`
    display: flex;
    flex-direction: row;
    gap: 4.2rem;
    position: relative;

    width: 100%;
    max-width: 160rem;
    
    // width: 100%;
    height: 21.5rem;
    background: #BACDF0;

    margin: 0 -3.2rem 6rem;
    padding: 8rem 3rem;
    justify-content: center;

    margin-bottom: 5rem;


    @media ${({theme}) => theme.device.tablet} {
        margin-bottom: 9rem;
    }

    @media ${({theme}) => theme.device.desktop} {
		border-radius: 52px;
        margin: 0 4rem 5rem;
        padding: 8rem 4rem;
    }

    @media ${({theme}) => theme.device.widescreen} {
        margin: 0 16rem 5rem;
    }

    @media ${({theme}) => theme.device.touch} {
        flex-direction: column;
        align-items: center;
        justify-content: start;
        height: 30rem;
    }
`;

export const Title = styled.div`
    text-align: center;
    align-self: center;

    @media ${({theme}) => theme.device.desktop} {
        min-width: max-content;
    }

    & > h2 {
        color: ${({theme}) => theme.colors.black};
    }

`

export const SelectContainer = styled.div`
    max-width: 580px;
    width: 100%;
    @media ${({theme}) => theme.device.mobile} {
        >.select {
            min-width: unset;
        }
    }
` 
