import { createGlobalStyle } from "styled-components";
import hyperlink from "assets/images/hyperlink.svg";

export const GlobalStyles = createGlobalStyle`
    *,
    *:before,
    *::after {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }

    html, body {
        height:100%;
    }

    html {
        background-color: ${({ theme }) => theme.colors.white};
        font-size: 62.5%;
        scroll-behavior: smooth;
    }

    body {
        font-family: "Barlow", sans-serif;
        font-weight: 400;
        line-height: normal;
        color: ${({ theme }) => theme.colors.main};
        font-size: 1.6rem;
    }

    #root {
        min-height: 100vh;
        height: 100%;
        display: flex;
        flex-direction: column;
    }


    h1 {
        font-size: 3rem;
    }

    p {
        font-size: 1.6rem;
    }


    a {
        text-decoration: none;
        font-size: 1.4rem;
        color: ${({ theme }) => theme.colors.main};

        &.active {
            color:#393939;
            font-weight: 600;
        }

        &[target=_blank]::after {
            content: "";
            display: inline-block;
            margin-left: 1.3rem;
            width: 0.9em;
            height: 0.9em;
            background: url(${hyperlink});
        }
    }

    .lowercase {
        text-transform: lowercase;
    }

    input {
        accent-color: ${({ theme }) => theme.colors.accent};
    }
`;
