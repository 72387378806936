import { useEffect, useContext, useMemo } from 'react';
import { useParams, useNavigate } from "react-router-dom";
import { AppContext } from 'context/appContext';

import { cities } from "utils/utils";
import { UnitTypes } from "utils/enums";

const useUnknownCityOrType = () => {
    const navigate  = useNavigate();
    const { city, generalType } = useParams();
    const { instances } = useContext(AppContext);

    const availableCitiesList = useMemo(() => {
        if (!instances) return null;
        return cities(instances).map(({id}) => id);
    }, [instances]);

    const availableTypesList = useMemo(() => {
        return Object.values(UnitTypes);
    }, []);

    const cityIsValid = useMemo(() => {
        if (availableCitiesList?.length === 0) return true;
        return availableCitiesList?.includes(city);
    }, [availableCitiesList, city]);

    useEffect(() => {
        if (availableCitiesList.length > 0 && 
            cityIsValid && 
            !availableTypesList.includes(generalType)
        ){
            navigate(`/${city}`);
        }

        if (availableCitiesList.length > 0 && 
            !cityIsValid && 
            availableTypesList.includes(generalType)
        ){
            navigate(`/wdrozenia/${generalType}`);
        }
    }, [instances, cityIsValid]);

    return cityIsValid;
}

export default useUnknownCityOrType;