import React from 'react';

import * as Styled from './DropletThumbnail.styles';

const DropletThumbnail = ({ 
    src, 
    alt, 
    start, 
    end, 
    maxwidth,
    rotationangle,
    padding, 
    color, 
    transitionspeed = 0.3,
    imagerotation, 
    imagescale 
}) => {

    const dropletRotationSpeed = transitionspeed * Math.abs(rotationangle) / 90;

    return (
        <Styled.DropletThumbnail padding={padding} end={end}  maxwidth={maxwidth} rotationangle={rotationangle} imagerotation={imagerotation} imagescale={imagescale}>
            <Styled.DropletThumbnailDropled color={color} start={start} dropletRotationSpeed={dropletRotationSpeed} />
            <Styled.DropletThumbnailContent>
                <Styled.DropletThumbnailImage src={src} alt={alt} />
            </Styled.DropletThumbnailContent>
        </Styled.DropletThumbnail>
    )
};

export default DropletThumbnail;