import styled, { css } from "styled-components";
import { ButtonText } from 'components/commons/Button/ButtonText';
import { Container } from 'styles/common.styles';
import { Dropdown } from "components/commons/Dropdown/Dropdown";
import { Input } from "components/commons/Input/Input";
import { Button } from 'components/commons/Button/Button';


export const FiltersSection = styled.section`
    position: relative;
    margin: 0px -1.5rem;
    @media ${({ theme }) => theme.device.tablet} {
        margin: 0px -3rem;
    }
    @media ${({ theme }) => theme.device.desktop} {
        margin: 0;
    }
    padding-bottom: 4rem;
`;

export const SearchContainer = styled.div`
    width: 100%;
    background-color: ${({ theme }) => theme.colors.backgroundPrime};
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 3rem 2rem;
`;
export const SearchInputWrapper = styled.div`
    position: relative;
    width: 100%;
`;

export const SearchInputClearButton = styled.div`
    position: absolute;
    right: 1rem;
    top: 1.3rem;
    cursor: pointer;
    width: 2rem;
    height: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;

    >img {
        width: 1rem;
        height: 1rem;
        filter: grayscale(100%);
        opacity: 0.4;
    }
    &:hover {
        >img {
            opacity: 0.8;
        }
    }
`;

const MaxWidthWrapper = styled(Container)`
    width: 100%;
    @media ${({ theme }) => theme.device.desktop} {
        max-width: min(calc(100vw - 8rem), 120rem);
    }
    @media ${({ theme }) => theme.device.widescreen} {
        max-width: 120rem;
    }
`;

export const SearchWrapper = styled(MaxWidthWrapper)`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 3rem;
`;

export const SearchHeader = styled.div`
    width: 100%;
    text-align: center;
	font-size: 1.8rem;
	font-weight: 600;
	color: ${({ theme }) => theme.colors.grey90};
	@media ${({ theme }) => theme.device.tablet} {
		font-size: 2rem;
	}
	@media ${({ theme }) => theme.device.desktop} {
		font-size: 2.6rem;
	}
`;

export const SearchBar = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: end;
    gap: 3rem;

    ${({ hasTwoFilterElements }) => hasTwoFilterElements && css`
        grid-template-columns: 1fr 1fr;
        >button{
            justify-self: end;
            grid-column-end: -1;
        }
	`}
    
    // on mobile - all grid settings of twoFiltersElements should be overriden
    @media ${({ theme }) => theme.device.mobile} {
        grid-template-columns: 1fr;
        >button{
            justify-self: center;
        }
    }
`;

export const SearchFooter = styled.div`
    @media ${({ theme }) => theme.device.tablet} {
        align-self: flex-start;
    }
`;

export const FiltersContainer = styled(SearchContainer)`
    background-color: ${({ theme }) => theme.colors.ligthblueFiltersBackground};
`;

export const FiltersWrapper = styled(MaxWidthWrapper)`
    display: grid;
    gap: 2rem 3rem;
    grid-template-columns: 1fr;
    margin: 4rem 0;
    
    @media ${({ theme }) => theme.device.tablet} {        
        grid-template-columns: repeat(2, 1fr);
    }
    @media ${({ theme }) => theme.device.desktop} {
        gap: 2rem 4rem;
        grid-template-columns: repeat(3, 1fr);
    }
`;

export const ButtonsContainer = styled.div`
    grid-column-end: -1;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    
`;
export const ButtonsWrapper = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    @media ${({ theme }) => theme.device.tablet} {
        flex-direction: row;
        justify-content: space-between;
    }    
`;

export const OfferNotification = styled.p`
    font-size: 2rem;
	font-weight: 600;
	color: ${({ theme }) => theme.colors.grey90};
	@media ${({ theme }) => theme.device.mobile} {
		font-size: 1.4rem;
	}
	text-align: center;
`;

export const TextButton = styled(ButtonText)`
    font-size: 1.6rem;
    font-weight: 600;
`;

export const FiltersDropdown = styled(Dropdown)`
    & [class*="-control"] {
        min-height: 4.5rem;
    }
`;

export const FiltersInput = styled(Input)`
    min-height: 4.5rem;

    &::placeholder{
        color: ${({ theme }) => theme.colors.grey60};
    }
`;


export const ExpandFiltersButton = styled(Button)`
    padding: 1.2rem 3rem;
    >img {
        width: 2.5rem;
        margin-left: 2rem;
    }
    &:focus {
        img {
            filter: none;
        }
    }
    &:hover {
        img {
            filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(200%) contrast(100%);
        }
    }


`;