import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from "styled-components";
import parse from "html-react-parser";

import Modal from 'components/commons/Modal/Modal';
import { Button } from 'components/commons/Button/Button';
import { useLocation } from "react-router-dom";

// type: 1 - Regulamin 2- Oświadczenia

const ModalRegulations = ({setInitialRegulations, regulationsId, type }) => {
    const { pathname } = useLocation();
    const { t } = useTranslation();
    const [regulations, setRegulations] = useState(null);
    const [allChecked, setAllChecked] = useState(false);
    
    const closeModal = () => {
        setInitialRegulations(null);
    }

    const findCheckboxes = () => {
        const checkboxes = document.querySelectorAll('.regulation-wrapper input[type="checkbox"]');
        checkboxes.forEach((checkbox) => {
            checkbox.addEventListener('change', () => {
              checkAllCheckboxes();
            });
        });
    }

    const checkAllCheckboxes = () => {
        const checkboxes = document.querySelectorAll('.regulation-wrapper input[type="checkbox"]');
        setAllChecked(!Array.from(checkboxes).some((checkbox) => !checkbox.checked));
    }

    const autoCheckAll = () => {
        const checkboxes = document.querySelectorAll('.regulation-wrapper input[type="checkbox"]');
        checkboxes.forEach((checkbox) => {
            checkbox.checked = true;
          });
          setAllChecked(true);
    }

    const fetchRegulations = async (regulationsId) => {
        try {
          const response = await fetch(`${process.env.PUBLIC_URL}/regulations/${regulationsId}/index.html`)
           const resText = await response.text();
           setRegulations(resText);
           await new Promise(resolve => setTimeout(resolve, 100));// wait for html render
           findCheckboxes();
        } catch (error) {
          console.error(error);
        }
      }

    useEffect(() => {
        fetchRegulations(regulationsId);
    }, []);

    return (
        <Modal
            closeModal={closeModal}
            wrapModal={true}
            closeButton={false}
            closeOnBackground={false}
        >
            {(closeModal) => (
                <>
                
                <RegulationWrapper
                    className={'regulation-wrapper'}
                >
                    <h2>{type === "2"
                        ? t("application.statements")
                        : t("application.regulations")
                    }</h2>
                    {regulations && parse(regulations)}
                </RegulationWrapper>
                <CloseButtonWrapper>
                    <Button
                        secondary
                        type="button"
                        to={`/${pathname.split('/').slice(1, 3).join('/')}`}
                        className='cancel-button'
                        bold
                    >
                        {t("application.exit")}
                    </Button>

                    <Button
                        type='button'
                        onClick={ (allChecked || type === "1")  ? closeModal : autoCheckAll}
                        // disabled={!allChecked}
                    >
                        {allChecked 
                            ? t("application.goFurther") 
                            : (type === "2" ? t("application.statementsConsent") : t("application.regulationsConsent"))
                        }
                    </Button>
                </CloseButtonWrapper>
                </>
            )}
        </Modal>
    );
};

export default ModalRegulations;

const RegulationWrapper = styled.div`
    color: black;
    padding: 2rem;
    max-height: calc(-32rem + 100dvh);
    width: 100%;
    overflow-y: auto;
    & * {
        line-height: 3rem;
    }
    & h3 {
        color: inherit;
    }
    & h2 {
        margin-bottom: 2rem;
    }
    & ol {
        padding-left: 20px;
    }
    & ul {
        list-style-type:disc;
        padding-left: 40px;
    }
    & a {
        font-weight: bold;
        font-size: 1.6rem;
        color: #003189;
    }
    & a:hover {
        text-decoration: underline;
    }
    & input[type="checkbox"] {
        accent-color: #003189;
        margin-right: 1rem;
        position: relative;
        &::after {
            content: "*";
            color: ${({ theme }) => theme.colors.warning};
            position: absolute;
            top: -6px;
            right: -9px;
            font-size: 1.6rem;
        }
    }
    @-moz-document url-prefix() {
        & label:has(input[type="checkbox"]) {
            accent-color: #003189;
            margin-right: 1rem;
            position: relative;
        }
        & label:has(input[type="checkbox"])::before {
            content: '*';
            color: ${({ theme }) => theme.colors.warning};
            position: absolute;
            top: -6px;
            left: 16px;
            font-size: 1.6rem;
        }
    }
`;

const CloseButtonWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
    gap: 1rem;
`
