import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import useApi from "hooks/useApi";

import ApplicationGeneralInformation from "./elements/ApplicationGeneralInformation";
import ApplicationPreferencesList from "./elements/ApplicationPreferencesList";
import ApplicationAttachments from "./elements/ApplicationAttachments";

import Loader from "components/commons/Loader/Loader";

import alertIcon from "assets/icons/ico-triangle-alert.svg";
import alertIconDark from "assets/icons/ico-triangle-alert-dark.svg";
import * as Styled from "pages/ApplicationList/elements/ChildrenOverview/ApplicationItem/ApplicationItem.styles";

import IconTooltip from 'components/commons/Tooltip/IconTooltip';
import dayjs from "dayjs";

const ApplicationPanel = ({
    savedApplications,
    submittedApplications,
    deleteApplication,
    submitApplication,
    sentVerificationLink,
    requestForWithdrawal,
    removeRequestForWithdrawal,
    signApplication,
    prolongateApplication,
    processApplication,
    acceptApplication,
    recreateApplication,
    copyApplication,
    getDeclination,
    isMobile,
    instanceName,
    instanceInfo,
}) => {
    const { generalType } = useParams();
    const { candidateApi } = useApi();
    const [selectedTab, setSelectedTab] = useState("1");
    const [schoolBranchesList, setSchoolBranchesList] = useState([]);
    const [applicationInfo, setApplicationInfo] = useState([]);
    const [hasAttachments, setHasAttachments] = useState(false);
    const [attachments, setAttachments] = useState([]);
    const [loading, setLoading] = useState(false);
    const [appId, setAppId] = useState(null);
    const [isIncomplete, setIncomplete] = useState(false);

    const applicationTypes = [
        { application: savedApplications, isSaved: true },
        { application: submittedApplications, isSaved: false },
    ];

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = date.getDate().toString().padStart(2, "0");
        const month = (date.getMonth() + 1).toString().padStart(2, "0");
        const year = date.getFullYear().toString();
        const hours = date.getHours().toString().padStart(2, "0");
        const minutes = date.getMinutes().toString().padStart(2, "0");
        const seconds = date.getSeconds().toString().padStart(2, "0");

        return `${day}.${month}.${year} - ${hours}:${minutes}:${seconds}`;
    };

    const getLongestValidSchoolBranch = (app) => {
        let result =  app?.schoolBranches?.flatMap(x=>x.validToInContInstance).reduce(function (p, v) {
            return ( p > v ? p : v );
        });
        if (result)
            result = formatDate(result);
        return result;
    }

    const isProlongationRequired = (app) => {
        let result = false;
        let nearestEnd =  app?.schoolBranches?.flatMap(x=>x.validToInContInstance).reduce(function (p, v) {
            return ( p < v ? p : v );
        });
        if (nearestEnd) {
            const end = dayjs(nearestEnd);
            const now = dayjs();
            if (end > now) {
                const days = end.diff(now, 'day');
                result = days < 31;
            }
        }
        return result;
    }

    const getQualifiedSchoolBranch = (app) => {
        let result = null;
        let results =  app?.schoolBranches?.filter(x=>
            x.id_DictApplicationContStatus === 5
            || x.id_DictApplicationContStatus === 10
            || x.id_DictApplication2SBStatus === 5 || x.id_DictApplication2SBStatus === 10);
        if (results && results.length)
            result = results[0].unitName+" / "+results[0].schoolBranchName;
        return result;
    }

    // General Application Information
    const getApplicationInfo = () => {
        const generalApplicationInfo = applicationTypes.flatMap(
            ({ application, isSaved }) =>
                application?.map((app) => ({
                    id: app.id,
                    docId: app.docId,
                    fullName: app.fullName,
                    id_DictApplicationStatus: app.id_DictApplicationStatus,
                    status: app.status,
                    qualified: getQualifiedSchoolBranch(app),
                    createdDate: formatDate(app.createdDate),
                    validDate: getLongestValidSchoolBranch(app),
                    isProlongationRequired: isProlongationRequired(app),
                    lastModified: formatDate(app.modifiedDate),
                    isEditable: app.isEditable,
                    isRemovable: app.isRemovable,
                    isDownloadable: app.isDownloadable,
                    incomplete: app.incomplete,
                    id_DictApplicationType: app.id_DictApplicationType,
                    isSaved: isSaved,
                    unverifyAlertSent: app.unverifyAlertSent,
                    sa: app.supplementaryAdmission,
                    isSA: instanceInfo.supplementaryAdmission,
                    isContinuous: instanceInfo.operatingYear === 0,
                    instanceStatus: instanceInfo.instanceStatus,
                    signDocument: !instanceInfo.settings?.DisableSiginDocuments,
                    hasMailVerification: instanceInfo.settings?.SendVerificationEmail || instanceInfo?.settings?.CandidateEmailOrLoginRequired || instanceInfo?.settings?.RequireEmailAsLogin,
                    remoteVerification: instanceInfo.settings?.RemoteVerificationByCandidate
                })) || []
        );

        const isAnyIncomplete = generalApplicationInfo.some(
            (app) => app.incomplete
        );
        setIncomplete(isAnyIncomplete);

        setApplicationInfo(generalApplicationInfo);
    };

    // Preferences List
    const getSchoolBranchesList = (savedApplications, submittedApplications) => {
        const branchesList = applicationTypes.flatMap(
            ({ application }) =>
                application?.flatMap(
                    (app) =>
                        app.schoolBranches?.map((branch) => ({
                            id_SchoolBranch: branch.id_SchoolBranch,
                            name: branch.schoolBranchName,
                            unitId: branch.id_Unit,
                            unitName: branch.unitName,
                            status: branch.status,
                            verificationDate: formatDate(branch.verificationDate),
                            totalPoints: branch.penultimatePhasePoints,
                            pointOverMin: branch.lastPhasePoints,
                        })) || []
                ) || []
        );
        setSchoolBranchesList(branchesList);
    };

    // Attachments List
    const checkAttachments = (savedApplications, submittedApplications) => {
        const checkForAttachments = (applications) => {
            applications.forEach((app) => {
                if (app.attachmentsCount > 0) {
                    setHasAttachments(true);
                    setAppId(app.id);
                } else {
                    setHasAttachments(false);
                }
            });
        };

        if (savedApplications && savedApplications.length > 0) {
            checkForAttachments(savedApplications);
        } else if (submittedApplications && submittedApplications.length > 0) {
            checkForAttachments(submittedApplications);
        }
    };

    const getAttachmentList = async (appId) => {
        setLoading(true);
        try {
            const { data: appList, error } = await candidateApi.get(
                `Application/${appId}/attachments`
            );
            if (error) {
                throw new Error(error);
            }
            if (appList) {
                setAttachments(appList);
            }
        } catch (err) {
            // setAttachmentsError(err);
            console.error("AttachmentList fetch error:", err);
        } finally {
            setLoading(false);
        }
    };

    // Actions for aplication tabs
    const actions = {
        1: getApplicationInfo,
        2: getSchoolBranchesList,
        3: checkAttachments,
    };

    const handleTabClick = (number) => {
        setSelectedTab(number);

        const action = actions[number];
        if (action) {
            action(savedApplications, submittedApplications);
        }
    };

    useEffect(() => {
        const action = actions[selectedTab];
        if (action) {
            action(savedApplications, submittedApplications);
        }
    }, [selectedTab, savedApplications, submittedApplications]);

    useEffect(() => {
        if (appId && hasAttachments) {
            getAttachmentList(appId);
        }
    }, [appId, hasAttachments]);

    useEffect(() => {
        if (
            (isMobile && savedApplications) ||
            (isMobile && submittedApplications)
        ) {
            getSchoolBranchesList(savedApplications, submittedApplications);
            checkAttachments(savedApplications, submittedApplications);
        }

        if (savedApplications || submittedApplications) {
            setSelectedTab("1");
        }
    }, [savedApplications, submittedApplications]);

    // Render Content
    const tabs = [
        {
            id: "1",
            label: `Informacje o ${getDeclination(
                generalType,
                "singular",
                "miejscownik"
            )}`,
            incomplete: isIncomplete,
        },
        { id: "2", label: "Lista Preferencji" },
    ];

    if (instanceInfo.settings?.ApplicationAttachments) {
        tabs.push({
            id: "3",
            label: `Załączniki do ${getDeclination(
                generalType,
                "singular",
                "dopelniacz"
            )}`,
        });
    }

    const renderApplicationContent = () => {
        switch (selectedTab) {
            case "1":
                return (
                    <ApplicationGeneralInformation
                        applicationInfo={applicationInfo}
                        deleteApplication={deleteApplication}
                        submitApplication={submitApplication}
                        sentVerificationLink={sentVerificationLink}
                        requestForWithdrawal={requestForWithdrawal}
                        removeRequestForWithdrawal={removeRequestForWithdrawal}
                        signApplication={signApplication}
                        prolongateApplication={prolongateApplication}
                        processApplication={processApplication}
                        acceptApplication={acceptApplication}
                        recreateApplication={recreateApplication}
                        copyApplication={copyApplication}
                        getDeclination={getDeclination}
                        isMobile={isMobile}
                        isIncomplete={isIncomplete}
                    />
                );
            case "2":
                return (
                    <ApplicationPreferencesList
                        schoolBranchesList={schoolBranchesList}
                        isMobile={isMobile}
                        instanceName={instanceName}
                    />
                );
            case "3":
                return (
                    <Styled.ApplicationInfoWrapper>
                        {loading ? (
                            <Loader />
                        ) : (
                            <ApplicationAttachments
                                attachments={hasAttachments ? attachments : []}
                                formatDate={formatDate}
                                getAttachmentList={getAttachmentList}
                                applicationId={applicationInfo[0]?.id}
                                appType={applicationInfo[0]?.id_DictApplicationType}
                                getDeclination={getDeclination}
                            />
                        )}
                    </Styled.ApplicationInfoWrapper>
                );
            default:
                return null;
        }
    };

    return (
        <>
            {!isMobile && (
                <Styled.ApplicationInformationPanel className="application-information-panel">
                    <Styled.ApplicationInformationPanelWrapper className="application-information-panel-wrapper">
                        <Styled.ApplicationInformationPanelTabContainer className="application-information-tab-container">
                            {tabs.map((tab) => {
                                return (
                                    <Styled.ApplicationPanelTab
                                        key={tab.id}
                                        className="application-panel-tab"
                                        isActive={selectedTab === tab.id}
                                        onClick={() => handleTabClick(tab.id)}
                                    >
                                        {tab.incomplete ? (    
                                            <Styled.ApplicationPanelTabInner 
                                                incomplete={true}
                                                isActive={selectedTab === tab.id}
                                            >
                                                {tab.label}
                                                <IconTooltip
                                                    tooltip='Status podania: niekompletny'
                                                    isMobile={false}
                                                >
                                                    <img
                                                        src={selectedTab === tab.id ? alertIcon : alertIconDark}
                                                        alt="Alert Icon"
                                                    />
                                                </IconTooltip>
                                            </Styled.ApplicationPanelTabInner>
    
                                        ) : (
                                            <Styled.ApplicationPanelTabInner>
                                                {tab.label}
                                            </Styled.ApplicationPanelTabInner>
                                            // <p></p>
                                        )}
                                    </Styled.ApplicationPanelTab>
                                );
                            })}
                        </Styled.ApplicationInformationPanelTabContainer>
                        <Styled.ApplicationInformationContainer>
                            {renderApplicationContent()}
                        </Styled.ApplicationInformationContainer>
                    </Styled.ApplicationInformationPanelWrapper>
                </Styled.ApplicationInformationPanel>
            )}
            {isMobile &&
                applicationTypes.map(
                    ({ application }, index) =>
                        application && (
                            <ApplicationPanelMobile
                                key={application.id}
                                applications={application}
                                applicationInfo={applicationInfo}
                                deleteApplication={deleteApplication}
                                submitApplication={submitApplication}
                                sentVerificationLink={sentVerificationLink}
                                requestForWithdrawal={requestForWithdrawal}
                                removeRequestForWithdrawal={removeRequestForWithdrawal}
                                signApplication={signApplication}
                                getDeclination={getDeclination}
                                isMobile={isMobile}
                                hasAttachments={hasAttachments}
                                formatDate={formatDate}
                                attachments={hasAttachments ? attachments : []}
                                schoolBranchesList={schoolBranchesList}
                                loading={loading}
                                applicationId={applicationInfo[0]?.id}
                                appId={appId}
                                checkAttachments={checkAttachments}
                                instanceName={instanceName}
                            />
                        )
                )}
        </>
    );
};

export default ApplicationPanel;

const ApplicationPanelMobile = ({
    applications,
    applicationInfo,
    deleteApplication,
    submitApplication,
    sentVerificationLink,
    requestForWithdrawal,
    removeRequestForWithdrawal,
    signApplication,
    prolongateApplication,
    processApplication,
    acceptApplication,
    recreateApplication,
    copyApplication,
    getDeclination,
    formatDate,
    isMobile,
    schoolBranchesList,
    loading,
    attachments,
    hasAttachments,
    applicationId,
    instanceName,
}) => {
    return (
        <>
            {applications.map((application, index) => (
                <Styled.ApplicationPanelMobile
                    key={application.id}
                    className="application-panel-mobile"
                >
                    <ApplicationGeneralInformation
                        applicationInfo={applicationInfo}
                        deleteApplication={deleteApplication}
                        submitApplication={submitApplication}
                        sentVerificationLink={sentVerificationLink}
                        requestForWithdrawal={requestForWithdrawal}
                        removeRequestForWithdrawal={removeRequestForWithdrawal}
                        signApplication={signApplication}
                        prolongateApplication={prolongateApplication}
                        processApplication={processApplication}
                        acceptApplication={acceptApplication}
                        recreateApplication={recreateApplication}
                        copyApplication={copyApplication}
                        getDeclination={getDeclination}
                        isMobile={isMobile}
                    />
                    <ApplicationPreferencesList
                        schoolBranchesList={schoolBranchesList}
                        isMobile={isMobile}
                        instanceName={instanceName}
                    />
                    {loading ? (
                        <Loader />
                    ) : (
                        <ApplicationAttachments
                            attachments={hasAttachments ? attachments : []}
                            formatDate={formatDate}
                            applicationId={applicationId}
                            appType={applicationInfo[0]?.id_DictApplicationType}
                            getDeclination={getDeclination}
                            isMobile={isMobile}
                        />
                    )}
                </Styled.ApplicationPanelMobile>
            ))}
        </>
    );
};
