import { useState, useEffect, useCallback } from 'react';
import { useBlocker,  } from "react-router-dom";
const usePageBlocker = (enableGuard = false) => {
    const [ pageBlocked, setPageBlocked ] = useState(false);

    const blocker = useBlocker(
        ({ currentLocation, nextLocation }) => {
            return (currentLocation.pathname !== nextLocation.pathname) && enableGuard
        }
    );

    useEffect(() => {
        if (blocker.state === 'blocked' && enableGuard) {
            setPageBlocked(true);
        } else {
            setPageBlocked(false);
        }
    }, [blocker.state, enableGuard]);
    

    const onProceed = useCallback(() => {
        setPageBlocked(false);
        setTimeout(blocker.proceed, 10);
    }, [blocker]);

    const onBlock = useCallback(() => {
        console.log("onBlock")
        setPageBlocked(false);
        blocker.reset();
    }, [blocker]);

    return {
        pageBlocked,
        onProceed,
        onBlock
    };
};


export default usePageBlocker;


// const handlePopState = (ev) => {
    //     console.log("EV: ", ev);
    //     ev.preventDefault();
    // }
    
    // useEffect(() => {
    //     console.log('okej dodajemy');
    //     const url = new URL(`${city}/${generalType}/wnioski`, window.location.origin);
    //     url.search = ''; // remove the query string
    //     window.history.pushState({ page: 1 }, "title 1", url.href);
    //     // window.history.pushState({ page: 1 }, "title 1", `${city}/${generalType}/wnioski`);
    // }, []);

    // useEffect(() => {        
        // if (blocker.state === 'blocked') {
            // if(blockRoute === false) {
            //     blocker.proceed();
            //     return setShowBlockModal(false);
            // }
            // setShowBlockModal(true);
        // } else {
            // setShowBlockModal(false);
        // }
    // }, [blocker.state]);