import styled from "styled-components";
import {List} from "./../List/List.styles";

export const InstancesSelector = styled.div`
    margin-bottom: 80px;
`

export const InstancesListing = styled.div`
    margin-top: 50px;
`

export const InstancesListContainer = styled(List)`
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(${({quantity}) => Math.ceil(quantity / 4)}, 1fr);

    @media ${({theme}) => theme.device.tabletOnly} {
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(${({quantity}) => Math.ceil(quantity / 3)}, 1fr);
    }

    @media ${({theme}) => theme.device.mobile} {
        grid-template-columns: 1fr;
        grid-auto-flow: row;
    }
` 