import React from 'react';
import pcss from 'assets/images/pcss_logo.svg';
import Logo from "./Logo";

const PCSSLogo = () => {
    return (
        <Logo src={pcss} alt="pcss-logo" />
    )
};

export default PCSSLogo;