import styled from "styled-components";

const Triangle = styled.div`
  line-height: 1;
  width: .7rem;
  height: .7rem;
  border-top-right-radius: 40%;
  background-color: ${({ theme }) => theme.colors.accent};
  display: inline-block;
  text-align: left;

  transform: rotate(90deg) skewX(-30deg) scale(1, 0.866);
  transform-origin: 30% 60%;

  &:before {
    transform: rotate(-135deg) skewX(-45deg) scale(1.414, 0.707)
      translate(0, -50%);
  }

  &:after {
    transform: rotate(135deg) skewY(-45deg) scale(0.707, 1.414) translate(50%);
  }

  &:before,
  &:after {
    content: "";
    position: absolute;
    background-color: inherit;
    width: 104%;
    height: 104%;
    border-top-right-radius: 40%;
  }
`;

export const RoundedArrow = () => {
  return <Triangle></Triangle>;
};
