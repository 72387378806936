import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import parse from "html-react-parser";
import { UnitGeneralTypes, UnitTypes } from "utils/enums";

const TextBeforeSave = ({ instance, application }) => {
  const [confirmations, setConfirmations] = useState("");
  const polkolonie = instance?.unitGeneralType == UnitGeneralTypes.Semicolony;
  const dyzurWakacyjny = instance?.unitGeneralType == UnitGeneralTypes.HolidayDuty;
  const emailVerification = instance?.settings?.SendVerificationEmail || instance?.settings?.CandidateEmailOrLoginRequired;
  const emailValidation = instance.settings.RequireEmailAsLogin;

  let html = null;

  if (instance?.settings?.TextBeforeApplicationCreated) {
    html = parse(instance.settings.TextBeforeApplicationCreated.value);
  } else if(polkolonie) {
    html  = (<>
      <p>Po naciśnięciu przycisku <strong>Zakończ</strong> kompletne dane zgłoszenia zostaną zapisane w systemie.</p>
      <p><strong>Zgłoszenie należy potem złożyć online w podanym dniu i godzinie rejestracji zgłoszeń.</strong></p>
      <p><strong>Dopiero po rejestracji zgłoszenia kandydat weźmie udział w procesie rekrutacji.</strong></p>
    </>)
  } else if(dyzurWakacyjny) {
    html  = (<>
      <p>Po naciśnięciu przycisku <strong>Zakończ</strong> kompletne dane podania zostaną zapisane w systemie.</p>
      <p>Zostanie następnie wygenerowany dokument w formacie PDF, który należy pobrać z systemu,
        wydrukować, podpisać i złożyć w placówce do której obecnie uczęszcza dziecko.</p>
      <p><strong>Dopiero po złożeniu podania w placówce macierzystej, kandydat weźmie udział w procesie rekrutacji.</strong></p>
    </>)
  } else if (emailValidation || emailVerification) {
    html = (<>
      <p>Po naciśnięciu przycisku <strong>Zakończ</strong> kompletne dane podania zostaną zapisane w systemie.</p>
      <p>Przy pierwszym zapisie kompletnego podania zostanie wysłany e-mail z linkiem weryfikacyjnym.</p>
      <p><strong>Dopiero po potwierdzeniu podania przez kliknięcie linku podanie będzie brać udział w procesie rekrutacyjnym.</strong></p>
    </>)
  } else {
    html = (<>
      <p>Po naciśnięciu przycisku <strong>Zakończ</strong> kompletne dane podania zostaną zapisane w systemie.</p>
      <p>Zostanie następnie wygenerowany dokument w formacie PDF, który należy pobrać z systemu,
          wydrukować, podpisać i złożyć w placówce pierwszego wyboru.</p>
      <p><strong>Dopiero po złożeniu podania w placówce, kandydat weźmie udział w procesie rekrutacji.</strong></p>
    </>)
  }

  function isFlagEnabled(what, flag) {
    if (!what) return false;
    if (!flag) return false;
    return (what & flag) == flag;
  }

  useEffect(() => {
    if (instance && (isFlagEnabled(instance?.settings?.ApplicationStartPage?.value,4)) ) {
        fetch(`${process.env.PUBLIC_URL}/confirmations/${instance.id}/index.html`)
            .then((r) => r.text())
            .then((html) => {
                setConfirmations(html);
            });
    }
}, [instance]);

  return (
      <>
        <TextBeforeSaveWrapper>
          {html}
          {confirmations &&
            <>
            <br></br>
            {parse(confirmations)}
            </>
            }
        </TextBeforeSaveWrapper>
      </>
  );
};

export default TextBeforeSave;

export const TextBeforeSaveWrapper = styled.div`
    font-size: 1.6rem;
    line-height: 1.5;
    color: ${({ theme }) => theme.colors.black};
`;