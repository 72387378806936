import React from 'react';
import * as Styled from 'styles/common.styles';

export const Container = ({ 
    children,
    narrow,
    narrowmore,
    className
}) => {
  return (
    <Styled.Container 
      className={className} 
      narrow={narrow} 
      narrowmore={narrowmore}
    >
        {children}
    </Styled.Container>
  );
};