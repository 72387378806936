import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import parse from "html-react-parser";

import { useInstance } from "hooks/useInstance";
import  useApi  from "hooks/useApi";
import useUnknownCityOrType from "hooks/useUnknownCityOrType";

import Breadcrumbs from "components/commons/Breadcrumbs/Breadcrumbs";
import Banner from "components/commons/Banner/Banner";
import StaticPage from "components/reusable/StaticPage/StaticPage";
import { Page } from "components/commons/Page/Page";

const Contact = () => {
  const { t } = useTranslation();
  const [contact, setContact] = useState("");
  const { id: instanceId } = useInstance([{ name: t("breadcrumbs.contact") }]);
  const { offerApi } = useApi();

  useUnknownCityOrType();

  const getContact = async (id) => {
      const { data, error } = await offerApi.get(`instance/${id}/contact`);

      if(!error)
        setContact(data);
  };

  useEffect(() => {
    if (instanceId) {
      getContact(instanceId);
    }
  }, [instanceId]);

  return (
    <Page>
      <Breadcrumbs />
      <Banner />
      <StaticPage title={t("breadcrumbs.contact")}>{parse(contact)}</StaticPage>
    </Page>
  );
};

export default Contact;
