import { useTranslation } from "react-i18next";

import * as Styled from "pages/Account/elements/TrustedProfile/TrustedProfile.styles";

const PersonalInfo = ({ profile } ) => {
    const { t } = useTranslation();

    return(
        <Styled.PersonalInfoContainer>
            <Styled.Info>
                <p>{t("myData.trustedProfile.name")}</p>
                <p>{profile.given_name}</p>
            </Styled.Info>
            <Styled.Info>
                <p>{t("myData.trustedProfile.surname")}</p>
                <p>{profile.family_name}</p>
            </Styled.Info>
            <Styled.Info>
                <p>{t("myData.trustedProfile.pesel")}</p>
                <p>{profile.name}</p>
            </Styled.Info>
        </Styled.PersonalInfoContainer>
    )
}

export default PersonalInfo;