import { useState, forwardRef, useCallback } from "react";
import Select, { components as SelectComponents } from "react-select";
import { Dropdown as DropdownDiv, Chevron } from "./Dropdown.styles";
import { theme } from "styles/theme";
import { useTranslation } from "react-i18next";

export const dropdownStyles = {
  placeholder: (base) => ({
    ...base,
    fontSize: "1.4rem",
    color: theme.colors.grey60,
    
  }),
  menu: (base, { menuIsOpen }) => ({
    ...base,
    padding: "0.1rem 1.2rem",
    fontSize: "1.4rem",
    marginTop: "0",
    borderRadius: "0 0 0.5rem 0.5rem",
    boxShadow: "none",
    border: `1px solid ${theme.colors.main}`,
    borderTopWidth: menuIsOpen ? 0 : "1px",
  }),
  control: (base, { menuIsOpen }) => {
    return {
      ...base,
      padding: "0.3rem",
      borderColor: menuIsOpen ? theme.colors.main : theme.colors.grey30,
      cursor: "pointer",
      boxShadow: "none",
      borderRadius: menuIsOpen ? "0.5rem 0.5rem 0 0" : "0.5rem",
    };
  },
  option: (base, state) => ({
    ...base,
    cursor: "pointer",
    backgroundColor: state.isFocused ? "#CCD5E7" : base.backgroundColor,
    ":active": {
      ...base[":active"],
      backgroundColor: !state.isDisabled && (state.isSelected ? "#CCD5E7" : "#CCD5E7"),
    },
  }),
  menuList: (base) => ({
    ...base,
    margin: "1rem 0",
  }),
  valueContainer: (base) => ({
    ...base,
    fontSize: "1.4rem",
    // color: theme.colors.main,
  }),
  multiValueLabel: (base) => ({
    ...base,
    backgroundColor: theme.colors.white,
    fontSize: "1.4rem",
  }),
  multiValueRemove: (base) => ({
    ...base,
    backgroundColor: theme.colors.white,
  }),
};

export const Dropdown = forwardRef(
  ({ labelText, components, ...rest }, ref) => {
    const { t } = useTranslation();
    const [isExpanded, setIsExpanded] = useState(false);

    const DropdownIndicator = useCallback((props) => {
      return (
        <SelectComponents.DropdownIndicator {...props}>
          <Chevron isExpanded={isExpanded} />
        </SelectComponents.DropdownIndicator>
      );
    }, [isExpanded]);

    const indicatorSeparator = useCallback(() => null, []);

    return (
      <DropdownDiv>
        {labelText && <label htmlFor={labelText}>{labelText}</label>}
        <Select
          defaultValue={labelText}
          htmlFor={labelText}
          menuPortalTarget={document.body}
          isClearable
          styles={dropdownStyles}
          components={{
            DropdownIndicator,
            IndicatorSeparator: indicatorSeparator,
            ...components,
          }}
          onMenuOpen={() => setIsExpanded(true)}
          noOptionsMessage={() => t("application.noOptionsMessage")}
          onMenuClose={() => setIsExpanded(false)}
          {...rest}
        />
      </DropdownDiv>
    );
  }
);

export default Dropdown;
