import styled, { css } from "styled-components";
import InfoIcon from 'components/commons/icons/InfoIcon';

export const TooltipWrapper = styled.div`
    position: relative;
`;

export const Tooltip = styled.div`
    position: absolute;
    bottom: ${({tooltipToTop}) => tooltipToTop ? '2rem' : 'unset'};
    padding: 1rem;
    background: ${({theme}) => theme.colors.white};
    pointer-events: none;
    font-size: 1.4rem;
    border-radius: 0.4rem;
    z-index: 100;
    width: max-content;
    max-width: ${({maxWidth}) => Math.floor(maxWidth/10)}rem;
    box-shadow: 0px 3px 12px #00000029;
    color: ${({theme}) => theme.colors.black};
    ${({tooltipOnLeft}) => tooltipOnLeft ? css`
        left: unset;
        right: 0;
    `: css`
        left: 2rem;
        right: unset;
    `};
`;

export const StyledInfoIcon = styled(InfoIcon)`
    width: 1.8rem;
    height: 1.8rem;
    margin-left: 0.5rem;
    position: relative;
    top: 2px;
    > .icon {
        fill: ${({theme}) => theme.colors.main};
    }
`
