import React, { useState, useEffect, useContext } from "react";
import { AppContext } from "context/appContext";
import { getInstance } from "utils/utils";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import * as Styled from "pages/ApplicationList/elements/ChildrenOverview/ApplicationItem/ApplicationItem.styles";
import {UnitTypes} from "utils/enums";
import ModalBranch from "components/Unit/ModalUnit/ModalBranch";
import ModalUnit from "components/Unit/ModalUnit/ModalUnit";

const ApplicationPreferencesList = ({
  schoolBranchesList,
  isMobile,
  instanceName,
}) => {
  const { t } = useTranslation();
  const { generalType } = useParams();
  const [unitModalId, setUnitModalId] = useState(null);
  const [branchModalId, setBranchModalId] = useState(null);
  const showBranchName = [UnitTypes.PostSecondarySchool, UnitTypes.TradeSchool,
                                  UnitTypes.SecondarySchool, UnitTypes.Class4, UnitTypes.Class6, UnitTypes.Class7].includes(
      generalType
  );
  const showBranchDescription = [UnitTypes.SecondarySchool, UnitTypes.TradeSchool].includes(
      generalType
  );
  return (
    <>
      {!isMobile && (
        <Styled.ApplicationInfoWrapper>
          {/* MODALS */}
          {branchModalId && (
              <>
                <ModalBranch
                    branchId={branchModalId}
                    onHideModal={() => setBranchModalId(null)}
                />
              </>
          )}
          {unitModalId && (
              <>
                <ModalUnit
                    unitId={unitModalId}
                    onHideModal={() => setUnitModalId(null)}
                />
              </>
          )}
          <Styled.H5 className="application-information-title">
            {t("myApplications.preferences.title")} (
            {schoolBranchesList?.length})
          </Styled.H5>
          <table>
            <thead>
              <tr>
                <th className="school-title">{instanceName}</th>
                <th className="status-title">
                  {t("myApplications.preferences.titleStatus")}
                </th>
                {["zlobek", "przedszkole", "szkolapodstawowa"].includes(
                  generalType
                ) && (
                  <>
                    {generalType === "zlobek" && (
                      <th>{t("myApplications.preferences.titleSince")}</th>
                    )}
                    {(generalType === "przedszkole" ||
                      generalType === "szkolapodstawowa") && (
                      <>
                        <th>{t("myApplications.preferences.titleScore")}</th>
                        <th>{t("myApplications.preferences.titleScore2")}</th>
                      </>
                    )}
                  </>
                )}
              </tr>
            </thead>
            <tbody>
              {schoolBranchesList.map((schoolBranch, index) => (
                <tr key={schoolBranch.id_SchoolBranch}>
                  <td className="school-name">
                    <span>{index + 1}</span>. <span style={{cursor: 'grab',color: 'rgb(0, 49, 137)'}} onClick={() => {
                    if (showBranchDescription) {
                      setBranchModalId(schoolBranch.id_SchoolBranch);
                    }  else {
                      setUnitModalId(schoolBranch.unitId);
                    }
                  }}>{
                    showBranchName? schoolBranch.unitName + ' / '+schoolBranch.name : schoolBranch.unitName
                  }</span>
                  </td>
                  <td className="school-status">{schoolBranch.status}</td>
                  {["zlobek", "przedszkole", "szkolapodstawowa"].includes(
                    generalType
                  ) && (
                    <>
                      {generalType === "zlobek" && (
                        <td className="school-date">
                          {schoolBranch.verificationDate}
                        </td>
                      )}
                      <td className="school-points">
                        {schoolBranch.totalPoints}
                      </td>
                      <td className="school-points">
                        {schoolBranch.pointOverMin}
                      </td>
                    </>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </Styled.ApplicationInfoWrapper>
      )}
      {isMobile && (
        <ApplicationPreferencesListMobile
          schoolBranchesList={schoolBranchesList}
        />
      )}
    </>
  );
};

export default ApplicationPreferencesList;

const ApplicationPreferencesListMobile = ({ schoolBranchesList }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const { instances } = useContext(AppContext);
  const { city, generalType } = useParams();
  const { t } = useTranslation();
  const [instance, setInstance] = useState(null);
  const [unitModalId, setUnitModalId] = useState(null);
  const [branchModalId, setBranchModalId] = useState(null);
  const showBranchName = [UnitTypes.PostSecondarySchool, UnitTypes.TradeSchool,
    UnitTypes.SecondarySchool, UnitTypes.Class4, UnitTypes.Class6, UnitTypes.Class7].includes(
      generalType
  );
  const showBranchDescription = [UnitTypes.SecondarySchool, UnitTypes.TradeSchool].includes(
      generalType
  );

  useEffect(() => {
    if (instances?.length > 0) {
      if (instances != null && instances.length > 0) {
        setInstance(getInstance(instances, city, generalType)[0]);
      }
    }
  }, [instances, city]);

  const item = {
    title: instance
      ? instance.instanceUrl
        ? `${instance.instanceName}`
        : `${t(`unitTypesForPreferences.${instance.typeUrl}`)}`
      : "x",
  };

  const toggleList = () => {
    setIsExpanded((prev) => !prev);
  };

  return (
    <>
      {schoolBranchesList?.length > 0 && (
        <Styled.ApplicationInfoWrapperMobile className="preferences-list">
          {/* MODALS */}
          {branchModalId && (
              <>
                <ModalBranch
                    branchId={branchModalId}
                    onHideModal={() => setBranchModalId(null)}
                />
              </>
          )}
          {unitModalId && (
              <>
                <ModalUnit
                    unitId={unitModalId}
                    onHideModal={() => setUnitModalId(null)}
                />
              </>
          )}
          <Styled.ApplicationPrefTitleContainerMobile>
            <Styled.H5 className="application-title-mobile">
              {t("myApplications.preferencesMobile.title")} {item.title} (
              {schoolBranchesList?.length})
            </Styled.H5>
            <span
              onClick={toggleList}
              className={`arrow-icon ${isExpanded ? "expanded" : ""}`}
            ></span>
          </Styled.ApplicationPrefTitleContainerMobile>
          <div
            className={`preferences-list-content ${
              isExpanded ? "expanded" : ""
            }`}
          >
            {schoolBranchesList.map((schoolBranch, index) => (
              <div key={schoolBranch.id_SchoolBranch} className="map-preferences">
                <Styled.ApplicationSubtitleContainerMobile className="application-unit-name-mobile">
                  <Styled.H5 className="application-title-mobile">
                    {index + 1} - {""}
                    <span style={{cursor: 'grab',color: 'rgb(0, 49, 137)'}} onClick={() => {
                      if (showBranchDescription) {
                        setBranchModalId(schoolBranch.id_SchoolBranch);
                      }  else {
                        setUnitModalId(schoolBranch.unitId);
                      }
                    }}>
                      {showBranchName? schoolBranch.unitName + ' / '+schoolBranch.name : schoolBranch.unitName}
                    </span>
                    </Styled.H5>
                </Styled.ApplicationSubtitleContainerMobile>
                <Styled.ApplicationSubtitleContainerMobile>
                  <Styled.H5 className="application-title-mobile">
                    {t("myApplications.preferences.titleStatus")}
                  </Styled.H5>
                  <Styled.ApplicationInfoParagraphMobile>
                    {schoolBranch.status}
                  </Styled.ApplicationInfoParagraphMobile>
                </Styled.ApplicationSubtitleContainerMobile>
                <Styled.ApplicationSubtitleContainerMobile>
                  {generalType === "zlobek" && (
                    <>
                      <Styled.H5 className="application-title-mobile">
                        {t("myApplications.preferences.titleSince")}
                      </Styled.H5>
                      <Styled.ApplicationInfoParagraphMobile>
                        {schoolBranch.verificationDate}
                      </Styled.ApplicationInfoParagraphMobile>
                    </>
                  )}
                  {(generalType === "przedszkole" ||
                    generalType === "szkolapodstawowa") && (
                    <>
                      <Styled.H5 className="application-title-mobile">
                        {t("myApplications.preferences.titleScore")}
                      </Styled.H5>
                      <Styled.ApplicationInfoParagraphMobile>
                        {schoolBranch.totalPoints}
                      </Styled.ApplicationInfoParagraphMobile>
                    </>
                  )}
                  {(generalType === "przedszkole" ||
                    generalType === "szkolapodstawowa") && (
                    <>
                      <Styled.H5 className="application-title-mobile">
                        {t("myApplications.preferences.titleScore2")}
                      </Styled.H5>
                      <Styled.ApplicationInfoParagraphMobile>
                        {schoolBranch.pointOverMin}
                      </Styled.ApplicationInfoParagraphMobile>
                    </>
                  )}
                </Styled.ApplicationSubtitleContainerMobile>
              </div>
            ))}
          </div>
        </Styled.ApplicationInfoWrapperMobile>
      )}
    </>
  );
};
