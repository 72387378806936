import { useParams } from "react-router-dom";
import useUnknownCityOrType from "hooks/useUnknownCityOrType";

import { Banner } from "components/commons";

const ChooseApplicationType = () => {
    useUnknownCityOrType();
  return (
    <div>
      <Banner />
      <h1>Wybierz rodzaj podania</h1>
    </div>
  );
};

export default ChooseApplicationType;
