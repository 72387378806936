import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import parse from "html-react-parser";

import { Button } from 'components/commons/Button/Button';
import useApi from "hooks/useApi";

import closeIcon from 'assets/icons/ico-close-2.svg';

import { H4 } from "styles/common.styles";
import { AppContext } from "context/appContext";
import { createDictFromArray, decodeEscapedHtml, getInstance } from "utils/utils";
import { additionalFeatureIcons, sectionConfig } from "pages/Unit/Unit.config";
import { UnitGeneralTypes } from "utils/enums";
import { languages as languagesConst } from "utils/constants";
import { AdditionalFeatures } from "pages/Unit/AdditionalFeatures";
import { UnitHtmlSection } from "pages/Unit/UnitHtmlSection";
import { showProperty, showSection, usedIds } from "utils/unitHelpers";
import * as Styled from "./ModalUnit.styles";

const ModalUnit = ({ unitId, onHideModal }) => {
    const { t } = useTranslation();
    const { city, generalType } = useParams();
    const { instances: instancesList } = useContext(AppContext);
    const [instance, setInstance] = useState(null);
    const [unit, setUnit] = useState(null);
    const { offerApi } = useApi();

    useEffect(() => {
        let isSubscribed = true;

        const fetchUnit = async (id, inst) => {
            const { data, error } = await offerApi.get(`unit/${id}?id_instance=${inst.id}`);

            if (isSubscribed) {
                setUnit(data);
            }
        };

        if (city && instancesList?.length > 0) {
            const instances = getInstance(instancesList, city, generalType);

            if (instances != null && instances.length > 0) {
                const inst = instances[0];
                setInstance(inst);
                fetchUnit(unitId, inst);
            }
        }
        return () => (isSubscribed = false);
    }, [instancesList, city, generalType, unitId]);


    const showDetailsInNewWindow = () => {
        window.open(`/${instance.regionUrl}/${instance.typeUrl}/info/${unit.id}`);
    };

    const onBackgroundClick = (ev) => {
        if (ev.target.getAttribute('aria-labelledby') === 'ModalUnitTitle'){
            onHideModal();
        }
        ev.stopPropagation()
    }

    return (
        <Styled.ModalUnitWrapper aria-hidden="true">
            {unit && (
                <Styled.ModalUnit
                    id="ModalUnit"
                    role="dialog"
                    aria-labelledby="ModalUnitTitle"
                    onClick={onBackgroundClick}
                >
                    <Styled.ModalContainer>
                        <Styled.ModalUnitHeader>
                            <H4 id="ModalUnitTitle">{`${unit?.name}`}</H4>
                            <Styled.CloseButton type="button" onClick={onHideModal}>
                                <img className='image-wrapper' src={closeIcon} alt={t("units:locationModal.closeIconAlt")} />
                            </Styled.CloseButton>
                        </Styled.ModalUnitHeader>

                        <ModalUnitContent
                            unit={unit}
                            instance={instance}
                            city={city}
                            generalType={generalType}
                        />

                        <Styled.ModalUnitsFooter>
                            <Button
                                type='button' 
                                onClick={showDetailsInNewWindow}
                            >
                                {t("units:descriptionModal.openNewTabBtn")}
                            </Button>
                        </Styled.ModalUnitsFooter>
                    </Styled.ModalContainer>
                </Styled.ModalUnit>
            )}
            <Styled.BodyBlockScroll />
        </Styled.ModalUnitWrapper>
    );
}

export default ModalUnit;


const ModalUnitContent = ({ unit, instance, city, generalType }) => {
    const { t } = useTranslation();

    const languages = createDictFromArray(
        languagesConst,
        "id",
        "label"
    );

    const boolFeatures = unit?.boolFeatures?.filter((e) => e.value).map((el) => el.id);

    const displaySpecificSections = (name) => {
        switch (name) {
            case "additionalFeatures":
                return (
                    <>
                        {boolFeatures && (
                            <AdditionalFeatures visibleFeatures={boolFeatures} />
                        )}
                    </>
                );
            case "additionalInformationSection":
                return <>{displayAdditionalInformation()}</>;
            case "branches":
                return (
                    <>
                        {(instance.unitGeneralType === UnitGeneralTypes.SecondarySchool ||
                            instance.unitGeneralType === UnitGeneralTypes.TradeSchool) &&
                            displaySchoolBranches()}
                    </>
                );
            case "languages":
                return <>{unit.languages.map((lang) => languages[lang]).join(", ")}</>;
            case "terms":
                return (
                    <>
                        {unit.schoolbranches.map((branch) => (
                            <Styled.TermsListItem>
                                {branch.normalizedName}{" "}
                                {instance?.settings.hasOwnProperty("ShowFreePlaces") && (
                                    <span>
                                        {t("units:branches.features.freePlaces")}:{" "}
                                        {branch.freePlaces}
                                    </span>
                                )}
                            </Styled.TermsListItem>
                        ))}
                    </>
                );
            default:
                return <></>;
        }
    };

    const displaySchoolBranches = () => {
        if (unit?.schoolbranches?.length > 0) {
            return (
                <>
                    {unit.schoolbranches.map((branch) => {
                        console.log('branch: ', branch);
                        return (
                            <Styled.BranchListItemLink
                                key={`branch-${branch.id}`}
                                to={`/${city}/${generalType}/info/Oddzial/${branch.id}`}
                                state={ {unitId: unit.id, unitName: unit.name, branchName: branch.name} }
                            >
                                {branch.name}
                            </Styled.BranchListItemLink>
                        )
                    })}
                </>
            );
        }
        return null;
    };

    const displayAdditionalInformation = () => {
        const featuresIds = Object.values(additionalFeatureIcons)
            .filter((el) => el.id !== null)
            .map((el) => el.id);

        const displayedIds = unit.features?.filter(
            (el) =>
                !usedIds.includes(el.id) &&
                !featuresIds.includes(el.id) &&
                el.valueType !== 6
        );

        const sixYOFeature = unit.features?.find((el) => el.id === 33);

        if (displayedIds?.length > 0) {
            return (
                <>
                    {displayedIds.map((feature) => {
                        return (

                            <Styled.SectionListItem
                                key={feature.id}
                            >
                                <strong>{feature.label}:</strong>
                                <Styled.SectionListItemDescription>
                                    {typeof feature.value === "boolean"
                                        ? t(`units:sections.values.${!!feature.value ? "yes" : "no"}`)
                                        : feature.value}
                                </Styled.SectionListItemDescription>
                            </Styled.SectionListItem>

                        )
                    })}
                    {sixYOFeature && (
                        <Styled.SectionListItem>
                            <strong>{sixYOFeature.label}:</strong>
                            <Styled.SectionListItemDescription>
                                {parse(decodeEscapedHtml(sixYOFeature.value))}
                            </Styled.SectionListItemDescription>
                        </Styled.SectionListItem>
                    )}
                </>
            );
        }

        return null;
    };


    return (
        <Styled.ModalUnitContainer>
            {sectionConfig.right.map(
                (section) =>
                    showSection({ section, unit, instance, usedIds }) && (
                        <Styled.Section key={section?.name || section?.i18n_key} className='unit-section'>
                            <Styled.SectionHeading>
                                {section.hasOwnProperty("i18n_key")
                                    ? t(`units:sections.${section.i18n_key}`)
                                    : section.getLabel(unit)}
                            </Styled.SectionHeading>
                            {section?.features?.length > 0 ? (
                                <>
                                    {section.features.map(
                                        (feature) =>
                                            showProperty({ feature, unit, instance }) && (
                                                <Styled.SectionListItem
                                                    key={feature?.name || feature?.i18n_key}
                                                >
                                                    <strong>
                                                        {feature.hasOwnProperty("i18n_key")
                                                            ? t(
                                                                `units:sections.features.${feature.i18n_key}`
                                                            )
                                                            : feature.getLabel(unit)}
                                                        :
                                                    </strong>
                                                    <Styled.SectionListItemDescription>
                                                        {feature.getData(unit, {
                                                            url: `${city}/${generalType}`,
                                                        })}
                                                    </Styled.SectionListItemDescription>
                                                </Styled.SectionListItem>
                                            )
                                    )}
                                </>
                            ) : (
                                showSection({ section, unit, instance, usedIds }) && (
                                    <>
                                        {section.hasOwnProperty("display") ? (
                                            displaySpecificSections(section.display)
                                        ) : (
                                            <UnitHtmlSection>
                                                {section.getData(unit)}
                                            </UnitHtmlSection>
                                        )}
                                    </>
                                )
                            )}
                        </Styled.Section>
                    )
            )}
        </Styled.ModalUnitContainer>
    )
}
