import React, { useEffect, useState, useMemo } from 'react';
import { useLocation } from "react-router-dom";
import * as Styled from './FormStepSelector.styles';

import useIsMobile from 'hooks/useIsMobile';

import { podajBiernik, podajMianownik } from 'utils/utils';

const maxInLine = 7;
const maxInBaseLineWhenMoreThanMaxInLine = 6;

const FormStepSelector = ({
    steps,
    currentStep,
    onBallClick,
    // availableStep,
    errors = [],
    formReadOnly,

    instanceDisplayName,
    savedAppId,
}) => {
    const {pathname} = useLocation();
    const [upLineSteps, setUpLineSteps] = useState([]);
    const [bottomLineSteps, setBottomLineSteps] = useState();

    const [linePercentage, setLinePercentage] = useState(0);
    const [lowerLinePercentage, setLowerLinePercentage] = useState(0);

    const isMobile = useIsMobile('colMedium'); // mobile version always has only one vertical line

    const appType = useMemo(() => {
        const urlType = pathname.split("/").pop();
//        if (urlType === "deklaracja") {
//            return "deklarację";
//        } else if (urlType === "zgloszenie") {
//            return "zgłoszenie";
//        }
        return urlType;
    }, [pathname]);

    useEffect(() => {
        if (steps.length <= maxInLine) {
            return setUpLineSteps(steps);
        }
        setUpLineSteps(steps.slice(0, maxInBaseLineWhenMoreThanMaxInLine));
        setBottomLineSteps(steps.slice(maxInBaseLineWhenMoreThanMaxInLine));
    }, [steps]);


    useEffect(() => {
        if (steps.length <= maxInLine) {
            return setLinePercentage((currentStep / (upLineSteps.length - 1)) * 100);
        }
        setLinePercentage(Math.min(100, ((currentStep / (upLineSteps.length - 1)) * 100)));
        setLowerLinePercentage( currentStep > maxInBaseLineWhenMoreThanMaxInLine ? (currentStep%maxInBaseLineWhenMoreThanMaxInLine / (bottomLineSteps?.length - 1)) * 100 : 0 );
    }, [upLineSteps, bottomLineSteps, steps, currentStep]);

    const formName = useMemo(() => {
        if (formReadOnly) {
            return `Podgląd ${podajBiernik(appType)} nr: ${savedAppId}`;
        }
        if (savedAppId){
            return `Edycja ${podajBiernik(appType)} nr: ${savedAppId}`;
        }
        const localAppType = podajMianownik(appType).replace(/^./, match => match.toUpperCase());
        return `${localAppType} - ${instanceDisplayName}`
    }, []);

    return (
        <Styled.FormStepSelector>
            <Styled.FormStepSelectorContainer className="wizard-container">
                {/* <Styled.FormStepSelectorHeader>{formReadOnly ? "Podgląd wniosku nr: " : "Wniosek: "} {appType}</Styled.FormStepSelectorHeader> */}
                <Styled.FormStepSelectorHeader>{formName}</Styled.FormStepSelectorHeader>
                <Styled.FormStepSelectorButtonsWrapper className="wizard-bt-wrapper">
                    {isMobile ? (
                        /// ON MOBILE - VERTICAL
                        <Styled.CirclesWrapper>
                            <Styled.FormStepSelectorLines
                                className='lines'
                                percent={linePercentage}
                            >
                                <div className='line-active' />
                                <div />
                            </Styled.FormStepSelectorLines>
                            <Styled.FormStepSelectorButtons className='step-bullets'>
                                {steps.map((step, idx) => (
                                    <StepBt
                                        description={step}
                                        key={`step-${step}-${idx}`}
                                        stepNo={idx}
                                        onClick={onBallClick}
                                        active={currentStep === idx}
                                        // disabled={isTest ? false :idx > availableStep}
                                        error={errors[idx] === true}
                                    />
                                ))}
                            </Styled.FormStepSelectorButtons>
                        </Styled.CirclesWrapper>
                    ) : (
                        /// ON DESKTOP - HORIZONTAL - two lines if needed
                        <Styled.HorizontalCirclesWrapper>
                            <Styled.CirclesWrapper elementsCount={upLineSteps.length}>
                                <Styled.FormStepSelectorLines
                                    className='lines'
                                    percent={linePercentage}
                                >
                                    <div className='line-active' />
                                    <div />
                                </Styled.FormStepSelectorLines>
                                <Styled.FormStepSelectorButtons className='step-bullets'>
                                    {upLineSteps.map((step, idx) => (
                                        <StepBt
                                            description={step}
                                            key={`step-${step}-${idx}`}
                                            stepNo={idx}
                                            onClick={onBallClick}
                                            active={currentStep === idx}
                                            // disabled={isTest ? false : idx > availableStep}
                                            error={errors[idx] === true}
                                        />
                                    ))}
                                </Styled.FormStepSelectorButtons>
                            </Styled.CirclesWrapper>

                            { bottomLineSteps && (
                                <Styled.CirclesWrapper elementsCount={bottomLineSteps.length}>
                                    <Styled.FormStepSelectorLines
                                        className='lines'
                                        percent={lowerLinePercentage}
                                    >
                                        <div className='line-active' />
                                        <div />
                                    </Styled.FormStepSelectorLines>
                                    <Styled.FormStepSelectorButtons className='step-bullets-bottom'>
                                        {bottomLineSteps.map((step, idx) => (
                                            <StepBt
                                                description={step}
                                                key={`step-${step}-${idx+6}`}
                                                stepNo={idx+6}
                                                onClick={onBallClick}
                                                active={currentStep === idx+6}
                                                // disabled={isTest ? false : idx+6 > availableStep}
                                                error={errors[idx+6] === true}
                                            />
                                        ))}
                                    </Styled.FormStepSelectorButtons>
                                </Styled.CirclesWrapper>
                            )}
                        </Styled.HorizontalCirclesWrapper>
                    )}
                </Styled.FormStepSelectorButtonsWrapper>
            </Styled.FormStepSelectorContainer>
        </Styled.FormStepSelector>
    );
};
export default FormStepSelector;

// const NextPrevButton = ({ isPrevious, onClick, disabled }) => {
//     return (
//         <Styled.NextButton
//             onClick={onClick}
//             disabled={disabled}
//             isPrevious={isPrevious}
//         >
//             <ArrowIcon />
//         </Styled.NextButton>
//     );
// }

const StepBt = ({ onClick, stepNo, description, active, disabled, error }) => {
    return (
        <Styled.StepSelectorBt
            active={active}
            disabled={disabled}
            onClick={() => onClick ? onClick(stepNo) : {}}
            error={error}
        >
            <div className='step-text'>{stepNo + 1}</div>
            <div className='step-bg-active' />
            <div className='step-bg' />
            <div className='step-description'>
                {description}
            </div>
        </Styled.StepSelectorBt>
    );
};
