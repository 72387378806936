import React, { memo, useEffect, useState } from "react";
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import TextFieldForm from "./TextFieldForm";
import { useFormContext, Controller } from "react-hook-form";

const IncomeInput = ({ name, isRequired, defaultValue, ...rest }) => {
    const { t } = useTranslation();
    const { control, trigger } = useFormContext();

    const [stringValIsEmpty, setStringValIsEmpty] = useState(false);

    const validateIncomeField = async (value) => {
        if (isRequired && stringValIsEmpty) {
            return t('application.errors.fieldRequired');
        }
        if (rest?.maxValue && value > rest?.maxValue) {
            return `${t('application.errors.maxIncomeValue')} ${parseToCurrencyFormat(rest?.maxValue)} )`;
        }

        if (rest?.minValue && value < rest?.minValue) {
            return `${t('application.errors.minIncomeValue')} ${parseToCurrencyFormat(rest?.minValue)} )`;
        }
        return true;
    }

    return (
        <StyledIncomeInput>
            <Controller
                name={name}
                control={control}
                defaultValue={0}
                rules={{
                    validate: validateIncomeField
                }}
                render={({ field, fieldState }) => {
                    return (
                        <IncomeTextField
                            field={field}
                            fieldState={fieldState}
                            isRequired={isRequired}
                            trigger={trigger}
                            setStringValIsEmpty={setStringValIsEmpty}
                            {...rest}
                        />
                    )
                }}
            />
        </StyledIncomeInput>
    );
};

export default memo(IncomeInput);

const IncomeTextField = ({
    field, 
    fieldState, 
    isRequired, 
    trigger, 
    setStringValIsEmpty,
    ...rest
}) => {

    const normalize = (value) => {
        if (value || value === 0) {
            return value?.toString().replace(".", ",") || "";
        }
        return "";
    };

    const [stringVal, setStringVal] = useState(normalize(field.value));

    useEffect(() => {
        // check if there is initial value taken from "defaultValues" of form, if so - trigger validation to check if it is correct;
        if (field.value !== 0) {
            trigger(field.name);
        }
    }, []);

    useEffect(() => {
        setStringValIsEmpty( stringVal.length < 1);
    }, [stringVal]);

    const handleInputChange = (e, fieldOnChange) => {
        let val = e.target.value;
        
        val = val
            .replace(/[^0-9,.]/g, "") // only numbers, coma and dot 
            .replace(/,/g, '.') // replaca comas with dots
            .replace(/(?<=\.[^.]*)(\.)/g, ''); // only one dot allowed

        const match = val.match(/(?:\.)\d{3,}/);
        if (match) {
            val = val.substring(0, match.index + 3);
        }
        setStringVal(val.replace('.', ','));

        fieldOnChange( isNaN(parseFloat(val)) ? 0 : parseFloat(val) );
    };

    return (
        <TextFieldForm
            placeholder="0,00"
            {...rest}
            {...field}
            onChange={ev => handleInputChange(ev, field.onChange)}
            onBlur={ev => {                
                setStringVal(parseToCurrencyFormat(stringVal));
                trigger(field.name);
            }}
            name={null}
            value={stringVal}
            isRequired={isRequired}
            error={fieldState.error}
            maxLength={10}
        />
    )
}

const parseToCurrencyFormat = (stringNumber) => {
    const replaced = stringNumber.toString().replace(',', '.');
    if (!isNaN(parseFloat(replaced))) {
        return (parseFloat(replaced).toFixed(2).replace('.', ','));
    }
    return stringNumber;
}


const StyledIncomeInput = styled.div`
    & .input-wrapper {
        max-width: 10rem;
    }
    & .text-input {
        text-align: right;
    }
`;