import { useState, useCallback, useEffect, useMemo } from 'react';

// SPECIFIC module validation
const useFormValidation = (data, currentStep, rhfMethods) => {
    const { trigger, getFieldState, getValues } = rhfMethods;
    const [fields, setFields] = useState([]);

    const moduleElements = useMemo(() => {
        if (!data || !data[currentStep] || !data[currentStep]?.sections) {
            return null;
        }
        return data[currentStep]?.sections;
    }, [data, currentStep]);

    useEffect(() => {
        setFields(state => [ ...findModuleFieldsNames(moduleElements).filter(item => !state.includes(item))]);
    }, [moduleElements]);

    const findModuleFieldsNames = useCallback((element) => {
        if (Array.isArray(element)) {
            return element.flatMap(item => findModuleFieldsNames(item));
        } else if (typeof element === "object" && element !== null) {
            return Object.entries(element).flatMap(([key, value]) => {
                if (element.hasOwnProperty("component")) {
                    if (key === "name" && !!element.name) {
                        return element.name;
                    }
                    if (key === "uid" && element.required === true) {
                        return `Answers.${element.uid}`;
                    }
                    return findModuleFieldsNames(value);
                } else {
                    return findModuleFieldsNames(value);
                }
            });
        }
        return [];
    }, []);

    const onValidation = useCallback(async () => {
        await trigger(fields);
        let errors = [];
        fields.forEach( field => {
            if (getFieldState(field).invalid) {
                console.log("ERROR fields: ", field, ' , value: ', getValues(field));
                errors = [...errors, {
                    field,
                    value: getValues(field),
                }];
            }
        });
        if (errors?.length > 0) {
            // scroll first error into view
            const firstElementWithClassErrorText = document.querySelector('.error-text');
            if (firstElementWithClassErrorText){
                firstElementWithClassErrorText?.scrollIntoView({block: 'center'});
            }
        }
        if (errors?.length === 0) {
            return true;
        }
        console.log("ERRRORS: ", errors);
        return errors;
    }, [fields, getValues, getFieldState, trigger]);

    const addFieldToValidation = useCallback((field) => {
        setTimeout(() => 
            setFields(state => state.includes(field) ? state : [...state, field])
        , 1);
    }, []);

    const removeFieldFromValidation = useCallback((field) => {
        setTimeout(() => setFields(fields => {
            if (fields.includes(field)) {
                return fields.filter(item => item !== field);
            }
            return fields;
        }), 1);
    }, []);

    return {
        onValidation,
        addFieldToValidation,
        removeFieldFromValidation,
    };
}
export default useFormValidation;