import React, { useMemo, useEffect, useState, memo, useCallback } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import ReactSelect, { createFilter, components } from 'react-select';
import styled, { css } from 'styled-components';

import Error from './elements/Error';
import Label from './elements/Label';
import Description from './elements/Description';

import { RadioOptions } from './RadioForm';

import { dropdownStyles } from 'components/commons/Dropdown/Dropdown';

const SelectFormContextWrapper = (props) => {
    const { t } = useTranslation();
    const { options, name, isRequired } = props;

    const { control, trigger, setValue, getValues } = useFormContext();

    const selectOptions = useMemo(() => {
        if (options === null || options === undefined) return null;
        if (Array.isArray(options)) return options;
        if (typeof options === 'object') {
            return Object.entries(options).map(([label, value]) => ({
                value,
                label
            }));
        }
    }, [options]);

    useEffect(() => {
        if (!name || !selectOptions || selectOptions?.length < 1) return;
        let curVals = getValues(name);
        if (typeof curVals === 'object' ) {
            curVals = curVals.value;
        }
        // sometimes relevant value is double nested
        if (typeof curVals === 'object' ) {
            curVals = curVals.value;
        }
        if (curVals && typeof curVals !== 'object' && curVals?.toString()) {
            const foundOption = selectOptions.find( ({value}) => value.toString() === curVals.toString());
            setValue(name, foundOption);
        }
    }, [selectOptions]);

    const validateField = useCallback(async (value) => {
        if (isRequired && value === undefined) {
            return t('application.errors.selectRequired');
        }
        return true;
    }, []);

    const memoizedSelect = useMemo(() => {
        return (
            <Controller
                name={name || ''}
                control={control || null}
                rules={{ validate: validateField }}
                render={({ field: { ref, ...controllerField }, fieldState: { error } }) => {
                    return (
                        <SelectComponent
                            {...controllerField}
                            {...props}
                            error={error}
                            trigger={trigger}
                            defaultPlaceholder={t('common.select')}
                        />
                    )
                }}
            />
        );
    }, [control, props, trigger, validateField]);

    return (
        <>
            {memoizedSelect}
        </>
    )
};
export default SelectFormContextWrapper;

export const SelectComponent = memo((props) => {
    const {
        name,
        options,
        value,
        label,
        description,
        placeholder,
        isRequired,
        onChange,
        additionalOnChange,

        trigger,

        error,

        ...restProps
    } = props;

    const { t } = useTranslation();

    const selectOptions = useMemo(() => {
        if (options === null || options === undefined) return null;
        if (Array.isArray(options)) return options;
        if (typeof options === 'object') {
            return Object.entries(options).map(([label, value]) => ({
                value,
                label
            }));
        }
    }, [options]);

    const optionsMaxLength = useMemo(() => {
        if (!selectOptions) return 0;
        return selectOptions?.reduce((acc, option) => {
            return option?.label?.length > acc ? option?.label?.length : acc;
        }, 0);
    }, [selectOptions]);

    const memoizedLabel = useMemo(() => label && (
        <Label
            label={label}
            isRequired={isRequired}
            htmlFor={name}
            className='select-form-label'
        />
    ), [label, isRequired]);

    const memoizedHelper = useMemo(() => {
        return (
            <Description>{description}</Description>
        )
    }, [description]);

    const onMenuClose = useCallback(() => {
        if (name && trigger) trigger(name);
    }, [name, trigger]);

    const onMenuChange = useCallback((ev) => {
        onChange && onChange(ev);
        additionalOnChange && additionalOnChange(ev, name);
        if (trigger && name) trigger(name);
    }, [additionalOnChange, name, onChange, trigger]);

    const noOptionsMessageFunc = useCallback(() => t('application.noOptionsMessage') || '', []);

    return (
        <Wrapper
            className='select-form-wrapper'
        // isVisible={isVisible}
        >
            {memoizedLabel}

            {optionsMaxLength < 100 ? (
                <StyledDropdownWrapper
                    // {...controllerField}
                    {...restProps}
                    options={selectOptions}
                    onChange={onMenuChange}
                    placeholder={placeholder || `${t('common.select')}...`}
                    value={value ?
                        selectOptions.find(option => option.value === value.value) :
                        value}
                    /// above will cause apriopriate scrollIntoView on open
                    className='form-select'
                    noOptionsMessage={noOptionsMessageFunc}
                    styles={dropdownStyles}
                    onMenuClose={onMenuClose}
                    isShort={optionsMaxLength < 30}
                />
            ) : (
                <RadioOptions
                    options={selectOptions}
                    field={props}
                    disabled={restProps.disabled}
                    displayColumn={true}
                />
            )}

            <Error error={error} />
            {memoizedHelper}
            {/* ) : (
                <p style={{ color: 'red', fontWeight: '600' }}>Brak opcji dla {name}</p>
            )} */}

        </Wrapper>
    )
});
SelectComponent.displayName = "SelectComponent";

const CustomOption = props => {
    const { innerProps, isFocused, ...otherProps } = props;
    const { onMouseMove, onMouseOver, ...otherInnerProps } = innerProps;
    const newProps = { innerProps: { ...otherInnerProps }, ...otherProps };
    return (
        <components.Option {...newProps}>
            {props.children}
        </components.Option>
    )
}

const StyledDropdownWrapper = ({ options, value, disabled, ...props }) => {
    return (
        <StyledSelect
            className='form-select'
            options={options}
            value={value}
            {...props}
            isDisabled={disabled}
            filterOption={createFilter({ ignoreAccents: false })}
            components={{ Option: CustomOption }}

            classNames={{
                control: (state) => (state.isFocused ? 'focused' : '')
            }}
        />
    )
};

StyledDropdownWrapper.displayName = "StyledDropdownWrapper";

const Wrapper = styled.div`
    position: relative;
    display: ${({ isVisible }) => isVisible === false ? 'none' : 'flex'};
    flex-direction: column;
    font-size: 1.4rem;
    font-weight: 400;
    color: ${({ theme }) => theme.colors.black};
`;


const StyledSelect = styled(ReactSelect)(({ theme: { colors } }) => css`
    min-height: 4.5rem;
    display: flex;
    max-width: ${({ isShort }) => isShort ? '30rem' : 'unset'};

    & [class*="indicatorContainer"] {
        svg {
            color: ${({ isDisabled }) => isDisabled ? colors.primaryDisabledGrey : colors.accent};
        }
    }
    & [class*="-control"] {
        min-height: 100%;
        width: 100%;
        border-color: ${colors.unitAddressColor};
        &[aria-disabled="true"] {
            background-color: ${colors.disabledControlBackground};
            color: ${colors.black};

            & [class*="-singleValue"] {
                color: ${colors.black};
            }
        }
        &:hover {
            box-shadow: 0px 0px 10px #00000029;
        }

        &.focused {
            border: 2px solid ${colors.accent};
        }
    }
    & [class*="-option"] {
        cursor: pointer;
        &[aria-selected="true"] {
            background-color: ${colors.backgroundPrime};
            color: ${colors.black};
        }
        &:hover {
            &:not([aria-selected="true"]) {
                background-color: ${colors.selectItemBackground};
            }
            &[aria-selected="true"] {
                background-color: ${colors.main};
                color: white;
            }
        }
    }
    
    & [class*="-menu"] {
        z-index: 200;
    }
`);

StyledSelect.displayName = "MemoizedStyledSelect";