import parse from "html-react-parser";
import { DictUnitTypeExt } from "utils/enums";
import { decodeEscapedHtml, linkify } from "utils/utils";
import {Link} from "react-router-dom";

const extractFeature = (unit, id, label) => {
  const feature = unit?.features.find((el) => el.id === id);
  if (!feature) return "";
  let result = feature[label];
  if (label === "value") {
    const valueType = feature["valueType"];
    if (valueType === 6) {
      result = parse(decodeEscapedHtml(result));
    }
  }
  return result;
};

export const sectionConfig = {
  left: [
    {
      i18n_key: "languages",
      display: "languages",
    },
    {
      name: "extracurricuralActivities",
      feature_ids: [34],
      getLabel: function (unit) {
        return extractFeature(unit, this.feature_ids[0], "label");
      },
      getData: function (unit) {
        return extractFeature(unit, this.feature_ids[0], "value");
      },
    },
    {
      name: "workingHours",
      feature_ids: [40],
      getLabel: function (unit) {
        return extractFeature(unit, this.feature_ids[0], "label");
      },
      getData: function (unit) {
        return extractFeature(unit, this.feature_ids[0], "value");
      },
    },
    {
      name: "freePlaces",
      feature_ids: [48],
      getLabel: function (unit) {
        return extractFeature(unit, this.feature_ids[0], "label");
      },
      getData: function (unit) {
        return extractFeature(unit, this.feature_ids[0], "value");
      },
    },
    {
      i18n_key: "description",
      unit_condition: ["description"],
      getData: (unit) => parse(decodeEscapedHtml(unit.description)),
    },
    {
      name: "history",
      feature_ids: [2],
      getLabel: function (unit) {
        return extractFeature(unit, this.feature_ids[0], "label");
      },
      getData: function (unit) {
        return extractFeature(unit, this.feature_ids[0], "value");
      },
    },
    {
      name: "achievments",
      feature_ids: [6],
      getLabel: function (unit) {
        return extractFeature(unit, this.feature_ids[0], "label");
      },
      getData: function (unit) {
        return extractFeature(unit, this.feature_ids[0], "value");
      },
    },
    {
      name: "interestsGroups",
      feature_ids: [3],
      getLabel: function (unit) {
        return extractFeature(unit, this.feature_ids[0], "label");
      },
      getData: function (unit) {
        return extractFeature(unit, this.feature_ids[0], "value");
      },
    },
    {
      i18n_key: "additionalInformation",
      display: "additionalInformationSection",
    },
    {
      name: "cooperationWithUniveristies",
      feature_ids: [65],
      getLabel: function (unit) {
        return extractFeature(unit, this.feature_ids[0], "label");
      },
      getData: function (unit) {
        return extractFeature(unit, this.feature_ids[0], "value");
      },
    },
    {
      name: "cooperationWithEmployers",
      feature_ids: [5],
      getLabel: function (unit) {
        return extractFeature(unit, this.feature_ids[0], "label");
      },
      getData: function (unit) {
        return extractFeature(unit, this.feature_ids[0], "value");
      },
    },
    {
      name: "internationalContacts",
      feature_ids: [4],
      getLabel: function (unit) {
        return extractFeature(unit, this.feature_ids[0], "label");
      },
      getData: function (unit) {
        return extractFeature(unit, this.feature_ids[0], "value");
      },
    },
    {
      name: "personel",
      feature_ids: [30],
      getLabel: function (unit) {
        return extractFeature(unit, this.feature_ids[0], "label");
      },
      getData: function (unit) {
        return extractFeature(unit, this.feature_ids[0], "value");
      },
    },
    {
      name: "FtePlanned",
      feature_ids: [43],
      getLabel: function (unit) {
        return extractFeature(unit, this.feature_ids[0], "label");
      },
      getData: function (unit) {
        return extractFeature(unit, this.feature_ids[0], "value");
      },
    },
    {
      name: "FtePerformed",
      feature_ids: [44],
      getLabel: function (unit) {
        return extractFeature(unit, this.feature_ids[0], "label");
      },
      getData: function (unit) {
        return extractFeature(unit, this.feature_ids[0], "value");
      },
    },
  ],
  right: [
    {
      i18n_key: "contactData",
      features: [
        {
          i18n_key: "unitGroup",
          unit_condition: ["unitsGroup", "unitsGroupName"],
          getData: (unit, { url }) =>
              (
                <Link to={'/'+url+'/zespol/'+unit.unitsGroup}>{unit.unitsGroupName}</Link>
              )
        },
        {
          i18n_key: "address",
          unit_condition: ["street", "city"],
          getData: (unit) =>
            `${unit.street} ${unit.houseNo}${
              unit.appartmentNo ? "/" + unit.appartmentNo : ""
            }, ${unit.postalCode || ""} ${unit.city}`,
        },
        {
          i18n_key: "phone",
          unit_condition: ["phone"],
          getData: (unit) => unit.phones?.join(" / "),
        },
        {
          i18n_key: "fax",
          unit_condition: ["faxes"],
          getData: (unit) => unit.faxes?.join(" / "),
        },
        {
          i18n_key: "email",
          unit_condition: ["email"],
          getData: (unit) =>
            parse(`<a href="mailto:${unit.email}">${unit.email}</a>`),
        },
        {
          i18n_key: "www",
          unit_condition: ["www"],
          getData: (unit) =>
            parse(
              `<a href="${linkify(
                unit.www
              )}" target="_blank" rel="noopener noreferrer">${unit.www}</a>`
            ),
        },
        {
          i18n_key: "workingHours",
          name: "workingHours",
          feature_ids: [31, 36],
          getData: function (unit) {
            const startHour = extractFeature(
              unit,
              this.feature_ids[0],
              "value"
            );
            const endHour = extractFeature(unit, this.feature_ids[1], "value");
            return `${startHour}-${endHour}`;
          },
        },
        {
          i18n_key: "manager",
          unit_condition: ["manager"],
          getData: (unit) => unit.manager,
        },
        {
          name: "managerPhone",
          feature_ids: [53],
          getLabel: function (unit) {
            return extractFeature(unit, this.feature_ids[0], "label");
          },
          getData: function (unit) {
            return extractFeature(unit, this.feature_ids[0], "value");
          },
        },
        {
          name: "managerEmail",
          feature_ids: [54],
          getLabel: function (unit) {
            return extractFeature(unit, this.feature_ids[0], "label");
          },
          getData: function (unit) {
            return extractFeature(unit, this.feature_ids[0], "value");
          },
        },
        {
          name: "managerHours",
          feature_ids: [55],
          getLabel: function (unit) {
            return extractFeature(unit, this.feature_ids[0], "label");
          },
          getData: function (unit) {
            return extractFeature(unit, this.feature_ids[0], "value");
          },
        },
      ],
    },
    {
      i18n_key: "terms",
      display: "terms",
    },
    {
      i18n_key: "recruitment",
      features: [
        {
          name: "totalPlaces",
          feature_ids: [47],
          getLabel: function (unit) {
            return extractFeature(unit, this.feature_ids[0], "label");
          },
          getData: function (unit) {
            return extractFeature(unit, this.feature_ids[0], "value");
          },
        },
        {
          name: "freePlaces",
          feature_ids: [48],
          additionalCondition: "ShowFreePlaces",
          getLabel: function (unit) {
            return extractFeature(unit, this.feature_ids[0], "label");
          },
          getData: function (unit) {
            return extractFeature(unit, this.feature_ids[0], "value");
          },
        },
        {
          name: "ageRange",
          feature_ids: [35],
          getLabel: function (unit) {
            return extractFeature(unit, this.feature_ids[0], "label");
          },
          getData: function (unit) {
            return extractFeature(unit, this.feature_ids[0], "value");
          },
        },
      ],
    },
    {
      name: "openDay",
      feature_ids: [52],
      getLabel: function (unit) {
        return extractFeature(unit, this.feature_ids[0], "label");
      },
      getData: function (unit) {
        return extractFeature(unit, this.feature_ids[0], "value");
      },
    },
    {
      name: "approach",
      feature_ids: [39],
      getLabel: function (unit) {
        return extractFeature(unit, this.feature_ids[0], "label");
      },
      getData: function (unit) {
        return extractFeature(unit, this.feature_ids[0], "value");
      },
    },
    {
      name: "openingHoursRemarks",
      feature_ids: [32],
      getLabel: function (unit) {
        return extractFeature(unit, this.feature_ids[0], "label");
      },
      getData: function (unit) {
        return extractFeature(unit, this.feature_ids[0], "value");
      },
    },
    {
      i18n_key: "schoolBranches",
      display: "branches",
    },
    {
      name: "facilitiesDescription",
      feature_ids: [8],
      getLabel: function (unit) {
        return extractFeature(unit, this.feature_ids[0], "label");
      },
      getData: function (unit) {
        return extractFeature(unit, this.feature_ids[0], "value");
      },
    },
    {
      i18n_key: "additionalFeatures",
      display: "additionalFeatures",
    },
    {
      name: "additionalActivitiesSection",
      feature_ids: [20],
      getLabel: function (unit) {
        return extractFeature(unit, this.feature_ids[0], "label");
      },
      getData: function (unit) {
        return extractFeature(unit, this.feature_ids[0], "value");
      },
    },
    {
      name: "nutrition",
      feature_ids: [16],
      getLabel: function (unit) {
        return extractFeature(unit, this.feature_ids[0], "label");
      },
      getData: function (unit) {
        return extractFeature(unit, this.feature_ids[0], "value");
      },
    },
    {
      i18n_key: "organization",
      features: [
        {
          i18n_key: "unitType",
          unit_condition: ["unitType"],
          getData: (unit) =>
            Object.values(DictUnitTypeExt)
              .find((e) => e.value === unit.unitType)
              ?.toString(),
        },
        {
          name: "leadingAuthority",
          feature_ids: [49],
          getLabel: function (unit) {
            return extractFeature(unit, this.feature_ids[0], "label");
          },
          getData: function (unit) {
            return extractFeature(unit, this.feature_ids[0], "value");
          },
        },
        {
          name: "paymentPrinciples",
          feature_ids: [37],
          getLabel: function (unit) {
            return extractFeature(unit, this.feature_ids[0], "label");
          },
          getData: function (unit) {
            return extractFeature(unit, this.feature_ids[0], "value");
          },
        },
        {
          name: "iodoEmail",
          feature_ids: [64],
          getLabel: function (unit) {
            return extractFeature(unit, this.feature_ids[0], "label");
          },
          getData: function (unit) {
            return extractFeature(unit, this.feature_ids[0], "value");
          },
        },
        {
          name: "iodoPhone",
          feature_ids: [66],
          getLabel: function (unit) {
            return extractFeature(unit, this.feature_ids[0], "label");
          },
          getData: function (unit) {
            return extractFeature(unit, this.feature_ids[0], "value");
          },
        },
        {
          name: "subsidisedUnit",
          feature_ids: [42],
          getLabel: function (unit) {
            return extractFeature(unit, this.feature_ids[0], "label");
          },
          getData: function (unit) {
            return extractFeature(unit, this.feature_ids[0], "value")
              ? "Tak"
              : "Nie";
          },
        },
        {
          name: "dataProcessingUnitName",
          feature_ids: [67],
          getLabel: function (unit) {
            return extractFeature(unit, this.feature_ids[0], "label");
          },
          getData: function (unit) {
            return extractFeature(unit, this.feature_ids[0], "value");
          },
        },
        {
          name: "dataProcessingUnitPhone",
          feature_ids: [68],
          getLabel: function (unit) {
            return extractFeature(unit, this.feature_ids[0], "label");
          },
          getData: function (unit) {
            return extractFeature(unit, this.feature_ids[0], "value");
          },
        },
        {
          name: "dataProcessingUnitMail",
          feature_ids: [69],
          getLabel: function (unit) {
            return extractFeature(unit, this.feature_ids[0], "label");
          },
          getData: function (unit) {
            return extractFeature(unit, this.feature_ids[0], "value");
          },
        },
        {
          name: "accountNumberForFees",
          feature_ids: [56],
          getLabel: function (unit) {
            return extractFeature(unit, this.feature_ids[0], "label");
          },
          getData: function (unit) {
            return extractFeature(unit, this.feature_ids[0], "value");
          },
        },
        {
          name: "accountNumberForCatering",
          feature_ids: [58],
          getLabel: function (unit) {
            return extractFeature(unit, this.feature_ids[0], "label");
          },
          getData: function (unit) {
            return extractFeature(unit, this.feature_ids[0], "value");
          },
        },
        {
          name: "feeRecipient",
          feature_ids: [59],
          getLabel: function (unit) {
            return extractFeature(unit, this.feature_ids[0], "label");
          },
          getData: function (unit) {
            return extractFeature(unit, this.feature_ids[0], "value");
          },
        },
        {
          name: "feeRecipientAddress",
          feature_ids: [60],
          getLabel: function (unit) {
            return extractFeature(unit, this.feature_ids[0], "label");
          },
          getData: function (unit) {
            return extractFeature(unit, this.feature_ids[0], "value");
          },
        },
        {
          name: "feeRecipientNIP",
          feature_ids: [61],
          getLabel: function (unit) {
            return extractFeature(unit, this.feature_ids[0], "label");
          },
          getData: function (unit) {
            return extractFeature(unit, this.feature_ids[0], "value");
          },
        },
        {
          name: "regon",
          feature_ids: [1],
          getLabel: function (unit) {
            return extractFeature(unit, this.feature_ids[0], "label");
          },
          getData: function (unit) {
            return extractFeature(unit, this.feature_ids[0], "value");
          },
        },
      ],
    },
  ],
};

export const additionalFeatureIcons = {
  EliminationDiets: {
    id: 19,
    label: "Diety elimina&shy;cyjne",
    icon: "elimination-diets.svg",
  },
  CentralKitchen: {
    id: 51,
    label: "Kuchnia centralna",
    icon: "central-kitchen.svg",
  },
  MilkCousine: {
    id: 18,
    label: "Kuchnia mleczna",
    icon: "milk-cousine.svg",
  },
  Garden: {
    id: 13,
    label: "Ogródek",
    icon: "garden.svg",
  },
  KindergartenGarden: {
    id: 29,
    label: "Ogródek przed&shy;szkolny",
    icon: "garden.svg",
  },
  SeparateBuilding: {
    id: 28,
    label: "Osobny budynek",
    icon: "separate-building.svg",
  },
  Parking: {
    id: 12,
    label: "Parking",
    icon: "parking.svg",
  },
  Playground: {
    id: 50,
    label: "Plac zabaw",
    icon: "playground.svg",
  },
  EntranceForDisabled: {
    id: 10,
    label: "Podjazd dla niepełno&shy;sprawnych",
    icon: "entrance-for-disabled.svg",
  },
  FacilitiesForDisabled: {
    id: 57,
    label: "Ułatwienia dla niepełno&shy;sprawnych",
    icon: "entrance-for-disabled.svg",
  },
  WheelchairRamp: {
    id: 9,
    label: "Podjazd dla wózków",
    icon: "wheelchair-ramp.svg",
  },
  Rhytmics: {
    id: 22,
    label: "Rytmika",
    icon: "rhythmics.svg",
  },
  OutdoorTerrace: {
    id: 15,
    label: "Taras do werandowa&shy;nia",
    icon: "outdoor-terrace.svg",
  },
  TrolleyRoom: {
    id: 14,
    label: "Wózkarnia",
    icon: "trolley-room.svg",
  },
  Activities: {
    id: 20,
    label: "Zajęcia dodatkowe",
    icon: "activities.svg",
  },
  Sports: {
    id: 23,
    label: "Zajęcia sportowe",
    icon: "sports.svg",
  },
  ArtClasses: {
    id: 26,
    label: "Kółko plastyczne",
    icon: "art-classes.svg",
  },
  TheaterClasses: {
    id: 25,
    label: "Kółko teatralne",
    icon: "theater-classes.svg",
  },
  Elevator: {
    id: 11,
    label: "Winda",
    icon: "elevator.svg",
  },
  Integration: {
    id: 71,
    label: "Placówka integracyjna",
    icon: "integration.svg",
  },
  Chess: {
    id: 70,
    label: "Zajęcia szachowe",
    icon: "chess.svg",
  },
  ComputerClasses: {
    id: 24,
    label: "Zajęcia kompute&shy;rowe",
    icon: "computer-classes.svg",
  },
  Languages: {
    id: 21,
    label: "Języki obce",
    icon: "languages.svg",
  },
  Religion: {
    id: 27,
    label: "Religia",
    icon: "religion.svg",
  },
};
