import { memo, useState, useEffect, useCallback, useContext } from "react";
import styled from 'styled-components';
import { useTranslation } from "react-i18next";
import TextFieldForm from "./TextFieldForm";
import CheckboxForm from "./CheckboxForm";
import { useFormContext, Controller } from "react-hook-form";

import InputMask from "react-input-mask";

import usePesel from "../utils/usePesel";
import { usePeselFiller } from 'utils/formFiller/usePeselFiller';

import SelectForm from "./SelectForm";
import DateInput from "./DateInput";

import { ApplicationContext } from "../Application";
import { differenceInMonths, subMonths } from 'date-fns';

const genderDefaultOptions = {męska: "1", żeńska: "2"};

const PeselInput = ({
    name,
    peselData,
    isRequired,
    disabled,
    ...rest
}) => {
    const { t } = useTranslation();
    const [ usingPesel, setUsingPesel ] = useState(null);

    const { control, trigger, setValue, getValues, unregister } = useFormContext();
    const { 
        formValidationMethods: { removeFieldFromValidation, addFieldToValidation }, 
        instance: { maxAge: maxAgeInMonths } 
    } = useContext(ApplicationContext);

    const ageRestricted = name === 'Pesel';

    useEffect(() => {
        // startin values
        const formPesel = getValues(peselData?.peselName);
        const formId = getValues(peselData?.idName);
        const formBirthDate = getValues(peselData?.birthDateName);
        if (((!formPesel || formPesel?.length < 1) && (formId || formBirthDate)) || peselData?.usingPesel === false) {
            unregister(peselData.peselName);
            setUsingPesel(false);
        } else {
            unregister(peselData.idName);
            setUsingPesel(true);
        }
    }, []);

    useEffect(() => {
        if (usingPesel === true) {
            unregister(peselData.idName);
            removeFieldFromValidation(peselData.idName);

            addFieldToValidation(peselData.peselName);
        }
        if (usingPesel === false) {
            unregister(peselData.peselName);
            removeFieldFromValidation(peselData.peselName);
            
            addFieldToValidation(peselData.idName);
        }
    }, [usingPesel, peselData, addFieldToValidation, removeFieldFromValidation]);

    const onAutoFill = async (pesel) => {
        await setValue(peselData.peselName, pesel);
        trigger(peselData.peselName);
    }    
    
    usePeselFiller({name, setValue: onAutoFill, usingPesel});

    const { 
        // isValid: isValidPesel,
        // birthDate: peselBirthDate,
        // gender: peselGender,
        validatePesel: peselValidation,
     } = usePesel();

    const validatePeselField = async (val) => {
        if (isRequired && (val === '' || val === undefined || val === null)) {
            // autoFillFieldsFromPesel(true);
            return t('application.errors.fieldRequired');
        }
        if ((/_/).test(val) || val.length !== 11) {
            // autoFillFieldsFromPesel(true);
            return t('application.errors.peselTooShort');
        }

        const peselObject = peselValidation(val);
        if (!peselObject) {
            return t('application.errors.wrongPesel');
        }

        const today = new Date();
        today.setHours(0, 0, 0, 0);
        if (new Date(peselObject.birthDate) > today) {
            // birth date is later than today...
            return t('application.errors.wrongBirthDate');
        }
        if (ageRestricted && maxAgeInMonths && differenceInMonths(today, new Date(peselObject.birthDate)) > maxAgeInMonths) {
            return t('application.errors.tooOld');
        }
        if (peselData?.birthDateName) {
            setValue(peselData?.birthDateName, peselObject.birthDate);
        }
        if (peselData?.genderName) {
            setValue(peselData?.genderName, parseInt(peselObject.gender));
        }
        return true;
    }

    const usingPeselCheckbox = useCallback((fieldName) => (
        <ChevronCheckboxWrapper>
            <CheckboxForm
                name={"No"+name}
                label={t('application.noPeselLabel')}
                onChange={() => {
                    setUsingPesel(state => !state);
                    if (!usingPesel) {
                        trigger(fieldName);
                    }
                }}
                checked={!usingPesel}
                disabled={disabled}
            />
        </ChevronCheckboxWrapper>
    ), [usingPesel]);

    return (
        <>
           {usingPesel && (
                <ControlledPeselField
                    name={name}
                    control={control}
                    validatePeselField={validatePeselField}
                    trigger={trigger}
                    isRequired={isRequired}
                    label={rest.label}
                    description={rest.description}
                    usingPeselCheckbox={usingPeselCheckbox}
                    // formPesel={currentPesel}
                    disabled={disabled}
                />
            )}
            {!usingPesel && peselData?.idLabel && (
                <IdentityInput
                    name={peselData?.idName}
                    isRequired={isRequired}
                    label={peselData?.idLabel}
                    description={peselData?.idDescription}
                    chevron={usingPeselCheckbox}
                    disabled={disabled}
                />
            )}
            {!usingPesel && peselData.genderName && (
                <SelectForm
                    name={peselData?.genderName}
                    isRequired={isRequired}
                    label={peselData?.genderLabel}
                    description={peselData?.genderDescription}
                    options={peselData?.genderOptions || genderDefaultOptions}
                    disabled={disabled}
                />
            )}
            {!usingPesel && peselData?.birthDateName && (
                <DateInput
                    name={peselData?.birthDateName}
                    isRequired={isRequired}
                    label={peselData?.birthDateLabel}
                    description={peselData?.birthDateDescription}
                    disabled={disabled}
                    maxValue={new Date()}
                    minValue={ (maxAgeInMonths && ageRestricted) ? subMonths(new Date(), maxAgeInMonths) : null}
                />
            )}
        </>
    );
};

export default memo(PeselInput);

const ControlledPeselField = memo(({
    // formPesel,

    name,
    control,
    validatePeselField,
    trigger,
    isRequired,
    label,
    description,
    usingPeselCheckbox,
    disabled
}) => {
    // const [val, setVal] = useState(formPesel || '');

    // useEffect(() => {
    //     if (formPesel?.length === 11 && formPesel !== val) {
    //         setVal(formPesel);
    //     }
    // }, [formPesel]);
    
    return (
        <Controller
            name={name}
            control={control}
            defaultValue=''
            rules={{ validate: validatePeselField }}
            render={({ field: { name: fieldName, ...fieldRest }, fieldState }) => {
                return (
                    <InputMask
                        mask="99999999999"
                        {...fieldRest}
                        // onChange={ev => setVal(ev.target.value)}
                        // value={val}
                        onBlur={() => {
                            // fieldRest.onChange(val);
                            setTimeout(() => trigger(fieldName), 10);
                        }}
                        disabled={disabled}
                    >
                        {(inputProps) => {
                            return (
                                <TextFieldForm
                                    placeholder={''}
                                    {...inputProps}
                                    isRequired={isRequired}
                                    label={label}
                                    description={description}
                                    error={fieldState.error}
                                    chevron={usingPeselCheckbox(fieldName)}
                                    disabled={disabled}
                                />
                            )
                        }}
                    </InputMask>
                )
            }}
        />
    )
});


const IdentityInput = ({ name, isRequired, label, description, chevron, disabled }) => {
    const { control } = useFormContext();
    return (
        <Controller
            name={name}
            control={control}
            defaultValue=''
            render={({ field, fieldState }) => {
                return (
                    <TextFieldForm
                        placeholder={''}
                        isRequired={isRequired}
                        description={description}
                        label={label}
                        {...field}
                        chevron={chevron(field.name)}
                        error={fieldState.error}
                        disabled={disabled}
                    />
                )
            }}
        />
    )
}


const ChevronCheckboxWrapper = styled.div`
    position: absolute;
    top: 50%;
    right: 1rem;
    transform: translateY(-50%);
`;
