import { useState, useCallback, useRef, memo, cloneElement } from 'react';

import * as Styled from './Tooltip.styles';

import ModalInfo from 'components/commons/Modal/ModalInfo';


const IconTooltip = ({
    tooltip,
    children,
    maxWidth = 150,
    isMobile,
    useWrapperBoundaries = true,
    // when isMobile flag is checked - tooltip info will be displayed as modal, triggered on icon click instead of mouseEnter - if this kind of behavior is not required simply leave this param unset

    standardIconSize = false,
}) => {
    const [currentTooltipText, setCurrentTooltipText] = useState();
    const [tooltipOnLeft, setTooltipOnLeft] = useState(false);

    const [mobileModalVisible, setMobileModalVisible] = useState(false);

    const iconRef = useRef();

    const onShowTooltip = () => {
        let parrentWrapperRight = window.innerWidth;
        if (useWrapperBoundaries && iconRef.current) {
            const parentWrapper = findWrapper(iconRef.current, 'tooltip-wrapper');
            if (parentWrapper) {
                parrentWrapperRight = parentWrapper.getBoundingClientRect().right;
            }
        }
        setCurrentTooltipText(tooltip);
        if (iconRef && iconRef.current) {
            setTooltipOnLeft(iconRef.current.getBoundingClientRect().right + maxWidth > parrentWrapperRight);
        }
    };

    const onHideTooltip = useCallback(() => {
        setCurrentTooltipText(null);
    }, []);
    
    const onShowModal = () => {
        setMobileModalVisible(true);
    }

    const iconHandlers = {
        onMouseEnter: isMobile ? null : onShowTooltip,
        onMouseLeave: isMobile ? null : onHideTooltip,
        onClick: isMobile ? onShowModal : null,
    }

    const iconToRender = children ? (
        cloneElement(children, {
            style: {
                ...(children.props.style || {}),
                ...(standardIconSize ? {
                    width: '1.5rem',
                    marginLeft: '1rem',
                    cursor: 'default',
                } : {})
            }, ...iconHandlers
        })) : (
        <Styled.StyledInfoIcon
            {...iconHandlers}
        />
    );

    return (
        <>
            {mobileModalVisible && (
                <ModalInfo 
                    closeModal={() => setMobileModalVisible(false)} 
                    info={tooltip} 
                />
            )}
            {tooltip && (
                <Styled.TooltipWrapper
                    className="tooltip-wrapper"
                    ref={iconRef}
                >
                    {iconToRender}
                    {currentTooltipText && (
                        <Styled.Tooltip
                            tooltipOnLeft={tooltipOnLeft}
                            maxWidth={maxWidth}
                            className='tooltip'
                        >
                            {currentTooltipText}
                        </Styled.Tooltip>
                    )}
                </Styled.TooltipWrapper>
            )}
        </>
    )
}
export default memo(IconTooltip);

const findWrapper = (element, className) => {
    let parent = element.parentElement;
    while (parent) {
        if (parent.classList.contains(className)) {
            return parent;
        }
        parent = parent.parentElement;
    }
    return null;
}