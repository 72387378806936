import * as React from 'react';

import Header from 'components/layouts/Header/Header';
import Footer from 'components/layouts/Footer/Footer';

import * as Styled from './InsideError.styles';
import * as Layout from 'components/commons/Layout/Layout';

const InsideError = ({children}) => {
    return (
        <>
            <Header />
            <Styled.InsideError>
                <Layout.Container>
                    {children}
                </Layout.Container>
            </Styled.InsideError>
            <Footer />
        </>
    )
};

export default InsideError;