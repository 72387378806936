import { useEffect, useCallback, createContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useForm, FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import FormStepSelector from './elements/FormStepSelector';

import ModalRegulations from './elements/ModalRegulations';

// import useFormValidation from './utils/useFormValidation';
import useApplication from './utils/useApplication';

import LoginOrRegister from 'pages/LoginOrRegister/LoginOrRegister';

import ModuleFactory from './modules/ModuleFactory';
import * as Styled from './Application.styles';

import Loader from 'components/commons/Loader/Loader';
import ModalConfirm from "components/commons/Modal/ModalConfirm"

import { podajBiernik, podajCelownik } from "utils/utils";
import Modal from 'components/commons/Modal/Modal';

/// ApplicationPage > ModuleFactory > FormModule > SectionFactory > FormSection > ElementFactory 

export const ApplicationContext = createContext();

const IS_TEST = false;

const ApplicationPageWrapper = ({ applicationType = 1 }) => {
    // force reload when switch applicationType;
    return <ApplicationPage key={applicationType} applicationType={applicationType} />
}


const ApplicationPage = ({ applicationType }) => {
    const { t } = useTranslation();
    const { appId: savedAppId } = useParams();

    /*** APPLICATION LOGIC */
    // all logic moved to useApplication;
    const appLogic = useApplication({
        applicationType,
        isTest: IS_TEST,
    });

    const {
        schema,
        instance, instanceId,

        // main settings sethings
        formIsReady,
        isAuthenticated,
        formReadOnly,

        // form and validation methods
        autoSequenceValidation,
        formMethods,

        // saving form
        // onSubmitEntireForm,

        // form steps handling
        steps,
        currentStep,
        // availableStep,
        onBallClickGotoStep,



        // minor settings and methods
        pageBlocker,

        // currentFormKey,

        initialRegulations, setInitialRegulations
    } = appLogic;
    

    if (!isAuthenticated) {
        return (
            <main>
                <LoginOrRegister />
            </main>
        );
    }

    return (
        <main>
            { initialRegulations && instanceId && (
                <ModalRegulations
                    regulationsId={instanceId}
                    setInitialRegulations={setInitialRegulations}
                    type={initialRegulations}
                /> 
            )}

            {pageBlocker.pageBlocked && (
                <ModalConfirm
                    onConfirm={pageBlocker.onProceed}
                    closeModal={pageBlocker.onBlock}
                    closeButton={false}
                    title={
                        t('application.blockerTitle', { what: podajBiernik(applicationType) })
                    }
                    text={
                        `${t('application.blockerContent', { what: podajBiernik(applicationType) })}\n
                        ${formMethods.formState.isDirty ? t('application.blockerContentDirty') : ''}`
                    }
                    confirmText={
                        t('application.blockerConfirm', { what: podajCelownik(applicationType) })
                    }
                />
            )}

            {autoSequenceValidation.isAutoSequence && (
                <AutoSequenceModal />
            )}

            {(!formIsReady) ? (
                <Loader />
            ) : (
                <>
                    <FormStepSelector
                        steps={steps}
                        currentStep={currentStep}
                        onBallClick={onBallClickGotoStep}
                        // availableStep={availableStep}
                        errors={autoSequenceValidation.validStepsArray}
                        formReadOnly={formReadOnly}
                        isTest={IS_TEST}

                        instanceDisplayName={instance.displayName}
                        savedAppId={savedAppId}
                    />

                    <FormProvider
                        {...formMethods}
                    >
                        <ApplicationContext.Provider
                            value={{...appLogic}}
                        >
                            <Styled.FormWrapper>
                                <ModuleFactory
                                    module={schema[currentStep]}
                                    maxStep={steps.length - 1}
                                    formIsDirty={formMethods.formState.isDirty}
                                />
                            </Styled.FormWrapper>
                        </ApplicationContext.Provider>

                    </FormProvider>
                </>
            )}
        </main>
    );
}

export default ApplicationPageWrapper;


const AutoSequenceModal = () => {
    return (
        <Modal
            closeModal={false}
            wrapModal={true}
            closeButton={null}
            closeOnBackground={false}
        >
            {() => <p style={{ textAlign: "center", fontSize: '1.6rem', fontWeight: '600' }}>Trwa sprawdzanie wniosku</p>}
        </Modal >
    )
};