import { useMemo, useCallback, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { UnitGeneralTypes, UnitInstanceStatus, HighSchoolDisplayMode } from "utils/enums";

export default function useUnitList(instance) {
    const { t } = useTranslation();
    const [ nurseriesPublicSegregation, setNurseriesPublicSegregation ] = useState(false);
    const [ featuresTitle, setFeaturesTitle ] = useState(t("units:header.additionalFeatures"))

    useEffect(() => {
        if (instance.unitGeneralType === UnitGeneralTypes.Nursery){
            setNurseriesPublicSegregation(!("NoPublicNurserySegregation" in instance.settings));
        }
        if (instance.unitGeneralType === UnitGeneralTypes.Kindergarten) {
            setFeaturesTitle(t("units:header.extendedSubjects"))
        }
    }, [instance]);

    const instanceVisibleFeatures = useMemo(() => {
        const filteredFeatures = instance.settings?.ShowAlternateNames
            ? instance.features
                .map(
                    (el) => instance.alternateFeatureNames?.find((obj) => el.id === obj.id) ??
                        el
                )
                ?.filter((el) => instance.visibleFeatures?.includes(el.id))
            : instance.features.filter((el) => instance.visibleFeatures?.includes(el.id));
        return (filteredFeatures);
    }, [instance]);

    const instanceLanguages = useMemo(() => {
        if (instance?.languages?.length > 0) {
            return (instance?.languages.reduce((acc, language) => ({ ...acc, [language.id]: language.label }), {}));
        }
        return [];
    }, [instance]);

    const instanceSubjects = useMemo(() => {
        if (instance.unitGeneralType === UnitGeneralTypes.SecondarySchool && instance?.subjects) {
            return (instance.subjects.reduce((acc, subject) => ({ ...acc, [subject.id]: subject.name }), {}));
        }
        return [];
    }, [instance]);

    const calcFreePlaces = (freePlaces, returnBoolean = true) => {
        return (instance.settings?.ShowFreePlaces ? [{
            id: 5000,
            label: t("units:availablePlaces"),
            value: returnBoolean ? freePlaces > 0 : freePlaces.toString()
        }] : [])
    }

    const headerFeatures = useMemo(() => {
        switch (instance.unitGeneralType) {
            case UnitGeneralTypes.Nursery:
                return [
                    ...instanceVisibleFeatures.map(({ label }) => label),
                    ...(instance.settings?.ShowFreePlaces ? [t("units:availablePlaces")] : []),
                ];

            case UnitGeneralTypes.Kindergarten:
                return [
                    ...instanceVisibleFeatures.map(({ label }) => label),
                    ...(instance.settings?.ShowFreePlaces ? [t("units:availablePlaces")] : []),
                ];

            case UnitGeneralTypes.PrimarySchool:
            case UnitGeneralTypes.Class7:
                return [
                    t("units:header.features.languages"),
                    ...(instance.settings?.ShowFreePlaces ? [t("units:availablePlaces")] : []),
                ];

            case UnitGeneralTypes.SecondarySchool:
            case UnitGeneralTypes.TradeSchool:
                const additionalTitles = [];
                const displayMode = instance?.settings?.HighSchoolDisplayMode?.value;
                if (displayMode) {
                    if ((displayMode & HighSchoolDisplayMode.FreePlacesInUnit) == HighSchoolDisplayMode.FreePlacesInUnit) {
                        additionalTitles.push(t("units:header.features.freePlaces"));
                    }
                    if ((displayMode & HighSchoolDisplayMode.CountOfAllApplicationsInUnit) == HighSchoolDisplayMode.CountOfAllApplicationsInUnit) {
                        additionalTitles.push(t("units:header.features.candidatesRatio"));
                    }
                }
                return [
                    t("units:header.features.languages"),
                    t("units:header.features.scoredSubjects"),
                    ...(additionalTitles),
                ];

            case UnitGeneralTypes.Semicolony:
                return [
                    ...(instance.settings?.ShowFreePlaces ? ["Pakiet",t("units:header.features.freePlaces")] : ["Pakiet"]),
                ]

            default:
                return [];
        }
    }, [instance]);

    const getBooleanFeature = (unit, id) => {
        if (unit.boolFeatures?.length) {
            const feature = unit.boolFeatures.find((element) => element.id == id);
            if (feature) return feature.value;
        }
        return null;
    }

    function getPackageName(unit) {
        const result = getBooleanFeature(unit, 72);
        if (result === true)
            return "rozszerzony";
        else if (result === false)
            return "standardowy";
        else
            return "";
    }

    function isFlagEnabled(what, flag) {
        if (!what) return false;
        return (((what & flag) == flag));
    }

    const getUnitFeaturesBranches = useCallback((unit) => {
        switch (instance.unitGeneralType) {
            case UnitGeneralTypes.Nursery:
                case UnitGeneralTypes.Kindergarten:
                    // features & freePlaces
                const unitFeatures = unit.boolFeatures ? unit.boolFeatures.filter(feat => feat.value).map(({ id }) => id) : [];
                const nurseryFeatures = instanceVisibleFeatures.map(feature => {
                    return ({
                        ...feature,
                        value: unitFeatures.includes(feature.id),
                    })
                });
                return {
                    features: [
                        ...nurseryFeatures,
                        ...calcFreePlaces(unit.freePlaces)
                    ]
                };

                case UnitGeneralTypes.PrimarySchool:
                    case UnitGeneralTypes.Class7:
                        // langs / freePlaces;
                const langs = unit?.languages?.map(langId => instanceLanguages[langId]).toString().replace(/,/g, ", ") || '';
                return {
                    features: [
                        { id: 0, label: t("units:header.features.languages"), value: langs },
                        ...calcFreePlaces(unit.freePlaces)
                    ]
                }
            case UnitGeneralTypes.SecondarySchool:
                case UnitGeneralTypes.TradeSchool:
                    const highSchoolDisplayMode = instance?.settings?.HighSchoolDisplayMode?.value;
                    const additionalColumns = [];
                    let id = 1;
                    if (isFlagEnabled(highSchoolDisplayMode, HighSchoolDisplayMode.FreePlacesInUnit)) {
                        id++;
                        additionalColumns.push(                                {
                            id: id,
                            label: t("units:header.features.freePlaces"),
                            value: unit.freePlaces.toString()
                        });
                    }
                    if (isFlagEnabled(highSchoolDisplayMode, HighSchoolDisplayMode.CountOfAllApplicationsInUnit)) {
                        id++;
                        additionalColumns.push(                                               {
                                    id: id,
                                    label: t("units:header.features.candidatesRatio"),
                                    value: `${unit.applications1Pref}/${unit.applications}`
                                });
                    }

                if (!unit.schoolBranches) {
                    return {
                        features: [
                            { id: 0, label: t("units:header.features.languages"), value: '' },
                            { id: 1, label: t("units:header.features.scoredSubjects"), value: '' },
                            ...(additionalColumns)
                        ],
                        branches: null,
                        unitAdditionalInfo: unit?.id_DictStatus === UnitInstanceStatus.InPreparation ? t("units:secondarySchool.inPreparation") : null,
                    };
                }
                const uniqueLangs = new Set();
                const uniqueSubjects = new Set();
                const agregatedBranches = unit.schoolBranches?.reduce((acc, branch) => {
                    let branchLangs = '';
                    if (branch?.languages && branch.languages.length > 0) {
                        branchLangs = branch.languages.map(({ id_DictLanguage }) => {
                            const lang = instanceLanguages[id_DictLanguage];
                            uniqueLangs.add(lang);
                            return lang;
                        }).toString().replace(/,/g, ", ");
                    }

                    let branchSubjects = '';
                    if (branch?.awardedSubjects && branch.awardedSubjects.length > 0) {
                        branchSubjects = branch.awardedSubjects.map(subjectId => {
                            const subject = instanceSubjects[subjectId];
                            uniqueSubjects.add(subject);
                            return subject;
                        }).toString().replace(/,/g, ", ");
                    };

                    let additionalValuesForBranch = [];
                    id = 1;
                    if (isFlagEnabled(highSchoolDisplayMode, HighSchoolDisplayMode.FreePlacesInSchoolBranch)) {
                        id++;
                        additionalValuesForBranch.push({
                            id: id,
                            label: t("units:header.features.freePlaces"),
                            value: branch.freePlaces.toString()
                        });
                    }
                    if (isFlagEnabled(highSchoolDisplayMode, HighSchoolDisplayMode.CountOfAllApplicationsInSchoolBranch)) {
                        id++;
                        additionalValuesForBranch.push(                                {
                            id: id,
                            label: t("units:header.features.candidatesRatio"),
                            value: `${branch.applications1Pref}/${branch.applications}`
                        });
                    }

                    return [...acc, {
                        id: branch.id,
                        name: branch.name,
                        features: [
                            { id: 0, label: t("units:header.features.languages"), value: branchLangs },
                            { id: 1, label: t("units:header.features.scoredSubjects"), value: branchSubjects },
                            ...additionalValuesForBranch,
                            ],
                    }];
                    }, []);
                const additionalFeatures = [];
                id = 1;
                if (isFlagEnabled(highSchoolDisplayMode, HighSchoolDisplayMode.FreePlacesInUnit)) {
                    id++;
                    additionalFeatures.push(                            {
                        id: id,
                        label: t("units:header.features.freePlaces"),
                        value: unit.freePlaces.toString(),
                        isBold: true
                    });
                }
                if (isFlagEnabled(highSchoolDisplayMode, HighSchoolDisplayMode.CountOfAllApplicationsInUnit)) {
                    id++;
                    additionalFeatures.push(                            {
                            id: id,
                            label: t("units:header.features.candidatesRatio"),
                            value: `${unit.applications1Pref}/${unit.applications}`,
                            isBold: true
                        }
                    );
                }
                return {
                    features: [
                        { id: 0, label: 'Języki obce', value: `Wszystkie języki obce: ${uniqueLangs.size}`, isBold: true },
                        { id: 1, label: 'Przedmioty punktowane', value: `Wszystkie przedmioty punktowane: ${uniqueSubjects.size}`, isBold: true },
                        ...(additionalFeatures)
                    ],
                    branches: agregatedBranches,
                };

                case UnitGeneralTypes.Semicolony:
                    const semicolonyBranches = unit.schoolBranches?.map((branch) => {
                        return {
                            id: branch.id,
                            name: branch.name,
                            features: [...calcFreePlaces(branch.freePlaces, false)],
                            notLinked: true,
                        }
                    })
                return {
                        features: instance.settings?.ShowFreePlaces ? [{ id: 0, label: 'Pakiet', value: getPackageName(unit) },{id: 1,label: 'Wolne miejsca',value: `` }] : [{ id: 0, label: 'Pakiet', value: getPackageName(unit)  }],
                        branches: semicolonyBranches,
                };

            // case UnitGeneralTypes.Dormitory:
            // for now it uses default


            default:
                return {
                    features: [],
                    branches: null,
                };
        }
    }, [instance.unitGeneralType]);

    return {
        headerFeatures,
        getUnitFeaturesBranches,

        nurseriesPublicSegregation,
        featuresTitle,
    }
}