import styled from "styled-components";

export const UnitSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 3rem;
  @media ${({ theme }) => theme.device.tablet} {
    margin-top: 2rem;
  }
  @media ${({ theme }) => theme.device.desktop} {
    margin-top: 1rem;
  }  
`;

export const UnitTypesSection = styled.section`
  text-align: center;

  & a {
    text-decoration: none;
  }
`;

export const Title = styled.div`
  @media ${({ theme }) => theme.device.desktop} {
    text-align: center;
  }
`;

export const UnitTypesHeader = styled.h3`
  margin-bottom: 1.6rem;
  text-transform: uppercase;
  display: flex;
  gap: 2rem;
  font-size: 2rem;
  grid-column: 2;

  @media ${({ theme }) => theme.device.mobile} {
    font-size: 1.6rem;
    gap: 1rem;
  }

  & span:first-child {
    @media ${({ theme }) => theme.device.mobile} {
      display: none;
    }
  }
`;

export const UnitTypesSectionGrid = styled.div`
  display: grid;
  margin: 7rem 1rem;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 150px 150px;

@media ${({ theme }) => theme.device.desktop} {
    grid-template-columns: 1fr 1fr 1fr;

    > *:nth-child(7):last-child {
      grid-column-start: 2;
   }

    > *:only-child {
    grid-column-start: 2;
   }
  }

  @media ${({ theme }) => theme.device.touch} {
    grid-template-columns: 1fr 1fr;
  }

  @media ${({ theme }) => theme.device.mobile} {
    grid-template-columns: 1fr;
  }
`;

export const UnitTypesItem = styled.div`
  @media ${({ theme }) => theme.device.mobile} {
    display: grid;

    grid-template-columns: 12rem 1fr;
    align-items: center;
    /* gap: 1rem; */
    text-align: left;
  }
`;
