import React from "react";
import { useTranslation } from "react-i18next";

import StaticPage from "components/reusable/StaticPage/StaticPage";
import Banner from "components/commons/Banner/Banner";
import Breadcrumbs from "components/commons/Breadcrumbs/Breadcrumbs";

import InstancesSelector from "components/allinstances-page/InstancesSelector/InstancesSelector";

const AllInstances = () => {
  const { t } = useTranslation();

  return (
    <>
      <Breadcrumbs />
      <Banner />
      <StaticPage title={t("allinstances:title")}>
        <InstancesSelector />
      </StaticPage>
    </>
  );
};

export default AllInstances;
