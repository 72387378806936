import { useTranslation, Trans } from "react-i18next";

import { Link } from "components/commons/Link/Link";

import * as Styled from "pages/LoginOrRegister/LoginOrRegister.styles";

const LoginOrRegisterDescription = ({login, register}) => {
  const { t } = useTranslation();
  const appType = window.location.pathname.split("/").pop();
  let biernik = "podanie";
  let celownik = "podania";
  if (appType) {
      if (appType == "wniosek") {
          biernik = "wniosek";
          celownik ="wniosku";
      } else if (appType == "deklaracja") {
          biernik = "deklarację";
          celownik = "deklaracji";
      } else if (appType == "zgloszenie") {
          biernik = "zgłoszenie";
          celownik ="zgłoszenia";
      }
  }

  return (
    <Styled.LoginOrRegisterDescription>
      <Styled.LoginOrRegisterParagraph>
        <Trans
          i18nKey="loginOrRegister.loginDescription"
          values={{what: celownik}}
          components={{ 1: <Styled.Link as={Link} onClick={login} /> }}
        />
      </Styled.LoginOrRegisterParagraph>
      <Styled.LoginOrRegisterParagraph>
        <Trans
          i18nKey="loginOrRegister.registerDescription"
          components={{ 1: <Styled.Link as={Link} onClick={register} /> }}
        />
      </Styled.LoginOrRegisterParagraph>
    </Styled.LoginOrRegisterDescription>
  );
};

export default LoginOrRegisterDescription;
