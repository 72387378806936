import styled from "styled-components";

export const HowToSection = styled.section`
    margin-top: -12rem;
    padding-top: 12rem;
    @media ${({ theme }) => theme.device.tablet} {
        margin-top: -20rem;
        padding-top: 20rem;
    }
    @media ${({ theme }) => theme.device.desktop} {
        margin-top: 0;
        padding-top: 0;
    }
`;

export const Wrapper = styled.section`
  padding-bottom: 130px;
`;

export const Title = styled.div`
  @media ${({ theme }) => theme.device.desktop} {
    text-align: center;
  }
`;

export const Columns = styled.section`
  display: grid;
  grid-template-columns: 1fr;
  margin-top: 6rem;
  margin-bottom: 6rem;

    @media ${({ theme }) => theme.device.tablet} {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 2rem 3rem;
  }

  @media ${({ theme }) => theme.device.desktop} {
    grid-template-columns: repeat(3, 1fr);
    gap: 4rem 1rem;
  }

  @media ${({ theme }) => theme.device.hd} {
    grid-template-columns: repeat(6, 1fr);
  }
`;

export const Item = styled.div`
  padding: 3rem 0;
  /* &:not(:last-child) { */
    border-bottom: 1px solid ${({ theme }) => theme.colors.labelBorder};
  /* } */

  @media ${({ theme }) => theme.device.desktop} {
    padding: 2rem;
    border-top: 0;
    border-bottom: 0 !important;
    &:nth-child(3n + 2) {
      border-left: 1px solid ${({ theme }) => theme.colors.labelBorder};
      border-right: 1px solid ${({ theme }) => theme.colors.labelBorder};
    }
  }

  @media ${({ theme }) => theme.device.hd} {
    border-right: 0 !important;
    &:not(:first-child) {
      border-left: 1px solid ${({ theme }) => theme.colors.labelBorder};
    }
  }
`;

export const ItemNumber = styled.span`
  display: block;
  font-size: 5rem;
  font-weight: 300;
  color: ${({ theme }) => theme.colors.main};
`;

export const ItemHead = styled.div`
  display: flex;
  gap: 6rem;
  align-items: center;
  margin-bottom: 2rem;
`;

export const ItemTitle = styled.div`
  margin-top: 30px;
`;

export const ItemContent = styled.div`
  margin-top: 30px;
`;
