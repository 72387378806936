import  data from '../formFiller/data.json';

export const valuesGenerator =  () => {

	const getDigit = () => {
		const digit = Math.floor(Math.random() * 10);
		return digit > 0 ? digit : 1;
	};

	const randomDigits = (length)  => {
		if (length <= 0) return "0";
		let str = "";
		while (str.length < length)
				str += getDigit();
		return str;
	};

	const randomBool = ()  => Math.random() < 0.5;

	const randomNumber = (length)  => {
		if (length <= 0) return "0";
		let str = ""; 
		while (str.length < length)
				str += getDigit();

		return parseInt(str).toString();
	};

	const getRandomInRange = (min, max)  => {
		return Math.floor(Math.random() * (max - min + 1) + min);
	}

	const randomUniqueNumbers = (min, max, length)  => {
			const result = [];
			if (length <= 0) return result;
			while (result.length < length) {
					const number = getRandomInRange(min, max);
					if (!result.includes(number)){
						result.push(number);
					}						
			}
			return result;
	};

	const getRandomNumberArray = (n, min, max) =>  new Array(n).fill().map(() => getRandomInRange(min, max));

	const randomString = (length = 5) => {
		let result = '';
		const characters = 'abcdefghijklmnopqrstuvwxyz';
		const charactersLength = characters.length;
		let counter = 0;
		while (counter < length) {
			result += characters.charAt(Math.floor(Math.random() * charactersLength));
			counter += 1;
		}
		return result;
}

	const allLetters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";

	const getLetter = (l)  => {
		const d = l || allLetters
		const letterLength = d.length;
		const randomN = Math.floor(Math.random() * letterLength);
		const res = d.charAt(randomN);
		return res;
	};

	const getRandomHour = () =>  {
		return ("0" + getRandomInRange(0, 23)).slice(-2)
	}

	const getRandomMinute = () =>  {
		return ("0" + getRandomInRange(0, 59)).slice(-2)
	}

	const getRandomTime = ()  => {
		return getRandomHour() + ":" + getRandomMinute();
	}

	const getRandomYear = (from = 5, to = 1)  => {
		const currentYear = new Date().getFullYear();
		const result = getRandomInRange(currentYear - from, currentYear - to);
		return result;
	}

	const getRandomMonth = () =>  {
		return ("0" + getRandomInRange(1, 12)).slice(-2)
	}

	const getRandomDay = () =>  {
		return ("0" + getRandomInRange(1, 28)).slice(-2)
	}

	const getRandomDate = ({from, to})  => {
		 const result = getRandomYear(from, to) + '-' + getRandomMonth() + '-' + getRandomDay();
		 return result;
 	}

	const getRandomElement = (array) => {
		return array[getRandomInRange(0, array.length - 1)];
 	}

	const getRandomEmail = () => randomString(5) + '@' +randomString(5) + "."+getRandomElement(data.countryCodes);

	const getRandomCountry = () => getRandomElement(data.countries).pl;

	const calcPesel = (date, male) => {
		const k = [1, 3, 7, 9, 1, 3, 7, 9, 1, 3];
		const n = date.getFullYear();
		const l = n % 100;
		let f = date.getMonth() + 1;
		const h = date.getDate();
		if (n >= 1800 && n <= 1899) {
				f += 80
		} else {
				if (n >= 2000 && n <= 2099) {
						f += 20
				} else {
						if (n >= 2100 && n <= 2199) {
								f += 40
						} else {
								if (n >= 2200 && n <= 2299) {
										f += 60
								}
						}
				}
		}
		let g;
		const b = [Math.floor(l / 10), l % 10, Math.floor(f / 10), f % 10, Math.floor(h / 10), h % 10, Math.floor(Math.random() * 2)];
		for (g = b.length; g < k.length - 1; g++) {
				b[g] = getDigit()
		}
		if (male === true) {
				b[k.length - 1] = getLetter("13579")
		} else {
				b[k.length - 1] = getLetter("02468")
		}
		let c = 0;
		for (g = 0; g < b.length; g++) {
				c += k[g] * b[g]
		}
		c = (10 - (c % 10)) % 10;
		let a = "";
		for (g = 0; g < b.length; g++) {
				a += String(b[g])
		}
		a += String(c);
		return a
	};

	const getRandomIdentityNr = () => `${randomString(3).toUpperCase()}${randomDigits(6)}`;

	const getRandomObjProperty = (obj) => {
    var keys = Object.keys(obj);
    return obj[keys[ keys.length * Math.random() << 0]];
};

	const getChildPesel = (min, max)  => {
		const date = new Date();
		const diff = getRandomInRange(min, max);
		date.setFullYear(date.getFullYear() - diff);
		date.setDate(date.getDate() - diff);
		return calcPesel(date)
	};

	const getAdultPesel = (min, max, male)  => {
		const date = new Date();
		const diff = getRandomInRange(min, max);
		date.setFullYear(date.getFullYear() - diff);
		date.setDate(date.getYear(), getRandomInRange(0,11), getRandomInRange(1,28));
		return calcPesel(date, male)
	};

	const shuffle = (s) =>  {
		const a =s.split(""),
				n = a.length;

		for (let i = n - 1; i > 0; i--) {
				const j = Math.floor(Math.random() * (i + 1));
				const tmp = a[i];
				a[i] = a[j];
				a[j] = tmp;
		}
		return a.join("");
	};

	const randomArrayIdx = (arr) => Math.floor(Math.random() * arr.length);

	const getRandomPersonData = (female) => {
		if (female) {
			return getRandomMotherData();
		} else {
			return getRandomFatherData();
		}
	}

	const getRandomFemaleSurname = () => {
		const females = data.people.filter((x, i, a) => x.gender !== "male");
		const person = females[randomArrayIdx(females)];
		const surname = person.surname;
		return surname;
	}

	const getRandomMotherData = () => {
		const females = data.people.filter((x, i, a) => x.gender !== "male");
		const firstPerson = females[randomArrayIdx(females)];
		const name = firstPerson.name;
		const gender = firstPerson.gender;
		const secondPerson = data.people[randomArrayIdx(data.people)];
		const surname = secondPerson.surname;
		const region = secondPerson.region;
		return {name, gender, surname, region}
	}

	const getRandomFatherData = () => {
		const males = data.people.filter((x, i, a) =>x.gender === "male");
		const firstPerson = males[randomArrayIdx(males)];
		const name = firstPerson.name;
		const gender = firstPerson.gender;
		const secondPerson = males[randomArrayIdx(males)];
		const surname = secondPerson.surname;
		const region = secondPerson.region;
		return {name, gender, surname, region}
	}

	const getRandomCityData = () => data.cities[randomArrayIdx(data.cities)];

	const getRandomStreet = () => data.streetNames[randomArrayIdx(data.streetNames)];
	
	const getPassword = (lowercase, uppercase, digits, specials )  => {
		let result = '';

		if (lowercase) {
			for (let i = 1; i <= lowercase; i++ ) {
					let char = getLetter().toLowerCase();
					result += char;
			}
		}

		if (uppercase) {
			for (let i = 1; i <= uppercase; i++) {
					let char = getLetter().toUpperCase();
					result += char;
			}
		}

		if (digits) {
			for (let i = 1; i <= digits; i++) {
					let char = getDigit().toString();
					result += char;
			}
		}

		if (specials) {
			for (let i = 1; i <= specials; i++) {
					let char = getLetter('!%&@#$^*?_~()[]{}+-=";:<>.,/');
					result += char;
			}
		}

		return shuffle(result);
	}

	return{
		randomBool,
		randomDigits,
		randomNumber,
		randomString,
		randomUniqueNumbers,
		getRandomInRange,
		getRandomTime,
		getRandomDate,
		getRandomEmail,
		getRandomCountry,
		getChildPesel,
		getAdultPesel,
		getPassword,
		getRandomIdentityNr,
		getRandomFemaleSurname,
		getRandomPersonData,
		getRandomFatherData,
		getRandomMotherData,
		getRandomCityData,
		getRandomStreet,
		getRandomNumberArray,
		getRandomObjProperty,
		randomArrayIdx,
		getRandomElement
	}
};

const {
	randomString, randomDigits, getRandomElement, randomBool, getRandomNumberArray, getRandomInRange, getRandomIdentityNr, getRandomPersonData, getRandomMotherData, getRandomFatherData, getRandomDate, getRandomTime, getRandomFemaleSurname, randomNumber, getRandomCountry, getRandomCityData, getRandomStreet, getAdultPesel, getRandomEmail, getPassword
} = valuesGenerator();

export const inputFiler = (field, female) => {
    const {name, uid, options, componentType, component} = field;
	let randomPersonData = getRandomPersonData(female);
	if (name?.includes("Mother"))
		randomPersonData = getRandomMotherData();
	else if (name?.includes("Father"))
		randomPersonData = getRandomFatherData();
	const randomCityData = getRandomCityData();
	const randomStreet = getRandomStreet(); 
	const urlPath = window.location.pathname;
	const unitType = urlPath.split("/")[2];
	const hasSecondName = randomBool();
    const hasPost = randomBool();
    if(name?.toLowerCase().includes('id_community')) return '';

	const getTextByName = () => {
            if (name.includes("Pesel")) {
				return getAdultPesel(20, 90, !female);
			}
			if(name.includes("PostalCode"))
				return `${randomDigits(2)}-${randomDigits(3)}`;
			else if(name.includes("Post"))
				return hasPost? getRandomCityData().name: '';
			if(name.includes("IdentityNr"))
				return getRandomIdentityNr();
			if(name.includes("HouseNo"))
				return randomNumber(1);
			if(name.includes("AppartmentNo"))
				return randomNumber(2);
			if(name.includes('FirstName'))
				return randomPersonData.name;
			if(name.includes('SecondName'))
				return hasSecondName?  randomPersonData.name: '';
			if(name.includes('LastName'))
				return randomPersonData.surname;
			if(name.includes('BirthPlace'))
				return getRandomCityData().name;
			if(name.includes('MaidenName'))
				return female? getRandomFemaleSurname(): '';
			if(name.includes('Nationality'))
				return getRandomCountry();
			if(name.includes('City'))
				return randomCityData.name;
			if(name.includes('Id_Community'))
                return;
			// 	return '';
			if(name.includes('Street'))
				return randomStreet;
			if(name.includes('Password'))
				return getPassword(2,2,2,2);

			return randomString(10);
	};

	const getTextByUid = () => {
		if(uid.includes("SIBLINGS_AGES"))
			return getRandomNumberArray(5, 1, 17).join(",");

		return randomString(10);
	}

	const getDigitsByUid = () => {
		if(uid.includes("SIBLINGS_COUNT"))
			return getRandomInRange(1, 10);
		return randomDigits(4);
	}

	const getSelectValue = () => {
        if (Array.isArray(options)) {
            return getRandomElement(options);
        }
		const keys = Object.keys(options);
		const key = getRandomElement(keys);        
		return { value: options[key], label: key };
	}
	
	const getOneValue = (options) => {
        if (Array.isArray(options)) {
            return getRandomElement(options);
        }
		const keys = Object.keys(options);
		const key = getRandomElement(keys);
		return options[key];
	}

	const geUnitSpecyficDate = (val) => { 
		if(val.includes("Mother") || val.includes("Father")){
			return getRandomDate({from: 45, to: 20});
		};
		if(unitType.includes("szkolaponadpodstawowa")){
			return getRandomDate({from: 19, to: 15});
		};
		if(unitType.includes("szkolabranzowa2")){
			return getRandomDate({from: 21, to: 18});
		};
		if(unitType.includes("przedszkole") || unitType.includes("dyzurywakacyjne")){
			return getRandomDate({from: 6, to: 3});
		};
		if(unitType.includes("szkolapodstawowa") || unitType.includes("klasa")){
			return getRandomDate({from: 14, to: 7});
		};
		if(unitType.includes("bursa")){
			return getRandomDate({from: 18, to: 15});
		};
		if(unitType.includes("zlobek")){
			return getRandomDate({from: 3, to: 1});
		};
		if(unitType.includes("polkolonie")){
			return getRandomDate({from:143, to: 7});
		};
		if(unitType.includes("szkolapolicealna")){
			return getRandomDate({from: 40, to: 18});
		};
		return getRandomDate({from: 18, to: 1});
	}

	const getValue = () =>  {
		switch(component) {
			case 'input':
				switch(componentType) {
					case 'text': {
						if(name) return getTextByName();
						if(uid) return getTextByUid();
						return randomString(10);
					}
					case "pesel": {
						let min = 0;
						let max = 18;
						if(unitType.includes("zlobek")){
							min = 0; max = 3;
						};
						if(unitType.includes("szkolaponadpodstawowa")){
							min = 15; max = 18;
						};
						if(unitType.includes("szkolabranzowa2")){
							min = 18; max = 21;
						};
						if(unitType.includes("przedszkole") || unitType.includes("dyzurywakacyjne")){
							min = 4; max = 6;
						};
						if(unitType.includes("szkolapodstawowa") || unitType.includes("klasa")){
							min = 6; max = 14;
						};
						if(unitType.includes("bursa")){
							min = 15; max = 21;
						};
						if(unitType.includes("polkolonie")){
							min = 6; max = 14;
						};
						if(unitType.includes("szkolapolicealna")){
							min = 18; max = 40;
						};
						switch(name) {
							case "Pesel":return getAdultPesel(min, max, !female);
							case "Mother_Pesel":return getAdultPesel(18, 40, false);
							case "Father_Pesel":return getAdultPesel(18, 50, true);
						}
						return getAdultPesel(20, 90, true);
					}
					case "date":{
						const value = name || uid;
						if(value.toLowerCase().includes("birth")){
							return geUnitSpecyficDate(value);
						};
						return getRandomDate({from: 0, to: -2});
					}
					case "tel":
						return randomDigits(9);
					case "email": 
						return getRandomEmail();
					case 'postal':
						return `${randomDigits(2)}-${randomDigits(3)}`;
					case 'integer':
						return getDigitsByUid();
					case 'number':
						return randomDigits(3);
					case 'hour':
						return getRandomTime();
					case 'checkbox':
						if(options) return getOneValue(options);
						return randomBool() ? "YES": "NO";
					default: 
						return randomString(10);
				}
			case 'textarea': 
				return randomString(20);
            case 'radio':
                const rnd = getSelectValue(options);
                return rnd;
			case 'select':
				switch(componentType) {
					case 'userType':{
						if(options) return getSelectValue();
						break;
					}
					case 'boolean':{
						if(options) return getSelectValue();
						break;
					}
					default: 
						if(options) return getSelectValue();
				}
			default:
				return randomString(10);
	}}; 

	return { name, uid, value: getValue() }
}



