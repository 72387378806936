import { useState, useEffect, useMemo} from 'react';
import { theme } from 'styles/theme';

const useIsMobile = (breakpointName = 'colXs') => {
    const [isMobile, setIsMobile ] = useState(false);

    const desktopBreakpoint = useMemo(() => {
        return parseInt(theme.size[breakpointName].replace('px', ''));
    }, []);
    
    useEffect(() => {
        window.addEventListener('resize', onResize);
        onResize();
        return () => window.removeEventListener('resize', onResize);
    }, []);

    const onResize = () => {
        setIsMobile(window.innerWidth < desktopBreakpoint)
    };
    return isMobile;
}

export default useIsMobile;