import { useMemo, useContext, useEffect, memo } from 'react';
import styled from 'styled-components';
import { useFormFiller } from 'utils/formFiller/useFormFiller';
import parse from "html-react-parser";
import FieldFactory from '../fields/FieldFactory';

import useQuestionSchoolFitering from '../utils/useQuestionSchoolFitering';
import { ApplicationContext } from '../Application';

import Label from '../fields/elements/Label';

const QuestionsSection = ({ data: { questions} }) => {
    const { formValidationMethods } = useContext(ApplicationContext);

    useFormFiller(questions);

    const filteredSchools = useQuestionSchoolFitering();

    /// WARNING : on output (save or commit) - answers must be processed in: 
    /// src/pages/Application/processOutputData

    const processedQuestionsValid4SchoolBranch = useMemo(() => {
        let quests = [];
        if (questions) {
            questions.forEach(question => {
                if (question.valid4SchoolBranch && filteredSchools) {
                    const questionMappedBySchools = filteredSchools.map(school => {
                        formValidationMethods.addFieldToValidation(`Answers.${question.uid}##${school.adequateBranchId}`);
                        formValidationMethods.removeFieldFromValidation(`Answers.${question.uid}`);
                        return {
                            ...question,
                            uid: `${question.uid}##${school.adequateBranchId}`,
                            label: `${school.name}`,
                        }
                    });
                    const questionGroup = {
                        groupId: question.uid,
                        groupLabel: question.label,
                        elements: questionMappedBySchools,
                    }
                    quests = [...quests, questionGroup];
                }
                else {
                    quests = [...quests, question];
                }
            })
        }
        return quests;
    }, [ questions, filteredSchools ]);

    return (
        <QuestionsSectionWrapper
            className='questions-wrapper'
        >
            {
                (processedQuestionsValid4SchoolBranch) && processedQuestionsValid4SchoolBranch.map((question) => {
                    if (question?.groupId && question?.elements) {
                        return (
                            <QuestionGroupContainer
                                key={question.groupId}
                            >
                                <Label className='group-label'>{question.groupLabel}</Label>
                                <QuestionsWrapper>
                                    {question.elements.map((groupQuestion) => {
                                        return (
                                        <QuestionFactory
                                            question={groupQuestion}
                                            key={groupQuestion.uid}
                                        />
                                    )})}
                                </QuestionsWrapper>
                            </QuestionGroupContainer>
                        )
                    }

                    return (
                        <QuestionFactory
                            question={{
                                ...question,
                            }}
                            key={`quest: ${question.uid}`}
                        />
                    )
                })
            }
            <p className='required-info'><span className='asterisk'>*</span>&nbsp;Pole obowiązkowe</p>
        </QuestionsSectionWrapper>
    )
}

export default QuestionsSection;

const PrePostHtmlWrapper = styled.div`
    & * {
        color: ${({theme}) => theme.colors.black};
    }
`;

const QuestionFactory = memo(({
    question,
}) => {
    return (
        <>
            {question.preHtml && <PrePostHtmlWrapper>{parse(question.preHtml)}</PrePostHtmlWrapper>}
            <FieldFactory
                field={{
                    ...question, 
                    name: `Answers.${question.uid}`,
                }}
            />
            {question.postHtml && <PrePostHtmlWrapper>{parse(question.postHtml)}</PrePostHtmlWrapper>}
        </>
    );
}, (prevProps, nextProps) => {
    return JSON.stringify(prevProps) === JSON.stringify(nextProps);
});

const QuestionsWrapper = styled.div`
    margin-left: 2rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
`;

const QuestionGroupContainer = styled.div`
    & .group-label {
        margin-bottom: 2rem;
    }
    /* & .radio-form-wrapper {
        flex-direction: row;
        align-items: center;
    } */
`;

const QuestionsSectionWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4rem;

    border-top: 1px solid #00318954;
    padding-top: 3rem;
    padding-bottom: 1rem;

    & .required-info {
        font-size: 1.4rem;
        color: ${({theme}) => theme.colors.black};
        >.asterisk {
            color: red;
            font-size: 1.6rem;
        }
    }

    & label {
        font-size: 1.6rem;
        font-weight: 600;
        line-height: 2.5rem;
    }
    & .radio-label {
        font-weight: 400;
        /* color: yellow; */
    }
`;