import React, { useState, useRef, useEffect } from "react";
import { createPortal } from "react-dom";
import { createGlobalStyle } from "styled-components";

import { useTranslation } from "react-i18next";

import useApi from "hooks/useApi";
import useAuthentication from "hooks/useAuthentication";

import { Button } from "components/commons";

import closeIcon from "assets/icons/ico-close-2.svg";
import showEyeIcon from "assets/icons/ico-eye.svg";
import hideEyeIcon from "assets/icons/ico-eye2.svg";

import * as Styled from "pages/Account/elements/ModalDeleteAccount.styles";

const BodyBlockScroll = createGlobalStyle`
  body {
    overflow: hidden;
  }
`;

const ModalDeleteAccount = ({
  closeModal,
  closeOnBackground = true,
  closeButton = false,
  isDeleteAccountModal = true,
  isLocalUser = false,
  canRemove,
}) => {
  const [passwordVisibility, setPasswordVisibility] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);
  const [showWarning, setShowWarning] = useState("");
  const passwordInputRef = useRef(null);
  const { t } = useTranslation();
  const { candidateApi } = useApi();
  const { logout, changePassword } = useAuthentication();

  const togglePasswordVisibility = () => {
    setPasswordVisibility(!passwordVisibility);
  };

  const handleDeleteAccount = async () => {
    const endpoint = "Account";
    let password = null;
    if (isLocalUser) {
      password = passwordInputRef.current.value;
    }

    try {
      const response = await candidateApi.delete(endpoint, {
        data: { password },
      });

      if (response.data && response.status === 200) {
        setIsDeleted(true);
      } else {
        throw new Error(`Failed with unknown status: ${response.status}`);
      }
    } catch (error) {
      console.error("Account deletion failed:", error);
      let errorMessage = `${t("myData.deleteAccountModal.warning")}`;
      if (error.response) {
        if (error.response.status === 403 || error.response.status === 405) {
          errorMessage = (
            <span>
              {t("myData.deleteAccountModal.warning403")}{" "}
              <Styled.DeleteAccountLink onClick={changePassword}>
                Kliknij aby zresetować hasło
              </Styled.DeleteAccountLink>
            </span>
          );
        }
      }
      setShowWarning(errorMessage);
    }
  };

  useEffect(() => {
    if (isDeleted) {
      const timer = setTimeout(() => {
        logout();
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [isDeleted]);

  if (isDeleted) {
    return createPortal(
      <Styled.ModalWrapper>
        <BodyBlockScroll />
        <Styled.ModalContainer
          isDeleteAccountModal={isDeleteAccountModal}
          onClick={(ev) => {
            ev.stopPropagation();
            if (ev.target === ev.currentTarget) {
              ev.preventDefault();
            }
          }}
        >
          <Styled.H4>{t("myData.deleteAccountModal.success")}</Styled.H4>
        </Styled.ModalContainer>
      </Styled.ModalWrapper>,
      document.body
    );
  }

  if (!canRemove) {
    return createPortal(
      <Styled.ModalWrapper onClick={closeOnBackground ? closeModal : null}>
        <BodyBlockScroll />
        <Styled.ModalContainer
          isDeleteAccountModal={isDeleteAccountModal}
          onClick={(ev) => {
            ev.stopPropagation();
            if (ev.target === ev.currentTarget) {
              ev.preventDefault();
            }
          }}
        >
          {closeButton && (
            <Styled.CloseButton onClick={closeModal}>
              <img src={closeIcon} alt="close-modal" />
            </Styled.CloseButton>
          )}
          <Styled.H4>
            {t("myData.deleteAccountModal.titleCantRemove")}
          </Styled.H4>
          <p>{t("myData.deleteAccountModal.infoCantRemove")}</p>
        </Styled.ModalContainer>
      </Styled.ModalWrapper>,
      document.body
    );
  }

  return createPortal(
    <Styled.ModalWrapper onClick={closeOnBackground ? closeModal : null}>
      <BodyBlockScroll />
      <Styled.ModalContainer
        isDeleteAccountModal={isDeleteAccountModal}
        onClick={(ev) => {
          ev.stopPropagation();
          if (ev.target === ev.currentTarget) {
            ev.preventDefault();
          }
        }}
      >
        <Styled.H4>{t("myData.deleteAccount")}</Styled.H4>
        <Styled.H5>{t("myData.deleteAccountDescription")}</Styled.H5>
        {isLocalUser && (
          <Styled.ModalInputContainer>
            <label htmlFor="passwordInput">
              {t("myData.deleteAccountModal.passwordLabel")}
            </label>
            <input
              id="passwordInput"
              ref={passwordInputRef}
              type={passwordVisibility ? "text" : "password"}
            />
            <img
              src={passwordVisibility ? hideEyeIcon : showEyeIcon}
              alt={
                passwordVisibility
                  ? t("myData.deleteAccountModal.hide")
                  : t("myData.deleteAccountModal.show")
              }
              onClick={togglePasswordVisibility}
            />
          </Styled.ModalInputContainer>
        )}
        {showWarning && (
          <Styled.ModalTextWarning>{showWarning}</Styled.ModalTextWarning>
        )}
        <Styled.ModalButtonsContainer>
          <Styled.DeleteAccountLink onClick={closeModal}>
            {t("myData.deleteAccountModal.cancel")}
          </Styled.DeleteAccountLink>
          <Button onClick={handleDeleteAccount}>
            {t("myData.deleteAccountModal.delete")}
          </Button>
        </Styled.ModalButtonsContainer>
      </Styled.ModalContainer>
    </Styled.ModalWrapper>,
    document.body
  );
};

export default ModalDeleteAccount;
