export const languageModes = [
    { id: 1, label: 'I język' },
    { id: 2, label: 'II język' },
    { id: 3, label: 'kontynuacja' },
    { id: 4, label: 'nieobowiązkowy' },
    { id: 5, label: 'opcjonalny' },
    { id: 6, label: 'podstawowy' },
    { id: 7, label: 'II język - kontynuacja' },
    { id: 8, label: 'II język - podstawowy' }
];

export const languages = [
    { id: 1, label: "Chiński" },
    { id: 2, label: "Czeski" },
    { id: 3, label: "Angielski" },
    { id: 4, label: "Francuski" },
    { id: 5, label: "Niemiecki" },
    { id: 6, label: "Grecki" },
    { id: 7, label: "Włoski" },
    { id: 8, label: "Japoński" },
    { id: 9, label: "Łaciński" },
    { id: 10, label: "Portugalski" },
    { id: 11, label: "Rosyjski" },
    { id: 12, label: "Hiszpański" },
    { id: 13, label: "Białoruski" },
    { id: 14, label: "Hebrajski" },
    { id: 15, label: "Jidysz" },
    { id: 16, label: "Karaimski" },
    { id: 17, label: "Kaszubski" },
    { id: 18, label: "Litewski" },
    { id: 19, label: "Łemkowski" },
    { id: 20, label: "Ormiański" },
    { id: 21, label: "Romski" },
    { id: 22, label: "Słowacki" },
    { id: 23, label: "Tatarski" },
    { id: 24, label: "Ukraiński" },
    { id: 100, label: "Inny" }
];

export const subjects = [
    { id: 1, name: "język polski" },
    { id: 2, name: "język obcy nowożytny" },
    { id: 3, name: "drugi język obcy nowożytny" },
    { id: 100, name: "muzyka" },
    { id: 101, name: "plastyka" },
    { id: 102, name: "historia" },
    { id: 103, name: "wiedza o społeczeństwie" },
    { id: 104, name: "biologia" },
    { id: 105, name: "geografia" },
    { id: 106, name: "chemia" },
    { id: 107, name: "fizyka" },
    { id: 108, name: "matematyka" },
    { id: 109, name: "informatyka" },
    { id: 110, name: "technika" },
    { id: 111, name: "zajęcia techniczne" },
    { id: 112, name: "wychowanie fizyczne" },
    { id: 113, name: "edukacja dla bezpieczeństwa" },
    { id: 114, name: "zajęcia z wychowawcą" },
    { id: 115, name: "zajęcia artystyczne" },
    { id: 116, name: "historia sztuki" },
    { id: 117, name: "filozofia" },
    { id: 150, name: "praktyczna nauka zawodu" },
    { id: 201, name: "biznes i zarządzanie" },
    { id: 1001, name: "język chiński" },
    { id: 1002, name: "język czeski" },
    { id: 1003, name: "język angielski" },
    { id: 1004, name: "język francuski" },
    { id: 1005, name: "język niemiecki" },
    { id: 1006, name: "język grecki" },
    { id: 1007, name: "język włoski" },
    { id: 1008, name: "język japoński" },
    { id: 1009, name: "język łacina" },
    { id: 1010, name: "język portugalski" },
    { id: 1011, name: "język rosyjski" },
    { id: 1012, name: "język hiszpański" },
    { id: 1013, name: "język białoruski " },
    { id: 1014, name: "język hebrajski" },
    { id: 1015, name: "język jidysz" },
    { id: 1016, name: "język karaimski" },
    { id: 1017, name: "język kaszubski" },
    { id: 1018, name: "język litewski" },
    { id: 1019, name: "język łemkowski" },
    { id: 1020, name: "język ormiański" },
    { id: 1021, name: "język romski" },
    { id: 1022, name: "język słowacki" },
    { id: 1023, name: "język tatarski" }
];

export const professions = [
    { id: 1, name: "Technik analityk"},
    { id: 2, name: "Technik geodeta"},
    { id: 3, name: "Technik geolog"},
    { id: 4, name: "Technik budownictwa"},
    { id: 5, name: "Technik budownictwa wodnego"},
    { id: 6, name: "Technik inżynierii środowiska i melioracji"},
    { id: 7, name: "Technik renowacji elementów architektury"},
    { id: 8, name: "Technik budowy dróg"},
    { id: 10, name: "Technik inżynierii sanitarnej"},
    { id: 11, name: "Technik robót wykończeniowych w budownictwie"},
    { id: 12, name: "Technik elektroenergetyk transportu szynowego"},
    { id: 13, name: "Technik elektryk"},
    { id: 14, name: "Technik energetyk"},
    { id: 15, name: "Technik automatyk sterowania ruchem kolejowym"},
    { id: 16, name: "Technik elektronik"},
    { id: 17, name: "Technik mechatronik"},
    { id: 18, name: "Technik elektroniki i informatyki medycznej"},
    { id: 19, name: "Technik szerokopasmowej komunikacji elektronicznej"},
    { id: 20, name: "Technik mechanik"},
    { id: 21, name: "Technik pojazdów samochodowych"},
    { id: 22, name: "Technik mechanizacji rolnictwa i agrotroniki"},
    { id: 23, name: "Technik papiernictwa"},
    { id: 24, name: "Technik technologii chemicznej"},
    { id: 25, name: "Technik górnictwa odkrywkowego"},
    { id: 26, name: "Technik górnictwa otworowego"},
    { id: 27, name: "Technik górnictwa podziemnego"},
    { id: 29, name: "Technik odlewnik"},
    { id: 30, name: "Technik przeróbki kopalin stałych"},
    { id: 31, name: "Technik wiertnik"},
    { id: 32, name: "Technik automatyk"},
    { id: 33, name: "Technik garbarz"},
    { id: 34, name: "Technik gazownictwa"},
    { id: 35, name: "Technik obuwnik"},
    { id: 36, name: "Technik pożarnictwa"},
    { id: 37, name: "Technik technologii drewna"},
    { id: 38, name: "Technik technologii szkła"},
    { id: 39, name: "Technik technologii wyrobów skórzanych"},
    { id: 40, name: "Technik transportu drogowego"},
    { id: 41, name: "Technik transportu kolejowego"},
    { id: 42, name: "Technik chłodnictwa i klimatyzacji"},
    { id: 43, name: "Technik urządzeń i systemów energetyki odnawialnej"},
    { id: 44, name: "Technik włókienniczych wyrobów dekoracyjnych"},
    { id: 45, name: "Technik włókiennik"},
    { id: 48, name: "Technik procesów drukowania"},
    { id: 49, name: "Technik procesów introligatorskich"},
    { id: 50, name: "Technik urządzeń dźwigowych"},
    { id: 51, name: "Technik przemysłu mody"},
    { id: 52, name: "Technik budowy jednostek pływających"},
    { id: 53, name: "Technik grafiki i poligrafii cyfrowej"},
    { id: 54, name: "Technik ceramik"},
    { id: 55, name: "Technik architektury krajobrazu"},
    { id: 56, name: "Technik hodowca koni"},
    { id: 57, name: "Technik ogrodnik"},
    { id: 58, name: "Technik pszczelarz"},
    { id: 59, name: "Technik rolnik"},
    { id: 60, name: "Technik rybactwa śródlądowego"},
    { id: 61, name: "Technik leśnik"},
    { id: 62, name: "Technik przetwórstwa mleczarskiego"},
    { id: 63, name: "Technik technologii żywności"},
    { id: 64, name: "Technik mechanik okrętowy"},
    { id: 65, name: "Technik nawigator morski"},
    { id: 66, name: "Technik rybołówstwa morskiego"},
    { id: 67, name: "Technik żeglugi śródlądowej"},
    { id: 68, name: "Technik awionik"},
    { id: 69, name: "Technik mechanik lotniczy"},
    { id: 70, name: "Technik lotniskowych służb operacyjnych"},
    { id: 71, name: "Technik elektroradiolog"},
    { id: 72, name: "Technik sterylizacji medycznej"},
    { id: 73, name: "Technik farmaceutyczny"},
    { id: 74, name: "Protetyk słuchu"},
    { id: 75, name: "Technik dentystyczny"},
    { id: 76, name: "Technik ortopeda"},
    { id: 78, name: "Technik weterynarii"},
    { id: 80, name: "Asystentka stomatologiczna"},
    { id: 81, name: "Higienistka stomatologiczna"},
    { id: 82, name: "Technik optyk"},
    { id: 83, name: "Technik masażysta"},
    { id: 84, name: "Technik bezpieczeństwa i higieny pracy"},
    { id: 85, name: "Technik ochrony środowiska"},
    { id: 86, name: "Opiekunka dziecięca"},
    { id: 87, name: "Ortoptystka"},
    { id: 88, name: "Terapeuta zajęciowy"},
    { id: 89, name: "Technik agrobiznesu"},
    { id: 90, name: "Technik ekonomista"},
    { id: 91, name: "Technik eksploatacji portów i terminali"},
    { id: 92, name: "Technik logistyk"},
    { id: 93, name: "Technik spedytor"},
    { id: 94, name: "Technik reklamy"},
    { id: 95, name: "Technik administracji"},
    { id: 96, name: "Asystent osoby niepełnosprawnej"},
    { id: 97, name: "Opiekun osoby starszej"},
    { id: 98, name: "Opiekun w domu pomocy społecznej"},
    { id: 99, name: "Opiekunka środowiskowa"},
    { id: 100, name: "Fotograf"},
    { id: 101, name: "Technik fotografii i multimediów"},
    { id: 102, name: "Florysta"},
    { id: 104, name: "Technik żywienia i usług gastronomicznych"},
    { id: 109, name: "Asystent kierownika produkcji filmowej / telewizyjnej"},
    { id: 110, name: "Technik teleinformatyk"},
    { id: 111, name: "Technik informatyk"},
    { id: 112, name: "Technik tyflo informatyk"},
    { id: 114, name: "Technik realizacji nagrań"},
    { id: 115, name: "Technik telekomunikacji"},
    { id: 116, name: "Technik prac biurowych"},
    { id: 117, name: "Technik usług pocztowych i finansowych"},
    { id: 118, name: "Technik organizacji turystyki"},
    { id: 119, name: "Technik hotelarstwa"},
    { id: 120, name: "Technik rachunkowości"},
    { id: 121, name: "Magazynier-logistyk"},
    { id: 122, name: "Technik archiwista"},
    { id: 123, name: "Kucharz"},
    { id: 124, name: "Kelner"},
    { id: 125, name: "Fryzjer"},
    { id: 126, name: "Technik usług fryzjerskich"},
    { id: 127, name: "Technik usług kosmetycznych"},
    { id: 129, name: "Jeździec"},
    { id: 130, name: "Sprzedawca"},
    { id: 131, name: "Technik handlowiec"},
    { id: 132, name: "Technik księgarstwa"},
    { id: 133, name: "Opiekun medyczny"},
    { id: 134, name: "Technik ochrony fizycznej osób i mienia"},
    { id: 135, name: "Ogrodnik"},
    { id: 136, name: "Pszczelarz"},
    { id: 137, name: "Rolnik"},
    { id: 138, name: "Rybak śródlądowy"},
    { id: 139, name: "Monter konstrukcji budowlanych"},
    { id: 140, name: "Zdun"},
    { id: 141, name: "Murarz-tynkarz"},
    { id: 142, name: "Kamieniarz"},
    { id: 143, name: "Betoniarz-zbrojarz"},
    { id: 144, name: "Cieśla"},
    { id: 146, name: "Monter nawierzchni kolejowej"},
    { id: 147, name: "Monter budownictwa wodnego"},
    { id: 148, name: "Dekarz"},
    { id: 149, name: "Monter izolacji budowlanych"},
    { id: 150, name: "Monter izolacji przemysłowych"},
    { id: 151, name: "Monter systemów rurociągowych"},
    { id: 152, name: "Monter sieci i instalacji sanitarnych"},
    { id: 153, name: "Monter zabudowy i robót wykończeniowych w budownictwie"},
    { id: 154, name: "Lakiernik samochodowy"},
    { id: 155, name: "Kominiarz"},
    { id: 156, name: "Modelarz odlewniczy"},
    { id: 157, name: "Blacharz"},
    { id: 159, name: "Blacharz samochodowy"},
    { id: 160, name: "Monter kadłubów jednostek pływających"},
    { id: 161, name: "Kowal"},
    { id: 162, name: "Ślusarz"},
    { id: 163, name: "Operator obrabiarek skrawających"},
    { id: 164, name: "Mechanik pojazdów samochodowych"},
    { id: 165, name: "Mechanik motocyklowy"},
    { id: 166, name: "Mechanik-monter maszyn i urządzeń"},
    { id: 168, name: "Mechanik precyzyjny"},
    { id: 169, name: "Optyk-mechanik"},
    { id: 170, name: "Zegarmistrz"},
    { id: 171, name: "Złotnik-jubiler"},
    { id: 172, name: "Koszykarz-plecionkarz"},
    { id: 173, name: "Rękodzielnik wyrobów włókienniczych"},
    { id: 176, name: "Elektryk"},
    { id: 177, name: "Elektromechanik"},
    { id: 178, name: "Elektromechanik pojazdów samochodowych"},
    { id: 179, name: "Elektronik"},
    { id: 180, name: "Mechatronik"},
    { id: 181, name: "Monter sieci i urządzeń telekomunikacyjnych"},
    { id: 182, name: "Przetwórca ryb"},
    { id: 183, name: "Przetwórca mięsa"},
    { id: 184, name: "Cukiernik"},
    { id: 185, name: "Piekarz"},
    { id: 186, name: "Stolarz"},
    { id: 187, name: "Krawiec"},
    { id: 188, name: "Kuśnierz"},
    { id: 189, name: "Tapicer"},
    { id: 190, name: "Garbarz skór"},
    { id: 191, name: "Obuwnik"},
    { id: 192, name: "Kaletnik"},
    { id: 193, name: "Górnik eksploatacji podziemnej"},
    { id: 194, name: "Górnik odkrywkowej eksploatacji złóż"},
    { id: 195, name: "Górnik eksploatacji otworowej"},
    { id: 196, name: "Wiertacz"},
    { id: 199, name: "Operator urządzeń przemysłu chemicznego"},
    { id: 200, name: "Operator maszyn i urządzeń do przetwórstwa tworzyw sztucznych"},
    { id: 201, name: "Operator maszyn w przemyśle włókienniczym"},
    { id: 202, name: "Operator maszyn i urządzeń przemysłu spożywczego"},
    { id: 203, name: "Mechanik-operator maszyn do produkcji drzewnej"},
    { id: 204, name: "Operator urządzeń przemysłu ceramicznego"},
    { id: 205, name: "Operator urządzeń przemysłu szklarskiego"},
    { id: 206, name: "Kierowca mechanik"},
    { id: 207, name: "Mechanik-operator pojazdów i maszyn rolniczych"},
    { id: 208, name: "Operator maszyn leśnych"},
    { id: 209, name: "Operator maszyn i urządzeń do robót ziemnych i drogowych"},
    { id: 210, name: "Pracownik pomocniczy obsługi hotelowej"},
    { id: 211, name: "Pracownik pomocniczy krawca"},
    { id: 212, name: "Pracownik pomocniczy mechanika"},
    { id: 213, name: "Pracownik pomocniczy ślusarza"},
    { id: 214, name: "Pracownik pomocniczy stolarza"},
    { id: 216, name: "Technik budowy i strojenia fortepianów i pianin"},
    { id: 217, name: "Technik realizacji nagłośnień"},
    { id: 218, name: "Monter stolarki budowlanej"},
    { id: 219, name: "Automatyk"},
    { id: 220, name: "Pracownik pomocniczy fryzjera"},
    { id: 221, name: "Górnik podziemnej eksploatacji kopalin innych niż węgiel kamienny"},
    { id: 222, name: "Operator maszyn i urządzeń przeróbczych"},
    { id: 223, name: "Technik podziemnej eksploatacji kopalin innych niż węgiel kamienny"},
    { id: 224, name: "Pracownik obsługi hotelowej"},
    { id: 225, name: "Pracownik pomocniczy gastronomii"},
    { id: 227, name: "Technik turystyki na obszarach wiejskich"},
    { id: 228, name: "Technik usług kelnerskich"},
    { id: 229, name: "Operator maszyn i urządzeń odlewniczych"},
    { id: 230, name: "Operator maszyn i urządzeń przemysłu metalurgicznego"},
    { id: 231, name: "Technik przemysłu metalurgicznego"},
    { id: 233, name: "Drukarz fleksograficzny"},
    { id: 234, name: "Drukarz offsetowy"},
    { id: 235, name: "Operator procesów introligatorskich"},
    { id: 238, name: "Technik programista"},
    { id: 240, name: "Technik budownictwa kolejowego"},
    { id: 241, name: "Monter jachtów i łodzi"},
    { id: 242, name: "Wielozawodowa"},
    { id: 243, name: "Technik spawalnictwa"},
    { id: 244, name: "Zdobnik ceramiki"},
    { id: 245, name: "Technik robotyk"},
    { id: 246, name: "Technik stylista"},
    { id: 247, name: "Technik elektroautomatyk okrętowy"},
    { id: 248, name: "Technik przemysłu jachtowego"},
    { id: 249, name: "Technik kontroli jakości i bezpieczeństwa żywności"},
    { id: 250, name: "Technik obsługi przemysłu targowo-wystawienniczego"},
    { id: 251, name: "Monter konstrukcji targowo-wystawienniczych"},
    { id: 253, name: "Technik dekarstwa"},
    { id: 254, name: "Technik aranżacji wnętrz"},
    { id: 255, name: "Technik automatyki i robotyki"},
    { id: 256, name: "Technik tekstronik"},
    { id: 257, name: "Technik projektant tekstyliów"},
    { id: 258, name: "Technik nowej mobilności"},
    { id: 259, name: "Technik pojazdów kolejowych"},
    { id: 260, name: "Operator maszyn i urządzeń przemysłu drzewnego"},
    { id: 261, name: "Technik elektromobilności"},
    { id: 262, name: "Serwisant sprzętu komputerowego"},
]