import React from "react";
import { useTranslation } from "react-i18next";

import * as Layout from "components/commons/Layout/Layout";
import * as Styled from "./Banner.styles";

import { useParams, useLocation } from "react-router-dom";
import { instanceBannerDict } from "utils/enums";

import { globalConfig } from "configuration/globalConfig";

const Banner = ({
  title,
  description,
  cityImg,
  isOffer,
  instanceId,
  children,
}) => {
  const { t } = useTranslation();
  let { type } = useParams();
  let { pathname } = useLocation();
  pathname = pathname.split("/");
  let regionFromPath, typeFromPath, page, id;
  for( var i = 0 ; i<pathname.length; i++) {
    if (i === 1 && pathname.length === 2) {
      page = pathname[i];
    }
    if (i === 1) {
      regionFromPath = pathname[i];
    }
    if (i === 2) {
      typeFromPath = pathname[i];
    }
    if (i === 3) {
      page = pathname[i];
    }
    if (i === 4) {
      id = pathname[i];
    }
  }
  type = type || typeFromPath;

  const getImage = () => {
    switch (page) {
      case "oprojekcie":
        return "banner1-m.jpg";
      case "pomoc":
        return "banner2-m.png";
      case "start":
        return "banner12-m.png";
      case "dokumenty":
        return "banner13-m.png";
      case "aktualnosci":
        return "banner14-m.png";
      case "kontakt":
        return "banner15-m.png";
      default:
        if (type) return instanceBannerDict[type];
        if (pathname.includes("wdrozenia")) {
          return "banner3-m.png";
        }
        return "banner-universal.png";
    }
  };

  const image = require(`assets/images/banners/${getImage()}`);
  const cityImage = cityImg ? require(`assets/images/banners/${cityImg}`) : "";
  const graphicFrame = require(`assets/images/banners/offer-banner-frame.png`);

  return isOffer ? (
    <Styled.Banner
        className='offer-banner'
    >
      <Styled.BannerContainer>
        <Styled.BannerInfo>
          <Styled.BannerTitle>{title}</Styled.BannerTitle>
          <Styled.BannerDescription>{description}</Styled.BannerDescription>
        </Styled.BannerInfo>
        {children && (
          <>
            <Styled.BannerButtons>
              <Styled.BannerButtonsTitle>
                {t('offer.onPageYoullFind')}
              </Styled.BannerButtonsTitle>
              <Styled.BannerButtonsList>{children}</Styled.BannerButtonsList>
            </Styled.BannerButtons>
            <Styled.BannerFrameWrapper>
              <Styled.BannerImgFrame image={graphicFrame}>
                <Styled.BannerCityImg
                  image={
                    instanceId
                      ? `${globalConfig.get().REACT_APP_OFFER_API_ENDPOINT}/instance/${instanceId}/image`
                      : cityImage
                  }
                />
              </Styled.BannerImgFrame>
            </Styled.BannerFrameWrapper>
          </>
        )}
      </Styled.BannerContainer>
    </Styled.Banner>
  ) : (
    <Styled.BannerBasicWrapper>
      <Styled.BannerBasic image={image} />
    </Styled.BannerBasicWrapper>
  );
};

export default Banner;
