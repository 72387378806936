import { useTranslation } from "react-i18next";

import { Button } from "components/commons";

import { H4 } from "pages/Account/Account.styles";
import * as Styled from "pages/Account/elements/NormalAccount/NormalAccount.styles";

const PasswordInfo = ({ authentication }) => {
    const { t } = useTranslation();

    const changePassword = () => {
        authentication.changePassword();
    }

  return (
    <Styled.PasswordInfoContainer>
      <H4>{t("myData.normalAccount.password")}</H4>
      <p>
      {t("myData.normalAccount.passwordDescription")}
      </p>
      <Button onClick={changePassword}>{t("myData.normalAccount.passwordButton")}</Button>
    </Styled.PasswordInfoContainer>
  );
};

export default PasswordInfo;
