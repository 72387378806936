import React from 'react';
import PropTypes from 'prop-types';
import * as Styled from './Link.styles';

export const Link = ({ 
    active,
    children,
    reverse,
    ...props 
}) => {

  return (
    <Styled.Link
        active={active.toString()}
        reverse={reverse.toString()}
        {...props}
    >
        {children}
    </Styled.Link>
    
  );
};

Link.propTypes = {
  active: PropTypes.bool,
  reverse: PropTypes.bool,
};

Link.defaultProps = {
  active: false,
  reverse: false,
};
