export const galleryBaseWidth = 775;
export const thumbsGap = 15;

export const bigButtonBaseSize = 63;
export const buttonBaseSize = 55;

export const mobileButtonBaseSize = 32;
export const mobileBigButtonBaseSize = 36;

export const baseRadius = 22;
export const mobileRadius = 8;