import styled from "styled-components";

export const UnitGroupPage = styled.div`
  margin-bottom: 18.3rem;
`;

export const UnitGroupSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5.8rem;
  margin: 8.8rem 0;
  width: 50%;
  color: ${({ theme }) => theme.colors.grey90};

  @media ${({ theme }) => theme.device.touch} {
    width: 100%;
  }
`;

export const UnitGroupSection = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 1.2rem;
  gap: 1.5rem;
`;

export const UnitGroupSectionHeading = styled.p`
  position: relative;
  color: ${({ theme }) => theme.colors.grey90};
  font-weight: 600;
  font-size: 2rem;
  padding-bottom: 1.5rem;

  @media ${({ theme }) => theme.device.mobile} {
    font-size: 1.8rem;
    padding-bottom: 1.1rem;
  }

  &::after {
    content: "";
    display: block;
    position: absolute;
    height: 1px;
    bottom: 0;
    background-color: #1a76cb;
    opacity: 0.35;
    right: 0;
    left: 0;

    @media ${({ theme }) => theme.device.mobile} {
      right: -24px;
      left: -24px;
    }
  }
`;

export const UnitGroupBackLink = styled.a`
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
  gap: 0.9rem;

  &:hover {
    text-decoration: underline;
  }
`;

export const UnitGroupDict = styled.div`
  display: grid;
  grid-template-columns: 20rem auto;
  gap: 8px;

  @media ${({ theme }) => theme.device.mobile} {
    grid-template-columns: 1fr;
  }
`;

export const UnitGroupSectionList = styled.ul`
  list-style-type: none;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  font-weight: 600;

  & a:hover {
    text-decoration: underline;
  }
`;
