import {useCallback, useEffect, useState} from 'react';
import { useFormContext } from "react-hook-form";

export const usePeselFiller = ({name, setValue, usingPesel}) => {
    const [delayCounter, setDelayCounter] = useState(0);
    // this is responisible for updating "setVal" with delay when formPesel is updated by useFormFiller
    const { getValues } = useFormContext();

    useEffect(() => {
        if (delayCounter > 0) {
            setValue(getValues(name));
        }
    }, [delayCounter]);


    const handleShortcutClick = useCallback((e) => {
		if(e.ctrlKey && e.shiftKey) {
			setTimeout(() => setDelayCounter(state => state + 1), 200);
		}
	}, []);

    useEffect(()=>{
		if (process.env.REACT_APP_ENV === "production") {
			return;
		};
		
		document.addEventListener("keydown", handleShortcutClick);

		return () => document.removeEventListener("keydown", handleShortcutClick);
	}, [handleShortcutClick]);
}