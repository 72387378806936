import styled from "styled-components";

export const PrivacyPolicy = styled.div`
  ul, ol{
		padding-left: 2rem;
	}
`;

export const AnswerText = styled.div`
	font-size: 1.4rem;
	line-height: 2.2rem;
	color: ${({theme}) => theme.colors.grey90};

	& ul {
		padding-left: 2rem;
	}
`;