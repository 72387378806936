import styled from "styled-components";

import { H4 as CommonH4 } from "styles/common.styles";
import { H5 as CommonH5 } from "styles/common.styles";

export const H4 = styled(CommonH4)`
  color: ${({ theme }) => theme.colors.grey90};
`;

export const H5 = styled(CommonH5)`
  color: ${({ theme }) => theme.colors.grey90};
`;

export const DeleteAccountLink  = styled.button`
  display: inline;
  background: none;
  color: ${({ theme }) => theme.colors.main};
  font: inherit;
  font-weight: 600;
  font-size: 1.8rem;
  text-decoration: none;
  border: none;
  cursor: pointer;
  padding: 0;
  margin: 0;
  width: fit-content;
  height: fit-content;

  &:hover,
  &:focus {
    text-decoration: underline;
  }

  &:focus-visible {
        outline: 1.5px solid ${props => (props.reverse === true ? props.theme.colors.white : props.theme.colors.grey90)};
        outline-offset: 2px;
        padding: 0.1rem;
        border-radius: 5px;
    }
`

export const ModalWrapper = styled.div`
    position: fixed;
    width: 100vw;
    height: 100vh;
    left: 0;
    top: 0;
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${({theme}) => theme.colors.modalTransparentBackground};
`;

export const ModalContainer = styled.div`
    max-width: 82rem;
    width: 90dvw;
    background-color: ${({theme}) => theme.colors.white};
    box-shadow: 0px 0.3rem 1.2rem #00000029;
    border-radius: 2.2rem;
    padding: 4rem;
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    gap: 2rem;

    ${({ isDeleteAccountModal }) =>
  isDeleteAccountModal &&
  `
  width: 600px;
`}
`;

export const ModalInputContainer = styled.div`
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    label {
        color: ${({theme}) => theme.colors.grey90};
        font-size: 1.6rem;
    }

    input {
        width: 100%;
        padding: 1rem;
        border-radius: 1rem;
        border: 0.1rem solid ${({theme}) => theme.colors.grey60};
        font-size: 2.5rem;
        letter-spacing: 0.2rem;
    }

    img{
        position: absolute;
        right: 1.5rem;
        top: 65%;
        transform: translateY(-50%);
        cursor: pointer;
        height: 1.5rem;
    }
`;

export const ModalInfoText = styled.p`
    color: ${({theme}) => theme.colors.black};
    font-size: 1.8rem;
`;

export const ModalTextWarning = styled.p`
    color: ${({theme}) => theme.colors.warning};
    font-size: 1.8rem;
`;

export const CloseButton = styled.button`
    position: absolute;
    right: 1rem;
    top: 1rem;
    cursor: pointer;
    width: fit-content;
    padding: 0px;
    background-color: transparent;
    transition: all 0.2s ease-in-out;
    border: none;
    >img {
        width: 1.6rem;
        background-color: white;
        margin: 1rem;
    }
    &:hover {
        transform: scale(0.9);
    }
`;

export const ModalButtonsContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 2rem;
    justify-content: flex-end;

`;