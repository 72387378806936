import styled from "styled-components";

const PageContainer = styled.div`
    @media ${({theme}) => theme.device.touch} {
        padding-top: 19rem;
    }

    @media ${({theme}) => theme.device.mobile} {
        padding-top: 8rem;
    }
`

export const Page = ({children}) => {
    return <PageContainer>{children}</PageContainer>
}