const ExamRow = (props) => {
	const onPointsChange = (e) => {
		if (e.target.value > 100) e.target.value = 100;
		else if (e.target.value < 0) e.target.value = 0;
		props.onChange(props.id, e.target.value, props.weight);
	}

	function moveFocus(e) {
		var previousElement = e.target.previousElementSibling;
		if (previousElement && previousElement.tagName === 'INPUT') {
			previousElement.focus();
		}
	}

	function passFocus(e) {
		var element = e.target;
		const firstInput = element.querySelector('input');
		if (firstInput) {
			firstInput.focus();
		}
	}

    return (
        <tr>
			<td>{props.children}</td>
			<td></td>
            <td>
                <div className="examWrapper" onClick={passFocus}>
				<input
                    className="exam"
					type="number"
					value={props.pts ? props.pts : 0}
					onChange={onPointsChange}
                    min="0"
					max="100"/>
                <span className="percent" onClick={moveFocus}>%</span>
                </div>
            </td>
        </tr>
    )
}

export default ExamRow;