import React from 'react';
import { useTranslation } from "react-i18next";

import useUnknownCityOrType from "hooks/useUnknownCityOrType";

import DocumentsListing from 'components/documents-page/DocumentsListing/DocumentsListing';
import Breadcrumbs from 'components/commons/Breadcrumbs/Breadcrumbs';
import Banner from 'components/commons/Banner/Banner';
import StaticPage from "components/reusable/StaticPage/StaticPage";

import { Page } from 'components/commons/Page/Page';

const Documents = () => {
    const { t } = useTranslation();
    useUnknownCityOrType();

    return (
        <Page>
            <Breadcrumbs />
            <Banner />
            <StaticPage title={t('documents:title')}>
                <DocumentsListing type={1} />
            </StaticPage>
        </Page>
    )
};

export default Documents;