import React from 'react';

import * as Styled from './Loader.styles';

const Loader = ({isLonger, isLongerOver, text}) => (
        <Styled.Loading className='loader'>
            <Styled.LoadingBox>
                <Styled.LoadingCircle>
                    <Styled.LoadingCircleItem />
                    <Styled.LoadingCircleItem />
                    <Styled.LoadingCircleItem />
                    <Styled.LoadingCircleItem />
                </Styled.LoadingCircle>
                {isLonger && <Styled.LoadingText isLongerOver={isLongerOver}>
                    { text || "Trwa wczytywanie danych, trwa to znacznie dłużej niż zwykle. Prosimy o cierpliwość."}
                </Styled.LoadingText>}
            </Styled.LoadingBox>
        </Styled.Loading>
    )

export default Loader;