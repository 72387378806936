import { memo, useContext, useEffect, useState } from 'react';
import { useFormFiller } from "utils/formFiller/useFormFiller";
import CheckboxForm from '../fields/CheckboxForm';
import * as Styled from '../Application.styles'; 
import { ApplicationContext } from '../Application';

const FormSectionWrapper = ({ 
    section: {section: sectionName, condition, headSection = false, ...sectionData}, 
    showHeader = true,
    collapsed,
    setCollapsed,
    children 
}) => {
    useFormFiller(sectionData.fields, true);
    const { formReadOnly } = useContext(ApplicationContext);

    return (
        <Styled.FormSectionWrapper
            className='form-section-wrapper'
        >
            { sectionName && showHeader && (
                <Styled.FormSectionHeader
                    className='form-section-header'
                    headSection={headSection}
                >
                    {sectionName}
                    {condition && (
                        <CheckboxForm
                            name={condition.name || null}
                            label={condition.label}
                            onChange={(state) => setCollapsed(state)}
                            disabled={formReadOnly}
                        />
                    )}
                </Styled.FormSectionHeader>
            )}            
            
            {!collapsed && (
                <Styled.FormSectionContent 
                    className='form-section-content'
                    columns={2}
                >
                    {children}
                </Styled.FormSectionContent>
            )}
        </Styled.FormSectionWrapper>
    )
};

export default memo(FormSectionWrapper);