import { useMemo, memo } from 'react';
import PropTypes from 'prop-types';
import * as Styled from './Button.styles';

export const Button = memo(({
    reverse = false,
    secondary = false,
    disabled = false,
    className = '',
    children,
    to,
    href,
    ...props
}) => {

    const link = useMemo(() => to || href, [to, href]);
    const buttonClassName = `${className} button-default${secondary ? '-secondary' : ''}${reverse ? '-reverse' : ''}${disabled ? '-disabled' : ''}`
    
    if (link) {
        if (link.startsWith('#')) {
            return (
                <Styled.AnchorLinkButton
                    className={buttonClassName}
                    href={link}
                    reverse={reverse}
                    secondary={secondary}
                    disabled={disabled}
                    {...props}
                >
                    {children}
                </Styled.AnchorLinkButton>
            )
        }
        return (
            <Styled.LinkButton
                className={buttonClassName}
                to={link}
                reverse={reverse}
                secondary={secondary}
                disabled={disabled}
                {...props}
            >
                {children}
            </Styled.LinkButton>
        )
    }
    return (
        <Styled.Button
            className={buttonClassName}
            reverse={reverse}
            secondary={secondary}
            disabled={disabled}
            {...props}
        >
            {children}
        </Styled.Button>
    );
});

Button.displayName = 'Button';

Button.propTypes = {
    reverse: PropTypes.bool,
    secondary: PropTypes.bool,
    disabled: PropTypes.bool,
    className: PropTypes.string,
    children: PropTypes.node,
    to: PropTypes.string,
    href: PropTypes.string
};

Button.defaultProps = {
    reverse: false,
    secondary: false,
    disabled: false,
};
