import React, { useEffect, useState } from 'react';
import * as Styled from './Gallery.styles';
import { globalConfig } from "configuration/globalConfig";
import { imageArrayType } from './types';

import GalleryModal from './GalleryModal';
import GalleryThumbs from './GalleryThumbs';

import imagePlaceholder from 'assets/images/gallery-placeholder.jpg';

export const baseURL = `${globalConfig.get().REACT_APP_OFFER_API_ENDPOINT}/Unit/picture/`;

const Gallery = ( {images} ) => {
    const [currentBigImageIndex, setCurrentBigImageIndex] = useState(0);
    const [bigImageUrl, setBigImageUrl] = useState(() => images[0]?.id ? `${baseURL}${images[0].id}` : imagePlaceholder);    

    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        if (currentBigImageIndex < 0 || currentBigImageIndex > images.length - 1) {
            return setCurrentBigImageIndex(0);
        }
        setBigImageUrl(`${baseURL}${images[currentBigImageIndex].id}`);
    }, [currentBigImageIndex, images]);

    const onHeroNext = () => {
        setCurrentBigImageIndex(state => {
            if (state === images.length - 1) return 0;
            return state + 1;
        });
    }
    const onHeroPrev = () => {
        setCurrentBigImageIndex(state => {
            if (state === 0) return images.length - 1;
            return state - 1;
        });
    }

    return (
        <Styled.GalleryContainer className='gallery-container'>
            {showModal && (
                <GalleryModal
                    images={images.map( ({id}) => {
                        if (!id) return null;
                        return `${baseURL}${id}`;
                    }).filter(thumb => thumb !== null)}
                    startImageIndex={currentBigImageIndex}
                    hideModal={() => setShowModal(false)}
                />
            )}

            <Styled.Hero
                className={(!images || images.length < 1 || !bigImageUrl) ? 'disabled' : ''}
            >
                {images.length > 1 && (
                    <>
                        <Styled.HeroNextBt
                            className='show-on-hover'
                            onClick={onHeroNext}
                        />
                        <Styled.HeroPrevBt
                            className='show-on-hover'
                            onClick={onHeroPrev}
                        />
                    </>
                )}

                {bigImageUrl ? (
                    <>
                        <Styled.HeroShowModalBt
                            className='show-on-hover'
                            onClick={() => setShowModal(true)}
                        />
                        <Styled.HeroImage className='image-wrapper' src={bigImageUrl} alt='big-image' />
                    </>
                ) : (
                    <Styled.HeroImage className='image-wrapper' src={imagePlaceholder} alt='big-image' />
                )}
            </Styled.Hero>

            <GalleryThumbs
                thumbs={images.map( ({id}) => {
                    if (!id) return null;
                    return `${baseURL}${id}`;
                }).filter(thumb => thumb !== null)}
                setCurrentBigImageIndex={setCurrentBigImageIndex}
            />

        </Styled.GalleryContainer>
    );
};

Gallery.propTypes = {
    images: imageArrayType.isRequired,
};

export default Gallery;