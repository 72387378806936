import React, { useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext, Controller } from 'react-hook-form';
import styled, { css } from 'styled-components';

import SelectedIco from 'assets/icons/ico-selected.svg';

import Error from './elements/Error';
import Label from './elements/Label';
import Description from "./elements/Description";

const RadioForm = ({
    name,
    label,
    description,
    options,
    isRequired,
    disabled
}) => {
    const { t } = useTranslation();
    const { control } = useFormContext();

    const radioOptions = useMemo(() => {
        if (options === null || options === undefined) return null;
        if (Array.isArray(options)) return options;
        return Object.keys(options).map(option => {
            return {
                value: options[option].toString(),
                label: option
            }
        })
    }, [options]);

    const validateField = (value) => {
        if (isRequired && value === undefined) {
            return t('application.errors.radioRequired');
        }
        return true;
    }

    return (
        <Wrapper className='radio-form-wrapper'>
            {label && (
                <Label
                    label={label}
                    isRequired={isRequired}
                    htmlFor={name}
                    className='radio-form-label'
                />
            )}
            {description && (
                <Description>{' '}{description}</Description>
            )}
            <Controller
                control={control}
                name={name}
                rules={{ validate: validateField }}
                render={({ field, fieldState }) => (
                    <>
                        <RadioOptions
                            field={field}
                            options={radioOptions}
                            disabled={disabled}
                            displayInRows={false}
                        />
                        <Error error={fieldState.error} />
                    </>
                )
                }
            />
        </Wrapper>
    )
}
export default RadioForm;


export const RadioOptions = ({
    field, 
    options, 
    disabled=false,
    displayColumn=false,
    onChange=null,
}) => {

    const { value: fieldValue, name: fieldName, onChange: fieldOnChange } = field;

    const disabledSelectedOption = useMemo(() => {
        if (disabled) {
            const val = typeof fieldValue === 'object' ? (fieldValue?.value || null) : (fieldValue || null);
            const found = options.find(option => option.value === val);
            return {
                label: found?.label || 'Nie zaznaczono żadnej opcji',
                found: !!found
            };
        }
        return {
            label: 'Nie zaznaczono żadnej opcji',
            found: false
        };
    }, [disabled, options, fieldValue]);

    return (
        <OptionsWrapper
            displayColumn={displayColumn}
            className='options-wrapper'
            disabled={disabled}
        >
            {!disabled && options.map(option => (
                <RadioOption
                    key={`${field.name}-${option.value}`}
                    disabled={disabled}
                >
                    <input
                        type="radio"
                        id={`${field.name}-${option.value}`}
                        // {...field}
                        value={option.value}
                        checked={fieldValue === option.value || fieldValue?.value === option.value}
                        disabled={disabled}
                        onChange={(ev) => {
                            if (onChange) onChange(option.value);
                            return fieldOnChange(option.value);
                        }}
                    />
                    <RadioLabel
                        htmlFor={`${fieldName}-${option.value}`}
                        className='radio-label'
                    >
                        {option.label}
                    </RadioLabel>
                </RadioOption>
            ))}
            { disabled && disabledSelectedOption && (
                <DisabledSelectedItem 
                    className='disabled-selected-item'
                >
                    {disabledSelectedOption.found && (<img src={SelectedIco} alt="selected" />)}
                    <p>{disabledSelectedOption.label}</p>
            </DisabledSelectedItem>
            )}
        </OptionsWrapper>
    )
}

const DisabledSelectedItem = styled.div`
    display: flex;
    gap: 2rem;
    align-items: center;
    padding-left: 2rem;

    >p {
        font-size: 1.6rem;
        font-weight: 700;
    }
`;

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    font-size: 1.4rem;
    font-weight: 400;
    color: ${({ theme }) => theme.colors.black};
    position: relative;
`;

const OptionsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 0.8rem;
    gap: 0.5rem;
    font-size: 1.5rem;
    ${({ disabled }) => disabled && css`
        
    `}
    /* ${({ displayColumn }) => !displayColumn && css`
        @media ${({ theme: { device } }) => device.desktop} {
            flex-direction: row;
            gap: 1.5rem;
        }
    `} */
`;

const RadioOption = styled.div`
    cursor: pointer;
    padding: 0.5rem;
    text-indent: -2rem;
    margin-left: 2rem;
    
    &::first-line {
        text-indent: 0;
    }

    >* {
        cursor: pointer;
    }
    ${({ disabled }) => disabled && css`
        cursor: unset;
        >* {
            cursor: unset;
        }
    `}
`;

const RadioLabel = styled.label`
    margin-left: 1rem;
`;