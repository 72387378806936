import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import parse from "html-react-parser";
import { getDate } from 'utils/utils';
import useApi from "hooks/useApi";

import { useInstance } from 'hooks/useInstance';

import * as Styled from './NewsListing.styles';
import * as Layout from 'components/commons/Layout/Layout';
import * as Text from 'components/commons/Text/Text';

const NewsListing = () => {
    const {t} = useTranslation();
    const [ news, setNews ] = useState([]);    
    const {id: instanceId} = useInstance([{name: t('breadcrumbs.news')}]);
    const { offerApi } = useApi();

    const getNews = async (id) => {
        const { data } = await offerApi.get(`instance/${id}/news`);
        setNews(data);
    }

    useEffect(() => {
        if (instanceId){
            getNews(instanceId);
        }
    }, [instanceId]);    

    return (
        <Styled.NewsListing>
            <Layout.Container narrowmore>  
                <Text.TitleMain>{t('breadcrumbs.news')}</Text.TitleMain>

                {news.length ? (
                    <Styled.News>
                    {news.map((item) => (
                        <Styled.NewsItem key={item.id}>
                            <Styled.NewsItemHead>
                                <Text.Title bold>{item.name}</Text.Title>
                            </Styled.NewsItemHead>
                            <Styled.NewsItemSubhead>
                                <Text.Content>{getDate(item.createdDate)}</Text.Content>
                            </Styled.NewsItemSubhead>
                            <Styled.NewsItemContent>
                                <Text.Content>{parse(item.content)}</Text.Content>
                            </Styled.NewsItemContent>
                        </Styled.NewsItem>
                    ))}
                    </Styled.News>
                ) : (
                    <div></div>
                )}
                
            </Layout.Container>
        </Styled.NewsListing>
    )
};

export default NewsListing;