import React from 'react';
import { createPortal } from 'react-dom';
import { createGlobalStyle } from 'styled-components';
import closeIcon from 'assets/icons/ico-close-2.svg';

import * as Styled from './Modal.styles';

const BodyBlockScroll = createGlobalStyle`
  body {
    overflow: hidden;
  }
`;

const Modal = ({
    closeModal,
    children,
    closeOnBackground = true,
    wrapModal = false,
    closeButton = false,
    isDeleteAccountModal = false,
    className
}) => {
    return (
        createPortal(
            <Styled.ModalWrapper
                onClick={closeOnBackground ? () => {
                    closeModal();
                } : null}
            >
                <BodyBlockScroll />
                {wrapModal ? (
                    <Styled.ModalContainer
                    isDeleteAccountModal={isDeleteAccountModal}
                    className={className}
                        onClick={(ev) => {
                            ev.stopPropagation();
                            if (ev.target === ev.currentTarget) {
                                ev.preventDefault();
                            }
                        }}
                    >
                        {closeButton && (
                            <Styled.CloseButton
                                onClick={closeModal}
                            >
                                <img src={closeIcon} alt='close-modal' />
                            </Styled.CloseButton>
                        )}
                        {children(closeModal)}
                    </Styled.ModalContainer>
                ) : (<>
                    {children(closeModal)}
                </>
                )}

            </Styled.ModalWrapper>
            ,
            document.body
        )
    );
}

export default Modal;