import React, { useCallback, useMemo, memo } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import useFiltersInitial from "./useFiltersInitial";

import { UnitsFiltersForm } from "./UnitsFiltersForm";

const UnitsFilters = ({ 
    instance, 
    units,
}) => {
    const navigate = useNavigate();
    const { search: searchParams } = useLocation();
    
    const { 
        formInitialValues, 
        currentInstanceFilters, 
        filtersExpandedOnStartup 
    } = useFiltersInitial({ instance, units });

    const additionalSearchParams = useMemo(() => {
        const filtered = Object.fromEntries(new URLSearchParams(searchParams));
        for (const key in formInitialValues) {
            delete filtered[key];
        }
        return filtered;
    }, [searchParams, formInitialValues]);

    const navigateToFilteredURL = useCallback((currentFiltersObject) => {
        const query = Object.keys(currentFiltersObject).reduce((acc, key) => {
            return `${acc}${(acc && acc.length > 0) ? '&' : ''}${key}=${currentFiltersObject[key].replace(' ', '%20')}`
        }, '');
        navigate(`${(query) && "?"}${query}`, { replace: true });
    }, [navigate]);


    const onFilter = useCallback((filterObject) => {
        const currentFilters = {
            ...filterObject,
            ...additionalSearchParams
        };
        navigateToFilteredURL(currentFilters);
    }, [navigateToFilteredURL, additionalSearchParams]);

    if ((!formInitialValues) || (units.length < 2)) {
        return null;
    }

    return (
        <UnitsFiltersForm
            currentInstanceFilters={currentInstanceFilters}
            defaultValues={formInitialValues}
            onFilter={onFilter}
            filtersExpandedOnStartup={filtersExpandedOnStartup}
        />
    );
};
export default memo(UnitsFilters);