import styled, { css } from "styled-components";
import { Button } from "components/commons/Button/Button.styles";
import { Link } from "react-router-dom";
import { H2 as CommonH2 } from "styles/common.styles";
import { H3 as CommonH3 } from "styles/common.styles";
import { H4 as CommonH4 } from "styles/common.styles";
import { H5 as CommonH5 } from "styles/common.styles";

export const H2 = styled(CommonH2)`
  font-size: 2.4rem;
  color: ${({ theme }) => theme.colors.black};
  margin-top: 2rem;

  &.instance-name {
    font-size: 2.6rem;
    font-weight: 400;
  }
  &.child-name-desktop {
    ::first-letter {
      text-transform: uppercase;
    }
  }
`;

export const H3 = styled(CommonH3)`
  font-size: 2rem;
  margin: 4rem 0;
  color: ${({ theme }) => theme.colors.black};
`;

export const H4 = styled(CommonH4)`
  font-size: 1.8rem;
  color: ${({ theme }) => theme.colors.main};

  &.link-applications-mobile {
    cursor: pointer;
    
    :hover {
      text-decoration: underline;
    }
  }
`;

export const H5 = styled(CommonH5)`
  font-size: 1.8rem;
  color: ${({ theme }) => theme.colors.black};
  &.children-overview-title {
    margin-bottom: 5rem;
  }

  &.children-name-mobile {
    padding: 2rem;
    position: sticky;
    top: 80px;
    z-index: 999;
    background-color: white;
  }

  &.child-name-mobile {
    padding: 2rem;
    ::first-letter {
      text-transform: uppercase;
    }
  }
`;

export const ApplicationListMain = styled.main`
  margin-bottom: 10rem;

  & .collapse-item {
    margin: 0 -1.5rem;
    @media ${({ theme: { device } }) => device.tablet} {
      margin: 0;
    }
  }
  & .collapse-children-wrapper {
    padding: 0 1.5rem;
    @media ${({ theme: { device } }) => device.tablet} {
      padding: 0;
    }
  }

  & .collapse-label {
    font-size: 1.8rem;
    align-items: center;
    padding-left: 1.5rem;
    @media ${({ theme: { device } }) => device.tablet} {
      font-size: 2rem;
      padding-left: 0;
    }
  }
`;

export const ApplicationsHeader = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  gap: 3rem;
  margin-bottom: 6rem;
  @media ${({ theme: { device } }) => device.tablet} {
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;
    gap: unset;
  }

  @media ${({ theme: { device } }) => device.touchOnly} {
    margin-top: 200px;
  }
`;

export const ApplicationsHeadTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
  `;

export const ApplicationsHeadTitle = styled.h1`
  font-size: 2rem;
  color: ${({ theme }) => theme.colors.black};
  @media ${({ theme: { device } }) => device.tablet} {
    font-size: 2.6rem;
  }
`;

export const ApplicationItem = styled.div``;

export const NoApplicationsInfo = styled.div`
  font-size: 2rem;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.black};
`;

export const LoadingError = styled(NoApplicationsInfo)`
  color: ${({ theme }) => theme.colors.warning};
`;

export const ApplicationsModuleHeader = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 1.9rem;
  color: ${({ theme }) => theme.colors.black};
  margin-bottom: 3rem;
  & .text-bold {
    font-weight: 600;
  }
  & .select-label {
    font-size: 1.6rem;
    font-weight: 600;
    min-width: fit-content;
    margin-bottom: 0.5rem;
    @media ${({ theme: { device } }) => device.tablet} {
      margin-bottom: 0;
    }
  }

  @media ${({ theme: { device } }) => device.desktop} {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`;

export const SelectWithLabel = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.main};
  height: inherit;
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  padding-top: 2rem;
  @media ${({ theme: { device } }) => device.tablet} {
    flex-direction: row;
    gap: 20px;
    align-items: center;
  }
  @media ${({ theme: { device } }) => device.desktop} {
    border-top: none;
    margin-top: 0;
    padding-top: 0;
  }
`;

export const LinkButton = styled(Button).attrs({ as: Link })`
  @media ${({ theme: { device } }) => device.mobile} {
    display: block;
    width: 28rem;
    height: 6rem;
    justify-content: center;
    align-content: center;
    margin: 2rem auto 2rem auto;
  }
`;

export const ApplicationsList = styled.ul`
  list-style-type: none;
`;

export const RecruitmentItem = styled.li`
  text-decoration: none;
  margin-top: 2rem;
  padding-bottom: 2rem;
  border-bottom: 1px solid ${({ theme }) => theme.colors.labelBorder};
  font-weight: 600;
  a {
    font-size: 2rem;
    :focus-visible {
      outline: 1px solid ${({ theme }) => theme.colors.grey90};
      outline-offset: 2px;
      padding: 0.5rem;
      border-radius: 5px;
    }
    @media ${({ theme: { device } }) => device.mobile} {
      font-size: 1.6rem;
    }
  }
`;

// Styling for ChildrenOverview
export const ChildrenOverviewWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 4rem;
  align-items: center;
  padding-bottom: 5rem;
  border-bottom: 2px solid ${({ theme }) => theme.colors.borderChildrenOverview};

  &.children-overview {
    border-bottom: none;
  }
  @media ${({ theme: { device } }) => device.mobile} {
    flex-direction: column;
    gap: 2rem;
    align-items: flex-start;
  }
`;

export const ChildProfileWrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 1rem;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    img {
        width: 105px;
        height: 105px;
        scale: 1;
        transition: scale 0.3s cubic-bezier(.24,-0.09,.75,1.75);
    }

    @media ${({ theme: { device } }) => device.mobile} {
        flex-direction: row;
        gap: 2rem;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: nowrap;

        img {
            width: 50px;
            height: 50px;
        }
    }

    ${({ isIdle }) => isIdle && css`
        img {
            scale: 0.8;
        }
    `}
`;

export const ChildProfileInfo = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 1rem;
  align-items: center;
  justify-content: center;

  p {
    font-size: 1.4rem;
    color: ${({ theme }) => theme.colors.black};
  }

  @media ${({ theme: { device } }) => device.mobile} {
    flex-direction: column;
    gap: 1rem;
    align-items: flex-start;

    h4{
      flex-wrap: wrap;
    }
  }
`;

export const ChildProfileContainer = styled.div``;

// Mobile styles
/// ChildrenOverview isMobile///

export const ChildrenOverviewMobile = styled.div``;

export const ChildrenOverviewHeadingMobile = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2rem 0 2rem 2rem;
  border-bottom: 2px solid ${({ theme }) => theme.colors.borderChildrenOverview};
`;

export const ChildrenOverviewButtonContainerMobile = styled.div`
display: flex;
justify-content: center;
align-items: center;
align-self: center;
  `; 
