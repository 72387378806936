import { useTranslation } from "react-i18next";

import { H4 } from "pages/Account/Account.styles";
import * as Styled from "pages/Account/elements/NormalAccount/NormalAccount.styles";

const EmailInfo = ({ userEmail }) => {
  const { t } = useTranslation();

  return (
    <Styled.EmailInfoContainer>
      <H4>{t("myData.normalAccount.email")}</H4>
      <p>
      {t("myData.normalAccount.emailDescription")}{" "}
      </p>
      <Styled.EmailInputContainer>
        <label htmlFor="email" >{t("myData.normalAccount.emailLabel")}</label>
        <input type="email" value={userEmail} disabled id="email" />
      </Styled.EmailInputContainer>
    </Styled.EmailInfoContainer>
  );
};

export default EmailInfo;
