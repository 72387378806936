import React, { useEffect, useContext } from "react";
import { useTranslation, Trans } from "react-i18next";

import Breadcrumbs from "components/commons/Breadcrumbs/Breadcrumbs";
import Collapse from "components/commons/Collapse/Collapse";

import { AppContext } from "context/appContext";

import StaticPage from "components/reusable/StaticPage/StaticPage";

import Banner from "components/commons/Banner/Banner";

import styled from "styled-components";

const AnswerText = styled.div`
  font-size: 1.4rem;
  line-height: 2.2rem;
  color: ${({theme}) => theme.colors.grey90};

  & ul {
    padding-left: 2rem;
  }
`;

const About = () => {
  const { t } = useTranslation();

  const { setBreadcrumbsPath } = useContext(AppContext);

  useEffect(() => {
    setBreadcrumbsPath([
      {
        name: t("breadcrumbs.about"),
      },
    ]);
  }, []);

  return (
    <>
      <Breadcrumbs />
      <Banner />
      <StaticPage title={t("menu.about")}>
        {t("about:questions", { returnObjects: true }).map((item) => (
          <Collapse title={item.title} collapsed={true} key={item.title} id={item.title}>
            <AnswerText>
              <Trans
                i18nKey={item.content}
                components={{ br: <br />, ul: <ul></ul>, li: <li /> }}
              />
            </AnswerText>
          </Collapse>
        ))}
      </StaticPage>
    </>
  );
};

export default About;
