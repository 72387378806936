import { memo, useMemo, useState, useContext, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext, Controller } from 'react-hook-form';
import * as Styled from '../Application.styles';
import SectionFactory from '../sections/SectionFactory';
import { ApplicationContext } from '../Application';

import { RadioOptions } from '../fields/RadioForm';

const PersonalModule = ({ data, disabled }) => {
    // contexts:
    const { unregister, watch } = useFormContext();
    
    const { formValidationMethods: 
        { 
            removeFieldFromValidation, 
            addFieldToValidation
        },
        setFormSaveable,
    } = useContext(ApplicationContext);

    const motherSingleParent = watch('Mother_SingleParent');
    const fatherSingleParent = watch('Father_SingleParent');
    
    const [motherJuridicalOptions, setMotherJuridicalOptions] = useState(null);
    const [fatherJuridicalOptions, setFatherJuridicalOptions] = useState(null);
    
    const formSaveableData = watch(['FirstName', 'LastName', 'Pesel', 'IdentityNr', 'BirthDate' ]);

    useEffect(() => {
        if ( !!formSaveableData[0] 
            && !!formSaveableData[1] 
            && (!!formSaveableData[2] || (!!formSaveableData[3] && !!formSaveableData[4]))) 
        {
            setFormSaveable(true);
        } else {
            setFormSaveable(false);
        }
    }, [formSaveableData]);


    const removeFieldsFromValidation = useCallback((fields) => {
        fields.forEach(field => {
            removeFieldFromValidation(field);
            unregister(field);
        });
    }, [ unregister, removeFieldFromValidation ]);


    const addAllFieldsToValidation = useCallback((sections) => {
        sections.forEach((section) => {
            section.fields.forEach((field) => {
                addFieldToValidation(field.name);
            });
        });
    }, [ addFieldToValidation ]);
        

    const filterSectionsByPrefix = useCallback((sections, prefix, exeptions) => {
        let unregisterFieldsArr = [];
        const newSections = sections.filter(
            (section) => {
                if (section.fields.some(field => field.name.includes(prefix)) ) {
                    // unreginster all fields from this section
                    section.fields.forEach(field => {
                        if (!exeptions.includes(field.name)) {
                            unregisterFieldsArr.push(field.name);
                        }
                    });
                    return false;
                }
                return true;
            }
        );
        
        // invoke function with a little delay in order to avoid unregistering fields that are still in view
        setTimeout(() => {
            removeFieldsFromValidation( [...unregisterFieldsArr, `${prefix}Id_Street`, `${prefix}Id_City`]);
        }, 30);
        
        return newSections;
    }, []);

    const getParentJuridicalOptions = useCallback((fields) => {
        // lodz / bialystok zlobki case - when user decides to not provide one of the parents, additional radio must appeer with reason for not-providing - parent is dead, uknkown and so on. Function filters only other fields and at the same time sets new options for main cause (setMotherJuridicalOptions or setFatherJuridicalOptions - both send to ParentsSelector which is responsible for rendering radio as wee
        const fieldsToExcludeIds = ['6', '7', '66'];
        
        if (fields.some( ({name}) => name === "Mother_Id_DictParentJuridicalStatus" || name === 'Father_Id_DictParentJuridicalStatus')) {
            const newFields = fields.reduce( (acc, field) => {
                if (field.name === "Mother_Id_DictParentJuridicalStatus" || field.name === 'Father_Id_DictParentJuridicalStatus') {
                    let newOptions;
                    if (!Array.isArray(field.options)) {
                        newOptions = Object.keys(field.options).map((key) => ({label: key, value: field.options[key]}));
                    } else {
                        newOptions = field.options;
                    }

                    // exclude fields to motherJuridicalOptions or fatherJuridicalOptions
                    const excluded = newOptions.filter(({value}) => fieldsToExcludeIds.includes(value));

                    const filteredOptions = newOptions.filter(({value}) => !fieldsToExcludeIds.includes(value));

                    if (field.name === "Mother_Id_DictParentJuridicalStatus") {
                        setMotherJuridicalOptions(excluded);
                    }
                    if (field.name === "Father_Id_DictParentJuridicalStatus") {
                        setFatherJuridicalOptions(excluded);
                    }
                    return [...acc, {...field, options: filteredOptions}];
                }
                return [...acc, field];
            }, []);
            return newFields;
        }
        return fields;
    }, []);


    const sections = useMemo(() => {
        if (!data?.sections || data.sections.length === 0) return [];

        // if section has fields that includes 'FirstName' and 'LastName' - add headSection property
        const currentSections = data.sections.map((section) => {
            let { fields } = section;

            // const filteredByCause = getParentJuridicalOptions(fieldCopy);
            fields = getParentJuridicalOptions(fields);

            section = {...section, fields: fields};

            if (fields.some(({ name }) => name.includes('FirstName')) && fields.some(({ name }) => name.includes('LastName'))) {
                return { ...section, headSection: true }
            }

            return {...section};
        });

        addAllFieldsToValidation(currentSections);

        if (motherSingleParent === true) return filterSectionsByPrefix(currentSections, 'Father_', ['Father_Id_DictParentJuridicalStatus']);
        if (fatherSingleParent === true) return filterSectionsByPrefix(currentSections, 'Mother_', ['Mother_Id_DictParentJuridicalStatus']);

        return currentSections;
    }, [ data, motherSingleParent, fatherSingleParent ]);

    return (
        <>
            <ParentsSelector
                disabled={disabled}

                noMotherOptions = {motherJuridicalOptions}
                noFatherOptions = {fatherJuridicalOptions}

                motherSingleParent={motherSingleParent}
                fatherSingleParent={fatherSingleParent}
            />
            <SectionsList 
                sections={sections} 
                disabled={disabled}
            />
        </>
    );
}

export default memo(PersonalModule);


const SectionsList = memo(({sections, disabled}) => {
    return (
        <div 
            className='personal-module-sections-wrapper'
        >            
            {sections.map((section, index) => {
                return (
                    <SectionFactory
                        section={section}
                        key={`${section.type}__${index}`}

                        disabled={disabled}
                    />
                )
            })}
        </div>
    )
});

const ParentsSelector = ({ 
    disabled, 
    noMotherOptions,
    noFatherOptions,

    motherSingleParent,
    fatherSingleParent,
}) => {
    const { setValue } = useFormContext();

    useEffect(() => {
        console.log("MOTHER SINGLE PARENT: ", motherSingleParent, " FATHER SINGLE PARENT: ", fatherSingleParent);
    }, []);

    const { t } = useTranslation();
    const parentsSelectOptions = useMemo(() => [
        { label: t('application.personal.personalModuleParentsStateSelector.option0'), value: 0 },
        { label: t('application.personal.personalModuleParentsStateSelector.option1'), value: 1 },
        { label: t('application.personal.personalModuleParentsStateSelector.option2'), value: 2 },
    ], []);

    const localOnChange = (val) => {
        const value = val?.value ? val.value : val;
        setValue('Mother_SingleParent', value.toString() === '1' ? true : null, { shouldDirty: true });
        setValue('Father_SingleParent', value.toString() === '2' ? true : null, { shouldDirty: true });
    }

    return (
        <Styled.ParentsSelectorWrapper>
            <Styled.PersonalDataDescription>
                <p>{t('application.personal.personalHead')}</p>
                <p><span>{t('application.personal.personalDescription')}</span></p>
            </Styled.PersonalDataDescription>
            <Styled.ParentsSelectWrapper>
                <RadioOptions
                    field={{
                        name: 'personalModuleParentsStateSelector',
                        value: motherSingleParent === true ? 1 : fatherSingleParent === true ? 2 : 0,
                        onChange: localOnChange,
                    }}
                    options={parentsSelectOptions}
                    displayColumn={true}
                    disabled={disabled}
                />
            </Styled.ParentsSelectWrapper>

            { motherSingleParent === true && noFatherOptions && noFatherOptions.length > 0 && (
                <NoParentReason
                    options={noFatherOptions}
                    relevantId={'Father_Id_DictParentJuridicalStatus'}
                />
            )}
            { fatherSingleParent === true && noMotherOptions && noMotherOptions.length > 0 && (
                <NoParentReason
                    options={noMotherOptions}
                    relevantId={'Mother_Id_DictParentJuridicalStatus'}
                />
            )}
        </Styled.ParentsSelectorWrapper>
    )
}

const NoParentReason = ({options, relevantId}) => {
    const { t } = useTranslation();
    const { control, getValues, setValue } = useFormContext();

    useEffect(() => {
        const startingVal = getValues(relevantId);
        if (startingVal && options?.length > 0){
            const foundOption = options.find(({value}) => value.toString() === startingVal.toString());
            if (foundOption) {
                setValue(relevantId, foundOption);
            }
        }
    }, []);

    return (
        <Styled.NoParentReasonWrapper>
            <Styled.PersonalDataDescription>
                <p>{t('application.personal.noParentReason.header')}</p>
                <p><span>{t('application.personal.noParentReason.description')}</span></p>
            </Styled.PersonalDataDescription>
            <Styled.ParentsSelectWrapper>
                <Controller
                    name={relevantId}
                    control={control}
                    render={({ field: { value, onChange } }) => {
                        const localOnChange = (ev) => {
                            const foundOption = options.find(({value}) => value.toString() === ev.target.value.toString());
                            if(foundOption) onChange(foundOption);
                        }
                        
                        // return <p>PLiszka</p>
                        return (
                            <RadioOptions
                                field={{
                                    name: relevantId,
                                    value: value?.value || null,
                                    onChange: localOnChange
                                }}
                                options={options}
                                displayColumn={true}
                            />
                        )
                    }}
                />
                
            </Styled.ParentsSelectWrapper>
        </Styled.NoParentReasonWrapper>
    )
}