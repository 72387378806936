const routes = {
    home: '/',

    auth: {
        signInOidc: 'signin-oidc'
    },

    about: '/oprojekcie',
    help: '/pomoc',
    contact: '/kontakt',
    privacyPolicy: '/polityka-prywatnosci',
    accessDeclaration: '/deklaracja-dostepnosci',

    allInstances: {
        all: '/wdrozenia',
        type: ':type'
    },

    myAccount: {
        applicationsList: '/:city/:generalType/wnioski',
        declaration: '/:city/:generalType/deklaracja/:appId?',
        submission: '/:city/:generalType/zgloszenie/:appId?',
        application: '/:city/:generalType/wniosek/:appId?',
        appeal: '/:city/:generalType/odwolanie/:appId?',
        account: '/:city/:generalType/konto',
    },

    mail: {
        verification: '/:city/:generalType/verify/:appIdGuid/:timeGuid',
    },

    city: {
        main: ':city',
        instance: ':city/:generalType',
        unit: ':city/:generalType/info/:uid',
        branch:':city/:generalType/info/oddzial/:uid',
        unitGroup:':city/:generalType/zespol/:uid',
        documents: ':city/:generalType/dokumenty',
        news: ':city/:generalType/aktualnosci',
        contact: ':city/:generalType/kontakt',
        faq: ':city/:generalType/faq',
        wybor: ':city/:generalType/wybor',
        start: ':city/:generalType/start/:appType',
        // podanie: ':city/:generalType/podanie/:id',
        offer: ':city/:generalType/oferta',
        help: ':city/:generalType/pomoc',
        calculator: ':city/:generalType/kalkulator',
        unknown: ':city/:generalType/:unknown',
    }
}

export default routes;
