import styled from 'styled-components';

// Modal
export const ModalContent = styled.section`
    color: ${({ theme }) => theme.colors.black};
`;

export const ModalLoader = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-color: #ffffffaa;
    z-index: 300;
`;

export const ModalHead = styled.h2`
    font-size: 2rem;
    margin-bottom: 1.6rem;
`;
export const ModalSubheader = styled.p`
    font-size: 1.6rem;
    font-weight: 600;
`;

export const ModalForm = styled.form`
    display: flex;
    flex-direction: column;
    gap: 2rem;
`;

export const Asterisk = styled.span`
    color: ${({ theme }) => theme.colors.warning};
`;

export const ModalSelectWrapper = styled.div`
    position: relative;
    min-height: 7rem;
    & .select-label {
        font-size: 1.6rem;
    }
    & .modal-select {
        position: absolute;
        width: 100%;
    }
`;

export const ModalButtonsWrapper = styled.div`
    display: flex;
    gap: 2rem;
    justify-content: flex-end;
`;

export const ModalInfo = styled.p`
    font-size: 1.4rem;
    margin: 0.6rem 0;
    >span {
        font-weight: 700;
    }
`;
