import styled from 'styled-components';

export const Table = styled.div`
    display: table;
    width: 100%;
    margin-top: 50px;

    @media ${props => props.theme.device.mobile} {
        display: block;
    }
`;

export const TableHead = styled.div`
    display: table-header-group;
    background-color: ${props => props.theme.colors.main};

    @media ${props => props.theme.device.mobile} {
        display: block;
    }

    & > div {
        padding: 24px;

        
        @media ${props => props.theme.device.mobile} {
            display: none;
            padding: 12px;

            &:first-child {
                display: block;
            }
        }

       
    }
`;

export const TableRow = styled.div`
    display: table-row;

    @media ${props => props.theme.device.mobile} {
        display: flex;
        flex-direction: column-reverse;
        border-bottom: 1px solid ${props => props.theme.colors.main}35;
        padding: 12px;
    }

    & > div {

        @media ${props => props.theme.device.tablet} {
            border-bottom: 1px solid ${props => props.theme.colors.main}35;
        }
    }
        
    }
`;

export const TableCol = styled.div`
    display: table-cell;
    padding: 18px 20px;

    @media ${props => props.theme.device.mobile} {
        display: block;
        padding: 0px;
    }
`;

