import React, { useState, useEffect, useMemo, useCallback, useContext } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { AppContext } from "context/appContext";
import { getInstance } from "utils/utils";

import Breadcrumbs from "components/commons/Breadcrumbs/Breadcrumbs";
import Loader from "components/commons/Loader/Loader";
import ModalConfirm from "components/commons/Modal/ModalConfirm";

import useApi from "hooks/useApi";

import { orderby } from "utils/utils";

import { useInstance } from "hooks/useInstance";

import * as Styled from "./ApplicationList.styles";
import * as Layout from "components/commons/Layout/Layout";

import useIsMobile from "hooks/useIsMobile";

import Authorize from "components/authorize/Authorize";

import OtherRecruitmentApplications from "./elements/OtherRecruitmentApplications";
import ChildrenOverview from "./elements/ChildrenOverview/ChildrenOverview";

// import { mock } from './Application/mock'

const ApplicationList = () => {
  const { t } = useTranslation();
  const isMobile = useIsMobile();
  const { candidateApi } = useApi();

  const { city, generalType } = useParams();

  const [applicationsData, setApplicationsData] = useState();

  const [loading, setLoading] = useState(false);
  const [loadingError, setLoadingError] = useState();
  const [applicationOperationConfirmModal, setApplicationOperationConfirmModal] =
    useState();

  const { id: instanceId } = useInstance([
    { name: t("breadcrumbs.myApplications") },
  ]);

  const getApplicationList = useCallback(async (id) => {
    setLoading(true);
    try {
      const { data: appList, error } = await candidateApi.get(
        `Application/${id}/list`
      );
      if (appList) {
        setApplicationsData(appList);
      }
    } catch (err) {
      setLoadingError(err);
      throw Error("ApplicationList fetch: ", err);
    } finally {
      setLoading(false);
    }
  }, [candidateApi]);

  const onDeleteApplication = ({ id, name }) => {
    setApplicationOperationConfirmModal({
      title: t(
        "myAccount.submittedApplications.application.removeApplicationModal.title"
      ),
      text: `${t(
        "myAccount.submittedApplications.application.removeApplicationModal.description"
      )} ${name}?`,
      id,
      confirmText: t(
        "myAccount.submittedApplications.application.removeApplicationModal.text"
      ),
      onConfirm: async (id) => {
        try {
          const done = await candidateApi.delete(`Application?id=${id}`);
          if (done) {
            setApplicationsData();
            await getApplicationList(instanceId);
          }
        } catch (err) {
          throw new Error("Delete application Error: ", err);
        } finally {
          setApplicationOperationConfirmModal(null);
        }
      }
    });
  };

  const onSubmitApplication = ({ id, name }) => {
    setApplicationOperationConfirmModal({
      title: t(
        "myAccount.submittedApplications.application.submitApplicationModal.title"
      ),
      text: `${t(
        "myAccount.submittedApplications.application.submitApplicationModal.description"
      )} ${name}?`,
      id,
      confirmText: t(
        "myAccount.submittedApplications.application.submitApplicationModal.text"
      ),
      onConfirm: async (id) => {
        try {
          var response = await candidateApi.post(`application/${id}/verify`);
          if (response.data) {
              if (response.data.success) {
                setApplicationsData();
                await getApplicationList(instanceId);
              } else {
                  alert(response.data.error);
              }
          }
        } catch (err) {
            throw new Error("Submit application Error: ", err);
        } finally {
            setApplicationOperationConfirmModal(null);
        }
      }
    });
  };

  const onSentVerificationLink = ({ id, name }) => {
    setApplicationOperationConfirmModal({
      title: t(
        "myAccount.submittedApplications.application.generateVerificationLink.title"
      ),
      text: `${t(
        "myAccount.submittedApplications.application.generateVerificationLink.description"
      )} ${name}?`,
      id,
      confirmText: t(
        "myAccount.submittedApplications.application.generateVerificationLink.text"
      ),
      onConfirm: async (id) => {
        try {
          var response = await candidateApi.post(`application/${id}/sent`);
          if (response.data) {
              if (response.data.result) {
                  alert(response.data.message);
                  if (response.data.url) {
                      prompt("Url do kliknięcia:",response.data.url);
                  }
              } else {
                  alert(response.data.message);
              }
          }
        } catch (err) {
            throw new Error("Sent link Error: ", err);
        } finally {
            setApplicationOperationConfirmModal(null);
        }
      }
    });
  };

   const onProlongateApplication = ({ id, name }) => {
     setApplicationOperationConfirmModal({
       title: t(
         "myAccount.submittedApplications.application.prolongateApplication.title"
       ),
       text: `${t(
         "myAccount.submittedApplications.application.prolongateApplication.description"
       )} ${name}?`,
       id,
       confirmText: t(
         "myAccount.submittedApplications.application.prolongateApplication.text"
       ),
       onConfirm: async (id) => {
         try {
           var response = await candidateApi.post(`application/${id}/prolongate`);
           if (response.data) {
               if (response.data.success) {
                 setApplicationsData();
                 await getApplicationList(instanceId);
               } else {
                   alert(response.data.error);
               }
           }
         } catch (err) {
             throw new Error("Prolongate application Error: ", err);
         } finally {
             setApplicationOperationConfirmModal(null);
         }
       }
     });
   };

   const onRequestForWithdrawal = ({ id, name }) => {
     setApplicationOperationConfirmModal({
       title: t(
         "myAccount.submittedApplications.application.sendRequestForWithdrawal.title"
       ),
       text: `${t(
         "myAccount.submittedApplications.application.sendRequestForWithdrawal.description"
       )} ${name}?`,
       id,
       confirmText: t(
         "myAccount.submittedApplications.application.sendRequestForWithdrawal.text"
       ),
       onConfirm: async (id) => {
         try {
           var response = await candidateApi.post(`application/${id}/withdrawal`);
           if (response.data) {
               if (response.data.success) {
                 setApplicationsData();
                 await getApplicationList(instanceId);
               } else {
                   alert(response.data.error);
               }
           }
         } catch (err) {
             throw new Error("Request for withdrawal Error: ", err);
         } finally {
             setApplicationOperationConfirmModal(null);
         }
       }
     });
   };


   const onRemoveRequestForWithdrawal = ({ id, name }) => {
     setApplicationOperationConfirmModal({
       title: t(
         "myAccount.submittedApplications.application.removeRequestForWithdrawal.title"
       ),
       text: `${t(
         "myAccount.submittedApplications.application.removeRequestForWithdrawal.description"
       )} ${name}?`,
       id,
       confirmText: t(
         "myAccount.submittedApplications.application.removeRequestForWithdrawal.text"
       ),
       onConfirm: async (id) => {
         try {
           var response = await candidateApi.post(`application/${id}/removewithdrawal`);
           if (response.data) {
               if (response.data.success) {
                 setApplicationsData();
                 await getApplicationList(instanceId);
               } else {
                   alert(response.data.error);
               }
           }
         } catch (err) {
             throw new Error("Remove withdrawal Error: ", err);
         } finally {
             setApplicationOperationConfirmModal(null);
         }
       }
     });
   };

  const onProcessApplication = ({ id, name }) => {
    setApplicationOperationConfirmModal({
      title: t(
        "myAccount.submittedApplications.application.confirmationOfAcceptance.title"
      ),
      text: `${t(
        "myAccount.submittedApplications.application.confirmationOfAcceptance.description"
      )} ${name}?`,
      id,
      confirmText: t(
        "myAccount.submittedApplications.application.confirmationOfAcceptance.text"
      ),
      onConfirm: async (id) => {
        try {
          var response = await candidateApi.post(`application/${id}/process`);
          if (response.data) {
              if (response.data.success) {
                setApplicationsData();
                await getApplicationList(instanceId);
              } else {
                  alert(response.data.message);
              }
          }
        } catch (err) {
            throw new Error("Process Error: ", err);
        } finally {
            setApplicationOperationConfirmModal(null);
        }
      }
    });
  };

   const onAcceptApplication = ({ id, name }) => {
     setApplicationOperationConfirmModal({
       title: t(
         "myAccount.submittedApplications.application.confirmationOfAcceptance.title"
       ),
       text: `${t(
         "myAccount.submittedApplications.application.confirmationOfAcceptance.description"
       )} ${name}?`,
       id,
       confirmText: t(
         "myAccount.submittedApplications.application.confirmationOfAcceptance.text"
       ),
       onConfirm: async (id) => {
         try {
           var response = await candidateApi.post(`application/${id}/accept`);
           if (response.data) {
               if (response.data.success) {
                 setApplicationsData();
                 await getApplicationList(instanceId);
               } else {
                   alert(response.data.message);
               }
           }
         } catch (err) {
             throw new Error("Accept Error: ", err);
         } finally {
             setApplicationOperationConfirmModal(null);
         }
       }
     });
   };

  const onAcceptSchoolbranch = ({ id, id_schoolbranch, name }) => {
    setApplicationOperationConfirmModal({
      title: t(
        "myAccount.submittedApplications.application.confirmationOfAcceptance.title"
      ),
      text: `${t(
        "myAccount.submittedApplications.application.confirmationOfAcceptance.description"
      )} ${name}?`,
      id,
      confirmText: t(
        "myAccount.submittedApplications.application.confirmationOfAcceptance.text"
      ),
      onConfirm: async (id) => {
        try {
          var response = await candidateApi.post(`application/${id}/${id_schoolbranch}/acceptschoolbranch`);
          if (response.data) {
              if (response.data.success) {
                setApplicationsData();
                await getApplicationList(instanceId);
              } else {
                  alert(response.data.error);
              }
          }
        } catch (err) {
            throw new Error("Accept Error: ", err);
        } finally {
            setApplicationOperationConfirmModal(null);
        }
      }
    });
  };

   const onCopyApplication = ({ id, name }) => {
     setApplicationOperationConfirmModal({
       title: t(
         "myAccount.submittedApplications.application.copyApplication.title"
       ),
       text: `${t(
         "myAccount.submittedApplications.application.copyApplication.description"
       )}`,
       id,
       confirmText: t(
         "myAccount.submittedApplications.application.copyApplication.text"
       ),
       onConfirm: async (id) => {
         try {
           var response = await candidateApi.post(`application/${id}/copy`);
           if (response.data) {
               if (response.data.success) {
                 setApplicationsData();
                 await getApplicationList(instanceId);
               } else {
                   alert(response.data.error);
               }
           }
         } catch (err) {
             throw new Error("Copy application Error: ", err);
         } finally {
             setApplicationOperationConfirmModal(null);
         }
       }
     });
   };


   const onRecreateApplication = ({ id, name }) => {
     setApplicationOperationConfirmModal({
       title: t(
         "myAccount.submittedApplications.application.recreateApplication.title"
       ),
       text: `${t(
         "myAccount.submittedApplications.application.recreateApplication.description"
       )}`,
       id,
       confirmText: t(
         "myAccount.submittedApplications.application.recreateApplication.text"
       ),
       onConfirm: async (id) => {
         try {
           var response = await candidateApi.post(`application/${id}/recreate`);
           if (response.data) {
               if (response.data.success) {
                 setApplicationsData();
                 await getApplicationList(instanceId);
               } else {
                   alert(response.data.error);
               }
           }
         } catch (err) {
             throw new Error("Recreate application Error: ", err);
         } finally {
             setApplicationOperationConfirmModal(null);
         }
       }
     });
   };

  const onSignApplication = ({ id, name }) => {
    setApplicationOperationConfirmModal({
      title: t(
        "myAccount.submittedApplications.application.signApplication.title"
      ),
      text: `${t(
        "myAccount.submittedApplications.application.signApplication.description"
      )}`,
      id,
      confirmText: t(
        "myAccount.submittedApplications.application.signApplication.text"
      ),
      onConfirm: () => {
        try {
          window.open("https://moj.gov.pl/uslugi/signer/upload?xFormsAppName=SIGNER", "_blank");
        } catch (err) {
            throw new Error("Recreate application Error: ", err);
        } finally {
            setApplicationOperationConfirmModal(null);
        }
      }
    });
  };

  useEffect(() => {
    if (instanceId) {
      getApplicationList(instanceId);
    }
  }, [instanceId]);

  const applicationsInCurrentInstance = useMemo(
    () =>
      applicationsData?.filter(({ id_Instance }) => id_Instance === instanceId),
    [applicationsData, instanceId]
  );

  const applicationsInOtherInstances = useMemo(
    () =>
      applicationsData
        ?.filter(({ id_Instance }) => id_Instance !== instanceId)
        .sort(orderby("fullName", (a) => a.toUpperCase())),
    [applicationsData, instanceId]
  );

  const savedApplications = useMemo(() => {
    // Incomplete == true i VerifiedDate != null
    if (applicationsInCurrentInstance?.length < 0) return [];
    return applicationsInCurrentInstance?.filter(
      (app) => app.incomplete === true && !app.verifiedDate
    );
  }, [applicationsInCurrentInstance]);

  const submittedApplications = useMemo(() => {
    if (applicationsInCurrentInstance?.length < 0) return [];
    return applicationsInCurrentInstance?.filter(
      (app) => app.incomplete === false
    );
  }, [applicationsInCurrentInstance]);

  const { instances } = useContext(AppContext);
  const [instance, setInstance] = useState(null);

  useEffect(() => {
    if (instances?.length > 0) {
      if (instances !== null && instances.length > 0) {
        setInstance(getInstance(instances, city, generalType)[0]);
      }
    }
  }, [instances, city]);

  const newApplicationButton = useMemo(() => {
    return (
        <div>
          {instance?.allowPublicSubbmissions && <Styled.LinkButton to={`/${city}/${generalType}/wniosek`}>
            {instance?.unitGeneralType === 11
                ? t("offer.endorsments.type2.fillText")
                : t("offer.endorsments.type3.fillText")}
          </Styled.LinkButton> }
          <br></br>
          {instance?.allowPublicClaimsAndApplications && <Styled.LinkButton to={`/${city}/${generalType}${instance?.unitGeneralType === 3 ? '/zgloszenie' : '/deklaracja' }`}>
            {instance?.unitGeneralType === 3
                ? t("offer.endorsments.type2.fillText")
                : t("offer.endorsments.type1.fillText")}
          </Styled.LinkButton> }
        </div>
    );
  }, [city, generalType, instance]);

  const itemTitle = useMemo(() => {
    if (!instance) return 'X';
    return instance.instanceUrl 
        ? `${instance.instanceName}` 
        : `${t(`unitTypes.${instance.typeUrl}`)} - ${instance.instanceName}`;
  } , [ instance ]);

  return (
    <Authorize>
      <Layout.Container>
        {applicationOperationConfirmModal && (
          <ModalConfirm
            closeModal={() => setApplicationOperationConfirmModal(null)}
            title={applicationOperationConfirmModal.title}
            text={applicationOperationConfirmModal.text}
            onConfirm={() =>
              applicationOperationConfirmModal.onConfirm(applicationOperationConfirmModal.id)
            }
            confirmText={applicationOperationConfirmModal.confirmText}
          />
        )}
        <Breadcrumbs />
        <Styled.ApplicationListMain className="applications-list">
          <Styled.ApplicationsHeader>
            <Styled.ApplicationsHeadTitleContainer>
            <Styled.ApplicationsHeadTitle>
              {t("myAccount.submittedApplications.mainTitle")} (
              {submittedApplications?.length + savedApplications?.length || 0})
            </Styled.ApplicationsHeadTitle>
            <Styled.H2 className="instance-name">{itemTitle}</Styled.H2>
            </Styled.ApplicationsHeadTitleContainer>
            {!isMobile && (
              newApplicationButton
            )}
          </Styled.ApplicationsHeader>

          {loading ? (
            <Loader />
          ) : (
            <>
              <ChildrenOverview
                savedApplications={savedApplications}
                submittedApplications={submittedApplications}
                deleteApplication={onDeleteApplication}
                submitApplication={onSubmitApplication}
                sentVerificationLink={onSentVerificationLink}
                requestForWithdrawal={onRequestForWithdrawal}
                removeRequestForWithdrawal={onRemoveRequestForWithdrawal}
                signApplication={onSignApplication}
                prolongateApplication={onProlongateApplication}
                processApplication={onProcessApplication}
                acceptApplication={onAcceptApplication}
                recreateApplication={onRecreateApplication}
                copyApplication={onCopyApplication}
                instanceName={instance?.typeName}
                instanceInfo={instance}
                generalType={generalType}
                isMobile={isMobile}
                {...(isMobile && { newApplicationButton })}
              />
              <OtherRecruitmentApplications
                applications={applicationsInOtherInstances}
              />
            </>
          )}
        </Styled.ApplicationListMain>
      </Layout.Container>
    </Authorize>
  );
};

export default ApplicationList;