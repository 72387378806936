import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { useInstance } from "hooks/useInstance";
import useApi from "hooks/useApi";

import PlusIcon from 'components/commons/icons/PlusIcon';
import Modal from 'components/commons/Modal/Modal';
import { ButtonText } from 'components/commons/Button/ButtonText';
import { Button } from 'components/commons/Button/Button';

import Loader from 'components/commons/Loader/Loader';

import { Input } from 'components/commons/Input/Input';
import Select from "components/commons/Select/Select";

import * as Styled from './ModalAddAttachment.styles';

import { wait } from 'utils/utils';

const FILE_SIZE = 8 * 1024 * 1024;

const yupVS = yup.object().shape({
    description: yup.string().required('Opis jest wymagany'),
    fileType: yup.mixed().required('Wybierz opcję'),
    file: yup.mixed().required('Plik jest wymagany').
    test('fileType', "Plik musi być typu .JPG/.JPEG, .PNG lub .PDF", file => ['application/pdf', 'image/jpeg', 'image/png'].includes(file.type)).
    test('fileSize', "Plik musi być poniżej 8 MB", file => file.size <= FILE_SIZE).
    test('fileNonZero', "Plik nie może być pusty", file => file.size > 0)
});

const attachmentTypes = [
    { label: 'SubmissionAttachment', value: 1 },
    { label: 'Submission', value: 2 },
    { label: 'Diploma', value: 10 },
    { label: 'ExamResult', value: 15 },
    { label: 'Referral', value: 20 },
    { label: 'Vaccination', value: 25 },
    { label: 'Taxation', value: 30 },
    { label: 'Other', value: 100 },
];

const formatFileSize = (bytes) => {
    if (bytes) {
        if (bytes > (1024 * 1024 * 1024)) {
            const gigabytes = bytes / (1024 * 1024 * 1024);
            return gigabytes.toFixed(0)+' GB';
        } else
        if (bytes > (1024 * 1024)) {
            const megabytes = bytes / (1024 * 1024);
            return megabytes.toFixed(0)+' MB';
        } else {
            const kilobytes = bytes / (1024);
            return kilobytes.toFixed(0)+' kb';
        }
    }
    return 0+' b';
};

const formatFileType = (type) => {
    return '.'+type.split('/')[1].toUpperCase();
};


export const ModalAddAttachment = ({ closeModal, applicationId, appType, reloadAttachmentList }) => {

    const { t } = useTranslation();
    const { candidateApi } = useApi();
    const { handleSubmit, control, formState: { isValid, errors }, setError, register, watch } = useForm({
        resolver: yupResolver(yupVS),
    });
    const fileInputRef = useRef();
    const [loading, setLoading] = useState(false);
    const { instance } = useInstance();
    const [availableAttachmentTypes, setAvailableAttachmentTypes] = useState([1]);
    const watchFile = watch('file');
    
    useEffect(() => {
            if (instance) {
                if (appType == 3)
                    setAvailableAttachmentTypes(instance.allowedAttachmentTypesForAddInSubbmissions);
                else
                    setAvailableAttachmentTypes(instance.allowedAttachmentTypesForAddInClaimsAndApplications);
            }
        },
        [instance]);

    const attachmentTypeOptions = attachmentTypes.filter((a)=> availableAttachmentTypes.includes(a.value));

    const onSubmit = async (data) => {
        setLoading(true);
        const formData = new FormData();
        formData.append('id', applicationId);
        formData.append('name', data.description);
        formData.append('id_Application', applicationId);
        formData.append('id_DictApplicationAttachmentType', data.fileType.value);
        formData.append('file', data.file);
    
        try {
            const response = await candidateApi.post(`Attachment/${applicationId}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            }); 
            if (response.status === 200) {
                closeModal();
                wait(1000).then(() => {
                    reloadAttachmentList();
                });
            } else {
                setError('description', { type: 'custom', message: response?.data?.detail || "Wystąpił błąd" });
            }
        } catch (error) {
            console.error('send error:', error);
            setError('description', { type: 'custom', message: error.response?.data?.detail || "Wystąpił błąd" });
        } finally {
            setLoading(false);
        }
    };

    return (
        <Modal
            closeModal={closeModal}
            wrapModal={true}
            closeButton={true}
            closeOnBackground={!loading}
        >
            {(closeModal) => (
                <Styled.ModalContent>
                    {loading && (
                        <Styled.ModalLoader>
                            <Loader />
                        </Styled.ModalLoader>
                    )}

                    <Styled.ModalHead>
                        {t('myAccount.submittedApplications.application.addAttachmentModal.head')}
                    </Styled.ModalHead>
                    <Styled.ModalForm onSubmit={handleSubmit(onSubmit)}>
                        <div>
                            <Styled.Asterisk>* </Styled.Asterisk>{t('myAccount.submittedApplications.application.addAttachmentModal.labelDescription')}
                            <Input
                                error={errors?.description?.message}
                                {...register('description')}
                            />
                        </div>
                        <Styled.ModalSelectWrapper>
                            <Controller
                                name='fileType'
                                control={control}
                                render={({ field }) => (
                                    <Select
                                        label={
                                            <>
                                                <Styled.Asterisk>* </Styled.Asterisk>
                                                {t('myAccount.submittedApplications.application.addAttachmentModal.labelTypeSelect')}
                                            </>
                                        }
                                        isFilter
                                        onChange={(option) => {
                                            field.onChange(option);
                                        }}
                                        onChangeType='object'
                                        className='modal-select'
                                    >
                                        {attachmentTypeOptions.map(({ label, value }, index) => (
                                            <option
                                                value={value}
                                                key={`${label}--${index}`}
                                            >
                                                {t(`myAccount.submittedApplications.application.addAttachmentModal.attachmentOptions.${label}`)}
                                            </option>
                                        ))}
                                    </Select>
                                )}
                            />
                        </Styled.ModalSelectWrapper>
                        <div>
                            <Styled.ModalSubheader>
                                {t('myAccount.submittedApplications.application.addAttachmentModal.attachFile')}
                            </Styled.ModalSubheader>
                            <Styled.ModalInfo>
                                {t('myAccount.submittedApplications.application.addAttachmentModal.caution')}
                            </Styled.ModalInfo>
                        </div>

                        <div>
                            <Controller
                                name="file"
                                control={control}
                                render={({ field }) => (
                                    <>
                                        <input
                                            type="file"
                                            accept=".jpg, .jpeg, .png, .pdf"
                                            style={{ display: 'none' }}
                                            onChange={(ev) => {
                                                if (ev.target.files) {
                                                    field.onChange(ev.target.files[0]);
                                                }
                                            }}
                                            ref={fileInputRef}
                                        />
                                        <ButtonText
                                            type='button'
                                            onClick={() => fileInputRef.current && fileInputRef.current.click()}
                                        >
                                            <PlusIcon className='icon' />
                                            {t('myAccount.submittedApplications.application.addAttachmentModal.attachFile')}
                                        </ButtonText>
                                    </>
                                )}
                            />
                            {watchFile && watchFile.name && (
                                <>
                                <Styled.ModalInfo>
                                    {t('myAccount.submittedApplications.application.addAttachmentModal.selectedFile')}: <span>{watchFile.name}</span>
                                </Styled.ModalInfo>
                                    <Styled.ModalInfo>
                                        Rozmiar: <span>{formatFileSize(watchFile.size)}</span>
                                    </Styled.ModalInfo>
                                    <Styled.ModalInfo>
                                        Format: <span>{formatFileType(watchFile.type)}</span>
                                    </Styled.ModalInfo>
                                </>
                            )}

                        </div>


                        <Styled.ModalButtonsWrapper>
                            <ButtonText
                                onClick={closeModal}
                                type='button'
                            >
                                {t('common.cancel').toUpperCase()}
                            </ButtonText>
                            <Button
                                type="submit"
                                disabled={!isValid || loading}
                            >
                                {t('myAccount.submittedApplications.application.addAttachmentModal.head')}
                            </Button>
                        </Styled.ModalButtonsWrapper>

                    </Styled.ModalForm>
                </Styled.ModalContent>
            )}
        </Modal>
    );
};
