import styled, { css } from 'styled-components';

import { Link as RouterLink } from 'react-router-dom';

export const Link = styled(RouterLink)`
    color: ${props => (props.active === true ? props.theme.colors.grey90 : props.reverse === true ? props.theme.colors.white : props.theme.colors.main)};
    font-size: 1.6rem;
    text-decoration: none;
    font-weight: ${props => (props.active === true ? 'bold' : 'normal')};
    position: relative;
    pointer-events: ${props => (props.active === true ? 'none' : 'auto')};

    &[disabled] {
        color: ${({theme}) => theme.colors.grey30} !important;
        pointer-events: none;
    }

    &:hover, &:focus {
        text-decoration: ${props => (props.active === true ? 'none' : 'underline')};
    }

    &:focus-visible {
        outline: 1.5px solid ${props => (props.reverse === true ? props.theme.colors.white : props.theme.colors.grey90)};
        outline-offset: 2px;
        padding: 0.1rem;
        border-radius: 5px;
    }

    &:active {
        &:before {
            width: calc(100% + 10px);
            height: calc(100% + 10px);
            border: 1px solid ${({reverse, theme}) => (reverse ? theme.colors.white : theme.colors.accent)};
            content: "";
            display: block;
            position: absolute;
            left: -5px;
            top: -5px;
            border-radius: 5px;
        }
    }

    &:visited {
        color: ${({active, reverse, theme}) => (active ? theme.colors.main : reverse ? theme.colors.white : theme.colors.main)};
    }

    ${props => props.bold && css`
        font-weight: 600;
    `}
`;
