import styled from 'styled-components';
export const ModuleButtonsWrapper = styled.div`
    position: sticky;
    display: flex;
    flex-direction: column-reverse;
    gap: 2rem;
    padding: 2rem 1.5rem;
    bottom: 0;
    background: ${({ theme: { colors } }) => colors.white};
    border-top: 1px solid ${({ theme: { colors } }) => colors.accent};
    z-index: 100;

    margin: 0px -1.5rem 3rem;
    width: calc(100% + 3rem);   

    @media ${({ theme: { device } }) => device.tablet} {
        padding: 2rem 3rem;
        margin: 0px -3rem 3rem;
        width: calc(100% + 6rem);
    }
    
    @media ${({ theme: { device } }) => device.desktop} {
        justify-content: flex-end;
        flex-direction: row;
        
        margin: 0;

        margin-left: -4rem;
        width: calc(100% + 7rem);
    }

    @media ${({ theme: { device } }) => device.desktopMax} {
        >button {
            width: 100%;
            padding: 0.8rem;
        }
    }

    @media ${({ theme: { device } }) => device.widescreen} {
        width: 100dvw;
        max-width: unset;
        margin-left: calc(((-34rem + 100vw) / 2) - 50vw);
        padding-right: 15rem;
    }

    @media ${({ theme: { device } }) => device.superHd} {
        margin-left: calc(-50dvw + 79rem);
        padding-right: calc(50dvw - 81rem);
    }

    >.cancel-button {
        align-self: center;
    }

    & .error-wrapper {
        right: 0;
    }
`;

export const StyledModuleErrorText = styled.div`
    position: absolute;
    border: 2px solid ${({ theme: { colors }, isError }) => isError ? colors.warning : colors.success};
    top: -25px;
    background: white;
    border-radius: 10px;
    padding: 0.5rem;
    text-align: center;
    width: 100%;
    max-width: calc(100vw - 5rem);
    font-weight: 500;
    color: ${({ theme: { colors }, isError }) => isError ? colors.warning : colors.main};

    @media ${({ theme: { device } }) => device.tablet} {
        max-width: calc(100vw - 8rem);
    }
    @media ${({ theme: { device } }) => device.desktop} {
        max-width: 50rem;
    }
`;