import styled, { css } from 'styled-components';

import { Container } from 'styles/common.styles';

import moveDefault from 'assets/icons/move-default.svg';
import moveActive from 'assets/icons/move-active.svg';
import moveHover from 'assets/icons/move-hover.svg';

const mobileLeftPadding = '3rem';
const mobileLeftPaddingSmall = '2rem';

export const Element = styled.div`
    display: flex;
    gap: 1rem;
    align-items: center;
    justify-content: ${({ toLeft }) => toLeft ? 'flex-start' : 'center'};
    ${({onClick}) => onClick && css`
        cursor: pointer;
        text-align: ${({ toLeft }) => toLeft ? 'left' : 'center'};
        &:hover {
            text-decoration: underline;
        }
    `}
`;

export const AvailableContainer = styled.section`
    position: relative;
    margin: 0 -1.3rem 3rem;
    @media ${({ theme }) => theme.device.tablet} {
        margin: 0 -2.9rem 3rem;
    }
    @media ${({ theme }) => theme.device.desktop} {
        margin: 0 0 3rem;
    }
`;

export const SelectedContainer = styled.section`
    position: relative;
    margin: 4rem -1.3rem;
    @media ${({ theme }) => theme.device.tablet} {
        margin: 4rem -2.9rem;
    }
    @media ${({ theme }) => theme.device.desktop} {
        margin: 4rem 0;
    }
`;
export const SelectedTitle = styled.div`
    position: relative;
    margin-bottom: 3rem;
    font-size: 1.9rem;
    font-weight: 600;
    color: ${({theme}) => theme.colors.black};
    padding: 0 ${mobileLeftPaddingSmall};
    @media ${({ theme }) => theme.device.tablet} {
        padding: 0 ${mobileLeftPadding};
    }
    @media ${({ theme }) => theme.device.desktop} {
        padding: 0;
    }
`;

export const SelectedRow = styled.div`
    display: grid;
    grid-template-columns: 11rem 1fr 9rem 17rem 6rem;
    padding: 2.4rem 0;
    color: ${({theme}) => theme.colors.black};
    font-size: 1.8rem;
    gap: 4rem;
    font-weight: 600;

    @media ${({ theme }) => theme.device.desktop} {
        padding: 2.4rem 1.5rem;
    }
`;

export const SelectedHeader = styled(SelectedRow)`
    background: ${({theme}) => theme.colors.selectItemBackground};    
    position: sticky;
    top: 0;
    font-size: 2rem;
    z-index: 1;
    display: none;

    @media ${({ theme }) => theme.device.desktop} {
        display: grid;
    }
`;

export const SelectedUnitContainer = styled(SelectedRow)`
    display: block;
    padding-bottom: 0rem;
    background: #ffffffee;
    @media ${({ theme }) => theme.device.desktop} {
        display: grid;
        padding: 2.4rem 1.5rem;
    }
    border-bottom: 1px solid ${({theme}) => theme.colors.accent};

    ${({isDragging}) => isDragging ? css`        
        box-shadow: rgba(0, 0, 0, 0.25) 0px 2px 10px;
    ` : css`        
        box-shadow: none;
    `}
`;
export const SelectedPreferenceOrd = styled(Element)`
    background: ${({theme}) => theme.colors.backgroundPrime};
    justify-content: flex-start;
    margin-bottom: 1rem;

    padding: 1.2rem ${mobileLeftPaddingSmall};
    @media ${({ theme }) => theme.device.tablet} {
        padding: 1.2rem ${mobileLeftPadding};
    }

    @media ${({theme: {device}}) => device.desktop} {
        justify-content: center;
        width: 100%;
        margin: unset;
        background: none;

        >.mobile-preference-key {
            display: none;
        }
    }
`;


const AvailableRow = styled.div`
    font-size: 1.6rem;
    @media ${({ theme }) => theme.device.desktop} {
		display: grid;
        grid-template-columns: 1fr 15rem 14rem 10rem;
        padding: 1.5rem 2rem;
        gap: 2rem;
        min-height: 6.5rem;
        align-items: center;
        font-weight: 600;
        font-size: 1.8rem;
	}
`;

export const AvailableHead = styled(AvailableRow)`
    background: ${({ theme }) => theme.colors.main};
    color: ${({ theme }) => theme.colors.white};
    font-size: 2rem;
    position: sticky;
    top: 0;
    z-index: 1;
    @media ${({ theme }) => theme.device.touch} {
		display: none;
	}
`;

export const AvailableUnitStyled = styled(AvailableRow)(
    ({hasBranches, theme}) => css`
    color: ${theme.colors.black};
    position: relative;
    border-bottom: 1px solid ${theme.colors.main};
    margin-top: 0.5rem;

    @media ${theme.device.desktop} {
        margin-top: 0;
    }

    ${hasBranches && css`
        grid-template-columns: 1fr 14rem 10rem;
        margin-top: 0;
        background-color: ${theme.colors.selectItemBackground};
    `};
`);

export const AvailableBranchStyled = styled(AvailableUnitStyled)`
    margin-top: 0;
`;

export const AvailableUnitWithBranchesWrapper = styled.div`
    margin-bottom: 3rem;
    border-bottom: 1px solid ${({ theme }) => theme.colors.main};
    border-top: 2px solid ${({ theme }) => theme.colors.main};
    @media ${({theme: {device}}) => device.desktop} {
        margin-bottom: 0;
        border-top: none;
    }
`;

export const PreferenceName = styled(Element)`
    display: flex;
    font-size: 1.6rem;
    font-weight: 600;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 0;
    color: ${({theme}) => theme.colors.main};
    
    @media ${({theme: {device}}) => device.desktop} {
        font-size: 1.8rem;
    }
`;

export const BranchName = styled(PreferenceName)`
    padding: 1rem 6rem 0.5rem ${mobileLeftPaddingSmall};
    color: ${({theme}) => theme.colors.black};
    @media ${({ theme }) => theme.device.tablet} {
        padding: 1rem 6rem 0.5rem ${mobileLeftPadding};
    }
    @media ${({theme: {device}}) => device.desktop} {
        padding: 0 0 0 2rem;
    }
`;

export const SelectedName = styled(PreferenceName)`
    padding: 1rem ${mobileLeftPaddingSmall};
    color: ${({theme}) => theme.colors.black};
    @media ${({ theme }) => theme.device.tablet} {
        padding: 1rem ${mobileLeftPadding};
    }
    @media ${({theme: {device}}) => device.desktop} {
        padding: 0;
    }
`;

export const CheckboxWrapper = styled(Element)`
    @media ${({theme: {device}}) => device.touch} {
        position: absolute;
        right: 3rem;
        top: 1.4rem;
    }
`;

export const PreferenceUnitName = styled.div`
    padding: 1.4rem ${mobileLeftPaddingSmall} 1rem ;
    color: ${({theme}) => theme.colors.black};
    @media ${({ theme }) => theme.device.tablet} {
        padding: 1.4rem 6rem 1rem ${mobileLeftPadding};
    }
    @media ${({theme: {device}}) => device.desktop} {
        padding: unset;
    }
    ${({clickable}) => clickable && css`
        cursor: pointer;
        color: ${({theme}) => theme.colors.black};
    `}
`;

export const PreferenceAddress = styled.div`
    font-size: 1.4rem;
    font-weight: 600;
    color: ${({theme}) => theme.colors.black};

    border-top: 1px solid ${({theme}) => theme.colors.main3};
    width: 100%;
    margin-top: 1rem;
    padding-top: 1rem;
    padding-left: ${mobileLeftPaddingSmall};
    padding-right: 6rem;

    @media ${({theme: {device}}) => device.tablet} {
        padding-left: ${mobileLeftPadding};
    }
    @media ${({theme: {device}}) => device.desktop} {
        border: none;
        margin-top: 0;
        padding: 0;
        font-size: 1.6rem;
        font-weight: 400;
    }
`

export const CheckboxInput = styled.input`
    width: 2rem;
    height: 2rem;
    
`;

export const HoverDeleteIcon = styled.div`
    border: 2px solid ${({ theme }) => theme.colors.black};
    border-radius: 50%;
    width: 3.6rem;
    height: 3.6rem;
    background: white;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.7rem;
    cursor: pointer;
    & .icon {
        fill: ${({ theme }) => theme.colors.warning};
    }
    &:hover {
        border-color: #fb693c;
        background-color: ${({ theme }) => theme.colors.warning};
        & .icon {
            fill: ${({ theme }) => theme.colors.white};
        }
    }
    ${({ disabled }) => disabled && css`
        filter: grayscale(1);
        opacity: 0.5;
        pointer-events: none;
    `};
`;

export const UpDownButton = styled.div`
    display: flex;
    width: 3.2rem;
    height: 3.2rem;
    background-color: ${({ theme }) => theme.colors.white};
    border: 2px solid ${({ theme }) => theme.colors.main};
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding-left: 1px;
    transition: all 0.1s ease-in-out;
    transform: rotate( ${({ isDown }) => isDown ? '90deg' : '270deg'});

    & .cls-1 {
        fill: ${({ theme }) => theme.colors.main};
    }
    &:hover {
        background-color: ${({ theme }) => theme.colors.main};
        border-color: ${({ theme }) => theme.colors.white};
        & .cls-1 {
            fill: ${({ theme }) => theme.colors.white};
        }
    }

    ${({ inverted }) => inverted && css`
        background-color: ${({ theme }) => theme.colors.main};
        border-color: ${({ theme }) => theme.colors.white};
        & .cls-1 {
            fill: ${({ theme }) => theme.colors.white};
        }
        &:hover {
            background-color: ${({ theme }) => theme.colors.white};
            border-color: ${({ theme }) => theme.colors.main};
            & .cls-1 {
                fill: ${({ theme }) => theme.colors.main};
            }
        }
    `}
`;

export const UpDownButtonWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    padding-right: 2rem;
    padding-bottom: 2rem;
    margin-top: -1.5rem;

    @media ${({theme: {device}}) => device.desktop} {
        justify-content: center;
        padding: unset;
        margin-top: 0;
    }
`;


export const UnitDescriptionButton = styled(Element)`
    font-weight: 600;
    cursor: pointer;
    color: ${({theme}) => theme.colors.main};
    margin: 1rem 0;
    padding-top: 1rem;
    border-top: 1px solid ${({theme}) => theme.colors.main3};
    justify-content: flex-start;
    font-size: 1.4rem;
    padding-left: ${mobileLeftPaddingSmall};

    @media ${({theme: {device}}) => device.tablet} {
        padding-left: ${mobileLeftPadding};
    }

    &:hover {
        text-decoration: underline;
    }

    @media ${({theme: {device}}) => device.desktop} {
        margin: 1rem 0;
        padding: 0;
        justify-content: center;
        border-top: none;
        font-size: 1.6rem;
    }
`;

export const BranchDescriptionButton = styled(UnitDescriptionButton) `
    padding-left: ${mobileLeftPaddingSmall};
    @media ${({theme: {device}}) => device.touch} {
        padding-left: ${mobileLeftPadding};
    }
    @media ${({theme: {device}}) => device.desktop} {
        padding-left: 0;
    }
`;



export const PreferenceOrderControlWrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: left;
    padding: 2rem ${mobileLeftPadding};

    grid-template-areas: "item1 item2 item3";

    @media ${({theme: {device}}) => device.touch} {
        >div:nth-child(1) {
            grid-area: item2;
        }
        >div:nth-child(2) {
            grid-area: item1;
        }
        >div:nth-child(3) {
            grid-area: item3;
        }
    }

    @media ${({theme: {device}}) => device.desktop} {
        display: contents;
    }
`;

export const DragItemButton = styled.div`
    background-image: url(${moveDefault});
    background-repeat: no-repeat;
    width: 2.4rem;
    height: 3rem;
    &:hover {
        background-image: url(${moveHover});
    }
    &:active {
        background-image: url(${moveActive});
    }
`;


export const DetailsBar = styled.div`
    position: sticky;
    bottom: ${({bottom}) => bottom ? `${bottom}px` : '87px' };
    left: 0;
    width: calc(100% + 3rem);
    min-height: 7rem;
    background-color: ${({ theme }) => theme.colors.accent};
    color: ${({ theme }) => theme.colors.white};
    z-index: 50;
    padding: 1rem 3rem;
    font-size: 1.6rem;
    font-weight: 400;
    display: flex;
    align-items: center;
    margin-left: -1.5rem;
    max-width: calc(100% + 10rem);
    margin-bottom: -1px;

    @media ${({ theme: { device } }) => device.tablet} {
        width: calc(100% + 6rem);
        margin-left: -3rem;
    }

    @media ${({ theme: { device } }) => device.desktop} {
        font-size: 1.8rem;
        font-weight: 600;
        margin-left: -4rem;
        width: calc(100% + 7rem);
    }

    @media ${({ theme: { device } }) => device.widescreen} {
        width: 100vw;
        max-width: unset;
        margin-left: calc(((-34rem + 100vw) / 2) - 50vw);
    }

    @media ${({ theme: { device } }) => device.superHd} {
        width: 100dvw;
        margin-left: calc(-50dvw + 790px);
        max-width: unset;
    }
    & span {
        font-weight: 600;
    }
`;

export const DetailsBarInnerContainer = styled(Container)`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
    justify-content: space-between;
    align-items: center;

    >.details-bar-text{
        text-align: center;
    }

    @media ${({ theme: { device } }) => device.desktop} {
        flex-direction: row;
        >.details-bar-text{
            text-align: left;
        }
    }
`;

export const DetailsBarShowselected = styled.div`
    display: flex;
    gap: 2rem;
    align-items: center;
    &:hover{
        cursor: pointer;
        & .show-selected {
            text-decoration: underline;
        }
        & .up-down-button {
            background-color: ${({ theme }) => theme.colors.main};
            border-color: ${({ theme }) => theme.colors.white};
            & .cls-1 {
                fill: ${({ theme }) => theme.colors.white};
            }
        }
    }
`;

export const NotificationWrapper = styled.div`
    margin-bottom: 6rem;
`;

export const BranchesSelectedInCurrentUnitCount = styled.div`
    font-size: 1.8rem;
    font-weight: 600;
    color: ${({theme}) => theme.colors.accent};
    display: flex;
    justify-content: center;
    text-align: center;

    height: 0;
    margin-top: 0.7rem;

    @media ${({ theme: { device } }) => device.desktop} {
        height: auto;
        margin-top: 0;
    }
`;

export const NotificationContainer = styled.div`
    margin: 4rem 0;
`;