import React, { useEffect, useState } from "react";
import useIsMobile from "hooks/useIsMobile";
import logo from "assets/images/nabor-logo.svg";
import logoWhite from "assets/images/nabor-logo-white.svg";
import logoSmall from "assets/images/nabor-logo-small.svg";

import Logo from "./Logo";
import { useInstance } from "hooks/useInstance";

import { useParams, Link } from "react-router-dom";

const NaborLogo = ({ white, useNarrowLogo = false }) => {
	const { city, generalType } = useParams();
	const { instance, region } = useInstance();

	const [currentLogo, setCurrentLogo] = useState(white ? logoWhite : logo);

	const isMobile = useIsMobile("colMedium");
	const noStandartLogo = city && generalType && instance?.settings?.NoStandartLogo;
	const localLogoExists = (city && instance?.urlName === city && instance?.typeUrl === generalType && instance?.emblemExists) ||
							(city && region?.url === city && region?.logoExists);

	useEffect(() => {
			if (city && generalType && noStandartLogo) {
				setCurrentLogo("/logo/" + instance.settings.NoStandartLogo.value);
			} else if (city && generalType && instance?.settings?.NoPCSSLogo) {
				setCurrentLogo(null);
			} else {
				setCurrentLogo( (isMobile && localLogoExists)? logoSmall : white ? logoWhite : logo);
			}
	}, [instance, city, generalType, isMobile]);

	if (currentLogo == null) return <></>;

	if (noStandartLogo) return (
			<Logo src={currentLogo} alt={`/logo/${instance.settings.NoStandartLogo.value}`}/>
	)

	return (
		<Link to="/">
			<Logo 
                src={currentLogo} 
                alt="nabor-logo" 
                useNarrowLogo={useNarrowLogo}
            />
		</Link>
	);
};

export default NaborLogo;
