import { additionalFeatureIcons, sectionConfig } from "pages/Unit/Unit.config";
import { UnitGeneralTypes } from "./enums";
import { isHtmlEmpty, propertyNotEmpty } from "./utils";

export const usedIds = Object.values(sectionConfig)
	.map((side) =>
		side.map((section) => {
			if (section.hasOwnProperty("features")) {
				return section.features.map((feature) => {
					if (feature.hasOwnProperty("feature_ids")) {
						return feature.feature_ids;
					}
					return undefined;
				});
			}
			return undefined;
		})
	)
	?.flat(3)
	?.filter((id) => id !== undefined);

	const showSpecificSection = ({name, unit, instance, usedIds}) => {
		switch (name) {
			case "additionalFeatures":
				return (
					unit?.boolFeatures &&
					(instance.unitGeneralType === UnitGeneralTypes.Nursery ||
						instance.unitGeneralType === UnitGeneralTypes.Kindergarten ||
						instance.unitGeneralType === UnitGeneralTypes.HolidayDuty)
				);
			case "additionalInformationSection": {
				const featuresIds = Object.values(additionalFeatureIcons)
					.filter((el) => el.id !== null)
					.map((el) => el.id);
	
				const displayedIds = unit.features?.filter(
					(el) =>
						!usedIds.includes(el.id) &&
						!featuresIds.includes(el.id) &&
						el.valueType !== 6
				);
	
				return displayedIds.length > 0;
			}
			case "branches":
				return (
					(instance.unitGeneralType === UnitGeneralTypes.SecondarySchool ||
						instance.unitGeneralType === UnitGeneralTypes.TradeSchool) &&
					unit?.schoolbranches?.length > 0
				);
			case "languages":
				return (
					(instance.unitGeneralType === UnitGeneralTypes.PrimarySchool ||
						instance.unitGeneralType === UnitGeneralTypes.Class4 ||
						instance.unitGeneralType === UnitGeneralTypes.Class6 ||
						instance.unitGeneralType === UnitGeneralTypes.Class7) &&
					unit.languages?.length > 0
				);
			case "terms":
				return (
					instance.unitGeneralType === UnitGeneralTypes.HolidayDuty &&
					unit?.schoolbranches?.length > 0
				);
			default:
				return false;
		}
	};

	const findFeature = (id, unit) => {
		const foundFeature = unit.features.find((el) => el.id === id);
		return !!foundFeature;
	};
	
	export const showProperty = ({feature, unit, instance}) => {
		const unitCondition = feature.hasOwnProperty("unit_condition")
			? feature.unit_condition
					.map((cond) => propertyNotEmpty(unit[cond]))
					.reduce((acc, cur) => acc && cur)
			: true;
		const featureCondition = feature.hasOwnProperty("feature_ids")
			? feature.feature_ids
					.map((id) => findFeature(id, unit))
					.reduce((acc, cur) => acc && cur)
			: true;
	
		const additionalCondition = feature.hasOwnProperty("additionalCondition")
			? instance?.settings?.hasOwnProperty(feature.additionalCondition)
			: true;
	
		return unitCondition && featureCondition && additionalCondition;
	};
	
export	const showSection = ({section, unit, instance, usedIds}) => {
		if (!section.hasOwnProperty("features")) {
			if (section.hasOwnProperty("display"))
				return showSpecificSection({
					name: section.display, 
					unit, 
					instance, 
					usedIds
				});
			if (!showProperty({feature: section, unit, instance})) return false;
			return (
				isHtmlEmpty(section.getData(unit)) &&
				propertyNotEmpty(section.getData(unit))
			);
		}
		return section.features
			.map((feature) => showProperty({feature, unit, instance}))
			.reduce((acc, cur) => acc || cur);
	};

