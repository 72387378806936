import { useState } from 'react';

const ExtraRowExpandable = (props) => {
    const multiplierArray = [10, 7, 5, 10, 7, 5, 1, 1, 1, 1, 1, 4, 3, 2, 1];
	const [examPointsArray, setExamPointsArray] = useState(new Array(15).fill(0));

    const onPointsChange = (event) => {
        const newExamPointsArray = [...examPointsArray];
        newExamPointsArray[event.target.name] = parseInt(event.target.value);
        setExamPointsArray(newExamPointsArray);

        let pointsSum = 0;
        for (let i = 0; i < newExamPointsArray.length; i++)
            pointsSum += (newExamPointsArray[i] * multiplierArray[i]);

        props.onChange(props.id, pointsSum > props.max ? props.max : pointsSum);
	}

    return (
		<table className="tableCalculator">
			<tbody>
				<tr />
				{/* § 6.1.1 */}
				<tr>
					<td>Tytuły finalisty konkursu przedmiotowego uzyskane w zawodach wiedzy będących konkursem o zasięgu ponadwojewódzkim organizowanych przez kuratorów oświaty na podstawie zawartych porozumień</td>
					<td><input type="number" name="0" value={examPointsArray[0]} onChange={onPointsChange} min={0} /></td>
				</tr>
				<tr>
					<td>Tytuły laureata konkursu tematycznego lub interdyscyplinarnego uzyskane w zawodach wiedzy będących konkursem o zasięgu ponadwojewódzkim organizowanych przez kuratorów oświaty na podstawie zawartych porozumień</td>
					<td><input type="number" name="1" value={examPointsArray[1]} onChange={onPointsChange} min={0} /></td>
				</tr>
				<tr>
					<td>Tytuły finalisty konkursu tematycznego lub interdyscyplinarnego uzyskane w zawodach wiedzy będących konkursem o zasięgu ponadwojewódzkim organizowanych przez kuratorów oświaty na podstawie zawartych porozumień</td>
					<td><input type="number" name="2" value={examPointsArray[2]} onChange={onPointsChange} min={0} /></td>
				</tr>
				{/* § 6.1.2 */}
				<tr>
					<td>Tytuły finalisty konkursu przedmiotowego uzyskane w zawodach wiedzy będących konkursem o zasięgu międzynarodowym albo ogólnopolskim, przeprowadzanym zgodnie z przepisami wydanymi na podstawie art. 22 ust. 6 ustawy z dnia 7 września 1991 r. o systemie oświaty (Dz. U. z 2022 r. poz. 2230)</td>
					<td><input type="number" name="3" value={examPointsArray[3]} onChange={onPointsChange} min={0} /></td>
				</tr>
				<tr>
					<td>Tytuły laureata konkursu interdyscyplinarnego uzyskane w zawodach wiedzy będących konkursem o zasięgu międzynarodowym albo ogólnopolskim, przeprowadzanym zgodnie z przepisami wydanymi na podstawie art. 22 ust. 6 ustawy z dnia 7 września 1991 r. o systemie oświaty (Dz. U. z 2022 r. poz. 2230)</td>
					<td><input type="number" name="4" value={examPointsArray[4]} onChange={onPointsChange} min={0} /></td>
				</tr>
				<tr>
					<td>Tytuły finalisty konkursu interdyscyplinarnego uzyskane w zawodach wiedzy będących konkursem o zasięgu międzynarodowym albo ogólnopolskim, przeprowadzanym zgodnie z przepisami wydanymi na podstawie art. 22 ust. 6 ustawy z dnia 7 września 1991 r. o systemie oświaty (Dz. U. z 2022 r. poz. 2230)</td>
					<td><input type="number" name="5" value={examPointsArray[5]} onChange={onPointsChange} min={0} /></td>
				</tr>
				{/* § 6.1.3 */}
				<tr>
					<td>Tytuły finalisty konkursu przedmiotowego uzyskane w zawodach wiedzy będących konkursem o zasięgu wojewódzkim organizowanych przez kuratora oświaty</td>
					<td>
						<select name="6" onChange={onPointsChange} value={examPointsArray[6]}>
							<option value={0}>brak</option>
							<option value={7}>jeden</option>
							<option value={10}>dwa lub więcej</option>
						</select>
					</td>
				</tr>
				<tr>
					<td>Tytuły laureata konkursu tematycznego lub interdyscyplinarnego uzyskane w zawodach wiedzy będących konkursem o zasięgu wojewódzkim organizowanych przez kuratora oświaty</td>
					<td>
						<select name="7" onChange={onPointsChange} value={examPointsArray[7]}>
							<option value={0}>brak</option>
							<option value={5}>jeden</option>
							<option value={7}>dwa lub więcej</option>
						</select>
					</td>
				</tr>
				<tr>
					<td>Tytuły finalisty konkursu tematycznego lub interdyscyplinarnego uzyskane w zawodach wiedzy będących konkursem o zasięgu wojewódzkim organizowanych przez kuratora oświaty</td>
					<td>
						<select name="8" onChange={onPointsChange} value={examPointsArray[8]}>
							<option value={0}>brak</option>
							<option value={3}>jeden</option>
							<option value={5}>dwa lub więcej</option>
						</select>
					</td>
				</tr>
				{/* § 6.1.4 */}
				<tr>
					<td>Tytuły finalisty konkursu przedmiotowego uzyskane w zawodach wiedzy będących konkursem o zasięgu ponadwojewódzkim lub wojewódzkim, przeprowadzanym zgodnie z przepisami wydanymi na podstawie art. 22 ust. 6 ustawy o systemie oświaty</td>
					<td>
						<select name="9" onChange={onPointsChange} value={examPointsArray[9]}>
							<option value={0}>brak</option>
							<option value={7}>jeden</option>
							<option value={10}>dwa lub więcej</option>
						</select>
					</td>
				</tr>
				<tr>
					<td>Tytuły finalisty konkursu interdyscyplinarnego uzyskane w zawodach wiedzy będących konkursem o zasięgu ponadwojewódzkim lub wojewódzkim, przeprowadzanym zgodnie z przepisami wydanymi na podstawie art. 22 ust. 6 ustawy o systemie oświaty</td>
					<td>
						<select name="10" onChange={onPointsChange} value={examPointsArray[10]}>
							<option value={0}>brak</option>
							<option value={5}>jeden</option>
							<option value={7}>dwa lub więcej</option>
						</select>
					</td>
				</tr>
				{/* § 6.1.5 */}
				<tr>
					<td>Wysokie miejsca lub zwycięskie tytuły w zawodach wiedzy innych niż wymienione powyżej, artystycznych lub sportowych, organizowanych przez kuratora oświaty lub inne podmioty działające na terenie szkoły, na szczeblu międzynarodowym</td>
					<td><input type="number" name="11" value={examPointsArray[11]} onChange={onPointsChange} min={0} /></td>
				</tr>
				<tr>
					<td>Wysokie miejsca lub zwycięskie tytuły w zawodach wiedzy innych niż wymienione powyżej, artystycznych lub sportowych, organizowanych przez kuratora oświaty lub inne podmioty działające na terenie szkoły, na szczeblu krajowym</td>
					<td><input type="number" name="12" value={examPointsArray[12]} onChange={onPointsChange} min={0} /></td>
				</tr>
				<tr>
					<td>Wysokie miejsca lub zwycięskie tytuły w zawodach wiedzy innych niż wymienione powyżej, artystycznych lub sportowych, organizowanych przez kuratora oświaty lub inne podmioty działające na terenie szkoły, na szczeblu wojewódzkim</td>
					<td><input type="number" name="13" value={examPointsArray[13]} onChange={onPointsChange} min={0} /></td>
				</tr>
				<tr>
					<td>Wysokie miejsca lub zwycięskie tytuły w zawodach wiedzy innych niż wymienione powyżej, artystycznych lub sportowych, organizowanych przez kuratora oświaty lub inne podmioty działające na terenie szkoły, na szczeblu powiatowym</td>
					<td><input type="number" name="14" value={examPointsArray[14]} onChange={onPointsChange} min={0} /></td>
				</tr>
			</tbody>
		</table>
    )
}

export default ExtraRowExpandable;