import React, {useEffect, useContext} from 'react';

import Breadcrumbs from 'components/commons/Breadcrumbs/Breadcrumbs';
import Collapse from 'components/commons/Collapse/Collapse';
import StaticPage from 'components/reusable/StaticPage/StaticPage';
import Banner from 'components/commons/Banner/Banner';

import { useTranslation } from 'react-i18next';
import { AppContext } from 'context/appContext';
import { Page } from 'components/commons/Page/Page';

import * as Text from 'components/commons/Text/Text';

const Help = () => {
    const { t } = useTranslation();

    const { setBreadcrumbsPath } = useContext(AppContext);

    useEffect(() => {
        setBreadcrumbsPath([
            { 
                name: t('breadcrumbs.help'),
            },
        ])
    }, [])

    return (
        <Page>
            <Breadcrumbs />
            <Banner />
            <StaticPage title={t('menu.help')}>
                { t('help:questions', { returnObjects: true }).map((item) => (
                    <Collapse title={item.title} key={item.title} id={item.title}>
                        <Text.Content>{item.content}</Text.Content>
                    </Collapse>
                )) }
            </StaticPage>
        </Page>
    )
};

export default Help;