import { useAuth } from "react-oidc-context";
import { useParams } from "react-router-dom";

const useAuthentication = () => {
  const auth = useAuth();
  const { city, generalType } = useParams();

  const login = () => {
      console.log("LOGIN " + window.location.origin + window.location.pathname + '?a=11');
    let loginSuccess = true;
    auth.signinRedirect({
      redirect_uri: window.location.origin + window.location.pathname + '?a=11',
      extraQueryParams: {
        region: city,
        gt: generalType,
      },
    });
    if (auth.error) {
        console.log(auth.error);
        loginSuccess = false;
    }
    return loginSuccess;
  };

  const register = () => {
    let url = new URL(auth.settings.authority);
    url.pathname = "/Account/Register";

    let returnUrl = new URL(window.location.origin + window.location.pathname);

    returnUrl.searchParams.append("acr_values", auth.settings.acr_values);
    returnUrl.searchParams.append("region", city);
    returnUrl.searchParams.append("gt", generalType);

    url.searchParams.append("returnUrl", returnUrl.toString());
    console.log(url.toString());

    //console.log(auth.settings);

    window.location = url.toString();
  };

  const changePassword = () => {
    let url = new URL(auth.settings.authority);
    url.pathname = "/Account/ChangePassword";

    let returnUrl = new URL(window.location.origin + window.location.pathname);

    returnUrl.searchParams.append("acr_values", auth.settings.acr_values);
    returnUrl.searchParams.append("region", city);
    returnUrl.searchParams.append("gt", generalType);

    url.searchParams.append("returnUrl", returnUrl.toString());

    window.location = url.toString();
  };

  const forgotPassword = () => {
    let url = new URL(auth.settings.authority);
    url.pathname = "/Account/ForgotPassword";

    let returnUrl = new URL(window.location.origin + window.location.pathname);

    returnUrl.searchParams.append("acr_values", auth.settings.acr_values);
    returnUrl.searchParams.append("region", city);
    returnUrl.searchParams.append("gt", generalType);

    url.searchParams.append("returnUrl", returnUrl.toString());

    console.log(url.toString());

    window.location = url.toString();
  };

  const renewToken = () => {};

  const logout = () => {
    auth.signoutRedirect({
      post_logout_redirect_uri:
        window.location.origin + window.location.pathname,
    });
  };

  return {
    auth,
    login,
    register,
    changePassword,
    forgotPassword,
    user: auth.user,
    renewToken,
    logout,
    isAuthenticated: auth.isAuthenticated,
    isLoading: auth.isLoading,
    access_token: auth?.user?.access_token,
  };
};

export default useAuthentication;
