import { forwardRef, useEffect, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext, Controller } from 'react-hook-form';
import styled from 'styled-components';
import Label from './elements/Label';
import Error from './elements/Error';

import { ApplicationContext } from '../Application';

const CheckboxForm = forwardRef(({
    name,
    label,
    onChange = null,
    isRequired,
    disabled,
    options,
    defaultValue,
    ...rest
}, ref) => {
    const { t } = useTranslation();
    const { control, getValues, setValue, trigger } = useFormContext();

    const { formValidationMethods: 
        { 
            removeFieldFromValidation, 
            addFieldToValidation
        },
    } = useContext(ApplicationContext);

    useEffect(() => {
        if (options && Object.keys(options)?.length > 0) {
            const initialValue = getValues(name);
            if (!Object.values(options).includes(initialValue)){
                setValue(name, options.false, { shouldDirty: false });
            }
        }
    }, []);

    useEffect(() => {
        if (isRequired) {
            addFieldToValidation(name);
        } else {
            removeFieldFromValidation(name);   
        }
    }, [isRequired]);

    const fieldValidation = (val) => {
        if (!isRequired) return true;

        if (options && Object.keys(options)?.length) {
            if (val !== options?.true) {
                return t('application.errors.fieldMustBeChecked');
            }
            return true;
        }
        if (val !== true) {
            return t('application.errors.fieldMustBeChecked');
        }
        return true;
    }

    const generatedID = useMemo(() => {
        if (!name) return `checkbox-${Math.random()}`;
        return name;
    }, [name]);

    return (
        <Wrapper>
            <Controller
                name={name || ''}
                control={control}
                rules={{ 
                    validate: fieldValidation
                }}
                render={({ field, fieldState: { error } }) => {
                    const isFieldChecked = () => {
                        if (options && Object.keys(options)?.length) {
                            return field.value === options.true;
                        }
                        return field.value;
                    }

                    const fieldOnChange = (e) => {
                        const checked = e.target.checked;
                        if (onChange){
                            onChange(checked);
                        }
                        if(field.onChange) {
                            if (options && Object.keys(options)?.length) {
                                field.onChange(checked ? options.true : options.false);
                            } else {
                                field.onChange(checked);
                            }
                        }
                    }

                    return (
                        <>
                            <LabelWrapper>
                                <CheckboxInput
                                    type="checkbox"
                                    {...field}
                                    {...rest}
                                    onChange={fieldOnChange}
                                    disabled={disabled}
                                    checked={isFieldChecked()}
                                    id={generatedID}
                                    onBlur={(ev) => {
                                        if (isRequired && name) {
                                            trigger(name);
                                        }
                                        field.onBlur(ev);
                                    }}
                                />
                                <Label
                                    label={label}
                                    isRequired={isRequired}
                                    htmlFor={generatedID}
                                    cursorPointer={disabled ? false : true}
                                />
                            </LabelWrapper>
                            <Error error={error} />
                        </>
                )}}
            />
        </Wrapper>
    )
});

export default CheckboxForm;


const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    font-size: 1.4rem;
    font-weight: 400;
    color: ${({ theme }) => theme.colors.black};
`;

const LabelWrapper = styled.label`
    display: flex;
`;

export const CheckboxInput = styled.input`
    cursor: pointer;
    margin-right: 0.5rem;
`;