import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Styled from './PreferencesSection.styles';

import Loader from 'components/commons/Loader/Loader';
import { Notification } from "components/commons/Notification/Notification";
import ModalUnit from 'components/Unit/ModalUnit/ModalUnit.js';
import AvailableUnit from './AvailableUnit';

import ModalBranch from 'components/Unit/ModalUnit/ModalBranch.js';

const PreferencesAvailableList = ({
    selectedBranches,
    selectedUnits,
    units,
    onSelectBranch,
    unitsLoading,
    predefinedUnit,

    maxUnits,
    maxBranches,

    // childAgeInMonths = null,

    alwaysShowBranches = false,

    showNoAgeBranchesInfo = false,
}) => {
    const { t } = useTranslation();
    const [unitModalId, setUnitModalId] = useState(null);
    const [branchModalId, setBranchModalId] = useState(null);

    const [collapseAll, setCollapseAll] = useState(false);

    return (
        <Styled.AvailableContainer
            className='available-preferences'
        >
            {/* MODALS */}
            {branchModalId && (
                <>
                    <ModalBranch
                        branchId={branchModalId}
                        onHideModal={() => setBranchModalId(null)}
                    />
                </>
            )}
            {unitModalId && (
                <>
                    <ModalUnit
                        unitId={unitModalId}
                        onHideModal={() => setUnitModalId(null)}
                    />
                </>
            )}

            <Styled.AvailableHead className='available-head'>
                <Styled.Element toLeft={true}>{t('application.preferences.unitNameAddress')}</Styled.Element>
                <Styled.Element>{t('application.preferences.unitDescription')}</Styled.Element>
                <Styled.Element>{t('application.preferences.select')}{selectedBranches?.length && selectedBranches?.length > 0 ? ` (${selectedBranches.length})` : ''}</Styled.Element>
                <Styled.Element 
                    onClick={() => setCollapseAll(state => !state)}
                >
                    {collapseAll  ? t('application.preferences.expandAll') : t('application.preferences.collapseAll')}
                </Styled.Element>
            </Styled.AvailableHead>

            { unitsLoading && <Loader /> }

            { !unitsLoading && units.length === 0 && showNoAgeBranchesInfo && (
                <h1>{t('application.preferences.noUnitMatchAge')}</h1>
            )}

            {!unitsLoading && (
                (units) ? (
                    units.map((unit) => {
                        const unitId = unit?.id;
                        // if (!unitId) {
                        //     return null;
                        // }
                        return (
                            <AvailableUnit
                                key={unitId}
                                unit={unit}
                                onSelectBranch={onSelectBranch}
                                descriptionButtonText={t('application.preferences.seeUnitDescription')}
                                branchDescriptionText={t('application.preferences.seeBranchDescription')}
                                setUnitModalId={setUnitModalId}
                                setBranchModalId={setBranchModalId}
                                selectedBranches={selectedBranches}
                                isPredefinedUnit={predefinedUnit === unitId?.toString() || false}

                                disabled={selectedUnits.length >= maxUnits && !selectedUnits.includes(unitId)}
                                maxBranches={maxBranches}

                                alwaysShowBranches={alwaysShowBranches}

                                collapseAll={collapseAll}
                            />
                    )
                })
                ) : (
                    <Notification type="warning">
                        {t("units:notFound")}
                    </Notification>
                )
            )}
        </Styled.AvailableContainer>
    )
}

export default PreferencesAvailableList;