import styled from "styled-components";

export const NewsListing = styled.div`
    margin-top: 50px;
    padding-bottom: 200px;
`

export const NewsItem = styled.div`
    padding-bottom: 50px;
`

export const NewsItemHead = styled.div`
    padding-bottom: 20px;
    border-bottom: 1px solid ${({theme}) => theme.colors.main}35;
`

export const NewsItemSubhead = styled.div`
    margin-top: 20px;
`

export const NewsItemContent = styled.div`
    margin-top: 35px;
`

export const News = styled.div`
    margin-top: 50px;
`

// export const News = styled.div`

// `