import styled, { css } from "styled-components";
import chevron from "assets/images/chevron-1.svg";

export const Select = styled.div.attrs({ tabIndex: 0 })`
  display: grid;
  position: relative;
  grid-template-areas: "select";
  background-color: transparent;
  border: 0.5px solid ${({ theme }) => theme.colors.accent};
  border-radius: 4px;
  min-width: 32rem;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white};
  font-size: 1.6rem;
  z-index: 99;
  outline: none;

  & ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.main};
  }

  & ::-webkit-scrollbar {
    width: 0.5rem;
  }

  /* Track */
  & ::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.colors.backgroundLight};
  }

  /* Handle on hover */
  & ::-webkit-scrollbar-thumb:hover {
    background: ${({ theme }) => theme.colors.accent};
  }

  ${({disabled}) =>
    disabled &&
    css`
      background-color: ${({ theme }) => theme.colors.grey5};
    `}

  ${({round}) =>
    round &&
    css`
      border-radius: 2.8rem;
    `}

    ${({open}) =>
    open &&
    css`
      border-color: ${({ theme }) => theme.colors.accent};
    `}

    &:focus {
    border: 1px solid ${({theme}) => theme.colors.backgroundPrime};
    box-shadow: 0px 0px 10px #00000033;
  }

  &:focus-within {
    z-index: 999;
  }

  &:hover {
    ${({open}) =>
      !open &&
      css`
        border-color: ${({ theme }) => theme.colors.unitAddressColor};
      `}
    box-shadow: 0px 0px 15px #00000057;
  }

  &::after {
    grid-area: select;
    justify-self: end;
    content: "";
    width: 1.7rem;
    height: 1rem;
    margin-right: 1.6rem;
    background-image: url(${chevron});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    ${({open}) =>
      open &&
      css`
        transform-origin: center;
        transform: rotate(180deg);
      `}

    ${({disabled}) =>
      disabled &&
      css`
        background-color: ${({theme}) => theme.colors.grey30};
      `}
  }

  & ul {
    list-style: none;
    z-index: 2;
    background-color: ${({ theme }) => theme.colors.white};
    width: calc(100% - 2.8rem);
    margin: auto;
    padding: 1rem 0;
    line-height: 3.4rem;
    border-top: 0.5px solid ${({theme}) => theme.colors.accent};
    margin: 0 1.4rem 0.9rem 1.4rem;
    max-height: 42rem;
    overflow-x: hidden;

    & li {
    }
  }
`;

export const SelectListItem = styled.li`
  user-select: none;
  cursor: pointer;
  padding: 0.1rem 1.2rem;

  &:hover,
  &:focus {
    background-color: ${({ theme }) => theme.colors.backgroundPrime};
  }

  ${({active}) =>
    active &&
    css`
      background-color: ${({ theme }) => theme.colors.backgroundPrime};
      border: 2px solid ${({ theme }) => theme.colors.main};
    `}

  & img {
    display: none;
  }

  ${({selected}) =>
    selected &&
    css`
      background-color: ${({ theme }) => theme.colors.selectItemBackground};
      & img {
        display: inline-block;
        width: 1em;
        margin-right: 1.6em;
      }
    `}

  & a {
    display: flex;
    justify-content: space-between;
    border: none;
    background-color: transparent;

    &:focus-visible {
      outline: none;
    }
  }
  & a span {
    font-size: 1.4em;
  }
`;

export const SelectLabelButton = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1.8rem 4.6rem 1.8rem 2.6rem;
  margin: 0;
  width: 100%;
  cursor: pointer;
  line-height: 1.1;
  outline: none;
  grid-area: select;
  cursor: pointer;
  overflow: hidden;
  z-index: 1;

  & img {
    width: 1em;
  }
`;

export const SelectLabelButtonContent = styled.span`
  overflow: hidden;
`;

export const SelectLabelButtonMulti = styled.span`
  display: flex;
  gap: 1rem;
  flex-wrap: nowrap;
  overflow: hidden;
`;

export const SelectLabelButtonItem = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  align-items: stretch;
  gap: 0.5em;
  font-weight: bold;

  & span {
    display: flex;
    align-items: center;
    &:last-child {
      flex-shrink: 0;
    }
  }
`;

export const SelectLabel = styled.p`
  font-size: 1.4rem;
  color: ${({ theme }) => theme.colors.grey60};
  margin-bottom: 3px;
  cursor: default;
`;
