import PersonalInfo from "./parts/PersonalInfo";

import DeleteAccount from "pages/Account/elements/DeleteAccount";

import * as Styled from "./TrustedProfile.styles";

const TrustedProfile = ({profile}) => {
  return (
    <Styled.TrustedProfileContainer>
          <PersonalInfo profile={profile} />
        <DeleteAccount/>
    </Styled.TrustedProfileContainer>
  )
};

export default TrustedProfile;
