import styled from "styled-components";

import { Input as InputDefault } from "components/commons/Input/Input.styles";
import { Link as LinkDefault } from "components/commons/Link/Link.styles";

export const ModalContent = styled.section`
  color: ${({ theme }) => theme.colors.black};
  display: flex;
  flex-direction: column;

  ${({ isDeleteAccountModal }) =>
  isDeleteAccountModal &&
  `
  background-color: red; /* Przykładowy styl */
`}
`;

export const ModalHead = styled.h2`
  font-size: 1.8rem;
  margin-bottom: 3rem;
  font-weight: 800;
  @media ${({ theme: { device } }) => device.tablet} {
    font-size: 2rem;
  }
`;
export const ModalText = styled.p`
  font-size: 1.6rem;
  font-weight: 400;
  margin-bottom: 3rem;
`;
export const ModalButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column-reverse;
  gap: 2rem;
  align-items: center;
  @media ${({ theme: { device } }) => device.tablet} {
    flex-direction: row;
    justify-content: flex-end;
  }
`;
// Style for Delete Account Modal Confirm
export const Input = styled(InputDefault)`
  margin-bottom: 2rem;
`;

export const Label = styled.label`
    font-size: 1.6rem;
    font-weight: 400;
    margin-bottom: 1rem;
    `;

export const Link = styled(LinkDefault)`
  margin-bottom: 2rem;
`;

