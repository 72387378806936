import React from 'react';
import PropTypes from 'prop-types';
import * as Styled from './Button.styles';

export const ButtonText = ({
    children,
    disabled,
    ...props
}) => {
  return (
    <Styled.ButtonText
      disabled={disabled}
      {...props}
    >
      {children}
    </Styled.ButtonText>
  );
};

ButtonText.propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
};

ButtonText.defaultProps = {
  disabled: false,
  onClick: undefined,
};
