import { useMemo, useContext, useEffect, memo } from 'react';
import { useFormContext } from "react-hook-form";
import styled from 'styled-components';

import { useFormFiller } from 'utils/formFiller/useFormFiller';

import FieldFactory from '../fields/FieldFactory';
import TextBeforeSave from "../texts/TextBeforeSave";

import { ApplicationContext } from '../Application';

const SummarySection = ({ data: { questions, fields} }) => {
    const { instance } = useContext(ApplicationContext);
    const { getValues } = useFormContext();
    const application = getValues();
    useFormFiller(questions);
    return (
        <SummarySectionWrapper
            className='questions-wrapper'
        >
            <TextBeforeSave instance={instance} application={application} />
            {
              fields &&
              fields.map((field) => {
                return <FieldFactory field={field} key={field.name} />;
              })
            }
            {
                (questions) && questions.map((question) => {
                    return (
                        <QuestionFactory
                            question={{
                                ...question,
                            }}
                            key={`quest: ${question.uid}`}
                        />
                    )
                })
            }
            <p className='required-info'><span className='asterisk'>*</span>&nbsp;Pole obowiązkowe</p>
        </SummarySectionWrapper>
    )
}

export default SummarySection;

const QuestionFactory = memo(({
    question,
}) => {
    return (
        <>
            <FieldFactory
                field={{
                    ...question,
                    name: `Answers.${question.uid}`,
                }}
            />
        </>
    );
}, (prevProps, nextProps) => {
    return JSON.stringify(prevProps) === JSON.stringify(nextProps);
});

const SummarySectionWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4rem;

    border-top: 1px solid #00318954;
    padding-top: 3rem;

    border-bottom: 1px solid #00318954;
    margin-bottom: -1px;
    padding-bottom: 1rem;

    & .required-info {
        font-size: 1.4rem;
        color: ${({theme}) => theme.colors.black};
        >.asterisk {
            color: red;
            font-size: 1.6rem;
        }
    }

    & label {
        font-size: 1.6rem;
        font-weight: 600;
        line-height: 2.5rem;
    }
    & .radio-label {
        font-weight: 400;
        /* color: yellow; */
    }
    & .red {
        color: red;
    }
    & .blue {
        color: blue;
    }
`;