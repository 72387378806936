import styled, { css } from "styled-components";
import { NavLink } from "react-router-dom";

export const InstanceMenu = styled.div`
  background-color: #f2f4f9;
  padding: 2rem 0;
  display: none;
  border-top: 1px solid ${({ theme }) => theme.colors.unitAddressColor};
  margin-bottom: 2rem;

  & > div {
    width: 100%;
  }

  @media ${({ theme }) => theme.device.desktop} {
    display: flex;
    justify-content: space-between;
  }

  & ul {
    list-style-type: none;
    display: flex;
    gap: 6rem;
  }

  & ul:not(:first-child) a {
    ${(props) =>
        props.backgroundPicture &&
        css`
        color: ${({ theme }) => theme.colors.white};
      `}
  }

  // This span is for "create account" and "login" buttons in mobile menu //
  & span {
    &:hover, &:focus {
      text-decoration: underline;
    }

    &:focus, &:focus-visible {
      outline: 1.5px solid ${props => (props.reverse === true ? props.theme.colors.white : props.theme.colors.grey90)};
      outline-offset: 2px;
      padding: 5px;
      border-radius: 5px;
    }
  }

  @media ${({ theme }) => theme.device.touch} {
    display: flex;
    background-color: initial;
    padding: 0;
    margin: 0;
    border-top: none;

    & ul {
      display: flex;
      flex-direction: column;
      gap: 1.5rem;
    }

    & ul li:first-child {
      display: none;
    }
    & > div {
      margin: 1.5rem 0 0;
    }
  }
`;

const LinkStyle = css`
    font-size: 2rem;
    @media ${({ theme }) => theme.device.touch} {
        font-size: 1.6rem;
    }

    &:hover, &:focus {
        text-decoration: underline;
    }

    &:focus, &:focus-visible {
      outline: 1.5px solid ${props => (props.reverse === true ? props.theme.colors.white : props.theme.colors.grey90)};
      outline-offset: 2px;
      padding: 0.1rem;
      border-radius: 5px;
  }
`;

export const StyledNavLink = styled(NavLink)`
    ${LinkStyle}
    &.active {
        pointer-events: none;
        cursor: default;
    }
`;

export const MyAccountMenu = styled.div`
  margin-top: 10px;

  @media ${({ theme }) => theme.device.desktop} {
    display: none;
  }
`;

export const AuthItems = styled.li`
  list-style-type: none;
  padding: 9px 0;
  color: ${({ theme }) => theme.colors.main};

    &.logout {
      margin-top: 10px;
    }
`;

export const NavHandler = styled.span`
    ${LinkStyle}
    cursor: pointer;
`;
