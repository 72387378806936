import * as React from 'react';
import { useRouteError } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Link } from 'components/commons/Link/Link';

import ContentBlock from 'components/error-page/ContentBlock/ContentBlock';

import url from 'routes/routes';

import styled from "styled-components";

var Container = styled.div`
    margin: 20px;
`;

const Error403 = () => {
    const { t } = useTranslation();

    const error = useRouteError();
    if (error) console.error(error);

    return (
        <Container>
            <ContentBlock 
                title={`${t('error.error')}: 403`} 
                subtitle={"Żądany zasób nie jest już dostępny"}
            >
                {"Wróć na "} <Link to={url.home}>{t('error.notFoundText2')}</Link>
            </ContentBlock>
        </Container>
    )
};

export default Error403;