import React, { useState, useEffect, useMemo} from 'react';

import Modal from 'components/commons/Modal/Modal';

import * as Styled from './Gallery.styles';

import useScreenSize from 'hooks/useScreenSize';

const GalleryModal = ({ images, startImageIndex, hideModal }) => {
    const [currentImageIndex, setCurrentBigImageIndex] = useState(startImageIndex);
    const [loadedNaturalSize, setLoadedNaturalSize] = useState({ width: 0, height: 0 });
    const screenSize = useScreenSize();

    const bigImageUrl = images[currentImageIndex];

    const onImageClick = (e) => {
        e.stopPropagation();
    };

    const onNextImage = (e) => {
        e.stopPropagation();
        setCurrentBigImageIndex(state => (state + 1 === images.length) ? 0 : state + 1);
    }
    const onPrevImage = (e) => {
        e.stopPropagation();
        setCurrentBigImageIndex(state => (state === 0) ? images.length - 1 : state - 1);
    }

    const calcImageSize = useMemo(() => {
        const calcDouble = {
            width: Math.min(screenSize.width * 0.9, loadedNaturalSize.width * 2),
            height: Math.min(screenSize.height * 0.9, loadedNaturalSize.height * 2),
        };
        const doubleRatio = calcDouble.width / calcDouble.height;
        const naturalRatio = loadedNaturalSize.width / loadedNaturalSize.height;
        if (naturalRatio > doubleRatio) {
            return ({ width: calcDouble.width, height: calcDouble.width / naturalRatio })
        }
        return ({ width: calcDouble.height * naturalRatio, height: calcDouble.height })
    }, [screenSize, loadedNaturalSize]);

    return (
        <Modal closeModal={hideModal}>
            {(modalClose) => (
                <Styled.ModalContent>
                    <Styled.ModalImageWrapper
                        style={{
                            maxWidth: `${calcImageSize.width}px`,
                            maxHeight: `${calcImageSize.height}px`,
                        }}
                    >
                        {bigImageUrl && (
                            <>
                                {images.length > 1 && (
                                    <>
                                        <Styled.ModalNextBt
                                            onClick={onNextImage}
                                        />
                                        <Styled.ModalPrevBt
                                            onClick={onPrevImage}
                                        />
                                    </>
                                )}

                                <Styled.ModalCloseBt
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        modalClose();
                                    }}
                                />
                                <img
                                    src={bigImageUrl}
                                    onLoad={(event) => {
                                        setLoadedNaturalSize({
                                        width: event.target.naturalWidth,
                                        height: event.target.naturalHeight,
                                    })}}
                                    alt='big'
                                    onClick={onImageClick}
                                />
                            </>
                        )}
                    </Styled.ModalImageWrapper>

                </Styled.ModalContent>
            )}
        </Modal>
    )
}

export default GalleryModal;