import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { globalConfig } from "configuration/globalConfig";

import useApi from "hooks/useApi";
import { getDate } from 'utils/utils';

// import { Link } from 'components/commons/Link/Link';
// import { Link } from 'react-router-dom';

import { Table, TableHead, TableRow, TableCol } from '../Table/Table';

import { useInstance } from 'hooks/useInstance';

import * as Styled from './DocumentsListing.styles';
import * as Layout from 'components/commons/Layout/Layout';
import * as Text from 'components/commons/Text/Text';

const DocumentsListing = ({type}) => {
    const {t} = useTranslation();
    const [ docs, setDocs ] = useState([]);
    const { offerApi } = useApi();

    const getDocs = async (id) => {
        const { data, error } = await offerApi.get(`instance/${id}/documents`);
        let documents = data;
        if (documents && Array.isArray(documents) && documents.length > 0) {
            documents = documents.filter((doc) => (doc.id_DictDocumentType === type));
        }
        setDocs(documents);
    }

    const { id: instanceId } = useInstance([{ name: t('breadcrumbs.documents') }]);


 
    useEffect(() => {
        if (instanceId){
            getDocs(instanceId);
        }
    }, [instanceId]);    

    return (
        <Styled.DocumentsListing>
            <Layout.Container>  

                {docs.length ? (
                    <Table>
                        <TableHead>
                            <TableCol>
                                <Styled.DocumentsListHeader>
                                    <Text.Title white bold>{t('documents:colName')}</Text.Title>
                                    <Text.Title white>({t('documents:clickToDownload')})</Text.Title>
                                </Styled.DocumentsListHeader>
                            </TableCol>
                            <TableCol>
                                <Text.Title white>{t('documents:colDate')}</Text.Title>
                            </TableCol>
                        </TableHead>

                        {docs.map((item) => (
                            <TableRow key={item.id}>
                                <TableCol>
                                    <Styled.Link reloadDocument={true}  to={`${globalConfig.get().REACT_APP_OFFER_API_ENDPOINT}/document/${item.id}`}>{item.name}</Styled.Link>
                                    { item.description && <Text.Content>{item.description}</Text.Content>}
                                </TableCol>
                                <TableCol>
                                    <Styled.DocumentsDateHeader>
                                        <Styled.DocumentsTitleMobile><Text.Title>{t('documents:colDate')}</Text.Title>:</Styled.DocumentsTitleMobile>
                                        <Text.Content>{getDate(item.createdDate)}</Text.Content>
                                    </Styled.DocumentsDateHeader>
                                </TableCol>
                            </TableRow>
                            ))}
                    </Table>
                ) : (
                    <div></div>
                )}
                
                
            </Layout.Container>
        </Styled.DocumentsListing>
    )
};

export default DocumentsListing;