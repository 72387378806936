import React, {useContext} from 'react';
import SectionFactory from '../sections/SectionFactory';

const SummaryModule = ({ data }) => {
    let sections = data.sections;
    const sectionsExists = sections?.length > 0;
    if (!sectionsExists) {
        sections = [{
            "section": "Zakończ",
            "type": "SummarySection",
            "condition": null,
            "fields": [],
            "questions": []
        }];
    }
    return (
        <>
            {sections.map((section, index) => {
                return (
                    <SectionFactory
                        section={{...section, type: 'SummarySection'}}
                        key={`${section.type}__${index}`}
                    />
                )
            })}
        </>
    );
}

export default SummaryModule;