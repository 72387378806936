import styled from "styled-components";
import { Link } from "react-router-dom";

export const BranchPage = styled.div`
  margin-bottom: 18.3rem;
  & .branch-content {
    margin: 6rem 0;
  }
`;

export const BranchName = styled.span`
  font-weight: normal;
`;

export const BranchSectionHeading = styled.p`
  position: relative;
  color: ${({ theme }) => theme.colors.grey90};
  font-weight: 600;
  font-size: 2rem;
  padding-bottom: 1.5rem;

  @media ${({ theme }) => theme.device.mobile} {
    font-size: 1.8rem;
    padding-bottom: 1.1rem;
  }

  &::after {
    content: "";
    display: block;
    position: absolute;
    height: 1px;
    bottom: 0;
    background-color: #1a76cb;
    opacity: 0.35;
    right: 0;
    left: 0;

    @media ${({ theme }) => theme.device.mobile} {
      right: -24px;
      left: -24px;
    }
  }
`;

export const BranchSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5.8rem;
  color: ${({ theme }) => theme.colors.grey90};

  @media ${({ theme }) => theme.device.touch} {
		width: 100%;
	}
`;

export const BranchSection = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 1.2rem;
  gap: 1.5rem;
`;

export const BranchSectionList = styled.ul`
  list-style-type: none;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  font-weight: 600;
`;

export const BranchSectionDict = styled.div`
	display: grid;
	grid-template-columns: 24rem auto;
	gap: 8px;

	@media ${({ theme }) => theme.device.mobile} {
		grid-template-columns: 1fr;
	}
`;

export const BranchBackLink = styled(Link)`
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
  gap: 0.9rem;

  &:hover {
    text-decoration: underline;
  }
`;
