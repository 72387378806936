import React, { useContext } from "react";

import { Link} from "react-router-dom";
import { useTranslation } from "react-i18next";

import { AppContext } from "context/appContext";

import DropletThumbnail from "components/reusable/DropletThumbnail/DropletThumbnail";

import * as Styled from "./UnitTypesSection.styles";
import * as Layout from "components/commons/Layout/Layout";
import * as Text from "components/commons/Text/Text";

import dugt_1 from "assets/images/dugt-1.jpg";
import dugt_2 from "assets/images/dugt-2.jpg";
import dugt_3 from "assets/images/dugt-3.jpg";
import dugt_5 from "assets/images/dugt-5.jpg";
import dugt_6 from "assets/images/dugt-6.png";
import dugt_7 from "assets/images/dugt-7.jpg";
import dugt_9 from "assets/images/dugt-9.jpg";
import dugt_13 from "assets/images/dugt-13.jpg";
import dugt_14 from "assets/images/dugt-14.jpg";


import {UnitGeneralTypes, UnitTypes} from "utils/enums";

import * as CommonStyled from "styles/common.styles";

const UnitTypesSectionMain = () => {
  const { t } = useTranslation("default");
  const { instances } = useContext(AppContext);

  const dutiesExists = instances?.some((instance) => instance.unitGeneralType == UnitGeneralTypes.HolidayDuty );
  const semicolonyExists = instances?.some((instance) => instance.unitGeneralType == UnitGeneralTypes.Semicolony );
  const cultureExists = instances?.some((instance) => instance.unitGeneralType == UnitGeneralTypes.Culture );
  const centerExists = instances?.some((instance) => instance.unitGeneralType == UnitGeneralTypes.Center );

  const unitTypesForStart = [
    {
      url: `/${UnitTypes.Nursery}`,
      name: t(`unitTypes.${UnitTypes.Nursery}`),
      kropka: "#FFA0A0",
      obwodka: "#F9D8DD",
      img: dugt_1,
      dropletFrom: "bottom-right",
      dropletTo: "top-right",
    },
    {
      url: `/${UnitTypes.Kindergarten}`,
      name: dutiesExists?  t(`unitTypes.${UnitTypes.Kindergarten}`)+' / '+t(`unitTypes.${UnitTypes.HolidayDuty}`) : t(`unitTypes.${UnitTypes.Kindergarten}`),
      kropka: "#FCAE17",
      obwodka: "#CDDEEE",
      img: dugt_2,
      dropletFrom: "top-left",
      dropletTo: "bottom-left",
    },
    {
      url: `/${UnitTypes.PrimarySchool}`,
      name: semicolonyExists ?  t(`unitTypes.${UnitTypes.PrimarySchool}`)+' / '+t(`unitTypes.${UnitTypes.Semicolony}`) : t(`unitTypes.${UnitTypes.PrimarySchool}`),
      kropka: "#FB693C",
      obwodka: "#FB693C",
      img: dugt_3,
      dropletFrom: "bottom-right",
      dropletTo: "bottom-left",
    },
    {
      url: `/${UnitTypes.SecondarySchool}`,
      name: t(`unitTypes.${UnitTypes.SecondarySchool}`),
      kropka: "#1A76CB",
      obwodka: "#88B8E4",
      img: dugt_9,
      dropletFrom: "top-left",
      dropletTo: "top-right",
    },
    {
      url: `/${UnitTypes.TradeSchool}`,
      name: t(`unitTypes.${UnitTypes.TradeSchool}`),
      kropka: "#02E38C",
      obwodka: "#02E38C",
      img: dugt_5,
      dropletFrom: "top-right",
      dropletTo: "bottom-left",
    },
    {
      url: `/${UnitTypes.Dormitory}`,
      name: t(`unitTypes.${UnitTypes.Dormitory}`),
      kropka: "#003189",
      obwodka: "#FCAE17",
      img: dugt_7,
      dropletFrom: "bottom-left",
      dropletTo: "top-left",
    },
    {
      url: `/${UnitTypes.PostSecondarySchool}`,
      name: t(`unitTypes.${UnitTypes.PostSecondarySchool}`),
      kropka: "green",
      obwodka: "red",
      img: dugt_6,
      dropletFrom: "bottom-left",
      dropletTo: "top-left",
    },
  ];

  if (cultureExists) {
    unitTypesForStart.push({
      url: `/${UnitTypes.Culture}`,
      name: t(`unitTypes.${UnitTypes.Culture}`),
      kropka: "red",
      obwodka: "green",
      img: dugt_14,
      dropletFrom: "bottom-right",
      dropletTo: "top-left",
    })
  }
  if (centerExists) {
    unitTypesForStart.push({
      url: `/${UnitTypes.Center}`,
      name: t(`unitTypes.${UnitTypes.Center}`),
      kropka: "blue",
      obwodka: "yellow",
      img: dugt_13,
      dropletFrom: "top-right",
      dropletTo: "bottom-right",
    })
  }

  return (
    <Styled.UnitTypesSection>
      <Layout.Container narrow>
        <Styled.UnitSectionContainer className="UnitSectionContainer">
          <Styled.Title>
            <Text.TitleMain>{t("unitTypesSection.title")}</Text.TitleMain>
          </Styled.Title>
        <Styled.UnitTypesSectionGrid>
          {unitTypesForStart.map((item) => {
              return (
                <Link
                  key={item.url}
                  to={
                      `/wdrozenia${item.url}`
                  }
                >
                  <Styled.UnitTypesItem>
                    <Styled.UnitTypesHeader>
                      <CommonStyled.Dot color={item.kropka} />
                      <span>{item.name}</span>
                    </Styled.UnitTypesHeader>
                    <DropletThumbnail
                      src={item.img}
                      color={item.obwodka}
                      start={item.dropletFrom}
                      end={item.dropletTo}
                      alt={item.name}
                    />
                  </Styled.UnitTypesItem>
                </Link>
              );
            return null;
          })}
        </Styled.UnitTypesSectionGrid>
        </Styled.UnitSectionContainer>
      </Layout.Container>
    </Styled.UnitTypesSection>
  );
};

export default UnitTypesSectionMain;
