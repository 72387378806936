import React, { memo, useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import { useFormContext, Controller } from "react-hook-form";

import TextFieldForm from "./TextFieldForm";

import DatePicker, { } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import InputMask from "react-input-mask";
import pl from "date-fns/locale/pl";

import calendarIcon from 'assets/icons/ico-calendar.svg';

import { stringToIso, isoToString, parseDateToString, parseDateToIso } from "utils/dateUtils";

import { 
    StyledDateWrapper, 
    DatePickerStyleWrapper, 
    CalendarIcon 
} from './DateInput.styles';


const DateInput = ({ name, disabled, ...rest }) => {
    const { t } = useTranslation();
    const { control, trigger, getValues, setValue } = useFormContext();

    const minDate = rest?.minValue ? new Date(rest?.minValue) : new Date('1900-01-01');
    const maxDate = rest?.maxValue ? new Date(rest?.maxValue) : new Date('2099-12-31');

    useEffect(() => {
        const val = getValues(name);
        if(!val || val.length < 8) return;
        const parsed = parseDateToString(new Date(getValues(name)));
        if (parsed) {
            setValue(name, parsed.replaceAll('.', '-').split('-').reverse().join('-'));
        }
    }, []);

    const validateDateField = value => {
        if (rest.isRequired && (!value || value === '')) {
            return t('application.errors.fieldRequired');
        }
        if (value.length < 10) {
            return t('application.errors.tooShortDate');
        }
        if (value === '0000-00-00') {
            return t('application.errors.dateWrongFormat');
        }
        const parsedDate = new Date(value);
        if (isNaN(parsedDate)) {
            return t('application.errors.invalidDate');
        }
        if (minDate && parsedDate < new Date(minDate)) {
            return `${t('application.errors.minDateExceeded')} ${parseDateToString(minDate)} )`;
        }
        if (maxDate && parsedDate > new Date(maxDate)) {
            return `${t('application.errors.maxDateExceeded')} ${parseDateToString(maxDate)} )`;
        }
        return true;
    }

    return (
        <DatePickerStyleWrapper
            className='date-input-wrapper'    
        >
            <Controller
                control={control}
                name={name}
                defaultValue={''}
                rules={{
                    validate: validateDateField
                }}
                render={({ field, fieldState }) => {
                    return (
                        <DatePicker
                            selected={ validateDateField(field.value) === true ? field.value : new Date() }
                            onChange={(currentDate) => {
                                const parsedToIso = parseDateToIso(currentDate);
                                field.onChange(parsedToIso);
                                trigger(name);
                            }}
                            showYearDropdown
                            showMonthDropdown
                            dateFormat="dd.MM.yyyy"
                            customInput={
                                <MaskedDateField
                                    field={field}
                                    fieldState={fieldState}
                                    {...rest}
                                    disabled={disabled}
                                />
                            }
                            popperPlacement="bottom-start"
                            locale={pl}
                            minDate={minDate}
                            maxDate={maxDate}
                            disabled={disabled}
                        />
                    )
                }}
            />
        </DatePickerStyleWrapper>
    );
};

export default memo(DateInput);


const MaskedDateField = React.forwardRef(({
    field,
    fieldState,
    onClick,
    disabled,
    ...rest
}, ref) => {
    const { trigger } = useFormContext();
    const [enterValue, setEnterValue] = useState(() => {
        const parsed = isoToString(field.value);
        if (parsed) {
            // if there is a defaultValue in the formState - it could be initially wrong
            trigger(field.name);
            return parsed;
        }
        return '';
    });

    useEffect(() => {
        // handle datePicker onChange
        if (field.value) {
            const iso = isoToString(field.value);
            if (iso) {
                setEnterValue(iso);
            }
        }
    }, [field.value]);

    return (
        <StyledDateWrapper>
            <InputMask 
                mask="99.99.9999" 
                value={enterValue} 
                onChange={(ev) => {
                    const val = ev.target.value;
                    setEnterValue(val);
                    field.onChange(stringToIso(val));
                }}
                onBlur={() => trigger(field.name)}
                disabled={disabled}
            >
                {(inputProps) => {
                    return (
                        <TextFieldForm
                            placeholder={'dd.mm.rrrr'}
                            {...inputProps}
                            isRequired={rest?.isRequired}
                            label={rest?.label}
                            description={rest?.description}
                            error={fieldState.error}
                            chevron={
                                <CalendarIcon
                                    onClick={onClick}
                                    src={calendarIcon}
                                    alt='open-calendar-icon'
                                />
                            }
                            disabled={disabled}
                        />
                )}}
              </InputMask>            
        </StyledDateWrapper>
    )
});



// YUP VALIDATION:
// const validString = `string()
    //     .required('To pole jest wymagane')
    //     .min(10, \`${t('application.errors.tooShortDate')}\`)
    //     .test('isValidDate', 'Invalid date', function(value) {    
    //     const [day, month, year] = value.split(".");
    //     const parsedDate = new Date(\`\${year}-\${month}-\${day}\`);
    //     if (isNaN(parsedDate)) {
    //         return this.createError({ message: \`${t('application.errors.invalidDate')}\` });
    //     }
    //     const numParsedDate = parsedDate.getTime();
    //     if (${valMin} !== 0 && numParsedDate < ${valMin}) {
    //         return this.createError({ message: \`${t('application.errors.minDateExceeded')} ${minDate}\` });
    //     }
    //     return true;
    // })`;
