import React, { memo, useEffect } from "react";
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import TextFieldForm from "./TextFieldForm";
import { useFormContext, Controller } from "react-hook-form";

import * as yup from 'yup';

const EmailInput = ({ name, isRequired, defaultValue, ...rest }) => {
    const { t } = useTranslation();
    const { control, trigger, setValue } = useFormContext();


    const emailSchema = yup.string().email(t('application.errors.invalidEmail')).when('$isRequired', ([isRequired], schema) =>
        isRequired ? schema.required(t('application.errors.fieldRequired')) : schema
    );

    const validateEmail = async (value) => {
        try {
            await emailSchema.validate(value, { context: { isRequired } });
        } catch (error) {
            return error.message;
        }
        return true;
    }

    useEffect(() => {
        if (defaultValue) {
            setValue(name, defaultValue);
        }
    }, [defaultValue]);

    return (
        <StyledEmailWrapper>
            <Controller
                name={name}
                control={control}
                defaultValue=''
                rules={{ validate: validateEmail }}
                render={({ field, fieldState }) => (
                    <TextFieldForm
                        {...rest}
                        onChange={(ev) => {
                            let val = ev.target.value;
                            val = val.replace(/[^a-zA-Z0-9.!#$%&'*+/=?^_`{|}~@-]/g, '');
                            field.onChange(val);
                        }}
                        error={fieldState.error}
                        isRequired={isRequired}
                        value={field.value || ''}
                        type="email"
                        onBlur={(ev) => {
                            trigger(field.name);
                            field.onBlur(ev);
                        }}
                        ref={field.ref}
                    />
                )}
            />
        </StyledEmailWrapper>
    );
};

export default memo(EmailInput);

const StyledEmailWrapper = styled.div``;