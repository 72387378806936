import styled from "styled-components";
import chevron from "assets/images/chevron-1.svg";

export const Dropdown = styled.div`
  display: flex;
  flex-direction: column;

  & label {
    font-size: 1.4rem;
    margin-bottom: 0.3rem;
    color: ${({ theme }) => theme.colors.grey60};
  }
`;

export const Chevron = styled.div`
  width: 1.7rem;
  transform: rotate(${({ isExpanded }) => (isExpanded ? "180deg" : "0")});

  &::after {
    content: url(${chevron}) !important;
    clip-path: none !important;
    background-color: transparent !important;
  }
`;
