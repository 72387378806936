import { useState, useRef, useEffect, useMemo } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { isBefore } from 'date-fns'
import { v4 as uuidv4 } from "uuid";

import ApplicationItem from "pages/ApplicationList/elements/ChildrenOverview/ApplicationItem/ApplicationItem";
import inactiveKid from "assets/images/kid-1.svg";
import activeKid from "assets/images/kid-2.svg";

import * as Styled from "pages/ApplicationList/ApplicationList.styles";

const sortOnDates = (array) => {
    return array.sort((a, b) => {
        const aDate = new Date(a?.savedApplications[0]?.modifiedDate || a?.submittedApplications[0]?.modifiedDate);
        const bDate = new Date(b?.savedApplications[0]?.modifiedDate || b?.submittedApplications[0]?.modifiedDate);
        return isBefore(aDate, bDate) ? 1 : -1;
    });
};

const ChildrenOverview = ({
    savedApplications = [],
    submittedApplications = [],
    deleteApplication,
    submitApplication,
    sentVerificationLink,
    requestForWithdrawal,
    removeRequestForWithdrawal,
    signApplication,
    prolongateApplication,
    processApplication,
    acceptApplication,
    recreateApplication,
    copyApplication,
    instanceName,
    newApplicationButton,
    isMobile,
    instanceInfo,
}) => {
    const { t } = useTranslation();
    const { generalType } = useParams();
    const [ searchParams, setSearchParams ] = useSearchParams();

    const mergeArrays = (savedApplications, submittedApplications) => {
        const result = {};

        const addToResult = (obj, arrayName) => {
            const { pesel, identityNr, firstName, lastName, fullName, secondName } =
                obj;
            const keyBase = secondName
                ? `${firstName}-${secondName}-${lastName}`
                : `${firstName}-${lastName}`;
            const key = pesel
                ? `${keyBase}-${pesel}`
                : identityNr
                    ? `${keyBase}-${identityNr}`
                    : keyBase;

            if (!result[key]) {
                result[key] = {
                    pesel,
                    identityNr,
                    fullName,
                    firstName,
                    lastName,
                    uuid: uuidv4(),
                    savedApplications: [],
                    submittedApplications: [],
                };
            }
            result[key][arrayName].push(obj);
        };

        savedApplications.forEach((obj) => addToResult(obj, "savedApplications"));
        submittedApplications.forEach((obj) => addToResult(obj, "submittedApplications"));

        if (Object.keys(result).length < 1) {
            return [];
        }
        const sorted = sortOnDates( Object.values(result) );
        return sorted;
        // sortOnModificationDate(savedApplications).forEach((obj) => addToResult(obj, "savedApplications"));
        // sortOnModificationDate(submittedApplications).forEach((obj) => addToResult(obj, "submittedApplications"));
    };

    const userChildrenList = useMemo(() => {
        const merged = mergeArrays(savedApplications, submittedApplications);
        return merged;
    }, [savedApplications, submittedApplications]);

    const findChildWithApplicationId = (applicationId) => {
        return userChildrenList.find(
            (child) =>
                child.savedApplications.some((app) => app.id === applicationId) ||
                child.submittedApplications.some((app) => app.id === applicationId)
        );
    };

    const [selectedChild, setSelectedChild] = useState(() => {
        const childId = searchParams.get('child');
        const initialChildApplicationId = childId ? parseInt(childId) : null;

        if (initialChildApplicationId) {
            if (findChildWithApplicationId(initialChildApplicationId)) {
                return findChildWithApplicationId(initialChildApplicationId);
            }
        }
        if (userChildrenList.length > 0) {
            return userChildrenList[0]
        }
        return null;
    });

    const activeChildUuid = useMemo(() => selectedChild?.uuid || null, [selectedChild])

    const [showMobilePanel, setShowMobilePanel] = useState(false);

    const handleChildClick = (uuid) => {
        if (activeChildUuid === uuid) {
            setSelectedChild(null);
            return setSearchParams("");
        }
        const child = userChildrenList.find((child) => child.uuid === uuid);
        setSelectedChild(child);
        setSearchParams(
            child.savedApplications[0]?.id
                ? { child: child.savedApplications[0].id }
                : { child: child.submittedApplications[0]?.id }
        );
    };

    const sumOfApplications = (child) => {
        return child.savedApplications.length + child.submittedApplications.length;
    };

    const getDeclination = (generalType, grammaticalNumber, caseType) =>
        `${t(
            `applicationDeclination.${generalType}.${grammaticalNumber}.${caseType}`
        )}`;

    const scrollRef = useRef(null);
    const handleClick = () => {
        if (scrollRef.current) {
            scrollRef.current.scrollIntoView({
                behavior: "smooth",
                block: "start",
                inline: "nearest",
                offsetTop: -500,
            });
        }
    };

    useEffect(() => {
        if (isMobile && selectedChild?.submittedApplications.length > 0) {
            setShowMobilePanel("submittedApplications");
        }
        if (isMobile && selectedChild?.savedApplications.length > 0) {
            setShowMobilePanel("savedApplications");
        }
    }, [selectedChild, isMobile]);

    const applicationTypesForMobile = [
        {
            type: "savedApplications",
            translationKey:
                "myApplications.childrenOverviewMobile.savedApplicationsHead",
        },
        {
            type: "submittedApplications",
            translationKey:
                "myApplications.childrenOverviewMobile.submittedApplicationsHead",
        },
    ];

    return (
        <>
            {userChildrenList.length > 1 && (
                <>
                    <Styled.H5 className="children-overview-title">
                        {t("myApplications.childrenOverview.title")}{" "}
                        {getDeclination(generalType, "plural", "mianownik")}:
                    </Styled.H5>
                    <Styled.ChildrenOverviewWrapper className="children-overview">
                        {userChildrenList.map((child) => (
                            <ChildProfile
                                key={child.uuid}
                                child={child}
                                isActive={child.uuid === activeChildUuid}
                                isIdle={!!activeChildUuid && child.uuid !== activeChildUuid}
                                isMobile={isMobile}
                                onClick={handleChildClick}
                                sumOfApplications={sumOfApplications(child)}
                                handleClick={handleClick}
                                getDeclination={getDeclination}
                                generalType={generalType}
                            />
                        ))}
                    </Styled.ChildrenOverviewWrapper>
                </>
            )}
            <Styled.ChildrenOverviewButtonContainerMobile ref={scrollRef}>
                {newApplicationButton}
            </Styled.ChildrenOverviewButtonContainerMobile>
            {!isMobile && selectedChild && (
                <>
                    <Styled.H2 className="child-name-desktop">
                        {getDeclination(generalType, "plural", "mianownik")} dla:{" "}
                        {selectedChild.firstName} {selectedChild.lastName}
                    </Styled.H2>
                    {["savedApplications", "submittedApplications"].map((appType) =>
                        selectedChild[appType].map((application, index) => (
                            <ApplicationItem
                                key={`${appType}-${index}`}
                                savedApplications={
                                    appType === "savedApplications" ? [application] : undefined
                                }
                                submittedApplications={
                                    appType === "submittedApplications"
                                        ? [application]
                                        : undefined
                                }
                                deleteApplication={deleteApplication}
                                submitApplication={submitApplication}
                                sentVerificationLink={sentVerificationLink}
                                requestForWithdrawal={requestForWithdrawal}
                                removeRequestForWithdrawal={removeRequestForWithdrawal}
                                signApplication={signApplication}
                                prolongateApplication={prolongateApplication}
                                processApplication={processApplication}
                                acceptApplication={acceptApplication}
                                recreateApplication={recreateApplication}
                                copyApplication={copyApplication}
                                isMobile={isMobile}
                                instanceName={instanceName}
                                getDeclination={getDeclination}
                                instanceInfo={instanceInfo}
                            />
                        ))
                    )}
                </>
            )}
            {isMobile && (
                <Styled.ChildrenOverviewMobile>
                    {applicationTypesForMobile.map(({ type, translationKey }) => (
                        <Styled.ChildrenOverviewHeadingMobile key={type}>
                            <Styled.H4
                                className="link-applications-mobile"
                                onClick={() => setShowMobilePanel(type)}
                            >
                                {t(translationKey)}{" "}
                                {getDeclination(generalType, "plural", "mianownik")} (
                                {selectedChild?.[type].length || "0"})
                            </Styled.H4>
                        </Styled.ChildrenOverviewHeadingMobile>
                    ))}
                    {selectedChild && (
                        <Styled.H5
                            className={
                                userChildrenList.length > 1
                                    ? "children-name-mobile"
                                    : "child-name-mobile"
                            }
                        >
                            {getDeclination(generalType, "plural", "mianownik")} dla:{" "}
                            {selectedChild.fullName}
                        </Styled.H5>
                    )}
                    {["savedApplications", "submittedApplications"].includes(
                        showMobilePanel
                    ) &&
                        selectedChild?.[showMobilePanel]?.map((application, index) => (
                            <ApplicationItem
                                key={`${showMobilePanel}-${index}`}
                                {...{ [showMobilePanel]: [application] }}
                                deleteApplication={deleteApplication}
                                submitApplication={submitApplication}
                                sentVerificationLink={sentVerificationLink}
                                requestForWithdrawal={requestForWithdrawal}
                                removeRequestForWithdrawal={removeRequestForWithdrawal}
                                signApplication={signApplication}
                                prolongateApplication={prolongateApplication}
                                processApplication={processApplication}
                                acceptApplication={acceptApplication}
                                recreateApplication={recreateApplication}
                                copyApplication={copyApplication}
                                isMobile={isMobile}
                                instanceName={instanceName}
                                getDeclination={getDeclination}
                                instanceInfo={instanceInfo}
                            />
                        ))}
                </Styled.ChildrenOverviewMobile>
            )}
        </>
    );
};

export default ChildrenOverview;

const ChildProfile = ({
    child,
    isActive,
    isIdle,
    isMobile,
    onClick,
    sumOfApplications,
    getDeclination,
    generalType,
    handleClick,
}) => {
    const { t } = useTranslation();

    return (
        <Styled.ChildProfileContainer>
            <Styled.ChildProfileWrapper
                className="child-profile"
                onClick={() => {
                    onClick(child.uuid);
                    if (isMobile) {
                        handleClick();
                    }
                }}
                isIdle={isIdle}
            >
                <img src={isActive ? activeKid : inactiveKid} alt={child.fullName} />
                <Styled.ChildProfileInfo>
                    <Styled.H4>
                        {child.firstName} {child.lastName}
                    </Styled.H4>
                    <p>
                        {t("myApplications.childProfile.numberOfApplications")}{" "}
                        {getDeclination(generalType, "plural", "dopelniacz")}:{" "}
                        {sumOfApplications}
                    </p>
                </Styled.ChildProfileInfo>
            </Styled.ChildProfileWrapper>
        </Styled.ChildProfileContainer>
    );
};
