import React, { useEffect, useContext, useState } from "react";
import { useTranslation, Trans } from "react-i18next";

import Breadcrumbs from "components/commons/Breadcrumbs/Breadcrumbs";
import Collapse from "components/commons/Collapse/Collapse";

import { AppContext } from "context/appContext";

import StaticPage from "components/reusable/StaticPage/StaticPage";

import Banner from "components/commons/Banner/Banner";

import * as Styled from "./PrivacyPolicy.styles";
import { useJsonData } from "hooks/useJsonData";


const PrivacyPolicy = () => {
  const { t } = useTranslation();
  const { setBreadcrumbsPath } = useContext(AppContext);
  const data = t("privacyPolicy:points",{returnObjects: true});

  useEffect(() => {
    setBreadcrumbsPath([
      {
        name: t("breadcrumbs.privacyPolicy"),
      },
    ]);
  }, []); 

  return (
    <>
      <Breadcrumbs />
      <Banner />
      <StaticPage title={t("menu.privacyPolicy")}>
        <Styled.PrivacyPolicy>
          {data.length && data.map((item) => (
            <Collapse title={item.title} key={item.title} id={item.title}>
              <Styled.AnswerText>
                <Trans
                  i18nKey={item.content}
                  components={{ br: <br />, ul: <ul></ul>, li: <li />, b: <b />, ol: <ol></ol> }}
                />
              </Styled.AnswerText>
            </Collapse>
          ))}
        </Styled.PrivacyPolicy>
      </StaticPage>
    </>
  );
};

export default PrivacyPolicy;
