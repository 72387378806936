import styled from "styled-components";

export const NoUnits = styled.div`
  ${({ theme }) => `
    background-color: ${theme.colors.backgroundLight};
    border: 1px solid ${theme.colors.grey30};
    border-radius: 10px;
    width: 65%;
    margin: 10rem 0;
    padding: 5rem;

    @media ${theme.device.mobile} {
        width: 90%; 
        margin: 2rem;
        margin-top: 5rem;
        text-align: center;
    }
  `}
`;
