import styled, { createGlobalStyle } from 'styled-components';
import { Tooltip, MapContainer } from "react-leaflet";

export const BodyBlockScroll = createGlobalStyle`
  body {
    overflow: hidden;
  }
`;

export const ModalMapWrapper = styled.div`
	position: fixed;
	inset: 0px;
  z-index: 1100;
`;

export const ModalContainer = styled.div`
	width: 80dvw;
	background-color: ${({theme}) => theme.colors.white};
	box-shadow: 0px 0.3rem 1.2rem #00000029;
	border-radius: 2.2rem;
	padding: 4rem;
	position: relative;
	overflow: hidden;
	
	button{
		border: none;
	}

	@media ${({theme}) => theme.device.mobileSmall} {
		width: 100vw;
        padding: 4rem 2rem;
        border-radius: 1.5rem;
	}
`;

export const ModalMap = styled.div`
	position: fixed;
	width: 100vw;
	height: 100vh;
	left: 0;
	top: 0;
	z-index: 1000;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: ${({theme}) => theme.colors.modalTransparentBackground};
`;

export const ModalMapContainer = styled(MapContainer)`
	height: 650px;

	@media ${({theme}) => theme.device.mobileSmall} {
			height: 570px;
		}
`;

export const ModalMapTooltip = styled(Tooltip)`
	box-shadow: none;
	background-color: transparent;
	top: -10px;
	border: none;

	&::before,
	&::after{
		display: none;
	}
`;

export const ModalMapHeader = styled.div`
	padding-bottom: 20px;
	margin-top: -20px;
  border-bottom: 1px solid #e5e5e5;
	display: flex;
	justify-content: space-between;
	align-items: baseline;
`;

export const ModalMapButtons = styled.div`
	display: flex;
  justify-content: flex-end;
	padding-top: 25px;
  text-align: right;
  border-top: 1px solid #e5e5e5;
	gap: 4rem;
	margin-bottom: -10px;

	button:last-child{
		border: 2px solid ${props => props.theme.colors.accent};

		@media ${({theme}) => theme.device.mobileSmall} {
			width: 100%;
		}
	}

	@media ${({theme}) => theme.device.mobileSmall} {
		flex-direction: column;
		justify-content: center;
		align-items: center;
		padding-top: 32px;
		gap: 3rem;
	}
`;

export const CloseButton = styled.button`
	padding: none;
	background-color: transparent;
	transition: all 0.2s ease-in-out;
	cursor: pointer;

	> img {
			width: 1.6rem;
			background-color: white;
	}
	&:hover {
			transform: scale(0.9);
	}
`;