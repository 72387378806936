import React from 'react';
import PropTypes from 'prop-types';
import * as Styled from './Notification.styles';

import ImgIconClose from 'assets/images/icon-close.svg';
import ImgIconWarning from 'assets/images/icon-warning.svg';

export const Notification = ({ 
    children,
    type,
    close,
    ...props 
}) => {
  return (
    <Styled.NotificationContainer>
        { close && <Styled.NotificationClose>
            <img src={ImgIconClose} alt="Close" />
        </Styled.NotificationClose>}
        { type === 'warning' && <Styled.NotificationIcon>
            <img src={ImgIconWarning} alt="Warning" />
        </Styled.NotificationIcon>}
        <Styled.NotificationText>
            {children}
        </Styled.NotificationText>
    </Styled.NotificationContainer>
    
  );
};

Notification.propTypes = {
  type: PropTypes.oneOf(['normal', 'warning']),
  close: PropTypes.bool
};

Notification.defaultProps = {
  type: 'normal',
  close: false
};
