import styled from 'styled-components';

export const Container = styled.div`
	max-width: calc(100vw - 4.8rem);
	margin: auto;

	@media ${({theme}) => theme.device.tablet} {
		max-width: calc(100vw - 8rem);
    }
	@media ${({theme}) => theme.device.desktop} {
		max-width: calc(100vw - 8rem);
    }
	@media ${({theme}) => theme.device.widescreen} {
		max-width: calc(100vw - 32rem);
    }	
    @media ${({theme}) => theme.device.superHd} {
		max-width: 160rem;
    }
`;

export const Dot = styled.span`
	display: inline-block;
	border-radius: 50%;
	// width: ${props => props.height ? props.height : "22px"};
	height: ${props => props.height ? props.height : "22px"};
	background-color: ${props => props.color ? props.color : "#000"};
    aspect-ratio: 1;
`;

export const Button = styled.button`
	padding: 2rem 2rem;
	background-color: ${({theme}) => theme.colors.main};
	color: ${({theme}) => theme.colors.white};
	font-size: 1.6rem;
	border-radius: 32px;
	border: none;
	font-weight: 600;
`;

export const H1 = styled.h1`
	font-size: 3.5rem;
	color: ${({theme}) => theme.colors.grey90};
	@media ${({theme}) => theme.device.mobile} {
		font-size: 3rem;
	}
`;


export const H2 = styled.h2`
	font-size: 2.6rem;
	color: ${({theme}) => theme.colors.grey90};
	@media ${({theme}) => theme.device.mobile} {
		font-size: 2rem;
	}
`;


export const H3 = styled.h3`
	font-size: 2.2rem;
	color: ${({theme}) => theme.colors.grey90};
	@media ${({theme}) => theme.device.mobile} {
		font-size: 1.6rem;
	}
`;


export const H4 = styled.h4`
	font-size: 2rem;
	color: ${({theme}) => theme.colors.grey90};
	@media ${({theme}) => theme.device.mobile} {
		font-size: 1.4rem;
	}
`;


export const H5 = styled.h5`
	font-size: 1.6rem;
	color: ${({theme}) => theme.colors.grey90};
	@media ${({theme}) => theme.device.mobile} {
		font-size: 1.4rem;
	}
`;

export const H6 = styled.h6`
	font-size: 1.4rem;
	color: ${({theme}) => theme.colors.grey90};
	@media ${({theme}) => theme.device.mobile} {
		font-size: 1.4rem;
	}
`;

export const Main = styled.main`
	padding: 3rem 0;
`;