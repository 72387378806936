import { memo, forwardRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import * as Styled from './PreferencesSection.styles';

import { Notification } from "components/commons/Notification/Notification";
import ArrowIcon from 'components/commons/icons/ArrowIcon';
import DeleteIcon from 'components/commons/icons/DeleteIcon';

const PreferencesSelectedList = forwardRef(({
    selectedUnits,
    onMove,
    onRemoveBranchFromSelected,
    disabled = false
}, ref) => {
    const { t } = useTranslation();

    const handleDrag = ({ source, destination }) => {
        if (destination) {
            onMove(source.index, destination.index);
        }
    };

    const selectedUp = (index) => {
        onMove(index, index - 1);
    }

    const selectedDown = (index) => {
        onMove(index, index + 1);
    }

    if (selectedUnits.length < 1) return (
        <Styled.SelectedContainer
            ref={ref}
            className='selected-container'
        >
            <Styled.SelectedTitle>
                {t('application.preferences.selectedByYou')}:
            </Styled.SelectedTitle>
            <Notification>
                {t("application.preferences.notSelectedYet")}
            </Notification>
        </Styled.SelectedContainer>
    )

    return (
        <Styled.SelectedContainer
            ref={ref}
            className='selected-container'
        >
            <Styled.SelectedTitle >
                {t('application.preferences.selectedByYou')}:
            </Styled.SelectedTitle>

            <Styled.SelectedHeader
                className='selected-header'
            >
                <Styled.Element>{t('application.preferences.preferences')}</Styled.Element>
                <Styled.Element toLeft={true}>{t('application.preferences.unitNameAddress')}</Styled.Element>
                { !disabled && (
                    <>
                        <Styled.Element>{selectedUnits.length > 1 ? t('application.preferences.move') : ''}</Styled.Element>
                        <Styled.Element>{selectedUnits.length > 1 ? t('application.preferences.modifyOrder') : ''}</Styled.Element>
                        <Styled.Element>{t('application.preferences.remove')}</Styled.Element>
                    </>
                )}
                
            </Styled.SelectedHeader>
            <DragDropContext
                onDragEnd={handleDrag}
            >
                <Droppable droppableId="test-items">
                    {(provided) => (
                        <div {...provided.droppableProps} ref={provided.innerRef}>
                            {selectedUnits.map((unit, index) => {
                                // those (provided) elements are required by react-beautiful-dnd
                                return (
                                    <Draggable
                                        key={`test[${index}]`}
                                        draggableId={`item-${index}`}
                                        index={index}
                                    >
                                        {(provided, snapshot) => (
                                            <SelectedUnit
                                                key={unit.id}
                                                unit={unit}
                                                index={index}
                                                selectedUp={index !== 0 && selectedUp}
                                                selectedDown={index !== selectedUnits.length - 1 && selectedDown}
                                                onRemoveBranchFromSelected={onRemoveBranchFromSelected}
                                                showDragButton={selectedUnits.length > 1}
                                                dragHandleProps={provided.dragHandleProps}
                                                provRef={provided.innerRef}
                                                draggableProps={provided.draggableProps}
                                                isDragging={snapshot.isDragging}

                                                disabled={disabled}
                                            />
                                        )}
                                    </Draggable>
                                );
                            })}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        </Styled.SelectedContainer>
    )
});

export default PreferencesSelectedList;

const SelectedUnit = memo(({
    unit,
    index,
    selectedUp,
    selectedDown,
    onRemoveBranchFromSelected,
    showDragButton,
    dragHandleProps,
    provRef,
    draggableProps,
    isDragging,
    disabled,
}) => {
    const { t } = useTranslation();

    return (
        <Styled.SelectedUnitContainer
            className='selected-unit'
            ref={provRef}
            {...draggableProps}
            isDragging={isDragging}
        >
            <Styled.SelectedPreferenceOrd
                className='selected-preference-ord'
            >
                <span className='mobile-preference-key'>
                    {t('application.preferences.preferences')}
                </span>
                {index + 1}
            </Styled.SelectedPreferenceOrd>
            <Styled.SelectedName
                className='selected-name'
            >
                <div>{unit.name}</div>
                {unit.branchName && (
                    <div className='address'>
                        {`${t('application.preferences.preferences')}: ${unit.branchName}`}
                    </div>
                )}
            </Styled.SelectedName>


            {!disabled && (

                <Styled.PreferenceOrderControlWrapper
                    className='preference-order-control-wrapper'
                >
                    <Styled.Element {...dragHandleProps} >
                        {showDragButton && <Styled.DragItemButton />}
                    </Styled.Element>
                    <Styled.Element>
                        {selectedUp && (
                            <Styled.UpDownButton
                                onClick={() => selectedUp(index)}
                            >
                                <ArrowIcon />
                            </Styled.UpDownButton>
                        )}
                        {selectedDown && (
                            <Styled.UpDownButton
                                onClick={() => selectedDown(index)}
                                isDown={true}
                            >
                                <ArrowIcon />
                            </Styled.UpDownButton>
                        )}
                    </Styled.Element>
                    <Styled.Element>
                        <Styled.HoverDeleteIcon onClick={() => onRemoveBranchFromSelected(unit.branchId)}>
                            <DeleteIcon />
                        </Styled.HoverDeleteIcon>
                    </Styled.Element>
                </Styled.PreferenceOrderControlWrapper>

            )}
        </Styled.SelectedUnitContainer>
    )
});