import {useEffect, useCallback, useMemo} from "react";
import { inputFiler } from "./formFiller";

import { useFormContext } from "react-hook-form";
import {useParams} from "react-router-dom";

export const useFormFiller = (fields, useFormSetter) => {
	const { city, generalType } = useParams();
    const { setValue, getValues } = useFormContext();
	const female = Math.random() < 0.5;
	const generateInputsContent = useCallback((cFields) => {
		if(!cFields) return;
        const preferenceQuestions = cFields.filter((field) => field.valid4SchoolBranch );
        const simpleQuestions = cFields.filter((field) => field.valid4SchoolBranch === false ).map((field) => field.uid);
        const currentFormFields = getValues();
        const inputValues = cFields.map( (field) => inputFiler(field, female)).filter( ({value}) => value !== undefined);

        if (preferenceQuestions?.length && currentFormFields.branches) {
            preferenceQuestions.map( (field) =>
                {
                    currentFormFields.branches.map((branch) =>
                    {
                        const randomValue = inputFiler(field, female).value;
                        setValue(`Answers.${field.uid}##${branch}`, randomValue);
                    });
                } );
        }
        
        inputValues.forEach((inputData) => {
            const {name, uid, value} = inputData;

            if (!name && uid && simpleQuestions.includes(uid)) {
                return setValue(`Answers.${uid}`, value);
            }
            
            if (name in currentFormFields) {
				//Nie losujemy pól ponownie, wyjątkiem jest pesel
				if (name == "Pesel" || !currentFormFields[name]) {
					setValue(name, value);
				}
            }
        });
	}, [ getValues, setValue ]);

	const handleShortcutClick = useCallback((e) => {
		if(e.ctrlKey && e.shiftKey){
			generateInputsContent(fields);
		}
	}, [fields, generateInputsContent]);

	useEffect(()=>{
		if (process.env.REACT_APP_ENV === "production") {
			return;
		};

		document.addEventListener("keydown", handleShortcutClick); 

		return () => document.removeEventListener("keydown", handleShortcutClick);
	}, [fields, handleShortcutClick]);

	// return values;
}