import { memo, useEffect, useState, useMemo } from 'react';
import * as Styled from './PreferencesSection.styles';
import ArrowIcon from 'components/commons/icons/ArrowIcon';

const AvailableUnit = memo(({
    unit,
    onSelectBranch,
    descriptionButtonText,
    branchDescriptionText,
    selectedBranches,
    setUnitModalId,
    setBranchModalId,
    isPredefinedUnit = false,
    alwaysShowBranches = false,
    maxBranches,
    disabled,

    collapseAll,
}) => {
    const [branchesExpanded, setBranchesExpanded] = useState(true);

    // passedChildAgeInMonts means that this component need to "auto-select" the only one branch that fits to the child's age

    const hasBranches =  unit?.schoolBranches && (unit?.schoolBranches?.length > 1 || (alwaysShowBranches && unit?.schoolBranches?.length > 0));

    const selectedBranchesInThisUnit = useMemo(() => {
        if (!unit.schoolBranches) return null;
        const number = unit.schoolBranches.filter( branch => selectedBranches.includes(branch.id)).length;
        if (number === 0) return null;
        return number.toString();
    }, [ selectedBranches, unit.schoolBranches ]);

    const localOnSelectBranch = (branchId) => branchId ? onSelectBranch(branchId) : null;

    useEffect(() => {
        setBranchesExpanded(!collapseAll);
    }, [collapseAll]);

    return (
        <Styled.AvailableUnitWithBranchesWrapper
            className='available-unit-branches-wrapper'
        >
            {/* UNIT */}
            <Styled.AvailableUnitStyled
                hasBranches={hasBranches}
                className="available-unit-branches"
            >
                <Styled.PreferenceName>
                    <Styled.PreferenceUnitName
                        className='available-unit-name'
                        clickable={hasBranches ? true : false}
                        onClick={() => setBranchesExpanded(state => !state)}
                    >
                        {unit.name} {isPredefinedUnit && ` (szkoła obwodowa)`}
                    </Styled.PreferenceUnitName>
                    
                    <Styled.PreferenceAddress className='available-unit-address'>
                        Adres: {unit.street} {unit.houseNo}, {unit.postalCode} {unit.city}
                    </Styled.PreferenceAddress>
                    {unit.foundOnlyBranch && (
                        <Styled.PreferenceAddress className='available-unit-address'>
                        Preferencja: {unit?.foundOnlyBranch}
                    </Styled.PreferenceAddress>
                    )}
                </Styled.PreferenceName>
                {hasBranches &&  (
                    <Styled.BranchesSelectedInCurrentUnitCount
                        className='branches-selected-unit-count'
                    >
                        {(selectedBranchesInThisUnit && !branchesExpanded) ? `Wybrane: ${selectedBranchesInThisUnit}` : ''}
                    </Styled.BranchesSelectedInCurrentUnitCount>
                )}
                {!hasBranches && (
                    <>
                        <Styled.UnitDescriptionButton
                            className='unit-description-button'
                            onClick={() => setUnitModalId(unit.id)}
                        >
                            {descriptionButtonText}
                        </Styled.UnitDescriptionButton>
                        { !isPredefinedUnit && unit?.schoolBranches?.length > 0 && (
                            <Styled.CheckboxWrapper>
                                <Styled.CheckboxInput
                                    type="checkbox"
                                    onChange={() => localOnSelectBranch(unit?.schoolBranches?.[0]?.id || null)}
                                    checked={selectedBranches.includes(unit?.schoolBranches?.[0]?.id) || false}

                                    disabled={disabled}
                                />
                            </Styled.CheckboxWrapper>
                        )}
                    </>
                )}
                {hasBranches && (
                    <Styled.UpDownButtonWrapper
                        className='expand-branches-button-wrapper'
                    >
                        <Styled.UpDownButton
                            onClick={() => setBranchesExpanded(state => !state)}
                            inverted={branchesExpanded}
                            isDown={!branchesExpanded}
                        >
                            <ArrowIcon />
                        </Styled.UpDownButton>
                    </Styled.UpDownButtonWrapper>
                )}
            </Styled.AvailableUnitStyled>

            {/* Unit  Branches */}
            {hasBranches && branchesExpanded && unit?.schoolBranches.map((branch) => (
                <BranchItem
                    key={branch.id}
                    branchName={branch.name}
                    branchId={branch.id}

                    onSelectBranch={onSelectBranch}
                    branchDescriptionText={branchDescriptionText}
                    setBranchModalId={setBranchModalId}
                    checked={selectedBranches.includes(branch.id)}
                    isPredefinedBranch={branch.schoolBranchType === 1 && isPredefinedUnit}

                    // branch is disabled when max branches is reached or max schools is reached and this is not branch of already selected school
                    disabled={disabled || (selectedBranches.length >= maxBranches && !selectedBranches.includes(branch.id))}
                />
            )
            )}
        </Styled.AvailableUnitWithBranchesWrapper>
    )
});

AvailableUnit.displayName = 'AvailableUnit';
export default AvailableUnit;


const BranchItem = ({
    branchName,
    branchId,
    branchDescriptionText,
    setBranchModalId,
    disabled = false,
    onSelectBranch,
    checked = false,
    isPredefinedBranch = false,
}) => {
    return (
        <Styled.AvailableBranchStyled
            className="available-branch"
        >
            <Styled.BranchName
                toLeft={true}
                className='branch-name'
            >
                {branchName || ''}
                {isPredefinedBranch && ` (oddział obwodowy)`}
            </Styled.BranchName>

            <Styled.BranchDescriptionButton
                onClick={() => setBranchModalId(branchId)}
            >
                {branchDescriptionText}
            </Styled.BranchDescriptionButton>
            {!isPredefinedBranch && (
                <Styled.CheckboxWrapper>
                    <Styled.CheckboxInput
                        type="checkbox"
                        onChange={() => onSelectBranch(branchId)}
                        checked={checked}
                        disabled={disabled}
                    />
                </Styled.CheckboxWrapper>
            )}
        </Styled.AvailableBranchStyled>
    )
}