import styled from 'styled-components';

export const List = styled.ul`
    list-style-type: none;
    margin: 60px 0 0 0;
    padding: 0;
`;

export const ListItem = styled.li`
    margin: 0;
    padding: 0 0 0 22px;
    position: relative;
    margin-top: 15px;
    
    
    &:after {
        width: 1px;
        height: 100%;
        background-color: ${({theme}) => theme.colors.accent};
        content: "";
        display: block;
        position: absolute;
        left: 0;
        top: 0;
    }
`;
