import Breadcrumbs from "components/commons/Breadcrumbs/Breadcrumbs";
import Banner from "components/commons/Banner/Banner";
import Calculator from "components/calculator/Calculator";
import { useTranslation } from "react-i18next";
import { useInstance } from "hooks/useInstance";
import { Page } from "components/commons/Page/Page";
import React from "react";

const InstanceCalculator = () => {
  const { t } = useTranslation();

  useInstance([
    {
      name: "Kalkulator punktowy",
    },
  ]);

  return (
    <Page>
      <Breadcrumbs />
      <Calculator/>
    </Page>
  );
};

export default InstanceCalculator;
