import styled from 'styled-components';

export const HeadingMain = styled.h1`
    font-size: 3.5rem;
    font-weight: 900;
    letter-spacing: 1px;
    color: ${props => props.color ? props.theme.colors.main : props.theme.colors.grey90};
    max-width: 90%;

    @media ${props => props.theme.device.mobile} {
      font-size: 3rem;
    }
`;

export const HeadingSub = styled.h2`
    font-size: 2.6rem;
    font-weight: 600;
    color: ${props => props.color ? props.theme.colors.main : props.theme.colors.grey90};

    @media ${props => props.theme.device.mobile} {
      font-size: 2rem;
    }
`;

export const TitleMain = styled.h2`
    font-size: 2.6rem;
    font-weight: 500;
    color: ${props => props.color ? props.theme.colors.main : props.theme.colors.grey90};

    @media ${props => props.theme.device.mobile} {
		font-size: 2rem;
	}
`;

export const TitleSub = styled.h3`
    font-size: 2.2rem;
    font-weight: 500;
    color: ${props => props.color ? props.theme.colors.main : props.theme.colors.grey90};

    @media ${props => props.theme.device.mobile} {
      font-size: 1.6rem;
    }
`;

export const Title = styled.h4`
    font-size: 2rem;
    font-weight: ${props => props.bold ? "600" : "300"};
    color: ${props => props.white ? props.theme.colors.white : props.color ? props.theme.colors.main : props.theme.colors.grey90};
    line-height: 2.8rem;

    @media ${props => props.theme.device.mobile} {
      font-size: 1.4rem;
    }
`;

export const TitleMini = styled.h5`
    font-size: 1.6rem;
    font-weight: 500;
    color: ${props => props.color ? props.theme.colors.main : props.theme.colors.grey90};
    line-height: 2.5rem;

    @media ${props => props.theme.device.mobile} {
      font-size: 1.4rem;
    }
`;

export const TitleContent = styled.h6`
    font-size: 1.4rem;
    font-weight: 500;
    line-height: 2.2rem;
    color: ${props => props.color ? props.theme.colors.main : props.theme.colors.grey90};

    @media ${props => props.theme.device.mobile} {
      font-size: 1.4rem;
    }
`;

export const Content = styled.p`
    font-size: 1.4rem;
    line-height: 2.2rem;
    color: ${props => props.color ? props.theme.colors.main : props.theme.colors.grey90};
`;