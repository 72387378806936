import { useState, useEffect} from 'react';

const useScreenSize = () => {
    const [screenSize, setScreenSize] = useState({ width: 0, height: 0 });
    
    useEffect(() => {
        window.addEventListener('resize', onResize);
        onResize();
        return () => window.removeEventListener('resize', onResize);
    }, []);

    const onResize = () => {
        setScreenSize({
            width: window.innerWidth,
            height: window.innerHeight,
        });
    };
    return screenSize;
}

export default useScreenSize;