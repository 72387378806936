import { useState, useEffect, useCallback } from "react";

const  useStorageStateExpiring = (key, expire = 24 * 60) => {
    // set expire to null to never expire

    const [storedValue, setStoredValue] = useState(() => {
        if (!key) {
            return null;
        }
        try {
            const item = JSON.parse(localStorage.getItem(key));
            if (item) {
                if ('expireTime' in item && item.expireTime !== null && item.expireTime < Date.now()) {
                    localStorage.removeItem(key);
                    return null;
                }
                return item.value || null;
            }
            return null;
        } catch (error) {
            return null;
        }
    });

    const setValue = useCallback((value) => {
        if (value) {
            localStorage.setItem(key, JSON.stringify({
                value: value,
                time: Date.now(),
                expireTime: expire === null ? null : (Date.now() + (expire * 60 * 1000)),
            }));
            setStoredValue(value);
        } else {
            localStorage.removeItem(key);
        }
    }, [key, expire, storedValue]);

    return [storedValue, setValue];
}

export default useStorageStateExpiring;