import { useState, useEffect, useCallback } from 'react';

const useAutoSequenceValidation = ({
    currentStep, 
    setCurrentStep, 
    numSteps,
    onValidation,
    showErrorInModule
}) => {
    const [isAutoSequence, setIsAutoSequence] = useState(false);

    // const [backStepAfterValidation, setBackStepAfterValidation] = useState();
    const [currentAutoValidationStep, setCurrentAutoValidationStep] = useState(null);
    const [validationTrigger, setValidationTrigger] = useState(null);

    const [validStepsArray, setValidStepsArray] = useState([]);

    const [stopStep, setStopStep] = useState(null);
    const [breakOnError, setBreakOnError] = useState(false);

    const startAutoValidation = useCallback((startStep = 0, stopStep = 0, breakOnError = false) => {
        // setBackStepAfterValidation(stopStep === 0 ? numSteps : stopStep);
        setCurrentAutoValidationStep(startStep);
        setStopStep(stopStep === 0 ? numSteps : stopStep);
        setBreakOnError(breakOnError);
        setIsAutoSequence(true);
    }, [numSteps]);

    const validateCurrentStep = async () => {
        if (!isAutoSequence) return
        const validation = await onValidation();
        if (validation !== true) {
            if (breakOnError) {
                // just break 
                showErrorInModule(validation);
                return finishAutoValidation(false);
            }
            // go further but colorize bullet
            setValidStepsArray( state => {
                const arr = [...state];
                arr[currentStep] = !(validation === true);
                return arr;
            });
        }
    };

    useEffect( () => {        
        if (validationTrigger !== null && isAutoSequence) {
            validateCurrentStep();
        }
    }, [validationTrigger, isAutoSequence]);

    const finishAutoValidation = (backToStep = true) => {
        // setBackStepAfterValidation(null);
        setCurrentAutoValidationStep(null);
        setValidationTrigger(null);
        setBreakOnError(false);
        setIsAutoSequence(false);
    }

    useEffect(() => {
        if (currentAutoValidationStep !== null) {
            if ((currentAutoValidationStep  < stopStep + 1) && isAutoSequence) {
                setCurrentStep(currentAutoValidationStep);
                // give new step some time in order to render it
                setTimeout( () => setValidationTrigger((state) => state === null ? 0 : state + 1), 100 );
                setTimeout( () => setCurrentAutoValidationStep(state => state + 1), 150 );
            } else {
                finishAutoValidation();
            }
            
        }
    }, [currentAutoValidationStep]);

    return {
        isAutoSequence,
        startAutoValidation,

        validStepsArray,
        setValidStepsArray,
    }
}

export default useAutoSequenceValidation;