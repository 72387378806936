import React from "react";
import preval from 'preval.macro';
import { useTranslation } from "react-i18next";
import PCSSLogo from "components/commons/Logo/PCSSLogo";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import * as CommonStyled from "styles/common.styles";
import { H5 } from "styles/common.styles";

import * as Styled from "./Footer.styles";

const buildTimestamp = preval`module.exports = new Date().toISOString();`;

const Footer = () => {
    const { t } = useTranslation();
    var unitTypes = t("unitTypesForView", { returnObjects: true });

    return (
        <Styled.Footer>
            <CommonStyled.Container>
                <Styled.FooterCpyright>
                    <Link to="/" className="img-link">
                        <PCSSLogo />
                    </Link>
                    <div>
                        <div>
                            <span>{t("footer.copyright").toUpperCase()}</span>
                            <Link
                                to="https://www.pcss.pl/"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {t("footer.psnc").toUpperCase()}
                            </Link>
                        </div>
                        <span>{t("footer.compilation")} {dayjs(new Date(buildTimestamp)).format("YYYY-MM-DD HH:mm:ss")}</span>
                    </div>
                </Styled.FooterCpyright>
                <Styled.FooterLinks>
                    <div>
                        <H5>{t("menu.allInstances")}</H5>
                        {Object.keys(unitTypes).map((key) => (
                            <Link key={`footer--${key}`} id={key} to={"/wdrozenia/" + key}>
                                {unitTypes[key]}
                            </Link>
                        ))}
                    </div>
                    <div>
                        <H5>{t("menu.about")}</H5>
                        <Link to="/oprojekcie?title=System Nabór - dla rodziców i kandydatów">
                            {t("footer.systemParentsCandidates")}
                        </Link>
                        <Link to="/oprojekcie?title=System Nabór - dla samorządów i placówek">
                            {t("footer.systemLocalGov")}

                        </Link>
                        <Link to="/oprojekcie?title=Kim jesteśmy?">{t('footer.whoWeAre')}</Link>
                        <Link to="/deklaracja-dostepnosci">{t('footer.accessibilityDeclaration')}</Link>
                        <Link to="/polityka-prywatnosci">{t('footer.privacyPolicy')}</Link>
                    </div>
                    <div>
                        <H5>{t('footer.help')}</H5>
                        <Link to="/kontakt">{t('footer.contactTechnicalSupport')}</Link>
                    </div>
                </Styled.FooterLinks>
            </CommonStyled.Container>
        </Styled.Footer>
    );
};

export default Footer;
