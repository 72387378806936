import React from 'react';
import * as Styled from './Text.styles';


export const HeadingMain = ({ 
    children,
    color,
    ...props
}) => {
  return (
    <Styled.HeadingMain 
        color={color?.toString()}
        {...props}
    >
        {children}
    </Styled.HeadingMain>
  );
};

export const HeadingSub = ({ 
    children,
    color,
    ...props
}) => {
  return (
    <Styled.HeadingSub 
        color={color}
        {...props}
    >
        {children}
    </Styled.HeadingSub>
  );
};

export const TitleMain = ({ 
    children,
    color,
    ...props
}) => {
  return (
    <Styled.TitleMain 
        color={color}
        {...props}
    >
        {children}
    </Styled.TitleMain>
  );
};

export const TitleSub = ({ 
    children,
    color,
    ...props
}) => {
  return (
    <Styled.TitleSub 
        color={color}
        {...props}
    >
        {children}
    </Styled.TitleSub>
  );
};

export const Title = ({ 
    children,
    color,
    white,
    bold,
    ...props
}) => {
  return (
    <Styled.Title 
        color={color} 
        bold={bold} 
        white={white}
        {...props}
    >
        {children}
    </Styled.Title>
  );
};

export const TitleMini = ({ 
    children,
    color,
    ...props
  }) => {
  return (
    <Styled.TitleMini 
        color={color}
        {...props}
    >
        {children}
    </Styled.TitleMini>
  );
};


export const TitleContent = ({ 
  children,
  color,
  ...props
}) => {
  return (
    <Styled.TitleContent 
        color={color}
        {...props}
    >
        {children}
    </Styled.TitleContent>
  );
};

export const Content = ({ 
    children,
    color,
    ...props
}) => {
  return (
    <Styled.Content 
        color={color}
        {...props}
    >
        {children}
    </Styled.Content>
  );
};

