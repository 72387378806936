import { memo } from 'react';
import * as Styled from './ScheduleBlock.styled';
import styled from 'styled-components';

import { Tooltip } from "react-tooltip";


const ScheduleBlock = memo(({ element: { date, title, description, active} }) => {
    return (
        <Styled.Container
            className='schedule-block'
        >
            <Styled.DateIconWrapper>
                <Styled.DateDisplay>{date}</Styled.DateDisplay>
                <Styled.Line className='schedule-line' />

                <StyledTooltip 
                    id="my-tooltip" 
                    effect="float" 
                    place="bottom"
                    positionStrategy="absolute"
                />

                <Styled.OnMobile>
                    <Styled.Icon 
                        icon={active ? 'now' : 'past'} 
                        className='schedule-icon'
                    />
                </Styled.OnMobile>
                <Styled.OnDesktop>
                    <Styled.Icon 
                        icon={active ? 'now' : 'past'} 
                        className='schedule-icon'
                        data-tooltip-id="my-tooltip" 
                        data-tooltip-html={ description }
                    />
                </Styled.OnDesktop>                
                
            </Styled.DateIconWrapper>
            <Styled.TitleWrapper>{title}</Styled.TitleWrapper>

            {/* Standard description - shown only on mobile */}
            <Styled.Description>{description}</Styled.Description>
        </Styled.Container>
    );
});
ScheduleBlock.displayName = "ScheduleBlock";

export default ScheduleBlock;


const StyledTooltip = styled(Tooltip)`
    &.react-tooltip {
        background: ${({theme}) => theme.colors.white};
        max-width: 40rem;
        color: ${({theme}) => theme.colors.black};
        font-size: 1.4rem;
        border-radius: 0.4rem;
        box-shadow: 0px 3px 4px #33333305;
        font-weight: 600;
        overflow: visible;
        z-index: 200;
    }
`;