import React from 'react';

const PlusIcon = ({ ...props }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        {...props}
    >
        <g className='icon' data-name="Warstwa 1">
            <circle style={{ fill: '#fff' }} cx="10" cy="10" r="10" />
            <path d="m10,20C4.49,20,0,15.51,0,10S4.49,0,10,0s10,4.49,10,10-4.49,10-10,10Zm0-18C5.59,2,2,5.59,2,10s3.59,8,8,8,8-3.59,8-8S14.41,2,10,2Z" />
            <path data-name="Union 3" d="m9,14v-3h-3v-2h3v-3h2v3h3v2h-3v3h-2Z" />
        </g>
    </svg>
)

export default PlusIcon;