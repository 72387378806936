import styled, { css } from 'styled-components';
import { Container } from "styles/common.styles";

// Form > Module > Section > Element

export const FormWrapper = styled.form`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const ModuleWrapper = styled(Container).attrs({ as: styled.section`` })`
    width: 100%;
    position: relative;
`;

export const ModuleHeader = styled.div`
    color: ${({ theme }) => theme.colors.black};
    margin-top: 4rem;
    /* border-bottom: 1px solid ${({ theme }) => theme.colors.main};
    margin: 7rem 0 4rem; */
`;

export const ModuleTitle = styled.div`
    font-size: 2.6rem;
    font-weight: 600;
    margin-bottom: 3rem;
`;

export const ModuleDescription = styled.div`
    font-size: 1.9rem;
    font-weight: 600;
    margin-bottom: 1rem;
`;

export const FormSectionWrapper = styled.div`
    color: ${({ theme }) => theme.colors.black};
    margin-bottom: 3.5rem;
`;

export const FormSectionHeader = styled.div(({ theme: { colors, device }, headSection }) => css`
    min-height: 6.4rem;
    color: ${headSection ? colors.white : colors.black};
    background-color: ${headSection ? colors.main : colors.selectItemBackground};
    border-bottom: 1px solid ${colors.main};
    font-size: 1.9rem;
    font-weight: 600;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding-left: 3rem;
    gap: 0.3rem;

    margin: 0 -1.5rem;
    @media ${device.tablet} {
        margin: 0 -3rem;
    }
    @media ${device.desktop} {
        margin: 0;
    }
    margin-bottom: 1rem;
`);

export const FormSectionContent = styled.div(({ theme: { device } }) => css`
    display: grid;
    grid-template-columns: 1fr;
    gap: 3rem;
    grid-auto-rows: auto;
    margin-top: 2.5rem;
    @media ${device.tablet} {
        gap: 3rem 2rem;
        grid-template-columns: repeat(${({ columns }) => columns || 2}, 1fr);
    }
    @media ${device.desktop} {
        gap: 3rem;
    }
`);

export const ErrorMessage = styled.div`
    color: ${({ theme }) => theme.colors.warning};
    font-size: 1.4rem;
    font-weight: 600;
`;

export const Asterisk = styled.span`
    font-size: inherit;
    color: ${({ theme }) => theme.colors.warning};
    &:before {
        content: "*";
    }
`;



/// PersonalModule
export const ParentsSelectorWrapper = styled.div`
    font-size: 1.9rem;
    color: ${({ theme }) => theme.colors.black};
`;

export const PersonalDataDescription = styled.div`
    font-size: 1.9rem;
    font-weight: 600;
    color: ${({ theme }) => theme.colors.black};
    & span {
        font-weight: 400;
    }
    margin-bottom: 3rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    > p {
        color: inherit;
        font-size: inherit;
    }
`;

export const ParentsSelectWrapper = styled.div`
    margin-bottom: 5rem;
    & .options-wrapper {
        color: ${({ theme }) => theme.colors.black};
        gap: 1.2rem;
    }
    & .radio-label {
        font-weight: 600;
        font-size: 1.6rem;
        margin-left: 1rem;
    }
`;

export const NoParentReasonWrapper = styled.div`
    padding-top: 3rem;
    border-top: 1px solid #00318969;
    color: ${({ theme }) => theme.colors.black};
`;

export const StartSectionWrapper = styled.div`
    color: ${({ theme }) => theme.colors.black};
    margin-bottom: 3.5rem;
`;

export const StartSectionContent = styled.div(({ theme: { device } }) => css`
    display: grid;
    grid-template-columns: 1fr;
    gap: 3rem;
    grid-auto-rows: auto;
    margin-top: 2.5rem;
    @media ${device.tablet} {
        gap: 3rem 2rem;
        grid-template-columns: repeat(${({ columns }) => columns || 2}, 1fr);
    }
    @media ${device.desktop} {
        gap: 3rem;
    }
    
    & a {
        font-weight: 600;
        font-size: 1.6rem;
    }
    & a:hover {
        text-decoration: underline;
    }
    & a[target=_blank]::after {
        margin-left: 0.2rem;
    }
    & p {
        margin-bottom: 5px;
    }
    & input[type=checkbox] {
        margin-right: 2px;
    }
`);