import React, { useState } from "react";
import useApi from "hooks/useApi";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { Button } from "components/commons/Button/Button";
import ModalConfirm from "components/commons/Modal/ModalConfirm";
import { ModalAddAttachment } from "pages/ApplicationList/Application/elements/ModalAddAttachment";

import DownloadIcon from "assets/icons/download-blue.svg";
import DeleteIcon from "assets/icons/delete-icon-blue.svg";
import AddIcon from "assets/icons/add-white.svg";

import * as Styled from "pages/ApplicationList/elements/ChildrenOverview/ApplicationItem/ApplicationItem.styles";

const ApplicationAttachments = ({
  attachments,
  formatDate,
  getAttachmentList,
  applicationId,
  appType,
  getDeclination,
  isMobile,
}) => {
  const { t } = useTranslation();
  const { generalType } = useParams();
  const { candidateApi } = useApi();
  const [attachmentRemoveConfirmModal, setAttachmentRemoveConfirmModal] =
    useState(null);
  const [showModalAddAttachment, setShowModalAddAttachment] = useState(false);

  const showAttachmentType = (typeNumber) => {
    switch (typeNumber) {
      case 1:
        return t(
          "myAccount.submittedApplications.application.attachmentTypes.1"
        );
      case 2:
        return t(
          "myAccount.submittedApplications.application.attachmentTypes.2"
        );
      case 10:
        return t(
          "myAccount.submittedApplications.application.attachmentTypes.10"
        );
      case 15:
        return t(
          "myAccount.submittedApplications.application.attachmentTypes.15"
        );
      case 20:
        return t(
          "myAccount.submittedApplications.application.attachmentTypes.20"
        );
      case 25:
        return t(
            "myAccount.submittedApplications.application.attachmentTypes.25"
        );
      case 30:
        return t(
          "myAccount.submittedApplications.application.attachmentTypes.30"
        );
      case 100:
        return t(
          "myAccount.submittedApplications.application.attachmentTypes.100"
        );
      default:
        return "Nieznany typ załącznika";
    }
  };

  const formatMB = (bytes) => {
    const megabytes = bytes / (1024 * 1024);
    return megabytes.toFixed(2);
  };

  // Attachments methods
  const downloadAttachment = async ({ id, name }) => {
    await candidateApi.downloadFile(
      `Attachment/${applicationId}/${id}/download`, name
    );
  };

  const removeAttachment = ({ id, name }) => {
    console.log("Remove attachment", id, name);
    setAttachmentRemoveConfirmModal({
      title: t(
        "myAccount.submittedApplications.application.removeAttachmentModal.title"
      ),
      text: `${t(
        "myAccount.submittedApplications.application.removeAttachmentModal.description"
      )} '${name}'?`,
      id,
      confirmText: t(
        "myAccount.submittedApplications.application.removeAttachmentModal.remove"
      ),
    });
  };

  const removeAttachmentConfirmed = async (id) => {
    try {
      const done = await candidateApi.delete(`Attachment/${applicationId}/${id}`);
      if (done) {
        getAttachmentList(applicationId);
      }
    } catch (err) {
      throw new Error("Delete Attachment Error: ", err);
    } finally {
      setAttachmentRemoveConfirmModal(null);
    }
  };

  return (
    <>
      {showModalAddAttachment && (
        <ModalAddAttachment
          closeModal={() => setShowModalAddAttachment(false)}
          applicationId={applicationId}
          appType={appType}
          reloadAttachmentList={() => getAttachmentList(applicationId)}
        />
      )}
      {attachmentRemoveConfirmModal && (
        <ModalConfirm
          closeModal={() => setAttachmentRemoveConfirmModal(null)}
          title={attachmentRemoveConfirmModal.title}
          text={attachmentRemoveConfirmModal.text}
          onConfirm={() =>
            removeAttachmentConfirmed(attachmentRemoveConfirmModal.id)
          }
          confirmText={attachmentRemoveConfirmModal.confirmText}
        />
      )}
      {!isMobile && (
        <>
          <Styled.H5 className="application-information-title">
            {t("myApplications.attachments.title")}{" "}
            {getDeclination(generalType, "singular", "dopelniacz")} (
            {attachments?.length || 0})
          </Styled.H5>
          {attachments?.map((attachment, index) => (
            <React.Fragment key={attachment.id}>
              <Styled.ApplicationAttachmentsContainer>
                <Styled.H5 className="application-information-title">
                  {index + 1}. {t("myApplications.attachments.attachmentTo")}{" "}
                  {getDeclination(generalType, "singular", "dopelniacz")}
                </Styled.H5>
                <Styled.ApplicationContentInfoParagraph className="application-attachment-name">
                  {attachment.name}
                </Styled.ApplicationContentInfoParagraph>
                <Styled.ApplicationContentInfoParagraph className="application-attachment-info">
                  {t("myApplications.attachments.attachmentDateAdded")}{" "}
                  {formatDate(attachment.createdDate)}
                </Styled.ApplicationContentInfoParagraph>
                <Styled.ApplicationContentInfoParagraph className="application-attachment-info">
                  {t("myApplications.attachments.attachmentType")}{" "}
                  {showAttachmentType(
                    attachment.id_DictApplicationAttachmentType
                  )}
                </Styled.ApplicationContentInfoParagraph>
                <Styled.ApplicationContentInfoParagraph className="application-attachment-info">
                  {t("myApplications.attachments.attachmentSize")}{" "}
                  {formatMB(attachment.size)}{" "}
                  {t("myApplications.attachments.format")}
                </Styled.ApplicationContentInfoParagraph>
                <Styled.ApplicationActionButtonsContainer className="application-attachment-buttons">
                  <Styled.ApplicationSecondaryActions>
                    <Button
                      secondary
                      className="application-button"
                      onClick={() => downloadAttachment(attachment)}
                    >
                      <span className="text">
                        {t("myApplications.attachments.actionButtons.download")}{" "}
                        <span className="space">
                          {t(
                            "myApplications.attachments.actionButtons.attachment"
                          )}
                        </span>
                      </span>
                      <img src={DownloadIcon} alt="Download icon" />
                    </Button>
                    <Button
                      secondary
                      className="application-button"
                      onClick={() => removeAttachment(attachment)}
                    >
                      <span className="text">
                        {t("myApplications.attachments.actionButtons.delete")}{" "}
                        <span className="space">
                          {t(
                            "myApplications.attachments.actionButtons.attachment"
                          )}
                        </span>
                      </span>
                      <img src={DeleteIcon} alt="Delete icon" />
                    </Button>
                  </Styled.ApplicationSecondaryActions>
                </Styled.ApplicationActionButtonsContainer>
              </Styled.ApplicationAttachmentsContainer>
            </React.Fragment>
          ))}
          <Styled.ApplicationActionButtonsContainer className="application-attachment-button-primary">
            <Button
              secondary
              reverse
              className="application-button"
              onClick={() => setShowModalAddAttachment(true)}
            >
              <span className="text">
                {t("myApplications.attachments.actionButtons.add")}{" "}
                <span className="space">
                  {t("myApplications.attachments.actionButtons.attachment")}
                </span>
              </span>
              <img src={AddIcon} alt="Add icon" />
            </Button>
          </Styled.ApplicationActionButtonsContainer>
        </>
      )}
      {isMobile && (
        <ApplicationAttachmentsMobile
          attachments={attachments}
          formatDate={formatDate}
          formatMB={formatMB}
          showAttachmentType={showAttachmentType}
          downloadAttachment={downloadAttachment}
          removeAttachment={removeAttachment}
          setShowModalAddAttachment={setShowModalAddAttachment}
        />
      )}
    </>
  );
};

export default ApplicationAttachments;

const ApplicationAttachmentsMobile = ({
  attachments,
  formatDate,
  formatMB,
  showAttachmentType,
  downloadAttachment,
  removeAttachment,
  setShowModalAddAttachment,
}) => {
  const { t } = useTranslation();
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleList = () => {
    setIsExpanded((prev) => !prev);
  };
  return (
    <Styled.ApplicationInfoWrapperMobile>
      <Styled.ApplicationPrefTitleContainerMobile>
        <Styled.H5 className="application-title-mobile">
          {t("myApplications.attachmentsMobile.title")} (
          {attachments?.length || 0})
        </Styled.H5>
        <span
          onClick={toggleList}
          className={`arrow-icon ${isExpanded ? "expanded" : ""}`}
        ></span>
      </Styled.ApplicationPrefTitleContainerMobile>

      {attachments?.length > 0 && (
        <div
          className={`preferences-list-content ${isExpanded ? "expanded" : ""}`}
        >
          {attachments.map((attachment) => (
            <div key={attachment.id} className="attachment-item">
              <Styled.ApplicationSubtitleContainerMobile>
                <Styled.H5 className="application-title-mobile">
                  {attachment.name}
                </Styled.H5>
              </Styled.ApplicationSubtitleContainerMobile>
              <Styled.ApplicationInfoMobile>
                <Styled.ApplicationInfoParagraphMobile>
                  {t("myApplications.attachments.attachmentDateAdded")}{" "}
                  {formatDate(attachment.createdDate)}
                </Styled.ApplicationInfoParagraphMobile>
                <Styled.ApplicationInfoParagraphMobile>
                  {t("myApplications.attachments.attachmentType")}{" "}
                  {showAttachmentType(
                    attachment.id_DictApplicationAttachmentType
                  )}
                </Styled.ApplicationInfoParagraphMobile>
                <Styled.ApplicationInfoParagraphMobile>
                  {t("myApplications.attachments.attachmentSize")}{" "}
                  {formatMB(attachment.size)}{" "}
                  {t("myApplications.attachments.format")}
                </Styled.ApplicationInfoParagraphMobile>
                <Styled.ApplicationActionButtonsContainerMobile>
                  <Button
                    secondary
                    className="application-button-mobile"
                    onClick={() => downloadAttachment(attachment)}
                  >
                    <span className="text">
                      {t("myApplications.attachments.actionButtons.download")}{" "}
                      <span className="space">
                        {t(
                          "myApplications.attachments.actionButtons.attachment"
                        )}
                      </span>
                    </span>
                    <img src={DownloadIcon} alt="Download icon" />
                  </Button>
                  <Button
                    secondary
                    className="application-button-mobile"
                    onClick={() => removeAttachment(attachment)}
                  >
                    <span className="text">
                      {t("myApplications.attachments.actionButtons.delete")}{" "}
                      <span className="space">
                        {t(
                          "myApplications.attachments.actionButtons.attachment"
                        )}
                      </span>
                    </span>
                    <img
                      className="delete-icon"
                      src={DeleteIcon}
                      alt="Delete icon"
                    />
                  </Button>
                </Styled.ApplicationActionButtonsContainerMobile>
              </Styled.ApplicationInfoMobile>
            </div>
          ))}
        </div>
      )}

      {attachments?.length === 0 && (
        <Styled.ApplicationSubtitleContainerMobile>
          <Styled.ApplicationInfoParagraphMobile>
            {t("myApplications.attachments.noAttachments")}
          </Styled.ApplicationInfoParagraphMobile>
        </Styled.ApplicationSubtitleContainerMobile>
      )}

      <Styled.ApplicationActionButtonsContainerMobile>
        <Button
          secondary
          reverse
          className="application-button-mobile"
          onClick={() => setShowModalAddAttachment(true)}
        >
          <span className="text">
            {t("myApplications.attachments.actionButtons.add")}{" "}
            <span className="space">
              {t("myApplications.attachments.actionButtons.attachment")}
            </span>
          </span>
          <img src={AddIcon} alt="Add icon" />
        </Button>
      </Styled.ApplicationActionButtonsContainerMobile>
    </Styled.ApplicationInfoWrapperMobile>
  );
};
