import styled from 'styled-components';

export const NotificationContainer = styled.div`
    background-color: ${({theme}) => theme.colors.grey5};
    border: 1px solid ${({theme}) => theme.colors.grey30};
    border-radius: 4px;
    padding: 3rem 1.7rem;
    position: relative;
    display: flex;
`;

export const NotificationIcon = styled.div`
    margin-right: 10px;
`;

export const NotificationClose = styled.button`
    position: absolute;
    right: 10px;
    top: 10px;
    border: 0;
    background: transparent;
`;

export const NotificationText = styled.div`
    font-size: 1.6rem;
    font-weight: bold;
`;