import styled, { css } from "styled-components";

import arrow from 'assets/images/arrow.svg'
import arrowDisabled from 'assets/images/arrow-disabled.svg'

export const CarouselWrapper = styled.div`
    margin: auto;
    width: 100%;
    
    border-radius: 2.2rem 0px 0px 2.2rem;
    background: linear-gradient(0deg, white 0%, ${({ theme }) => theme.colors.backgroundLight} 5%, ${({ theme }) => theme.colors.backgroundLight} 80%, white 90%, white 100%);

    @media ${({ theme }) => theme.device.desktop} {
        background: linear-gradient(90deg, white 0%, ${({ theme }) => theme.colors.backgroundLight} 10%, ${({ theme }) => theme.colors.backgroundLight} 92%, white 100%);
        display: flex;
    }
`;

export const CarouselTitle = styled.div`
    background-color: white;
    h2 {
        margin: 0;
        border-bottom: 2px ${({ theme }) => theme.colors.main} solid;
        font-size: 2rem;
        color: #393939;
        padding: 0px 2rem 4rem;
    }

    @media ${({ theme }) => theme.device.desktop} {
      margin: 0px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      max-width: 240px;
      background-color: #EDF5FB;
      border-right: 1px solid ${({ theme }) => theme.colors.main};
      border-radius: 22px 0px 0px 22px;
      h2 {
        text-align: end;
        padding: 0px 2rem 0px 3.6rem;
        border-bottom: 0px;
      }
    }
`;

export const Carousel = styled.div`
    position: relative;
    white-space: nowrap;
    overflow: hidden;
    scroll-behavior: smooth;
    margin: 0 3rem;
    height: 104rem;

    ${({items}) => (items < 4) && css`
        height: ${(25 * items) + 4}rem;
    `}


    @media ${({ theme }) => theme.device.desktop} { 
        height : auto;
        width: 100%;
    }
`;

export const NavArrow = styled.button`
    position: absolute; 
    z-index: 1;
    width: 3.6rem;
    height: 3.6rem;
    border: 0;
    transition: transform 1000ms;
    margin-left: 0;
    cursor: pointer;
    background: url(${arrow});
    background-size: contain;

    bottom: 0;
    transform: rotate(90deg);

    ${({ disabled }) => disabled && css`
        background: url(${arrowDisabled});
        cursor: default;
    `}

    ${({ left }) => left && css`
        transform: rotate(-90deg);
        bottom: unset;
    `}

    @media ${({ theme }) => theme.device.desktop} {
        top: calc(50% + (13rem - 3.4rem));
        right: 0px;
        transform: rotate(0deg);

        ${({ left }) => left && css`
            top: calc(13rem - 3.4rem);
            left: 0px; 
            transform: rotate(-180deg);
        `}
    }
    @media ${({ theme }) => theme.device.desktop} { 
        top: calc(13rem - 3.4rem); 
    }
`;

export const CarouselContent = styled.div`
    margin: 50px 0px;
    ${props => props.row === 2 ? `display:none;` : ``};
    ${props => {
        return `
            transition: transform ${1000 * props.row}ms;
            transform: translateY(-${props.offset * 100 / props.items}%);`
    }};
    @media ${({ theme }) => theme.device.desktop} {
        margin: 0px;
        display: block;
        ${({ items, row }) => (items <= 2 && row === 2) && css`
            display:none;
        `}

        ${(offset, row) => {
        const calculateTranslation = ((offset + (row === 2 ? 2 : 0)) * 100) / 2;
        return css`
                transform: translate(-${calculateTranslation}%);
            `;
    }}
        
        & > div {
            /* width: 50%; */

            ${({ items }) => items < 3 && css`
                width: ${100 / items}%;
            `}        
    }
    }
    @media ${({ theme }) => theme.device.desktop} {
        ${({row}) => row === 2 && css`
            display:none;
        `};

        ${({ offset }) => css`
            transform: translate(-${offset * 100 / 4}%);
        `};
        & > div {
            width: 25%;
            ${({ items }) => (items < 4) && css`
                width: ${100 / items}%;
            `}
        }
    }
`;
