import React, { useEffect, useState } from 'react';
import arrow from 'assets/images/arrow.svg';
import * as Styled from './Collapse.styles';
import { useSearchParams } from 'react-router-dom';

const Collapse = ({title, id, collapsed = true, fullwidth = false, children }) => {
    const [open, setOpen] = useState(!collapsed); 
    const [searchParams] = useSearchParams(); 
    const titleParam = searchParams.get("title");

    useEffect(() => {
        setOpen(title === titleParam);
      }, [titleParam]);
    
    return (
        <Styled.Collapse 
            className='collapse-item'
        >
            <input 
                type="checkbox" 
                id={"collapse-chb" + id} 
                checked={open} 
                onChange={() => setOpen(state => !state)} 
            />
            <label htmlFor={"collapse-chb" + id} className='collapse-label'>
                <span>{title}</span>
                <span><img src={arrow} alt={open ? 'collapse' : 'expand'} /></span>
            </label>
            <div 
                className='collapse-children-wrapper'
            >
                {children}
            </div>
        </Styled.Collapse>
    )
};

export default Collapse;