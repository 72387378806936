import React, { memo, useEffect } from "react";
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import TextFieldForm from "./TextFieldForm";
import { useFormContext, Controller } from "react-hook-form";

const IntegerInput = ({ name, isRequired, defaultValue, ...rest }) => {
    const { t } = useTranslation();
    const { control, trigger, setValue } = useFormContext();

    const validateField = async (value) => {
        if (isRequired && (value === '' || value === undefined || value === null)){
            return t('application.errors.fieldRequired');
        }
        if (!isRequired && value === '') {
            return true;
        };
        const integer = parseInt(value);
        if (isNaN(integer)) {
            return t('application.errors.wrongNumber');
        }
        if (rest.minValue && integer < rest.minValue) {
            return `${t('application.errors.minValue')} ${rest.minValue} )`;
        }
        if (rest.maxValue && integer > rest.maxValue) {
            return `${t('application.errors.maxValue')} ${rest.maxValue} )`;
        }
        return true;
    }
    
    useEffect(() => {
        if (defaultValue) {
            setValue(name, defaultValue);
        }
    }, [defaultValue, name, setValue]);

    return (
        <StyledIntegerWrapper>
            <Controller
                name={name}
                control={control}
                defaultValue=''
                rules={{ validate: validateField }}
                render={({ field, fieldState }) => (
                    <TextFieldForm
                        placeholder="0"
                        {...rest}
                        onChange={(ev) => {
                            ev.target.value = ev.target.value.replace(/\D/g, "");
                            const int = parseInt(ev.target.value);
                            if (!isNaN(int)) {
                                field.onChange(int);
                            } else {
                                field.onChange('');
                            }
                        }}
                        onBlur={(ev) => {
                            trigger(name);
                        }}
                        error={fieldState.error}
                        isRequired={isRequired}
                        value={field.value}
                    />
                )}
            />
        </StyledIntegerWrapper>
    );
};

export default memo(IntegerInput);

const StyledIntegerWrapper = styled.div`
    & .input-wrapper {
        max-width: 11rem;
    }
    & .text-input {
        text-align: right;
    }
`;