import React from 'react';

import * as Styled from './Table.styles';

const Table = ({children}) => {
    return (
        <Styled.Table>
            {children}
        </Styled.Table>
    )
};

const TableHead = ({children}) => {
    return (
        <Styled.TableHead>
            {children}
        </Styled.TableHead>
    )
};

const TableRow = ({children}) => {
    return (
        <Styled.TableRow>
            {children}
        </Styled.TableRow>
    )
};

const TableCol = ({children}) => {
    return (
        <Styled.TableCol>
            {children}
        </Styled.TableCol>
    )
};

export { 
    Table,
    TableHead,
    TableRow,
    TableCol
 };