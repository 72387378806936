import { useEffect, memo, useCallback, useRef, useContext, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useFieldArray, useFormContext, useForm } from 'react-hook-form';
import { differenceInMonths, differenceInYears } from 'date-fns';

import useUnitFiltering from 'hooks/useUnitFiltering';

import PreferencesAvailableList from './PreferencesSectionElements/PreferencesAvailableList.js';
import PreferencesSelectedList from './PreferencesSectionElements/PreferencesSelectedList.js';
import { Notification } from "components/commons/Notification/Notification";
import * as Styled from './PreferencesSectionElements/PreferencesSection.styles.js';

import UnitsFilters from "components/commons/UnitsFilters/UnitsFilters";
import ArrowIcon from 'components/commons/icons/ArrowIcon';

import { UnitGeneralTypes, KindergartenAgeCodes } from "utils/enums";
import { isDateValid } from "utils/utils";
import { ApplicationContext } from '../Application';
import { usePreferencesFiller } from 'utils/formFiller/usePreferencesFiller';
// USING INSTANCE SETTINGS:

// 123 - AutoSchoolBranch4Age - Przy podawaniu preferencji wybierana jest jedynie placówka, a oddział automatycznie ze względu na wiek dziecka na 1 września danego roku.

// 135 - AutoSchoolBranchBaseDate - Data na podstawie której ustalany jest wiek przy automatycznym dobieraniu grupy wiekowej. Wartość przyjdzie w formacie YYYY-MM-DD, jej brak oznacza że liczymy na dzisiaj

// 142 - UseStartDateInSchoolBranchDecision - Przy automatycznym wyborze oddziału ze względu na wiek dziecka jest uwzględniana data obięcia opieką (większa z Now/Const i StartDate). 

const getLaterDate = (date1, date2) => {
    if (!isDateValid(date1) && isDateValid(date2)) return date2;
    if (!isDateValid(date2) && isDateValid(date1)) return date1;
    return new Date(Math.max(date1, date2))
}

const schoolBranchTypesForMale = [302,402,121,136,138,140,148,150];
const schoolBranchTypesForFemale = [301,401,135,137,139,141,147,149];

const PreferencesSection = () => {    
    const [ searchParams ] = useSearchParams();
    const { register, setValue, getValues, unregister } = useFormContext();

    const [unitsAgeGenderFiltered, setUnitsAgeGenderFiltered] = useState();

    const { 
        instance, 
        units, 
        formValidationMethods, 
        formReadOnly, 
        bottomBarHeight,

        updateModuleSchemaOnSelectedBranches,
    } = useContext(ApplicationContext);
    const { settings } = instance;

    // Preferences section uses a new local form in order to take advantage of fieldArray to sort, and remove branches to list
    const { control: localControl } = useForm();
    const { 
        fields: selectedBranches,
        append, 
        remove, 
        move,
        update
    } = useFieldArray({
        control: localControl,
        name: 'preferences',
    });
    const selectedRef = useRef();

    useEffect(() => {
        // INITIAL - check whether there are branches in form or preselected_branch in url
        if (!units || unitsAgeGenderFiltered) return;

        let branchesFromInitialState = getValues('branches');

        if (!branchesFromInitialState || branchesFromInitialState?.length < 1) {
            const { preselected_branch } = Object.fromEntries(searchParams);
            if (preselected_branch && !isNaN(parseInt(preselected_branch))) {
                branchesFromInitialState = [parseInt(preselected_branch)];
                setValue('branches', [parseInt(preselected_branch)], { shouldDirty: true });
            }
        }
        
        if (branchesFromInitialState?.length > 0) {
            branchesFromInitialState.forEach( branchId => {
                append({ branchId });
            });
        }

        // connect branches to main form and moduleValidation
        formValidationMethods.addFieldToValidation('branches');
        register('branches', {
            validate: validateBranches
        });


        /// LIST TO SHOW UNITS -  filter branches to show by age and gender        
        const ageInMonths = settings?.AutoSchoolBranch4Age ?getChildAgeInMonths(settings, getValues('BirthDate')) : null;
        const ageInYears = settings.BranchFilterBirthYear  ? getChildAgeInYears(getValues('BirthDate')) : null;
        let childGender = (settings?.UseDedicatedSchoolBranchesforSex || settings?.BranchFilterSex) ? getValues('Id_DictSex') : null;
        if (childGender) {
            childGender = parseInt(childGender?.value || childGender);
        }

        let filteredUnitList = filterUnitsByAge(units, ageInMonths, ageInYears);
        filteredUnitList = childGender ? filterUnitsByGender(filteredUnitList, childGender) : filteredUnitList;
        setUnitsAgeGenderFiltered(filteredUnitList);

        if (branchesFromInitialState?.length > 0) {
            checkIfBranchesStillMeetsCriteriaAfterReopen(
                branchesFromInitialState, 
                units, 
                ageInMonths, 
                ageInYears, 
                childGender,
            );
        }
    }, [units, unitsAgeGenderFiltered]);

    const unitsUserFiltered = useUnitFiltering({ 
        units: unitsAgeGenderFiltered, 
        instance, 
        filteringForPreferences: true,
    });

    /// FILTER UNITS BY AGE AND GENDER
    // AGE CONDITIONS:
    const getChildAgeInMonths = useCallback((settings, formBirthDate) => {
        // console.log("123 - AutoSchoolBranch4Age", settings.AutoSchoolBranch4Age);
        // console.log("135 - AutoSchoolBranchBaseDate", settings.AutoSchoolBranchBaseDate);
        // console.log("142 - UseStartDateInSchoolBranchDecision", settings.UseStartDateInSchoolBranchDecision);

        if (!formBirthDate || !isDateValid(new Date(formBirthDate))) return null;

        const autoSchoolBranchBaseDate =  isDateValid(new Date(settings?.AutoSchoolBranchBaseDate?.value)) ? new Date(settings?.AutoSchoolBranchBaseDate?.value) : null;

        const birthDate = new Date(formBirthDate);

        if (autoSchoolBranchBaseDate) {
            return Math.max(differenceInMonths(getLaterDate(autoSchoolBranchBaseDate, new Date()), new Date(birthDate)), 1);
        }

        if (settings?.UseStartDateInSchoolBranchDecision && getValues('Answers.INCOME_DATE')) {
            const formIncomeDate = isDateValid(new Date(getValues('Answers.INCOME_DATE'))) ? new Date(getValues('Answers.INCOME_DATE')) : null;
            if (formIncomeDate) {
                return Math.max(differenceInMonths(getLaterDate(formIncomeDate, new Date()), new Date(birthDate)), 1);
            }
            return Math.max(differenceInMonths(new Date(), new Date(birthDate)), 1);
        }
        return Math.max(differenceInMonths(new Date(), new Date(birthDate)), 1);
    }, [ getLaterDate, isDateValid ]);


    const getChildAgeInYears = useCallback((formBirthDate) => {
        if (!formBirthDate || !isDateValid(new Date(formBirthDate))) return null;
        const birthYear = new Date(new Date(formBirthDate).getFullYear(), 0, 1);
        return differenceInYears(new Date(), birthYear);
    }, []);

    const filterUnitsByAge = useCallback((localUnitList, ageInMonths, ageInYears) => {
        if (!ageInMonths && !ageInYears) return localUnitList;
        if (ageInMonths) {
            const filteredUnits = localUnitList?.map(unit => {
                if (!unit?.schoolBranches) return unit;
                const foundBranch = unit?.schoolBranches?.find(branch => ageInMonths <= branch?.maxAge);
                if ( foundBranch ) {
                    return {
                        ...unit,
                        schoolBranches: foundBranch ? [foundBranch] : [],
                        foundOnlyBranch: foundBranch?.name,
                    }
                } 
                return null;
            }).filter(Boolean);
            return filteredUnits;
        }
        if (ageInYears) {
            const filteredUnits = localUnitList?.map((unit) => {
                const kindergartenCodes = Object.keys(KindergartenAgeCodes);
                if (!unit?.schoolBranches) return null;
                const filteredBranches = unit.schoolBranches.map(branch => {
                    const branchType = branch.schoolBranchType.toString();
                    if (kindergartenCodes.includes(branchType)) {
                        if (KindergartenAgeCodes[branchType].includes(ageInYears)) {
                            return branch;
                        }
                        return null;
                    }
                    return branch;
                }).filter(Boolean);
                
                if (filteredBranches?.length) {
                    return {...unit, schoolBranches: filteredBranches};
                }
                return null;
            }).filter(Boolean);
            return filteredUnits;
        }
    }, []);

    const filterUnitsByGender = useCallback((localUnitList, childGender) => {
        const filteredUnits = localUnitList?.map(unit => {
            if (!unit?.schoolBranches) return unit;
            const foundBranch = unit?.schoolBranches?.filter(({schoolBranchType}) => {
                return childGender === 1 ? schoolBranchTypesForMale.includes(schoolBranchType) : schoolBranchTypesForFemale.includes(schoolBranchType);
            });
            return foundBranch?.length ? { ...unit, schoolBranches: foundBranch } : null;
        }).filter(Boolean);
        return filteredUnits;
    }, []);


    const checkIfBranchesStillMeetsCriteriaAfterReopen = useCallback( (
        selectedBranches, 
        unitList, 
        childAgeInMonths, 
        childAgeInYears,
        childGender,
    ) => {
        if (!selectedBranches?.length || (!childAgeInMonths && !childAgeInYears && !childGender) || !unitList?.length) return;
        const listOfBranchesToRemove = [];
        
        selectedBranches.forEach( (branchId, branchIndexInSelectedList) => {
            const unitForThisBranch = unitList?.find( unit => {
                return unit?.schoolBranches?.find( branch => branch.id === branchId )
            });

            if (childAgeInMonths) {
                let branchMatchingForCurrentAge;
                branchMatchingForCurrentAge = unitForThisBranch?.schoolBranches?.find(branch => childAgeInMonths <= branch?.maxAge);
                if (branchMatchingForCurrentAge && branchMatchingForCurrentAge.id !== branchId) {
                    const newBranchId = branchMatchingForCurrentAge.id;
                    if (newBranchId) {
                        // on update there is also need to check answers - if any of them is assigned to updated brach:
                        unregisterAnswersForBranch(branchId);
                        update(branchIndexInSelectedList, { branchId: newBranchId });
                    }
                }
                // if no better branch found it means that we need to remove it, but do it with delay;
                if (branchMatchingForCurrentAge === undefined) {
                    listOfBranchesToRemove.push(branchId);
                }
            }
            if (childAgeInYears) {
                const kindergartenCodes = Object.keys(KindergartenAgeCodes);
                // if (!unitForThisBranch?.schoolBranches) return null;
                const filteredBranches = unitForThisBranch.schoolBranches.map( branch => {
                    const branchType = branch.schoolBranchType.toString();
                    if (kindergartenCodes.includes(branchType)) {
                        if (KindergartenAgeCodes[branchType].includes(childAgeInYears)) {
                            return branch;
                        }
                        return null;
                    }
                    return branch;
                }).filter(Boolean);

                if (!filteredBranches.map( ({id}) => id ).includes(branchId)) {
                    if (filteredBranches?.length === 1) {
                        // if there is only one matching in this unit - we can update it
                        const newBranchId = filteredBranches[0].id;
                        update(branchIndexInSelectedList, { branchId: newBranchId });
                        // and remove questions for old one:
                        unregisterAnswersForBranch(branchId);
                    } else if (filteredBranches?.length > 1) {
                        listOfBranchesToRemove.push(branchId);
                    }
                }
            }
            if (childGender) {
                const { schoolBranchType } = unitForThisBranch?.schoolBranches?.find( branch => branch.id === branchId );
                let foundAlternativeForGender = null;
                if (childGender === 1 && !schoolBranchTypesForMale.includes(parseInt(schoolBranchType))) {
                    foundAlternativeForGender = unitForThisBranch?.schoolBranches?.filter( ({schoolBranchType}) => schoolBranchTypesForMale.includes(schoolBranchType));
                }
                if (childGender === 2 && !schoolBranchTypesForFemale.includes(parseInt(schoolBranchType))) {
                    foundAlternativeForGender = unitForThisBranch?.schoolBranches?.filter( ({schoolBranchType}) => schoolBranchTypesForFemale.includes(schoolBranchType));
                }

                if (foundAlternativeForGender?.length > 1) {
                    listOfBranchesToRemove.push(branchId);
                }
                if (foundAlternativeForGender?.length === 1) {
                    const newBranchId = foundAlternativeForGender[0].id;
                    update(branchIndexInSelectedList, { branchId: newBranchId});
                    unregisterAnswersForBranch(branchId);
                }
            }
        });

        // if there are branches that dont meet the age at all:
        if (listOfBranchesToRemove.length > 0) {
            const indexesToRemove = listOfBranchesToRemove.map( branchId => {
                unregisterAnswersForBranch(branchId);
                return selectedBranches.indexOf( branchId );
            });
            remove( indexesToRemove );
        }
    }, []);


    const alwaysShowBranches = useMemo(() => {
        // all branches (even if there is only one in unit) is visible in SecondarySchool and TradeSchool
        if (instance?.unitGeneralType &&
                (instance.unitGeneralType === UnitGeneralTypes.SecondarySchool ||
                 instance.unitGeneralType === UnitGeneralTypes.TradeSchool ||
                 instance.unitGeneralType === UnitGeneralTypes.PostSecondarySchool ||
                 instance.unitGeneralType === UnitGeneralTypes.Class4 ||
                 instance.unitGeneralType === UnitGeneralTypes.Class6 ||
                 instance.unitGeneralType === UnitGeneralTypes.Class7)
            ) {
            return true;
        }
        return false;
    }, [instance]);
    
    const predefinedUnit = useMemo(() => {
        // szkoła obwoddowa
        const predefined = getValues('Id_PredefinedUnit');
        if (predefined) {
            if (typeof predefined === 'object' && predefined?.value) {
                return predefined.value.toString();
            }
            return predefined.toString();
        }
        return null;
    }, [getValues]);
    

    useEffect(() => {
        // INTERFACE TO MAIN FORM
        setValue('branches', selectedBranches.map(({ branchId}) => branchId), {
            shouldDirty: true,
        });
    }, [selectedBranches]);

    const onSelectBranch = useCallback((branchId) => {
        const branchIndex = selectedBranches.findIndex( item => item.branchId === branchId );
        if (branchIndex > -1) {
            return remove(branchIndex);
        }
        return append({branchId});
    }, [append, remove, selectedBranches]);

    const selectedBranchesData = useMemo(() => {
        if (!units) return;
        return selectedBranches?.reduce( (acc, branch) => {
            const { branchId } = branch
            const unitHasBranch = units.find( ({ schoolBranches, id }) => {
                if (id.toString() === predefinedUnit) return false;
                return schoolBranches?.map( branch => branch.id ).includes(branchId);
            } );
            if (unitHasBranch) {
                return [...acc, {
                    schoolId: unitHasBranch.id,
                    name: unitHasBranch.name,
                    branchName: unitHasBranch.schoolBranches.find( branch => branch.id === branchId ).name,
                    branchId
                }]
            }
            return acc;
        }, []);
    }, [selectedBranches, units]);


    const onRemoveBranchFromSelected = (branchId) => {
        // every branch removal should remove corresponding answers;
        unregisterAnswersForBranch(branchId);
        const index = getValues('branches').findIndex( (id) => id === branchId );
        remove( index );
    }

    const validateBranches = useCallback((branchesArray) => {
        if (instance?.minPreferences && (branchesArray.length < instance.minPreferences)) {
            return `Nie wybrano odpowiedniej ilości preferencji (minimum: ${instance.minPreferences})`;
        }
        return true;
    }, [instance]);

    const unregisterAnswersForBranch = (branchId) => {
        const answers = getValues('Answers');
        if (!answers || Object.keys(answers).length < 1) return;
        Object.keys(answers).forEach( question => {
            // eslint-disable-next-line no-unused-vars
            const [uid, questionBranchId] = question.split('##');
            if (questionBranchId && questionBranchId.toString() === branchId.toString()) {
                unregister(`Answers.${question}`);
            }
        });
    }

    useEffect( () => {
        if (selectedBranches.length > 0) {
            updateModuleSchemaOnSelectedBranches(selectedBranches.map(({ branchId }) => branchId));
        }
    }, [selectedBranches]);

    const selectedUnits = useMemo(() => {
        return Array.from(new Set(selectedBranchesData?.map( ({ schoolId }) => schoolId )));
    }, [selectedBranchesData]);


    const noUnitMatches = units?.length > 0 && !unitsAgeGenderFiltered?.length > 0;

    usePreferencesFiller();

    return (
        <>
            { !noUnitMatches && (
                <PreferencesSelectedList
                    selectedUnits={selectedBranchesData || []}
                    onMove={move}
                    onRemoveBranchFromSelected={onRemoveBranchFromSelected}
                    ref={selectedRef}
                    disabled={formReadOnly}
                />
            )}

            
            { noUnitMatches && (
                <Styled.NotificationContainer>
                <Notification>
                    Wprowadzone dane nie pasują do żadnej z dostępnych jednostek.
                </Notification> 
                </Styled.NotificationContainer>
            )}

            {unitsAgeGenderFiltered && instance && !formReadOnly && (
                <UnitsFilters
                    instance={instance}
                    units={unitsAgeGenderFiltered}
                />
            )}


            {!formReadOnly && (unitsUserFiltered.length > 0) && (
                <PreferencesAvailableList
                    selectedBranches={ selectedBranches.map( ({ branchId }) => branchId ) }
                    selectedUnits={ selectedUnits }
                    units={ unitsUserFiltered }
                    onSelectBranch={onSelectBranch}
                    unitsLoading={ false }
                    predefinedUnit={ predefinedUnit }
                    
                    maxUnits={ instance?.maxPreferences }
                    maxBranches={ instance?.totalMaxPreferences || 1000}

                    alwaysShowBranches={alwaysShowBranches}

                    // showNoAgeBranchesInfo={ unitsFilteredWithAge && !unitsFilteredWithAge.length }
                    showNoAgeBranchesInfo={ false }
                />
            )}
            <DetailsBar
                onScrollToPreferences={() => selectedRef.current.scrollIntoView({ behavior: 'smooth' })}
                selectedPreferences={selectedBranches.length}
                selectedUnits={selectedUnits.length}
                maxPreferences={instance?.totalMaxPreferences}
                maxUnits={instance?.maxPreferences}

                bottom={bottomBarHeight}
            />
        </>
    )
};
export default PreferencesSection;

const DetailsBar = memo(({ 
    selectedPreferences, 
    selectedUnits,
    maxUnits, 
    maxPreferences, 
    onScrollToPreferences,

    bottom,
}) => {
    const { t } = useTranslation();
    return (
        <Styled.DetailsBar
            className='preferences-details-bar'
            bottom={bottom}
        >
            <Styled.DetailsBarInnerContainer>
                <div className='details-bar-text'>
                    Wybrane preferencje: <span>{selectedPreferences}</span> / <span>{maxPreferences}</span>; Wybrane szkoły: <span>{selectedUnits}</span> / <span>{maxUnits}</span>
                </div>
                {selectedPreferences > 0 && (
                    <Styled.DetailsBarShowselected
                        onClick={onScrollToPreferences}
                    >
                        <div className='show-selected'>{t('application.preferences.showSelectedPreferences')}</div>
                        <div>
                            <Styled.UpDownButton
                                className='up-down-button'
                                onClick={onScrollToPreferences}
                            >
                                <ArrowIcon />
                            </Styled.UpDownButton>
                        </div>
                    </Styled.DetailsBarShowselected>
                )}

            </Styled.DetailsBarInnerContainer>
        </Styled.DetailsBar>
    )
});


// adjust display according to Preferences
    // https://docs.psnc.pl/pages/viewpage.action?spaceKey=NaborNG&title=Edycja+preferencji+na+wniosku
    // const preferencesUnitList = useMemo(() =>{
    //     switch (instance?.unitGeneralType) {
    //         case UnitGeneralTypes.Nursery:
    //             return reduceBranchesIfOnlyOne(unitList);
    //         case UnitGeneralTypes.Kindergarten:
    //             return reduceBranchesIfOnlyOne(unitList);
    //         case UnitGeneralTypes.PrimarySchool:
    //             return reduceBranchesIfOnlyOne(unitList);     
    //         default: return unitList;
    //     }
    // }, [unitList, instance]);