import styled from 'styled-components';
import { theme as colors } from '../../../styles/theme.js'

export const CheckboxContainer = styled.label`
    pointer-events: ${props => (props.disabled === true ? "none" : "auto")};
    color: ${props => (props.disabled === true ? props.theme.colors.grey30 : "inherit")};
`;

export const Checkbox = styled.input`

`;

export const CheckboxLabel = styled.span`
    margin-left: 5px;
    font-size: 1.6rem;
    color: ${props => (props.error === true ? props.theme.colors.warning : "inherit")};
`;