import styled from "styled-components";

export const FeatureIconContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 8px;

    & img{
        width: 75px;
        height: 75px;
    }

	& p {
		font-size: 14px;
        text-align: center;
		width: 75px;
		word-break: break-word;
		hyphens: auto;
	}
`;

export const IconsList = styled.div`
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	gap: 3.2rem 0;
	
	@media ${({ theme }) => theme.device.mobile} {
		grid-template-columns: repeat(3, 1fr);
		gap: 2.4rem 0;

	}
`;
