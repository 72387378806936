import styled, { css } from 'styled-components';

export const DropletThumbnail = styled.div`
    padding: 10px;
    position: relative;
    box-sizing: border-box;
    aspect-ratio: 1 / 1;
    width: 100%;
    height: auto;
    grid-column: 1;
    grid-row: 1;

    max-width: ${props => props.maxwidth ? props.maxwidth : "30rem"};
    max-height: ${props => props.maxwidth ? props.maxwidth : "30rem"};
    padding: ${props => props.padding ? props.padding : "32px"};

    @media ${({theme}) => theme.device.mobile} {
        max-width: 10rem;
        max-height: 10rem;
        padding: 0;
    }

    &:hover > div:first-child {

      ${(props) =>
            props.end &&
        css`
          border-top-left-radius: ${props => (props.end === "top-left" ? "5%" : "50%")};
          border-top-right-radius: ${props => (props.end === "top-right" ? "5%" : "50%")};
          border-bottom-left-radius: ${props => (props.end === "bottom-left" ? "5%" : "50%")};
          border-bottom-right-radius: ${props => (props.end === "bottom-right" ? "5%" : "50%")};
        `}
    
        transform: rotate(${ props => props.rotationangle ? props.rotationangle : "0" }deg);
    }

    &:hover img {
        transform: scale(${props => props.imagescale ? props.imagescale : "1.25"}) rotate(${props => props.imagerotation ? props.imagerotation : "5deg"})
    }
`;


export const DropletThumbnailContent = styled.div`
    aspect-ratio: 1/1;
    width: 100%;
    height: auto;
    position: relative;
    z-index: 5;
    clip-path: circle(50.0% at 50% 50%);
 `;


export const DropletThumbnailImage = styled.img`
    object-fit: cover;
    width: 100%;
    height: auto;
    aspect-ratio: 1 / 1;
    position: relative;
    z-index: 10;
    border-radius: 50%;
    transition: all ${ props => props.dropletRotationSpeed ? props.dropletRotationSpeed : "0.3" }s ease;

 `;


export const DropletThumbnailDropled = styled.div`
    position: absolute;
    content: '';
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1;
    background-color: ${props => props.color ? props.color : "#aa0022"};
    border-top-left-radius: ${props => (props.start === "top-left" ? "5%" : "50%")};
    border-top-right-radius: ${props => (props.start === "top-right" ? "5%" : "50%")};
    border-bottom-left-radius: ${props => (props.start === "bottom-left" ? "5%" : "50%")};
    border-bottom-right-radius: ${props => (props.start === "bottom-right" ? "5%" : "50%")};
    transition: all ${ props => props.dropletRotationSpeed ? props.dropletRotationSpeed : "0.3" }s ease;

    @media ${({theme}) => theme.device.mobile} {
		  display: none;
    }
 `;


