const ExtraRow = (props) => {
	const onChange = (e) => {
		if (props.id) props.onChange(props.id, e.target.value === "true" ? props.max : 0);
		else props.onChange(e.target.value === "true")
	}

	return (
		<tr>
			<td>{props.children}</td>
			<td></td>
			<td className="calculator-radio-wrapper">
				<div >
					<input
						type="radio"
						id={"radio-yes-" + props.id}
						name={"radio" + props.id}
						value={true}
						checked={props.pts === props.max}
						onChange={onChange} />
					<label htmlFor={"radio-yes-" + props.id}>TAK</label>
				</div>
				<div>
					<input
						type="radio"
						id={"radio-no-" + props.id}
						name={"radio" + props.id}
						value={false}
						checked={props.pts === 0}
						onChange={onChange} />
					<label htmlFor={"radio-no-" + props.id}>NIE</label>
				</div>
			</td>
		</tr>
	)
}

export default ExtraRow;