import styled from 'styled-components';

export const Loading = styled.div`
    width: 100%;
	height: inherit;
	display: flex;
    position: relative;
	justify-content: center;
	align-items: center;
    padding: 50px;
	box-sizing: border-box;
`;

export const LoadingBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-gap: 25px;
`;

export const LoadingText = styled.span`
    opacity: ${(props) => (props.isLongerOver ? 1 : 0)};
    transition: all 0.5s;
    text-align: center;
`;


export const LoadingCircle = styled.div`
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
`;

export const LoadingCircleItem = styled.div`
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid #000;
    border-radius: 50%;
    animation: loading-circle 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #003189 transparent transparent transparent;

    &:nth-child(1) {
        animation-delay: -0.45s;
    }

    &:nth-child(2) {
        animation-delay: -0.3s;
    }

    &:nth-child(3) {
        animation-delay: -0.15s;
    }

    @keyframes loading-circle {
        0% {
        transform: rotate(0deg);
        }
        100% {
        transform: rotate(360deg);
        }
    }
`;


