import { Container } from "styles/common.styles";

import styled, {css} from 'styled-components';
const stepCircleSize = 5.4; // rem
const animationTime = 0.4 // seconds

export const FormStepSelector = styled.section`
    min-height: 26rem;
    background-color: ${({theme}) => theme.colors.selectItemBackground};
    box-shadow: inset 0px 0px 22px #0031892F;
    width: 100%;
    padding: 12rem 0 0;
    height: auto;

    @media ${props => props.theme.device.tablet} {
        padding: 20rem 0 0;
    }

    @media ${props => props.theme.device.desktop} {
        padding: 3rem 5rem 0;
    }
`;

export const FormStepSelectorContainer = styled(Container)`
    display: flex;
    flex-direction: column;
    align-items: normal;
    @media ${props => props.theme.device.touch} {
        align-items: center;
    }
`;

export const FormStepSelectorHeader = styled.div`
    font-size: 2.6rem;
    color: ${({theme}) => theme.colors.black};
    font-weight: 600;
    margin-bottom: 3rem;
    @media ${props => props.theme.device.touch} {
        text-align: center;
    }
`;

export const CirclesWrapper = styled.div`
    min-height: min-content;
    position: relative;
    margin-bottom: 7rem;
    width: 100%;
    ${({elementsCount}) => {
        if (elementsCount === 2) {
            return css`
                max-width: 30rem;
            `
        }
        if (elementsCount === 3) {
            return css`
                max-width: 60rem;
            `
        }
        if (elementsCount === 4 || elementsCount === 5) {
            return css`
                max-width: 80%;
            `
        }
        if (elementsCount === 5) {
            return css`
                min-width: 82rem;
            `
        }
    }}
        
    @media ${props => props.theme.device.touch} {
        margin-left: -16rem;
        margin-bottom: none;
    }
`;

export const HorizontalCirclesWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const FormStepSelectorButtonsWrapper = styled.div`
    padding-left: 2rem;
    padding-right: 2rem;
    @media ${props => props.theme.device.touch} {
        padding-left: 10rem;
        padding-right: 0;
    }
`;

export const FormStepSelectorButtons = styled.ul`
    display: flex;
    justify-content: space-between;
    list-style: none;
    position: relative;
    z-index: 2;
    @media ${props => props.theme.device.touch} {
        flex-direction: column;
        gap: 3.5rem;
    }
`;



export const StepSelectorBt = styled.li`
    cursor: pointer;
    width: ${stepCircleSize}rem;
    height: ${stepCircleSize}rem;
    position: relative;

    >.step-text {
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 2rem;
        font-weight: 600;
        color: ${({theme: {colors}, error}) => error ? colors.white : colors.main};
        transition: all 0.1s ease-in-out;
        z-index: 2;
    }
    >.step-bg-active {
        height: 100%;
        width: 100%;
        border-radius: 50%;
        background: ${({theme}) => theme.colors.accent};
        border: 5px solid ${({theme: {colors}, error}) => error ? colors.warning : colors.white};
        position: absolute;
        scale: 0;
        transition: all ${animationTime}s ease-in-out;
    }

    >.step-bg {
        border: 2px solid ${({theme}) => theme.colors.main};
        background: ${({theme: {colors}, error}) => error ? colors.warning : colors.white};
        height: 100%;
        width: 100%;
        border-radius: 50%;
        transition: all 0.1s ease-in-out;
    }

    >.step-description {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        width: max-content;
        max-width: 14rem;
        color: ${({theme}) => theme.colors.black};
        font-weight: 400;
        top: 6.5rem;
        text-align: center;
        @media ${props => props.theme.device.touch} {
            left: 8rem;
            top: 50%;
            transform: none;
            transform: translateY(-50%);
            max-width: 17rem;
            text-align: left;
        }
    }
    ${({disabled}) => disabled && css`
        pointer-events: none;
        >.step-text {
            color: ${({theme}) => theme.colors.grey30};
        }
        >.step-bg {
            background: ${({theme}) => theme.colors.selectItemBackground};
            border: 2px solid ${({theme}) => theme.colors.grey30};
        }
        >.step-description {
            color: ${({theme}) => theme.colors.grey30};
        }
    `};

    ${({active}) => active && css`
        pointer-events: none;
        >.step-text {
            color: #FFFFFF;
        }
        >.step-description {
            font-weight: 600;
        }
        >.step-bg-active {
            scale: 1.02;
        }
    `};
    
    ${({active, disabled}) => !disabled && !active && css`
        &:hover {
            >.step-text {
                scale: 1.2;
            }
            >.step-bg {
                scale: 1.1;
            }
        }
    `};
`;

export const FormStepSelectorLines = styled.div`
    width: calc(100% - ${stepCircleSize}rem);
    margin-left: ${stepCircleSize/2}rem;
    position: absolute;
    margin-top: ${stepCircleSize/2}rem;
    >div {
        border-top: 2px solid ${({theme}) => theme.colors.grey30};
        height: 1px;
    }
    >.line-active {
        border-color: ${({theme}) => theme.colors.accent};
        border-width: 3px;
        position: absolute;
        transition: all ${animationTime}s ease-in-out;
        width: ${({percent}) => `${percent}%`};
    }

    @media ${props => props.theme.device.touch} {
        height: calc(100% - ${stepCircleSize}rem);
        margin-left: ${stepCircleSize/2}rem;
        >div {
            border-top: none;
            border-left: 2px solid ${({theme}) => theme.colors.grey30};
            height: 100%;
            width: 1px;
        }
        >.line-active {
            height: ${({percent}) => `${percent}%`};
        }
    }
`;

export const NextButton = styled.div`
    display: flex;
    width: 3.2rem;
    height: 3.2rem;
    background-color: ${({theme}) => theme.colors.main};
    border: 2px solid ${({theme}) => theme.colors.white};
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding-left: 1px;
    transition: all 0.1s ease-in-out;

    & .cls-1 {
        fill: ${({theme}) => theme.colors.white};
    }

    ${({isPrevious}) => isPrevious && css`
        padding-left: 0;
        padding-right: 1px;
        >svg {
            transform: rotate(180deg);
        }
    `}

    ${({disabled}) => disabled && css`
        pointer-events: none;
        background-color: ${({theme}) => theme.colors.grey30};
        border-color: ${({theme}) => theme.colors.white};
    `}

    &:hover {
        background-color: ${({theme}) => theme.colors.white};
        border-color: ${({theme}) => theme.colors.main};
        & .cls-1 {
            fill: ${({theme}) => theme.colors.main};
        }
    }

    @media ${props => props.theme.device.touch} {
        display: none;
    }
`;