import React from 'react';
import Modal from './Modal';

import * as Styled from './Modal.styles';

const ModalInfo = ({ closeModal, info }) => (
    <Modal
            closeModal={closeModal}
            wrapModal={true}
            closeButton={true}
        >
            {() => <Styled.MoadlInfoText>{info}</Styled.MoadlInfoText>}

        </Modal>
);

export default ModalInfo;