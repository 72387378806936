import { useState } from 'react';

const CertificateRow = (props) => {
    const [exempted, setExempted] = useState(false);
	const onChange = (e) => {
        props.onChange(
		    props.id,
		    parseInt(e.target.value)
	    );
        const checkbox = document.querySelector(`input[type="checkbox"][name="${props.id}"]`);
        if (checkbox) {
            checkbox.checked = false;
        }
        setExempted(false);
    }

    const onChangeExempt = (e) =>  {
        props.onChange(
            props.id,
            e.target.checked? parseInt(e.target.value): 0);
        setExempted(e.target.checked);
    }

    return (
        <tr>
            <td>{props.children}</td>
            <td>
                <input type="checkbox" className="exemption"
                       value={18} name={props.id}
                       onChange={onChangeExempt} />
            </td>
            <td>
                <div className="certificateValues">
                    <input type="radio"
                        label="2"
                        value={2}
                        name={props.id}
                        checked={props.pts === 2 && !exempted}
						onChange={onChange} />
                    <input type="radio"
                        label="3"
                        value={8}
                        name={props.id}
						checked={props.pts === 8 && !exempted}
						onChange={onChange} />
                    <input type="radio"
                        label="4"
                        value={14}
                        name={props.id}
						checked={props.pts === 14 && !exempted}
						onChange={onChange} />
                    <input type="radio"
                        label="5"
                        value={17}
                        name={props.id}
						checked={props.pts === 17 && !exempted}
						onChange={onChange} />
                    <input type="radio"
                        label="6"
                        value={18} name={props.id}
						checked={props.pts === 18 && !exempted}
						onChange={onChange} />
                </div>
            </td>
        </tr>
    )
}

export default CertificateRow;