import styled from "styled-components";

export const ModalWrapper = styled.div`
    position: fixed;
    width: 100vw;
    height: 100vh;
    left: 0;
    top: 0;
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${({theme}) => theme.colors.modalTransparentBackground};
`;

export const ModalContainer = styled.div`
    max-width: 82rem;
    width: 90dvw;
    background-color: ${({theme}) => theme.colors.white};
    box-shadow: 0px 0.3rem 1.2rem #00000029;
    border-radius: 2.2rem;
    padding: 4rem;
    position: relative;
    overflow: hidden;

    ${({ isDeleteAccountModal }) =>
  isDeleteAccountModal &&
  `
  width: 450px;
`}
    &.modal-login-problem {
        width: 50rem;
    }
`;
export const MoadlInfoText = styled.p`
    color: ${({theme}) => theme.colors.black};
    font-size: 1.8rem;
`;

export const CloseButton = styled.button`
    position: absolute;
    right: 2rem;
    top: 2rem;
    cursor: pointer;
    width: fit-content;
    padding: 0px;
    background-color: transparent;
    transition: all 0.2s ease-in-out;
    border: none;
    >img {
        width: 1.6rem;
        background-color: white;
        margin: 1rem;
    }
    &:hover {
        transform: scale(0.9);
    }
`;