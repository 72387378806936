import { useTranslation } from "react-i18next";

import * as Styled from "pages/LoginOrRegister/LoginOrRegister.styles";

const LoginOrRegisterPanel = ({login, register}) => {
    const { t } = useTranslation();

    return (
        <Styled.LoginOrRegisterPanel className="login-register-panel">
            <Styled.ButtonContainer>
            <Styled.Button onClick={login}>{t('loginOrRegister.loginButton')}</Styled.Button>
            <Styled.LoginOrRegisterParagraph>{t('loginOrRegister.loginButtonDescription')}</Styled.LoginOrRegisterParagraph>
            </Styled.ButtonContainer>
            <Styled.LoginOrRegisterParagraph>LUB</Styled.LoginOrRegisterParagraph>
            <Styled.ButtonContainer>
            <Styled.Button onClick={register}>{t('loginOrRegister.register')}</Styled.Button>
            <Styled.Link to="/#">STRONA GŁÓWNA</Styled.Link>
            </Styled.ButtonContainer>
        </Styled.LoginOrRegisterPanel>
    );
}

export default LoginOrRegisterPanel;