import React, { useState, forwardRef, useEffect, memo, useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import styled from 'styled-components';
import * as yup from 'yup';

import * as InputStyles from 'components/commons/Input/Input.styles';
import Label from './elements/Label';
import Error from './elements/Error';
import Description from './elements/Description';

import validateField from '../utils/validateField';

const TextFieldForm = forwardRef((props, ref) => {
    const {
        name,
        label,
        placeholder,
        isRequired,
        error,
        validation,
        fullWidth,
        disabled,
        multiline,
        description,
        onBlur,
        chevron,
        isVisible,
    } = props;
    
    const { t } = useTranslation();
    const { register, formState: { errors }, trigger } = useFormContext();

    const onFieldBlur = useCallback((ev) => {
        if (onBlur) {
            onBlur(ev);
        }
        if (name) {
            trigger(name);
        }
    }, [onBlur, name, trigger]);
    
    const inputProps = {
        ...(name && register(name, {
            ...(validation || isRequired) && {
                validate: async value => {
                    const currentValidation = await validateField(
                        value,
                        validation || `string().required(\`${t('application.errors.fieldRequired')}\`)`,
                        yup,
                        name
                    );
                    return currentValidation;
                }
            }
        })),
        type: 'text',
        disabled: disabled,
        placeholder: placeholder || "",
        error: !!errors[name],
        ...props,
        onBlur: onFieldBlur,
    };

    const momoizedLabel = useMemo(() => {
        return (
            <Label
                label={label}
                isRequired={isRequired}
                htmlFor={name}
                error={false}
            />
        )
    }, []);

    const memoizedHelper = useMemo(() => {
        return (
            <Description>{description}</Description>
        )
    }, []);

    return (
        <Wrapper
            disabled={disabled}
            fullWidth={fullWidth}
            isVisible={isVisible}
            className='text-field-form'
        >
            {momoizedLabel}
            <InputWrapper className='input-wrapper'>
                {multiline ? (
                    <InputStyles.InputTextArea
                        className="text-area-input"
                        {...inputProps}
                    />
                ) : (
                    <InputStyles.Input
                        className="text-input"
                        {...inputProps}
                    />
                )}
                {chevron}
            </InputWrapper>
            <Error error={errors[name] || error} />
            {memoizedHelper}
        </Wrapper>
    )
});

TextFieldForm.displayName = 'TextFieldForm';

export default memo(TextFieldForm);

const Wrapper = styled(InputStyles.InputContainer)`
    display: ${({ isVisible }) => isVisible === false ? 'none' : 'flex'};
    position: relative;
    margin-bottom: 0.5rem;
    flex-direction: column;
    align-items: flex-start;
    font-size: 1.4rem;
    font-weight: 400;
    color: ${({ theme }) => theme.colors.black};
    ${({ fullWidth }) => fullWidth && `
        grid-column: 1 / -1;
    `};
`;

const InputWrapper = styled.div`
    width: 100%;
    position: relative;
`;