import React from 'react';
import PropTypes from "prop-types";
import { useTranslation } from 'react-i18next';
import Modal from 'components/commons/Modal/Modal';
import { ButtonText } from 'components/commons/Button/ButtonText';
import { Button } from 'components/commons/Button/Button';
import * as Styled from './ModalConfirm.styles';

const ModalConfirm = ({ 
    onConfirm = () => {}, 
    closeModal, 
    title, 
    text, 
    confirmText = null, 
    cancelText = null,
    closeButton=true,
    className,
}) => {
    const { t } = useTranslation();
    return (
        <Modal
            closeModal={closeModal}
            wrapModal={true}
            closeButton={closeButton}
            closeOnBackground={closeButton}
        >
            {(closeModal) => (
                <Styled.ModalContent className={className || 'modal-confirm-content'}>
                    { title && <Styled.ModalHead>
                        {title}
                    </Styled.ModalHead> }

                    { text && <Styled.ModalText>
                        {text}
                    </Styled.ModalText> }

                    <Styled.ModalButtonsWrapper>
                        <ButtonText
                            onClick={closeModal}
                            type='button'
                        >
                            { cancelText || t('common.cancel').toUpperCase() }
                        </ButtonText>
                        <Button
                            onClick={ () => {
                                onConfirm();
                            }}
                            type='button'
                        >
                            { confirmText || t('common.confirm').toUpperCase() }
                        </Button>
                    </Styled.ModalButtonsWrapper>
                </Styled.ModalContent>
            )
            }
        </Modal >
    );
};
export default ModalConfirm;

ModalConfirm.propTypes = {
    onConfirm: PropTypes.func,
    closeModal: PropTypes.func.isRequired,
    title: PropTypes.string,
    text: PropTypes.string,
    confirmText: PropTypes.string,
    cancelText: PropTypes.string,
    className: PropTypes.string,
    closeButton: PropTypes.bool,
};