import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useApi from "hooks/useApi";

const MailVerification = () => {
    let { appIdGuid, timeGuid } = useParams();
    const { t } = useTranslation();
    const { candidateApi } = useApi();
    const [executed, setExecuted] = useState(false);
    const [message, setMessage] = useState("");

    useEffect(() => {
        async function fetchData() {
            var response = await candidateApi.post(`application/verifybymail/${appIdGuid}/${timeGuid}`);
            setExecuted(true);
            if (response.data) {
                setMessage(response.data.message);
            }
        }
        if(appIdGuid && timeGuid) {
            setExecuted(false);
            setMessage("");
            fetchData();
        }
    }, [appIdGuid, timeGuid]);

    return (
        <>
            {executed &&
                <div className="wrapper">
                    <div className="container">
                        {message && <h3>{message}</h3>}
                        {!message && <h3>{t("application.errors.mailVerificationError")}</h3>}
                    </div>
                </div>
            }
        </>
    )
};

export default MailVerification;