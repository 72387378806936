import { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { AppContext } from 'context/appContext';
import { getCity, getInstance, getRegion } from 'utils/utils';

import url from 'routes/routes';

export const useInstance = (addBreadcrumbs = null) => {
    const { t } = useTranslation();
    const { city, generalType } = useParams();
    const { instances, regions, setBreadcrumbsPath } = useContext(AppContext);

    const [cityName, setCityName] = useState(null);
    const [region, setRegion] = useState(null);
    const [instance, setInstance] = useState(null);

    useEffect(() => {

        if (!city) setCityName(null);

        if (city && regions?.length) {
            const localRegions = getRegion(regions, city);
            if (localRegions?.length) {
                setRegion(localRegions[0]);
                setCityName(localRegions[0].name);
            } else {
                setRegion(null);
                setCityName(null);
            }
        } else setRegion(null);

        if (city && generalType && instances?.length) {
            const localInstance = getInstance(instances, city, generalType);
            if (localInstance.length) {
                setInstance(localInstance[0]);
                setCityName(localInstance[0].instanceName);
            } else setInstance(null);
        } else setInstance(null);

    }, [city, instances, regions, generalType]);

    useEffect(() => {
        if(!addBreadcrumbs) return;
        setBreadcrumbsPath([
            {
                name: t('breadcrumbs.allInstances'),
                url: url.allInstances.all
            },
            {
                name: cityName,
                url: "/"+city
            },
            {
                name: t(`unitTypes.${generalType}`),
                url: "/"+city+"/"+generalType
            },
            ...addBreadcrumbs,
        ]);
    }, [cityName])

    return {
        instance,
        id: instance ? instance.id : null,
        cityName,
        region
    };
}