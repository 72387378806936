import { useEffect, useContext, useCallback } from 'react';
import { useParams } from "react-router-dom";

import { AppContext } from "context/appContext";

import useStorageStateExpiring from "hooks/useStorageStateExpiring";
import useApi from "hooks/useApi";

import { getInstance } from "utils/utils";

import useUnknownCityOrType from "hooks/useUnknownCityOrType";

const useInstanceAndUnits = (formReadOnly) => {
    const { city, generalType } = useParams();
    const { offerApi } = useApi();

    const { instances: instancesList } = useContext(AppContext);

    useUnknownCityOrType();

    const [instanceId, setInstanceId] = useStorageStateExpiring(`/${city}/${generalType}/instanceId`);
    const [instance, setInstance] = useStorageStateExpiring(`/${city}/${generalType}/instance`, 1);

    const [units, setUnits] = useStorageStateExpiring(`/${city}/${generalType}/unitsappdescription`, 60);

    /// first of all - we need instance
    // check if there is list of instances in AppContext
    const getInstanceIdFromInstanceList = useCallback((city, generalType, instancesList) => {
        if (instancesList?.length > 0) {
            const instances = getInstance(
                instancesList,
                city,
                generalType
            );
            if (instances != null && instances.length > 0) {
                const { id } = instances[0];
                if (id) setInstanceId(id);
            }
        }
    }, []);

    // there is no instanceId - we need to get it
    const fetchInstance = async (id) => {
        // eslint-disable-next-line no-unused-vars
        const { data, error } = await offerApi.get(`instance/${id}`);
        setInstance(data);
    };

    useEffect(() => {
        // START HERE - only with the instanceId can get instance
        if (!instanceId) {
            getInstanceIdFromInstanceList(city, generalType, instancesList);
        } else {
            if (!instance) {
                fetchInstance(instanceId);
            }
        }
    }, [instanceId]);


    const getUnits = useCallback(async (curInstanceId, formReadOnly) => {
        let { data: fetchedUnits } = await offerApi.get(`appdescription/${curInstanceId}/units${formReadOnly ? "?sa=false" : ""}`);
        // console.log('TUTAJ DODAJĘ TESTOWE idDictUnitTypes do warszawa żłobków (37, 40)');
        // fetchedUnits = fetchedUnits.map (( unit ) => {
        //     if (unit.id === 103393) unit.id_DictUnitType = 89; // warszawa - żłobek nr 37 - dodaję 89 - żłobkowy start
        //     if (unit.id === 103396) unit.id_DictUnitType = 109; // warszawa - żłobek nr 40 - dodaję 109 - równy start
        //     return unit;
        // });


        setUnits(fetchedUnits);
    }, [ offerApi, setUnits ]);

    useEffect(() => {
        if (instanceId) {
            getUnits(instanceId, formReadOnly);
        }
    }, [instanceId, formReadOnly]);

    return {
        units,
        instance,
        instanceId,
    };
}
export default useInstanceAndUnits;