import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { UnitTypes } from "utils/enums";

import * as Styled from "./HowToSection.styles";
import * as Layout from "components/commons/Layout/Layout";
import * as Text from "components/commons/Text/Text";


const HowToSection = ({ indexesToRemove = [] }) => {
    const { t } = useTranslation();
    const { generalType } = useParams();

    const getSchoolType = (type) => {
        switch (type) {
            case UnitTypes.Nursery:
                return UnitTypes.Nursery;
            case UnitTypes.Kindergarten:
                return UnitTypes.Kindergarten;
            case undefined:
                return "default";
            default:
                return "szkola";
        }
    }

    const varietyDict = {
        [UnitTypes.Nursery]: {
            genitive: "żłobka",
            genitivePlural: "żłobków",
            accusativePlural: "żłobki",
        },
        [UnitTypes.Kindergarten]: {
            genitive: "przedszkola",
            genitivePlural: "przedszkoli",
            accusativePlural: "przedszkola",
        },
        szkola: {
            genitive: "szkoły",
            genitivePlural: "szkół",
            accusativePlural: "szkoły",
        },
        default: {
            genitive: "placówki",
            genitivePlural: "placówek",
            accusativePlural: "placówki",
        },
    };

    return (
        <Styled.HowToSection id="rekrutacja">
            <Layout.Container narrow>
                <Styled.Wrapper>
                    <Styled.Title>
                        <Text.TitleMain>{t("howToSection.title")}</Text.TitleMain>
                    </Styled.Title>
                    <Styled.Columns>
                        {t("howToSection.columns", {
                            returnObjects: true,
                            ...varietyDict[getSchoolType(generalType)],
                        })
                            .filter((_, index) => !indexesToRemove.includes(index))
                            .map((item, i) => (
                                <HowToSectionItem
                                    key={item.title}
                                    index={i + 1}
                                    title={item.title}
                                    content={item.content}
                                />
                            ))}
                    </Styled.Columns>
                </Styled.Wrapper>
            </Layout.Container>
        </Styled.HowToSection>
    );
};

HowToSection.propTypes = {
    indexesToRemove: PropTypes.arrayOf(PropTypes.number),
};

export default HowToSection;


const HowToSectionItem = ({ index, imgAlt, title, content }) => {
    return (
        <Styled.Item>
            <Styled.ItemHead>
                <Styled.ItemNumber>{index}</Styled.ItemNumber>
                <img src={require(`assets/images/howto${index}.svg`)} alt={title} />
            </Styled.ItemHead>
            <Styled.ItemTitle>
                <Text.TitleMini>{title}</Text.TitleMini>
            </Styled.ItemTitle>
            <Styled.ItemContent>
                <Text.Content>{content}</Text.Content>
            </Styled.ItemContent>
        </Styled.Item>
    );
};