import React from "react";
import dayjs from "dayjs";
import "dayjs/locale/pl";

import {UnitTypes, UnitGeneralTypes} from 'utils/enums';

export const wait = miliseconds => new Promise(
    resolve => setTimeout(resolve, miliseconds)
);

export const decodeEscapedHtml = (input) => {
  const e = document.createElement("div");
  e.innerHTML = input;
  return e.textContent;
};

export const isVisibleHtml = (input) => {
  const e = document.createElement("div");
  e.innerHTML = input;
  return !!e.outerText?.trim().length > 0;
};

export const cities = (instances) => {
  let comparer = new Intl.Collator("pl").compare;
  let uniqueList = instances.filter(
    (x, i, a) => a.findIndex((el) => el.regionName === x.regionName) === i
  );
  return uniqueList
    .map((i) => ({ id: i.regionUrl, name: i.regionName }))
    .sort((a, b) => comparer(a.name, b.name));
};

export const getCity = (instances, cityFromParam) => {
  return instances.filter((x, i, a) => x.regionUrl === cityFromParam)[0]
    .instanceName;
};

export const getInstances4Region = (instances, regionurl) => {
  return instances.filter((x, i, a) => x.regionUrl === regionurl);
};

export const getInstancesByType = (instances, typeurl) => {
  return instances.filter((x, i, a) => x.typeUrl === typeurl);
};

export const getInstance = (instances, regionurl, typeurl) => {
  return instances.filter(
    (x, i, a) =>
      (x.instanceUrl === typeurl ||
        (x.typeUrl === typeurl && x.instanceUrl == null)) &&
      x.regionUrl === regionurl
  );
};

export const getInstanceById = (instances, id) => {
  return instances.find(
      (x, i, a) =>
          (x.id === id)
  );
};

export const getRegion = (regions, cityFromParam) => {
    return  regions.filter((x, i, a) => x.url === cityFromParam);
}

export const getDate = (date) => {
  if (date)
    return dayjs(date).format("DD.MM.YYYY - HH:mm");
  return "";
};

export const getChildAge = (birthDate) => {
    if (birthDate) {
        const then = dayjs(birthDate);
        const today = dayjs();
        const years = today.diff(then, 'year');
        const months = today.diff(then, 'month');
        const weeks = today.diff(then, 'week');
        const firstSeptember = dayjs(new Date( today.toDate().getFullYear(), 8, 1));
        const yearsOnFirstSeptember = firstSeptember.diff(then, 'year');
        const monthsOnFirstSeptember = firstSeptember.diff(then, 'month');
        const weeksOnFirstSeptember = firstSeptember.diff(then, 'week');
        return {years, months, weeks, yearsOnFirstSeptember, monthsOnFirstSeptember, weeksOnFirstSeptember};
    }
    return null;
};

export const createDictFromArray = (array, keyId, valueId) => {
  if (!Array.isArray(array) || array?.length < 1) return null;
  return array.reduce((acc, cur) => {
    acc[cur[keyId]] = cur[valueId];
    return acc;
  }, {})
};

export const getKeyByValue = (object, value) => {
  return Object.keys(object).find((key) => object[key] === value);
};

export const linkify = (url) => {
  if (!url.startsWith("http")) return "http://" + url;
  return url;
};

export const orderby = (field, resolver) => {
  const valueof = resolver ?
      function(x) {
        return resolver(x[field])
      } :
      function(x) {
        return x[field]
      };

  return function(a, b) {
    a = valueof(a);
    b = valueof(b);
    return ((a > b) - (b > a));
  }
}

export const orderbydesc = (field, resolver) => {
  const valueof = resolver ?
      function(x) {
        return resolver(x[field])
      } :
      function(x) {
        return x[field]
      };

  return function(a, b) {
    a = valueof(a);
    b = valueof(b);
    return -1 * ((a > b) - (b > a));
  }
};

export const propertyNotEmpty = (property) => {
	return (
		property !== null &&
		typeof property !== "undefined" &&
		(Array.isArray(property) ? property.length > 0 : true) &&
		(typeof property === "string" ? property.trim().length > 0 : true)
	);
};

export const isHtmlEmpty = (content) => {
	if (typeof content === "string") {
		return content.trim().length > 0;
	}

	if (React.isValidElement(content)) {
		return isHtmlEmpty(content.props.children);
	}

	if (Array.isArray(content)) {
		if (content.length > 0) {
			return content
				.map((el) => isHtmlEmpty(el))
				.reduce((acc, cur) => acc || cur);
		}
		return false;
	}

	return !!content;
};

export const setIntervalInstantly = (func, interval) => {
  func();
  return setInterval(func, interval);
};

export const getAppTypeFromString = (stringAppType) => {
    switch(stringAppType) {
        case "deklaracja": return 1;
        case "zgłoszenie": return 2;
        case "wniosek": return 3;
        case "odwolanie": return 4;
        default: return 0;
    }
}

export const getGeneralTypeFromString = (generalType) => {
  switch(generalType) {
      case UnitTypes.Nursery: return UnitGeneralTypes.Nursery;
      case UnitTypes.Kindergarten: return UnitGeneralTypes.Kindergarten;
      case UnitTypes.PrimarySchool: return UnitGeneralTypes.PrimarySchool;
      case UnitTypes.Class4: return UnitGeneralTypes.Class4;
      case UnitTypes.Class6: return UnitGeneralTypes.Class6;
      case UnitTypes.Class7: return UnitGeneralTypes.Class7;
      case UnitTypes.SecondarySchool: return UnitGeneralTypes.SecondarySchool;
      case UnitTypes.TradeSchool: return UnitGeneralTypes.TradeSchool;
      case UnitTypes.PostSecondarySchool: return UnitGeneralTypes.PostSecondarySchool;
      case UnitTypes.Dormitory: return UnitGeneralTypes.Dormitory;
      case UnitTypes.HolidayDuty: return UnitGeneralTypes.HolidayDuty;
      case UnitTypes.Semicolony: return UnitGeneralTypes.Semicolony;
      default: return -1;
  }
}

export const podajMianownik = function(appType, generalType = 0) {
    if (typeof appType !== "number") {
        appType = getAppTypeFromString(appType);
    }
    if (typeof generalType !== "number") {
      generalType = getGeneralTypeFromString(generalType);
    }
    switch(parseInt(appType)) {
        case 1: return "deklaracja";
        case 2: return "zgłoszenie";
        case 3: return generalType === 11 ? "zgłoszenie" : "wniosek";
        default: return "podanie";
    }
}
export const podajBiernik = function(appType, generalType = 0) {
    if (typeof appType !== "number") {
        appType = getAppTypeFromString(appType);
    }
    if (typeof generalType !== "number") {
        generalType = getGeneralTypeFromString(generalType);
    }
    switch(parseInt(appType)) {
        case 1: return "deklaracji";
        case 2: return "zgłoszenia";
        case 3: return generalType === 11 ? "zgłoszenia" : "wniosku";
        default: return "podania";
    }
}

export const podajCelownik = function(appType, generalType = 0) {
    if (typeof appType !== "number") {
        appType = getAppTypeFromString(appType);
    }
    if (typeof generalType !== "number") {
        generalType = getGeneralTypeFromString(generalType);
    }
    switch(parseInt(appType)) {
        case 1: return "deklarację";
        case 2: return "zgłoszenie";
        case 3: return generalType === 11 ? "zgłoszenie" : "wniosek";
        default: return "podanie";
    }
}


const baseParseAppType = (appType) => {
    switch (parseInt(appType)) {
        case 1:
            return 'deklaracja';
        case 2:
            return 'zgloszenie';
        case 3:
            return 'wniosek';
        case 4:
            return 'odwolanie';
        case 5:
            return 'wybor';
        default:
            return 'wniosek';
    }
}
export const parseAppType = (appType, withoutSlash = false) => {
    return `${withoutSlash ? '' : '/'}${baseParseAppType(appType)}`;
}

export const isDateValid = (date) => {
    return !isNaN(Date.parse(date));
}