import React, { forwardRef, useMemo } from "react";
import PropTypes from "prop-types";
import * as Styled from "./Input.styles";

export const Input = forwardRef(
  function InputFR({ 
    label, 
    disabled, 
    error, 
    required, 
    children, 
    placeholder, 
    fullWidth,
    ...props },
    ref) {

    const errorMessage = useMemo(() => {
        if (error) {
            if (typeof error === "string") {
                return error;
            } else {
                return error?.message || null;
            }
        }
        return null;
    }, [error]);

    return (
      <Styled.InputContainer disabled={disabled} fullWidth={fullWidth} >
        { (children || label) && (
            <Styled.InputLabel error={!!error}>
                {required && <Styled.InputRequired>*</Styled.InputRequired>}
                {children}
                {label}
            </Styled.InputLabel>
        )}
        <Styled.Input
          disabled={disabled}
          placeholder={placeholder}
          error={!!error}
          ref={ref}
          {...props}
        />
        {errorMessage && <Styled.InputError>{errorMessage}</Styled.InputError>}
      </Styled.InputContainer>
    );
  }
);

export default Input;

Input.propTypes = {
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  error: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      message: PropTypes.string.isRequired,
    }),
    PropTypes.bool,
  ]),
  placeholder: PropTypes.string,
};

Input.defaultProps = {
  error: false,
  required: false,
  disabled: false,
  placeholder: "",
};
