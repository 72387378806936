import { useEffect, useMemo, useCallback, useContext } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import useApi from "hooks/useApi";
import FormSectionWrapper from './FormSectionWrapper';
import FieldFactory from '../fields/FieldFactory';

const PredefinedSchool = ({data, collapsed, setCollapsed}) => {
    const { t } = useTranslation();
    const { getValues, setValue, setError, clearErrors } = useFormContext();
    const { candidateApi, offerApi } = useApi();

    const fetchDistrict = useCallback(async (idCommunity, idCity, idStreet, house) => {
        clearErrors(data.fields[0].name);
        try {
            const { data: preselectedId } = await offerApi.get(`AppDescription/district?idCommunity=${idCommunity}&idCity=${idCity}&idStreet=${idStreet || null}&house=${house}`);
            return preselectedId.toString();
        } catch(error) {
            setError(data.fields[0].name, { type: 'custom', message: t('application.errors.predefinedFetchListError') });
        }
    }, []);

    const terytVals = useMemo(() => {
        if (getValues) {
            return getValues(['Id_Community', 'Id_City', 'Id_Street', 'HouseNo']);
        }
    }, [getValues]);

    const setInitialValue = async () => {
        let arr = Object.entries(data?.fields[0].options).map(([label, value]) => ({ value, label }));

        const rootFormPreselected = getValues(data.fields[0].name);
        const preselectedId = rootFormPreselected?.value || rootFormPreselected;
        if (rootFormPreselected) {
            const found = arr.find( element => element.value === preselectedId.toString());
            if (found) {
                return setValue(data?.fields[0].name, found);
            }
        }
        
        const fetchedDistrict = await fetchDistrict(...terytVals);
        if (fetchedDistrict) {
            const found = arr.find( element => element.value === fetchedDistrict);
            if (found) {
                return setValue(data?.fields[0].name, found);
            }
        }
    }

    useEffect(() => {
        if (data && data?.fields[0] && data?.fields[0].options) {
            setInitialValue();
        }
    }, [data]);

    return (
            <FormSectionWrapper 
                section={data} 
                collapsed={collapsed} 
                setCollapsed={setCollapsed}
                showHeader={false}
            >
                {
                    data?.fields.map((field) => {
                        return (
                            <FieldFactory
                                field={field}
                                key={field.name}
                                // disabled={disabled}
                            />
                        )
                    })
                }
            </FormSectionWrapper>
    )
}

export default PredefinedSchool;