import React, { useEffect, useContext } from "react";
import { useTranslation, Trans } from "react-i18next";

import { AppContext } from "context/appContext";

import { useJsonData } from "hooks/useJsonData";


import StaticPage from "components/reusable/StaticPage/StaticPage";
import Banner from "components/commons/Banner/Banner";
import Breadcrumbs from "components/commons/Breadcrumbs/Breadcrumbs";
import { H2 } from "styles/common.styles";

import * as Styled from "./ApplicationContact.styles";

const ApplicationContact = () => {
  const { t } = useTranslation();
  const data = t("applicationContact:points", {returnObjects: true});
  const { setBreadcrumbsPath } = useContext(AppContext);

  useEffect(() => {
    setBreadcrumbsPath([
      {
        name: t("breadcrumbs.contact"),
      },
    ]);
  }, []);

  return (
    <>
      <Breadcrumbs />
      <Banner />
      <StaticPage title={t("menu.contact")}>
      {data.length > 0 && data.map((item) => (
            <Styled.ApplicationContact>
              <h3>{item.title}</h3>
              <Trans
                i18nKey={item.content}
                components={{ br: <br />, ul: <ul></ul>, li: <li />, b: <b />, ol: <ol></ol>, p: <p></p>, h3: <h3></h3>, a: <a></a>, address: <address></address>, abbr: <abbr></abbr> }}
              />
            </Styled.ApplicationContact>
        ))}
      </StaticPage>
    </>
  );
};

export default ApplicationContact;
