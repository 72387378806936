import {useCallback, useEffect, useState} from 'react';
import { useFormContext } from "react-hook-form";

export const usePreferencesFiller = () => {

    const handleShortcutClick = useCallback((e) => {
		if(e.ctrlKey && e.shiftKey) {
			const checkboxes = document.querySelectorAll('.available-preferences input[type="checkbox"]:not(:checked)');
			checkboxes.forEach(checkbox => checkbox.click());
		}
	}, []);

    useEffect(()=>{
		if (process.env.REACT_APP_ENV === "production") {
			return;
		}
		
		document.addEventListener("keydown", handleShortcutClick);

		return () => document.removeEventListener("keydown", handleShortcutClick);
	}, [handleShortcutClick]);
}