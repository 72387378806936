import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import { instanceFiltersConfig } from "./UnitsFilters.config";

import { DetailedUnitTypes } from "utils/enums";

const secondarySchoolTypes = {
    [DetailedUnitTypes.TradeSchool]: "Szkoła branżowa",
    [DetailedUnitTypes.SecondarySchool]: "Liceum",
    [DetailedUnitTypes.TechnicalSchool]: "Technikum",
};

const getDistrictOptions = (units) => [...new Set(units?.map((el) => el.district))].filter(
    (element) => element !== "" && element !== null
).map((district) => ({ label: district, value: district }));

const getLocalFeatures = (instance) => {
    if (instance?.settings?.ShowAlternateNames) {
        return instance?.features?.map(feature => instance.alternateFeatureNames?.find((obj) => feature.id === obj.id) ??
            feature).filter(featureToFilter => instance.visibleFeatures?.includes(featureToFilter.id));
    }
    return instance?.features?.filter(featureToFilter => instance.visibleFeatures?.includes(featureToFilter.id));
}

const getSchoolTypes = (units) => {
    const parsedFromUnits = [...new Set(units?.map((el) => el.id_DictUnitType))].filter((el) => el !== 100);
    return parsedFromUnits.map((el) => ({ label: secondarySchoolTypes[el], value: el.toString() }));
}

const parseIdsToValues = (arr) => arr?.map(({ label, value, id }) => ({
    label,
    value: value || id.toString(),
}));

const useFiltersInitial = ({ instance, units }) => {
    const { search: searchParams } = useLocation();
    const { t } = useTranslation();

    const unitTypeId = instance?.unitGeneralType;

    const unitTypes = useMemo(() => ([
        {
            label: t("units:publicShort"),
            value: "true",
        },
        {
            label: t("units:privateShort"),
            value: "false",
        },
    ]), [t]);

    const getOptions = (id) => {
        // all instance options go here
        if (Array.isArray(id) && id.length > 0) {
            return id.map((el) => ({ label: el, value: el }));
        }
        switch (id) {
            case "districts":
                return getDistrictOptions(units);
            case "languages":
                return parseIdsToValues(instance?.languages);
            case "secondarySchoolTypes":
                return getSchoolTypes(units);
            case "types":
                return unitTypes;
            case "professions":
                return instance?.professions.map(({ id, name }) => ({ label: name, value: id.toString() }));
            case "features":
                return parseIdsToValues(getLocalFeatures(instance));
            case "branchTypes":
                return instance?.schoolbranchTypes.map(({ id, name }) => ({ label: name, value: id.toString() }));
            case "subjects":
                return instance?.subjects.map(({ id, name }) => ({ label: name, value: id.toString() }));
            case "boolean":
                return [{ label: "Tak", value: "true" }, { label: "Nie", value: "false" }];
            case "package":
                return [{ label: "rozszerzony", value: "true" }, { label: "standardowy", value: "false" }];
            default:
                return null;
        }
    }

    const currentInstanceFilters = useMemo(() => {
        const filtersConfig = instanceFiltersConfig.find(el => el.unitGeneralType === unitTypeId).filters;

        if (filtersConfig.length === 0) return [];

        return filtersConfig.reduce(
            (acc, filter) => {
                if (
                    (filter?.conditionalSetting && !(filter?.conditionalSetting in instance.settings)) ||
                    (filter?.negativeConditionalSetting && (filter?.negativeConditionalSetting in instance.settings)) ||
                    (filter?.filterConditionalSetting && !(filter?.filterConditionalSetting in instance.settings))
                ) return acc;

                const filterOptions = getOptions(filter.options, instance, units);
                if (filterOptions?.length < 2) return acc;

                return [...acc, {
                    id: filter.id,
                    label: t(filter.label),
                    placeholder: t(filter.placeholder),
                    multiselect: filter.multiselect,
                    options: filterOptions,
                }]
            },
            []);
    }, []);

    const formInitialValues = useMemo(() => {
        let initialVals = {};
        // dont add this to dependency list because it is readed only once - at componennt initialization;
        const searchParamsObject = Object.fromEntries(new URLSearchParams(searchParams));
        const searchParamsKeys = Object.keys(searchParamsObject);

        if ((searchParamsKeys.includes('unitName')) && (typeof searchParamsObject['unitName'] === 'string')) {
            initialVals = { ...initialVals, unitName: searchParamsObject['unitName'] };
        } else {
            initialVals = { ...initialVals, unitName: '' };
        }

        const otherFilters = currentInstanceFilters.map(({ id, options, multiselect }) => {
            if (multiselect) {
                if (searchParamsKeys.includes(id) && typeof searchParamsObject[id] === 'string' && searchParamsObject[id].length > 0) {
                    return { [id]: searchParamsObject[id].split(',').map(item => options.find(el => el.value === item)) };
                } else {
                    return { [id]: [] };
                }
            } else {
                if (searchParamsKeys.includes(id) && typeof searchParamsObject[id] === 'string' && searchParamsObject[id].length > 0) {
                    return { [id]: options.find(el => el.value === searchParamsObject[id]) };
                } else {
                    return { [id]: null };
                }
            }
        });
        return { ...initialVals, ...Object.assign({}, ...otherFilters) };
    }, []);

    const filtersExpandedOnStartup = useMemo(() => {
        const searchParamsKeysExceptUnitName = Object.keys(Object.fromEntries(new URLSearchParams(searchParams))).filter(key => key !== 'unitName');
        return searchParamsKeysExceptUnitName.some(key => key in formInitialValues);
    }, []);

    return { 
        currentInstanceFilters, 
        formInitialValues, 
        filtersExpandedOnStartup
    };
};

export default useFiltersInitial;