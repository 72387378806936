import React, { useState, useEffect, useContext } from "react";
import { getInstanceById } from "utils/utils";
import { AppContext } from "context/appContext";

import { Link } from "react-router-dom";

import * as Styled from "pages/ApplicationList/ApplicationList.styles";

const OtherRecruitmentApplications = ({ applications }) => {
  const { instances } = useContext(AppContext);
  const [applicationDetails, setApplicationDetails] = useState([]);

  useEffect(() => {
    if (!applications || applications.length === 0) return;

    const details = applications.map((application) => {
      const data = getInstanceById(instances, application.id_Instance);
      return {
        ...application,
        regionName: data?.regionName || "",
        typeName: data?.typeName || "",
        typeUrl: data?.typeUrl || "",
        regionUrl: data?.regionUrl || "",
      };
    });

    setApplicationDetails(details);
  }, [applications, instances]);

  return (
    <div>
      <Styled.H3>
        Podania w innych rekrutacjach (
        {applications ? applications.length : "0"})
      </Styled.H3>
      <Styled.ApplicationsList>
        {applicationDetails.map((application) => (
          <Styled.RecruitmentItem key={application.id}>
            <Link
              to={`/${application.regionUrl}/${application.typeUrl}/wnioski?child=${application.id}`}
            >
              {application.fullName} - {application.typeName} - {application.regionName}
            </Link>
          </Styled.RecruitmentItem>
        ))}
      </Styled.ApplicationsList>
    </div>
  );
};

export default OtherRecruitmentApplications;
