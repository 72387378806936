import React, {
    useState,
    useEffect,
    useContext,
    useCallback,
    useMemo,
    useRef,
  } from "react";
  import { useTranslation } from "react-i18next";
  import { useParams } from "react-router-dom";
  import { AppContext } from "context/appContext";
  import { getInstance } from "utils/utils";
  import { Page } from "components/commons/Page/Page";
  
  import { Button } from "components/commons/Button/Button";
  
  import { UnitTypes, UnitGeneralTypes } from "utils/enums";
  import useUnknownCityOrType from "hooks/useUnknownCityOrType";
  
  import useApi from "hooks/useApi";
  import url from "routes/routes";
  
  import Banner from "components/commons/Banner/Banner";
  import Breadcrumbs from "components/commons/Breadcrumbs/Breadcrumbs";
  import Loader from "components/commons/Loader/Loader";
  
  import * as Styled from "./Offer.styles";
  
  import useStorageStateExpiring from "hooks/useStorageStateExpiring";
  
  import OfferSchedule from "./OfferSchedule";
  import UnitsListWrapper from "./UnitsListWrapper";
  import { parseAppType } from "utils/utils";
  
  export const viewTypeDict = {
    [UnitGeneralTypes.Nursery]: UnitTypes.Nursery,
    [UnitGeneralTypes.Kindergarten]: UnitTypes.Nursery,
    [UnitGeneralTypes.PrimarySchool]: UnitTypes.PrimarySchool,
    [UnitGeneralTypes.TradeSchool]: UnitTypes.SecondarySchool,
    [UnitGeneralTypes.PostSecondarySchool]: UnitTypes.SecondarySchool,
    [UnitGeneralTypes.Dormitory]: UnitTypes.Dormitory,
    [UnitGeneralTypes.Class7]: UnitTypes.PrimarySchool,
    [UnitGeneralTypes.SecondarySchool]: UnitTypes.SecondarySchool,
    [UnitGeneralTypes.HolidayDuty]: UnitTypes.HolidayDuty,
    [UnitGeneralTypes.Semicolony]: UnitTypes.HolidayDuty,
    [UnitGeneralTypes.Center]: UnitTypes.Center,
    [UnitGeneralTypes.Culture]: UnitTypes.Culture,
  };
  
  const Offer = () => {
    const { t } = useTranslation();
    const { city, generalType } = useParams();
    const { instances: instancesList, setBreadcrumbsPath } =
      useContext(AppContext);
    const [showScheduleButton, setShowScheduleButton] = useState(false);
    const { offerApi } = useApi();
  
    const offerListRef = useRef();
    const scheduldeRef = useRef();

    useUnknownCityOrType();

    const [instanceId, setInstanceId] = useStorageStateExpiring(
      `/${city}/${generalType}/instanceId`
    );
    const [instance, setInstance] = useStorageStateExpiring(
      `/${city}/${generalType}/instance`,
      1
    );
  
    const getInstanceIdFromInstanceList = useCallback(() => {
      if (instancesList?.length > 0) {
        const instances = getInstance(instancesList, city, generalType);
        if (instances != null && instances.length > 0) {
          const { id } = instances[0];
          if (id) setInstanceId(id);
        }
      }
    }, [city, generalType, instancesList]);
  
    const fetchInstance = async (id) => {
      const { data, error } = await offerApi.get(`instance/${id}`);
  
      setInstance(data);
    };
  
    useEffect(() => {
      // START HERE - only with the instanceId can get instance;
      if (!instanceId) {
        getInstanceIdFromInstanceList();
      } else {
        if (!instance) {
          fetchInstance(instanceId);
        }
      }
    }, [instanceId, getInstanceIdFromInstanceList]);
  
    const currentYear = useMemo(
      () => instance?.operatingYear || new Date().getFullYear(),
      [instance]
    );
  
    useEffect(() => {
      setBreadcrumbsPath([
        {
          name: t("breadcrumbs.allInstances"),
          url: url.allInstances.all,
        },
        {
          name: instance?.instanceName || city,
          url: `/${city}/`,
        },
        {
          name: t(`unitTypes.${generalType}`),
        },
      ]);
    }, [instance?.instanceName, generalType]);
  
    if (instance === null)
      return (
        <Loader isLonger={true} isLongerOver={true} text={t("offer.loading")} />
      );
  
    const scrollTo = (el) =>
      el.current && el.current.scrollIntoView({ behavior: "smooth" });
  
    const firstActionButton = () => {
      if (!instance?.allowPublicSubbmissions) return null;
  
      return (
        <Button
          to={`/${city}/${generalType}${parseAppType(3)}`}
          reverse
          tabIndex={0}
        >
          {instance?.unitGeneralType === 11
          ? t("offer.endorsments.type2.fillText")
          : t("offer.endorsments.type3.fillText")}
        </Button>
      );
    };
  
    const secondActionButton = () => {
      if (!instance?.allowPublicClaimsAndApplications) return null;
  
      const isFirstActionButtonNull = firstActionButton() === null;
  
      const buttonType =
        instance?.unitGeneralType === 3
          ? t("offer.endorsments.type2.fillText")
          : t("offer.endorsments.type1.fillText");
  
      const commonProps = {
        to: `/${city}/${generalType}${parseAppType(
          instance?.unitGeneralType === 3 ? 2 : 1
        )}`,
        reverse: true,
        tabIndex: 0,
      };
  
      return isFirstActionButtonNull ? (
        <Button {...commonProps}>{buttonType}</Button>
      ) : (
        <Styled.OfferButton
          {...commonProps}
          secondary={instance?.unitGeneralType !== 3}
        >
          {buttonType}
        </Styled.OfferButton>
      );
    };
  
    const bannerTitles = t("bannerTitles", { returnObjects: true });

    const getInfo = (generalType) =>
        `${t(
            `instanceInfo.${generalType}`
        )}`;
  
    return (
      <Page>
        <Breadcrumbs />
        <Banner
          isOffer
          cityImg={"banner-city-example.png"}
          title={`${t("offer.recruitment")} ${bannerTitles[generalType]}`}
          description={getInfo(generalType)}
          instanceId={instanceId}
        >
          {firstActionButton()}
          {secondActionButton()}
          <Styled.OfferLink
            tabIndex={0}
            onClick={(e) => {
              e.preventDefault();
              scrollTo(offerListRef);
            }}
          >
            {t("offer.offers").toUpperCase()}
          </Styled.OfferLink>
        </Banner>
  
        <Styled.InstanceSection>
          <OfferSchedule
            ref={scheduldeRef}
            instanceId={instanceId}
            generalType={generalType}
            currentYear={currentYear}
            setShowScheduleButton={setShowScheduleButton}
          />
          <div ref={offerListRef} style={{ overflowX: "hidden" }}>
            <UnitsListWrapper instance={instance} />
          </div>
        </Styled.InstanceSection>
      </Page>
    );
  };
  
  export default Offer;
  