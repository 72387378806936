import React from 'react';
import styled from 'styled-components';

const Label = ({ 
    label, 
    isRequired, 
    error, 
    htmlFor, 
    description, 
    children, 
    cursorPointer = false,
    ...props}) => (
    <StyledLabel 
        error={!!error} 
        htmlFor={htmlFor}
        cursorPointer={cursorPointer}
        {...props}
    >
        {isRequired && <Asterisk />}
        {children}
        {label}
        {description && (
            <Description>{' '}{description}</Description>
        )}
        
    </StyledLabel>
);
export default Label;


const StyledLabel = styled.label`
    display: block;
    font-size: 1.4rem;
    margin-bottom: 3px;
    /* color: ${props => (props.error === true ? props.theme.colors.warning : props.theme.colors.grey60)}; */
    color: ${props => props.theme.colors.grey60};
    cursor: ${props => props.cursorPointer ? 'pointer' : 'auto'};
`;
const Asterisk = styled.span`
    color: ${props => props.theme.colors.warning};
    font-size: inherit;
    margin-right: 5px;
    &:before {
        content: "*";
    }
`;
const Description = styled.span`
    color: inherit;
    font-size: inherit;
    font-style: italic;
`;