import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import parse from "html-react-parser";

import useApi from "hooks/useApi";
import useUnknownCityOrType from "hooks/useUnknownCityOrType";
import { useInstance } from 'hooks/useInstance';

import { decodeEscapedHtml } from "utils/utils";

import { Page } from 'components/commons/Page/Page';
import StaticPage from 'components/reusable/StaticPage/StaticPage';
import Breadcrumbs from 'components/commons/Breadcrumbs/Breadcrumbs';
import Collapse from 'components/commons/Collapse/Collapse';
import Banner from 'components/commons/Banner/Banner';

import * as Text from 'components/commons/Text/Text';

const Faq = () => {
    const { t } = useTranslation();
    const [faqs, setFaqs] = useState([]);
    const { offerApi } = useApi();

    useUnknownCityOrType();
    
    const getFaqs = async (id) => {
        const { data, error } = await offerApi.get(`instance/${id}/faqs`);

        if(!error)
            setFaqs(data);
        else
            console.log(error.message);
    }

    const { id: instanceId } = useInstance([{ name: t('breadcrumbs.faq') }]);

    useEffect(() => {
        if (instanceId){
            getFaqs(instanceId);
        }
    }, [instanceId]); 

    // useEffect(() => {
    //     if (city && instances.length ) {
    //         setCityName(getCity(instances, city));

    //         const instance = getInstance(instances, city, generalType);

    //         getFaqs(instance[0].id);
    //     }
    // }, [instances])

    // useEffect(() => {
    //     setBreadcrumbsPath([
    //         { 
    //             name: t('breadcrumbs.allInstances'),
    //             url: url.allInstances.all
    //         },
    //         { 
    //             name: cityName,
    //         },
    //         { 
    //             name: t(`unitTypes.${generalType}`),
    //         },
    //         { 
    //             name: t('breadcrumbs.faq'),
    //         },
    //     ])
    // }, [cityName])

    return (
        <Page>
            <Breadcrumbs />
            <Banner />
            <StaticPage title={t('breadcrumbs.faq')}>
                { faqs && faqs.map((item) => (
                    <Collapse title={item.question} key={item.question} id={item.question}>
                        <Text.Content>{parse(decodeEscapedHtml(item.answer))}</Text.Content>
                    </Collapse>
                )) }
            </StaticPage>
        </Page>
    )
};

export default Faq;