import React from 'react';

import Breadcrumbs from 'components/commons/Breadcrumbs/Breadcrumbs';

import InfoSection from "components/reusable/InfoSection/InfoSection";
import HowToSection from "components/reusable/HowToSection/HowToSection";
import UnitTypesSection from "components/reusable/UnitTypesSection/UnitTypesSection";


const City = () => {
    return (
        <>
            <InfoSection />
{/*            <HowToSection />*/}
            <UnitTypesSection />
        </>
    )
};

export default City;