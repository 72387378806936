import styled from "styled-components";
// import { Link as LinkDefault } from "react-router-dom";
import { Link as LinkDefault } from "components/commons/Link/Link";

export const DocumentsListing = styled.div`
    margin-top: 50px;
    padding-bottom: 200px;
`

export const DocumentsListHeader = styled.div`
    @media ${({theme}) => theme.device.tablet} {
        display: flex;
        gap: 5px;
    }
`

export const DocumentsTitleMobile = styled.div`
    display: flex;
    align-items: baseline;
    margin-right: 5px;
    @media ${({theme}) => theme.device.tablet} {
        display: none;
    }
`

export const DocumentsDateHeader = styled.div`
    display: flex;
    align-items: baseline;
`

export const Link = styled(LinkDefault)`
    max-width: max-content;
    font-size: 2rem;
    font-weight: 600;
`

