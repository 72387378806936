import styled from "styled-components";

export const ContentBlock = styled.div`

`

export const ContentBlockTitle = styled.div`

`

export const ContentBlockSubTitle = styled.div`
    margin-top: 50px;
    display: flex;
    gap: 15px;
`

export const ContentBlockBox = styled.div`
    margin-top: 30px;

    a {
        font-size: inherit;
        font-weight: bold;
    }
`
