import { useMemo, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import {
    UnitGeneralTypes,
    DetailedUnitTypes,
    UnitInstanceStatus,
} from "utils/enums";

import { filterOptionsDefault } from "components/commons/UnitsFilters/UnitsFilters.config";

const useUnitFiltering = ({ units, instance, filteringForPreferences = false }) => {
    const { search: searchParams } = useLocation();

    const filterBranchMap = useCallback((unit, valueName, searchParamsObject) => {
        if ( searchParamsObject[valueName].length > 0 ) {
            const parsedSearchAwaredSubjects = searchParamsObject[valueName].split(',').map(item => parseInt(item)).filter(element => !isNaN(element));
            if (unit?.schoolBranches) {
                const branchesMatchesSearch = unit?.schoolBranches.filter( (unit) => {
                    if (!unit[valueName] || unit[valueName]?.length < 1) {
                        return null;
                    }
                    return parsedSearchAwaredSubjects.every(element => unit[valueName].includes(element));
                });
                if (branchesMatchesSearch.length < 1) {
                    return null;
                }
                return {...unit, schoolBranches: branchesMatchesSearch};
            }
        }
    }, []);

    const globalFilters = (item) => {
        const notSchoolComplex =
            item?.id_DictUnitType !== DetailedUnitTypes.SchoolComplex

        const allowedToDisplay =
            (item?.id_DictStatus === UnitInstanceStatus.InPreparation || item?.id_DictStatus === UnitInstanceStatus.Approved);

        return notSchoolComplex && allowedToDisplay;
    };

    const newUnitsList = useMemo( () => {
        if (!units) return [];
        const searchParamsObject = Object.fromEntries(new URLSearchParams(searchParams));
        const relevantFilters = Object.keys(searchParamsObject).filter(key => Object.keys(filterOptionsDefault).includes(key));

        const isSecondaryOrTradeSchool = (instance?.unitGeneralType === UnitGeneralTypes.SecondarySchool || instance?.unitGeneralType === UnitGeneralTypes.TradeSchool);
        
        const preFilteredUnits = ( isSecondaryOrTradeSchool && !filteringForPreferences ) ? units.filter( unit => globalFilters(unit)) : units;

        if (relevantFilters.length < 1) {
            return preFilteredUnits;
        }

        const newUnits = [...preFilteredUnits];

        const filteredUnits = newUnits.map( (unit) => {
            if (relevantFilters.includes('unitName') && typeof searchParamsObject.unitName === 'string'  && searchParamsObject.unitName.length > 0) {
                if (!(`${unit?.name} ${unit?.street} ${unit?.houseNo} ${unit?.postalCode} ${unit?.city}`.match(new RegExp(searchParamsObject.unitName, "gi")))) {
                    return null;
                }
            }

            if (relevantFilters.includes('districts') && searchParamsObject.districts.length > 0) {
                if(!unit?.district || !searchParamsObject.districts.match(new RegExp(unit.district, "gi"))) {
                    return null;
                }
            }

            if (relevantFilters.includes('features') && searchParamsObject.features.length > 0) {
                if (!unit?.boolFeatures || unit.boolFeatures.length < 1) {
                    return null;
                }
                const paramsFeatures = searchParamsObject.features.split(',').map(item => parseInt(item)).filter(element => !isNaN(element));
                const parsedUnitFeatures = unit?.boolFeatures.map( feature => feature?.value === true ? feature?.id : null).filter(el => el);
                if (!paramsFeatures.every( featureId => parsedUnitFeatures.includes(featureId))) {
                    return null;
                }
            }

            if (relevantFilters.includes('type') && (searchParamsObject.type === 'true' || searchParamsObject.type === 'false')) {
                if (unit?.isPublic.toString() !== searchParamsObject.type) {
                    return null;
                }
            }

            if(relevantFilters.includes('ageGroup') && searchParamsObject.ageGroup.length > 0 && !isNaN(parseInt(searchParamsObject.ageGroup))) {
                if (!unit?.schoolBranchTypes || unit?.schoolBranchTypes.length < 1) {
                    return null;
                }
                if (!unit.schoolBranchTypes.includes( parseInt(searchParamsObject.ageGroup))){
                    return null;
                }
            }

            if(relevantFilters.includes('additionalActivities') && searchParamsObject.additionalActivities.length > 0) {
                if (!unit?.boolFeatures || unit?.boolFeatures.length < 1) {
                    return null;
                }
                const parsedUnitFeatures = unit?.boolFeatures.map( feature => feature?.value === true ? feature?.id : null).filter(el => parseInt(el));
                const parsedSearcActivities = searchParamsObject.additionalActivities.split(',').map(item => parseInt(item)).filter(element => !isNaN(element));
                if (!parsedSearcActivities.every(feature => parsedUnitFeatures.includes(feature))){
                    return null;
                }
            }

            if(relevantFilters.includes('languages') && searchParamsObject.languages.length > 0) {
                // language filtering also applies to branches
                const parsedSearchLanguages = searchParamsObject.languages.split(',').map(item => parseInt(item)).filter(element => !isNaN(element));
                let areLangsInBranches = false;
                if (unit?.schoolBranches && unit?.schoolBranches.length > 0) {
                    // first check if branches have languages that match
                    const branchMatchLanguages = unit?.schoolBranches.map( (branch) => {
                        if (!branch?.languages || branch?.languages?.length < 1) {
                            return null;
                        }
                        const branchLanguages = branch.languages.map(lang => lang?.id_DictLanguage);
                        if (branchLanguages.some(element => parsedSearchLanguages.includes(element))) {
                            areLangsInBranches = true;
                            return branch;
                        }                        
                        return null;
                    }).filter(el => el);

                    if (branchMatchLanguages.length < 1) {
                        return null;
                    }
                    unit = {...unit, schoolBranches: branchMatchLanguages};
                    // return {...unit, schoolBranches: branchMatchLanguages};
                } else if (unit?.languages && unit?.languages.length > 0 && !areLangsInBranches) {
                    if(!unit.languages.some(element => parsedSearchLanguages.includes(element))){
                        return null;
                    }
                } else {
                    return null;
                }
            }

            if(relevantFilters.includes('freePlaces') && (searchParamsObject?.freePlaces === 'Tak' || searchParamsObject?.freePlaces === 'Nie')) {
                if (!unit.freePlaces || !isNaN(parseInt(unit.freePlaces))) {
                    return null;
                }
                if ((searchParamsObject.freePlaces === 'Tak' && unit?.freePlaces < 1) || (searchParamsObject.freePlaces === 'Nie' && unit?.freePlaces > 0)) {
                    return null;
                }
            }

            if (relevantFilters.includes('secondarySchoolType') && (searchParamsObject.secondarySchoolType.length > 0) && (!isNaN(parseInt(searchParamsObject.secondarySchoolType)))) {
                if (unit?.unitType !== parseInt(searchParamsObject.secondarySchoolType)) {
                    return null;
                }
            }

            if (relevantFilters.includes('extendedSubjects') && (searchParamsObject.extendedSubjects.length > 0)) {
                // extended subject filtering only applies to branches
                const branchesMatchExtendedSubjects = matchBranches(unit, 'extendedSubjects', searchParamsObject);
                if (!branchesMatchExtendedSubjects) {
                    return null;
                }
                unit = {...unit, schoolBranches: branchesMatchExtendedSubjects};
            }

            if (relevantFilters.includes('awardedSubjects') && (searchParamsObject.awardedSubjects.length > 0)) {
                // awarded subject filtering only applies to branches
                const branchesMatchAwardedSubjects = matchBranches(unit, 'awardedSubjects', searchParamsObject);
                if (!branchesMatchAwardedSubjects) {
                    return null;
                }
                unit = {...unit, schoolBranches: branchesMatchAwardedSubjects};
            }

            if (relevantFilters.includes('professions') && (searchParamsObject.professions.length > 0)) {
                const branchesMatchProfessions = matchBranches(unit, 'professions', searchParamsObject);
                if (!branchesMatchProfessions) {
                    return null;
                }
                unit = {...unit, schoolBranches: branchesMatchProfessions};
            }

            if (relevantFilters.includes('package') && searchParamsObject.package) {
                if (!unit?.boolFeatures || unit.boolFeatures.length < 1) {
                    return null;
                }

                const packageFeature = unit.boolFeatures.find((feature) => feature.id == 72);
                if (packageFeature) {
                    if (packageFeature.value !== JSON.parse(searchParamsObject.package)) {
                        return null;
                    }
                } else return null;
            }

            // match all filters - must remain on list
            return unit;
        }).filter(el => el);
        
        return filteredUnits;
    }, [ searchParams, units ]);

    return newUnitsList;
}

const matchBranches = (unit, valueName, searchParamsObject) => {
    
    const parsedSearch = searchParamsObject[valueName].split(',').map(item => parseInt(item)).filter(element => !isNaN(element));

    if (unit?.schoolBranches) {
        const branchMatchValue = unit?.schoolBranches.filter( (unit) => {
            const valueToFilter = unit[valueName];
            if (!valueToFilter || valueToFilter?.length < 1) {
                return null;
            }
            return parsedSearch.every(element => valueToFilter.includes(element));
        });
        if (branchMatchValue.length < 1) {
            return null;
        }
        return branchMatchValue;
    }
}

export default useUnitFiltering; 